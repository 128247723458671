import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        now: new Date().getTime(),
        interval: null
    },

    mutations: {
        /** Updates time to now */
        [Mutation.TIME_UPDATE_CURRENT](state) {
            state.now = new Date().getTime()
        },

        /** Stops ticking */
        [Mutation.TIME_STOP](state) {
            clearInterval(state.interval)
            state.interval = null
        }
    },

    actions: {
        /** Starts every second tick updating identity session time */
        [Action.TIME_START]({ commit, dispatch, state }) {
            clearInterval(state.interval)
            state.interval = null

            if (state.interval === null) {
                state.interval = setInterval(() => {
                    commit(Mutation.TIME_UPDATE_CURRENT)
                    dispatch(Action.IDENTITY_SESSION_UPDATE, state.now)
                }, 1000)
            }
        },


        [Action.SIGNATURE_TEST](context, sign) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SIGNATURE_TEST,
                        data: sign,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },
    }
}