export default {

  data: () => {
    return {
      /**Rodzaje zawartości paczki
       * Shipment content types
       */
      mobiContentTypes: [
        {
          id: 0,
          name: 'Niezdefiniowano'
        },
        {
          id: 1,
          name: 'Sprzęt medyczny'
        },
        {
          id: 2,
          name: 'Dokumenty'
        },
        {
          id: 3,
          name: 'Lodówka transportowa'
        },
        {
          id: 4,
          name: 'Leki'
        },
        {
          id: 5,
          name: 'Żywność'
        },
        {
          id: 6,
          name: 'Bezpieczna koperta'
        },
        {
          id: 7,
          name: 'Meble'
        },
        {
          id: 8,
          name: 'Środki ochrony osobistej'
        },
        {
          id: 9,
          name: 'Artykuły higieniczne'
        },
        {
          id: 10,
          name: 'Artykuły biurowe'
        },
        {
          id: 11,
          name: 'Sprzęt elektroniczny'
        },
        {
          id: 12,
          name: 'Materiały promocyjne'
        },
        {
          id: 13,
          name: 'Środki chemiczne'
        },
        {
          id: 14,
          name: 'Magazyn Centrum'
        },
        {
          id: 100,
          name: 'Statyw - Cytologia'
        },
        {
          id: 101,
          name: 'Wyniki badań - Radiologia'
        },
        {
          id: 102,
          name: 'Statyw - Histopatologia'
        },
        {
          id: 103,
          name: 'Wyniki badań - Histopatologia'
        }
      ],

      mobiWarehouseCentrum: [
        {
          id: 0,
          name: 'Brak informacji'
        },
        {
          id: 1,
          name: "Chemimed"
        },
        {
          id: 2,
          name: "Eko higiena"
        },
        {
          id: 3,
          name: "Allpap"
        },
        {
          id: 4,
          name: "Farkomed"
        },
        {
          id: 5,
          name: "Inny"
        }
      ],

      mobiDeviceTypes: [
        {
          id: 0,
          name: 'Brak informacji'
        },
        {
          id: 1,
          name: 'Sprzęt USG'
        },
        {
          id: 2,
          name: 'Densytometr'
        },
        {
          id: 3,
          name: 'Sonda'
        },
        {
          id: 5,
          name: "Awaria sprzętu medycznego"
        },
        {
          id: 6,
          name: "Przesunięcia sprzętu medycznego"
        },
        {
          id: 7,
          name: "Naprawiony sprzęt medyczny"
        },
        {
          id: 8,
          name: "Waga"
        },
        {
          id: 4,
          name: 'Inny'
        }
      ],

      mobiDocumentTypes: [
        {
          id: 0,
          name: 'Brak informacji'
        },
        {
          id: 1,
          name: 'Kadrowe'
        },
        {
          id: 2,
          name: 'Księgowe'
        },
        {
          id: 3,
          name: 'NFZ'
        },
        {
          id: 4,
          name: 'Inny'
        }
      ],
      mobiOfficeArticlesTypes: [
        {
          id: 0,
          name: 'Brak informacji'
        },
        {
          id: 1,
          name: 'Etykiety'
        },
        {
          id: 2,
          name: 'Pisaki'
        },
        {
          id: 3,
          name: 'Papier'
        },
        {
          id: 4,
          name: 'Toner'
        },
        {
          id: 5,
          name: 'Inny'
        }
      ],
      mobiElectronicTypes: [
        {
          id: 0,
          name: 'Brak informacji'
        },
        {
          id: 1,
          name: 'Komputer'
        },
        {
          id: 2,
          name: 'Telefon'
        },
        {
          id: 3,
          name: 'Drukarka'
        },
        {
          id: 4,
          name: 'Myszka'
        },
        {
          id: 5,
          name: 'Klawiatura'
        },
        {
          id: 6,
          name: 'Monitor'
        },
        {
          id: 7,
          name: 'Inny'
        }
      ],
      mobiProtectiveEqupmentTypes: [
        {
          id: 0,
          name: 'Brak informacji'
        },
        {
          id: 1,
          name: 'Maski'
        },
        {
          id: 2,
          name: 'Fartuchy'
        },
        {
          id: 3,
          name: 'Rękawiczki'
        },
        {
          id: 4,
          name: 'Czepki'
        },
        {
          id: 5,
          name: 'Buty ochronne'
        },
        {
          id: 6,
          name: 'Kombinezony'
        },
        {
          id: 7,
          name: 'Inny'
        }
      ],

      mobiFurnitureTypes: [
        {
          id: 0,
          name: 'Brak informacji'
        },
        {
          id: 1,
          name: 'Stół'
        },
        {
          id: 2,
          name: 'Krzesło'
        },
        {
          id: 3,
          name: 'Leżanka'
        },
        {
          id: 4,
          name: 'Parawan'
        },
        {
          id: 5,
          name: 'Ławka'
        },
        {
          id: 6,
          name: 'Inny'
        }
      ],
      mobiChemicalsTypes: [
        {
          id: 0,
          name: 'Brak informacji'
        },
        {
          id: 1,
          name: 'Środki czyszczące'
        },
        {
          id: 2,
          name: 'Środki dezynfekcyjne'
        },
        {
          id: 3,
          name: 'Inny'
        }
      ],
      /**Typy przesyłek
       * Types of shipment
       */
      mobiFormatTypes: [
        {
          id: 0,
          name: 'Nieokreślony format',
          icon: 'help-rhombus-outline'
        },
        {
          id: 1,
          name: 'Koperta',
          icon: 'email-outline'
        },
        {
          id: 2,
          name: 'Paczka',
          icon: 'package-variant'
        }
      ],
      /**Status przesyłki
       * Shipment status
       */
      mobiStatusTypes: [
        {
          id: 0,
          name: 'Nadanie',
          icon: 'timer-sand',
          type: 'is-warning'
        },
        {
          id: 1,
          name: 'Przewóz',
          icon: 'truck',
          type: 'is-info'
        },
        {
          id: 2,
          name: 'Magazyn',
          icon: 'home-variant',
          type: 'is-dark'
        },
        {
          id: 3,
          name: 'Zewnętrzny',
          icon: 'dolly',
          type: 'is-maroon'
        },
        {
          id: 4,
          name: 'Przekierowanie',
          icon: 'chevron-triple-right',
          type: 'is-danger'
        },
        {
          id: 5,
          name: 'Dostarczona',
          icon: 'check-bold',
          type: 'is-success'
        },
        {
          id: 6,
          name: 'Zwrot do nadawcy',
          icon: 'check-bold',
          type: 'is-danger'
        },
        {
          id: 7,
          name: 'Zła adresacja',
          icon: 'check-bold',
          type: 'is-danger'
        },
        {
          id: 8,
          name: 'Odmowa przyjęcia',
          icon: 'check-bold',
          type: 'is-danger'
        },
        {
          id: 9,
          name: 'Uwaga',
          icon: 'check-bold',
          type: 'is-info'
        },
        {
          id: 99,
          name: 'Anulowana',
          icon: 'check-bold',
          type: 'is-danger'
        },
      ],

      /**Status przesyłki - statystki
       * Shipment status
       */
      mobiStatusTypesStats: [
        {
          id: 0,
          name: 'Nadanie',
          icon: 'timer-sand',
          type: 'is-warning'
        },
        {
          id: 1,
          name: 'Przewóz',
          icon: 'truck',
          type: 'is-info'
        },
        {
          id: 2,
          name: 'Magazyn',
          icon: 'home-variant',
          type: 'is-dark'
        },
        {
          id: 3,
          name: 'Zewnętrzny',
          icon: 'dolly',
          type: 'is-maroon'
        },
        {
          id: 5,
          name: 'Dostarczona',
          icon: 'check-bold',
          type: 'is-success'
        },
        {
          id: 99,
          name: 'Anulowana',
          icon: 'check-bold',
          type: 'is-danger'
        },
      ],



      mobiUserTypes: [
        {
          id: 0,
          name: 'Inny'
        },
        {
          id: 1,
          name: 'Użytkownik SZOK'
        },
        {
          id: 2,
          name: 'Placówka'
        },
        {
          id: 10,
          name: 'Zewnętrzny'
        }
      ],
      mobiVehicleStates: [
        {
          id: 0,
          name: 'Brak informacji'
        },
        {
          id: 1,
          name: 'Dobry'
        },
        {
          id: 2,
          name: 'Zepsuty'
        }
      ],
      mobiVehicleTypes: [
        {
          id: 0,
          name: 'Nieokreślony'
        },
        {
          id: 1,
          name: 'Samochód dostawczy'
        },
        {
          id: 2,
          name: 'Samochód osobowy'
        },
        {
          id: 3,
          name: 'Ciężarówka'
        },
        // {
        //   id: 4,
        //   name: 'Samolot'
        // }
      ],

      mobiReservationAreas: [
        {
          id: 9001,
          name: 'Patronaże'
        },
        {
          id: 9002,
          name: 'Przewóz materiału lab.'
        },
        {
          id: 9003,
          name: 'Kolumna transportowa'
        },
        {
          id: 9004,
          name: 'Biuro'
        }
      ],

      mobiMiscTypes: [
        {
          id: 1,
          name: 'Zniszczenie'
        },
        {
          id: 2,
          name: 'Uszkodzenie'
        },
        {
          id: 3,
          name: 'Problem z zawartością'
        },
        {
          id: 50,
          name: 'Rezygnacja z przesyłki'
        },
        {
          id: 51,
          name: 'Pomyłka w wysyłce'
        },
        {
          id: 52,
          name: 'Nadanie poza system na własną odpowiedzialność'
        },
        {
          id: 99,
          name: 'Inny powód'
        },
      ],

      mobiReservationTypes: [
        {
          id: 2100,
          name: 'Wizyty domowe'
        },
        {
          id: 2101,
          name: 'Patronaże'
        },
        {
          id: 2102,
          name: 'Delegacja'
        },
        {
          id: 2103,
          name: 'Przewóz mat. lab.'
        },
        {
          id: 2104,
          name: 'Transport celowy pacjentów'
        },
        {
          id: 2105,
          name: 'Transport celowy ładunku'
        },
        {
          id: 2106,
          name: 'Zadania IT'
        },
        {
          id: 2107,
          name: 'Zadania utrzymania'
        },
        {
          id: 2108,
          name: 'Szkolenia personelu'
        },
        {
          id: 2109,
          name: 'Zadania nadzoru'
        },
        {
          id: 2110,
          name: 'Wydarzenie'
        },
        {
          id: 2111,
          name: 'Zdrowa Gmina'
        },
        {
          id: 2112,
          name: 'Stały przydział pojazdu'
        }
      ],
    }
  },

  methods: {
    getMobiMisc(id) {
      let status = this.mobiMiscTypes.find(s => s.id === id)
      return status ? status.name : ''
    },
    getMobiStatus(id) {
      let status = this.mobiStatusTypes.find(s => s.id === id)
      return status ? status.name : 'Brak informacji'
    },
    getMobiStatusIcon(id) {
      let status = this.mobiStatusTypes.find(s => s.id === id)
      return status ? status.icon : 'alert'
    },
    getMobiStatusType(id) {
      let status = this.mobiStatusTypes.find(s => s.id === id)
      return status ? status.type : ''
    },
    getMobiContentType(id) {
      let status = this.mobiContentTypes.find(s => s.id === id)
      return status ? status.name : 'Brak informacji'
    },
    getMobiDeviceType(id) {
      let status = this.mobiDeviceTypes.find(s => s.id === id)
      return status ? status.name : 'Brak informacji'
    },
    getMobiVehicleType(id) {
      let status = this.mobiVehicleTypes.find(s => s.id === id)
      return status ? status.name : 'Brak informacji'
    },
    getMobiVehicleStatus(id) {
      let status = this.mobiVehicleStates.find(s => s.id === id)
      return status ? status.name : 'Brak informacji'
    },
    getMobiUserType(id) {
      let status = this.mobiUserTypes.find(s => s.id === id)
      return status ? status.name : 'Brak informacji'
    },

    getMobiDeviceTypeOther(table, id) {
      let status = table.find(s => s.id === id)
      return status ? status.name : 'Brak informacji'
    },

    getMobiFormatType(id) {
      let status = this.mobiFormatTypes.find(s => s.id === id)
      return status ? status.name : 'Brak informacji'
    },

    getMobiReservationArea(id) {
      let status = this.mobiReservationAreas.find(s => s.id === id)
      return status ? status.name : 'Brak informacji'
    },

    getMobiReservationType(id) {
      let status = this.mobiReservationTypes.find(s => s.id === id)
      return status ? status.name : 'Brak informacji'
    },
  }
}
