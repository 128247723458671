var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{attrs:{"icon-left":"clipboard-flow-outline","size":"is-small","type":"is-secondary"},on:{"click":function($event){_vm.isActiveModal = true}}},[_vm._v("Modyfikuj DILO")]),_c('ValidationObserver',{ref:"observerDILO",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{staticClass:"p-5",attrs:{"has-modal-card":"","aria-role":"dialog","aria-modal":"","scroll":"keep","can-cancel":false,"trap-focus":"","destroy-on-hide":false},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"modal-card",staticStyle:{"overflow":"visible"}},[_c('header',{staticClass:"modal-card-head has-background-kin"},[_c('p',{staticClass:"modal-card-title has-text-light"},[_vm._v("Modyfikuj DILO:")])]),_c('section',{staticClass:"modal-card-body",staticStyle:{"min-height":"350px"}},[_c('ContentPanel',{attrs:{"title":"Karta DILO","icon":"file-document"}},[_c('b-field',[_c('ValidationProvider',{attrs:{"rules":{
                              required: true,
                              digitsPL: 11,
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
                            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Numer karty DILO (11 cyfr)","label-position":"on-border","type":{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  },"message":_vm.t(errors[0])}},[_c('b-input',{attrs:{"icon":"alert-circle-outline","placeholder":"Wymagane"},model:{value:(_vm.diloNumber),callback:function ($$v) {_vm.diloNumber=$$v},expression:"diloNumber"}})],1)]}}],null,true)})],1)],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"icon-left":"close"},on:{"click":props.close}},[_vm._v("Anuluj")]),_c('b-button',{attrs:{"loading":_vm.isLoading,"disabled":invalid,"type":"is-primary"},on:{"click":_vm.saveExam}},[_vm._v("Zapisz zmiany")])],1)])]}}],null,true),model:{value:(_vm.isActiveModal),callback:function ($$v) {_vm.isActiveModal=$$v},expression:"isActiveModal"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }