<template>
  <div>
    <div class="level">
      <div class="level-left">
        <div class="level-item p-1 pl-2">
          <span @click="proceedTo('tele-office')" class="pt-1 pr-4 hand">
            <b-tooltip label="Gabinet" position="is-bottom" type="is-kin">
              <b-icon
                icon="page-next-outline"
                size="is-medium"
                type="is-primelight"
                class="hoverable"
              ></b-icon>
            </b-tooltip>
          </span>
          <span
            class="pt-1 pr-3 hand hoverable"
            @click="proceedTo('tele-archive')"
          >
            <b-tooltip
              label="Archiwum medyczne"
              position="is-bottom"
              type="is-kin"
            >
              <b-icon
                icon="folder-clock-outline"
                size="is-medium"
                type="is-primelight"
                class="hoverable"
              ></b-icon>
            </b-tooltip>
          </span>
          <span
            class="pt-1 pr-3 hand hoverable"
            @click="proceedTo('elearning')"
          >
            <b-tooltip label="E-Learning" position="is-bottom" type="is-kin">
              <b-icon
                icon="book-open-page-variant"
                size="is-medium"
                type="is-primelight"
                class="hoverable"
              ></b-icon>
            </b-tooltip>
          </span>
          <!-- <span
            class="pt-1 pr-3 hand hoverable"
            @click="proceedTo('tpk-worktimes')"
            v-if="perms.admin || perms.tpkMaster"
          >
            <b-tooltip label="Zarządzanie terminarzem" position="is-bottom" type="is-kin">
              <b-icon icon="calendar-month" size="is-medium" type="is-primelight" class="hoverable"></b-icon>
            </b-tooltip>
          </span>-->
          <span
            class="pt-1 pr-3 hand hoverable"
            @click="proceedTo('employee-list')"
            v-if="perms.admin"
          >
            <b-tooltip
              label="Zarządzanie kontami"
              position="is-bottom"
              type="is-kin"
            >
              <b-icon
                icon="account-multiple-plus"
                size="is-medium"
                type="is-primelight"
                class="hoverable"
              ></b-icon>
            </b-tooltip>
          </span>
          <span
            class="pt-1 pr-3 hand hoverable"
            @click="proceedTo('tele-my-account')"
          >
            <b-tooltip
              label="Moje konto pracownika"
              position="is-bottom"
              type="is-kin"
            >
              <b-icon
                icon="account-circle"
                size="is-medium"
                type="is-primelight"
                class="hoverable"
              ></b-icon>
            </b-tooltip>
          </span>
        </div>
      </div>
      <p class="has-text-weight-semibold has-text-primelight has-text-centered">
        {{ currentRouteName }}
      </p>
      <div class="level-right pr-1 mt-0">
        <div class="level-item">
          <!-- <b-tooltip class="pr-05" label="Tu będą powiadomienia" position="is-bottom">
            <b-icon icon="bell" type="is-light" data-badge="1" class="has-badge-info"></b-icon>
          </b-tooltip>-->
          <span v-if="perms.myAccount">
            <IdentityPanel class="mr-3" />
          </span>
          <span v-else>
            <IdentityPanel class="mr-3" />
          </span>
          <span @click="logout()" class="pt-1">
            <b-tooltip
              :label="t('common.logOut')"
              position="is-bottom"
              type="is-danger"
              class="ph-05 hand"
            >
              <b-icon
                icon="exit-to-app"
                size="is-medium"
                type="is-primelight"
                class="hoverable"
              ></b-icon>
            </b-tooltip>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonMixins from '../../mixins/commons'
import IdentityPanel from '../identity/IdentityPanel'
import { Action } from '../../store/config_actions'
import { Mutation } from '../../store/config_mutations'

export default {
  name: 'SiteNavigationTele',
  mixins: [CommonMixins],

  components: {
    IdentityPanel,
  },

  data: function () {
    return {
      pretender: null,
      patient: null,
      open: false
    }
  },

  computed: {

    isProduction() {
      let location = window.location.href
      return location.includes('szok.digitmed.pl')
    },

    perms() {
      return this.$store.state.employee.permits
    },

    currentRouteName() {
      return this.$route.meta.desc ?? 'SZOK';
    },
    myRoles: function () {
      return this.$store.getters.getMyRoles
    },
    pretenders: function () {
      return this.$store.state.employee.scheduledEmployees
    },
    patients: function () {
      return this.$store.state.patients.patients
    },
    email: function () {
      return this.$store.state.identity.session.email
    },

    canSeePOZ() {
      if (this.myRoles) {
        if (this.myRoles.includes('Doctor') || this.myRoles.includes('Admin')) {
          return true
        }
      }

      return false
    },

    canSeeNurseOffice() {
      if (this.myRoles) {
        if (this.myRoles.includes('Nurse') || this.myRoles.includes('Admin')) {
          return true
        }
      }

      return false
    },

    canSeePharmacistOffice() {
      if (this.myRoles) {
        if (this.myRoles.includes('Pharmacist') || this.myRoles.includes('Admin')) {
          return true
        }
      }

      return false
    },

    canSeeMobi() {
      if (this.myRoles) {
        if (this.myRoles.includes('MobiManager') || this.myRoles.includes('Admin')) {
          return true
        }
      }

      return false
    },

    canSeeOrders() {
      if (this.myRoles) {
        if (this.myRoles.includes('OrdersOperator') || this.myRoles.includes('Admin')) {
          return true
        }
      }

      return false
    },

    canSeeWorkers() {
      if (this.myRoles) {
        if (this.myRoles.includes('Management') || this.myRoles.includes('Admin')) {
          return true
        }
      }

      return false
    },

    canSeeCallCenter() {
      if (this.myRoles) {
        if (this.myRoles.includes('CallCenterWorker') || this.myRoles.includes('CallCenterManager') || this.myRoles.includes('Admin')) {
          return true
        }
      }

      return false
    },

    canSeeELearning() {
      if (this.myRoles) {
        if (this.myRoles.includes('Admin')) {
          return true
        }
      }

      return false
    },

    canSeePatientPortal() {
      if (this.myRoles) {
        if (this.myRoles.includes('Admin')) {
          return true
        }
      }

      return false
    },

    canSeeRegistration() {
      if (this.myRoles) {
        if (this.myRoles.includes('Nurse') || this.myRoles.includes('Doctor') || this.myRoles.includes('RagistrationWorker') || this.myRoles.includes('Admin')) {
          return true
        }
      }

      return false
    },
  },

  methods: {

    fullscreeen() {
      this.requestFullscreen(document.documentElement)
    },

    requestFullscreen(element) {
      if (element.requestFullScreen) {
        element.requestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      }
    },

    proceedTo(name) {
      this.open = false
      this.$router.push({ name: name })
    },

    toggleSidebar() {
      if (this.perms.myAccount) {
        this.open = true
      }
    },

    pretend() {
      this.$store.commit(Mutation.WORKER_PRETEND, this.pretender)
      this.successSnackbar('Udajesz: ' + this.pretender.name)
    },

    patientPretend() {
      this.$store
        .dispatch(Action.PATIENT_GET_MY_DATA, this.patient)
        .then(() => {
          this.successSnackbar('Pobrano dane medyczne pacjenta');
        })
        .catch(error => {
          this.apiProblem(error);
        });
    },
    getPatientDiagnoses() {
      this.$store
        .dispatch(Action.POZ_GET_DIAGNOSES, this.patient)
        .then(() => {
          this.successSnackbar('Pobrano informacje o rozpoznaniach pacjenta');
        })
        .catch(error => {
          this.apiProblem(error);
        });
    },
    getPatientAppointments() {
      this.$store
        .dispatch(Action.POZ_GET_APPOINTMENTS, this.patient)
        .then(() => {
          this.successSnackbar('Pobrano informacje o wizytach pacjenta');
        })
        .catch(error => {
          this.apiProblem(error);
        });
    },

    logout() {

      this.$buefy.dialog.confirm({
        message: 'Czy na pewno chcesz się wylogować?',
        cancelText: 'Anuluj',
        confirmText: 'Wyloguj',
        scroll: 'keep',
        type: 'is-info',
        hasIcon: true,

        onConfirm: () => {
          this.$store.dispatch(Action.IDENTITY_LOGOUT, 'FROM_NAV_TELE').then(() => {
            // this.$router.push({ name: 'login' })
            this.warningSnackbar(this.t('identity.userLoggedOut'))
          })

        }
      })
    },

    warningSnackbar(message) {
      this.$buefy.snackbar.open({
        message: message,
        queue: false,
        type: 'is-warning'
      })
    }
  }
}


</script>

<style scoped lang="scss">
.hoverable:hover {
  color: whitesmoke !important;
}
</style>
