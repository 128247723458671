import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'
import moment from 'moment'

export default {
    namespaced: false,

    state: {
        programId: null,

        ongoingAppointment: null,
        focusAppointment: null,

        appointmentHistory: [],
        appointmentDiagnoses: [],
        appointmentAllergies: null,

        isDiagnosesFromOld: false,
        isVisitCorrecting: false,
        isReediting: false,
        lastEndApp: null,
        diagnosisFromEdm: null,

        // TMP VISIT
        tmpVisitData: null,
        tmpVisitDataTrigger: 0,


        assistants: [],
    },

    mutations: {

        [Mutation.PROGRAM_APPOINTMENT_ADD_SUBUNIT_INFO](state, payload) {
            state.ongoingAppointment.subUnitInfo = payload
        },

        [Mutation.PROGRAM_APPOINTMENT_ADD_SUBJECT_INFO](state, payload) {
            state.ongoingAppointment.subjectInfo = payload
        },

        [Mutation.PROGRAM_APPOINTMENT_ADD_ORG_UNIT_INFO](state, payload) {
            state.ongoingAppointment.orgUnitInfo = payload
        },

        [Mutation.PROGRAM_APPOINTMENT_ADD_PROGRAM_ID](state, payload) {
            state.ongoingAppointment.programId = payload
        },

        [Mutation.PROGRAM_APPOINTMENT_RESET](state) {
            state.programId = null
            state.ongoingAppointment = null
            state.focusAppointment = null
            state.appointmentDiagnoses = []
            state.appointmentHistory = []
            state.appointmentAllergies = []
            state.tmpVisitData = null
            state.tmpVisitDataTrigger = 0
        },

        [Mutation.PROGRAM_APPOINTMENT_SET_FOCUS_APPOINTMENT_NURSE](state, payload) {
            state.focusAppointment = payload
        },

        [Mutation.PROGRAM_APPOINTMENT_SET_ONGOING_APPOINTMENT](state, payload) {
            state.ongoingAppointment = payload
        },

        [Mutation.PROGRAM_APPOINTMENT_SET_APPOINTMENT_INITIAL_DATA](state, payload) {
            if (payload) {

                if (payload.oldAppointments) {
                    state.appointmentHistory = payload.oldAppointments
                }
                else {
                    state.appointmentHistory = []
                }

                state.isDiagnosesFromOld = false;
                if (state.isVisitCorrecting && state.isReediting == false && state.lastEndApp != null && state.lastEndApp.diagnoses != null) {
                    state.isDiagnosesFromOld = true;

                    if (state.diagnosisFromEdm != null && state.diagnosisFromEdm.length > 0) {
                        if (state.lastEndApp.diagnoses.diagnoses != null) {
                            state.lastEndApp.diagnoses.diagnoses.forEach((diag) => {
                                if (diag.icd != null && diag.icd.code != null) {
                                    let found = state.diagnosisFromEdm.find((edmDiag) => edmDiag.icd?.code == diag.icd.code)
                                    if (found) {
                                        diag.fromBefore = found.fromBefore
                                        diag.isLeading = found.isLeading
                                        diag.isVisibleSummary = found.isVisibleSummary
                                    }
                                }
                            });
                        }
                    }
                    state.appointmentDiagnoses = state.lastEndApp.diagnoses
                } else if (state.tmpVisitData && state.tmpVisitData.appointmentDiagnoses) {
                    state.appointmentDiagnoses = JSON.parse(state.tmpVisitData.appointmentDiagnoses)
                } else if (payload.diagnoses) {
                    state.appointmentDiagnoses = payload.diagnoses
                }
                else {
                    state.appointmentDiagnoses = { diagnoses: [] }
                }

                if (payload.allergies) {
                    state.appointmentAllergies = payload.allergies
                }
                else {
                    state.appointmentAllergies = {
                        id: "tmp",
                        patientId: state.focusAppointment.patient.id,
                        changeDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                        items: []
                    }
                }
            }
        },
    },

    actions: {
        /** Attempt get all workers. Uses GET request without parameter */
        [Action.PROGRAM_APPOINTMENT_BEGIN_APPOINTMENT](context) {
            return new Promise((resolve, reject) => {
                if (context.state.focusAppointment) {
                    let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                    let s = context.state.focusAppointment
                    let worker = s.subjectId

                    if (s.subjectId < 0) {
                        let me = context.rootState.employee.me;
                        worker = me.id
                    }

                    if (token != null) {
                        // axios({
                        //     method: 'get',
                        //     url: Apis.PLC_LOCK.replace('{id}', s.patientId),
                        //     headers: { Authorization: "Bearer " + token }
                        // }).then(() => {
                        axios({
                            method: 'get',
                            // url: Apis.POZ_START_APPOINTMENT.replace('{patientId}', s.patientId).replace('{workerId}', worker).replace('{scheduleId}', s.externalId),
                            url: Apis.PD_START_APPOINTMENT.replace('{patientId}', s.patientId),
                            headers: { Authorization: "Bearer " + token }
                        }).then((response) => {

                            context.commit(Mutation.PROGRAM_APPOINTMENT_SET_ONGOING_APPOINTMENT, context.state.focusAppointment)
                            context.commit(Mutation.PROGRAM_APPOINTMENT_SET_APPOINTMENT_INITIAL_DATA, response.data)

                            let status = 1
                            if (context.state.isVisitCorrecting) {
                                status = 9
                            }

                            let scheduleUpdate = {
                                id: s.id,
                                data: {
                                    workerId: worker,
                                    status: status,
                                    assistants: context.state.assistants.map(e => e.id),
                                    version: context.state.focusAppointment.version
                                }
                            }

                            context.dispatch(Action.S2_POST_STATUS, scheduleUpdate)
                            resolve()
                        }).catch(error => { reject(error) })




                        //}).catch(error => { reject(error) })
                    } else reject('401')
                }
                else reject('no focus!!!')
            })
        },

        /** Attempt get all workers. Uses GET request without parameter */
        [Action.PROGRAM_APPOINTMENT_END_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {

                if (context.state.focusAppointment) {
                    let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                    if (token != null) {
                        axios({
                            method: 'POST',
                            data: payload,
                            url: Apis.POZ_FINISH_APPOINTMENT_V3,
                            headers: { Authorization: "Bearer " + token }
                        }).then(response => {
                            // axios({
                            //     method: 'get',
                            //     url: Apis.PLC_UNLOCK.replace('{id}', payload.patientId),
                            //     headers: { Authorization: "Bearer " + token }
                            // }).then(() => {
                            resolve(response.data)
                            // }).catch(error => { reject(error) })

                        }).catch(error => { reject(error) })
                    } else reject('401')
                }
                else reject('no focus!!!')
            })
        },
    }
}