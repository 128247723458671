import Vue from 'vue'
import App from './App.vue'

// Router and Vuex.
import router from './router/router'
import store from './store/store'

// Buefy with local icons.
import '@mdi/font/css/materialdesignicons.css'
import Buefy from 'buefy'

// Axios for API requests.
import axios from 'axios'
import VueAxios from 'vue-axios'

// Languages handler - enables 'ml' object in Vue.
import './localization/languages'

// Validation rules.
import './validation/config_rules'

// Bulma badge
import '@creativebulma/bulma-badge/dist/bulma-badge.min.css'

import VueQrcode from '@chenfengyuan/vue-qrcode'
Vue.component(VueQrcode.name, VueQrcode)

import VueBarcode from 'vue-barcode'
Vue.component('barcode', VueBarcode)

import VueHtmlToPaper from 'vue-html-to-paper'

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

const options = {
    name: '_blank',
    specs: [
        'fullscreen=no',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: ['labels.css', './css/html_to_paper.css']
}

// Cookies!
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.use(VueHtmlToPaper, options);

// Enable plugins.
Vue.use(Buefy)
Vue.use(VueAxios, axios)

import ContentPanel from '@/components/tools/ContentPanel'
// import InstructionDownloader from '@/components/tools/InstructionDownloader'
Vue.component('ContentPanel', ContentPanel)
// Vue.component('InstructionDownloader', InstructionDownloader)

// Translator helper for easier translations. This adds global t() function for translation.
import TranslatorHelper from './mixins/global/translator'
Vue.mixin(TranslatorHelper)

Vue.config.productionTip = false
new Vue({ router, store, render: h => h(App) }).$mount('#app')

