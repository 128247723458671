import okoliceCiala from "@/assets/okoliceCiala.json";

export default {

  data: () => {
    return {

      mBodyAreas: JSON.parse(JSON.stringify(okoliceCiala)),
      /**Statusy wizyty RIS
       * RIS appointment status
       */
      mRisStatuses: [
        {
          id: 0,
          name: "Rezerwacja"
        },
        {
          id: 1,
          name: "Gotowe do wykonania"
        },
        {
          id: 2,
          name: "Wykonano"
        },
        {
          id: 3,
          name: "Gotowe do opisu"
        },
        {
          id: 4,
          name: "Wersja robocza"
        },
        {
          id: 5,
          name: "Opisano"
        },
        {
          id: 6,
          name: "Wypalono"
        },
        {
          id: 7,
          name: "Odebrano"
        },
        {
          id: 8,
          name: "Opisano - po pierwszej ocenie"
        },
        {
          id: 9,
          name: "Opisano - po drugiej ocenie"
        },
        {
          id: 10,
          name: "Wersja robocza - drugi opis"
        },
        {
          id: 11,
          name: "Anulowano opis"
        },
        {
          id: 12,
          name: "Anulowano opis - pierwsza ocena"
        },
        {
          id: 13,
          name: "Anulowano opis - druga ocena"
        },
        {
          id: 14,
          name: "Poprawiono opis"
        },
        {
          id: 15,
          name: "Poprawiono opis - pierwsza ocena"
        },
        {
          id: 16,
          name: "Poprawiono opis - druga ocena"
        },
        {
          id: 99,
          name: "Anulowano zlecenie"
        },
      ],
      /**RIS events */
      mRisEvents: [
        { id: 0, name: 'Created', namePL: "Wysłano do urządzenia" },
        { id: 1, name: 'Collected', namePL: "Pobrana" },
        { id: 2, name: 'PackedInContainer', namePL: "Spakowane" },
        { id: 3, name: 'AwaitingRetrival', namePL: "Oczekuje na wysyłkę" },
        { id: 5, name: 'InTransferNotificationSent', namePL: "W drodze, wysłano powiadomienie" },
        { id: 8, name: 'Described1stGrade', namePL: "Pierwszy opis - badanie SIMP" },
        { id: 9, name: 'Described2ndGrade', namePL: "Drugi opis - badanie SIMP" },
        { id: 10, name: 'RisReadyToSend', namePL: "Gotowe do wysłania" },
        { id: 11, name: 'RisExaminationDone', namePL: "Badanie ukończone" },
        { id: 12, name: 'RisReadyForDescription', namePL: "Gotowe do opisu" },
        { id: 13, name: 'RisCancelled', namePL: "Badanie anulowane" },
        { id: 20, name: 'ReceivedLab', namePL: "Odebrana" },
        { id: 21, name: 'ReceivedRescreening', namePL: "Odebrana (Rescreening)" },
        { id: 22, name: 'ReceivedExternalDiagnostics', namePL: "????" },
        { id: 23, name: 'ReadyForAnalysis', namePL: "Gotowa do analizy" },
        { id: 40, name: 'DistributionOnStripOpen', namePL: "W otwartym statywie" },
        { id: 41, name: 'DistributionOnStripClosed', namePL: "W zamkniętym statywie" },
        { id: 42, name: 'DistributionTakenOffStrip', namePL: "Poza statywem" },
        { id: 50, name: 'PreparedCovidOnBoard', namePL: "????" },
        { id: 51, name: 'PreparedCovidOnBoard', namePL: "????" },
        { id: 62, name: 'PreparedCytoStaining', namePL: "Barwiona" },
        { id: 80, name: 'AnalysisCovid', namePL: "Analizowana" },
        { id: 90, name: 'PreparedCovidOnBoard', namePL: "????" },
        { id: 91, name: 'PreparedCovidTakenOffBoard', namePL: "????" },
        { id: 100, name: 'ReadyForDescription', namePL: "Gotowa do zatwierdzenia" },
        { id: 119, name: 'Described', namePL: "Nieistotne" },
        { id: 120, name: 'Described', namePL: "Opis - wersja robocza" },
        { id: 121, name: 'Accepted', namePL: "Opisano" },
        { id: 122, name: 'Authorized', namePL: "Nieistotne" },
        { id: 123, name: 'Signed', namePL: "Podpisane" },
        { id: 124, name: 'UnSigned', namePL: "Podpis cofnięty" },
        { id: 125, name: 'SignedExternal', namePL: "Wynik autoryzowany poza systemem" },
        { id: 126, name: 'DescribedRescreeningLowRiskDraft', namePL: "Nieistotne" },
        { id: 127, name: 'DescribedRescreeningHighRiskDraft', namePL: "Nieistotne" },
        { id: 128, name: 'DescribedRescreeningLowRisk', namePL: "Nieistotne" },
        { id: 129, name: 'DescribedRescreeningHighRisk', namePL: "Nieistotne" },
        { id: 130, name: 'Published', namePL: "Opublikowany" },
        { id: 131, name: 'ResultPrintedByWorker', namePL: "Wydrukowany przez pracownika" },
        { id: 132, name: 'ResultDownloadedByWorker', namePL: "Pobrany przez pracownika" },
        { id: 133, name: 'ReceivedByPatient', namePL: "Otrzymany przez pacjenta" },
        { id: 134, name: 'IssuedByWorker', namePL: "Wydany przez pracownika" },
        { id: 135, name: 'ResultBurnedOnDisc', namePL: "Nagrano na płytce" },
        { id: 136, name: 'ResultOnDiscDeliveredToFacility', namePL: "Płyta dostarczona" },
        { id: 137, name: 'DescriptionPrinted', namePL: "Opis wydrukowany" },
        { id: 138, name: 'ResultBurnedOnDiscInExternalSystem', namePL: "Oznaczono płytkę jako wypaloną" },
        { id: 139, name: 'ResultCancelled', namePL: "Opis odwołany" },
        { id: 140, name: 'ResultRedescribed', namePL: "Opis poprawiony" },
        { id: 141, name: 'EDMPrinted', namePL: "EDM wydrukowany" },
        { id: 142, name: 'ResultDownloadedByPatient', namePL: "Opis wydrukowany przez pacjenta" },
        { id: 143, name: 'PatientInformed', namePL: "Poinformowano pacjenta o wyniku" },
        { id: 144, name: 'ResultBurnedOnDiscIssued', namePL: "Wydano płytkę pacjentowi" },
        { id: 150, name: 'FirstDescriptionReverted', namePL: "Odwołoano pierwszy opis - badanie SIMP" },
        { id: 151, name: 'SecondDescriptionReverted', namePL: "Odwołoano drugi opis - badanie SIMP" },
        { id: 152, name: 'FirstDescriptionCorrection', namePL: "Poprawiono pierwszy opis - badanie SIMP" },
        { id: 153, name: 'SecondDescriptionCorrection', namePL: "Poprawiono drugi opis - badanie SIMP" },
        { id: 154, name: 'BlockDescription', namePL: "Zablokowano opis badania" },
        { id: 155, name: 'BlockResultIssue', namePL: "Zablokowano wydanie badania" },
        { id: 156, name: 'UnblockExamination', namePL: "Odblokowano badanie" },
        { id: 157, name: 'DecisionWhichDescriptionToIssue', namePL: "Podjęto decyzję o wyniku" },
        { id: 158, name: 'ChangeDescriptionIndended', namePL: "Zmieniono wybranego opisującego" },
      ],

      mRisEventsIcons: [
        { id: 0, icon: "forward", type: "is-primary" },
        { id: 11, icon: "send-check-outline", type: "is-success" },
        { id: 12, icon: "clipboard-outline", type: "is-success" },
        { id: 120, icon: "pen", type: "is-danger" },
        { id: 121, icon: "pen", type: "is-success" },
        { id: 125, icon: "pen", type: "is-danger" },
        { id: 8, icon: "pen", type: "is-warning" },
        { id: 9, icon: "pen", type: "is-success" },
        { id: 131, icon: "printer", type: "is-primary" },
        { id: 132, icon: "download", type: "is-orange" },
        { id: 134, icon: "file-send", type: "is-success" },
        { id: 135, icon: "content-save", type: "is-purple" },
        { id: 136, icon: "package-variant", type: "is-orange" },
        { id: 137, icon: "printer", type: "is-primary" },
        { id: 138, icon: "content-save-edit", type: "is-purple" },
        { id: 144, icon: "content-save-move", type: "is-success" },
        { id: 3, icon: "truck-outline", type: "is-primary" },
        { id: 154, icon: "lock", type: "is-danger" },
        { id: 155, icon: "lock", type: "is-orange" },
        { id: 156, icon: "lock-open-variant-outline", type: "is-success" },
        { id: 157, icon: "check-all", type: "is-success" },
        { id: 158, icon: "account-edit-outline", type: "is-primary" }
      ],


      mTypesRiSClinics: [
        7210, 7211, 7220, 7221, 7222, 7223, 7240, 7241, 7242, 7244, 7245, 7250, 7251,
        7912, 7913, 7950, 7951,
      ],
      /**Rodzaje badań RIS
       * Types of RIS examinations
       */
      mRisExamination: [
        {
          id: 1,
          name: "Tomografia komputerowa",
          device: "Tomograf komputerowy",
          nameShort: "TK",
          modality: ["CT"], //from mModality,
          isDesciptionAllowed: true
        },
        {
          id: 2,
          name: "Rentgenografia",
          device: "RTG",
          nameShort: "RTG",
          modality: ["CR", "DX"], //from mModality
          isDesciptionAllowed: true
        },
        {
          id: 3,
          name: "Ultrasonografia",
          device: "Ultrasonograf",
          nameShort: "USG",
          modality: ["US"], //from mModality
          isDesciptionAllowed: true
        },
        {
          id: 4,
          name: "Rezonans magnetyczny",
          device: "Aparat do rezonansu magnetycznego",
          nameShort: "RM",
          modality: ["MR"], //from mModality
          isDesciptionAllowed: true
        },
        {
          id: 5,
          name: "Mammografia",
          device: "Mammograf",
          nameShort: "MGF",
          modality: ["MG"], //from mModality,
          isDesciptionAllowed: true
        },
        {
          id: 6,
          name: "Ultrasonografia 3D",
          device: "Ultrasonograf 3D",
          nameShort: "USG-3D",
          modality: ["US"], //from mModality
          isDesciptionAllowed: true
        },
        {
          id: 7,
          name: "Densytometria",
          device: "Densytometr",
          nameShort: "Dens",
          modality: ["BMD"], //from mModality
          isDesciptionAllowed: false
        },
        {
          id: 8,
          name: "Scyntygrafia",
          device: "",
          nameShort: "Scyntygrafia",
          modality: ["NM"], //from mModality
          isDesciptionAllowed: true
        },
        {
          id: 9,
          name: "Pozytonowa tomografia emisyjna",
          device: "",
          nameShort: "PET",
          modality: ["PT"], //from mModality
          isDesciptionAllowed: true
        },
        {
          id: 10,
          name: "Tomografia OCT",
          device: "Tomograf OCT",
          nameShort: "TK-OCT",
          modality: ["OPT"], //from mModality
          isDesciptionAllowed: true
        },
        {
          id: 11,
          name: "Angiografia oka",
          device: "Aparat do angiografii oka",
          nameShort: "ANGIO-OKA",
          modality: ["OP"], //from mModality
          isDesciptionAllowed: true
        },
        {
          id: 12,
          name: "Termografia",
          device: "Aparat do termografii",
          nameShort: "Termografia",
          modality: ["TG"], //from mModality
          isDesciptionAllowed: true
        },
        {
          id: 99,
          name: "Inne",
          device: "",
          nameShort: "Inne",
          modality: [],
          isDesciptionAllowed: false
        }
      ],
      //when add, add to examination type to mRisExamination
      mModality: [
        "CT",//TK
        "MG",//Mammografia
        "CR",//RTG
        "DX",//RTG
        "BMD", //Densytometria
        "MR", //Rezonans magnetyczny
        "NM", //Scyntygrafia
        "OP", //Angiografia oka
        "OPT", //TK OCT
        "PT", //PET
        "TG",//Termografia
        "US",//USG
      ],

      /**
       * Słowniki USG
       */
      
      mDictionarioUSG: [
        {
          id: 0,
          category: "USG jamy brzusznej",
          name: "Wątroba prawidłowej wielkości, konturów i echogeniczności. W miąższu wątroby nie znaleziono zmian ogniskowych. Pęcherzyk żółciowy gładkościenny, prawidłowej wielkości, bez kamicy. Drogi żółciowe wewnątrz- i zewnątrzwątrobowe nieposzerzone. Trzustka niepowiększona, jednorodna. Śledziona bez zmian. Nerki prawidłowej wielkości i echogeniczności, bez cech zastoju i kamicy. Grubość warstwy miąższowej obu nerek prawidłowa. Pęcherz moczowy prawidłowej wielkości, gładkościenny, bez odbić wewnętrznych. Aorta nieposzerzona. Okolica okołoaortalna i pola nadnerczowe bez widocznych nieprawidłowości. Nie stwierdza się płynu w jamie brzusznej."
        },
        {
          id: 1,
          category: "USG jamy brzusznej 2",
          name: "Wątroba nieco powiększona, o zaokrąglonym dolnym brzegu i podwyższonej jednorodnie echogeniczności. W miąższu wątroby nie znaleziono zmian ogniskowych. Pęcherzyk żółciowy gładkościenny, prawidłowej wielkości, bez kamicy. Drogi żółciowe wewnątrz- i zewnątrzwątrobowe nieposzerzone. Trzustka niepowiększona, jednorodna. Śledziona bez zmian. Nerki prawidłowej wielkości i echogeniczności, bez cech zastoju i kamicy. Grubość warstwy miąższowej obu nerek prawidłowa. Pęcherz moczowy prawidłowej wielkości, gładkościenny, bez odbić wewnętrznych. Aorta nieposzerzona. Okolica okołoaortalna i pola nadnerczowe bez widocznych nieprawidłowości. Nie stwierdza się płynu w jamie brzusznej."
        },
        {
          id: 2,
          category: "USG jąder",
          name: "Jądra prawidłowej wielkości, echogeniczności i unaczynienia. Nie znaleziono zmian ogniskowych. Najądrza prawidłowej wielkości i echogeniczności. W głowie prawego najądrza znaleziono torbielke średnicy  mm. Nie znaleziono płynu w worku mosznowym ani innych nieprawidłowości."
        },
        {
          id: 3,
          category: "USG sutków",
          name: "Sutki o utkaniu tłuszczowo-gruczołowym. Nie znaleziono zmian ogniskowych podejrzanych o złośliwość. Przewody mlekowe nieposzerzone. Węzły chłonne pachowe niepowiększone."
        },
        {
          id: 4,
          category: "USG tarczycy",
          name: "Tarczyca położona w miejscu typowym, prawidłowej wielkości i echogeniczności. Wymiary: prawy płat  mm w wymiarze podłużnym, poprzecznym i a-p, lewy płat  mm w wymiarze podłużnym, poprzecznym i a-p, cieśń  mm w wymiarze a-p. Nie znaleziono guzków. Unaczynienie gruczołu prawidłowe. Ślinianki podżuchwowe i przyusznice bez zmian. Nie znaleziono powiększonych węzłów chłonnych wzdłuż naczyń szyjnych."
        },
        {
          id: 5,
          category: "USG tarczycy AZT",
          name: "Tarczyca położona w miejscu typowym, niepowiększona, o niejednorodnej echogeniczności i echostrukturze, wzorzec odbić pseudoguzkowy. Unaczynienie narządu wzmożone. Wymiary: prawy płat  mm w wymiarze podłużnym, poprzecznym i a-p, lewy płat  mm w wymiarze podłużnym, poprzecznym i a-p, cieśń  mm w wymiarze a-p. Nie znaleziono powiększonych węzłów chłonnych wzdłuż naczyń szyjnych. Poniżej dolnych biegunów obu płatów tarczycy i na wysokości cieśni znaleziono nieliczne, owalne węzły chłonne, które w osi długiej mierzą do 10 mm, o charakterze odczynowym. Ślinianki podżuchwowe i przyusznice bez zmian."
        }
      ],

      /**Słowniki TK
       * TK dictionaries
       */
      mDictionarioTK: [
        {
          id: 0,
          category: "Angio aorty brzusznej",
          options: [
            {
              id: 0,
              name: "Aorta brzuszna z uwapnionymi blaszkami miażdzycowymi - w nadbrzuszu szerokości 24mm ze skrzepliną okrężną ok 2mm ,- poniżej naczyń nerkowych szerokość aorty 17mm, skrzepliny przyścienne ok 4mm"
            },
            {
              id: 1,
              name: "Tętnice nerkowe obustronnie prawidłowe, drożne bez zwężeń - Lewa tętnica biodrowa 8mm ze stentgraftem - wykrzepionym, - brak zakontrastowania, - Lewa tętnica biodrowa zewnętrzna niedrożna, Lewa t.biodrowa wewnętrzna - odcinkowe znaczne przewężenie do 80%"
            },
            {
              id: 2,
              name: " Prawa t.biodrowa wspólna drożna szerokości 10mm, na odcinku ok 2cm w środkowej części t.biodrowej zewnętrznej rozwarstwienie. Prawa tętnica biodrowa wewnętrzna - odcinkowo niedrożna zwężona do 60-80%"
            }]
        },
        {
          id: 1,
          category: "Angio wersja 2",
          options: [
            {
              id: 0,
              name: "Zmiany miażdżycowe aorty i naczyń biodrowych i tt kończyn dolnych z uwapnionymi blaszkami miażdzycowymi. Aorta w nadbrzuszu szerokości 24mm, tt nerkowe obustronnie drożne bez zwężeń. Tętniak aorty brzusznej poniżej naczyń nerkowych szerokości 41mm x 44mm długość 60mm, skrzeplina przyścienna 19mm, kanał przepływu szerokości 29mm. Prawa t.biodrowa 22mm, lewa 17mm, odcinkowe skrzepliny wpuklające się do światła nie powodujące istotnych zwężeń, - uwapnione zmiany miażdżycowe tętnicy biodrowej lewej i prawej powodują przewężenia do 50-60%. Poza tym TT biodrowe po podziale drożne.",
              conclusion: ""
            }]
        },
        {
          id: 2,
          category: "Tomografia komputerowa",
          options: [
            {
              id: 0,
              name: "Badanie wykonano po dożylnym podaniu środka kontrastowego. Aorta brzuszna drożna, nieposzerzona, z obecnością zmian miażdżycowych niepowodujących istotnych zwężeń. Uwapnione, obwodowe zmiany miażdżycowe lewej t. biodrowej wspólnej nie powodują istotnych zwężeń. Zmiany miażdżycowe prawej t. biodrowej wspólnej powodują 50-60% zwężenia. Zmiany miażdżycowe tt. biodrowych zewnętrznych nie powodują istotnych zwężeń. Tt. biodrowe wewnętrzne drożne, z obecnością wielopoziomowych zmian miażdżycowych. Początkowy odcinek pnia trzewnego na dług. ok. 5-6mm, najprawdopodobniej krytycznie zwężony. Nasilone zmiany miażdżycowe tt. nerkowych.",
              conclusion: ""
            },
            {
              id: 1,
              name: "Uwidoczniono pojedyncze tętnice nerkowe odchodzą od aorty w miejscu typowym. Oba naczynia w odcinkach szerokość tętnicy prawej 6mm, lewej 6.9mm, nie uwidoczniono zwężeń. Nie uwidoczniono zmian miażdżycowych w aorcie w okolicy odejścia naczyń i tętnicach nerkowych. Pozostałe naczynia odchodzące od aorty bez zmian. Nie uwidoczniono zmian ogniskowych narządów jamy brzusznej objętych badaniem.",
              conclusion: ""
            }]
        },
        {
          id: 3,
          category: "Angio TK aorty brzusznej",
          options: [
            {
              id: 0,
              name: "Badanie wykonano po dożylnym podaniu środka kontrastowego. Aorta brzuszna drożna, nieposzerzona, z obecnością zmian miażdżycowych niepowodujących istotnych zwężeń. Uwapnione, obwodowe zmiany miażdżycowe lewej t. biodrowej wspólnej nie powodują istotnych zwężeń. Zmiany miażdżycowe prawej t. biodrowej wspólnej powodują 50-60% zwężenia. Zmiany miażdżycowe tt. biodrowych zewnętrznych nie powodują istotnych zwężeń. Tt. biodrowe wewnętrzne drożne, z obecnością wielopoziomowych zmian miażdżycowych. Początkowy odcinek pnia trzewnego na dług. ok. 5-6mm najprawdopodobniej krytycznie zwężony. Nasilone zmiany miażdżycowe tt. Nerkowych."
            }]
        },
        {
          id: 4,
          category: "Angio-TK, tt. kończyn dolnych",
          options: [
            {
              id: 0,
              name: "Badanie wykonano po dożylnym podaniu środka kontrastowego. Strona lewa: - uwapnione zmiany miażdżycowe t. udowej powodują wielopoziomowe ok. 50-60 % zwężenia, - t. głęboka uda drożna, - zaawansowane zmiany miażdżycowe t. udowej powierzchownej powodują wielopoziomowe istotne zwężenia do krytycznego włącznie, - t. podkolanowa z obecnością wielopoziomowych zwężeń do ok. 80-90% - pień piszczelowo-strzałkowy, tętnica: piszczelowa przednia, piszczelowa tylna, strzałkowa oraz grzbietowa stopy drożne. Strona prawa: - uwapnione zmiany miażdżycowe t. udowej powodują wielopoziomowe ok. 50-60 % zwężenia, - t. głęboka uda drożna, - zaawansowane zmiany miażdżycowe t. udowej powierzchownej powodują wielopoziomowe istotne zwężenia do 70-80% - t. podkolanowa z obecnością wielopoziomowych zwężeń do ok. 80 % - pień piszczelowo-strzałkowy, tętnica : piszczelowa przednia, piszczelowa tylna, strzałkowa oraz grzbietowa stopy drożne.",
              conclusion: "Wnioski: zaawansowane, wielopoziomowe zmiany miażdżycowe tętnic kończyn dolnych najbardziej nasilone w obrębie tt. udowych powierzchownych i podkolanowych. Rekonstrukcje grubymi warstwami 2,5 mm utrudniają dokładną ocenę stopnia zwężenia."
            }]
        },
        {
          id: 5,
          category: "Angio tk tętnic mózgowych",
          options: [
            {
              id: 0,
              name: "Angiogramy mózgowe prawidłowe bez cech zwężeń i tętniakowatego poszerzenia oraz bez cech malformacji naczyniowych. Wypełnienie tętnic w kole tętniczym symetrycznie prawidłowe. Morfologia uwidocznionych tętnic obwodowych prawidłowa. Spływ żylny prawidłowej szerokości, bez ubytków wypełnienia i cech zakrzepicy.",
              conslusion: ""
            }]
        },
        {
          id: 6,
          category: "Tk jamy brzusznej wykonane wielofazowo",
          options: [
            {
              id: 0,
              name: "Wątroba niepowiększona, o prawidłowej gęstości, bez zmian ogniskowych. Drogi żółciowe wewnątrz- i zewnątrzwątrobowe nieposzerzone. pęcherzyk żółciowy cienkościenny bez złogów. Trzustka niepowiększona, bez zmian ogniskowych. Przewód Wirsunga nieposzerzony. Śledziona prawidłowej wielkosci, jednorodna bez zmian ogniskowych. Nadnercza o prawidłowej wielkości i morfologii, bez zmian ogniskowych. Nerki położone typowo, prawidłowej wielkości, bez nieprawidłowych zmian. Obie nerki ulegają jednoczasowemu wzmocnieniu kontrastowemu. Układy kielichowo-miedniczkowe obustronnie nieposzerzone, bez widocznych uwapnionych złogów. Węzły chłonne trzewne i zaotrzewnowe niepowiększone. Aorta brzuszna drożna, nieposzerzona. Cech wolnego płynu nie uwidoczniono.",
              conslusion: ""
            },
            {
              id: 1,
              name: "Powłoki brzuszne bez cech przerwania ciągłości. Narządy miąższowe bez zmian pourazowych. Cech wolnego płynu nie uwidoczniono. Wątroba niepowiększona, o prawidłowej gęstości, bez zmian ogniskowych. Drogi żółciowe wewnątrz- i zewnątrzwątrobowe nieposzerzone. Trzustka niepowiększona, bez zmian ogniskowych. Przewód Wirsunga nieposzerzony. Śledziona ze ścisłym przyleganiem torebki, jednorodna. Nadnercza o prawidłowej wielkości i morfologii, bez zmian ogniskowych. Nerki położone typowo, prawidłowej wielkości, bez nieprawidłowych zmian. Obie nerki ulegają jednoczasowemu wzmocnieniu kontrastowemu. Układy kielichowo-miedniczkowe obustronnie nieposzerzone, bez widocznych uwapnionych złogów. Węzły chłonne trzewne i zaotrzewnowe niepowiększone. Aorta brzuszna drożna, nieposzerzona.",
              conslusion: ""
            },
            {
              id: 2,
              name: "Wątroba niepowiększona, o prawidłowej  gęstości, bez widocznych zmian ogniskowych dostępnych ocenie w badaniu bez kontrastu. Drogi żółciowe wewnątrz- i zewnątrzwątrobowe nieposzerzone. Pęcherzyk żółciowy cienkościenny bez złogów. Trzustka niepowiększona, bez widocznych zmian dostępnych ocenie w badaniu bez kontrastu. Przewód Wirsunga nieposzerzony. Śledziona o prawidłowych wymiarach, w normie. Nadnercza o prawidłowej wielkości i morfologii, bez zmian ogniskowych. Nerki położone typowo, prawidłowej wielkości, w lewej nerce dwa złogi o  wym. do 3mm  w dolnych kielichach, bez cech poszerzenia ukm. Węzły chłonne trzewne i zaotrzewnowe niepowiększone. Aorta brzuszna nieposzerzona.Pęcherz moczowy bez uwapnionych złogów. ",
              conslusion: ""
            },
            {
              id: 3,
              name: "Nie stwierdza się wolego płynu w jamie otrzewnej. Wątroba niepowiększona, o prawidłowej gęstości, w seg 4 tuż przy więzadle sierpowatym hipodensyjny obszar 14x13mm - w badaniu bez kontrastu - morfologia zmiany nie do oceny. Drogi żółciowe wewnątrz- i zewnątrzwątrobowenieposzerzone. Pęcherzyk żółciowy słabo widoczny cienkościenny bez złogów. Trzustka niepowiększona, bez widocznych zmian dostępnych ocenie w badaniu bez kontrastu. Przewód Wirsunga nieposzerzony. Śledziona o prawidłowych wymiarach, - nie do oceny bez kontrastu. Nerki położone typowo, prawidłowej wielkości - poza tym nie do oceny bez kontrastu. Pojedynczy złóg o wym. do 3mm w dolnym kielichu prawej nerki bez cech poszerzenia ukm. Węzły chłonne trzewne i zaotrzewnowe niepowiększone. Aorta brzuszna nieposzerzona.",
              conslusion: ""
            },
          ]
        },
        {
          id: 7,
          category: "Tk klatki piersiowej dwufazowo",
          options: [
            {
              id: 0,
              name: "Uwidoczniony układ kostno-mięśniowy ścian klatki piersiowej bez zmian pourazowych. Bez cech rozedmy podskórnej. Struktury śródpiersia, duże naczynia klatki piersiowej prawidłowe bez cech przerwania ciągłości i wynaczynienia. Odmy opłucnowej, osierdziowej i śródpiersiowej nie uwidoczniono. Oskrzela drożne, bez cech zwężeń i przerwania ciągłości. Upowietrznienie płuc prawidłowe bez zmian ogniskowych i pourazowych. Jamy opłucnowe bez cech płynu.",
              conslusion: ""
            }]
        },
        {
          id: 8,
          category: "Tk klatki piersiowej",
          options: [
            {
              id: 0,
              name: "Upowietrznienie miąższu płuc prawidłowe. Nie stwierdza się zmian zapalnych i ognisk patologicznego gromadzenia środka cieniującego. Struktury śródpiersia oraz duże naczynia klatki piersiowej prawidłowe. Nie uwidoczniono powiększonych węzłów chłonnych wnęk płucnych oraz śródpiersia. Oskrzela drożne, bez cech zwężeń. Jamy opłucnowe bez cech płynu. Ściany klatki piersiowej bez widocznej patologii. Bruzda tchawiczo-przełykowa bez zmian ogniskowych.",
              conslusion: ""
            }]
        },
        {
          id: 9,
          category: "Tk krtani dwufazowo",
          options: [
            {
              id: 0,
              name: "Wolny brzeg nagłośni prawidłowy. Zachyłki gruszkowate symetryczne. Struktury głośni i okolica podgłośniowa w obrazie TK-prawidłowe. Chrząstki krtani uwapnione symetrycznie, bez cech destrukcji. Struktury okołokrtaniowe bez zmian ogniskowych.",
              conslusion: ""
            }]
        },
        {
          id: 10,
          category: "Tk miednicy mniejszej wielofazowo",
          options: [
            {
              id: 0,
              name: "Pęcherz moczowy gładkościenny. Płaszczyzny tłuszczowe zachowane prawidłowo. Lokalnej limphadenopatii nieuwidoczniono. Pętle jelitowe bez zmian ogniskowych.",
              conslusion: ""
            },
            {
              id: 1,
              name: "Pęcherz moczowy gładkościenny. Pozostałe narządy miednicy mniejszej o prawidłowej morfologii bez zmian ogniskowych i pourazowych. Płaszczyzny tłuszczowe zachowane prawidłowo. Lokalnej limphadenopatii nieuwidoczniono. Pętle jelitowe bez zmian ogniskowych. Układ kostny miednicy bez zmian pourazowych. Zlecono dożylne podanie środka cieniującego.",
              conslusion: ""
            }
          ]
        },
        {
          id: 11,
          category: "Tk odcinka mięśniowego kręgosłupa",
          options: [
            {
              id: 0,
              name: "Kyfoza piersiowa zachowana prawidłowo. Linie kręgosłupowe równe, bez załamań. Uwidocznione struktury kostne bez zmian ogniskowych i przebudowy kostnej. Wysokość trzonów kręgowych prawidłowa. Krążki międzykręgowe prawidłowej wysokości, bez cech dyskopatii. Struktury wewnątrzkanałowe niezmienione.",
              conslusion: ""
            },
            {
              id: 1,
              name: "Odcinek piersiowy kręgosłupa bez świeżych zmian pourazowych. Wysokość trzonów kręgowych i tarcz międzykręgowych prawidłowa. Linie kręgosłupowe zachowane prawidłowo. Struktury wewnątrzkanałowe niezmienione. Badanie TK odcinka lędźwiowego kręgosłupa - traumascan.",
              conslusion: ""
            },
            {
              id: 2,
              name: "Odcinek lędźwiowy kręgosłupa bez świeżych zmian pourazowych. Wysokość trzonów kręgowych i tarcz międzykręgowych prawidłowa. Linie kręgosłupowe zachowane prawidłowo. Struktury wewnątrzkanałowe niezmienione.",
              conslusion: ""
            }
          ]
        },
        {
          id: 12,
          category: "Tk odcinka szyjnego kręgosłupa",
          options: [
            {
              id: 0,
              name: "Kompleks szczytowo-potyliczny i odcinek szyjny kręgosłupa bez świeżych zmian pourazowych. Linie kręgosłupowe zachowane prawidłowo. Struktury wewnątrzkanałowe niezmienione. Wysokość trzonów kręgowych i tarcz międzykręgowych zachowana prawidłowo.",
              conslusion: ""
            }
          ]
        },
        {
          id: 13,
          category: "Tk twarzoczaszki",
          options: [
            {
              id: 0,
              name: "Uwidocznione struktury kostne twarzoczaszki bez uchwytnych cech świeżych zmian pourazowych. Zatoki oboczne nosa prawidłowo powietrzne, bez poziomów płynu. Przegroda nosa skrzywiona na prawą stronę z kolcem kostnym. Obrysy oczodołów gładkie. Zawartość oczodołów bez cech zmian ogniskowych i pourazowych.",
              conslusion: ""
            },
            {
              id: 1,
              name: "Uwidocznione struktury kostne twarzoczaszki bez uchwytnych cech świeżych zmian pourazowych. Zatoki oboczne nosa prawidłowo powietrzne, bez poziomów płynu. Przegroda nosa położona pośrodkowo bez cech destrukcji. Obrysy oczodołów gładkie. Zawartość oczodołów bez cech zmian ogniskowych i pourazowych.",
              conslusion: ""
            }
          ]
        },
        {
          id: 14,
          category: "HRCT",
          options: [
            {
              id: 0,
              name: "Pola płucne równomiernie upowietrznione. Zmian restrykcyjnych w postaci ognisk pozapalnych i rozedmowych nie uwidoczniono. Oskrzela pierwszorzędowe i drugorzędowe prawidłowej szerokości, bez pogrubienia ścian i zagęszczeń okołooskrzelowych. W wykonanym badaniu nie uwidoczniono zmian ogniskowych i struktur mogących nasuwać podejrzenie zmian o charakterze rozrostowym.",
              conslusion: ""
            }
          ]
        },
        {
          id: 15,
          category: "Krtań",
          options: [
            {
              id: 0,
              name: "Pogrubienie asymetryczne mięśnia głosowego i fałdu głosowego prawego do 12mm na długości ok 30mm, w obrębie krtani zwężające przedsionek krtani. Pozostałe struktury krtani bez zmian podejrzanych symetryczne: fałdy nalewkowo nagłośniowe, zachyłki- symetryczne, struktury gardła i nosogardła bez istotnych zmian. Liczne węzły chłonne podżuchwowe o wym. do 12mm, ale z zatokami tłuszczowymi, węzły chłonne głębokie szyi i górne prawe do 8x5mm i 10x6mm po prawej stronie. Zatoki szczękowe klinowe i czołowe, a także sitowie prawidłowo powietrzne.",
              conslusion: ""
            },
            {
              id: 1,
              name: "Struktury tylnego dołu czaszki bez zmian ogniskowych. Układ komorowy nieposzerzony. Struktury linii środkowej bez przemieszczeń. Zanik korowy półkul mózgu wyrażony poszerzeniem płynowych przestrzeni przymózgowych. Nie stwierdza się cech krwawienia wewnątrzczaszkowego. Kości czaszki bez zmian ogniskowych i pourazowych. Niewielka ilość gęstej wydzieliny w zatoce klinowej.",
              conslusion: ""
            }
          ]
        },
        {
          id: 16,
          category: "Tk angio TT szyjnych",
          options: [
            {
              id: 0,
              name: "Niedrożność prawej t.kręgowej od odejścia od t.podobojczykowej prawej na odcinku V4, V3, V2, wąska ale zakontrastowana w odcinku V1. Poza tym w zakresie uwidocznionym aorta piersiowa, pień ramienno-głowowy, obustronnie TT podobojczykowe, T kręgowa lewa, TT szyjne wspólne wewnętrzne i zewnętrzne obustronnie drożne. W zakresie uwidocznionym TT wewnątrzczaszkowe bez rozwarstwień i innych patologii dostęnych ocenie.",
              conslusion: ""
            }
          ]
        },
        {
          id: 17,
          category: "TK głowy",
          options: [
            {
              id: 0,
              name: "Badanie złe technicznie duże artefakty - nie można jednoznacznie określić czy występuje krwawienie! Struktury mózgu i móżdżku nieprzemieszczone bez zmian ogniskowych. Układ komorowy nieposzerzony bez zniekształceń. Zbiorniki podstawy i płynowe przestrzenie przymózgowe w normie [ale ocena niepełna  z powodu artefaktów]. Kości pokrywy i podstawy czaszki bez zmian pourazowych. W wykonanym badaniu w zakresie możliwym do oceny nie uwidoczniono cech krwawienia śródczaszkowego.",
              conslusion: ""
            },
            {
              id: 1,
              name: "Mózg oraz struktury tylnego dołu czaszki bez zmian ogniskowych. Układ komorowy nieposzerzony. Struktury linii środkowej bez przemieszczeń. Przestrzenie płynowe przymózgowe prawidłowe. Nie stwierdza się cech krwawienia wewnątrzczaszkowego. Kości czaszki objęte badaniem bez widocznych zmian urazowych.",
              conslusion: ""
            },
            {
              id: 2,
              name: "Nie stwierdza się wynaczynionej krwi śródczaszkowo. Gęstość tkankowa istoty białej i szarej mózgu prawidłowa. Przestrzenie płynowe wewnątrzczaszkowe nieposzerzone, symetryczne. Struktury środkowe nieprzemieszczone. Układ komorowy nadnamiotowy nieposzerzony, symetryczny, nieprzemieszczony. Wodociąg mózgu i komora IV nieposzerzone. Struktury tylnojamowe (pień mózgu, móżdżek) bez uchwytnych patologii.",
              conslusion: ""
            },
            {
              id: 3,
              name: "Struktury mózgowia bez zmian ogniskowych. Układ komorowy symetryczny poszerzony nieprzemieszczony, cechy zaników korowych, płynowe przestrzenie przymózgowe poszerzone, nad płatami czołowymi i w bruzdach bocznych. Nie stwierdza się cech krwawienia śródmózgowego. Prawidłowe kości czaszki, zatoki przynosowe i podstawa czaszki objęte badaniem.",
              conclusion: "",
            },
            {
              id: 4,
              name: "Gęstość substancji białej i szarej mózgu prawidłowa. Układ komorowy nadnamiotowy prawidłowej szerokości, nieprzemieszczony, symetryczny. Komora IV - struktury tylnojamowe prawidłowe, układ kostny zmian nie wykazuje. Wynaczynionej krwi w obrębie OUN nie stwierdza się.",
              conclusion: "",
            },
            {
              id: 5,
              name: "Ogniska hipodensyjne zlokalizowane w istocie białej okołokomorowej obu półkul mózgu, pojedyncze w moście odpowiadające różnoczasowym ogniskom naczyniopochodnym, niedokrwiennym. Miernego stopnia uogólniony zanik korowo-podkorowy wyrażony poszerzeniem przestrzeni płynowych. Układ komorowy nieposzerzony symetryczny, nieprzemieszczony. Móżdżek oraz pozostałe struktury tylnej jamy czaszki bez zmian ogniskowych. Nie uwidoczniono cech krwawienia śródczaszkowego. W badaniu TK nie można jednoznacznie ocenić nowych zmian poniedokrwiennych.",
              conclusion: "",
            },
            {
              id: 6,
              name: "Drobne ogniska hipodensyjne zlokalizowane w istocie białej okołokomorowej obu półkul mózgu, pojedyncze w moście odpowiadające różnoczasowym ogniskom naczyniopochodnym, niedokrwiennym. Układ komorowy symetryczny nieposzerzony, nieprzemieszczony, przestrzenie płynowe zachowane. Móżdżek oraz pozostałe struktury tylnej jamy czaszki bez zmian ogniskowych. Nie uwidoczniono cech krwawienia śródczaszkowego. Struktury kostne bez zmian pourazowych.",
              conclusion: "",
            },
            {
              id: 7,
              name: "Ogniska i obszary hipodensyjne różnoczasowe poniedokrwienne naczyniopochodne w istocie białej obu półkul mózgowych, leukomalacja okołokomorowa, układ komorowy poszerzony, symetryczny. Cechy zaników korowo-podkorowych. Poszerzone przestrzenie płynowe nad płatami czołowymi i w bruzdach bocznych. Nie stwierdza się cech krwawienia śródmózgowego.",
              conclusion: "",
            },
            {
              id: 8,
              name: "Struktury mózgu i móżdżku nieprzemieszczone bez zmian ogniskowych. Układ komorowy nieposzerzony bez zniekształceń. Zbiorniki podstawy i płynowe przestrzenie przymózgowe w normie. Kości podstawy i pokrywy czaszki bez zmian ogniskowych i pourazowych. Nie uwidoczniono cech krwawienia wewnątrzczaszkowego.",
              conclusion: "",
            },
            {
              id: 9,
              name: "Mózg oraz struktury tylnego dołu czaszki bez zmian ogniskowych. Układ komorowy nieposzerzony. Struktury linii środkowej bez przemieszczeń. Przestrzenie płynowe przymózgowe prawidłowe. Nie stwierdza się cech krwawienia wewnątrzczaszkowego. Kości czaszki objęte badaniem bez widocznych zmian urazowych.",
              conclusion: "",
            }
          ]
        },
        {
          id: 18,
          category: "TK jamy brzusznej i miednicy",
          options: [
            {
              id: 0,
              name: "Wątroba prawidłowej wielkości gładkich obrysach, bez widocznych zmian ogniskowych. Pęcherzyk żółciowy prawidłowej wielkości o gładkich ścianach, bez widocznych złogów uwapnionych. Śledziona prawidłowej wielkości, bez zmian patologicznych. Trzustka powiększona, obrzęknięta wzmacnia się po kontraście w ok. 80%, poza tym niewielkie obszary martwicy, pasma płynu i obrzęku wokół trzustki, a także obszary o podwyższonej gęstości tkanki tłuszczowej, płyn w zachyłku przednerkowym lewym do 28mm. Płyn w prawym zachyłku przednerkowym ok.48mm.",
              conclusion: "",
            },
            {
              id: 1,
              name: "Płyn w zachyłku przyokrężniczym prawym 18mm.",
              conclusion: "",
            },
            {
              id: 2,
              name: "OZT, 5 pkt w skali CTSI.",
              conclusion: "",
            },
            {
              id: 3,
              name: "Nerki ułożone anatomicznie o gładkich obrysach, układy kliechowo-miedniczkowe nieposzerzone. Naczynia przestrzeni zaotrzewnowej bez widocznych patologii. Nie uwidoczniono powiększonych węzłów chłonnych śród- i pozaotrzewnowych. Pętle jelitowe nieposzerzone, pęcherz moczowy dobrze wypełniony, o gładkich obrysach. Narządy miednicy mniejszej bez widocznej patologii. Węzły chłonne miednicy niepowiększone. TK piramid kości skroniowych bez kontrastu:",
              conclusion: "",
            },
            {
              id: 4,
              name: "Wątroba prawidłowej wielkości gładkich obrysach, bez widocznych zmian ogniskowych. Pęcherzyk żółciowy prawidłowej wielkości o gładkich ścianach, bez widocznych złogów uwapnionych. Śledziona prawidłowej wielkości, bez zmian patologicznych. Trzustka prawidłowej wielkości o niezmienionej strukturze miąższu. Nerki ułożone anatomicznie o gładkich obrysach, układy kliechowo-miedniczkowe nieposzerzone. Naczynia przezstrzeni zaotrzewnowej bez widocznych patologii. Nie uwidoczniono powiększonych węzłów chłonnych śród- i pozaotrzewnowych. Pętle jelitowe nieposzerzone, płaszczyzny tłuszczowe zachowane prawidłowo. Pęcherz moczowy dobrze wypełniony o gładkich obrysach. Narządy miednicy mniejszej bez widocznej patologii. Węzły chłonne miednicy niepowiększone.",
              conclusion: "",
            }]
        },
        {
          id: 19,
          category: "TK kręgosłupa szyjnego",
          options: [
            {
              id: 0,
              name: "Lordoza zachowana, zmiany zwyrodnieniowe i wytwórcze, wysokość trzonów obniżona przeciążeniowo, osteofitoza na przednich krawędziach trzonów, obniżone wysokości krążków międzykręgowych C4-C5-C6 z niewielkimi wypuklinami na tych poziomach z uciskiem na worek oponowy.",
              conclusion: "",
            }]
        },
        {
          id: 20,
          category: "TK kręgosłup szyjny po urazie",
          options: [
            {
              id: 0,
              name: "Wysokość trzonów zachowana, nie stwierdza się zmian kostnych pourazowych, zniesiona lordoza z wytworzeniem kifozy, obniżona nieregularnie wysokość krążków C4-C5-C6 prawdopodobnie uszkodzenie aparatu więzadłowego.",
              conclusion: "",
            },
            {
              id: 1,
              name: "Wysokość trzonów zachowana, nie stwierdza się zmian kostnych pourazowych, zniesiona lordoza z wytworzeniem kifozy, obniżona nieregularnie wysokość krążków C4-C5-C6 prawdopodobnie uszkodzenie aparatu więzadłowego. ",
              conclusion: "",
            }
          ]
        },
        {
          id: 21,
          category: "Tk szyi",
          options: [
            {
              id: 0,
              name: "Liczne artefakty metaliczne z wypełnień stomatologicznych utrudniają ocenę jamy ustnej. Struktury języka, migdałki gardłowe i jama ustna w zakresie możliwym do oceny w badaniu CT bez ewidentnych zmian ogniskowych, symetryczne, bez wzmocnień po podaniu i.v. środka kontrastowego. Struktury nosogardła, gardła w części ustnej i krtaniowej symetryczne, bez zmian patologicznych. Przestrzenie przygardłowa, zagardłowa prawidłowa. Krtań w badaniu CT bez zmian. Uwidocznione w badaniu CT węzły chłonne szyi niepowiększone. Tarczyca dwupłatowa niepowiększona, jednorodna. Ślinianki przyuszne i podżuchwowe niepowiększone, bez zmian ogniskowych. Zatoki przynosowe w uwidocznionym zakresie powietrzne. Struktury kostne w badaniu bez zmian ogniskowych.",
              conclusion: "",
            }
          ]
        },
        {
          id: 22,
          category: "TT kończyn dolnych",
          options: [
            {
              id: 0,
              name: "Strona lewa: - uwapnione zmiany miażdżycowe t.udowej nie powodują istotnych zwężeń , - t.głęboka uda drożna, -t.udowa powierzchowna wąska, nitkowata, - t. podkolanowa drożna, - pień piszczelowo-strzałkowy, tętnica: piszczelowa przednia, piszczelowa tylna, strzałkowa oraz grzbietowa stopy drożne.",
              conclusion: "",
            },
            {
              id: 1,
              name: "Strona prawa:- uwapnione zmiany miażdżycowe t. udowej nie powodują istotnych zwężeń, - t.udowa głęboka i powierzchowna uda drożna, -  t.podkolanowa drożna - pień piszczelowo-strzałkowy, tętnica : piszczelowa przednia, piszczelowa tylna, strzałkowa oraz grzbietowa stopy drożne.",
              conclusion: "",
            }
          ]
        },
        {
          id: 23,
          category: "Ucho lewe",
          options: [
            {
              id: 0,
              name: "Wyrostek sutkowaty bogatokomórkowy/częściowo sklerotyczny, powietrzny - przewód słuchowy zew. drożny/wypełniony zgrubieniami prawd. woskowiną - błona bębenkowa o zachowanej ciągłości - jama ucha środkowego prawidłowo powietrzna - łańcuch kosteczek słuchowych zachowany - nerw VII widoczny w kanale tego nerwu - struktury ucha wew. zachowane.",
              conclusion: "",
            }
          ]
        },
        {
          id: 24,
          category: "Ucho prawe",
          options: [
            {
              id: 0,
              name: "Wyrostek sutkowaty bogatokomórkowy/częściowo sklerotyczny, powietrzny - przewód słuchowy zew. drożny/wypełniony zgrubieniami prawd. woskowiną - błona bębenkowa o zachowanej ciągłości - jama ucha środkowego prawidłowo powietrzna - łańcuch kosteczek słuchowych zachowany - nerw VII widoczny w kanale tego nerwu - struktury ucha wew. zachowane",
              conclusion: "",
            }
          ]
        },
        {
          id: 25,
          category: "ZATOKI 2",
          options: [
            {
              id: 0,
              name: "Zatoki szczękowe, czołowe i klinowe prawidłowo wykształcone, w obu szczękowych polipowate zmiany, gr do 12mm, niewielkie zmiany miękkotkankowe w sitowiu. Zatoki czołowe i klinowe bez zmian. Małżowiny nosowe pogrubiane. Przegroda nosa nieznacznie esowato skrzywiona. Kompleksy ujściowo-przewodowe niedrożne.",
              conclusion: "",
            }
          ]
        },
        {
          id: 26,
          category: "Zatoki",
          options: [
            {
              id: 0,
              name: "Zatoki szczękowe, czołowe i klinowe prawidłowo wykształcone i powietrzne. Sitowie prawidłowe, małżowiny nosowe niepogrubiane. Pogrubiałe śluzówki w zatokach szczękowych obustronnie do 4mm. Przegroda nosa nieznacznie esowato skrzywiona. Kompleksy ujściowo-przewodowe drożne.",
              conclusion: "Wnioski: zatoki prawidłowe, niewielkie pogrubienia śluzówki pozapalne, bez poziomów płynu.",
            },
            {
              id: 1,
              name: "Zatoki szczękowe, czołowe i klinowe prawidłowo wykształcone w obu szczękowych polipowate zmiany, gr do 12mm, niewielkie zmiany miękkotkankowe w sitowiu. Zatoki czołowe i klinowe bez zmian. Małżowiny nosowe pogrubiane. Przegroda nosa nieznacznie esowato skrzywiona. Kompleksy ujściowo-przewodowe niedrożne.",
              conclusion: "Wnioski: zmiany pozapalne w zatokach j/w",
            }
          ]
        },
        {
          id: 27,
          category: "Głowa",
          options: [
            {
              id: 0,
              name: "Ogniska i obszary hypodensyjne różnoczasowe poniedokrwienne, naczyniopochodne w istocie białej obu półkul mózgowych, układ komorowy poszerzony, symetryczny. Cechy zaników korowo-podkorowych. Poszerzone przestrzenie płynowe nad płatami czołowymi i w bruzdach bocznych. Nie stwierdza sie cech krwawienia śródmózgowego.",
              conclusion: "",
            },
            {
              id: 1,
              name: "Struktury mózgowia bez zmian ogniskowych. Układ komorowy symetryczny nieposzerzony, nieprzemieszczony. Cechy zaników korowych, płynowe przestrzenie przymózgowe poszerzone, nad płatami czołowymi i w bruzdach bocznych. Prawidłowe kości czaszki, zatoki przynosowe i podstawa czaszki objęte badaniem.",
              conclusion: "Cechy zaników korowych, poza tym prawidłowy obraz struktur wewnątrzczaszkowych i zewnątrzczaszkowych.",
            },
            {
              id: 2,
              name: "Gęstość substancji białej i szarej mózgu prawidłowa. Układ komorowy nadnamiotowy prawidłowej szerokości, nieprzemieszczony, symetryczny. Komora IV - struktury tylnojamowe prawidłowe, układ kostny zmian nie wykazuje. Wynaczynionej krwi w obrębie OUN nie stwierdza się.",
              conclusion: ""
            },
            {
              id: 3,
              name: "Struktury mózgu i móżdżku nieprzemieszczone bez zmian ogniskowych. Układ komorowy nieposzerzony bez zniekształceń. Zbiorniki podstawy i płynowe przestrzenie przymózgowe w normie. Kości podstawy i pokrywy czaszki bez zmian ogniskowych i pourazowych. Nie uwidoczniono cech krwawienia wewnątrzczaszkowego.",
              conclusion: "Nie stwierdza się zmian kostnych pourazowych."
            },
            {
              id: 4,
              name: "W badaniu TK głowy uwidoczniono pojedyncze, drobne ogniska hipodensyjne zlokalizowane w istocie białej okołokomorowej obu półkul mózgu, pojedyncze w moście odpowiadające różnoczasowym ogniskom naczyniopochodnym, niedokrwiennym. Miernego stopnia uogólniony zanik korowo-podkorowy wyrażony poszerzeniem przestrzeni płynowych. Móżdżek oraz pozostałe struktury tylnej jamy czaszki bez zmian ogniskowych. Nie uwidoczniono cech krwawienia śródczaszkowego.",
              conclusion: "Pojedyncze ogniska poniedokrwienne okołokomorowo i drobne w moście."
            },
            {
              id: 5,
              name: "Pojedyncze niewielkie ogniska naczyniopochodne w istocie okołokomorowej półkul mózgu. Maks. średnicy ok. 11mm w okolicy trzonu komory bocznej lewej. Struktury tylnego dołu czaszki bez zmian ogniskowych. Układ komorowy nieposzerzony. Struktury linii środkowej bez przemieszczeń. Zanik korowy półkul mózgu wyrażony poszerzeniem płynowych przestrzeni przymózgowych. Nie stwierdza się cech krwawienia wewnątrzczaszkowego. Kości czaszki bez zmian ogniskowych i pourazowych. Niewielka ilość gęstej wydzieliny w zatoce klinowej.",
              conclusion: "Nie stwierdza się obrzęku mózgu. Nieliczne, różnoczasowe ogniska naczyniopochodne. Zanik korowy mózgu."
            }
          ]
        },
        {
          id: 28,
          category: "Tk glowy wykonane jednofazowo",
          options: [
            {
              id: 0,
              name: "Struktury mózgu i móżdżku nieprzemieszczone bez zmian ogniskowych. Układ komorowy nieposzerzony bez zniekształceń. Zbiorniki podstawy i płynowe przestrzenie przymózgowe w normie. Kości pokrywy i podstawy czaszki bez zmian pourazowych. W wykonanym badaniu nie uwidoczniono cech krwawienia śródczaszkowego.",
              conclusion: ""
            }]
        },
        {
          id: 29,
          category: "Badanie TK twarzoczaszki wykonane jednofazowo",
          options: [
            {
              id: 0,
              name: "Uwidocznione struktury kostne twarzoczaszki bez uchwytnych cech świeżych zmian pourazowych. Zatoki oboczne nosa prawidłowo powietrzne, bez poziomów płynu. Przegroda nosa skrzywiona na prawą stronę z kolcem kostnym. Obrysy oczodołów gładkie. Zawartość oczodołów bez cech zmian ogniskowych i pourazowych.",
              conclusion: "Nie stwierdza się zmian kostnych pourazowych."
            }
          ]
        },
        {
          id: 30,
          category: "Tk jamy brzusznej i miednicy",
          options: [
            {
              id: 0,
              name: "Wątroba prawidłowej wielkości gładkich obrysach, bez widocznych zmian ogniskowych. Pęcherzyk żółciowy prawidłowej wielkości o gładkich ścianach, bez widocznych złogów uwapnionych. Śledziona prawidłowej wielkości, bez zmian patologicznych. Trzustka prawidłowej wielkosci o niezmienionej strukturze miąższu. Nerki ułozone anatomicznie o gładkich obrysach, układy kliechowo-miedniczkowe nieposzerzone. Naczynia przezstrzeni zaotrzewnowej bez widocznych patologii. Nie uwidoczniono powiększonych węzłów chłonnych śród- i pozaotrzewnowych. Pętle jelitowe nieposzerzone, płaszczyzny tłuszczowe zachowane prawidłowo. Pęcherz moczowy dobrze wypełniony o gładkich obrysach. Narządy miednicy mniejszej bez widocznej patologii. Węzły chłonne miednicy niepowiększone.",
              conclusion: ""
            }
          ]
        }
      ],
      /**Słowniki RTG
       * RTG Dictionaries
       */
      mDictionarioRTG: [
        {
          id: 0,
          name: "Miąższ płucny bez zagęszczeń. Krążenie płucne wydolne. Jamy opłucnej bez cech płynu. Serce i aorta w normie. Wnęki i śródpiersie nieposzerzone. Tchawica bez cech zwężenia ani przemieszczenia."
        },
        {
          id: 1,
          name: "Zniesienie fizjologicznej lordozy. Zmiany zwyrodnieniowe w postaci osteofitów na krawędziach trzonów. Wysokość trzonów i krążków międzykręgowych zachowana. Stawy krzyżowo-biodrowe bez zmian."
        },
        {
          id: 2,
          name: "RTG kręgosłupa piersiowo-lędźwiowego na stojąco: zniesienie fizjologicznych krzywizn. Prawoboczne skrzywienie w odcinku piersiowym z kątem Cobba ok. stopni. Kręg szczytowy skrzywienia Th. Lewoboczne skrzywienie w odcinku lędźwiowym z kątem Cobba ok. stopni. Kręg szczytowy skrzywienia L. Lewy talerz biodrowy ustawiony wyżej niż prawy o ok. mm."
        },
        {
          id: 3,
          name: "Zmiany zwyrodnieniowe w obu stawach biodrowych w postaci sklerotyzacji podchrzęstnej panewek i osteofitozy krawędziowej powierzchni stawowych. Zwężenie szpar stawowych."
        },
        {
          id: 4,
          name: "Zmiany zwyrodnieniowe w postaci zaostrzenia guzków międzykłykciowych, sklerotyzacji podchrzęstnej oraz osteofitozy krawędziowej powierzchni stawowych. Zwężenie szpar stawowych od strony przyśrodkowej i w przedziale rzepkowo-udowym obustronnie. Lateralizacja obu rzepek."
        },
        {
          id: 5,
          name: "Płaskostopie poprzeczne i podłużne, z koślawością paluchów i zmianami zwyrodnieniowymi w stawie śródstopno-paliczkowym palucha obustronnie. Ostrogi kostne na kości piętowej obustronnie."
        },
        {
          id: 6,
          name: "Zmiany zwyrodnieniowe w stawach międzypaliczkowych dalszych palców obu rąk, ze zwężeniem szpar stawowych. Podobne zmiany w stawie śródręczno-nadgarstkowym kciuka obustronnie."
        },
        {
          id: 7,
          name: "RTG lewego stawu ramiennego: niewielkie zmiany zwyrodnieniowe w postaci osteofitozy krawędziowej powierzchni stawowych. Podobne zmiany w stawie obojczykowo-barkowym."
        },
        {
          id: 8,
          name: "Zatoki przynosowe prawidłowo powietrzne. Niewielkie, prawoboczne skrzywienie przegrody nosa."
        },
        {
          id: 9,
          name: "Pogrubienie tkanek miękkich tylnej części nosogardła ze zwężeniem jego światła na tym poziomie. Obraz może odpowiadać przerostowi migdałka gardłowego."
        },
        {
          id: 10,
          name: "Miąższ płucny bez zagęszczeń. Krążenie płucne wydolne. Jamy opłucnowe bez cech płynu. Serce i aorta w normie. Wnęki nieposzerzone."
        },
        {
          id: 11,
          name: "Zniesienie fizjologicznej lordozy. Zmiany zwyrodnieniowe w postaci osteofitów na krawędziach trzonów. Niespojenie łuku S1. Poza tym bez zmian. Wysokość trzonów i krążków międzykręgowych zachowana. Stawy krzyżowo-biodrowe prawidłowe."
        },
        {
          id: 12,
          name: "Pogrubienie tkanek miękkich tylnego rejonu nosogardła, ze zwężeniem jego światła na tym poziomie. Obraz może odpowiadać przerostowi migdałka gardłowego."
        },
        {
          id: 13,
          name: "Zmiany zwyrodnieniowe w stawach międzypaliczkowych dalszych palców obu rąk, ze zwężeniem szpar stawowych. Podobne zmiany w stawach śródręczno-nadgarstkowych obu kciuków."
        },
        {
          id: 14,
          name: "Zmiany zwyrodnieniowe w postaci zagęszczenia struktury kostnej panewek i osteofitozy krawędziowej powierzchni stawowych. Zwężenie szpar stawowych."
        },
        {
          id: 15,
          name: "Zmiany zwyrodnieniowe w postaci zaostrzenia guzków międzykłykciowych i osteofitozy krawędziowej powierzchni stawowych. Zwężenie szpar stawowych od strony przyśrodkowej i w przedziale rzepkowo-udowym obustronnie. Lateralizacja obu rzepek."
        },
        {
          id: 16,
          name: "Płaskostopie poprzeczne i podłużne obu stóp, z koślawością obu paluchów oraz zmianami zwyrodnieniowymi w stawach śródstopno-paliczkowych obu paluchów. Ostrogi kostne na obu kościach piętowych."
        },
        {
          id: 17,
          name: "Zmiany zwyrodnieniowe w obu stawach kolanowych w postaci zaostrzenia guzków międzykłykciowych."
        }

      ],
      /**Słowniki MGF
       * MGF Dictionaries
       */
      mDictionarioMGF: [
        {
          id: 0,
          name: "Sutki o utkaniu tłuszczowo-gruczołowym, bez zmian ogniskowych podejrzanych o złośliwość. Birads 1."
        },
        {
          id: 1,
          name: "Sutki o utkaniu gruczołowo-tłuszczowym, bez zmian ogniskowych podejrzanych o złośliwość. BIRADS 1."
        },
        {
          id: 2,
          name: "Sutki o utkaniu gruczołowym, bez zmian ogniskowych podejrzanych o złośliwość. BIRADS 1."
        },
        {
          id: 3,
          name: "Sutki o utkaniu tłuszczowym, bez zmian ogniskowych podejrzanych o złośliwość. Birads 1."
        }
      ],

      mRisBirads:[
        {
          "value": 0,
          "text": "0"
        },
        {
            "value": 1,
            "text": "1"
        },
        {
            "value": 2,
            "text": "2"
        },
        {
            "value": 3,
            "text": "3"
        },
        {
            "value": 4,
            "text": "4a"
        },
        {
            "value": 5,
            "text": "4b"
        },
        {
            "value": 6,
            "text": "4c"
        },
        {
            "value": 7,
            "text": "5"
        },
        {
            "value": 8,
            "text": "6"
        },
        {
            "value": 9,
            "text": "Nie można zastosować"
        }
      ],

      mProcedures: [
        //temp to RIS
        { id: 1, name: 'USG - jamy brzusznej', time: 60 },
        { id: 2, name: 'Tomografia - ogólna', time: 120 },
        { id: 3, name: 'RTG - klatka piersiowa', time: 15 },
        { id: 4, name: 'RTG - ręka prawa', time: 20 },
        { id: 5, name: 'USG - kolano prawe', time: 30 },
        { id: 6, name: 'USG - kolano lewe', time: 30 },
        { id: 7, name: 'USG - kolana', time: 30 },
        { id: 8, name: 'Tomografia - głowy', time: 30 },
      ],

      mProjectionTypes: [
        { id: 1, name: 'AP' },
        { id: 2, name: 'PA' },
        { id: 3, name: 'Boczna' },
        { id: 4, name: 'Skośna' },
        { id: 5, name: 'Osiowa' },
        { id: 6, name: 'Inna' },
      ],
      /**Typy badań obrazowych
       * Imaging exams types
       */
      mRisExaminationsTypes: [
        {
          id: 0,
          name: "USG",
          localizations: [
            {
              value: 0,
              text: "Doppler kończyn dolnych"
            },
            {
              value: 1,
              text: "Ślinianek"
            },
            {
              value: 2,
              text: "Ścięgna achillesa"
            },
            {
              value: 3,
              text: "Węzłów chłonnych"
            },
            {
              value: 4,
              text: "Układu moczowego"
            },
            {
              value: 5,
              text: "Stawu barkowego"
            },
            {
              value: 6,
              text: "Łokcia"
            },
            {
              value: 7,
              text: "Biodra"
            },
            {
              value: 8,
              text: "Kolana"
            },
            {
              value: 9,
              text: "Nadgarstka"
            },
            {
              value: 10,
              text: "Palca"
            },
            {
              value: 11,
              text: "Stopy"
            },
            {
              value: 12,
              text: "Tarczycy"
            },
            {
              value: 13,
              text: "Macicy ciężarnej"
            },
            {
              value: 14,
              text: "Ginekologiczne"
            },
            {
              value: 15,
              text: "Przezciemiączkowe"
            },
            {
              value: 16,
              text: "Klatki piersiowej"
            },
            {
              value: 17,
              text: "Gruczołu krokowego"
            },
            {
              value: 18,
              text: "Piersi"
            },
            {
              value: 19,
              text: "Brzucha"
            },
            {
              value: 20,
              text: "Inna lokalizacja"
            }
          ]
        },
        {
          id: 1,
          name: "RTG",
          localizations: [
            {
              value: 0,
              text: "Bark"
            },
            {
              value: 1,
              text: "Czaszka"
            },
            {
              value: 2,
              text: "Dłoń"
            },
            {
              value: 3,
              idPart: "RTG_JAMY_BRZUSZNEJ",
              text: "Jama brzuszna"
            },
            {
              value: 4,
              idPart: "RTG_KLATKI_PIERSIOWEJ",
              text: "Klatka piersiowa"
            },
            {
              value: 5,
              text: "Kręgosłup"
            },
            {
              value: 6,
              text: "Łokieć"
            },
            {
              value: 7,
              text: "Miednica"
            },
            {
              value: 8,
              text: "Nadgarstek"
            },
            {
              value: 9,
              text: "Nos"
            },
            {
              value: 10,
              text: "Obojczyk"
            },
            {
              value: 11,
              text: "Oczodoły"
            },
            {
              value: 12,
              text: "Palec"
            },
            {
              value: 13,
              text: "Pięta"
            },
            {
              value: 14,
              text: "Podudzie"
            },
            {
              value: 15,
              text: "Przedramię"
            },
            {
              value: 16,
              text: "Ramię"
            },
            {
              value: 17,
              text: "Rzepka"
            },
            {
              value: 18,
              text: "Siodło tureckie"
            },
            {
              value: 19,
              text: "Staw kolanowy"
            },
            {
              value: 20,
              text: "Staw skokowy"
            },
            {
              value: 21,
              text: "Staw biodrowy"
            },
            {
              value: 22,
              text: "Stawy skroniowo-żuchwowe"
            },
            {
              value: 23,
              text: "Stopa"
            },
            {
              value: 24,
              text: "Szczyt płuca"
            },
            {
              value: 25,
              text: "Ślinianki"
            },
            {
              value: 26,
              text: "Twarzoczaszka"
            },
            {
              value: 27,
              text: "Udo"
            },
            {
              value: 28,
              text: "Wole zamostkowe"
            },
            {
              value: 29,
              text: "Zatoki"
            },
            {
              value: 30,
              text: "Zatoki klinowe"
            },
            {
              value: 31,
              text: "Ząb"
            },
            {
              value: 32,
              text: "Żebra"
            },
            {
              value: 33,
              text: "Żuchwa"
            },
            {
              value: 34,
              text: "Inna lokalizacja"
            }
          ]
        },
        {
          id: 2,
          name: "TK",
          localizations: [
            {
              value: 0,
              text: "Głowy"
            },
            {
              value: 1,
              idPart: "TK_TWARZOCZASZKI",
              text: "Twarzoczaszki"
            },
            {
              value: 2,
              text: "Oczodołów"
            },
            {
              value: 3,
              text: "Zatok przynosowych"
            },
            {
              value: 4,
              text: "Szczęki i żuchwy"
            },
            {
              value: 5,
              text: "Przysadki mózgowej"
            },
            {
              value: 6,
              text: "Piramid kości skroniowych"
            },
            {
              value: 7,
              text: "Szyi"
            },
            {
              value: 8,
              text: "Kręgosłupa"
            },
            {
              value: 9,
              idPart: "TK_KLATKI_PIERSIOWEJ",
              text: "Klatki piersiowej i śródpiersia"
            },
            {
              value: 10,
              text: "Serca"
            },
            {
              value: 11,
              idPart: "TK_JAMY_BRZUSZNEJ",
              text: "Jamy brzusznej"
            },
            {
              value: 12,
              idPart: "TK_JAMY_BRZUSZNEJ",
              text: "Jamy brzusznej i miednicy małej"
            },
            {
              value: 13,
              text: "Kości i stawów"
            },
            {
              value: 14,
              text: "Stawów i tkanek miękkich w kończynach"
            },
            {
              value: 15,
              text: "Łokcia"
            },
            {
              value: 16,
              text: "Nadgarstka"
            },
            {
              value: 17,
              text: "Ramienia"
            },
            {
              value: 18,
              text: "Przedramienia"
            },
            {
              value: 19,
              text: "Ręki"
            },
            {
              value: 20,
              text: "Stawu biodrowego"
            },
            {
              value: 21,
              text: "Stawu kolanowego"
            },
            {
              value: 22,
              text: "Uda"
            },
            {
              value: 23,
              text: "Podudzia"
            },
            {
              value: 24,
              text: "Stopy"
            },
            {
              value: 25,
              text: "Angio"
            },
            {
              value: 26,
              text: "Angio głowy"
            },
            {
              value: 27,
              idPart: "TK_ANGIO_TETNIC_MOZGOWYCH",
              text: "Angio głowy tętnic mózgowych"
            },
            {
              value: 28,
              text: "Żył mózgowych"
            },
            {
              value: 29,
              text: "Angio tętnic szyjnych"
            },
            {
              value: 30,
              text: "Angio klatki piersiowej"
            },
            {
              value: 31,
              text: "Angio, aorta piersiowa"
            },
            {
              value: 32,
              idPart: "TK_ANGIO_AORTY_BRZUSZNEJ",
              text: "Angio aorty brzusznej, pnia trzewnego i tętnic nerkowych"
            },
            {
              value: 33,
              text: "Angio tętnic kończyn dolnych"
            },
            {
              value: 34,
              text: "Angio jamy brzusznej i miednicy"
            },
            {
              value: 35,
              text: "Płuc niskodawkowa"
            },
            {
              value: 36,
              idPart: "TK_KRTANI",
              text: "Krtani"
            },
            {
              value: 37,
              idPart: "TK_MIEDNICY_MNIEJSZEJ",
              text: "Miednicy mniejszej"
            },
            {
              value: 38,
              idPart: "TK_ODCINKA_MIESNIOWEGO_KREGOSLUPA",
              text: "Odcinka mięśniowego kręgosłupa"
            },
            {
              value: 39,
              idPart: "TK_ODCINKA_SZYJNEGO_KREGOSLUPA",
              text: "Odcinka szyjnego kręgosłupa"
            },
            {
              value: 40,
              idPart: "TK_HRCT",
              text: "HRCT"
            },
            {
              value: 42,
              text: "Inna lokalizacja"
            }
          ]
        }
      ]
    }
  },

  methods: {
    mGetProjection(id) {
      let status = this.mProjectionTypes.find(s => s.id === id)
      return status ? status : { id: id, name: 'Brak informacji' }
    },

    mGetProjectionName(id) {
      let status = this.mProjectionTypes.find(s => s.id === id)
      return status ? status.name : `Brak informacji (${id})`
    },

    mGetProcedure(id) {
      let status = this.mProcedures.find(s => s.id === id)
      return status ? status : { id: id, name: 'Brak informacji' }
    },

    mGetProcedureName(id) {
      let status = this.mProcedures.find(s => s.id === id)
      return status ? status.name : `Brak informacji (${id})`
    },

    mGetRisStatus(id) {
      let status = this.mRisStatuses.find(s => s.id === id)
      return status ? status : { id: id, name: 'Brak informacji' }
    },

    mGetRisStatusName(id) {
      let status = this.mRisStatuses.find(s => s.id === id)
      return status ? status.name : `Brak informacji (${id})`
    },

    //mRisExaminationsTypes
    mGetRisExaminationsTypesName(id) {
      let status = this.mRisExaminationsTypes.find(s => s.id === id)
      return status ? status.name : `Brak informacji (${id})`
    },

    mGetmRisExaminationName(id) {
      if (id > 0) {
        let exam = this.mRisExamination.find(s => s.id === id)
        return exam ? exam.name : `Brak informacji (${id})`
      } else {
        return ""
      }
    },

    mGetmRisExaminationShortName(id) {
      if (id > 0) {
        let exam = this.mRisExamination.find(s => s.id === id)
        return exam ? exam.nameShort : `Brak informacji (${id})`
      } else {
        return ""
      }
    },

    mGetBodyAreasName(id) {
      if (id > 0) {
        let area = this.mBodyAreas.find(s => s.id === id)
        return area ? area.name : `Brak informacji (${id})`
      } else {
        return "Nieokreślono"
      }
    },

    mGetBodyAreasNameByCode(id) {
      if (id) {
        let area = this.mBodyAreas.find(s => s.codeOKC === id)
        return area ? area.name : `Brak informacji (${id})`
      } else {
        return "Nieokreślono"
      }
    },

    mGetRisEvent(id) {
      if (id > -1) {
        let area = this.mRisEvents.find(s => s.id === id)
        return area ? area.namePL : `Brak informacji (${id})`
      } else {
        return "Nieokreślono"
      }
    },

    mGetBodyAreasCode(id) {
      if (id > 0) {
        let area = this.mBodyAreas.find(s => s.id === id)
        return area ? area.codeOKC : `Brak informacji (${id})`
      } else {
        return ""
      }
    },

    mGetmRisExaminationDevice(id) {
      let exam = this.mRisExamination.find(s => s.id === id)
      return exam ? exam.device : `Brak informacji (${id})`
    },

    mGetRisExaminationsTypesLocalization(id, loc) {
      let status = this.mRisExaminationsTypes.find(s => s.id === id)
      if (status) {
        let local = status.localizations.find((l) => l.value === loc)
        return local ? local.text : `Brak informacji (${loc})`
      }
      return status ? status.name : `Brak informacji (${id})`
    },

    mGetRisExaminationsTypesLocalizationObject(id, loc) {
      let status = this.mRisExaminationsTypes.find(s => s.id === id)
      if (status) {
        let local = status.localizations.find((l) => l.value === loc)
        return local ? local : null
      }
      return null
    },

    mGetEventIcon(id) {
      let eventIcon = this.mRisEventsIcons.find(s => s.id === id)

      if (eventIcon) {
        return eventIcon.icon ? eventIcon.icon : null
      }

      return null
    },

    mGetEventIconType(id) {
      let eventIcon = this.mRisEventsIcons.find(s => s.id === id)

      if (eventIcon) {
        return eventIcon.icon ? eventIcon.type : null
      }

      return null
    }
  }
}