import HomeIndexView from '../views/home/HomeView.vue'

export default [

    // Default
    {
        name: 'home',
        path: '/',
        meta: {
            desc: 'Strona główna',
            permits: []
        },
        component: HomeIndexView
    },

    // **************************************************************************
    // ADMIN
    // **************************************************************************

    // Admin views
    {
        name: 'admin-accounts',
        path: '/admin/accounts',
        meta: {
            desc: 'ADMIN: Lista kont systemowych',
            permits: ["admin"]
        },
        component: () => import(/* webpackChunkName: "admaccs" */ '../views/identity/IdentityAccountList.vue')
    },
    {
        name: 'admin-accounts-add',
        path: '/admin/accounts/add',
        meta: {
            desc: 'ADMIN: Nowy użytkownik systemowy',
            permits: ["admin"]
        },
        component: () => import(/* webpackChunkName: "admaccsadd" */ '../views/identity/IdentityRegisterView.vue')
    },
    {
        name: 'admin-feedbacks',
        path: '/admin/feedbacks',
        meta: {
            desc: 'ADMIN: Feedback pracowników',
            permits: ["admin"]
        },
        component: () => import(/* webpackChunkName: "admfeeds" */ '../views/admin/WorkerFeedbacksView.vue')
    },
    {
        name: 'admin-api-errors',
        path: '/admin/errors/api',
        meta: {
            desc: 'ADMIN: Błędy API',
            permits: ["admin"]
        },
        component: () => import(/* webpackChunkName: "apierrs" */ '../views/admin/ApiErrorsView.vue')
    },
    {
        name: 'admin-api-lib',
        path: '/admin/api/lib',
        meta: {
            desc: 'ADMIN: Lista API',
            permits: ["subadmin"]
        },
        component: () => import(/* webpackChunkName: "apilib" */ '../views/admin/ApiLibView.vue')
    },
    {
        name: 'test-ikz',
        path: '/ikz/test',
        meta: {
            desc: 'Test IKZ',
            permits: []
        },
        component: () => import(/* webpackChunkName: "apierrs" */ '../views/ikz/IkzTestView.vue')
    },
    {
        name: 'test-ikz-supervisor',
        path: '/ikz/supervisortest',
        meta: {
            desc: 'Test IKZ: Mój zespół',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "ikzsup" */ '../views/ikz/IkzSupervisorTestView.vue')
    },
    {
        name: 'talk-general',
        path: '/talk/general',
        meta: {
            desc: 'SZOK Talk',
            permits: []
        },
        component: () => import(/* webpackChunkName: "talkgen" */ '../views/talk/TalkGeneralView.vue')
    },
    {
        name: 'worktime-management',
        path: '/management/worktime',
        meta: {
            desc: 'Terminarze',
            permits: []
        },
        component: () => import(/* webpackChunkName: "admwrk" */ '../views/admin/ScheduleManagerView.vue')
    },
    {
        name: 'admin-plc',
        path: '/admin/plc',
        meta: {
            desc: 'ADMIN: PLC',
            permits: ["admin"]
        },
        component: () => import(/* webpackChunkName: "admplc" */ '../views/admin/PLCPanelView.vue')
    },
    {
        name: 'admin-uac',
        path: '/admin/uac',
        meta: {
            desc: 'ADMIN: UAC',
            permits: ["admin"]
        },
        component: () => import(/* webpackChunkName: "admuac" */ '../views/admin/UACPanelView.vue')
    },
    {
        name: 'admin-hub',
        path: '/admin/hub',
        meta: {
            desc: 'ADMIN: HUB',
            permits: []
        },
        component: () => import(/* webpackChunkName: "admhub" */ '../views/admin/HubPanelView.vue')
    },
    {
        name: 'crash-log',
        path: '/admin/crash',
        meta: {
            desc: 'ADMIN: Crash Log',
            permits: ["admin"]
        },
        component: () => import(/* webpackChunkName: "crash" */ '../views/admin/CrashLogView.vue')
    },

    {
        name: 'e-signature',
        path: '/admin/esignature',
        meta: {
            desc: 'ADMIN: Test ePodpisu',
            permits: []
        },
        component: () => import(/* webpackChunkName: "admesig" */ '../views/e-signature/ESignatureTesterView.vue')
    },
    {
        name: 'admin-crm',
        path: '/admin/crm',
        meta: {
            desc: 'ADMIN: Zgłoszenia',
            permits: []
        },
        component: () => import(/* webpackChunkName: "admcrm" */ '../views/admin/CrmView.vue')
    },
    {
        name: 'manage-crm',
        path: '/manage/crm',
        meta: {
            desc: 'Zgłoszenia',
            permits: []
        },
        component: () => import(/* webpackChunkName: "admcrmmg" */ '../views/admin/CrmView.vue')
    },
    {
        name: 'admin-types',
        path: '/admin/types',
        meta: {
            desc: 'ADMIN: Struktury i typy',
            permits: []
        },
        component: () => import(/* webpackChunkName: "admtps" */ '../views/manager/ScheduleCoordinatorView.vue')
    },

    //CRM
    {
        name: 'crm-it',
        path: '/crm-it',
        meta: {
            desc: 'Zgłoszenia - IT',
            permits: ['crm', 'crmIt']
        },
        component: () => import(/* webpackChunkName: "crmit" */ '../views/crm/CrmItView.vue')
    },
    {
        name: 'crm-digitmed',
        path: '/crm-digitmed',
        meta: {
            desc: 'Zgłoszenia - Digitmed',
            permits: ['crmDigitmed']
        },
        component: () => import(/* webpackChunkName: "crmdigitmed" */ '../views/crm/CrmDigitmedView.vue')
    },
    {
        name: 'crm-maintenance',
        path: '/crm-maintenance',
        meta: {
            desc: 'Zgłoszenia - Utrzymanie',
            permits: ['crmMaintenance']
        },
        component: () => import(/* webpackChunkName: "crmmaintenance" */ '../views/crm/CrmMaintenanceView.vue')
    },
    {
        name: 'crm-customerService',
        path: '/crm-customerService',
        meta: {
            desc: 'Zgłoszenia - BOK',
            permits: ['crmCustomerService']
        },
        component: () => import(/* webpackChunkName: "crmcustomerservice" */ '../views/crm/CrmCustomerServiceView.vue')
    },
    {
        name: 'crm-rodo',
        path: '/crm-rodo',
        meta: {
            desc: 'Zgłoszenia - RODO',
            permits: ['crmRodo']
        },
        component: () => import(/* webpackChunkName: "crmrodo" */ '../views/crm/CrmRodoView.vue')
    },
    {
        name: 'crm-hr',
        path: '/crm-hr',
        meta: {
            desc: 'Zgłoszenia - HR',
            permits: ['crmHr']
        },
        component: () => import(/* webpackChunkName: "crmhr" */ '../views/crm/CrmHrView.vue')
    },
    {
        name: 'crm-promo',
        path: '/crm-promo',
        meta: {
            desc: 'Zgłoszenia - Promocja',
            permits: ['crmPromo']
        },
        component: () => import(/* webpackChunkName: "crmpromo" */ '../views/crm/CrmPromoView.vue')
    },
    {
        name: 'crm-black-box',
        path: '/crm-black-box',
        meta: {
            desc: 'Zgłoszenia - Czarna skrzynka',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "crmblackbox" */ '../views/crm/CrmBlackBoxView.vue')
    },
    {
        name: 'crm-employees',
        path: '/crm-employees',
        meta: {
            desc: 'Zgłoszenia - Pracownicy',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "crmemployees" */ '../views/crm/CrmEmployeesView.vue')
    },

    //DIGITMED
    {
        name: 'digitmed-management-clients',
        path: '/digitmed/management/clients',
        meta: {
            desc: 'Digitmed - Klienci i usługi',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "cfgcln" */ '../views/digitmed/DigitmedManagementClientsView.vue')
    },
    {
        name: 'digitmed-management-config',
        path: '/digitmed/management/config',
        meta: {
            desc: 'Digitmed - Konfiguracja systemu',
            permits: []
        },
        component: () => import(/* webpackChunkName: "cfgsys" */ '../views/digitmed/DigitmedManagementConfigView.vue')
    },
    {
        name: 'digitmed-management-sms',
        path: '/digitmed/management/sms',
        meta: {
            desc: 'Digitmed - Konfiguracja SMS',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "cfgsms" */ '../views/digitmed/DigitmedManagementSmsView.vue')
    },
    {
        name: 'digitmed-management-groupers',
        path: '/digitmed/management/groupers',
        meta: {
            desc: 'Digitmed - Konfiguracja grupowania świadczeń/usług',
            permits: ['subadmin']
        },
        component: () => import(/* webpackChunkName: "cfggrp" */ '../views/digitmed/DigitmedManagementGroupersView.vue')
    },
    {
        name: 'nfz-dictionaries',
        path: '/nfz/dictionaries',
        meta: {
            desc: 'Słowniki NFZ',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "nfzdct" */ '../views/nfz/NfzDictionariesView.vue')
    },
    {
        name: 'test-dawid',
        path: '/digitmed/test/dawid',
        meta: {
            desc: 'Twórczość Dawida',
            permits: ['subadmin']
        },
        component: () => import(/* webpackChunkName: "dgmdawid" */ '../views/test-dawid/TestDawidView.vue')
    },

    // OFFICES + DEVICES
    {
        name: 'offices',
        path: '/manage/offices',
        meta: {
            desc: 'Zarządzanie gabinetami',
            permits: []
        },
        component: () => import(/* webpackChunkName: "admoffs" */ '../views/offices-manager/OfficesManagerView.vue')
    },
    {
        name: 'medical-devices',
        path: '/manage/devices',
        meta: {
            desc: 'Zarządzanie urządzeniami medycznymi',
            permits: []
        },
        component: () => import(/* webpackChunkName: "admmeddev" */ '../views/medical-devices/MedicalDevicesView.vue')
    },

    // User account views
    {
        name: 'tpk-starter',
        path: '/start',
        meta: {
            desc: 'TPK: Rozpoczynanie wizyty',
            permits: []
        },
        props(route) { return route.query || {} },
        component: () => import(/* webpackChunkName: "tpkstr" */ '../views/test_office/TestOfficeVisitView.vue')
    },

    // User account views
    {
        name: 'reset-password',
        path: '/reset/password',
        meta: {
            desc: 'Reset hasła',
            permits: []
        },
        props(route) { return route.query || {} },
        component: () => import(/* webpackChunkName: "passres" */ '../views/identity/ResetAccountPasswordView.vue')
    },

    {
        name: 'reset-password-request',
        path: '/reset/request',
        meta: {
            desc: 'Reset hasła (potwierdzenie)',
            permits: []
        },
        component: () => import(/* webpackChunkName: "passresreq" */ '../views/identity/ResetAccountPasswordRequestView.vue')
    },
    {
        name: 'login',
        path: '/login',
        meta: {
            desc: 'Login',
            permits: []
        },
        component: () => import(/* webpackChunkName: "idlog" */ '../views/identity/IdentityLoginView.vue')
    },
    {
        name: 'my-account',
        path: '/account',
        meta: {
            desc: 'Moje konto',
            permits: ['myAccount']
        },
        component: () => import(/* webpackChunkName: "accs" */ '../views/home/MyAccountView.vue')
    },
    {
        name: 'my-account-tpk',
        path: '/tpk/account',
        meta: {
            desc: 'Moje konto',
            permits: ['myAccount']
        },
        component: () => import(/* webpackChunkName: "tpkaccs" */ '../views/home/MyAccountViewTPK.vue')
    },

    // EMPLOYEE
    {
        name: 'employee-list',
        path: '/employees',
        meta: {
            desc: 'Kadry',
            permits: ['humanResources']
        },
        component: () => import(/* webpackChunkName: "empmain" */ '../views/employee/EmployeesView.vue')
    },
    {
        name: 'employee-details',
        path: '/employee/details',
        meta: {
            desc: 'Szczególy pracownika',
            permits: ['humanResources']
        },
        component: () => import(/* webpackChunkName: "empdet" */ '../views/employee/EmployeeView.vue')
    },
    {
        name: 'employee-creator',
        path: '/employee/creator',
        meta: {
            desc: 'Kreator konta pracownika',
            permits: ['humanResources']
        },
        component: () => import(/* webpackChunkName: "empcrt" */ '../views/employee/EmployeeCreatorView.vue')
    },
    {
        name: 'employee-stats',
        path: '/employee/stats',
        meta: {
            desc: 'Statystyki czasu zalogowania, terminarza i wizyt',
            permits: ['nfzSettlements', 'humanResourcesStats']
        },
        component: () => import(/* webpackChunkName: "empcrt" */ '../views/employee/EmployeesStatsView.vue')
    },
    {
        name: 'employee-night',
        path: '/employee/night',
        meta: {
            desc: 'Nocni pracownicy',
            permits: ["admin"]
        },
        component: () => import(/* webpackChunkName: "empnth" */ '../views/employee/EmployeesNightView.vue')
    },

    // EMPLOYEE
    {
        name: 'employee-list-tpk',
        path: '/tpk/employees',
        meta: {
            desc: 'TPK: Zarządzanie kontami',
            permits: []
        },
        component: () => import(/* webpackChunkName: "tpkemp" */ '../views/employee/EmployeesViewTPK.vue')
    },
    {
        name: 'employee-details-tpk',
        path: '/tpk/employee/details',
        meta: {
            desc: 'TPK: Szczególy pracownika',
            permits: []
        },
        component: () => import(/* webpackChunkName: "tpkempdet" */ '../views/employee/EmployeeViewTPK.vue')
    },
    {
        name: 'employee-creator-tpk',
        path: '/tpk/employee/creator',
        meta: {
            desc: 'TPK: Kreator konta pracownika',
            permits: []
        },
        component: () => import(/* webpackChunkName: "tpkempcrt" */ '../views/employee/EmployeeCreatorViewTPK.vue')
    },





    // WORKERS
    {
        name: 'workers',
        path: '/workers',
        meta: {
            desc: 'Panel pracowników',
            permits: []
        },
        component: () => import(/* webpackChunkName: "wrkmain" */ '../views/worker/WorkersIndexView.vue')
    },
    {
        name: 'workers-add',
        path: '/workers/add',
        meta: {
            desc: 'Dodawanie pracownika',
            permits: []
        },
        component: () => import(/* webpackChunkName: "wrkadd" */ '../views/worker/WorkerRegistrationView.vue')
    },
    {
        name: 'workers-modify',
        path: '/workers/modify',
        meta: {
            desc: 'Edycja danych pracownika',
            permits: []
        },
        component: () => import(/* webpackChunkName: "wrkmod" */ '../views/worker/WorkerModifyView.vue')
    },
    {
        name: 'workers-all',
        path: '/workers/all',
        meta: {
            desc: 'Lista pracowników',
            permits: []
        },
        component: () => import(/* webpackChunkName: "wrkall" */ '../views/worker/WorkerListView.vue')
    },
    {
        name: 'nfz-dashboard',
        path: '/nfz/dashboard',
        meta: {
            desc: 'Rozliczenia NFZ',
            permits: ['subadmin', 'administration', 'administrationContract', 'administrationStructure']
        },
        component: () => import(/* webpackChunkName: "lbmng" */ '../views/nfz/NfzDashboardView.vue')
    },

    // **************************************************************************
    // OFFICES
    // **************************************************************************

    // LAB COVID
    {
        name: 'lab-covid',
        path: '/lab/covid',
        meta: {
            desc: 'Rejestracja pobrań COVID',
            permits: ['viralCollectionPoint']
        },
        component: () => import(/* webpackChunkName: "lbcov" */ '../views/lab/LabSampleRegistrationView.vue')
    },
    {
        name: 'lab-clean',
        path: '/lab/clean',
        meta: {
            desc: 'Pracownia laboratorium - analiza',
            permits: ['viralLaboratory']
        },
        component: () => import(/* webpackChunkName: "lbcln" */ '../views/lab/LabCleanRoomView.vue')
    },
    {
        name: 'lab-dirty',
        path: '/lab/dirty',
        meta: {
            desc: 'Pracownia laboratorium - dystrybucja',
            permits: ['viralLaboratory']
        },
        component: () => import(/* webpackChunkName: "lbdirt" */ '../views/lab/LabDirtyRoomView.vue')
    },
    {
        name: 'lab-recive',
        path: '/lab/recive',
        meta: {
            desc: 'Pracownia laboratorium - odbiór',
            permits: []
        },
        component: () => import(/* webpackChunkName: "lbrec" */ '../views/lab/LabReciveRoomView.vue')
    },
    {
        name: 'lab-management',
        path: '/lab/management',
        meta: {
            desc: 'Pracownia laboratorium - Zarządzanie',
            permits: ['laboratoryManagement']
        },
        component: () => import(/* webpackChunkName: "lbmng" */ '../views/lab/LabManagementView.vue')
    },

    //LAB CYTOLOGY

    {
        name: 'lab-cytology',
        path: '/lab/cytology',
        meta: {
            desc: 'Punkt pobrań położnej',
            permits: ['officeMidwife', 'gynecologistAssistant']
        },
        component: () => import(/* webpackChunkName: "lbcyt" */ '../views/lab/cytology/LabCytologyRegistrationView.vue')
    },

    {
        name: 'lab-histpat',
        path: '/lab/histpat',
        meta: {
            desc: 'Punkt pobrań - wycinki histopatologiczne',
            permits: ['histopathologyCollectionPoint']
        },
        component: () => import(/* webpackChunkName: "histpat" */ '../views/lab/histpat/HistPatDashboardView.vue')
    },

    {
        name: 'lab-histpat-packages',
        path: '/lab/histpat/packages',
        meta: {
            desc: 'Materiały do badań histopatologicznych - wysyłka',
            permits: ['histopathologyCollectionPoint']
        },
        component: () => import(/* webpackChunkName: "histpat" */ '../views/lab/histpat/HistPatPackagesView.vue')
    },

    {
        name: 'lab-histpat-analysis',
        path: '/lab/histpat/analytics',
        meta: {
            desc: 'Ocena',
            permits: ['histopathologyPathologist']
        },
        component: () => import(/* webpackChunkName: "histpat" */ '../views/lab/histpat/HistPatAnalysisView.vue')
    },

    {
        name: 'lab-histpat-book',
        path: '/lab/histpat/book',
        meta: {
            desc: 'Ksiąga laboratoryjna - badania histopatologiczne',
            permits: ['histopathologyAdministrator']
        },
        component: () => import(/* webpackChunkName: "histpat" */ '../views/lab/histpat/HistPatBookView.vue')
    },

    // {
    //     name: 'lab-cytology-v2',
    // path: '/lab/cytologyV2',
    // meta: {
    //     desc: 'Punkt pobrań położnej',
    //     permits: []
    // },
    //     component: () => import(/* webpackChunkName: "lbcyt" */ '../views/lab/cytology/LabCytologyRegistrationNewView.vue')
    // },

    {
        name: 'lab-cytology-recive',
        path: '/lab/cytology',
        meta: {
            desc: 'Punkt odbioru materiału',
            permits: ['viralLaboratory']
        },
        component: () => import(/* webpackChunkName: "lbcytrec" */ '../views/lab/cytology/LabCytologyReciveRoomView.vue')
    },

    {
        name: 'lab-cytology-analysis',
        path: '/lab/cytology/analysis',
        meta: {
            desc: 'Pracownia cytologiczna',
            permits: ['viralLaboratory']
        },
        component: () => import(/* webpackChunkName: "lbcytanal" */ '../views/lab/cytology/LabCytologyView.vue')
    },

    {
        name: 'lab-cytology-dirty',
        path: '/lab/cytology/dirty',
        meta: {
            desc: 'Dystrybucja',
            permits: ['viralLaboratory']
        },
        component: () => import(/* webpackChunkName: "lbcytdirt" */ '../views/lab/cytology/LabCytologyRoomView.vue')
    },

    {
        name: 'lab-cytology-high-risk-patients',
        path: '/lab/cytology/patients',
        meta: {
            desc: 'Zarządzanie populacją',
            permits: ['officeMidwife', 'administrationPopulationManagement']
        },
        component: () => import(/* webpackChunkName: "lbcytdirt" */ '../views/lab/cytology/LabCytologyHighRiskPatientsView.vue')
    },

    {
        name: 'lab-cytology-scan',
        path: '/lab/cytology/scan',
        meta: {
            desc: 'Skanowanie preparatów - cytologia LBC',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "lbcytdirt" */ '../views/lab/cytology/LabCytologyScanView.vue')
    },

    //LAB

    {
        name: 'lab-registration',
        path: '/lab/registration',
        meta: {
            desc: 'Laboratorium - rejestracja',
            permits: ['viralLaboratory']
        },
        component: () => import(/* webpackChunkName: "lbreg" */ '../views/lab/LabRegistrationView.vue')
    },

    {
        name: 'lab-book',
        path: '/lab/book',
        meta: {
            desc: 'Laboratorium - księga laboratoryjna',
            permits: ['viralLaboratory', 'laboratoryManagement', 'officeMidwife']
        },
        component: () => import(/* webpackChunkName: "lbreg" */ '../views/lab/LabBookView.vue')
    },

    {
        name: 'lab-sign',
        path: '/lab/sign',
        meta: {
            desc: 'Laboratorium - podpis wyników',
            permits: ['viralLaboratory']
        },
        component: () => import(/* webpackChunkName: "lbsgn" */ '../views/lab/LabSignView.vue')
    },

    {
        name: 'lab-foul',
        path: '/lab/foul',
        meta: {
            desc: 'Laboratorium - tabela fauli',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "lbfou" */ '../views/lab/LabFoulView.vue')
    },


    //LAB MINI CYTOLOGY

    {
        name: 'lab-mini-cytology-recive',
        path: '/lab-mini/cytology',
        meta: {
            desc: 'Punkt odbioru materiału',
            permits: []
        },
        component: () => import(/* webpackChunkName: "lbcytrec" */ '../views/lab-mini/cytology/LabCytologyReciveRoomView.vue')
    },

    {
        name: 'lab-mini-cytology-analysis',
        path: '/lab-mini/cytology/analysis',
        meta: {
            desc: 'Pracownia cytologiczna',
            permits: ['labMiniCytology', 'labMiniCytologyAssistant']
        },
        component: () => import(/* webpackChunkName: "lbcytanal" */ '../views/lab-mini/cytology/LabCytologyView.vue')
    },

    {
        name: 'lab-mini-cytology-dirty',
        path: '/lab-mini/cytology/dirty',
        meta: {
            desc: 'Dystrybucja',
            permits: []
        },
        component: () => import(/* webpackChunkName: "lbcytdirt" */ '../views/lab-mini/cytology/LabCytologyRoomView.vue')
    },

    //LAB MINI

    {
        name: 'lab-mini-registration',
        path: '/lab-mini/registration',
        meta: {
            desc: 'Laboratorium - rejestracja',
            permits: ['labMiniCytologyAdministration', 'labMiniCytologyAssistant']
        },
        component: () => import(/* webpackChunkName: "lbreg" */ '../views/lab-mini/LabRegistrationView.vue')
    },

    {
        name: 'lab-mini-management',
        path: '/lab-mini/management',
        meta: {
            desc: 'Laboratorium - zarządzanie',
            permits: ['labMiniCytologyAssistant', 'labMiniCytologyAdministration']
        },
        component: () => import(/* webpackChunkName: "lbmng" */ '../views/lab-mini/LabManagementView.vue')
    },


    // POZ
    {
        name: 'poz',
        path: '/poz/dashboard',
        meta: {
            desc: 'Gabinet lekarski - poczekalnia',
            permits: ['officePoz']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/POZ/POZDashboardView.vue')
    },
    {
        name: 'poz-appointment',
        path: '/poz/appointment',
        meta: {
            desc: 'Wizyta lekarska w trakcie',
            permits: ['officePoz']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/POZ/POZAppointmentView.vue')
    },

    // Nurse
    {
        name: 'nurse',
        path: '/nurse/dashboard',
        meta: {
            desc: 'Gabinet pielęgniarski - poczekalnia',
            permits: ['officeNurse']
        },
        component: () => import(/* webpackChunkName: "nurseds" */ '../views/offices/nurse/NurseDashboardView.vue')
    },
    {
        name: 'nurse-appointment',
        path: '/nurse/appointment',
        meta: {
            desc: 'Wizyta pielęgniarska w trakcie',
            permits: ['officeNurse']
        },
        component: () => import(/* webpackChunkName: "nurseapp" */ '../views/offices/nurse/NurseAppointmentView.vue')
    },

    // Midwife
    {
        name: 'midwife',
        path: '/midwife/dashboard',
        meta: {
            desc: 'Gabinet położnej - poczekalnia',
            permits: ['officeMidwife']
        },
        component: () => import(/* webpackChunkName: "midwds" */ '../views/offices/midwife/MidwifeDashboardView.vue')
    },
    {
        name: 'midwife-appointment',
        path: '/midwife/appointment',
        meta: {
            desc: 'Wizyta położnej',
            permits: ['officeMidwife']
        },
        component: () => import(/* webpackChunkName: "midwapp" */ '../views/offices/midwife/MidwifeAppointmentView.vue')
    },

    // Endoscopy
    {
        name: 'endoscopy',
        path: '/endoscopy/dashboard',
        meta: {
            desc: 'Gabinet endoskopowy - poczekalnia',
            permits: ['officeEndoscopy']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/endoscopy/EndoscopyDashboardView.vue')
    },
    {
        name: 'endoscopy-appointment',
        path: '/endoscopy/appointment',
        meta: {
            desc: 'Wizyta endoskopowa w trakcie',
            permits: ['officeEndoscopy']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/endoscopy/EndoscopyAppointmentView.vue')
    },

    // Pharmacist
    {
        name: 'pharma',
        path: '/pharmacist/dashboard',
        meta: {
            desc: 'Gabinet farmaceutyczny',
            permits: ['officePharma']
        },
        component: () => import(/* webpackChunkName: "pharmds" */ '../views/offices/pharma/PharmaDashboardView.vue')
    },
    {
        name: 'pharma-appointment',
        path: '/pharmacist/appointment',
        meta: {
            desc: 'Wizyta farmaceutyczna w trakcie',
            permits: []
        },
        component: () => import(/* webpackChunkName: "pharmapp" */ '../views/offices/pharma/PharmaAppointmentView.vue')
    },
    {
        name: 'pharma-intervention',
        path: '/pharmacist/intervention',
        meta: {
            desc: 'Wizyta interwencyjna w trakcie',
            permits: []
        },
        component: () => import(/* webpackChunkName: "pharmappint" */ '../views/offices/pharma/PharmaInterventionView.vue')
    },
    {
        name: 'pharma-advice',
        path: '/pharmacist-advice/dashboard',
        meta: {
            desc: 'Gabinet farmaceutyczny',
            permits: []
        },
        component: () => import(/* webpackChunkName: "pharmdvds" */ '../views/offices/pharma/PharmaAdviceDashboardView.vue')
    },
    {
        name: 'pharma-advice-appointment',
        path: '/pharmacist-advice/appointment',
        meta: {
            desc: 'Porada farmaceutyczna w trakcie',
            permits: []
        },
        component: () => import(/* webpackChunkName: "pharmdvapp" */ '../views/offices/pharma/PharmaAdviceAppointmentView.vue')
    },
    // allergist
    {
        name: 'allergist',
        path: '/allergist/dashboard',
        meta: {
            desc: 'Gabinet alergologa - poczekalnia',
            permits: ['officeAllergist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/allergist/AllergistDashboardView.vue')
    },
    {
        name: 'allergist-appointment',
        path: '/allergist/appointment',
        meta: {
            desc: 'Wizyta alergologiczna w trakcie',
            permits: ['officeAllergist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/allergist/AllergistAppointmentView.vue')
    },

    // cardiologist
    {
        name: 'cardiologist',
        path: '/cardiologist/dashboard',
        meta: {
            desc: 'Gabinet kardiologa - poczekalnia',
            permits: ['officeCardiologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/cardiologist/CardioDashboardView.vue')
    },
    {
        name: 'cardiologist-appointment',
        path: '/cardiologist/appointment',
        meta: {
            desc: 'Wizyta kardiologiczna w trakcie',
            permits: ['officeCardiologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/cardiologist/CardioAppointmentView.vue')
    },

    // dermatologist
    {
        name: 'dermatologist',
        path: '/dermatologist/dashboard',
        meta: {
            desc: 'Gabinet dermatologa - poczekalnia',
            permits: ['officeDermatologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/dermatologist/DermatologistDashboardView.vue')
    },
    {
        name: 'dermatologist-appointment',
        path: '/dermatologist/appointment',
        meta: {
            desc: 'Wizyta dermatologiczna w trakcie',
            permits: ['officeDermatologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/dermatologist/DermatologistAppointmentView.vue')
    },

    // diabetologist
    {
        name: 'diabetologist',
        path: '/diabetologist/dashboard',
        meta: {
            desc: 'Gabinet diabetologa - poczekalnia',
            permits: ['officeDiabetologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/diabetologist/DiabetologistDashboardView.vue')
    },
    {
        name: 'diabetologist-appointment',
        path: '/diabetologist/appointment',
        meta: {
            desc: 'Wizyta diabetologiczna w trakcie',
            permits: ['officeDiabetologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/diabetologist/DiabetologistAppointmentView.vue')
    },

    // dietician
    {
        name: 'dietician',
        path: '/dietician/dashboard',
        meta: {
            desc: 'Gabinet dietetyka - poczekalnia',
            permits: ['officeDietician']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/dietician/DietDashboardView.vue')
    },
    {
        name: 'dietician-appointment',
        path: '/dietician/appointment',
        meta: {
            desc: 'Wizyta dietetyczna w trakcie',
            permits: ['officeDietician']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/dietician/DietAppointmentView.vue')
    },

    // endocrinologist
    {
        name: 'endocrinologist',
        path: '/endocrinologist/dashboard',
        meta: {
            desc: 'Gabinet endokrynologa - poczekalnia',
            permits: ['officeEndocrinologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/endocrinologist/EndocrinologistDashboardView.vue')
    },
    {
        name: 'endocrinologist-appointment',
        path: '/endocrinologist/appointment',
        meta: {
            desc: 'Wizyta endokrynologiczna w trakcie',
            permits: ['officeEndocrinologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/endocrinologist/EndocrinologistAppointmentView.vue')
    },

    // gynecologist
    {
        name: 'gynecologist',
        path: '/gynecologist/dashboard',
        meta: {
            desc: 'Gabinet ginekologa - poczekalnia',
            permits: ['officeGynecologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/gynecologist/GynecologistDashboardView.vue')
    },
    {
        name: 'gynecologist-appointment',
        path: '/gynecologist/appointment',
        meta: {
            desc: 'Wizyta ginekologiczna w trakcie',
            permits: ['officeGynecologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/gynecologist/GynecologistAppointmentView.vue')
    },

    // neurologist
    {
        name: 'neurologist',
        path: '/neurologist/dashboard',
        meta: {
            desc: 'Gabinet neurologa - poczekalnia',
            permits: ['officeNeurologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/neurologist/NeuroDashboardView.vue')
    },
    {
        name: 'neurologist-appointment',
        path: '/neurologist/appointment',
        meta: {
            desc: 'Wizyta neurologiczna w trakcie',
            permits: ['officeNeurologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/neurologist/NeuroAppointmentView.vue')
    },

    // paramedic
    {
        name: 'paramedic',
        path: '/paramedic/dashboard',
        meta: {
            desc: 'Gabinet ratownika - poczekalnia',
            permits: ['officeParamedic']
        },
        component: () => import(/* webpackChunkName: "paramds" */ '../views/offices/paramedic/ParamedicDashboardView.vue')
    },
    {
        name: 'paramedic-appointment',
        path: '/paramedic/appointment',
        meta: {
            desc: 'Wizyta ratownicza (?) w trakcie',
            permits: ['officeParamedic']
        },
        component: () => import(/* webpackChunkName: "paramapp" */ '../views/offices/paramedic/ParamedicAppointmentView.vue')
    },

    // rheumatologist
    {
        name: 'rheumatologist',
        path: '/rheumatologist/dashboard',
        meta: {
            desc: 'Gabinet reumatologa - poczekalnia',
            permits: ['officeRheumatologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/rheumatologist/RheumatologistDashboardView.vue')
    },
    {
        name: 'rheumatologist-appointment',
        path: '/rheumatologist/appointment',
        meta: {
            desc: 'Wizyta reumatologiczna w trakcie',
            permits: ['officeRheumatologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/rheumatologist/RheumatologistAppointmentView.vue')
    },

    // surgeon-general
    {
        name: 'surgeon-general',
        path: '/surgeon-general/dashboard',
        meta: {
            desc: 'Gabinet chirurga ogólnego - poczekalnia',
            permits: ['officeSurgeonGeneral']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/surgeon-general/SurgeonGeneralDashboardView.vue')
    },
    {
        name: 'surgeon-general-appointment',
        path: '/surgeon-general/appointment',
        meta: {
            desc: 'Gabinet chirurga ogólnego - wizyta',
            permits: ['officeSurgeonGeneral']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/surgeon-general/SurgeonGeneralAppointmentView.vue')
    },

    // surgeon-oncological
    {
        name: 'surgeon-oncological',
        path: '/surgeon-oncological/dashboard',
        meta: {
            desc: 'Gabinet chirurga onkologicznego - poczekalnia',
            permits: ['officeSurgeonOncological']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/surgeon-oncological/SurgeonOncologicalDashboardView.vue')
    },
    {
        name: 'surgeon-oncological-appointment',
        path: '/surgeon-oncological/appointment',
        meta: {
            desc: 'Gabinet chirurga onkologicznego - wizyta',
            permits: ['officeSurgeonOncological']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/surgeon-oncological/SurgeonOncologicalAppointmentView.vue')
    },

    // surgeon-trauma
    {
        name: 'surgeon-trauma',
        path: '/surgeon-trauma/dashboard',
        meta: {
            desc: 'Gabinet chirurga urazowego - poczekalnia',
            permits: ['officeSurgeonTrauma']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/surgeon-trauma/SurgeonTraumaDashboardView.vue')
    },
    {
        name: 'surgeon-trauma-appointment',
        path: '/surgeon-trauma/appointment',
        meta: {
            desc: 'Gabinet chirurga urazowego - wizyta',
            permits: ['officeSurgeonTrauma']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/surgeon-trauma/SurgeonTraumaAppointmentView.vue')
    },

    // ipom-consultation
    {
        name: 'ipom-consultation',
        path: '/ipom-consultation/dashboard',
        meta: {
            desc: 'Konsultacje IPOM - poczekalnia',
            permits: ['officeIPOM']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/ipom-consultation/IpomConsultationDashboardView.vue')
    },
    {
        name: 'ipom-consultation-appointment',
        path: '/ipom-consultation/appointment',
        meta: {
            desc: 'Konsultacje IPOM - wizyta',
            permits: []
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/ipom-consultation/IpomConsultationAppointmentView.vue')
    },

    // rozliczenia - komunikaty
    {
        name: 'liocz-list',
        path: '/nfz/reports/liocz',
        meta: {
            desc: 'NFZ - Komunikaty kolejki',
            permits: []
        },
        component: () => import(/* webpackChunkName: "standalone_klx" */ '../views/nfz/LioczStandaloneView.vue')
    },
    {
        name: 'swx-list',
        path: '/nfz/reports/swx',
        meta: {
            desc: 'NFZ Komunikaty SWX',
            permits: ['nfzSettlements']
        },
        component: () => import(/* webpackChunkName: "nfzrpl" */ '../views/nfz/SwxListView.vue')
    },
    {
        name: 'dekl-list',
        path: '/nfz/reports/dekl',
        meta: {
            desc: 'NFZ Komunikaty DEKL',
            permits: ['nfzSettlements']
        },
        component: () => import(/* webpackChunkName: "nfzrpl" */ '../views/nfz/DeklListView.vue')
    },
    {
        name: 'klx-list',
        path: '/nfz/reports/klx',
        meta: {
            desc: 'NFZ Komunikaty KLX',
            permits: ['nfzSettlements']
        },
        component: () => import(/* webpackChunkName: "nfzrpl" */ '../views/nfz/KlxListView.vue')
    },
    {
        name: 'zbpoz-list',
        path: '/nfz/reports/zbpoz',
        meta: {
            desc: 'NFZ Komunikaty ZPOZ',
            permits: ['nfzSettlements', 'zbpoz']
        },
        component: () => import(/* webpackChunkName: "nfzrpl" */ '../views/nfz/ZbpozListView.vue')
    },
    {
        name: 'swx-details',
        path: '/nfz/reports/details',
        meta: {
            desc: 'Raport SWX',
            permits: []
        },
        component: () => import(/* webpackChunkName: "nfzrpd" */ '../views/nfz/SwxDetailsView.vue')
    },

    // dekle
    {
        name: 'dekle-list',
        path: '/nfz/dekle/list',
        meta: {
            desc: 'Raporty deklaracji',
            permits: ['nfzSettlements']
        },
        component: () => import(/* webpackChunkName: "dkllst" */ '../views/nfz/DekleListView.vue')
    },
    {
        name: 'dekle-details',
        path: '/nfz/dekle/details',
        meta: {
            desc: 'Raport deklaracji',
            permits: []
        },
        component: () => import(/* webpackChunkName: "dkldet" */ '../views/nfz/DekleDetailsView.vue')
    },
    {
        name: 'declarations',
        path: '/nfz/declarations',
        meta: {
            desc: 'Deklaracje',
            permits: ['settlementsDeclarations']
        },
        component: () => import(/* webpackChunkName: "dkldet" */ '../views/nfz/DeclarationsView.vue')
    },

    //ris-office
    {
        name: 'ris',
        path: '/ris/dashboard',
        meta: {
            desc: 'Gabinet radiologii',
            permits: ['officeRadiology']
        },
        component: () => import(/* webpackChunkName: "risds" */ '../views/ris/RISDashboardView.vue')
    },
    {
        name: 'ris-appointment',
        path: '/ris/appointment',
        meta: {
            desc: 'Gabinet radiologii - opis wyniku',
            permits: ['officeRadiology']
        },
        component: () => import(/* webpackChunkName: "risapp" */ '../views/ris/RISAppointmentView.vue')
    },


    // ris-workshop
    {
        name: 'ris-workshop',
        path: '/ris-workshop/dashboard',
        meta: {
            desc: 'Pracownia radiologii',
            permits: []
        },
        component: () => import(/* webpackChunkName: "riswrkds" */ '../views/ris/ris-workshop/RISWorkshopView.vue')
    },

    // ris-management
    {
        name: 'ris-manage',
        path: '/ris-workshop/management',
        meta: {
            desc: 'Pracownia radiologii - zarządzanie',
            permits: []
        },
        component: () => import(/* webpackChunkName: "rismng" */ '../views/ris/ris-workshop/RISWorkshopManagementView.vue')
    },

    // rehabilitation
    {
        name: 'rehabilitation',
        path: '/rehabilitation/dashboard',
        meta: {
            desc: 'Gabinet rehabilitanta - poczekalnia',
            permits: ['officeRehabilitation']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/rehabilitation/RehabilitationDashboardView.vue')
    },
    {
        name: 'rehabilitation-appointment',
        path: '/rehabilitation/appointment',
        meta: {
            desc: 'Wizyta rehabilitacyjna w trakcie',
            permits: ['officeRehabilitation']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/rehabilitation/RehabilitationAppointmentView.vue')
    },

    // rehabilitation-workshop
    {
        name: 'rehabilitation-workshop',
        path: '/rehabilitation-workshop/dashboard',
        meta: {
            desc: 'Pracownia rehabilitacyjna',
            permits: ['workshopRehabilitation', 'workshopCare', 'workshopDiagnostics']
        },
        component: () => import(/* webpackChunkName: "rehawrkds" */ '../views/offices/rehabilitation-workshop/RehabilitationWorkshopView.vue')
    },

    // physiotherapy
    {
        name: 'physiotherapy',
        path: '/physiotherapy/dashboard',
        meta: {
            desc: 'Gabinet fizjoterapeuty - poczekalnia',
            permits: ['officePhysiotherapy']
        },
        component: () => import(/* webpackChunkName: "physiods" */ '../views/offices/physiotherapy/PhysioDashboardView.vue')
    },
    {
        name: 'physiotherapy-appointment',
        path: '/physiotherapy/appointment',
        meta: {
            desc: 'Wizyta fizjoterapeutyczna w trakcie',
            permits: ['officePhysiotherapy']
        },
        component: () => import(/* webpackChunkName: "physioapp" */ '../views/offices/physiotherapy/PhysioAppointmentView.vue')
    },

    // pediatrician
    {
        name: 'pediatrician',
        path: '/pediatrician/dashboard',
        meta: {
            desc: 'Gabinet pediatry - poczekalnia',
            permits: ['officePediatrician']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/pediatrician/PediatricianDashboardView.vue')
    },
    {
        name: 'pediatrician-appointment',
        path: '/pediatrician/appointment',
        meta: {
            desc: 'Wizyta pediatryczna w trakcie',
            permits: ['officePediatrician']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/pediatrician/PediatricianAppointmentView.vue')
    },

    // psychiatrist
    {
        name: 'psychiatrist',
        path: '/psychiatrist/dashboard',
        meta: {
            desc: 'Gabinet psychiatry - poczekalnia',
            permits: ['officePsychiatrist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/psychiatrist/PsychiatristDashboardView.vue')
    },
    {
        name: 'psychiatrist-appointment',
        path: '/psychiatrist/appointment',
        meta: {
            desc: 'Wizyta psychiatry w trakcie',
            permits: ['officePsychiatrist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/psychiatrist/PsychiatristAppointmentView.vue')
    },

    // psychologist
    {
        name: 'psychologist',
        path: '/psychologist/dashboard',
        meta: {
            desc: 'Gabinet psychologa - poczekalnia',
            permits: ['officePsychologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/psychologist/PsychologistDashboardView.vue')
    },
    {
        name: 'psychologist-appointment',
        path: '/psychologist/appointment',
        meta: {
            desc: 'Wizyta psychologiczna w trakcie',
            permits: ['officePsychologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/psychologist/PsychologistAppointmentView.vue')
    },

    // speech-therapist
    {
        name: 'speech-therapist',
        path: '/speech-therapist/dashboard',
        meta: {
            desc: 'Gabinet logopedy - poczekalnia',
            permits: ['officeSpeechTherapist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/speech-therapist/SpeechTherapistDashboardView.vue')
    },
    {
        name: 'speech-therapist-appointment',
        path: '/speech-therapist/appointment',
        meta: {
            desc: 'Wizyta logopedyczna w trakcie',
            permits: ['officeSpeechTherapist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/speech-therapist/SpeechTherapistAppointmentView.vue')
    },

    // therapist
    {
        name: 'therapist',
        path: '/therapist/dashboard',
        meta: {
            desc: 'Gabinet terapeuty - poczekalnia',
            permits: ['officeTherapist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/therapist/TherapistDashboardView.vue')
    },
    {
        name: 'therapist-appointment',
        path: '/therapist/appointment',
        meta: {
            desc: 'Terapia w trakcie',
            permits: ['officeTherapist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/therapist/TherapistAppointmentView.vue')
    },

    // therapist-group
    {
        name: 'therapist-group',
        path: '/therapist-group/dashboard',
        meta: {
            desc: 'Gabinet terapii grupowej - poczekalnia',
            permits: ['officeTherapist']
        },
        component: () => import(/* webpackChunkName: "thergrds" */ '../views/offices/therapist-group/TherapistGroupDashboardView.vue')
    },
    {
        name: 'therapist-group-appointment',
        path: '/therapist-group/appointment',
        meta: {
            desc: 'Terapia grupowa w trakcie',
            permits: ['officeTherapist']
        },
        component: () => import(/* webpackChunkName: "thergrpapp" */ '../views/offices/therapist-group/TherapistGroupAppointmentView.vue')
    },

    // pulmonologist
    {
        name: 'pulmonologist',
        path: '/pulmonologist/dashboard',
        meta: {
            desc: 'Gabinet pulmonologa - poczekalnia',
            permits: ['officePulmonologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/pulmonologist/PulmonologistDashboardView.vue')
    },
    {
        name: 'pulmonologist-appointment',
        path: '/pulmonologist/appointment',
        meta: {
            desc: 'Wizyta pulmonologa w trakcie',
            permits: ['officePulmonologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/pulmonologist/PulmonologistAppointmentView.vue')
    },

    // urologist
    {
        name: 'urologist',
        path: '/urologist/dashboard',
        meta: {
            desc: 'Gabinet urologa - poczekalnia',
            permits: ['officeUrologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/urologist/UrologistDashboardView.vue')
    },
    {
        name: 'urologist-appointment',
        path: '/urologist/appointment',
        meta: {
            desc: 'Wizyta urologiczna -  w trakcie',
            permits: ['officeUrologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/urologist/UrologistAppointmentView.vue')
    },

    // orthopaedist
    {
        name: 'orthopaedist',
        path: '/orthopaedist/dashboard',
        meta: {
            desc: 'Gabinet ortopedy - poczekalnia',
            permits: ['officeOrthopaedist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/orthopaedist/OrthopaedistDashboardView.vue')
    },
    {
        name: 'orthopaedist-appointment',
        path: '/orthopaedist/appointment',
        meta: {
            desc: 'Wizyta ortopedyczna -  w trakcie',
            permits: ['officeOrthopaedist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/orthopaedist/OrthopaedistAppointmentView.vue')
    },

    // internal-diseases
    {
        name: 'internal-diseases',
        path: '/internal-diseases/dashboard',
        meta: {
            desc: 'Gabinet chorób wewnętrznych - poczekalnia',
            permits: ['officeInternalDiseases']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/internal-diseases/InternalDiseasesDashboardView.vue')
    },
    {
        name: 'internal-diseases-appointment',
        path: '/internal-diseases/appointment',
        meta: {
            desc: 'Wizyta chorób wewnętrznych -  w trakcie',
            permits: ['officeInternalDiseases']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/internal-diseases/InternalDiseasesAppointmentView.vue')
    },

    // laryngologist
    {
        name: 'laryngologist',
        path: '/laryngologist/dashboard',
        meta: {
            desc: 'Gabinet laryngologa - poczekalnia',
            permits: ['officeLaryngologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/laryngologist/LaryngologistDashboardView.vue')
    },
    {
        name: 'laryngologist-appointment',
        path: '/laryngologist/appointment',
        meta: {
            desc: 'Wizyta laryngologa -  w trakcie',
            permits: []
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/laryngologist/LaryngologistAppointmentView.vue')
    },

    // nephrologist
    {
        name: 'nephrologist',
        path: '/nephrologist/dashboard',
        meta: {
            desc: 'Gabinet nefrologa - poczekalnia',
            permits: ['officeNephrologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/nephrologist/NephrologistDashboardView.vue')
    },
    {
        name: 'nephrologist-appointment',
        path: '/nephrologist/appointment',
        meta: {
            desc: 'Wizyta nefrologa -  w trakcie',
            permits: ['officeNephrologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/nephrologist/NephrologistAppointmentView.vue')
    },

    // oncologist
    {
        name: 'oncologist',
        path: '/oncologist/dashboard',
        meta: {
            desc: 'Gabinet onkologa - poczekalnia',
            permits: ['officeOncologist']
        },
        component: () => import(/* webpackChunkName: "pozds" */ '../views/offices/oncologist/OncologistDashboardView.vue')
    },
    {
        name: 'oncologist-appointment',
        path: '/oncologist/appointment',
        meta: {
            desc: 'Wizyta onkologa -  w trakcie',
            permits: ['officeOncologist']
        },
        component: () => import(/* webpackChunkName: "pozapp" */ '../views/offices/oncologist/OncologistAppointmentView.vue')
    },

    // assistant office
    {
        name: 'assistant',
        path: '/assistant',
        meta: {
            desc: 'Asystenci - poczekalnia',
            permits: ['officeAssistant']
        },
        component: () => import(/* webpackChunkName: "astant" */ '../views/offices/assistant/AssistantView.vue')
    },


    // **************************************************************************
    // OTHERS
    // **************************************************************************

    // 3D tester
    {
        name: '3d',
        path: '/3d',
        meta: {
            desc: 'Trzeci Wymiar SZOKa',
            permits: []
        },
        component: () => import(/* webpackChunkName: "3d" */ '../views/admin/SuperSecretView.vue')
    },

    // COLLECTION POINT
    {
        name: 'collection-point',
        path: '/collectionpoint',
        meta: {
            desc: 'Punkt pobrań',
            permits: ['collectionPoint']
        },
        component: () => import(/* webpackChunkName: "colpt" */ '../views/offices/collection-point/CollectionPointDashboardView.vue')
    },

    // Structure
    {
        name: 'structure',
        path: '/structure',
        meta: {
            desc: 'Struktura',
            permits: []
        },
        component: () => import(/* webpackChunkName: "struct" */ '../views/structure/StructureView.vue')
    },

    // Clinics
    {
        name: 'facilities',
        path: '/facilities',
        meta: {
            desc: 'Placówki',
            permits: ["admin"]
        },
        component: () => import(/* webpackChunkName: "faclil" */ '../views/facilities/FacilitiesView.vue')
    },

    // Buildings
    {
        name: 'buildings',
        path: '/buildings',
        meta: {
            desc: 'Nieruchomości',
            permits: ["admin"]
        },
        component: () => import(/* webpackChunkName: "blds" */ '../views/buildings/BuildingsView.vue')
    },


    // PATIENTS
    {
        name: 'patients',
        path: '/patients',
        meta: {
            desc: 'Baza pacjentów',
            permits: []
        },
        component: () => import(/* webpackChunkName: "patsmain" */ '../views/patient/PatientPanelView.vue')
    },

    // PATIENT PORTAL
    {
        name: 'patientportal',
        path: '/patientportal',
        meta: {
            desc: 'Portal Pacjenta',
            permits: ['patientPortal']
        },
        component: () => import(/* webpackChunkName: "ppmain" */ '../views/patient-portal/PatientPortalView.vue')
    },

    // PATIENT ARCHIVE
    {
        name: 'patient-archive',
        path: '/patientarchive',
        meta: {
            desc: 'Archiwum pacjenta',
            permits: ['patientArchive', 'patientArchiveExaminations', 'patientArchiveSummary', 'patientArchiveVisitsWorker']
        },
        component: () => import(/* webpackChunkName: "patsarch" */ '../views/patient-archive/PatientArchiveView.vue')
    },

    // REGISTRATION
    {
        name: 'registration',
        path: '/registration',
        meta: {
            desc: 'Rejestracja pacjenta',
            permits: ['registration']
        },
        component: () => import(/* webpackChunkName: "regmain" */ '../views/registration/PatientRegistrationView.vue')
    },
    {
        name: 'registration-callcenter',
        path: '/registration/callcenter',
        meta: {
            desc: 'Rejestracja pacjenta - Call Center',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "regmain" */ '../views/registration/RegistrationCallCenterView.vue')
    },
    {
        name: 'registration-open-visits',
        path: '/registration/openvisits',
        meta: {
            desc: 'Rejestracja pacjenta - Otwarte wizyty',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "regmain" */ '../views/registration/RegistrationOpenVisitsView.vue')
    },
    {
        name: 'registration-edeclaration',
        path: '/registration/edeclaration',
        meta: {
            desc: 'Rejestracja - E-deklaracje',
            permits: ['registrationEdekl']
        },
        component: () => import(/* webpackChunkName: "regedekl" */ '../views/registration/RegistrationEdeclarationView.vue')
    },


    // CALLCENTER
    {
        name: 'callcenter',
        path: '/callcenter',
        meta: {
            desc: 'Call Center',
            permits: []
        },
        component: () => import(/* webpackChunkName: "ccmain" */ '../views/call-center/CallCenterView.vue')
    },

    // ELEARNING
    {
        name: 'elearning',
        path: '/e-learning',
        meta: {
            desc: 'e-Learning',
            permits: ['eLearning']
        },
        component: () => import(/* webpackChunkName: "elmain" */ '../views/e-learning/ELearningView.vue')
    },
    {
        name: 'elearning-moderator',
        path: '/e-learning/moderator',
        meta: {
            desc: 'e-Learning - Panel administracyjny',
            permits: ['eLearningEditor', 'eLearningSuperEditor']
        },
        component: () => import(/* webpackChunkName: "eladm" */ '../views/e-learning/ELearningAdminView.vue')
    },
    {
        name: 'elearning-course',
        path: '/e-learning/course',
        meta: {
            desc: 'e-Learning - Kurs w trakcie',
            permits: ['eLearningEditor', 'eLearningSuperEditor']
        },
        component: () => import(/* webpackChunkName: "elcour" */ '../views/e-learning/ELearningCourseView.vue')
    },
    {
        name: 'elearning-new',
        path: '/e-learning/new',
        meta: {
            desc: 'e-Learning - Tworzenie nowego kursu',
            permits: ['eLearningEditor', 'eLearningSuperEditor']
        },
        component: () => import(/* webpackChunkName: "elnew" */ '../views/e-learning/ELearningCourseNewView.vue')
    },

    // USER MANUAL
    {
        name: 'user-manual',
        path: '/user-manual',
        meta: {
            desc: 'Instrukcja obsługi',
            permits: []
        },
        component: () => import(/* webpackChunkName: "elnew" */ '../views/user-manual/UserManualView.vue')
    },

    // {
    //     name: 'elearning-calendar',
    // path: '/e-learning/calendar',
    // meta: {
    //     desc: 'Terminarz szkoleń',
    //     permits: []
    // },
    //     component: () => import(/* webpackChunkName: "elnew" */ '../views/e-learning/ELearningCalendar.vue')
    // },

    // MOBI
    // {
    //     name: 'mobi-old',
    // path: '/mobi/old',
    // meta: {
    //     desc: 'MOBI Przesyłki',
    //     permits: []
    // },
    //     component: () => import(/* webpackChunkName: "mobold" */ '../views/mobi/MobiIndexView.vue')
    // },
    {
        name: 'mobi',
        path: '/mobi/dashboard',
        meta: {
            desc: 'MOBI Logistyka',
            permits: ['mobi', 'mobiLimited']
        },
        component: () => import(/* webpackChunkName: "moblgs" */ '../views/mobi/Mobi2View.vue')
    },

    // INVENTORY
    {
        name: 'inventory',
        path: '/invantory/panel',
        meta: {
            desc: 'Panel inwentaryzacji',
            permits: ['inventory']
        },
        component: () => import(/* webpackChunkName: "invpan" */ '../views/inventory/InventoryView.vue')
    },

    // ORDERS
    {
        name: 'orders-products',
        path: '/orders/products',
        meta: {
            desc: 'Baza produktów do zamówień',
            permits: ['orders']
        },
        component: () => import(/* webpackChunkName: "abt" */ '../views/orders/OrdersProductsView.vue')
    },
    {
        name: 'orders-create',
        path: '/orders/new',
        meta: {
            desc: 'Nowe zamówienie',
            permits: ['orders']
        },
        component: () => import(/* webpackChunkName: "ordn" */ '../views/orders/OrdersCreateView.vue')
    },
    {
        name: 'orders-suppliers',
        path: '/orders/suppliers',
        meta: {
            desc: 'Dostawcy',
            permits: ['orders']
        },
        component: () => import(/* webpackChunkName: "ordsup" */ '../views/orders/OrdersSuppliersView.vue')
    },
    {
        name: 'orders',
        path: '/orders/all',
        meta: {
            desc: 'Zamówienia',
            permits: ['orders']
        },
        component: () => import(/* webpackChunkName: "ordall" */ '../views/orders/OrdersListView.vue')
    },

    // MANAGERS
    {
        name: 'manager-cluster',
        path: '/manager/cluster',
        meta: {
            desc: 'Zarządzanie klastrem',
            permits: ["subadmin"]
        },
        component: () => import(/* webpackChunkName: "mgclus" */ '../views/manager/ClusterManagerView.vue')
    },
    {
        name: 'manager-region',
        path: '/manager/region',
        meta: {
            desc: 'Zarządzanie regionem',
            permits: ["subadmin"]
        },
        component: () => import(/* webpackChunkName: "mgreg" */ '../views/manager/RegionManagerView.vue')
    },
    {
        name: 'treatment-coordinator',
        path: '/coordinator',
        meta: {
            desc: 'Koordynacja leczenia',
            permits: ["subadmin"]
        },
        component: () => import(/* webpackChunkName: "coord" */ '../views/manager/TreatmentCoordinatorView.vue')
    },
    {
        name: 'system-configuration',
        path: '/manager/config',
        meta: {
            desc: 'Konfiguracja systemu',
            permits: ['subadmin']
        },
        component: () => import(/* webpackChunkName: "sysconf" */ '../views/manager/SystemConfigurationView.vue')
    },
    {
        name: 'config-editor',
        path: '/config/editorV2',
        meta: {
            desc: 'Edytor konfiguracji V2',
            permits: ['subadmin']
        },
        component: () => import(/* webpackChunkName: "cfgedt" */ '../views/config/EditorV2View.vue')
    },
    {
        name: 'icd-editor',
        path: '/config/icd10',
        meta: {
            desc: 'Edytor ICD 10/11',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "cfgicd" */ '../views/config/EditorIcdView.vue')
    },
    {
        name: 'privilege-editor',
        path: '/config/privileges',
        meta: {
            desc: 'Edytor typów uprawnień',
            permits: ['subadmin']
        },
        component: () => import(/* webpackChunkName: "cfgprv" */ '../views/config/EditorPrivilegesView.vue')
    },

    // SETTLEMENTS
    {
        name: 'settlements-panel',
        path: '/settlements',
        meta: {
            desc: 'Rozliczenia',
            permits: ['nfzSettlements']
        },
        component: () => import(/* webpackChunkName: "settlm" */ '../views/settlements/SettlementsPanelView.vue')
    },

    {
        name: 'settlements-invoices',
        path: '/settlements/invoices',
        meta: {
            desc: 'Faktury i szablony',
            permits: ['nfzSettlements']
        },
        component: () => import(/* webpackChunkName: "stlinv" */ '../views/settlements/SettlementsInvoicesView.vue')
    },

    {
        name: 'settlements-invoices-print',
        path: '/settlements/invprint',
        meta: {
            desc: 'Drukowanie rachunków NFZ',
            permits: ['settlementsInvoicesPrint']
        },
        component: () => import(/* webpackChunkName: "stlinv" */ '../views/settlements/SettlementsInvoicesPrintView.vue')
    },

    {
        name: 'umx-panel',
        path: '/umx',
        meta: {
            desc: 'Szczegóły umowy',
            permits: []
        },
        component: () => import(/* webpackChunkName: "umxdet" */ '../views/umx/UmxPanelView.vue')
    },

    {
        name: 'umx-schedule',
        path: '/umx/plan',
        meta: {
            desc: 'Plan miesięczny',
            permits: []
        },
        component: () => import(/* webpackChunkName: "umxplm" */ '../views/umx/UmxScheduleView.vue')
    },

    {
        name: 'umx-realisation',
        path: '/umx/realisation',
        meta: {
            desc: 'Realizacja planu miesięcznego',
            permits: []
        },
        component: () => import(/* webpackChunkName: "umxrls" */ '../views/umx/UmxRealisationView.vue')
    },

    {
        name: 'settlements-commercial',
        path: '/settlements/commercial',
        meta: {
            desc: 'Rozliczenia komercyjne',
            permits: ['nfzSettlements']
        },
        component: () => import(/* webpackChunkName: "stlcom" */ '../views/settlements/SettlementsCommercialPanelView.vue')
    },

    // SETTLEMENTS NEW

    {
        name: 'settlements-umx-dashboard',
        path: '/settlements/umx',
        meta: {
            desc: 'Umowy UMX',
            permits: ['nfzSettlements']
        },
        component: () => import(/* webpackChunkName: "stlumx" */ '../views/settlementsNew/SettlementsUmxDashboardView.vue')
    },

    {
        name: 'settlements-umx-services',
        path: '/settlements/umx/services',
        meta: {
            desc: 'Konfiguracja świadczeń',
            permits: ['settlementsServices']
        },
        component: () => import(/* webpackChunkName: "stlumxsrv" */  '../views/settlementsNew/SettlementsUmxServicesView.vue')
    },

    {
        name: 'settlements-services',
        path: '/settlements/services',
        meta: {
            desc: 'Świadczenia',
            permits: ['settlementsServices']
        },
        component: () => import(/* webpackChunkName: "stlsrv" */ '../views/settlementsNew/SettlementsServicesView.vue')
    },

    {
        name: 'settlements-ikz-manager',
        path: '/settlements/ikz/manager',
        meta: {
            desc: 'IKZ',
            permits: ['settlementsIKZ']
        },
        component: () => import(/* webpackChunkName: "stlikzmng" */ '../views/settlementsNew/SettlementsIKZManagerView.vue')
    },

    {
        name: 'settlements-ikz-worker',
        path: '/settlements/ikz/worker',
        meta: {
            desc: 'IKZ - Pracownicy',
            permits: ['settlementsIKZ']
        },
        component: () => import(/* webpackChunkName: "stlikzwrk" */ '../views/settlementsNew/SettlementsIKZWorkerView.vue')
    },

    {
        name: 'settlements-ikz-invoices',
        path: '/settlements/ikz/invoices',
        meta: {
            desc: 'IKZ - Faktury',
            permits: ['settlementsIKZ']
        },
        component: () => import(/* webpackChunkName: "stlikzinv" */ '../views/settlementsNew/SettlementsIKZInvoicesView.vue')
    },

    {
        name: 'settlements-ikz-imports',
        path: '/settlements/ikz/imports',
        meta: {
            desc: 'IKZ - Importy',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "stlikzinv" */ '../views/settlementsNew/SettlementsIKZImportsView.vue')
    },

    {
        name: 'settlements-ikz-stats-test',
        path: '/settlements/ikz/imports-stats-test',
        meta: {
            desc: 'IKZ - Importy - Statystyki',
            permits: []
        },
        component: () => import(/* webpackChunkName: "stlikzinv" */ '../views/settlementsNew/SettlementsIKZImportsStatsView.vue')
    },

    {
        name: 'cash-registers',
        path: '/cash-registers',
        meta: {
            desc: 'Kasy fiskalne - zarejestrowane transakcje',
            permits: ['settlementsCashRegister']
        },
        component: () => import(/* webpackChunkName: "cshreg" */ '../views/cash-registers/CashRegistersView.vue')
    },

    // SUMMARY BOOK NEW

    {
        name: 'reports',
        path: '/reports',
        meta: {
            desc: 'Zestawienia',
            permits: ['settlementsReports']
        },
        component: () => import(/* webpackChunkName: "sumbook" */ '../views/reports/ReportsView.vue')
    },

    {
        name: 'summary-book',
        path: '/summary-book',
        meta: {
            desc: 'Stany realizacji',
            permits: ['settlementsSummary']
        },
        component: () => import(/* webpackChunkName: "sumbook" */ '../views/summary-book/SummaryBookView.vue')
    },

    // ADMINISTRATION
    {
        name: 'company-structures',
        path: '/company/structures',
        meta: {
            desc: 'Administracja i struktury',
            permits: ['subadmin', 'administration', 'administrationContract', 'administrationStructure']
        },
        component: () => import(/* webpackChunkName: "cmpstruct" */ '../views/administration/AdministrationPanelView.vue')
    },

    {
        name: 'subjects-med',
        path: '/subjects/med',
        meta: {
            desc: 'Kontrahenci medyczni',
            permits: ['subadmin', 'administration']
        },
        component: () => import(/* webpackChunkName: "submed" */ '../views/administration/AdministrationMedSubjectsView.vue')
    },

    {
        name: 'prices',
        path: '/prices',
        meta: {
            desc: 'Cenniki',
            permits: ['prices']
        },
        component: () => import(/* webpackChunkName: "prces" */ '../views/prices/PricesView.vue')
    },


    // DRUG CONTROL
    {
        name: 'drug-control',
        path: '/drugs/control',
        meta: {
            desc: 'Kontrola leków',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "cmpstruct" */ '../views/drugs/DrugControlView.vue')
    },

    //ARCHIVE
    {
        name: 'archive-panel',
        path: '/archive',
        meta: {
            desc: 'Archiwum',
            permits: []
        },
        component: () => import(/* webpackChunkName: "arch" */ '../views/archive/ArchivePanelView.vue')
    },

    //APPOINTMENT BOOK
    {
        name: 'appointment-book',
        path: '/appointmentbook',
        meta: {
            desc: 'E-księga wizyt',
            permits: ['settlementsServices']
        },
        component: () => import(/* webpackChunkName: "visbook" */ '../views/appointment-book/AppointmentBookView.vue')
    },

    //APPOINTMENT BOOK RESERVATIONS
    {
        name: 'appointment-book-reservations',
        path: '/appointmentbook/reservations',
        meta: {
            desc: 'E-księga rezerwacji wizyt',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "visbookres" */ '../views/appointment-book/AppointmentBookReservationsView.vue')
    },


    //APPOINTMENT EREFERRAL
    {
        name: 'appointment-ereferral',
        path: '/appointmentereferral',
        meta: {
            desc: 'E-skierowania',
            permits: ['eReferralBook']
        },
        component: () => import(/* webpackChunkName: "viseref" */ '../views/appointment-ereferral/AppointmentEreferralView.vue')
    },

    //APPOINTMENT EREFERRAL
    {
        name: 'tickets-book',
        path: '/ticketsbook',
        meta: {
            desc: 'Bilety',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "ticbook" */ '../views/tickets/TicketsBookView.vue')
    },

    {
        name: 'tickets-book-new',
        path: '/ticketsbookNew',
        meta: {
            desc: 'Zlecenia',
            permits: ['settlementsOrdersAndTickets']
        },
        component: () => import(/* webpackChunkName: "ticbook" */ '../views/tickets/TicketsBookNewView.vue')
    },

    // {
    //     name: 'solab-examinations',
    // path: '/solabexams',
    // meta: {
    //     desc: 'Badania SOLAB',
    //     permits: []
    // },
    //     component: () => import(/* webpackChunkName: "ticbook" */ '../views/solab/SolabExaminationsView.vue')
    // },


    // TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST
    {
        name: 'test-menu',
        path: '/test/menu',
        meta: {
            desc: 'Lekarz - menu',
            permits: []
        },
        component: () => import(/* webpackChunkName: "tsmenu" */ '../views/test_office/TestOfficeMenuView.vue')
    },
    {
        name: 'test-panel',
        path: '/test/panel',
        meta: {
            desc: 'Lekarz - dokumenty',
            permits: []
        },
        component: () => import(/* webpackChunkName: "tspan" */ '../views/test_office/TestOfficePanelView.vue')
    },
    {
        name: 'test-office',
        path: '/tpk/office',
        meta: {
            desc: 'Gabinet lekarski - wizyta',
            permits: []
        },
        component: () => import(/* webpackChunkName: "tsoffi" */ '../views/test_office/TestOfficeVisitView.vue')
    },
    {
        name: 'tpk-archive',
        path: '/tpk/archive',
        meta: {
            desc: 'TPK: Archiwum',
            permits: []
        },
        component: () => import(/* webpackChunkName: "tpkarch" */ '../views/tpk/TpkArchiveView.vue')
    },
    {
        name: 'tpk-worktimes',
        path: '/tpk/worktimes',
        meta: {
            desc: 'TPK: Zarządzanie terminarzem',
            permits: []
        },
        component: () => import(/* webpackChunkName: "tpkwork" */ '../views/tpk/TpkWorktimeManagerView.vue')
    },

    {
        name: 'tele-office',
        path: '/tele/office',
        meta: {
            desc: 'TeleSZOK: Gabinet lekarski - wizyta',
            permits: []
        },
        component: () => import(/* webpackChunkName: "tloffi" */ '../views/tele/TeleOfficeView.vue')
    },
    {
        name: 'tele-archive',
        path: '/tele/archive',
        meta: {
            desc: 'TeleSZOK: Archiwum',
            permits: []
        },
        component: () => import(/* webpackChunkName: "tlarch" */ '../views/tele/TeleArchiveView.vue')
    },
    {
        name: 'tele-my-account',
        path: '/tele/account',
        meta: {
            desc: 'TeleSZOK: Moje konto',
            permits: ['myAccount']
        },
        component: () => import(/* webpackChunkName: "tlaccs" */ '../views/home/MyAccountViewTele.vue')
    },

    //CASE CONFERENCE
    {
        name: 'case-conference',
        path: '/case-conference',
        meta: {
            desc: 'Telekonsylium',
            permits: ['subadmin']
        },
        component: () => import(/* webpackChunkName: "lbcytdirt" */ '../views/case-conference/CaseConferenceDashboardView.vue')
    },

    //PROJECTS
    {
        name: 'projects-diabetes-patients-list',
        path: '/projects/diabetes/patients',
        meta: {
            desc: 'Diabetologia. Kwalifikacja do programu - lista pacjentów',
            permits: ['subadmin']
        },
        component: () => import(/* webpackChunkName: "diabpat" */ '../views/projects/diabetes/ProjectsDiabetesPatientsListView.vue')
    },
    {
        name: 'projects-osteoporosis-patients-list',
        path: '/projects/osteoporosis/patients',
        meta: {
            desc: 'Osteoporoza. Kwalifikacja do programu - lista pacjentów',
            permits: ['projectOsteoporosis']
        },
        component: () => import(/* webpackChunkName: "diabpat" */ '../views/projects/osteoporosis/ProjectsOsteoporosisPatientsListView.vue')
    },
    {
        name: 'projects-tomography-patients-list',
        path: '/projects/tomography/patients',
        meta: {
            desc: 'Niskodawkowa tomografia płuc. Kwalifikacja do programu - lista pacjentów',
            permits: ['projectTomography']
        },
        component: () => import(/* webpackChunkName: "tomopat" */ '../views/projects/tomography/ProjectsTomographyPatientsListView.vue')
    },
    {
        name: 'projects-40-plus-list',
        path: '/projects/40plus/patients',
        meta: {
            desc: 'Program 40+. Lista pacjentów programu',
            permits: ['project40Plus']
        },
        component: () => import(/* webpackChunkName: "40pluspat" */ '../views/projects/40plus/Projects40PlusPatientsListView.vue')
    },
    {
        name: 'projects-management',
        path: '/projects/management',
        meta: {
            desc: 'Zarządzanie projektami',
            permits: ['projectManagement']
        },
        component: () => import(/* webpackChunkName: "prmanag" */ '../views/projects-management/ProjectsManagementView.vue')
    },
    {
        name: 'projects-population-config',
        path: '/projects/config',
        meta: {
            desc: 'Ścieżki - konfiguracja',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "projconf" */ '../views/projects/config/ProjectsConfigView.vue')
    },
    {
        name: 'projects-population-realizations-list',
        path: '/projects/config',
        meta: {
            desc: 'Realizacje programów populacyjnych',
            permits: ['administrationPopulationManagement']
        },
        component: () => import(/* webpackChunkName: "projconf" */ '../views/projects/config/ProjectsRealizationsView.vue')
    },

    // PATIENT-MANAGEMENT
    {
        name: 'patient-management',
        path: '/patient-management',
        meta: {
            desc: 'Zarządzanie pacjentem',
            permits: ['administrationPatientCoordination']
        },
        component: () => import(/* webpackChunkName: "patsmang" */ '../views/patient-management/PatientManagementView.vue')
    },

    // MED-QUESTIONS
    {
        name: 'med-questions',
        path: '/med-questions',
        meta: {
            desc: 'Szablony pytań',
            permits: ['admin', 'subadmin']
        },
        component: () => import(/* webpackChunkName: "medquests" */ '../views/med-questions/MedQuestionsView.vue')
    },

    // RECOMMENDATIONS
    {
        name: 'recommendations',
        path: '/recommendations',
        meta: {
            desc: 'Zalecenia',
            permits: ['admin']
        },
        component: () => import(/* webpackChunkName: "recommend" */ '../views/recommendations/RecommendationsView.vue')
    },
]