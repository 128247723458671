<template>
  <div>
    <p>
      <b-button :icon-left="buttonIcon" @click="isActiveModal = true" size="is-medium" expanded :type="buttonType">
        {{ buttonText }}
      </b-button>
    </p>

    <b-modal
      :active.sync="isActiveModal"
      :destroy-on-hide="true"
      scroll="keep"
      has-modal-card
      :can-cancel="false"
      style="z-index: 100"
      class="chatsize"
    >
      <ContentPanel
        :rounded="true"
        :paddingless="true"
        :title="`Dodawanie skierowania - ${clinicTypePropsName}, ${officeFacilityName}`"
        icon="format-list-bulleted-type"
      >
        <template slot="addon">
          <b-button icon-left="close" size="is-small" @click="closeWindow" type="is-danger">Zamknij</b-button>
        </template>

        <div class="modal-card" style="overflow: visible; width: 100% !important; max-height: 88vh; min-height: 80vh">
          <section class="modal-card-body" style="color: black">
            <b-steps
              v-model="tab"
              :rounded="false"
              :has-navigation="false"
              :animated="false"
              size="is-small"
              label-position="right"
            >
              <b-step-item label="Rodzaj skierowania" icon="format-list-bulleted-type" :clickable="false">
                <ValidationObserver ref="observerReferral" v-slot="{ invalid }">
                  <!-- <div class="block"> -->
                  <div class="columns">
                    <div class="column ml-5 mr-5">
                      <!--------------------------------------------------------->
                      <!--******************* PATIENT DATA *******************-->
                      <!--------------------------------------------------------->

                      <ContentPanel
                        class="mb-0"
                        title="Dane pacjenta"
                        icon="account"
                        :borderless="false"
                        v-if="patient"
                      >
                        <table class="table is-narrow border-bottom" style="width: 100%">
                          <tr>
                            <td>Imię i nazwisko:</td>
                            <td class="has-text-right has-text-weight-medium">
                              {{ patient.firstName }} {{ patient.lastName }}
                            </td>
                          </tr>
                          <tr v-if="patient.pesel">
                            <td>Pesel:</td>
                            <td class="has-text-right has-text-weight-medium">{{ patient.pesel }}</td>
                          </tr>
                          <tr v-if="!patient.pesel">
                            <td>Dokument tożsamości:</td>
                            <td class="has-text-right has-text-weight-medium">
                              <span
                                v-if="patient.identityDocumentNumber != null && patient.identityDocumentNumber != ''"
                                >{{ patient.identityDocumentNumber }}</span
                              >
                              <span v-else>-</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Adres zamieszkania:</td>
                            <td class="has-text-right has-text-weight-medium">
                              {{ patient.addressStreet }}
                              {{ patient.addressHouseNumber }}
                              <span
                                v-if="patient.addressApartmentNumber != null && patient.addressApartmentNumber != ''"
                                >/{{ patient.addressApartmentNumber }}</span
                              >
                              , {{ patient.addressCity }}
                            </td>
                          </tr>
                          <tr>
                            <td>Telefon</td>
                            <td class="has-text-right has-text-weight-medium">
                              {{ patient.teleAddressTelephoneNumber }}
                            </td>
                          </tr>
                        </table>
                      </ContentPanel>

                      <!--------------------------------------------------------->
                      <!--------------------------------------------------------->
                      <!--------------------------------------------------------->
                    </div>
                    <div class="column ml-5 mr-5">
                      <!--------------------------------------------------------->
                      <!--******************* REFERRAL TYPE *******************-->
                      <!--------------------------------------------------------->

                      <ContentPanel title="Rodzaj skierowania" icon="format-list-bulleted-type" :borderless="false">
                        <section>
                          <b-field v-for="item in filteredReferralsType" :key="item.id">
                            <b-radio v-model="referralType" name="name" :native-value="item">{{ item.name }}</b-radio>
                          </b-field>
                        </section>
                      </ContentPanel>

                      <!--------------------------------------------------------->
                      <!--------------------------------------------------------->
                      <!--------------------------------------------------------->

                      <!--------------------------------------------------------->
                      <!--******************* E-REFERRAL *******************-->
                      <!--------------------------------------------------------->
                      <template v-if="referralType && referralType.isPaperReferral == false">
                        <ContentPanel
                          title="Rodzaj e-skierowania"
                          icon="format-list-bulleted-square"
                          :borderless="false"
                        >
                          <AppointmentAddVisitReferralP1
                            :officeId="officeId"
                            :trigger="triggerP1"
                            :appointmentType="appointmentType"
                            :registrationData="{
                              destinationType: 1,
                              referralTypeRegistration: referralType.id
                            }"
                            @code-change="codeP1Change"
                            @eReferral-success="eReferralSuccess"
                            @eReferral-failure="eReferralFailure"
                            @eReferral-confirm="addIkzReferralConfirm"
                            @eReferral-refuse="addIkzReferralRefuse"
                            @eReferral-resign="addIkzReferralResign"
                          />
                        </ContentPanel>
                      </template>
                      <!--------------------------------------------------------->
                      <!--------------------------------------------------------->
                      <!--------------------------------------------------------->

                      <!--------------------------------------------------------->
                      <!--******************* PAPER REFERRAL *******************-->
                      <!--------------------------------------------------------->
                      <template v-else-if="referralType && referralType.isPaperReferral == true">
                        <ContentPanel title="Plik skierowania" icon="file" :borderless="false">
                          <p v-if="file" class="pb-3 has-text-weight-semibold">{{ file.name }}</p>
                          <b-field grouped group-multiline class="pb-4">
                            <b-field class="file">
                              <b-upload
                                v-model="file"
                                @input="checkFileSize"
                                accept=".pdf, .jpg, .jpeg, .png"
                                :disabled="!referralType || referralFileId != null"
                              >
                                <a class="button">
                                  <b-icon icon="folder-open-outline"></b-icon>
                                  <span v-if="file">Zmień plik</span>
                                  <span v-else>Wybierz plik skanu</span>
                                </a>
                              </b-upload>
                            </b-field>
                            <b-field>
                              <b-button
                                @click="sendScan"
                                type="is-secondary"
                                :loading="loading"
                                icon-left="upload"
                                :disabled="!patient || !file"
                                >Prześlij skan na serwer</b-button
                              >
                            </b-field>
                          </b-field>
                          <b-message v-if="referralFileId != null">Plik skierowania dodany</b-message>
                          <b-field>
                            <b-checkbox
                              :disabled="referralFileId != null"
                              v-model="withoutScanFile"
                              type="is-secondary"
                            >
                              Skan skierowania zostanie dodany później
                            </b-checkbox>
                          </b-field>
                          <b-field>
                            <b-checkbox type="is-orange" v-model="hasReferralInOtherSystem">
                              Skierowanie zarejestrowano w innym systemie świadczeniodawcy
                              <b-tooltip
                                multilined
                                label="Zaznacz, jeżeli skierowanie wygenerowano lub zeskanowano w innym systemie twojej organizacji."
                                class="pl-2"
                              >
                                <b-icon icon="alert-circle-outline" type="is-orange"></b-icon>
                              </b-tooltip>
                            </b-checkbox>
                          </b-field>
                        </ContentPanel>
                      </template>
                      <!--------------------------------------------------------->
                      <!--------------------------------------------------------->
                      <!--------------------------------------------------------->
                    </div>
                  </div>
                  <div>
                    <b-field class="has-text-centered">
                      <b-button
                        v-if="referralType && referralType.isPaperReferral == false"
                        :loading="isLoading"
                        type="is-secondary"
                        @click="referralTrigger"
                        icon-right="arrow-right-bold"
                        :disabled="
                          !patient ||
                          (!referralType && !codeP1.referralId && !codeP1.referralCode && !codeP1.referralPesel)
                        "
                        >Pobierz skierowanie</b-button
                      >
                      <b-button
                        v-else
                        style="width: 12rem"
                        :loading="isLoading"
                        class="mt-3"
                        type="is-primary"
                        @click="nextForPaperReferral"
                        icon-right="arrow-right-bold"
                        :disabled="invalid || !referralType || !patient"
                        >Dalej</b-button
                      >
                    </b-field>
                  </div>
                </ValidationObserver>
              </b-step-item>
              <b-step-item label="Szczegóły skierowania" icon="file" :clickable="false">
                <ValidationObserver ref="observerDoctor" v-slot="{ invalid }">
                  <div class="columns is-centered">
                    <div class="column" v-if="referralType && referralType.isPaperReferral == false">
                      <section class="section" v-if="resultP1 && resultP1.eSkierowanie">
                        <iframe
                          v-if="resultP1.eSkierowanie"
                          :srcdoc="resultP1.eSkierowanie"
                          style="width: 105%; height: 24rem"
                        ></iframe>
                      </section>
                    </div>
                    <div class="column ml-5 mr-5">
                      <ContentPanel title="Dane pacjenta" icon="account" :borderless="false" v-if="patient">
                        <table class="table is-narrow border-bottom" style="width: 100%">
                          <tr>
                            <td>Imię i nazwisko:</td>
                            <td class="has-text-right has-text-weight-medium">
                              {{ patient.firstName }} {{ patient.lastName }}
                            </td>
                          </tr>
                          <tr v-if="patient.pesel">
                            <td>Pesel:</td>
                            <td class="has-text-right has-text-weight-medium">{{ patient.pesel }}</td>
                          </tr>
                          <tr v-if="!patient.pesel">
                            <td>Dokument tożsamości:</td>
                            <td class="has-text-right has-text-weight-medium">
                              <span
                                v-if="patient.identityDocumentNumber != null && patient.identityDocumentNumber != ''"
                                >{{ patient.identityDocumentNumber }}</span
                              >
                              <span v-else>-</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Telefon</td>
                            <td class="has-text-right has-text-weight-medium">
                              {{ patient.teleAddressTelephoneNumber }}
                            </td>
                          </tr>
                        </table>
                      </ContentPanel>

                      <ContentPanel title="Dane skierowania" icon="file" :borderless="false">
                          <b-field expanded>
                            <ValidationProvider
                              :rules="{
                                required: true,
                                input_date: true,
                              }"
                              v-slot="{ errors, valid }"
                              immediate
                              expanded
                            >
                              <b-field
                                label="Data wystawienia (obowiązkowe)"
                                label-position="on-border"
                                :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }"
                                :message="t(errors[0])"
                                expanded
                              >
                                <b-input
                                  type="date"
                                  min="2000-01-01"
                                  v-model="referralApkolce.issueDate"
                                  icon="alert-circle-outline"
                                  expanded
                                ></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>

                          <Icd10SearchBar
                            @selected="choosenIcd10"
                            :referralIcd10Code="referralIcd10Code"
                            labelText="Wybierz rozpoznanie główne (wymagane)"
                            class="is-small"
                          ></Icd10SearchBar>
                          <div v-if="selectedIcd10" style="width: 100%" class="mb-3">
                            <p>
                              Wybrane główne rozpoznanie:
                              <span class="is-pulled-right">
                                <b-tooltip label="Usuń" type="is-danger">
                                  <b-icon
                                    @click.native="removeIcd10()"
                                    icon="close-circle-outline"
                                    type="is-danger"
                                    style="cursor: pointer"
                                  ></b-icon>
                                </b-tooltip>
                              </span>
                            </p>

                            <p class="has-text-success has-text-weight-medium mt-1 ml-2">
                              {{ selectedIcd10.code }} - {{ selectedIcd10.desc }}
                            </p>
                          </div>
                          <div v-else style="font-size: 0.9rem; width: 100%">
                            <p class="has-text-danger has-text-weight-medium mt-1 ml-2">Brak wybranego rozpoznania</p>
                          </div>
                      </ContentPanel>
                    </div>
                    <div class="column ml-5 mr-5">
                      <ContentPanel title="Badanie" icon="plus-box">
                        <b-field label="Wybierz z puli badań dla programu 40+" label-position="on-border">
                          <b-autocomplete
                            ref="labExams40Plus"
                            v-model="newProcedureName"
                            @select="addExamination"
                            placeholder="Zacznij pisać, aby wyświetlić sugerowane badanie (min. 2 znaki)"
                            :open-on-focus="true"
                            :data="labExamsFiltered"
                            field="name"
                            :max-height="375"
                            clearable
                            clear-on-select
                            expanded
                          >
                            <template slot-scope="props">
                              <table class="w-100">
                                <tr>
                                  <td style="width: 2rem" class="has-text-weight-semibold pr-3">
                                    <b-icon v-if="referralProcedures.find(p => p.procedureId === props.option.internalId)" icon="checkbox-outline" type="is-success"></b-icon>
                                    <b-icon v-else icon="checkbox-blank-outline"></b-icon>
                                  </td>
                                  <td style="white-space: pre-wrap" class="has-text-weight-semibold">
                                    <p>{{ props.option.codeICD9 }} - {{ props.option.name }}</p>
                                  </td>
                                </tr>
                              </table>
                            </template>
                          </b-autocomplete>
                        </b-field>

                        <b-table :data="referralProcedures" narrowed hoverable>
                          <b-table-column
                            v-slot="props"
                            sortable
                            field="icd9"
                            label="ICD-9"
                            width="16%"
                            class="has-text-weight-semibold"
                            centered
                          >
                            {{ props.row.icd9 }}
                          </b-table-column>
                          <b-table-column v-slot="props" sortable field="description" label="Nazwa badania">
                            {{ props.row.description }}
                          </b-table-column>
                          <b-table-column v-slot="props" width="1">
                            <b-tooltip label="Usuń" type="is-danger">
                              <b-icon
                                @click.native="referralProcedures.splice(props.index, 1)"
                                icon="close-circle-outline"
                                type="is-danger"
                                style="cursor: pointer"
                              ></b-icon>
                            </b-tooltip>
                          </b-table-column>

                          <template slot="empty">
                            <p class="has-text-danger has-text-weight-medium">Brak dodanych badań</p>
                          </template>
                        </b-table>
                      </ContentPanel>

                      <div class="mt-3">
                        <b-button @click="previewStep" :loading="isLoading" icon-left="menu-left" class="mr-2"
                          >Poprzedni krok</b-button
                        >
                        <b-button
                          class="is-pulled-right"
                          :loading="isLoading"
                          style="width: 12rem"
                          type="is-secondary"
                          @click="generateReferralTicket"
                          icon-left="check"
                          size="is-medium"
                          :disabled="
                            invalid ||
                            patient == null ||
                            selectClinicType == null ||
                            referralProcedures.length < 1 || 
                            !selectedIcd10
                          "
                          >Realizuj</b-button
                        >
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </b-step-item>
            </b-steps>
          </section>
        </div>
      </ContentPanel>
    </b-modal>

    <AppointmentCancelReferralP1
      @eReferral-canceled="cancelledEReferral"
      :trigger="triggerCancelReferral"
      :result="resultP1"
    />
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons"
import OrdersMixins from "@/mixins/orders/poz_orders"
import DateHelpers from "@/mixins/date_helpers"
import { Action } from "@/store/config_actions"
import { Mutation } from "@/store/config_mutations"
// import ContentPanel from "@/components/tools/ContentPanel";
import moment from "moment"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import ReferralMixins from "@/mixins/referrals.js"
import IkzExam from "@/mixins/ikz_exams.js"
import risMixins from "@/mixins/ris"
import Icd10SearchBar from "@/components/search-engines/Icd10SearchBar"

import AppointmentAddVisitReferralP1 from "@/components/appointments/AppointmentAddVisitReferralP1Ver2"
import AppointmentCancelReferralP1 from "@/components/appointments/AppointmentCancelReferralP1"

// OBSOLETE - const Exams40PlusIds = [45, 256, 1087, 333, 208, 209, 327, 1075, 1, 1081, 11, 1182, 232]

export default {
  name: "ScheduleModalAddReferral",
  mixins: [CommonHelpers, OrdersMixins, ReferralMixins, IkzExam, risMixins, DateHelpers],
  components: {
    //  ContentPanel,
    ValidationProvider,
    ValidationObserver,
    AppointmentAddVisitReferralP1,
    AppointmentCancelReferralP1,
    Icd10SearchBar,
  },

  props: {
    clinicType: { type: Number, required: true },
    appointmentType: { type: Object, required: true },
    officeId: { type: String, required: false },
    patient: { type: Object, required: true },
    appointmentPayer: { type: Number, required: true },
  },

  data: function () {
    return {
      isActiveModal: false,
      isLoading: false,
      loading: false,
      tab: 0,

      buttonText: "Dodaj skierowanie",
      buttonIcon: "plus",
      buttonType: "is-orange",
      hasReferralInOtherSystem: false,
      referralType: null,
      withoutScanFile: false,
      referralFileId: null,

      payerType: -1,

      selectClinicType: null,

      referralProcedures: [],

      resultP1: null,
      triggerP1: 0,
      codeP1: {
        referralId: "",
        referralCode: "",
        referralPesel: "",
      },

      file: null,
      fileDescription: "",

      triggerCancelReferral: 0,
      endVisit: false,

      referralIcd10Code: null,
      selectedIcd10: null,
      selectedIcd10Additional: [],

      referralApkolce: {
        subject: "",
        type: 0,
        typeIdent: null,
        issueDate: null,
        regon: "",
        code1st: "",
        code7th: "",
        code8th: "",
        musCode: "",
      },

      ticketsToSend: [],

      newProcedureName: "",
    }
  },

  mounted() {
    if (this.clinicType) {
      let found = this.configClinics.find((clinic) => clinic.id == this.clinicType)
      if (found) {
        this.selectClinicType = found
      }
    }

    this.referralType = null

    this.buttonText = "Dodaj skierowanie (wymagane)"
    this.buttonType = "is-orange"
    this.buttonIcon = "plus"
  },

  watch: {
    currentClinicType() {
      this.selectClinicType = this.currentClinicType
    },

    referralType() {
      if (this.referralType) {
        this.payerType = this.referralType.defaultPayer
      }

      if (this.referralType) {
        this.fileDescription = `${this.referralType.name} - ${this.clinicTypePropsName}`
      }

      if (this.referralType) {
        this.referralApkolce = {
          subject: "",
          type: 0,
          typeIdent: null,
          issueDate: null,
          regon: "",
          code1st: "",
          code7th: "",
          code8th: "",
          musCode: "",
        }
      }
    },

    filteredReferralsType() {
      if (this.filteredReferralsType && this.filteredReferralsType.length > 0) {
        if (!this.referralType) {
          this.referralType = this.filteredReferralsType[0]
        } else {
          let index = this.filteredReferralsType.indexOf(this.referralType)

          if (index > -1) {
            //do nothing
          } else {
            this.referralType = this.filteredReferralsType[0]
          }
        }
      } else {
        //do nothing
        this.referralType = null
      }
    },

    appointmentPayer() {
      if (this.ticketsToSend.length < 1) {
        this.buttonText = "Dodaj skierowanie (wymagane)"
        this.buttonType = "is-orange"
        this.buttonIcon = "plus"
      }
    },
  },

  computed: {
    me() {
      return this.$store.state.employee.me
    },
    meParentFacilityObject() {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.internalId === this.me.parentFacility)
        return found
      } else return null
    },
    registrationIkz() {
      return this.$store.state.registration.ikz
    },
    offices() {
      return this.$store.state.offices.offices
    },

    currentClinicType() {
      if (this.clinicType) {
        let found = this.configClinics.find((clinic) => clinic.id == this.clinicType)
        if (found) {
          return found
        }
      }
      return null
    },

    facilities() {
      return this.$store.state.clinic.clinics
    },

    configClinics() {
      return this.$store.state.config.clinics ?? []
    },

    referralsType() {
      return this.$store.state.config.referrals ?? []
    },

    clinicReferralTypes() {
      let filtered = []
      if (this.selectClinicType) {
        if (this.selectClinicType.referrals && this.selectClinicType.referrals.length > 0) {
          this.selectClinicType.referrals.forEach((option) => {
            let found = this.referralsType.find((refType) => refType.id == option)
            if (found) {
              if (!filtered.includes(found)) {
                filtered.push(found)
              }
            }
          })
        }
      }

      return filtered
    },

    filteredReferralsType() {
      //if appointmentType is null or undefined return empty array
      if (this.appointmentType == null || this.appointmentType == undefined) return []

      //get referralTypes from appoinmentType
      if (this.appointmentType.referrals != null && this.appointmentType.referrals.length > 0) {
        let filtered = []
        this.appointmentType.referrals.forEach((option) => {
          let found = this.referralsType.find((refType) => refType.id == option)
          if (found && found.isPaperReferral === false) {
            if (!filtered.includes(found)) {
              filtered.push(found)
            }
          }
        })
        return filtered
      }

      //get referralTypes from clinic and filter by payers
      if (this.clinicReferralTypes && this.appointmentType) {
        return this.clinicReferralTypes.filter((element) => {
          let found = element.payers.some((r) => this.appointmentType.payers.includes(r))

          if (found && element.isPaperReferral === false) {
            return true
          } else {
            return false
          }
        })
      }

      return []
    },

    clinicTypePropsName() {
      let found = this.configClinics.find((clinic) => clinic.id == this.clinicType)
      if (found) {
        return found.shortName
      }

      return "Brak informacji"
    },

    officeFacilityName() {
      let off = this.offices.find((office) => office.id == this.officeId)

      if (off) {
        return this.getFacility(off.facilityId)
      }

      return "Brak informacji"
    },

    typesExamLab() {
      return this.$store.state.results.examLabType ?? []
    },

    typesExamLabTranslate() {
      return this.typesExamLab.filter(examLab => examLab.additionalGroups && examLab.additionalGroups.includes(1))
    },

    labExamsFiltered() {
      let typesExamLab = this.typesExamLabTranslate

      typesExamLab = typesExamLab.filter((option) => {
        return (
          (option.name.toString().toLowerCase().indexOf(this.newProcedureName.toLowerCase()) >= 0) ||
          (option.codeICD9.toString().toLowerCase().indexOf(this.newProcedureName.toLowerCase()) >= 0)
        )
      })

      return typesExamLab
    },
  },

  methods: {
    addExamination(procedure) {
      if (procedure) {
        let indexOfProcedure = this.referralProcedures.findIndex(p => p.procedureId === procedure.internalId)
        if (indexOfProcedure > -1) {
          this.referralProcedures.splice(indexOfProcedure, 1)
        } else {
          let newProcedure = JSON.parse(JSON.stringify(this.ticketProcedure))
          newProcedure.procedureId = procedure.internalId
          newProcedure.ticketType = this.appointmentType.id
          newProcedure.description = procedure.name
          newProcedure.icd9 = procedure.codeICD9

          this.referralProcedures.push(newProcedure)
        }
      }

      this.newProcedureName = ""

      this.$nextTick(() => {
        this.$refs.labExams40Plus.focus()
      })
    },

    goNext() {
      if (this.referralType != null) {
        this.tab = 1
      } else {
        this.dangerSnackbar("Wybierz rodzaj skierowania")
      }
    },

    previewStep() {
      if (this.tab == 1 && this.resultP1) {
        this.successSnackbar("Anulowanie skierowania")
        this.triggerCancelReferral += 1
      } else {
        this.tab--
      }
    },

    nextForPaperReferral() {
      if (this.referralType != null) {
        this.tab = 1
      } else {
        this.dangerSnackbar("Wybierz rodzaj skierowania")
      }
    },

    closeWindow() {
      this.$buefy.dialog.confirm({
        message: "Chcesz przerwać dodawanie skierowania? Zmiany nie zostaną zapisane",
        confirmText: "Zakończ",
        cancelText: "Anuluj",
        type: "is-danger",
        hasIcon: true,
        scroll: "keep",
        onConfirm: () => {
          this.isActiveModal = false
          this.endVisit = true
          this.isLoading = false

          if (this.resultP1 != null) {
            this.successSnackbar("Anulowanie skierowania")
            this.triggerCancelReferral += 1
          } else {
            this.finishReferralP1()
            this.addIkzReferralAddCancel()
          }
        },
      })
    },

    cancelledEReferral(responseReferral) {
      this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false)
      this.$store.commit(Mutation.REGISTRATION_ADD_EREFERRAL, responseReferral)

      if (this.endVisit === true) {
        this.finishReferralP1()
        this.addIkzEReferralCancel()
        this.addIkzReferralAddCancel()
      } else {
        this.tab -= 1
        this.resultP1 = null
        this.addIkzEReferralCancel()
      }
    },

    finishReferralP1() {
      this.isActiveModal = false
      //this.selectClinicType = null;
      this.tab = 0
      this.referralProcedures = []
      this.referralFileId = null
      this.withoutScanFile = false

      this.referralType = null
      this.payerType = -1

      this.resultP1 = null
      this.codeP1 = {
        referralId: "",
        referralCode: "",
        referralPesel: "",
      }
      this.triggerP1 = 0

      this.triggerCancelReferral = 0
      this.endVisit = false
      this.isLoading = false

      this.referralIcd10Code = null
      this.selectedIcd10 = null
      this.selectedIcd10Additional = []

      this.referralApkolce = {
        subject: "",
        type: 0,
        typeIdent: null,
        issueDate: null,
        regon: "",
        code1st: "",
        code7th: "",
        code8th: "",
        musCode: "",
      }
    },

    async generateReferralTicket() {
      let ticket = JSON.parse(JSON.stringify(this.ticketObject))

      ticket.ikz = []
      ticket.procedures = this.referralProcedures
      ticket.patientId = this.patient.id
      ticket.releaseDate = this.mNow()
      ticket.startDate = this.mNow()
      ticket.created = this.mNow()
      ticket.expiryDate = moment().add(2, "year") // "2021-12-31T00:00:00.000Z";
      ticket.ticketType = 94
      ticket.ticketOffice = this.selectClinicType.id.toString()
      ticket.minMinutes = 10
      ticket.isTimeless = false
      ticket.payerType = this.payerType
      ticket.isPaid = false

      let responseP1Referral = null

      if (this.referralType.isPaperReferral == false) {

        let payloadUpdate = {
          issueDate: this.referralApkolce.issueDate,
          icd10: {
            code: this.selectedIcd10.code,
            description: this.selectedIcd10.desc,
            icD10Side: 0
          }
        }

        this.isLoading = true
        await this.$store.dispatch(Action.RESULTS_P1_REFERRAL_UPDATE_BY_KLUCZ, { domain: this.me.domainId, patientId: this.patient.id, klucz: this.resultP1.eSkierowanieKlucz, data: payloadUpdate })
          .then((response) => {
            responseP1Referral = response
          })
          .catch((error) => {
            this.apiProblem(error)
          })
        this.isLoading = false
        

        if (responseP1Referral) {
          ticket.referralResults = {
            eSkierowanieKlucz: this.resultP1.eSkierowanieKlucz,
            resultsId: responseP1Referral.id
          }
          ticket.referralResultsObject = responseP1Referral
          ticket.referralResultsObject.fileHtml = this.resultP1 ? this.resultP1.eSkierowanie : null
        }
      }

      if (responseP1Referral) {
        this.ticketsToSend = []
        let copy = JSON.parse(JSON.stringify(ticket))
        copy.referral = null
        this.ticketsToSend.push(copy)
        this.$emit("awaiting-create", this.ticketsToSend)
        this.isActiveModal = false
        this.buttonText = "Edytuj skierowanie"
        this.buttonType = "is-secondary"
        this.buttonIcon = "pencil"
      }
    },

    sendScan() {
      this.loading = true

      let scan = {
        patientId: this.patient.id,
        workerId: this.me.id,
        datetime: this.mNow(),
        uploadedFile: this.file,
        uploadType: 500,
        description: this.fileDescription,
      }

      this.$store
        .dispatch(Action.PATIENT_UPLOAD_FILE, scan)
        .then((response) => {
          this.referralFileId = response.data
          this.file = null
          this.fileDescription = ""
          this.loading = false
          this.successSnackbar("Zapisano na serwerze")

          //ADD IKZ - PLIK SKIEROWANIA
          this.addIkzScanUpload()
        })
        .catch((error) => {
          this.loading = false
          this.apiProblem(error)
        })
    },

    getFacility(id) {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.id === id)
        return found != null ? found.name : `Brak (${id})`
      } else return `Brak placówki (${id})`
    },

    referralTrigger() {
      this.triggerP1 += 1
      this.isLoading = true
      this.addIkzReferralGet()
    },

    codeP1Change(code) {
      this.codeP1 = code
    },

    eReferralSuccess(resultP1) {
      this.resultP1 = resultP1
      this.tab = 1
      this.isLoading = false
      this.getReferralData()
    },

    eReferralFailure() {
      this.isLoading = false
    },

    getReferralData() {
      if (this.resultP1 && this.resultP1.date !== null) {
        let dateFormatted = this.mDate(this.resultP1.date)
        if (dateFormatted && dateFormatted !== "0001-01-01") {
          this.referralApkolce.issueDate = moment(this.resultP1.date).format("YYYY-MM-DD")
        }
      }

      if (this.resultP1 && this.resultP1.icD10Data && this.resultP1.icD10Data.code) {
        this.referralIcd10Code = this.resultP1.icD10Data.code
      }
    },

    removeIcd10() {
      this.selectedIcd10 = null
    },

    choosenIcd10(option) {
      this.selectedIcd10 = option
    },

    choosenIcd10Additional(option) {
      let icd10Present = this.selectedIcd10Additional.find((x) => x.desc == option.desc)

      if (icd10Present) {
        this.dangerSnackbar("Wybrane rozpoznanie znajduje się już na liście")
      } else {
        this.selectedIcd10Additional.push(option)
      }
    },

    addIkzReferralGet() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find((x) => x.internalId == 595)

      if (selected) {
        var payload = {
          ikzId: selected.id,
          employeeId: this.me.id,
          patientId: this.patient.id,
          group: selected.group,
          type: selected.type,
          source: selected.source,
          points: selected.points,
          ikzInternalId: selected.internalId,
          name: selected.name,
          entry: 3, // 3 - ENTRY - AUTOMATYCZNE
          amount: 1,
          facility: facilityId,
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzReferralConfirm() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find((x) => x.internalId == 596)

      if (selected) {
        var payload = {
          ikzId: selected.id,
          employeeId: this.me.id,
          patientId: this.patient.id,
          group: selected.group,
          type: selected.type,
          source: selected.source,
          points: selected.points,
          ikzInternalId: selected.internalId,
          name: selected.name,
          entry: 3, // 3 - ENTRY - AUTOMATYCZNE
          amount: 1,
          facility: facilityId,
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzReferralResign() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find((x) => x.internalId == 597)

      if (selected) {
        var payload = {
          ikzId: selected.id,
          employeeId: this.me.id,
          patientId: this.patient.id,
          group: selected.group,
          type: selected.type,
          source: selected.source,
          points: selected.points,
          ikzInternalId: selected.internalId,
          name: selected.name,
          entry: 3, // 3 - ENTRY - AUTOMATYCZNE
          amount: 1,
          facility: facilityId,
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzReferralRefuse() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find((x) => x.internalId == 598)

      if (selected) {
        var payload = {
          ikzId: selected.id,
          employeeId: this.me.id,
          patientId: this.patient.id,
          group: selected.group,
          type: selected.type,
          source: selected.source,
          points: selected.points,
          ikzInternalId: selected.internalId,
          name: selected.name,
          entry: 3, // 3 - ENTRY - AUTOMATYCZNE
          amount: 1,
          facility: facilityId,
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzEReferralCancel() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find((x) => x.internalId == 599)

      if (selected) {
        var payload = {
          ikzId: selected.id,
          employeeId: this.me.id,
          patientId: this.patient.id,
          group: selected.group,
          type: selected.type,
          source: selected.source,
          points: selected.points,
          ikzInternalId: selected.internalId,
          name: selected.name,
          entry: 3, // 3 - ENTRY - AUTOMATYCZNE
          amount: 1,
          facility: facilityId,
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzReferralAddCancel() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find((x) => x.internalId == 630)

      if (selected) {
        var payload = {
          ikzId: selected.id,
          employeeId: this.me.id,
          patientId: this.patient.id,
          group: selected.group,
          type: selected.type,
          source: selected.source,
          points: selected.points,
          ikzInternalId: selected.internalId,
          name: selected.name,
          entry: 3, // 3 - ENTRY - AUTOMATYCZNE
          amount: 1,
          facility: facilityId,
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addIkzScanUpload() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find((x) => x.internalId == 482)

      if (selected) {
        var payload = {
          ikzId: selected.id,
          employeeId: this.me.id,
          patientId: this.patient.id,
          group: selected.group,
          type: selected.type,
          source: selected.source,
          points: selected.points,
          ikzInternalId: selected.internalId,
          name: selected.name,
          entry: 3, // 3 - ENTRY - AUTOMATYCZNE
          amount: 1,
          facility: facilityId,
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    checkFileSize(){
      if(!this.mPatientFileCheckSize(this.file.size)){
        this.file = null
      }
    }
  },
}
</script>

<style scoped lang="scss"></style>
