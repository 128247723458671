var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{attrs:{"icon-left":"clipboard-flow-outline","size":"is-small","type":"is-secondary"},on:{"click":function($event){_vm.isActiveModal = true}}},[_vm._v("Modyfikuj skierowanie AP-KOLCE")]),_c('ValidationObserver',{ref:"observerExternalSubjectApkolce",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{staticClass:"p-5",attrs:{"has-modal-card":"","aria-role":"dialog","aria-modal":"","scroll":"keep","can-cancel":false,"trap-focus":"","destroy-on-hide":false},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"modal-card",staticStyle:{"overflow":"visible"}},[_c('header',{staticClass:"modal-card-head has-background-kin"},[_c('p',{staticClass:"modal-card-title has-text-light"},[_vm._v("Modyfikuj skierowanie AP-KOLCE:")])]),_c('section',{staticClass:"modal-card-body",staticStyle:{"min-height":"350px"}},[_c('ContentPanel',{attrs:{"title":"APKolce - skierowanie","icon":"heart-half-full"}},[_c('b-field',{staticClass:"mt-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Nazwa podmiotu","label-position":"on-border","type":{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              },"message":_vm.t(errors[0])}},[_c('b-input',{model:{value:(_vm.referralApkolce.subject),callback:function ($$v) {_vm.$set(_vm.referralApkolce, "subject", $$v)},expression:"referralApkolce.subject"}})],1)]}}],null,true)})],1),_c('b-field',[_c('ValidationProvider',{attrs:{"rules":"regon14|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
                              var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"REGON (14 cyfr)","label-position":"on-border","type":{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              },"message":_vm.t(errors[0])}},[_c('b-input',{model:{value:(_vm.referralApkolce.regon),callback:function ($$v) {_vm.$set(_vm.referralApkolce, "regon", $$v)},expression:"referralApkolce.regon"}})],1)]}}],null,true)})],1),_c('b-field',[_c('ValidationProvider',{attrs:{"rules":"digitsPL:12|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
                              var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Kod część I (12 cyfr)","label-position":"on-border","type":{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              },"message":_vm.t(errors[0])}},[_c('b-input',{model:{value:(_vm.referralApkolce.code1st),callback:function ($$v) {_vm.$set(_vm.referralApkolce, "code1st", $$v)},expression:"referralApkolce.code1st"}})],1)]}}],null,true)})],1),_c('b-field',[_c('ValidationProvider',{attrs:{"rules":"digitsPL:3|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
                              var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Kod część VII (3 cyfry)","label-position":"on-border","type":{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              },"message":_vm.t(errors[0])}},[_c('b-input',{model:{value:(_vm.referralApkolce.code7th),callback:function ($$v) {_vm.$set(_vm.referralApkolce, "code7th", $$v)},expression:"referralApkolce.code7th"}})],1)]}}],null,true)})],1),_c('b-field',[_c('ValidationProvider',{attrs:{"rules":"digitsPL:4|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
                              var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Kod część VIII (4 cyfry)","label-position":"on-border","type":{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  },"message":_vm.t(errors[0])}},[_c('b-input',{model:{value:(_vm.referralApkolce.code8th),callback:function ($$v) {_vm.$set(_vm.referralApkolce, "code8th", $$v)},expression:"referralApkolce.code8th"}})],1)]}}],null,true)})],1),_c('b-field',{staticClass:"mt-3"},[_c('ValidationProvider',{attrs:{"rules":"numPL|length:5,6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
                                  var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Kod miejsca udzielania świadczeń (5-6 cyfr, nieobowiązkowe)","label-position":"on-border","type":{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              },"message":_vm.t(errors[0])}},[_c('b-input',{model:{value:(_vm.referralApkolce.musCode),callback:function ($$v) {_vm.$set(_vm.referralApkolce, "musCode", $$v)},expression:"referralApkolce.musCode"}})],1)]}}],null,true)})],1),_c('b-field',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
                              var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Data wystawienia","label-position":"on-border","type":{
                              'is-danger': errors[0],
                              'is-normal': valid,
                            },"message":_vm.t(errors[0])}},[_c('b-input',{attrs:{"type":"date","icon":"calendar-today"},model:{value:(_vm.referralApkolce.issueDate),callback:function ($$v) {_vm.$set(_vm.referralApkolce, "issueDate", $$v)},expression:"referralApkolce.issueDate"}})],1)]}}],null,true)})],1)],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"icon-left":"close"},on:{"click":props.close}},[_vm._v("Anuluj")]),_c('b-button',{attrs:{"loading":_vm.isLoading,"disabled":invalid,"type":"is-primary"},on:{"click":_vm.saveExam}},[_vm._v("Zapisz zmiany")])],1)])]}}],null,true),model:{value:(_vm.isActiveModal),callback:function ($$v) {_vm.isActiveModal=$$v},expression:"isActiveModal"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }