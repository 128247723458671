<template>
  <div>
    <b-button
      type="is-primelight"
      outlined
      size="is-small"
      icon-left="information"
      class="mr-1"
      v-if="false"
    >Wiadomość</b-button>
    <b-tooltip
      label="Alerty"
      type="is-dark"
      position="is-bottom"
      :animated="false"
      :delay="500"
      v-if="permits.admin"
      class="mr-1"
    >
      <b-button
        v-if="connecting || (connection != null && connection.state == 'Connected')"
        :loading="connecting"
        type="is-primelight"
        outlined
        size="is-small"
        @click="isComponentModalActive = true"
        icon-left="bullhorn"
      ></b-button>
      <b-button v-else type="is-danger" outlined size="is-small" @click="connect" icon-left="alert"></b-button>
    </b-tooltip>
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <ContentPanel :rounded="true" title="[ADMIN] Alerty systemowe" icon="bullhorn">
        <template slot="addon">
          <b-button
            @click="setLoginBlock(true)"
            icon-left="account-cancel"
            type="is-danger"
            class="mr-1"
            size="is-small"
            :loading="blockLoading"
          >Zablokuj login</b-button>
          <b-button
            size="is-small"
            class="mr-1"
            @click="setLoginBlock(false)"
            icon-left="account-check"
            type="is-secondary"
            :loading="blockLoading"
          >Odblokuj login</b-button>
          <b-button
            icon-left="close"
            size="is-small"
            @click="isComponentModalActive = false"
          >Zamknij</b-button>
        </template>

        <b-tag
          type="is-warning"
          size="is-medium"
          class="mb-3 has-text-weight-bold w-100"
        >Wiadomości wysłane z tego okna wyświetlane są jako globalne alerty systemowe!</b-tag>
        <b-field label="Szablony" label-position="on-border">
          <b-autocomplete
            :data="templates"
            @select="chooseTemplate"
            expanded
            open-on-focus
            clear-on-select
            placeholder="Wybierz szablon lub wpisz alert poniżej"
          ></b-autocomplete>
        </b-field>
        <b-field
          label="Treść alertu"
          label-position="on-border"
          v-if="connection != null && connection.state =='Connected'"
          class="w-100"
        >
          <b-input
            type="textarea"
            expanded
            ref="messageInput"
            placeholder="Wiadomość"
            v-model="alertMessage"
          ></b-input>
        </b-field>

        <b-button @click="sendAlert" expanded icon-left="alert" type="is-primary">Wyślij alert</b-button>
      </ContentPanel>
    </b-modal>
  </div>
</template>

<script>
// import ContentPanel from '@/components/tools/ContentPanel'
import CommonMixins from '../../mixins/commons'
import { SERVICE_TALK } from '@/store/config_apis'
import { Action } from '@/store/config_actions'
const signalR = require("@microsoft/signalr");

/**
 * SZOK Alerts modal and listener.
 * @author MC
 */
export default {
  name: 'TalkAlerts',
  components: {},
  mixins: [CommonMixins],

  data: function () {
    return {

      /**
       * Alert to be send.
       */
      alertMessage: "",

      /**
       * Template chosen.
       */
      choosenTemplate: null,

      /**
       * Connection object.
       */
      connection: null,

      /**
       * Modal active/inactive controller.
       */
      isComponentModalActive: false,

      /**
       * Connection loading indicator.
       */
      connecting: false,

      /**
       * Block loading indicator.
       */
      blockLoading: false,

      /**
       * Alert templates
       */
      templates: [
        "Uwaga, dziś w godzinach 22:00 - 23:30 system SZOK będzie wyłączony z powodu prac modernizacyjnych. Zalogowanie się w tych godzinach nie będzie możliwe. Za utrudnienia przepraszamy.",
        "Uwaga! Dziś w godzinach 22:00 - 23:30 system SZOK będzie wyłączony z powodu prac modernizacyjnych. Zalogowanie się w tych godzinach nie będzie możliwe. Prosimy o zakończenie pracy przed 22:00, w przeciwnym razie nastąpi automatyczne wylogowanie. Dziękujemy."
      ]
    }
  },

  computed: {
    /**
     * Current employee account.
     */
    me() { return this.$store.state.employee.me },

    /**
     * Current user permits.
     */
    permits() { return this.$store.state.employee.permits },

    /**
     * User token.
     */
    token() { return this.$store.state.identity.session ? this.$store.state.identity.session.token : '' },
  },

  mounted() {
    // connect on startup
    this.connecting = true
    this.connect()
  },

  /** 
   * Method used to destoroy 
   */
  destroyed() {
    // destroy connection
    this.connection = null
  },

  watch: {
    'connection.state'(val) {

      if (val == 'Disconnected') {
        if (!this.connecting) {
          let thisVue = this
          //setTimeout(function () {
          thisVue.connect()
          //}, 5000);
        }
      }

      // if (this.connection && this.connection.state == 1) {
      //   //this.successSnackbar('Podłączono do SZOK Talk')
      // }
      // else {
      //   if (!this.connecting) {
      //     let thisVue = this
      //     setTimeout(function () {
      //       thisVue.connect()
      //     }, 5000);
      //   }
      // }
    }
  },

  methods: {

    /**
     * Enables or disables login block
     * @author MC
     */
    setLoginBlock(val) {
      this.$buefy.dialog.confirm({
        scroll: 'keep',
        type: 'is-info',
        hasIcon: true,
        message: val ? 'Czy na pewno zablokować logowanie? Nikt oprócz adminów nie będzie mógł zalogować się do systemu. Aktualnie zalogowani uzytkownicy będą mogli dokończyć pracę i NIE zostaną wylogowani.' : 'Czy na pewno odblokować logowanie?',
        onConfirm: () => {
          this.blockLoading = true;
          this.$store
            .dispatch(Action.SET_BLOCK_LOGIN, val)
            .then(() => {
              this.blockLoading = false;
            })
            .catch((error) => {
              this.blockLoading = false;
              this.apiProblem(error);
            });
        }
      })
    },

    /**
     * Sets choosen template to actual message.
     * @author MC
     */
    chooseTemplate(item) {
      if (item) {
        this.alertMessage = item
      }
    },

    /**
     * Shows alert modal.
     * @author MC
     */
    showModal() {
      this.isComponentModalActive = true
    },

    /**
     * Creates SignalR connection with /hubs/alerts channel.
     */
    connect() {
      this.connecting = true
      this.connection = null

      // setup SignalR
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`${SERVICE_TALK}/hubs/alerts`, { accessTokenFactory: () => this.token })
        //.withUrl(`https://agw.digitmed.pl/hubs/alerts`, { accessTokenFactory: () => this.token })
        .configureLogging(signalR.LogLevel.Information)
        .build();

      // cache Vue instance -> in SignalR listeners 'this' means current event, not Vue instance
      var thisVue = this;

      // start connection
      this.connection.start()
        .then(() => {
          thisVue.connecting = false
        })
        .catch(function () {
          setTimeout(function () {
            thisVue.connect()
          }, 5000);
        });

      // setup event for incoming alerts
      this.connection.on("IncomingAlert", function (id, user, message/*, timestamp*/) {

        thisVue.$buefy.dialog.alert({
          message: message,
          type: 'is-info',
          scroll: 'keep',
          hasIcon: true,
          confirmText: 'Ok, rozumiem'
        })

        // thisVue.$buefy.snackbar.open({
        //   message: message,
        //   type: 'is-warning',
        //   position: 'is-top',
        //   actionText: 'Ok, rozumiem',
        //   indefinite: true,
        //   queue: false,
        //   onAction: () => { }
        // })
      });
    },

    sendAlert: function () {
      if (this.alertMessage.length > 0) {

        this.$buefy.dialog.confirm({
          scroll: 'keep',
          hasIcon: true,
          type: 'is-info',
          message: 'Czy na pewno wysłać alert?',
          cancelText: 'Zaniechaj',
          confirmText: 'Tak, wyślij do wszystkich',
          onConfirm: () => {
            let username = this.me.firstName + ' ' + this.me.lastName
            this.connection.invoke("SendMessage", this.me.id, username, this.alertMessage)
              .catch(function () {
                //this.dangerSnackbar('SZOK Talk niedostępny :(')
              });

            this.alertMessage = ''
          }
        })


      }
    }
  }
}
</script>

<style scoped lang="scss">
.otherMessage {
  color: #fff;
  padding: 0.2rem 0.8rem 0.3rem 0.8rem;
  border-radius: 1rem;
  border-top-left-radius: 3px;
  background-color: #4ca1d7;
  display: inline-block;
}

.myMessage {
  background-color: rgb(233, 233, 233);
  padding: 0.2rem 0.8rem 0.3rem 0.8rem;
  border-radius: 1rem;
  border-top-right-radius: 3px;
  display: inline-block;
}
</style>
