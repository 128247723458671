import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        code: null,
        pending: false,
        workerContent: null,
    },

    mutations: {
        [Mutation.P12_CODE_ADD](state, payload) {
            state.code = payload
            state.pending = false
        },
        [Mutation.P12_CODE_REQUEST](state, payload) {
            state.pending = payload
        },
        [Mutation.ERC_WORKERCREDS_SET_WORKERCONTENT](state, payload){
            state.workerContent = payload
        }
    },

    actions: {
        [Action.P12_VERIFY_CODE](context, code) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.P12_VERIFY_CODE,
                        data: code,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        if (response.data.p12ResultEnum === 0) {
                            context.commit(Mutation.P12_CODE_ADD, code)
                        }
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.ERC_WORKERCREDS_GET_BY_EMPLOYE](context,workerGuid){
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.ERC_WORKERCREDS_GET_BY_EMPLOYE,
                        headers: { Authorization: "Bearer " + token },
                        params:{
                            workerGuid:workerGuid
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        }
    }
}