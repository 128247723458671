import { Action } from '@/store/config_actions'
import { Apis } from '@/store/config_apis'
import { Mutation } from "@/store/config_mutations"
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        trigger: 0,

        sendedMaterials: [],

        correctlySendedOrders: []
    },

    mutations: {
        [Mutation.SOLAB_SEND_MATERIAL](currentState, payload) {
            currentState.sendedMaterials.push(payload)
        },

        [Mutation.SOLAB_ADD_SENDED_ORDER](currentState, payload) {

            let found = currentState.correctlySendedOrders.find(orderNumber => orderNumber == payload)

            if (!found) {
                currentState.correctlySendedOrders.push(payload)
            }
        },

        [Mutation.SOLAB_TRIGGER_UP](currentState) {
            currentState.trigger++
        }
    },

    actions: {
        [Action.SOLAB_LAB_ORDER_COMPLETE_CREATE](context, order) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SOLAB_LAB_ORDER_COMPLETE_CREATE,
                        data: order,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.SOLAB_RESULT_ORDER_COMPLETE](context, orderId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SOLAB_RESULT_ORDER_COMPLETE.replace('{orderId}', orderId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        }
    }
}