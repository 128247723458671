export default {
  data: function () {
    return {
      monthNames: [
        this.t("tools.date.month.january"),
        this.t("tools.date.month.february"),
        this.t("tools.date.month.march"),
        this.t("tools.date.month.april"),
        this.t("tools.date.month.may"),
        this.t("tools.date.month.june"),
        this.t("tools.date.month.july"),
        this.t("tools.date.month.august"),
        this.t("tools.date.month.september"),
        this.t("tools.date.month.october"),
        this.t("tools.date.month.november"),
        this.t("tools.date.month.december")
      ],

      dayNames: [
        this.t("tools.date.day.sunday"),
        this.t("tools.date.day.monday"),
        this.t("tools.date.day.tuesday"),
        this.t("tools.date.day.wednesday"),
        this.t("tools.date.day.thursday"),
        this.t("tools.date.day.friday"),
        this.t("tools.date.day.saturday")
      ],

      dayNamesShort: [
        this.t("tools.date.dayShort.sunday"),
        this.t("tools.date.dayShort.monday"),
        this.t("tools.date.dayShort.tuesday"),
        this.t("tools.date.dayShort.wednesday"),
        this.t("tools.date.dayShort.thursday"),
        this.t("tools.date.dayShort.friday"),
        this.t("tools.date.dayShort.saturday"),
      ],

      voivodeships: [
        'wielkopolskie',
        'kujawsko-pomorskie',
        'małopolskie',
        'łódzkie',
        'dolnośląskie',
        'lubelskie',
        'lubuskie',
        'mazowieckie',
        'opolskie',
        'podlaskie',
        'pomorskie',
        'śląskie',
        'podkarpackie',
        'świętokrzyskie',
        'warmińsko-Mazurskie',
        'zachodniopomorskie'
      ]
    }
  },

  methods: {
  }
}
