<template>
  <div class="chat-wrapper">
    <div class="is-pulled-right">
      <b-dropdown aria-role="list" ref="newEmployeeDropdown" position="is-bottom-left" v-if="group">
        <template #trigger>
          <b-tooltip label="Dodaj do grupy">
            <b-button size="is-small" icon-right="account-plus" class="mr-1"></b-button>
          </b-tooltip>
        </template>
        <div class="pl-3 pr-3 pt-1 pb-1">
          <EmployeeSelector @selected="newEmployeeSelected"></EmployeeSelector>
        </div>
      </b-dropdown>
      <b-tooltip label="Oznacz wszystko jako przeczytane">
        <b-button v-if="group" size="is-small" icon-right="check-all" @click="markAllRead"></b-button>
      </b-tooltip>
    </div>
    <div v-if="group" class="mt-2">
      <!-- MEMBERS -->
      <p class="subtitle mb-0">{{group.name}}</p>
      <div>
        <a
          v-for="item in group.employees"
          :key="item.index"
          class="mb-1 mr-2 is-size-7"
          size="is-small"
        >{{getWorker(item)}}</a>
      </div>
      <div class="mt-2">
        <b-field grouped>
          <b-field expanded>
            <b-input
              @keydown.native.enter="send"
              expanded
              v-model="content"
              placeholder="Wpisz wiadomość"
            ></b-input>
          </b-field>
          <b-button @click="send" :loading="loading" icon-right="send">Wyślij</b-button>
        </b-field>
      </div>

      <!-- CONTENT -->
      <div class="chat-content mt-3 pr-3">
        <div
          v-for="item in group.items"
          :key="item.id"
          class="card mb-3 p-4"
          style="min-height: 4.1rem;"
          :class="{'has-background-szoklight': item.createdBy == me.id, 'unread': isUnread(item)}"
        >
          <MyAvatar :workerId="item.createdBy" size="2.3rem" class="is-pulled-left mr-3"></MyAvatar>
          <p class="is-size-7 pl-3 pb-2 has-text-grey">
            <b>{{getWorker(item.createdBy)}}</b>
            <b-button
              v-if="isUnread(item)"
              icon-left="check"
              @click="markRead(item.id)"
              size="is-small"
              class="mr-l is-pulled-right"
            >Przeczytane</b-button>
            <b-button
              icon-left="dots-horizontal"
              @click="showDetails(item)"
              size="is-small"
              type="is-text"
              class="is-pulled-right"
            ></b-button>
            <br />
            {{mDateTime(item.created)}}
          </p>
          <p class="pt-2" style="white-space: pre-wrap; word-break: break-word;">{{item.content}}</p>
        </div>
      </div>
    </div>
    <div v-else class="chat-content-empty section">
      <p class="has-text-centered">Brak wiadomości</p>
    </div>

    <b-modal
      :active.sync="detailModalActive"
      has-modal-card
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <div class="modal-card">
        <header class="modal-card-head has-background-kin">
          <p class="modal-card-title has-text-light">
            <b-icon icon="chat" class="mr-4"></b-icon>Szczegóły wiadomości
          </p>
        </header>
        <section class="modal-card-body">
          <pre v-if="detailMessage">{{detailMessage}}</pre>
        </section>
        <footer class="modal-card-foot">
          <b-button icon-left="close" size="is-small" @click="detailModalActive = false">Zamknij</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from '../../mixins/commons'
import { Action } from '../../store/config_actions'
import EmployeeSelector from '@/components/tools/EmployeeSelector'
import MyAvatar from "@/components/my-account/MyAvatar";

export default {
  name: 'SzokTalkConversation',
  mixins: [CommonMixins],

  components: {
    EmployeeSelector, MyAvatar
  },

  data: function () {
    return {
      /**
       * Loading indicator.
       */
      loading: false,

      /**
       * Message content.
       */
      content: '',

      /**
       * Message to focus on.
       */
      detailMessage: null,

      /**
       * Detail modal control.
       */
      detailModalActive: false
    }
  },

  props: {
    /**
     * Current group.
     */
    group: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    }
  },

  watch: {
    /**
     * Downloads older messages on group change if not downloaded before.
     */
    group(val) {
      this.content = ''

      if (val) {
        if (val.items == null || val.items.length < 1) {
          this.getOlderMessages(20)
        }
      }
    }
  },

  computed: {
    /**
     * Messages unread by me.
     */
    unread() {
      if (this.group && this.group.items) {
        return this.group.items.filter(x => this.isUnread(x))
      }
      else {
        return []
      }
    },

    /**
     * Employee list.
     */
    employees() {
      if (this.$store.state.employee.all !== null) {
        return this.$store.state.employee.all;
      } else return [];
    },

    /** 
     * My account. 
     */
    me() { return this.$store.state.employee.me },
  },

  mounted() {
    this.getOlderMessages(20)
  },

  methods: {

    /**
     * Shows message details modal
     */
    showDetails(item) {
      this.detailMessage = item
      this.detailModalActive = true
    },

    /** 
     * Marks single message as read.
     */
    markRead(id) {
      let ids = []
      ids.push(id)
      this.sendReadMarks(ids)
    },

    /** 
     * Marks multiple messages as read.
    */
    markAllRead() {
      let ids = []
      this.unread.forEach(element => ids.push(element.id));
      this.sendReadMarks(ids)
    },

    /**
     * Sends read marks.
     */
    sendReadMarks(ids) {
      this.loading = true
      this.$store
        .dispatch(Action.TALK_MARK_READ, ids)
        .then(() => {
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.apiProblem(error);
        });
    },

    /**
     * Unread message marker.
     */
    isUnread(item) {
      if (item.readByEmployees) {
        let mark = item.readByEmployees.find(x => x.id == this.me.id)

        if (mark) return false
        else return true

      }
      else return true
    },

    /**
     * Sends message.
     */
    send() {
      if (this.group && this.content && this.content.length > 0) {
        let message = { groupId: this.group.id, content: this.content }
        this.loading = true
        this.$store
          .dispatch(Action.TALK_SEND_MESSAGE, message)
          .then(() => {
            this.loading = false
            // Reset content.
            this.content = ''
          })
          .catch(error => {
            this.loading = false
            this.apiProblem(error);
          });
      }
    },

    /** 
     * Get worker name.
     */
    getWorker(id) {

      if (id == this.me.id) {
        return "Ja"
      }

      if (this.employees) {
        let emp = this.employees.find((e) => e.id === id);

        if (emp) {
          return emp.lastName + " " + emp.firstName;
        } else return "-";
      }
    },

    /**
     * Adds new worker to group.
     */
    newEmployeeSelected(val) {
      if (val && this.$refs.newEmployeeDropdown) {
        this.$refs.newEmployeeDropdown.toggle()
      }

      if (this.group && val) {
        if (this.group.employees.includes(val.id)) {
          this.$buefy.dialog.alert({
            message: `Pracownik jest już w grupie.`,
            hasIcon: true,
            type: 'is-info',
            scroll: 'keep',
          })
        }
        else {
          this.$buefy.dialog.confirm({
            message: `Czy na pewno dodać ${val.firstName} ${val.lastName} do grupy?`,
            hasIcon: true,
            type: 'is-info',
            scroll: 'keep',
            cancelText: 'Anuluj',

            onConfirm: () => {

              // Group initiator.
              let payload = {
                gid: this.group.id,
                eid: val.id
              }

              this.loading = true
              this.$store
                .dispatch(Action.TALK_ADD_EMPLOYEE_TO_GROUP, payload)
                .then((response) => {
                  this.loading = false
                  this.group.employees = response.employees
                })
                .catch(error => {
                  this.apiProblem(error);
                  this.loading = false
                });
            }
          })
        }
      }
    },

    /**
     * Downloads older messages.
     */
    getOlderMessages(amount) {
      if (this.group) {
        this.loading = true
        this.$store
          .dispatch(Action.TALK_GET_MESSAGES, { group: this.group.id, count: amount })
          .then((response) => {
            this.loading = false
            this.group.items = response
          })
          .catch(error => {
            this.loading = false
            this.apiProblem(error);
          });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chat-content {
  overflow-y: scroll;
  overflow-x: visible;
  max-height: calc(100vh - 16rem);
  height: calc(100vh - 16rem);
}

.chat-content-empty {
  max-height: calc(100vh - 8.7rem);
  height: calc(100vh - 8.7rem);
}

.unread {
  //border: 2px solid #26a69a !important;
  -webkit-box-shadow: inset 0px 0px 3px 2px #26a69a !important;
  -moz-box-shadow: inset 0px 0px 3px 2px #26a69a !important;
  box-shadow: inset 0px 0px 3px 2px #26a69a !important;
}
</style>
