<template>
  <div>
    <!-- <b-button size="is-small" @click="isActiveModalUpload = true">Dane teleadresowe</b-button> -->

    <b-button
      @click="isActiveModal = true"
      icon-left="clipboard-flow-outline" size="is-small"
      type="is-secondary"
    >Modyfikuj informacje AP-KOLCE</b-button>

    <b-modal
      v-model="isActiveModal"
      has-modal-card
      aria-role="dialog"
      aria-modal
      scroll="keep"
      :can-cancel="false"
      class="p-5"
      trap-focus
      :destroy-on-hide="false"
    >
      <template #default="props">
          <div class="modal-card" style="overflow: visible">
            <header class="modal-card-head has-background-kin">
              <p class="modal-card-title has-text-light">Modyfikuj AP-KOLCE:</p>
            </header>
            <section class="modal-card-body" style="min-height: 350px">
                  <ContentPanel
                    title="APKolce"
                    icon="heart-half-full"
                  >
                    <b-field
                      label="Przypadek"
                      label-position="on-border"
                      class="pt-3 mb-4 mt-1"
                    >
                      <b-radio v-model="isUrgent" :native-value="false"
                        >stabilny</b-radio
                      >
                      <b-radio v-model="isUrgent" :native-value="true"
                        >pilny</b-radio
                      >
                    </b-field>
                      <b-field
                        label="Status (wymagane)"
                        label-position="on-border"
                      >
                        <b-select v-model="registrationType" expanded>
                          <option>Przyjęty na bieżąco</option>
                          <option>Oczekujący</option>
                          <option>Przyjęty w stanie nagłym</option>
                          <option>Kontynuujący leczenie</option>
                          <option>Posiadający prawo do korzystania poza kolejnością</option>
                          <option>Oczekujący objęty diagnostyką onkologiczną lub leczeniem onkologicznym</option>
                        </b-select>
                      </b-field>
                    <div>
                      <div
                        v-if="selectedIcd10"
                        style="font-size: 0.9rem; width: 100%"
                        class="mb-4"
                      >
                        <p style="font-size: 0.8rem">
                          Wybrane główne rozpoznanie:
                          <span class="is-pulled-right"
                            ><b-tooltip label="Usuń" type="is-danger">
                              <b-icon
                                @click.native="removeIcd10()"
                                icon="close-circle-outline"
                                type="is-danger"
                                style="cursor: pointer"
                              ></b-icon> </b-tooltip
                          ></span>
                        </p>

                        <p
                          class="
                            has-text-success has-text-weight-medium
                            mt-1
                            ml-2
                          "
                        >
                          {{ selectedIcd10.code }} - {{ selectedIcd10.desc }}
                        </p>
                      </div>
                      <Icd10SearchBar
                        @selected="choosenIcd10"
                        :referralIcd10Code="referralIcd10Code"
                        :labelText="'Wybierz rozpoznanie główne (wymagane)'"
                        class="mt-3 is-small"
                      ></Icd10SearchBar>

                          <Icd10SearchBar
                            @selected="choosenIcd10Additional"
                            :labelText="'Wybierz rozpoznania współistniejące'"
                            class="mt-3 is-small"
                          ></Icd10SearchBar>
                        <div
                          v-if="selectedIcd10Additional.length > 0"
                          style="font-size: 0.9rem; width: 100%"
                          class="mt-3"
                        >
                          <div
                            v-for="(item, index) in selectedIcd10Additional"
                            :key="index"
                            class="mt-1"
                          >
                            <span>{{ item.code }} - {{ item.desc || item.name }}</span>
                            <span class="is-pulled-right"
                              ><b-tooltip label="Usuń" type="is-danger">
                                    <b-icon
                                      @click.native="selectedIcd10Additional.splice(index, 1)"
                                      icon="close-circle-outline"
                                      type="is-danger"
                                      style="cursor: pointer"
                                    ></b-icon>
                                </b-tooltip
                            ></span>
                            <hr class="m-0 p-0 mt-1" />
                          </div>
                        </div>
                      <hr class="p-0 m-0 mt-3 mb-3" />
                    </div>
                    <b-field
                      label="Komentarz"
                      label-position="on-border"
                    >
                      <b-input v-model="comment" type="textarea" :rows="2"></b-input>
                    </b-field>
                    <b-field label="Okolice ciała (wymagane)" label-position="on-border">
                        <div>
                      <b-autocomplete
                        :data="filteredBodyAreas"
                        v-model="bodyArea"
                        placeholder="Wybierz"
                        open-on-focus
                        field="name"
                        clearable
                        @select="addBodyArea"
                        clear-on-select
                      >
                        <template slot-scope="props">
                          <!-- <b-tag
                            v-if="props.option.codeOKC"
                            type="is-primary"
                            >{{ props.option.codeOKC }}</b-tag
                          > -->
                          <span class="mt-2">{{ props.option.name }}</span>
                        </template>
                      </b-autocomplete>
                      <div
                        v-if="bodyAreas.length > 0"
                        style="font-size: 0.9rem; width: 100%"
                        class="mt-3"
                      >
                        <div
                          v-for="(item, item_index) in bodyAreas"
                          :key="item_index"
                          class="mt-1"
                        >
                          <b-tag v-if="item.codeOKC" type="is-primary">{{
                            item.codeOKC
                          }}</b-tag>
                          <span class="ml-2">{{ item.name }}</span>
                          <span class="is-pulled-right"
                            ><b-tooltip label="Usuń" type="is-danger">
                              <b-icon
                                @click.native="bodyAreas.splice(item_index, 1)"
                                icon="close-circle-outline"
                                type="is-danger"
                                style="cursor: pointer"
                              ></b-icon></b-tooltip
                          ></span>
                          <hr class="m-0 p-0 mt-1" />
                        </div>
                      </div>
                        </div>
                    </b-field>
                  </ContentPanel>
            </section>
            <footer class="modal-card-foot">
              <b-button icon-left="close" @click="props.close">Anuluj</b-button>
              <b-button
                :loading="isLoading"
                :disabled="
                  bodyAreas.length < 1 ||
                  !selectedIcd10 ||
                  !registrationType
                "
                @click="saveExam"
                type="is-primary"
              >Zapisz zmiany</b-button>
            </footer>
          </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import RISMixins from "@/mixins/ris";
import { Action } from "../../../../store/config_actions";
import { Mutation } from "../../../../store/config_mutations";
import Icd10SearchBar from '@/components/search-engines/Icd10SearchBar'

export default {
  name: "RISPatientChangeAPKolce",
  mixins: [CommonHelpers, RISMixins],
  components: {
    Icd10SearchBar,
  },

  props: { ticket: { type: Object, require: true } },

  data: function () {
    return {
      isLoading: false,
      requestInProgress: false,
      isActiveModal: false,

      referralIcd10Code: null,
      selectedIcd10: null,
      selectedIcd10Additional: [],
      comment: '',
      bodyArea: '',
      bodyAreas: [],
      isUrgent: false,
      registrationType: null,
    };
  },

  mounted() {
  },

  watch: {
    isActiveModal() {
      if (this.isActiveModal == true) {
          if (this.ticket) {
            if (this.ticket && this.ticket.apkolce) {
                this.comment = this.ticket.apkolce.comment ? this.ticket.apkolce.comment : ""
                this.isUrgent = this.ticket.apkolce.isUrgent
                this.registrationType = this.ticket.apkolce.registrationType
                this.referralIcd10Code = this.ticket.apkolce.icd10Code
                this.selectedIcd10Additional = this.ticket.apkolce.icd10Additional

                this.ticket.apkolce.bodyParts.forEach((bPart) => {
                    let bodyPart = this.mBodyAreas.find(element => element.codeOKC == bPart.code && element.name == bPart.name)
                    if(bodyPart) {
                        this.bodyAreas.push(bodyPart)
                    }
                })
            }
          }
        this.$store.commit(Mutation.RIS_SET_IS_EDITING_APP, true);
      } else {
        this.$store.commit(Mutation.RIS_SET_IS_EDITING_APP, false);
        this.resetData()
      }  
 
    }
  },

  computed: {
    me() {
      return this.$store.state.employee.me;
    },

    filteredBodyAreas() {
      return this.mBodyAreas.filter((option) => {
        if (option.name) {
          return (
            option.name
              .toLowerCase()
              .indexOf(this.bodyArea.toLowerCase()) >= 0
          )
        } else return false
      });
    }

  },

  methods: {

    choosenIcd10(option) {
      this.selectedIcd10 = option

      if(this.referralIcd10Code) {
          this.referralIcd10Code = null
      }
    },

    addBodyArea(option) {
      if (option) {
        let found = this.bodyAreas.find(area => area.id == option.id)

        if (found) {
          this.dangerSnackbar('Wybrana okolica ciała znajduje się już na liście')
          this.bodyArea = ''
        } else {
          this.bodyAreas.push(option)
          this.bodyArea = ''
        }
      }
    },

    removeIcd10() {
      this.selectedIcd10 = null
    },

    saveExam() {
        this.ticket.apkolce.isUrgent = this.isUrgent
        this.ticket.apkolce.registrationType = this.registrationType

        if (this.selectedIcd10 && this.selectedIcd10.code && this.selectedIcd10.desc) {
          this.ticket.apkolce.icd10Code = this.selectedIcd10.code
          this.ticket.apkolce.icd10Name = this.selectedIcd10.desc
        } 

        this.ticket.apkolce.comment = this.comment

        this.ticket.apkolce.icd10Additional = []
        this.selectedIcd10Additional.forEach((icd) => {
          let object = {
            code: icd.code,
            name: icd.desc,
          };
          this.ticket.apkolce.icd10Additional.push(object);
        });

        this.ticket.apkolce.bodyParts = []

        if (this.bodyAreas && this.bodyAreas.length > 0) {
          this.bodyAreas.forEach(element => {
            let obj = {
              "code": element.codeOKC,
              "name": element.name
            }
            this.ticket.apkolce.bodyParts.push(obj)
          })
        }

      this.updateTicket(this.ticket);
    },

    updateTicket(ticket) {
      this.isActiveModal = false;
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.TICKET_PUT, ticket)
        .then((resp) => {
          this.requestInProgress = false;
          this.successSnackbar("Zmiany zapisane");
          this.$store.commit(Mutation.RIS_PUSH_TICKET_TO_STORE, resp);
          this.$emit('updated')
          this.$emit("add-IKZ");
        })
        .catch((error) => {
          this.requestInProgress = false;
          this.apiProblem(error);
        });
    },

    resetData() {
        this.referralIcd10Code = null
        this.selectedIcd10 = null
        this.selectedIcd10Additional = []
        this.comment = ''
        this.bodyArea = ''
        this.bodyAreas = []
        this.isUrgent = false
        this.registrationType = null
    },

    choosenIcd10Additional(option) {
      let icd10Present = this.selectedIcd10Additional.find(
        (x) => x.desc == option.desc
      );

      if (icd10Present) {
        this.dangerSnackbar("Wybrane rozpoznanie znajduje się już na liście");
      } else {
        this.selectedIcd10Additional.push(option);
      }
    },

  },
};
</script>

<style scoped lang="scss">
</style>
