import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
// import { Mutation } from '../config_mutations';

export default {
    namespaced: false,

    state: {},

    getters: {},

    mutations: {},

    actions: {
        
        /** Attempt to add template referral. Uses POST request and requires template referral data object (check config_apis.js) */
        [Action.TEMPLATE_REFERRAL_CREATE](context, templateReferral) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TEMPLATE_REFERRAL_GET_ALL,
                        data: templateReferral,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all template referral. Uses GET request without parameter */
        [Action.TEMPLATE_REFERRAL_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEMPLATE_REFERRAL_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get template referral. Uses GET request */
        [Action.TEMPLATE_REFERRAL_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEMPLATE_REFERRAL_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt template referral delete. Uses DELETE request */
        [Action.TEMPLATE_REFERRAL_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.TEMPLATE_REFERRAL_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt template referral edit. Uses PUT request with id and requires template referral data object (check config_apis.js)*/
        [Action.TEMPLATE_REFERRAL_UPDATE](context, templateReferral) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.TEMPLATE_REFERRAL_GET.replace('{id}', templateReferral.id),
                        data: templateReferral,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get template referral by employee. Uses GET request */
        [Action.TEMPLATE_REFERRAL_GET_BY_PATIENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEMPLATE_REFERRAL_GET_BY_PATIENT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },    
        
        /** Attempt to get template referral by employee. Uses GET request */
        [Action.TEMPLATE_REFERRAL_GET_BY_EMPLOYEE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEMPLATE_REFERRAL_GET_BY_EMPLOYEE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },    

        /** Attempt to get template referral by employee. Uses GET request */
        [Action.TEMPLATE_REFERRAL_GET_BY_EMPLOYEE_TYPE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEMPLATE_REFERRAL_GET_BY_EMPLOYEE_TYPE.replace('{id}', payload.id).replace('{type}', payload.type),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        }, 
        
        /** Attempt to get template referral by employee. Uses GET request */
        [Action.TEMPLATE_REFERRAL_GET_BY_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEMPLATE_REFERRAL_GET_BY_APPOINTMENT.replace('{domainId}', payload.domainId).replace('{appointmentId}', payload.appointmentId).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        }, 

        /** Attempt to get template referral by employee. Uses GET request */
        [Action.TEMPLATE_REFERRAL_GET_BY_P1](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEMPLATE_REFERRAL_GET_BY_P1.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}