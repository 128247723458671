<template>
  <div class="is-inline">
    <a
      v-if="listmode"
      class="dropdown-item"
      aria-role="listitem"
      @click="isComponentModalActive = true"
    >
      <b-icon icon="space-invaders" size="is-small" class="menu-larger-icon"></b-icon>Wyślij uwagę
    </a>
    <b-tooltip
      v-else
      type="is-primary"
      :label="'Wyślij uwagę'"
      class="hand"
      position="is-bottom"
      :animated="false"
      :delay="500"
    >
      <b-button
        @click="isComponentModalActive = true"
        :type="light ? 'is-primelight':'is-text'"
        outlined
        size="is-small"
        icon-left="space-invaders"
      ></b-button>
    </b-tooltip>

    <!-- FEEDBACK MODAL -->
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <div class="modal-card">
        <header class="modal-card-head" :class="{'has-background-danger': payload.type===1}">
          <p class="modal-card-title" :class="{'has-text-light': payload.type===1}">
            Dodaj uwagę:
            <strong :class="{'has-text-light': payload.type===1}">{{target}}</strong>
          </p>
        </header>
        <section class="modal-card-body">
          <b-field expanded label="Typ zgłoszenia" label-position="on-border">
            <b-select expanded v-model="payload.type">
              <option :value="0">Ogólne uwagi</option>
              <option :value="1">Bardzo istotny błąd</option>
              <option :value="2">Propozycja funkcjonalności</option>
            </b-select>
          </b-field>
          <b-field label="Uwagi" label-position="on-border">
            <b-input
              placeholder="Minimum 10 znaków"
              type="textarea"
              v-model="payload.description"
              maxlength="300"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="isComponentModalActive = false">Zamknij</button>
          <b-button
            :disabled="payload.description.length < 10"
            :loading="loading"
            type="is-primary"
            @click="sendFeedback"
          >Wyślij</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import CommonMixins from '../../mixins/commons'
import { Action } from '../../store/config_actions'

export default {
  name: 'AppointmentHistory',
  mixins: [CommonMixins],

  components: {

  },

  props: {
    target: { type: String, required: true },
    hoverable: { type: Boolean, required: false },
    light: { type: Boolean, required: false },
    listmode: { type: Boolean, required: false }
  },

  watch: {
  },

  mounted() {
  },

  data: function () {
    return {
      isComponentModalActive: false,
      loading: false,

      payload: {
        workerId: -1,
        type: 0,
        componentName: this.target,
        description: '',
        created: this.mNow(),
        isCompleted: false
      }
    }
  },

  computed: {
    myId() {
      return this.$store.state.employee.me ? this.$store.state.employee.me.id : -1
    },
  },

  methods: {
    sendFeedback() {
      this.loading = true
      this.payload.created = moment().format('YYYY-MM-DDTHH:mm:ss')
      this.payload.workerId = this.myId

      this.$store
        .dispatch(Action.STATS_SEND_FEEDBACK, this.payload)
        .then(() => {
          this.successSnackbar('Uwaga została wysłana');
          this.loading = false
          this.isComponentModalActive = false
          this.payload.description = ''
          this.payload.type = 0
          this.payload.created = this.mNow()
        })
        .catch(error => {
          this.snackbar('Będzie działać jak Łukasz zrobi Statsy :D')
          this.apiProblem(error);
          this.loading = false
        });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
