export default {
    data: function () {
        return {
            /**Kategorie CRM Utrzymania
             * Categories for CRM Maintenance
             */
            mCrmMaintenanceCategory: [
                { id: 1, name: "Naprawa" },
                { id: 2, name: "Tereny zielone" },
                { id: 3, name: "Odśnieżanie" },
                { id: 4, name: "Wymiana" },
                { id: 5, name: "Montaż" },
                { id: 6, name: "Monitoring" },
                { id: 7, name: "Sprzątanie pojazdu" },
                { id: 8, name: "Sprzątanie pomieszczenia" },
                { id: 9, name: "Rezerwacja pojazdu" },
                { id: 10, name: "Sprzęt medyczny" },
            ],
            /**Kategorie CRM Digitmed
             * Categories for CRM Digitmed
             */
            mCrmDigitmedCategory: [
                { id: 1, name: "Strona WWW" },
                { id: 2, name: "SZOK (moduły)" },
                { id: 3, name: "AFM" },
                { id: 4, name: "Przetargowy" },
                { id: 5, name: "Wdrożenia" },
                { id: 6, name: "Awaria sprzętu" },
            ],
            /**Kategorie CRM Obsługa klienta
             * Categories for CRM Customer service
             */
            mCrmCustomerServiceCategory: [
                { id: 1, name: "Opinia" },
                { id: 2, name: "Pomysł" },
                { id: 3, name: "Zażalenie" },
                { id: 4, name: "Wniosek" },
                { id: 5, name: "Skarga" },
                { id: 6, name: "Zasłabnięcie" },
                { id: 7, name: "Awantura" },
            ],
            /**Kategorie CRM Rodo
             * Categories for CRM Rodo
             */
            mCrmRodoCategory: [
                { id: 1, name: "Incydent" },
                { id: 2, name: "Naruszenie" },
                { id: 3, name: "Zdarzenie" },
            ],
            /**Kategorie CRM HR
             * Categories for CRM HR
             */
            mCrmHrCategory: [
                { id: 1, name: "Wniosek urlopowy" },
                { id: 2, name: "Podanie o pracę" },
                { id: 3, name: "Problem personalny" },
                { id: 4, name: "Uzupełnienie dokumentów" },
                { id: 5, name: "Wynagrodzenia" },
                { id: 6, name: "Udogodnienia dla pracowników" },
            ],
            /**Kategorie CRM IT
             * Categories for CRM IT
             */
            mCrmITCategory: [
                { id: 1, name: "Oprogramowanie" },
                { id: 2, name: "Sprzęt komputerowy" },
                { id: 3, name: "Sieć" },
                { id: 4, name: "Montaż" },
                { id: 5, name: "Centrala telefoniczna" },
                { id: 6, name: "Internet" },
                { id: 7, name: "Hasła" },
                { id: 8, name: "Biuro" },
                { id: 9, name: "Awaria sprzętu" },
            ],
            /**Kategorie CRM Promocja
             * Categories for CRM Promotion
             */
            mCrmPromotionCategory: [
                { id: 1, name: "Zamówienie materiałów promocyjnych" },
                { id: 2, name: "Zgłoszenie zapotrzebowania na materiał promocyjny" },
            ],
            /**Podkategorie CRM Materiały promocyjne
             * Subcategories for CRM Promotion materials
             */
            mCrmPromotionMaterialSubCategory: [
                { id: 1, name: "Plakaty" },
                { id: 2, name: "Ulotki" },
                { id: 3, name: "Karty informacyjne" },
                { id: 4, name: "Broszury" },
                { id: 5, name: "Gadżety" },
            ],
            /**Podkategorie CRM hasła IT
             * Subcategories for CRM IT Password
             */
            mCrmITPasswordSubcategory: [
                { id: 1, name: "Medis" },
                { id: 2, name: "Solab" },
                { id: 3, name: "Terminal" },
                { id: 4, name: "Intranet" },
                { id: 5, name: "SZOI (Apkolce, Ewuś, Dilo, EZWM)" },
            ],
            /**Działy CRM
             * CRM Departments
             */
            mCrmDepartment: [
                { id: 0, isActive: false, name: "Digitmed" },
                { id: 1, isActive: true, name: "IT" },
                { id: 2, isActive: true, name: "Utrzymanie" },
                { id: 3, isActive: false, name: "RODO" },
                { id: 4, isActive: false, name: "BOK" },
                { id: 5, isActive: false, name: "HR" },
                { id: 6, isActive: false, name: "Promocja" },
                { id: 7, isActive: false, name: "Pracownicy" },
            ],
            /**Kategorie priorytetu zgłoszenia
             * Categories for priority of notification
             */
            mCrmPerformerPriority: [
                { id: 1, name: "Krytyczne" },
                { id: 2, name: "Bardzo pilne" },
                { id: 3, name: "Pilne" },
                { id: 4, name: "Standardowe" },
            ],

            /**Forma przekazu zgłoszenia
             * Type of report method
             */
             mCrmReportMethod: [
                { id: 0, name: "Domyślna", isDisabled:true},
                { id: 1, name: "Osobiście" },
                { id: 2, name: "Połączenie telefoniczne" },
                { id: 3, name: "Whatsapp" },
                { id: 4, name: "SMS" },
                { id: 5, name: "E-mail" },
                { id: 6, name: "SZOK-Talk" },
            ],
        }
    },
    computed:{
        reportMethodsEnabled(){
            return this.mCrmReportMethod.filter((method) => !method.isDisabled);
        }
    },
    methods: {
        /**Metoda wywoływania kategorii CRM Utrzymania
        * Method for getting CRM Maintance categories
        */
        mGetCrmMaintenanceCategory(id) {
            let target = this.mCrmMaintenanceCategory.find(c => c.id == id)
            if (target) { return target.name }
            else return "Nie wybrano"
        },
        /**Metoda wywoływania kategorii CRM Digitmed
        * Method for getting CRM Digitmed categories
        */
        mGetCrmDigitmedCategory(id) {
            let target = this.mCrmDigitmedCategory.find(c => c.id == id)
            if (target) { return target.name }
            else return "Nie wybrano"
        },
        /**Metoda wywoływania kategorii CRM Obsługi klienta
        * Method for getting CRM Customer Service categories
        */
        mGetCrmCustomerServiceCategory(id) {
            let target = this.mCrmCustomerServiceCategory.find(c => c.id == id)
            if (target) { return target.name }
            else return "Nie wybrano"
        },
        /**Metoda wywoływania kategorii CRM Rodo
        * Method for getting CRM Rodo categories
        */
        mGetCrmRodoCategory(id) {
            let target = this.mCrmRodoCategory.find(c => c.id == id)
            if (target) { return target.name }
            else return "Nie wybrano"
        },
        /**Metoda wywoływania kategorii CRM HR
        * Method for getting CRM HR categories
        */
        mGetCrmHrCategory(id) {
            let target = this.mCrmHrCategory.find(c => c.id == id)
            if (target) { return target.name }
            else return "Nie wybrano"
        },
        /**Metoda wywoływania kategorii CRM IT
        * Method for getting CRM IT categories
        */
        mGetCrmITCategory(id) {
            let target = this.mCrmITCategory.find(c => c.id == id)
            if (target) { return target.name }
            else return "Nie wybrano"
        },
        /**Metoda wywoływania kategorii CRM
        * Method for getting CRM categories
        */
        mGetCrmCategory(value) {
            let item = this.mCrmCategory.find(c => c.value == value)
            if (item) return item
            else return `${value}`
        },
        /**Metoda wywoływania nazwy kategorii CRM
        * Method for getting name of CRM categories
        */
        mGetCrmCategoryName(value) {
            if (value) {
                let item = this.mCrmCategory.find(c => c.value == value)
                if (item) return item.name
                else return `${value}`
            } else ""
        },
        /**Metoda wywoływania kategorii CRM według działu
        * Method for getting CRM categories by department
        */
        mGetCrmCategoryByDepartment(departmentId) {
            let categories = this.mCrmCategory.filter((c) => c.departmentId == departmentId)
            if (categories) return categories
            else return []
        },

        //mGetCrmDepartment(id) {
        //   let item = this.mCrmDepartment.find(c => c.id == id)
        //    if (item) return item
        //    else return `??? [${id}]`
        //},
        /**Metoda wywoływania id działu CRM
        * Method for getting CRM Department id
        */
        mGetCrmDepartment(id) {
            let target = this.mCrmDepartment.find(c => c.id == id)
            if (target) { return target.name }
            else return "Nie wybrano"
        },
        /**Metoda wywoływania nazwy działu CRM
        * Method for getting name of CRM Department
        */
        mGetCrmDepartmentName(id) {
            let item = this.mCrmDepartment.find(c => c.id == id)
            if (item) return item.name
            else return `??? [${id}]`
        },
        /**Metoda wywoływania id podkategorii CRM
        * Method for getting CRM subcategories id
        */
        mGetCrmSubcategory(id) {
            let item = this.mCrmSubcategory.find(c => c.id == id)
            if (item) return item
            else return `??? [${id}]`
        },
        /**Metoda wywoływania nazwy podkategorii CRM
        * Method for getting CRM subcategories name
        */
        mGetCrmSubcategoryName(id) {
            let item = this.mCrmSubcategory.find(c => c.id == id)
            if (item) return item.name
            else return `??? [${id}]`
        },
        /**Metoda wywoływania id priorytetu zgłoszenia
        * Method for getting priority of notification id
        */
        mGetPerformerPriority(id) {
            let target = this.mCrmPerformerPriority.find(c => c.id == id)
            if (target) { return target.name }
            else return "Nie wybrano"
        },

        /**Metoda wywoływania id priorytetu zgłoszenia
        * Method for getting priority of notification id
        */
         mGetReportMethodName(id) {
            let target = this.mCrmReportMethod.find(c => c.id == id)
            if (target) { return target.name }
            else return "Nie wybrano"
        }
    }
}