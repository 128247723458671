<template>
  <div>
    <!-- <b-button size="is-small" @click="isActiveModalUpload = true">Dane teleadresowe</b-button> -->

    <b-button
      @click="isActiveModal = true"
      size="is-small" type="is-secondary" icon-left="clipboard-flow-outline"
    >Modyfikuj usługę</b-button>

    <b-modal
      v-model="isActiveModal"
      has-modal-card
      aria-role="dialog"
      aria-modal
      scroll="keep"
      :can-cancel="false"
      class="p-5"
      trap-focus
      :destroy-on-hide="false"
    >
      <template #default="props">
        <ValidationObserver ref="observerDoctor" v-slot="{ invalid }">
          <div class="modal-card" style="overflow: visible">
            <header class="modal-card-head has-background-kin">
              <p class="modal-card-title has-text-light">Modyfikuj usługę:</p>
            </header>
            <section class="modal-card-body" style="min-height: 350px">
              <ContentPanel
                v-if="ticket && ikzAdded"
                title="Usługa"
                icon="format-list-bulleted-square"
              >
                <div style="min-height:2.5rem;">
                  <template slot="addon">
                    <b-tag v-if="ikzAdded.length < 1" type="is-danger" rounded>Wymagane</b-tag>
                  </template>
                  <b-field
                    label="Szukaj usługi"
                    label-position="on-border"
                    open-on-focus
                    :type="ikzAdded.length < 1 ? 'is-danger' : ''"
                  >
                    <b-autocomplete
                      :open-on-focus="true"
                      :data="filteredIkz"
                      clear-on-select
                      field="pharse"
                      placeholder="Zacznij pisać, aby wyświetlić sugerowane usługi"
                      @select="ikzSelected"
                      v-model="ikzFilter"
                    >
                      <template slot-scope="props">
                        <div style="white-space: pre-wrap">
                          <b-tag
                            v-if="props.option.icd9"
                            type="is-secondary"
                            class="mr-2"
                          >{{props.option.icd9}}</b-tag>
                          <span style="has-text-weight-semibold">{{props.option.name}}</span>
                          <small class="ml-1">{{props.option.code}}</small>
                        </div>
                      </template>
                    </b-autocomplete>
                  </b-field>
                </div>
                <div v-if="ikzAdded.length > 0">
                  <p class="has-text-weight-semibold mt-2 mb-2">Wybrana usługa:</p>
                  <div class="card p-1" v-for="(item, index) in ikzAdded" :key="index">
                    <b-tag v-if="item.icd9" type="is-secondary" class="ml-1 mr-2">{{item.icd9}}</b-tag>
                    <span style="has-text-weight-semibold">{{item.name}}</span>
                    <b-button type="is-text" size="is-small" @click="removeIkz(index)">Usuń</b-button>
                  </div>
                </div>
                <div v-else>
                  <p class="has-text-weight-semibold mt-2 mb-2">Brak wybranej usługi</p>
                </div>
              </ContentPanel>
            </section>
            <footer class="modal-card-foot">
              <b-button icon-left="close" @click="props.close">Anuluj</b-button>
              <b-button
                :loading="isLoading"
                :disabled="invalid || ikzAdded.length < 1 || !ticket"
                @click="saveExam"
                type="is-primary"
              >Zapisz zmiany</b-button>
            </footer>
          </div>
        </ValidationObserver>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import RISMixins from "@/mixins/ris";
import { Action } from "@/store/config_actions";
import OrdersMixins from "@/mixins/orders/poz_orders";
import { ValidationObserver } from "vee-validate";
import { Mutation } from "@/store/config_mutations";
import ReferralMixins from "@/mixins/referrals.js";
import IkzExam from "@/mixins/ikz_exams.js";

// import ContentPanel from "@/components/tools/ContentPanel";


export default {
  name: "SchedulePatientChangeIKZ",
  mixins: [CommonHelpers, RISMixins, OrdersMixins, ReferralMixins, IkzExam],
  components: {
    ValidationObserver,
  //  ContentPanel,
  },

  props: { examination: { type: Object, require: true } },

  data: function () {
    return {
      isLoading: false,
      changeInput: -1,
      isSimp: false,
      isNFZ: false,
      isPrivate: false,
      isHighCost: false,
      isActiveModal: false,
      changelocalization: -1,
      changeProcedure: null,
      newExaminationName: "",
      requestInProgress: false,

      bodySides: [],
      bodySide: null,

      referralDoctorPrefix: "",
      referralDoctorFirstName: "",
      referralDoctorLastName: "",
      referralDoctorPwz: "",
      referralDoctor: "",
      referralClinic: "",

      referredBy: -1,
      internalReferringClinicId: null,
      internalDoctor: true,
      contractorInput: "",
      contractor: null,

      internalClinic: true,
      subunitName: "",
      internalClinicId: null,

      employeeName: "",
      typeReferral: null,
      referralType: null,

      suggestedExaminationAdd: [],
      ikzFilter: '',
      ikzAdded: [],
      ikzIncluded: [],

      ticket: null,
    };
  },

  mounted() {
    if (!this.$store.state.finance.subjects) {
    //   this.getSubjects();
    }

    if (!this.$store.state.orgUnit.subunits || !this.$store.state.orgUnit.all) {
      // this.getSubUnits();
    }

  },

  watch: {
    isActiveModal() {
      if (this.isActiveModal == true) {
        this.getTicket()
      } 
    }
  },

  computed: {

    ikzs() {
        let ikzs = this.$store.state.finance.ikz
        return ikzs
    },

    me() {
      return this.$store.state.employee.me;
    },

    employeesAll() {
      return this.$store.state.employee.all ?? [];
    },

    filteredIkz() {
      if (this.ikzFilter.length < 1) {
        return this.ikzs
      }
      else {

        this.ikzs.forEach(element => {
          element.apc = element.pharse.toString().toLowerCase().indexOf(this.ikzFilter.toLowerCase())
        });

        var nonfiltered = this.ikzs.filter((option) => { return option.apc >= 0 })
        var sorted = nonfiltered.sort((a, b) => { return a.apc - b.apc })

        return sorted
      }
    },

    employees() {
      let array = [];
      if (this.employeesAll) {
        this.employeesAll.forEach((emp) => {
          if (
            emp.roles.includes(10) ||
            emp.roles.includes(11) ||
            emp.roles.includes(12) ||
            emp.roles.includes(20) ||
            emp.roles.includes(21)
          ) {
            if (!emp.roles.includes(999) && !emp.roles.includes(997)) {
              array.push(emp);
            }
          }
        });
      }
      return array;
    },

    subunitsAll() {
      return this.$store.state.orgUnit.subunits ?? [];
    },

    unitsAll() {
      return this.$store.state.orgUnit.all ?? [];
    },

    facilities() {
      return this.$store.state.clinic.clinics;
    },

    filteredEmployees() {
      if (this.employees) {
        return this.employees.filter((option) => {
          if (option.name) {
            return (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.employeeName.toLowerCase()) >= 0
            );
          } else return false;
        });
      } else return [];
    },

    subjects() {
      let subjects = this.$store.state.finance.subjects;
      if (subjects) {
        return subjects;
        // return subjects.filter((item) => item.isContractor === true);
      } else return [];
    },

    filteredSubjectsContractor() {
      if (this.subjects) {
        return this.subjects.filter((option) => {
          if (option.nameFull && option.domain != 1000) {
            return (
              option.nameFull
                .toString()
                .toLowerCase()
                .indexOf(this.contractorInput.toLowerCase()) >= 0
            );
          }
          return false;
        });
      } else return [];
    },

    transletedSubunits() {
      let translated = this.subunitsAll;

      translated.forEach((sub) => {
        if (sub) {
          if (sub.facilityId != null) {
            sub.facilityName = this.getFacility(sub.facilityId);
            sub.facilityObject = this.getFacilityObject(sub.facilityId);
          } else {
            sub.facilityName = "Nieokreślona";
            sub.facilityObject = null;
          }
        }
      });
      return translated;
    },

    filteredSubunits() {
      if (this.transletedSubunits) {
        return this.transletedSubunits.filter((option) => {
          if (option.facilityName) {
            return (
              option.facilityName
                .toString()
                .toLowerCase()
                .indexOf(this.subunitName.toLowerCase()) >= 0
            );
          }
          if (option.facilityObject) {
            if (option.facilityObject.address.street) {
              return (
                option.facilityObject.address.street
                  .toString()
                  .toLowerCase()
                  .indexOf(this.subunitName.toLowerCase()) >= 0
              );
            }

            if (option.facilityObject.address.city) {
              return (
                option.facilityObject.address.city
                  .toString()
                  .toLowerCase()
                  .indexOf(this.subunitName.toLowerCase()) >= 0
              );
            }
          }
          if (option.name) {
            return (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.subunitName.toLowerCase()) >= 0
            );
          }
        });
      }

      return [];
    },

    referralsType() {
      return this.$store.state.config.referrals ?? [];
    },

    appTypes() {
      return this.$store.state.config.appointments ?? [];
    },

    configClinics() {
      return this.$store.state.config.clinics ?? [];
    },

    isSimpDisabled() {
      if (this.isNFZ && (this.referralType && this.referralType.isSimpAllowed && this.referralType.isSimpRequired == false)) {
        return false;
      } else {
        return true;
      }
    },

    isHighCostDisabled() {
      if (this.isNFZ && (this.referralType && this.referralType.isHighCostAllowed)) {
        return false;
      } else {
        return true;
      }
    },

  },

  methods: {
    removeIkz(index) {
      if (this.ikzAdded) {
        this.ikzFilter = ''
        this.ikzAdded.splice(index, 1)
        // this.referralProcedures = []
        // this.suggestedExaminationAdd = [-1]
      }
    },

    ikzSelected(item) {
      if(item) {
        this.ikzAdded = []
        this.ikzAdded.push(item)
      }
    },

    getSubjects() {
      this.loading = true;
      this.$store
        .dispatch(Action.FINANCE_SUBJECTS_GET_ALL)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    getFacility(id) {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.id === id);
        return found != null ? found.name : `Brak (${id})`;
      } else return `Brak placówki (${id})`;
    },

    getFacilityObject(id) {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.id === id);
        return found;
      } else return null;
    },

    getWorker(id) {
      if (this.employeesAll && id) {
        let emp = this.employeesAll.find((e) => e.id === id);

        if (emp) {
          return emp.lastName + " " + emp.firstName;
        } else return "Brak informacji";
      } else return "Brak informacji";
    },

    getSubject(id) {
      if (this.subjects) {
        let subject = this.subjects.find((s) => s.id === id);
        if (subject) {
          return subject.nameFull;
        }
      }
      return "Nie znaleziono";
    },

    getSubunit(id) {
      if (this.subunitsAll) {
        let subunit = this.subunitsAll.find((s) => s.id === id);
        if (subunit) {
          return subunit.name;
        }
      }
      return "Nie znaleziono";
    },

    saveExam() {
      this.isActiveModal = false;
      this.ikzFilter = ''
      
      this.ticket.ikz = this.ikzAdded

      this.updateTicket(this.ticket);
    },

    getTicket() {
      this.$store
        .dispatch(Action.TICKET_GET, this.examination.ticket)
        .then((resp) => {
          if (resp) {
            this.$store.commit(Mutation.RIS_PUSH_TICKET_TO_STORE, resp);
            this.ticket = resp;
            this.ikzAdded = JSON.parse(JSON.stringify(this.ticket.ikz))
          }
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },


    updateTicket(ticket) {
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.TICKET_PUT, ticket)
        .then((resp) => {
          this.requestInProgress = false;
          this.successSnackbar("Zmiany zapisane");
          this.$emit("updated")
          this.$store.commit(Mutation.RIS_PUSH_TICKET_TO_STORE, resp);
        })
        .catch((error) => {
          this.requestInProgress = false;
          this.apiProblem(error);
        });
    },

    addExamination(option) {
      this.changeProcedure = option;
    },

    getSubUnits() {
      this.loading = true;
      this.$store
        .dispatch(Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_ALL)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
