export default {

    data: () => {
        return {
            /**
             * Worker task statuses
             */
            mWorkerTaskInfoStatuses: [
                {
                    id: 0,
                    name: "Zaplanowane"
                },
                {
                    id: 1,
                    name: "Realizowane"
                },
                {
                    id: 2,
                    name: "Ukończone"
                },
            ],
            /**
             * Priority types of tasks
             */
            mWorkerTaskInfoPriorityTypes: [
                {
                    id: 0,
                    name: "Niski"
                },
                {
                    id: 1,
                    name: "Wysoki"
                }
            ],
            /**
             * Worker task roles
             */
            mWorkerTaskInfoRoles: [
                {
                    id: 1,
                    acronym: "R",
                    description: "Osoba odpowiedzialna za wykonanie zadania"
                },
                {
                    id: 2,
                    acronym: "A",
                    description: "Osoba nadzorująca"
                },
                {
                    id: 3,
                    acronym: "S",
                    description: "Osoba wspierająca działanie zespołu"
                },
                {
                    id: 4,
                    acronym: "C",
                    description: "Osoba pełniąca rolę konsultanta"
                },
                {
                    id: 5,
                    acronym: "I",
                    description: "Osoba informowana o prowadzonych działaniach"
                }
            ],
            mWorkerTaskInfoNewRoleObject: {
                workerId: 0,
                role: null,
                status: 0
            }
        }
    },

    methods: {
        /**
         * Gets worker task info status name by id
         * @param id task info status
         */
        mGetWorkerTaskInfoStatusName(id) {
            let status = this.mWorkerTaskInfoStatuses.find(s => s.id == id)
            if (status) return status.name
        },
        /**
         * Gets worker task info priority type name by id
         * @param id task info priority type
         */
        mGetWorkerTaskInfoPriorityTypeName(id) {
            let priorityType = this.mWorkerTaskInfoPriorityTypes.find(p => p.id == id)
            if (priorityType) return priorityType.name
        },
        /**
         * Gets worker task info role description by id
         * @param id task info role
         */
        mGetWorkerTaskInfoRoleDescription(id) {
            let role = this.mWorkerTaskInfoRoles.find(r => r.id == id)
            if (role) return role.description
        },
        /**
         * Gets worker task info role acronym by id
         * @param id task info role
         */
        mGetWorkerTaskInfoRoleAcronym(id) {
            let role = this.mWorkerTaskInfoRoles.find(r => r.id == id)
            if (role) return role.acronym
        }
    }
}