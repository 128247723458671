import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations';

export default {
    namespaced: false,

    state: {
        medQuestionGroups: [],
        medQuestionsConfig: [],
    },

    getters: {},

    mutations: {
        [Mutation.MED_QUESTION_GROUP_SET](state, payload) {
            state.medQuestionGroups = payload
        },

        [Mutation.MED_QUESTIONS_CONFIG_SET](state, payload) {
            state.medQuestionsConfig = payload
        },
    },

    actions: {

        /** Attempt to add med questions config. Uses POST request and requires med questions config data object (check config_apis.js) */
        [Action.MED_QUESTIONS_CONFIG_CREATE](context, medQuestions) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MED_QUESTIONS_CONFIG_GET_ALL,
                        data: medQuestions,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all med questions config. Uses GET request without parameter */
        [Action.MED_QUESTIONS_CONFIG_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTIONS_CONFIG_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MED_QUESTIONS_CONFIG_SET, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get med questions config. Uses GET request */
        [Action.MED_QUESTIONS_CONFIG_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTIONS_CONFIG_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt med questions config delete. Uses DELETE request */
        [Action.MED_QUESTIONS_CONFIG_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MED_QUESTIONS_CONFIG_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt med questions config edit. Uses PUT request with id and requires med questions config data object (check config_apis.js)*/
        [Action.MED_QUESTIONS_CONFIG_UPDATE](context, medQuestions) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MED_QUESTIONS_CONFIG_GET_BY_ID.replace('{id}', medQuestions.id),
                        data: medQuestions,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get med questions config by patient. Uses GET request */
        [Action.MED_QUESTIONS_CONFIG_GET_BY_PATIENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTIONS_CONFIG_GET_BY_PATIENT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get med questions config by employee. Uses GET request */
        [Action.MED_QUESTIONS_CONFIG_GET_BY_EMPLOYEE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTIONS_CONFIG_GET_BY_EMPLOYEE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },    

        /** Attempt to get med questions config by filter. Uses GET request */
        [Action.MED_QUESTIONS_CONFIG_GET_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTIONS_CONFIG_GET_FILTER.replace('{clinicId}', payload.clinic).replace('{workerId}', payload.worker).replace('{patientId}', payload.patient),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        }, 

        /** Attempt to get med questions config translated by id. Uses GET request */
        [Action.MED_QUESTIONS_CONFIG_GET_FULL_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTIONS_CONFIG_GET_FULL_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        }, 

        /** Attempt to get med questions config translated by id. Uses GET request */
        [Action.MED_QUESTIONS_CONFIG_GET_FULL_BY_CLINIC](context, clinicId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTIONS_CONFIG_GET_FULL_BY_CLINIC.replace('{id}', clinicId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },    

        /** Attempt get all med questions config. Uses GET request without parameter */
        [Action.MED_QUESTION_GROUP_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_GROUP_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MED_QUESTION_GROUP_SET, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get med questions config. Uses GET request */
        [Action.MED_QUESTION_GROUP_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_GROUP_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add med questions config. Uses POST request and requires med questions config data object (check config_apis.js) */
        [Action.MED_QUESTION_GROUP_CREATE](context, medQuestions) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MED_QUESTION_GROUP_GET_ALL,
                        data: medQuestions,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt med questions config delete. Uses DELETE request */
        [Action.MED_QUESTION_GROUP_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MED_QUESTION_GROUP_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt med questions config edit. Uses PUT request with id and requires med questions config data object (check config_apis.js)*/
        [Action.MED_QUESTION_GROUP_UPDATE](context, medQuestions) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MED_QUESTION_GROUP_GET_BY_ID.replace('{id}', medQuestions.id),
                        data: medQuestions,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get med questions config. Uses GET request */
        [Action.MED_QUESTION_GROUP_GET_BY_INTERNAL_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_GROUP_GET_BY_INTERNAL_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get med questions config. Uses GET request */
        [Action.MED_QUESTION_GROUP_GET_BY_TYPE](context, type) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_GROUP_GET_BY_TYPE.replace('{type}', type),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all med questions config. Uses GET request without parameter */
        [Action.MED_QUESTION_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get med questions config. Uses GET request */
        [Action.MED_QUESTION_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get med questions config. Uses GET request */
        [Action.MED_QUESTION_GET_SORTED](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_GET_SORTED.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add med question. Uses POST request and requires med question data object (check config_apis.js) */
        [Action.MED_QUESTION_CREATE](context, medQuestion) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MED_QUESTION_GET_ALL,
                        data: medQuestion,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    
        /** Attempt to add med question from old json object (allQuestions.json). Uses POST request and requires med question data object (check config_apis.js) */
        [Action.MED_QUESTION_CREATE_FROM_OLD](context, medQuestion) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MED_QUESTION_ADD_FROM_OLD,
                        data: medQuestion,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add many med question from old json object (allQuestions.json). Uses POST request and requires med question data object (check config_apis.js) */
        [Action.MED_QUESTION_CREATE_MANY_FROM_OLD](context, medQuestion) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MED_QUESTION_ADD_MANY_FROM_OLD,
                        data: medQuestion,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt med question delete. Uses DELETE request */
        [Action.MED_QUESTION_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MED_QUESTION_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt med question edit. Uses PUT request with id and requires med question data object (check config_apis.js)*/
        [Action.MED_QUESTION_UPDATE](context, medQuestion) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MED_QUESTION_GET_BY_ID.replace('{id}', medQuestion.id),
                        data: medQuestion,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MED_QUESTION_ATTACHMENTS_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_ATTACHMENTS_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MED_QUESTION_ATTACHMENTS_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_ATTACHMENTS_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MED_QUESTION_ATTACHMENTS_GET_BY_INTERNAL_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_ATTACHMENTS_GET_BY_INTERNAL_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MED_QUESTION_ATTACHMENTS_GET_BY_TYPE](context, type) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MED_QUESTION_ATTACHMENTS_GET_BY_TYPE.replace('{type}', type),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MED_QUESTION_ATTACHMENTS_CREATE](context, medQuestionAttachment) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MED_QUESTION_ATTACHMENTS_GET_ALL,
                        data: medQuestionAttachment,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt med questions config delete. Uses DELETE request */
        [Action.MED_QUESTION_ATTACHMENTS_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MED_QUESTION_ATTACHMENTS_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MED_QUESTION_ATTACHMENTS_UPDATE](context, medQuestionAttachment) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MED_QUESTION_ATTACHMENTS_GET_BY_ID.replace('{id}', medQuestionAttachment.id),
                        data: medQuestionAttachment,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MED_QUESTION_ATTACHMENTS_UPLOAD_ATTACHMENT](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    // payload.append('hasRestrictedDomains', form.hasRestrictedDomains)
                    // payload.append('domains', form.domains)
                    // payload.append('type', form.type)
                    // payload.append('name', form.name)
                    // payload.append('fileName', form.fileName)
                    // payload.append('description', form.description)
                    // payload.append('configs', form.configs)
                    // payload.append('groups', form.groups)
                    // payload.append('questions', form.questions)
                    payload.append('file', form.file)                    
                    payload.append('info', form.info)                    

                    axios({
                        method: 'post',
                        url: Apis.MED_QUESTION_ATTACHMENTS_UPLOAD_ATTACHMENT,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MED_QUESTION_ATTACHMENTS_DOWNLOAD](context, attachmentId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.MED_QUESTION_ATTACHMENTS_DOWNLOAD.replace('{id}', attachmentId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

    }
}