import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        prices: [],
    },

    getters: {},

    mutations: {
        [Mutation.PRICE_LIST_SET_ALL](state, payload) {
            state.prices = payload
        },
    },

    actions: {

        /** Attempt to add worker. Uses POST request and requires worker data object (check config_apis.js) */
        [Action.FINANCE_PRICE_LIST_CREATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_PRICE_LIST_GET_ALL,
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all workers. Uses GET request without parameter */
        [Action.FINANCE_PRICE_LIST_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_PRICE_LIST_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PRICE_LIST_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.FINANCE_PRICE_LIST_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_PRICE_LIST_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.FINANCE_PRICE_LIST_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_PRICE_LIST_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.FINANCE_PRICE_LIST_UPDATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_PRICE_LIST_GET.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get price list by subject. Uses GET request */
        [Action.FINANCE_PRICE_LIST_GET_BY_SUBJECT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_PRICE_LIST_GET_BY_SUBJECT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get price list by subject grouped. Uses GET request */
        [Action.FINANCE_PRICE_LIST_GET_BY_SUBJECT_GROUPED](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_PRICE_LIST_GET_BY_SUBJECT_GROUPED.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // SUBJECT PRICES

        /** Attempt to add worker. Uses POST request and requires worker data object (check config_apis.js) */
        [Action.FINANCE_SUBJECT_PRICES_CREATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_SUBJECT_PRICES_GET_ALL,
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all workers. Uses GET request without parameter */
        [Action.FINANCE_SUBJECT_PRICES_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_SUBJECT_PRICES_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.FINANCE_SUBJECT_PRICES_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_SUBJECT_PRICES_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.FINANCE_SUBJECT_PRICES_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_SUBJECT_PRICES_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.FINANCE_SUBJECT_PRICES_UPDATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_SUBJECT_PRICES_GET.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get price list by subject. Uses GET request */
        [Action.FINANCE_SUBJECT_PRICES_GET_BY_SUBJECT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_SUBJECT_PRICES_GET_BY_SUBJECT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add worker. Uses POST request and requires worker data object (check config_apis.js) */
        [Action.FINANCE_SUBJECT_PRICES_PRICE_CREATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_SUBJECT_PRICES_PRICE_CREATE.replace('{baseId}', worker.baseId),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.FINANCE_SUBJECT_PRICES_PRICE_UPDATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_SUBJECT_PRICES_PRICE_UPDATE.replace('{baseId}', worker.baseId).replace('{priceInternalId}', worker.internalId),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.FINANCE_SUBJECT_PRICES_PRICE_DELTE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_SUBJECT_PRICES_PRICE_UPDATE.replace('{baseId}', worker.baseId).replace('{priceInternalId}', worker.internalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}