<template>
  <div>
    <b-tooltip type="is-success" v-if="patient.teleAddressTelephoneConfirmed" label>
      <b-button size="is-small" type="is-success" icon-left="phone-check" disabled></b-button>
      <template v-slot:content>
        <p>Zweryfikowano numer telefonu</p>
      </template>
    </b-tooltip>
    <b-tooltip v-else-if="isPhoneProvided" label="Zweryfikuj numeru telefonu" type="is-danger">
      <b-button
        size="is-small"
        icon-left="phone-check"
        type="is-danger"
        @click="showModal"
      ></b-button>
    </b-tooltip>
    <b-tooltip type="is-warning" v-else-if="!isPhoneProvided" label>
      <b-button size="is-small" type="is-warning" icon-left="phone-check" disabled></b-button>
      <template v-slot:content>
        <p>Brak poprawnego numeru telefonu</p>
      </template>
    </b-tooltip>
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <ContentPanel icon="phone-check" title="Weryfikacja numeru telefonu" style="width: 30rem">
        <template slot="addon">
          <b-button @click="isComponentModalActive = false" size="is-small" icon-left="close">Anuluj</b-button>
        </template>
        <b-field grouped>
          <b-field expanded label="Prefix" label-position="on-border" style="width: 6rem">
            <b-input disabled expanded v-model="patient.teleAddressTelephonePrefix"></b-input>
          </b-field>
          <b-field expanded label="Numer telefonu" label-position="on-border">
            <b-input disabled expanded v-model="patient.teleAddressTelephoneNumber"></b-input>
          </b-field>
          <b-field>
            <b-button
              @click="sendPhoneToken"
              :loading="loading"
              :disabled="!isPhoneProvided || !isDigitsOnly"
              type="is-primary"
              size="is-small"
              icon-left="phone-forward"
              >Wyślij kod na numer</b-button
            >
          </b-field>
        </b-field>
        <b-field grouped>
            <b-field expanded label="Kod dostępu" label-position="on-border" :type="message.length < 1 ? 'is-danger' : ''">
                <b-input type="number" expanded v-model="message"></b-input>
            </b-field>
          <b-field>
            <b-button
              @click="sendPhoneCode"
              :loading="loading"
              :disabled="!isPhoneProvided || !isDigitsOnly || message.length < 1"
              type="is-secondary"
              size="is-small"
              icon-left="check-all"
              >Potwierdź kod</b-button
            >
          </b-field>
        </b-field>
        <!-- <b-notification type="is-kin" v-if="appointment" class="mb-1">
          Wiadomość zostanie przypięta do wizyty
          <b>#{{appointment.id}}</b>
        </b-notification> -->
        <b-notification type="is-kin" v-if="patient">
          Numer telefonu zostanie zweryfikowany dla pacjenta
          <br />
          <b>{{ patient.firstName }} {{ patient.lastName }}</b>
        </b-notification>
      </ContentPanel>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from "../../mixins/commons"
import { Action } from "../../store/config_actions"

export default {
  name: "PhoneVerification",

  mixins: [CommonMixins],

  components: {
    //  ContentPanel
  },

  data: function () {
    return {
      isComponentModalActive: false,
      loading: false,
      message: "",
    }
  },

  watch: {},

  mounted() {},

  props: {
    appointment: { type: Object, required: false },
    patient: { type: Object, required: false },
  },

  computed: {
    perms() {
      return this.$store.state.employee.permits
    },

    /** My account data */
    me() {
      return this.$store.state.employee.me
    },

    phoneLocal() {
      if (this.patient) {
        const patientNumber = this.patient.teleAddressTelephoneNumber ?? ""
        let phone = `${patientNumber}`

        phone = phone.replace(/[\n\r\s\t]+/g, "")
        return phone
      }

      return ""
    },

    isPhoneProvided() {
      if (this.phoneLocal && this.phoneLocal.length > 0) return true
      else return false
    },

    isDigitsOnly() {
      if (this.patient.teleAddressTelephoneNumber) return /^\d+$/.test(this.patient.teleAddressTelephoneNumber)
      else return false
    },
  },

  methods: {
    showModal() {
      this.message = ""
      this.isComponentModalActive = true
    },

    sendPhoneToken() {
      this.loading = true
      this.$store
        .dispatch(Action.IDENTITY_TELEPHONE_VERIFICATION_TOKEN, { phone: this.phoneLocal, patientId: this.patient.id })
        .then((response) => {
          this.loading = false
          this.message = response ?? ""
        })
        .catch((error) => {
          this.loading = false
          this.apiProblem(error)
        })
    },

    sendPhoneCode() {
      this.loading = true
      this.$store
        .dispatch(Action.IDENTITY_TELEPHONE_TOKEN_VALIDATION, { code: this.message + "", patientId: this.patient.id })
        .then((response) => {
          this.loading = false
          this.message = ""

          if (response.validated === true) {
            this.snackbar("Zweryfikowano numer telefonu")
            this.isComponentModalActive = false
            this.$emit('validated', response)
          } else {
            this.snackbar("Błąd weryfikacji numeru telefonu, wyślij ponownie kod")
          }
        })
        .catch((error) => {
          this.loading = false
          this.apiProblem(error)
        })
    },

  },
}
</script>

<style scoped lang="scss"></style>
