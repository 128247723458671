<template>
  <div>
    <b-tooltip v-if="perms.smsSending && isPhoneProvided" label="Wyślij SMS" type="is-secondary">
      <b-button
        size="is-small"
        icon-left="message-text"
        type="is-secondary"
        @click="showModal"
        :disabled="!isPhoneProvided"
      ></b-button>
    </b-tooltip>
    <b-tooltip type="is-warning" v-else-if="perms.smsSending && !isPhoneProvided" label>
      <b-button size="is-small" type="is-warning" icon-left="message-text" disabled></b-button>
      <template v-slot:content>
        <p>Brak poprawnego numeru telefonu</p>
      </template>
    </b-tooltip>
    <b-tooltip v-else type="is-dark">
      <b-button size="is-small" icon-left="message-text" type="is-static" disabled></b-button>
      <template v-slot:content>
        <p>Brak uprawnień do wysyłania sms</p>
      </template>
    </b-tooltip>
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <ContentPanel icon="message-text" title="Bramka SMS" style="width: 30rem;">
        <template slot="addon">
          <b-button
            @click="sendSms"
            type="is-secondary"
            size="is-small"
            :loading="loading"
            icon-right="send"
            class="mr-1"
            :disabled="!isPhoneProvided || message.length < 1 || !isDigitsOnly"
          >Wyślij SMS</b-button>
          <b-button @click="isComponentModalActive = false" size="is-small" icon-left="close">Anuluj</b-button>
        </template>
        <b-field grouped>
          <b-field
            expanded
            label="Numer telefonu"
            label-position="on-border"
            :message="isDigitsOnly ? '' : 'Niepoprawny format numeru telefonu'"
            :type="isDigitsOnly ? '' : 'is-danger'"
          >
            <b-input expanded v-model="phoneLocal"></b-input>
          </b-field>
        </b-field>
        <b-field
          label="Wiadomość"
          label-position="on-border"
          :type="message.length < 1 ? 'is-danger':''"
        >
          <b-input type="textarea" :has-counter="true" v-model="message"></b-input>
        </b-field>
        <b-notification type="is-kin" v-if="appointment" class="mb-1">
          Wiadomość zostanie przypięta do wizyty
          <b>#{{appointment.id}}</b>
        </b-notification>
        <b-notification type="is-kin" v-if="patient">
          Wiadomość zostanie przypięta do pacjenta
          <br />
          <b>{{patient.firstName}} {{patient.lastName}}</b>
        </b-notification>
      </ContentPanel>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from '../../mixins/commons'
import { Action } from '../../store/config_actions'
// import ContentPanel from '@/components/tools/ContentPanel'
//import moment from 'moment'

export default {
  name: 'SmsGateModal',

  mixins: [CommonMixins],

  components: {
  //  ContentPanel
  },

  data: function () {
    return {
      isComponentModalActive: false,

      /** Request in progress indicator */
      loading: false,

      /** SMS message content */
      message: "",

      phoneLocal: ""
    }
  },

  watch: {
    phone(val) {
      if (val) {
        this.phoneLocal = val.replace(/[\n\r\s\t]+/g, '')
      }
    }
  },

  mounted() {
    if (this.phone) {
      this.phoneLocal = this.phone.replace(/[\n\r\s\t]+/g, '')
    }
  },

  props: {
    phone: { type: String, required: false },
    appointment: { type: Object, required: false },
    patient: { type: Object, required: false }
  },

  computed: {

    perms() {
      return this.$store.state.employee.permits
    },

    /** My account data */
    me() { return this.$store.state.employee.me; },

    isPhoneProvided() {
      if (this.phoneLocal && this.phoneLocal.length > 0) return true
      else return false
    },

    isDigitsOnly() {
      if (this.phoneLocal) return /^\d+$/.test(this.phoneLocal);
      else return false
    }
  },

  methods: {

    showModal() {
      if (this.phone) {
        this.phoneLocal = this.phone.replace(/[\n\r\s\t]+/g, '')
      }
      this.message = ""
      this.isComponentModalActive = true
    },

    /**
     * Sends sms to queue to be sent to provided phone number.
     * @author MC 
     */
    sendSms() {

      var payload = {
        "domainId": this.appointment ? this.appointment.domainId : this.me.domainId,
        "appointmentId": this.appointment ? this.appointment.id : -1,
        "patientId": this.patient ? this.patient.id : -1,
        "phone": this.phoneLocal,
        "content": this.message
      }

      this.$buefy.dialog.confirm({
        message: `Czy na pewno wysłać SMS o treści:<br /><br /><i>${payload.content}</i><br /><br />na numer telefonu <b>${payload.phone}</b> ?`,
        hasIcon: true,
        type: 'is-info',
        cancelText: 'Anuluj',
        confirmText: 'Tak, wyślij SMS',
        scroll: 'keep',
        onConfirm: () => {
          this.loading = true
          this.$store
            .dispatch(Action.SMS_SEND, payload)
            .then((response) => {
              this.loading = false
              this.message = ""
              this.isComponentModalActive = false
              this.snackbar("Wysłano wiadomość SMS")
              this.$emit('sent', response)
            })
            .catch(error => {
              this.loading = false
              this.apiProblem(error);
            });
        }
      })


    }
  }
}
</script>

<style scoped lang="scss">
</style>
