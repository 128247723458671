import { Action } from '../config_actions'
import { Apis } from '../config_apis'
// import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
    },

    mutations: {

    },

    actions: {
        [Action.FINANCE_NFZ_SERVICE_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_NFZ_SERVICE_TYPE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        // context.commit(Mutation.CONTRACTORS_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_NFZ_SERVICE_TYPE_GET_BY_ID](context,id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_NFZ_SERVICE_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_NFZ_SERVICE_TYPE_CREATE](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_NFZ_SERVICE_TYPE_CREATE,
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_NFZ_SERVICE_TYPE_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_NFZ_SERVICE_TYPE_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_NFZ_SERVICE_TYPE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_NFZ_SERVICE_TYPE_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
    }
}