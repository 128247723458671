//import { Action } from '../config_actions'
//import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
//import axios from 'axios'

export default {
    namespaced: false,

    state: {
        scheduleApplicationTypeVisible: false
    },

    mutations: {
        [Mutation.LOCALS_SCHEDULE_APP_TYPE_VISIBIILITY](state, payload) {
            state.scheduleApplicationTypeVisible = payload
        },
    },

    actions: {

    }
}