<template>
  <div>
    <!-------------------------------->
    <!--******** TREATMENTS ********-->
    <!-------------------------------->
    <div v-if="order.orderType == 0">
      <div v-if="order.procedures">
        <div v-for="(item, index) in order.procedures" :key="index">
          <div v-if="order.treatmentType == 0">
            <p>
              Lokalizacja:
              {{ getOrderArrayValue(orderLocalizations, item.localization) }}
              {{
                item.localizationDescription
                  ? `, ${item.localizationDescription}`
                  : ""
              }}
            </p>
            <p>
              Rodzaj rany:
              {{ getOrderArrayValue(orderWoundTypes, item.woundType) }},
              {{
                getOrderArrayValue(orderWoundExactTypes, item.woundExactType)
              }}
            </p>
            <p>Stan: {{ item.description ? item.description : "-" }}</p>
            <p>
              Metoda leczenia:
              {{
                getOrderArrayValue(orderTreatmentMethods, item.treatmentMethod)
              }}
            </p>
            <p v-if="item.comment">
              Uwagi: {{ item.comment ? item.comment : "-" }}
            </p>
          </div>

          <div v-if="order.treatmentType == 1 || order.treatmentType == 10">
            <p>
              Droga podania:
              {{
                getOrderArrayValue(
                  orderInjectonRouteOfAdministration,
                  item.routeOfAdministration
                )
              }}
            </p>
            <p v-if="item.routeOfAdministration !== -1">
              Miejsce podania:
              {{
                getOrderArrayValue(
                  orderInjectonRouteOfAdministration[item.routeOfAdministration]
                    .options_localization,
                  item.routeOfAdministratonLocation
                )
              }}
              <span
                v-if="
                  item.routeOfAdministration !== -1 &&
                  item.routeOfAdministratonLocation !== -1 &&
                  orderInjectonRouteOfAdministration[item.routeOfAdministration]
                    .options_localization[item.routeOfAdministratonLocation]
                    .options
                "
                >, {{ item.routeOfAdministratonLocationDescription }}
              </span>
            </p>
            <p v-if="item.drug">
              Wybrany lek:
              {{ item.drug.nazwaProduktu }}, {{ item.drug.moc }},
              {{ item.drug.postac }}
            </p>
            <p>
              Dawkę:
              {{ item.dose ? item.dose : "-" }},
              {{ item.doseNumber ? `numer: ${item.doseNumber}` : "numer: -" }},
              {{ getOrderArrayValue(orderContinuation, item.continuation) }},
            </p>
            <p v-if="item.comment">
              Uwagi: {{ item.comment ? item.comment : "-" }}
            </p>
          </div>
          <div v-if="order.treatmentType == 2">
            <p>
              Lokalizacja:
              {{ getOrderArrayValue(orderLocalizations, item.localization) }}
              {{
                item.localizationDescription
                  ? `, ${item.localizationDescription}`
                  : ""
              }}
            </p>
            <p>
              Rodzaj szwów:
              {{ getOrderArrayValue(orderStitchesTypes, item.stitchesType) }},
              doba po zabiegu: {{ item.stitchesDayAfter }}
            </p>
            <p v-if="item.comment">
              Uwagi: {{ item.comment ? item.comment : "-" }}
            </p>
          </div>
          <div v-if="order.treatmentType == 3">
            <p v-if="item.drug">
              Wybrany lek:
              {{ item.drug.nazwaProduktu }}, {{ item.drug.moc }},
              {{ item.drug.postac }}
            </p>
            <p>
              Dawkę:
              {{ item.dose ? item.dose : "-" }},
              {{ item.doseNumber ? `numer: ${item.doseNumber}` : "numer: -" }},
              {{ getOrderArrayValue(orderContinuation, item.continuation) }},
            </p>
            <p v-if="item.comment">
              Uwagi: {{ item.comment ? item.comment : "-" }}
            </p>
          </div>
          <div v-if="order.treatmentType == 4">
            <p>
              Cel zabiegu:
              <span v-if="item.purpose === -1">
                {{ getOrderArrayValue(orderPVCPurpose, 0) }}
              </span>
              <span v-else>
                {{ getOrderArrayValue(orderPVCPurpose, item.purpose) }}
              </span>
            </p>
            <p>
              Lokalizacja:
              {{ getOrderArrayValue(orderLocalizations2, item.localization) }}
            </p>
            <p>
              Ciągłość podania:
              {{ getOrderArrayValue(orderGoalTypes, item.continuation) }},
            </p>
            <p v-if="item.comment">
              Uwagi: {{ item.comment ? item.comment : "-" }}
            </p>
          </div>
          <div v-if="order.treatmentType == 5">
            <p v-if="item.drug">
              Wybrany lek:
              {{ item.drug.nazwaProduktu }}, {{ item.drug.moc }},
              {{ item.drug.postac }}
            </p>
            <p>
              Dawkę:
              {{ item.dose ? item.dose : "-" }},
              {{ getOrderArrayValue(orderContinuation, item.continuation) }},
            </p>
            <p v-if="item.comment">
              Uwagi: {{ item.comment ? item.comment : "-" }}
            </p>
          </div>
          <div v-if="order.treatmentType == 6">
            <p>
              Lokalizacja:
              {{ getOrderArrayValue(orderLocalizations3, item.localization) }}
            </p>
            <p>Powód płukania: {{ item.reason ? item.reason : "-" }},</p>
            <p v-if="item.comment">
              Uwagi: {{ item.comment ? item.comment : "-" }}
            </p>
          </div>
          <div v-if="order.treatmentType == 7">
            <p>
              Lokalizacja:
              {{ getOrderArrayValue(orderLocalizations4, item.localization) }}
            </p>
          </div>
          <div v-if="order.treatmentType == 8">
            <p>
              Rodzaj cewnika:
              {{
                getOrderArrayValue(
                  orderCatheterizationType,
                  item.catheterizationType
                )
              }}
            </p>
            <p>
              Ciągłość podania:
              {{ getOrderArrayValue(orderContinuation, item.continuation) }},
            </p>
            <p v-if="item.comment">
              Uwagi: {{ item.comment ? item.comment : "-" }}
            </p>
          </div>
          <div v-if="order.treatmentType == 9">
            <p>
              Lokalizacja:
              {{ getOrderArrayValue(orderLocalizations, item.localization) }}
            </p>
            <p>
              Rodzaj opatrunku:
              {{ getOrderArrayValue(orderBandageTypes, item.bandageType) }}
            </p>
            <p v-if="item.comment">
              Uwagi: {{ item.comment ? item.comment : "-" }}
            </p>
          </div>
          <div v-if="order.treatmentType == 10">
            <p v-if="item.comment">
              Uwagi: {{ item.comment ? item.comment : "-" }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!------------------------------------->
    <!--********** SZCZEPIENIE **********-->
    <!------------------------------------->
    <div v-if="order.orderType == 1">
      <div v-if="order.vaccinations">
        <div v-for="(item, index) in order.vaccinations" :key="index">
          <p>
            Rodzaj szczepienia:
            <span v-if="item.vaccinationType == 0">Obowiązkowe</span>
            <span v-else-if="item.vaccinationType == 1">Zalecane</span>
            <span v-else>-</span>
          </p>
          <p>Cel podania (przeciwko czemu): {{ item.description }}</p>
          <p v-if="item.drug">
            Wybrany lek:
            {{ item.drug.nazwaProduktu }}, {{ item.drug.moc }},
            {{ item.drug.postac }}
          </p>
          <p>
            Sposób podania:
            {{
              getOrderArrayValue(
                orderVaccineRouteOfAdministration,
                item.vaccinationRouteOfAdministration
              )
            }}
          </p>
          <p>
            Która dawka:
            {{
              getOrderArrayValue(
                orderVaccineDoseOrder,
                item.vaccinationDoseNumber
              )
            }}
          </p>
          <p>
            Powód opóźnienia:
            {{
              getOrderArrayValue(
                orderVaccineDelayReson,
                item.vaccinationDelayReason
              )
            }}
          </p>
          <p v-if="item.comment">
            Uwagi: {{ item.comment ? item.comment : "-" }}
          </p>
        </div>
      </div>
    </div>
    <!---------------------------------->
    <!--**********  POMIARY **********-->
    <!---------------------------------->
    <div v-if="order.orderType === 3">
      <div v-if="order.measurements">
        <div v-for="(item, index) in order.measurements" :key="index">
          <p>
            Pacjent:
            <span v-if="item.ticketType == 5001">Dziecko</span>
            <span v-if="item.ticketType == 5002">Dorosły</span>
          </p>

          <p>
            Pomiary:
            <span v-for="item in item.types" :key="item">
              {{ getOrderArrayValue(orderMeasurementTypes, item) }}
            </span>
          </p>

          <p v-if="item.comment">
            Uwagi: {{ item.comment ? item.comment : "-" }}
          </p>
        </div>
      </div>
    </div>
    <!---------------------------------->
    <!--**********  WYROBY MEDYCZNE **********-->
    <!---------------------------------->
    <div v-if="order.orderType === 4">
      <p>{{ order.name }}
      <b-tooltip v-if="order.result != null && order.statusSended != null && order.isCancelled !== true" 
        type="is-light"
        multilined
      >
        <template v-slot:content>
          <p
            v-if="order.result"
            :class="{
              'has-text-danger': (order.result.ezwmStatus === 4 || order.result.ezwmStatus === 7),
              'has-text-success has-text-weight-bold':
                order.result.ezwmStatus === 3,
            }"
          >
            [{{ order.result.ezwmStatus }}]
            {{ getStatus(order.result.ezwmStatus) }}
            <small
              >({{
                mDateTime(order.result.ezwmStatusChange)
              }})</small
            >
          </p>
          <div v-if="order.result && order.result.ezwmErrors">
            <p  v-for="(error, error_index) in order.result.ezwmErrors" :key="error_index" style="font-size: 0.7rem;white-space: pre-wrap;word-break: break-word;">
              {{error.errorMessage}}[{{error.errorCode}}]
            </p>            
          </div>
          
        </template>
        <b-icon icon="check-circle-outline" v-if="order.statusSended === 1" type="is-success"></b-icon>
        <b-icon icon="close-circle-outline" v-else-if="order.statusSended === 2" type="is-danger"></b-icon>
        <b-icon icon="send" v-else type="is-primary"></b-icon>
      </b-tooltip>
      </p>
      <div v-if="order.result && order.result.ezwmErrors">
        <ChangeEZWMPassword class="is-pulled-right" v-if="showButton && order.result.isPasswordExpired" :domain="order.result.domain" :configurationId="order.result.workerConfigurationId" :isExpired="true" :isCloseClear="false" />
        <p  v-for="(error, error_index) in order.result.ezwmErrors" :key="error_index" class="has-text-danger" style="font-size: 0.7rem;white-space: pre-wrap;word-break: break-word;">
          {{error.errorMessage}}[{{error.errorCode}}]
        </p>
        
      </div>
      <p class="has-text-weight-semibold" v-if="order.resultDoc && order.resultDoc.documentStatus ">
        Status dokumentu: {{order.resultDoc.documentStatus}}
      </p>
      <b-skeleton v-else-if="order.result && order.result.ezwmStatus===3" animated />
      <div v-if="order.resultDoc && order.resultDoc.ezwmErrors">
        <p  v-for="(error, error_index) in order.resultDoc.ezwmErrors" :key="error_index" class="has-text-danger" style="font-size: 0.7rem;white-space: pre-wrap;word-break: break-word;">
          {{error.errorMessage}}[{{error.errorCode}}]
        </p>
      </div>
      <p class="has-text-weight-semibold" v-if="order.resultDoc && order.resultDoc.documentPin">
        PIN: {{order.resultDoc.documentPin}}
      </p>
      <p v-if="order.result">        
        Numer nfz: {{ order.result.documentNumber }} <br />
        Id tech. nfz: {{ order.result.documentKey }} <br />
        Numer: {{ order.result.providedDocumentKey }} 
      </p>
      <p
        class="has-text-danger"
        v-if="
          order.isCancelled
        "
      >
        ANULOWANO
      </p>
    </div>
    <!---------------------------------->
    <!--********** TRANSPORT **********-->
    <!---------------------------------->
    <div v-if="order.orderType === 5">
      <div v-if="order.transport">
        <div v-for="(transportItem, index) in order.transport" :key="index">
          <p>Data i godzina: {{ mDateTime(transportItem.date) }}</p>
          <p>Z: (adres): {{ transportItem.transportFrom }}</p>
          <p>Do: (adres): {{ transportItem.transportTo }}</p>
          <p v-if="transportItem.formType != 1">
            Miejsce przewozu jest najbliższym miejscem właściwym ze względu na
            stan zdrowia pacjenta:
            {{ transportItem.transportPlaceClosest === 1 ? "Tak" : "Nie" }}
          </p>
          <p v-if="transportItem.formType !== 1"> Cel przewozu: {{ getOrderArrayValue(orderTransportGoals, transportItem.goalType) }}</p>
          <p v-else>Cel przewozu: {{ transportItem.goal }}</p>
          <p>
            Rodzaj transportu:
            {{
              getOrderArrayValue(
                orderTransportTypes,
                transportItem.transportType
              )
            }}
          </p>
          <p v-if="transportItem.formType === 1">
            Typ transportu: {{
              getOrderArrayValue(
                orderVehicleTypes,
                transportItem.transportVehicleType
              )
            }}
            <span v-if="transportItem.transportVehicleText && transportItem.transportVehicleText.length>0">, {{ transportItem.transportVehicleText}}</span>
          </p>
          <p v-if="transportItem.formType === 1">
            Tam i z powrotem:
            {{ transportItem.isTransportReturn === true ? "Tak" : "Nie" }}
          </p>
          <p v-if="transportItem.formType !== 1">
            Stopień niepełnosprawności:
            {{
              getOrderArrayValue(
                orderLevelOfDisability,
                transportItem.levelOfDisability
              )
            }}
          </p>
          <p>
            Pozycja:
            {{
              getOrderArrayValue(
                orderTransportPositions,
                transportItem.transportPosition
              )
            }}
          </p>
          <p>Informacje dodatkowe: {{ transportItem.comment }}</p>
        </div>
      </div>
    </div>

    <!---------------------------------->
    <!--********** TEST DIAGNOSTYCZNY **********-->
    <!---------------------------------->
    <div v-if="order.orderType === 6">
      <div v-if="order.diagTests">
        <div v-for="(item, index) in order.diagTests" :key="index">
          <p>
            {{ getOrderArrayValue(orderTypes[6].items, item.test) }}
          </p>
        </div>
      </div>
    </div>
    <!---------------------------------->
    <!--********** EKG **********-->
    <!---------------------------------->
    <div v-if="order.orderType === 7">
      <div v-if="order.ekg">
        <div v-for="(item, index) in order.ekg" :key="index">
          <p>
            {{ item.description }}
          </p>
          <p v-if="item.comment">
            Uwagi: {{ item.comment ? item.comment : "-" }}
          </p>
        </div>
      </div>
    </div>
    <!---------------------------------->
    <!--********** ALLERGY **********-->
    <!---------------------------------->
    <div v-if="order.orderType === 8">
      <div v-if="order.measurements">
        <div v-for="(item, index) in order.measurements" :key="index">
          <p v-for="(itemAllergy, indexAllery) in item.allergies" :key="indexAllery">
            {{ itemAllergy.name }}
          </p>
          <p v-if="item.description">
            Uwagi: {{ item.description ? item.description : "-" }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="order.orderType === 9">
      <div v-if="order.measurements">
        <div v-for="(item, index) in order.measurements" :key="index">
          <p v-if="item.description">
            Uwagi: {{ item.description ? item.description : "-" }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="order.orderType === 10">
      <div v-if="order.measurements">
        <div v-for="(item, index) in order.measurements" :key="index">
          <p v-if="item.types && item.types.includes(2450)" style="font-size: 0.75rem; margin: 0; padding: 0">
            Holter RR
          </p>
          <p v-if="item.types && item.types.includes(2451)" style="font-size: 0.75rem; margin: 0; padding: 0">
            Holter EKG
          </p>
          <p v-if="item.description">
            Uwagi: {{ item.description ? item.description : "-" }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="order.orderType === 11">
      <div v-if="order.procedures">
        <div v-for="(item, index) in order.procedures" :key="index">
          <p>
            Nazwa zabiegu: {{ item.description ? item.description : "-" }}
          </p>
          <p>
            Uwagi: {{ item.comment ? item.comment : "-" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonHelpers from "@/mixins/commons";
import DateHelpers from "@/mixins/date_helpers";
import OrdersMixins from "@/mixins/orders/poz_orders";
import InsuranceMixins from "@/mixins/insurance/insurance_enums";
import ChangeEZWMPassword from "@/components/my-account/MyAccountChangeEZWMPassword"

export default {
  name: "OrdersDetails",
  mixins: [CommonHelpers, DateHelpers, OrdersMixins, InsuranceMixins],
  components: {
    ChangeEZWMPassword
  },

  props: {
    order: { type: Object, required: false },
    showButton: {type: Boolean, required: false, default: false}
  },

  data: function () {
    return {};
  },

  mounted() {},

  watch: {},

  computed: {},

  methods: {
    getStatus(stat) {
      switch (stat) {
        case 0:
          return "Brak informacji";
        case 1:
          return "Kolejka";
        case 2:
          return "Przetwarzanie";
        case 3:
          return "Ukończono";
        case 4:
          return "Błąd";
        case 5:
          return "Utworzono zawartość";
        case 6:
          return "Podpisano zawartość";
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>