<template>
  <div>
    <b-dropdown aria-role="list" position="is-bottom-left">
      <a
        style="width: 20rem"
        aria-role="listitem"
        class="dropdown-item"
        @click="handleP12"
        slot="trigger"
      >
        <b-icon icon="login" size="is-small" class="menu-larger-icon"></b-icon
        >Logowanie P12
      </a>
    </b-dropdown>
  </div>
</template>

<script>
import { Mutation } from '@/store/config_mutations'

export default {
  name: 'NavP12',
  data() {
    return {}
  },

  mounted() { },

  methods: {
    handleP12() {
      this.$store.commit(Mutation.P12_CODE_REQUEST, false)
      this.$nextTick(() => {
        this.$store.commit(Mutation.P12_CODE_REQUEST, true)
      })
    }
  }
}

</script>

<style scoped lang="scss">
</style>