<template>
  <div>
    <b-tooltip label="Historia wysłanych SMS" type="is-primary">
      <b-button size="is-small" icon-left="phone-log" type="is-primary" @click="showModal"></b-button>
    </b-tooltip>

    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <ContentPanel icon="phone-log" title="Historia wysłanych SMS" style="overflow: visible; width: 64rem">
        <template slot="addon">
          <b-button @click="isComponentModalActive = false" size="is-small" icon-left="close">Zamknij</b-button>
        </template>
        <div class="modal-card" style="overflow: visible; width: 100% !important; max-height: 84vh; min-height: 80vh">
          <section class="modal-card-body" style="width: 100% !important">
            <b-table bordered :data="smsHistory" :default-sort="['created', 'desc']" :loading="loading">
              <b-table-column width="20%" label="Data" sortable searchable centered field="created">
                <template #searchable="props">
                  <b-input
                    expanded
                    v-model="props.filters[props.column.field]"
                    placeholder="Szukaj..."
                    icon="magnify"
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ mDateTime(props.row.created) }}</template>
              </b-table-column>
              <b-table-column width="20%" label="Pracownik" sortable searchable centered field="employeeName">
                <template #searchable="props">
                  <b-input
                    expanded
                    v-model="props.filters[props.column.field]"
                    placeholder="Szukaj..."
                    icon="magnify"
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.employeeName }}</template>
              </b-table-column>
              <b-table-column width="15%" label="Numer telefonu" searchable centered field="phone">
                <template #searchable="props">
                  <b-input
                    expanded
                    v-model="props.filters[props.column.field]"
                    placeholder="Szukaj..."
                    icon="magnify"
                    size="is-small"
                  />
                </template>
                <template v-slot="props">{{ props.row.phone }}</template>
              </b-table-column>
              <b-table-column width="45%" label="Wiadomość" searchable field="content">
                <template #searchable="props">
                  <b-input
                    expanded
                    v-model="props.filters[props.column.field]"
                    placeholder="Szukaj..."
                    icon="magnify"
                    size="is-small"
                  />
                </template>
                <template v-slot="props">
                  <span style="word-break: break-word; white-space: pre-wrap">
                    <i>{{ props.row.content }}</i>
                  </span>
                </template>
              </b-table-column>
            </b-table>
          </section>
        </div>
      </ContentPanel>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from "../../mixins/commons"
import { Action } from "../../store/config_actions"

export default {
  name: "SmsPatientHistory",

  mixins: [CommonMixins],

  components: {},

  data: function () {
    return {
      isComponentModalActive: false,

      smsHistory: [],

      loading: false,
    }
  },

  watch: {},

  mounted() {},

  props: {
    patient: { type: Object, required: false },
  },

  computed: {
    perms() {
      return this.$store.state.employee.permits
    },

    /** My account data */
    me() {
      return this.$store.state.employee.me
    },
  },

  methods: {
    getPatientHistory() {
      const payload = {
        domain: this.patient.domainId,
        patientId: this.patient.id,
      }
    
      this.loading = true

      this.$store
        .dispatch(Action.SMS_ARCHIVE_PATIENT, payload)
        .then((response) => {
          response.forEach((smsData) => {
            smsData.employeeName = this.mGetEmployee(smsData.createdBy)
          })
          this.smsHistory = response
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.apiProblem(error)
        })
    },

    showModal() {
      this.isComponentModalActive = true
      this.getPatientHistory()
    },
  },
}
</script>

<style scoped lang="scss"></style>
