import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        orders:[],
        cancelOrders:[],
        isInitialSet: false,
        medicalDevices: [],
        //criterias: []
    },

    mutations: {
        [Mutation.EZWM_ORDER_ADD](currentState, payload){
            currentState.orders.push(payload);
        },

        [Mutation.EZWM_ORDER_UPDATE](currentState, payload){
            currentState.orders[payload.index] = payload.order
        },

        [Mutation.EZWM_ORDER_REMOVE](currentState, payload) {
            let index = currentState.orders.indexOf(payload)

            if (index > -1) {
                currentState.orders.splice(index, 1)
            }
        },

        [Mutation.EZWM_CANCEL_ORDER_ADD](currentState,payload){
            currentState.cancelOrders.push(payload)
        },

        [Mutation.EZWM_RESET](currentState){
            currentState.orders =[],
            currentState.cancelOrders = []
        },

        [Mutation.EZWM_SET_INITIAL_DATA](currentState, payload){
            currentState.isInitialSet = true
            currentState.medicalDevices = payload.medicalDevices
            //currentState.criterias = payload.criterias
        }
    },

    actions: {
        [Action.EZWM_GET](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_GET,
                        headers: { Authorization: "Bearer " + token },
                        data: payload,
                        params:{
                            id:payload.id
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_SEND](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_SEND,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_DOWNLOAD_RESULT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.EZWM_DOWNLOAD_RESULT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_DOWNLOAD_ORDER_PDF](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.EZWM_DOWNLOAD_ORDER_PDF.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_CHECK_PASSWORD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_CHECK_PASSWORD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_WORKERCREDS_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_WORKERCREDS_CREATE,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_WORKERCREDS_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_WORKERCREDS_UPDATE,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_WORKERCREDS_GET](context, workerGuid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_WORKERCREDS_GET,
                        headers: { Authorization: "Bearer " + token },
                        params:{
                            workerGuid:workerGuid
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //EZWM_WORKERCREDS_ADD_CONFIGURATION
        [Action.EZWM_WORKERCREDS_ADD_CONFIGURATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_WORKERCREDS_ADD_CONFIGURATION,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_WORKERCREDS_UPDATE_CONFIGURATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_WORKERCREDS_UPDATE_CONFIGURATION,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_WORKERCREDS_SETACTIVE_CONFIGURATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_WORKERCREDS_SETACTIVE_CONFIGURATION,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_WORKERCREDS_GET_BY_WORKER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EZWM_WORKERCREDS_GET_BY_WORKER.replace('{workerId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_WORKERCREDS_UPDATE_PASSWORD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_WORKERCREDS_UPDATE_PASSWORD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_WORKERCREDS_ADD_CERT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EZWM_WORKERCREDS_ADD_CERT,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EZWM_GET_INITIAL_DATA](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EZWM_GET_INITIAL_DATA,
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        context.commit(Mutation.EZWM_SET_INITIAL_DATA, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}