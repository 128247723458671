import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'

export default {
    namespaced: false,

    state: {},

    getters: {},

    mutations: {},

    actions: {
    
        /** Attempt to add custom refferal. Uses POST request and requires custom refferal data object (check config_apis.js) */
        [Action.PATIENT_CUSTOM_REFFERAL_CREATE](context, customRefferal) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_CUSTOM_REFFERAL_GET_ALL,
                        data: customRefferal,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all custom refferal. Uses GET request without parameter */
        [Action.PATIENT_CUSTOM_REFFERAL_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_CUSTOM_REFFERAL_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get custom refferal. Uses GET request */
        [Action.PATIENT_CUSTOM_REFFERAL_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_CUSTOM_REFFERAL_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get custom refferal by patient id. Uses GET request */
        [Action.PATIENT_CUSTOM_REFFERAL_GET_BY_PATIENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_CUSTOM_REFFERAL_GET_BY_PATIENT_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt custom refferal delete. Uses DELETE request */
        [Action.PATIENT_CUSTOM_REFFERAL_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_CUSTOM_REFFERAL_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt custom refferal edit. Uses PUT request with id and requires custom refferal data object (check config_apis.js)*/
        [Action.PATIENT_CUSTOM_REFFERAL_UPDATE](context, customRefferal) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_CUSTOM_REFFERAL_GET.replace('{id}', customRefferal.id),
                        data: customRefferal,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to set custom refferal prop 'isRemoved' to true. Uses POST request */
        [Action.PATIENT_CUSTOM_REFERRAL_REMOVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_CUSTOM_REFERRAL_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to set custom refferal prop 'isRemoved' to false. Uses POST request */
        [Action.PATIENT_CUSTOM_REFERRAL_RESTORE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_CUSTOM_REFERRAL_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    
    }
}