<template>
  <div class="in-line">
    <b-tooltip
      type="is-dark"
      label="Klawiatura ekranowa"
      class="hand"
      :delay="500"
      position="is-bottom"
    >
      <b-button type="is-primelight"
        outlined
        size="is-small" @click="show = !show" @mousedown="mMoseDown" icon-left="keyboard-variant">
        <!-- <b-icon type="is-success" icon="keyboard-variant"></b-icon> -->
      </b-button>
    </b-tooltip>
    <div
      id="szok-keyboard-on-top"
      class="on-top"
      :style="show ? 'display:block' : 'display:none'"
      style="z-index: 999"
    >
      <div id="szok-keyboard-on-topheader" class="header-on-top" >
        Przytrzymaj i przesuń
        <b-button outlined @click="show = !show" size="is-small" class="button-visible is-pulled-right m-0" icon-left="close"></b-button>
      </div>
      <div class="pl-2 pb-2 pr-2">
        <b-field class="pb-0 mb-0 pt-1 pl-1 pr-1">
          <p class="control">
            <span class="button is-static is-small is-dark"
              >Typ klawiatury:</span
            >
          </p>
          <!-- <b-radio-button
            size="is-small"
            v-model="radioButton"
            native-value="Digit"
            name="keyboard-layout"
          >
            <span>Znaki</span>
          </b-radio-button> -->
          <b-radio-button
            size="is-small"
            v-model="radioButton"
            native-value="PL"
            name="keyboard-layout"
          >
            <span>PL</span>
          </b-radio-button>
          <b-radio-button
            size="is-small"
            v-model="radioButton"
            native-value="UKR"
            name="keyboard-layout"
          >
            <span>UKR</span>
          </b-radio-button>
          <b-radio-button
            size="is-small"
            v-model="radioButton"
            native-value="GR"
            name="keyboard-layout"
          >
            <span>GR</span>
          </b-radio-button>
          <b-radio-button
            size="is-small"
            v-model="radioButton"
            native-value="RU"
            name="keyboard-layout"
          >
            <span>RU</span>
          </b-radio-button> 
          <b-radio-button
            size="is-small"
            v-model="radioButton"
            native-value="BLR"
            name="keyboard-layout"
          >
            <span>BLR</span>
          </b-radio-button>
        </b-field>
        <div :class="keyboardClass"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
//import layout from "simple-keyboard-layouts/build/layouts/japanese";
import layoutPL from "simple-keyboard-layouts/build/layouts/polish";
import layoutHebrew from "simple-keyboard-layouts/build/layouts/hebrew";
import layoutGreek from "simple-keyboard-layouts/build/layouts/greek";
import layoutRussian from "simple-keyboard-layouts/build/layouts/russian";
import layoutBelarius from "simple-keyboard-layouts/build/layouts/belarusian";
import layoutUkrainian from "simple-keyboard-layouts/build/layouts/ukrainian";

const myLayout = {
  default: [
    "\u00B0 \u00A7 \u00DF \u00B4 \u02c7",
    "\u00FC \u00F6 \u00E4",
    "{shift} \u011B \u0161 \u010D \u0159 \u017E \u00FD \u00E1 \u00ED \u00E9 \u00B4 {shift}",
    ".com @ {space}",
  ],
  shift: [
    '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
    "\u00DC \u00D6 \u00C4",
    "{shift} > Y X C V B N M ; : _ {shift}",
    ".com @ {space}",
  ],
};

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
  },
  data: () => ({
    keyboard: null,
    layoutPL: layoutPL,
    layoutHebrew: layoutHebrew,
    layoutGreek: layoutGreek,
    layoutRussian: layoutRussian,
    layoutBelarius: layoutBelarius,
    layoutUkrainian: layoutUkrainian,
    radioButton: "PL",
    currentInput: null,
    show: false,
    pos1: 0,
    pos2: 0,
    pos3: 0,
    pos4: 0,
    element: null,
  }),
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      preventMouseDownDefault: false,
      onKeyReleased: this.setMyFocus
    });
    this.element = document.getElementById("szok-keyboard-on-top");
    this.dragElement();
  },

  methods: {
    onChange(input) {
      if (this.currentInput) {
        this.currentInput.value = input;
        this.currentInput.dispatchEvent(new Event("input"));
      } else {
        this.snackbar(`Kursor nie jest ustawiony w żadnym polu`)
      }
    },

    setMyFocus(){
      if(this.currentInput){
        this.currentInput.focus();
      }
    },

    onKeyPress(button) {
      this.$emit("onKeyPress", button);
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },

    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },

    changeLayout(country) {
      let layout = layoutPL;

      switch (country) {
        case "RU":
          layout = layoutRussian;
          break;
        case "GR":
          layout = layoutGreek;
          break;
        case "UKR":
          layout = layoutUkrainian;
          break;
        case "BLR":
          layout = layoutBelarius;
          break;
        case "HEB":
          layout = layoutHebrew;
          break;
        default:
          break;
      }

      this.keyboard.setOptions({
        ...layout,
      });
    },

    changeLayoutDigit() {
      this.keyboard.setOptions({
        layout: myLayout,
      });
    },

    focusChanged(event) {
      const el = event.target;
      if (el.tagName == "INPUT" && el.type != "radio") {
        
        if (event.target != this.currentInput && this.currentInput != null) {
          //this.currentInput.style.background = "";
          this.currentInput.removeEventListener(
            "input",
            this.updateValue,
            false
          );
        }
        if (event.target != this.currentInput) {
          //event.target.style.background = "pink";
          this.currentInput = null;
          this.currentInput = event.target;
          this.currentInput.addEventListener("input", this.updateValue, false);
          this.keyboard.setInput(event.target.value);
        }
      } else if(el.name=="keyboard-layout"){
        this.setMyFocus();
        //do nothing it's not input, current it's the same input
      }
      else {        
        this.currentInput = null
      }
    },

    updateValue(e) {
      if (e) {
        this.keyboard.setInput(e.target.value);  
      }
    },

    // mOver() {
    //   if (document.activeElement != null) {
    //     const el = document.activeElement;
    //     if (el.tagName == "INPUT" && el.type != "radio") {
    //       this.currentInput = document.activeElement;
    //     }
    //   }
    // },

    mMoseDown(){
      //const el = event.target;
      if (document.activeElement != null ) {
        const el = document.activeElement;
        if (el.tagName == "INPUT" && el.type != "radio") {
          this.currentInput = document.activeElement;
        }
      }
    },

    dragElement() {
      (this.pos1 = 0), (this.pos2 = 0), (this.pos3 = 0), (this.pos4 = 0);
      if (document.getElementById(this.element.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(this.element.id + "header").onmousedown =
          this.dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        this.element.onmousedown = this.dragMouseDown;
      }
    },

    dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      document.onmouseup = this.closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = this.elementDrag;
    },

    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      this.pos1 = this.pos3 - e.clientX;
      this.pos2 = this.pos4 - e.clientY;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      // set the element's new position:
      this.element.style.top = this.element.offsetTop - this.pos2 + "px";
      this.element.style.left = this.element.offsetLeft - this.pos1 + "px";
    },

    closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
  watch: {
    radioButton(val) {
      if (val != "Digit") {
        this.changeLayout(val);
      } else {
        this.changeLayoutDigit();
      }
    },

    show(val) {
      if (val) {
        document.addEventListener("focusin", this.focusChanged, false);
        if (this.currentInput != null) {
          this.keyboard.setInput(this.currentInput.value);
        }
      } else {
        document.removeEventListener("focusin", this.focusChanged, false);
        if (this.currentInput != null) {
          //this.currentInput.style.background = "";
          this.currentInput = null;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.on-top {
  position: absolute;
  z-index: 50;
  white-space: nowrap;
  /* overflow: visible !important; */
  top: 2rem;
  left: 0rem;
  background-color: #ececec;
}
.header-on-top {
  padding: 10px;
  cursor: move;
  background-color: #2196f3;
  color: #fff;
}

.button-visible{
  z-index:50 !important;
}
</style>
