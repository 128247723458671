import moment from "moment"

export default {
  data: () => {
    return {}
  },

  methods: {
    mGetPatientAge(patient) {
      if (patient) {
        if (patient.pesel != "" && patient.pesel != null && !patient.hasMothersPesel) {
          let arr = []
          let d = null
          for (let i = 0; i < 6; i++) {
            arr[i] = patient.pesel.charAt(i)
          }
          if (arr[2] < 2) {
            d = "19" + arr[0] + arr[1] + "-" + arr[2] + arr[3] + "-" + arr[4] + arr[5]
          } else {
            d = "20" + arr[0] + arr[1] + "-" + (arr[2] - 2) + arr[3] + "-" + arr[4] + arr[5]
          }
          return moment().diff(d, "years")
        } else {
          if (patient.age) return patient.age
          else {
            return moment().diff(moment(patient.dateOfBirth), "years")
          }
        }
      }
      return null
    },

    mGetPatientBirthdate(patient) {
      if (patient) {
        if (patient.pesel != "" && patient.pesel != null && !patient.hasMothersPesel) {
          return this.mGetBirthDateFromPesel(patient.pesel)
        } else {
          return moment(patient.dateOfBirth).format("YYYY-MM-DD")
        }
      }
      return null
    },

    mGetBirthDateFromPesel(pesel) {
      let d = null
      if (pesel) {
        if (pesel != "") {
          let arr = []
          for (let i = 0; i < 6; i++) {
            arr[i] = pesel.charAt(i)
          }
          if (arr[2] < 2) {
            d = "19" + arr[0] + arr[1] + "-" + arr[2] + arr[3] + "-" + arr[4] + arr[5]
          } else {
            d = "20" + arr[0] + arr[1] + "-" + (arr[2] - 2) + arr[3] + "-" + arr[4] + arr[5]
          }
        }
      }
      return d
    },
  },
}
