import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'
import { SERVICE_TALK } from '@/store/config_apis'
const signalR = require("@microsoft/signalr");

export default {
    namespaced: false,

    state: {
        /**
         * Current connection.
         */
        connection: null,

        /**
         * Connecting indicator.
         */
        connecting: false,

        /**
         * User groups.
         */
        groups: [],
    },

    mutations: {
        [Mutation.TALK_NULLIFY_CONNECTION](state) {
            state.connection = null
        },

        [Mutation.TALK_SET_CONNECTION](state, payload) {
            state.connection = payload

            state.connection.on("OnGroupMessage", function (message) {
                var parsed = JSON.parse(message)
                var group = state.groups.find(x => x.id == parsed.groupId)

                if (group) {
                    group.items.splice(0, 0, parsed)
                }
            });

            state.connection.on("OnReadMarks", function (message) {
                var parsed = JSON.parse(message)
                var group = state.groups.find(x => x.id == parsed.groupId)

                if (group) {
                    parsed.items.forEach(element => {
                        let message = group.items.find(x => x.id == element.id)

                        if (message) {
                            if (message.readByEmployees == null) message.readByEmployees = []
                            element.readByEmployees.forEach(read => {
                                if (message.readByEmployees.find(x => x.id == read.id)) {
                                    // skip
                                }
                                else {
                                    message.readByEmployees.push(read)
                                }
                            });

                            if (message.readByPatients == null) message.readByPatients = []
                            element.readByPatients.forEach(read => {
                                if (message.readByPatients.find(x => x.id == read.id)) {
                                    // skip
                                }
                                else {
                                    message.readByPatients.push(read)
                                }
                            });
                        }
                    });
                }
            });
        },

        [Mutation.TALK_SET_CONNECTING_INDICATOR](state, payload) {
            state.connecting = payload
        },

        [Mutation.TALK_SET_GROUPS](state, payload) {
            payload.forEach(element => {
                if (element.items == null) {
                    element.items = []
                }
            });

            state.groups = payload
        },
    },

    actions: {
        [Action.TALK_CREATE_CHAT_CONNECTION](context) {

            context.commit(Mutation.TALK_SET_CONNECTING_INDICATOR, true)
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;

            return new Promise((resolve, reject) => {
                if (context.state.connection == null) {

                    // setup SignalR
                    let connection = new signalR.HubConnectionBuilder()
                        .withUrl(`${SERVICE_TALK}/hubs/global`, { accessTokenFactory: () => token })
                        .configureLogging(signalR.LogLevel.Information)
                        .build();

                    context.commit(Mutation.TALK_SET_CONNECTION, connection)

                    // start connection
                    connection.start()
                        .then(() => {
                            context.commit(Mutation.TALK_SET_CONNECTING_INDICATOR, false)
                            context.dispatch(Action.TALK_GET_MY_GROUPS)
                            resolve(context.state.connection)
                        })
                        .catch(function (error) {
                            setTimeout(function () {
                                context.dispatch(Action.TALK_CREATE_CHAT_CONNECTION)
                            }, 4000);
                            reject(error)
                        });
                }
                else {
                    if (context.state.connection.state == 'Connected') {
                        context.dispatch(Action.TALK_GET_MY_GROUPS)
                        resolve(context.state.connection)
                    }
                    else {
                        context.state.connection.start()
                            .then(() => {
                                context.commit(Mutation.TALK_SET_CONNECTING_INDICATOR, false)
                                context.dispatch(Action.TALK_GET_MY_GROUPS)
                                resolve(context.state.connection)
                            })
                            .catch(function (error) {
                                context.commit(Mutation.TALK_NULLIFY_CONNECTION)

                                setTimeout(function () {
                                    context.dispatch(Action.TALK_CREATE_CHAT_CONNECTION)
                                }, 4000);
                                reject(error)
                            });
                    }
                }
            })
        },

        [Action.TALK_GET_MY_GROUPS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TALK_GET_MY_GROUPS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.TALK_SET_GROUPS, response.data)
                        let val = context.state.groups

                        if (context.state.connection && context.state.connection.state == 'Connected' && val) {
                            var ids = []
                            val.forEach(element => { ids.push(element.id) });

                            context.state.connection.invoke("JoinManyRooms", JSON.stringify(ids))
                                .then(() => {
                                    resolve(response.data)
                                })
                                .catch(function (error) {
                                    reject(error)
                                });
                        }
                        else {
                            reject("NOT CONNECTED")
                        }
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.TALK_CRETE_GROUP](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TALK_CRETE_GROUP,
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.TALK_ADD_EMPLOYEE_TO_GROUP](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TALK_ADD_EMPLOYEE_TO_GROUP.replace('{gid}', data.gid).replace('{eid}', data.eid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.TALK_REMOVE_EMPLOYEE_FROM_GROUP](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TALK_REMOVE_EMPLOYEE_FROM_GROUP.replace('{gid}', data.gid).replace('{eid}', data.eid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.TALK_GET_MESSAGES](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TALK_GET_MESSAGES.replace('{group}', data.group).replace('{count}', data.count),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.TALK_SEND_MESSAGE](context, data) {
            return new Promise((resolve, reject) => {
                context.state.connection.invoke("GroupMessage", JSON.stringify(data))
                    .then(() => {
                        resolve()
                    })
                    .catch(function (error) {
                        reject(error)
                    });
            })
        },

        [Action.TALK_MARK_READ](context, data) {
            return new Promise((resolve, reject) => {
                context.state.connection.invoke("MarkRead", JSON.stringify(data))
                    .then(() => {
                        resolve()
                    })
                    .catch(function (error) {
                        reject(error)
                    });
            })
        },
    }
}