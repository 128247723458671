import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations';

export default {
    namespaced: false,

    state: {
        triggerUpdate:0,
        types: [],
    },

    getters: {},

    mutations: {

        //EMPLOYEE_TRIGGER_UPDATE
        [Mutation.EMPLOYEE_TRIGGER_UPDATE](currentState){
            currentState.triggerUpdate++;
        },

        [Mutation.EMPLOYEE_PERMISSION_TYPE_SET_ALL](state, payload){
            state.types = payload;
        },

        [Mutation.EMPLOYEE_PERMISSION_TYPE_ADD](state, payload) {
            state.types.push(payload)
        },

        [Mutation.EMPLOYEE_PERMISSION_TYPE_REMOVE](state, payload) {
            let index = state.types.findIndex(u => u.id === payload)

            if (index > -1) {
                state.types.splice(index, 1)
            }
        },

        [Mutation.EMPLOYEE_PERMISSION_TYPE_EDIT](state, payload) {
            let permissionType = state.types.find(u => u.id === payload.id)

            if (permissionType) {
                permissionType = payload
            }
        },

    },

    actions: {

        /** Attempt to add worker. Uses POST request and requires worker data object (check config_apis.js) */
        [Action.EMPLOYEE_PERMISSION_ADD](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PERMISSION_GET_ALL,
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all workers. Uses GET request without parameter */
        [Action.EMPLOYEE_PERMISSION_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PERMISSION_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.EMPLOYEE_PERMISSION_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PERMISSION_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.EMPLOYEE_PERMISSION_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_PERMISSION_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.EMPLOYEE_PERMISSION_PUT](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_PERMISSION_GET.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add worker. Uses POST request and requires worker data object (check config_apis.js) */
        [Action.EMPLOYEE_PERMISSION_TYPE_ADD](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_PERMISSION_TYPE_GET_ALL,
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.EMPLOYEE_PERMISSION_TYPE_ADD, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all workers. Uses GET request without parameter */
        [Action.EMPLOYEE_PERMISSION_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PERMISSION_TYPE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_PERMISSION_TYPE_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.EMPLOYEE_PERMISSION_TYPE_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_PERMISSION_TYPE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.EMPLOYEE_PERMISSION_TYPE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_PERMISSION_TYPE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_PERMISSION_TYPE_REMOVE, id)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.EMPLOYEE_PERMISSION_TYPE_PUT](context, worker) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_PERMISSION_TYPE_GET.replace('{id}', worker.id),
                        data: worker,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EMPLOYEE_PERMISSION_TYPE_EDIT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}