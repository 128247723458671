<template>
  <div>
    <b-modal
      :active.sync="isActiveModal"
      has-modal-card
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      :can-cancel="false"
      scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head has-background-kin">
          <span class="modal-card-title has-text-light">Wyślij badanie do urządzenia</span>
        </header>
        <section class="modal-card-body">

          <b-message
            type="is-danger"
            animation="false"
            class="mb-3 has-text-weight-semibold"
            :closable="false"
            icon="file-outline"
            v-if="disabledNoReferral"
            has-icon
          >
            Brak skanu skierowania - musisz dodać skan skierowania przed wysłaniem lub zaznaczyć opcję
            "Skierowanie zarejestrowano w innym systemie świadczeniodawcy"
          </b-message>

          <table class="table is-narrow is-bordered w-100 mb-3">
            <tr>
              <th style="width: 9rem; vetical-align: middle">Pracownik wykonujący</th>
              <th class="p-2">
                <b-field v-if="!examEmployeeId">
                  <b-tag type="is-warning">
                    Wybierz
                    <b-icon icon="arrow-right" size="is-small"></b-icon>
                  </b-tag>
                </b-field>

                <b-autocomplete
                  :data="filteredEmployees"
                  v-model="employeeName"
                  open-on-focus
                  field="name"
                  @select="selectWorker"
                  clearable
                  :keep-first="true">
                  <template slot-scope="props">
                    <span> {{ props.option.name }} (PWZ:{{ props.option.pwz }}) </span>
                  </template>
                </b-autocomplete>
              </th>
            </tr>
          </table>

          <table class="table is-narrow is-bordered w-100 mb-3">
            <tr>
              <th style="width: 9rem; vetical-align: middle">Nazwa dla Packs</th>
              <th class="p-2">
                <b-field v-if="!selectedPacksProcedure">
                  <b-tag type="is-warning">
                    Wybierz
                    <b-icon icon="arrow-right" size="is-small"></b-icon>
                  </b-tag>
                </b-field>
                <b-radio
                  v-for="(item, index) in ticket.procedures"
                  :key="index"
                  :native-value="item"
                  v-model="selectedPacksProcedure"
                  class="mb-1"
                  type="is-secondary"
                  :class="{ 'has-text-secondary': selectedPacksProcedure == item }"
                  >{{ item.icd9 }} {{ item.description }}</b-radio
                >
                <b-message type="is-danger" class="mb-1" v-if="ticket.procedures == 0">Brak dodanych badań</b-message>
              </th>
            </tr>
          </table>

          <b-field class="mb-1">
            <b-checkbox :true-value="false" :false-value="true" v-model="isCdRequired"
              >Zdjęcie nie wymaga nagrania na płycie CD</b-checkbox
            >
          </b-field>
          <b-field class="mb-2" v-if="ticket && ticket.referral && ticket.referral.referredBy !== -1">
            <b-checkbox v-model="descriptionByReferrer">
              Opis zostanie wykonany przez lekarza kierującego na badanie:
              <br />
              {{ getWorkerNamePWZ(ticket.referral.referredBy) }}
            </b-checkbox>
          </b-field>
          <b-field
            v-if="
              ticket && ticket.referral && ticket.referral.isPaperReferral && ticket.referral.idReferralFile == null
            ">
            <b-checkbox
              v-model="hasReferralInOtherSystem"
              type="is-orange"
              :class="{ 'has-text-weight-semibold': hasReferralInOtherSystem }"
              >Skierowanie zarejestrowano w innym systemie świadczeniodawcy</b-checkbox
            >
          </b-field>
          <b-field>
            <b-checkbox
              v-model="multiProcedure"
              type="is-orange"
              :class="{ 'has-text-weight-semibold': multiProcedure }"
              >Procedura liczona podwójnie</b-checkbox
            >
          </b-field>
          <b-field class="mt-5" label="Notatka dla lekarza opisującego" label-position="on-border">
            <b-input type="textarea" v-model="examinatorComment"></b-input>
          </b-field>
          <div v-if="deviceObject">
            <b-field v-if="deviceObject.aeTitle == null" label="AETitle" label-position="on-border">
              <b-input v-model="tempAeTitle"></b-input>
            </b-field>
            <b-field
              v-if="
                (deviceObject && deviceObject.modalities == null) ||
                (deviceObject && deviceObject.modalities != null && deviceObject.modalities.length == 0)
              "
              label="Modalność"
              label-position="on-border">
              <b-field expanded label="Modalność" label-position="on-border">
                <b-select expanded v-model="tempModality">
                  <option v-for="(item, index) in mModality" :key="index" :value="item">{{ item }}</option>
                </b-select>
              </b-field>
            </b-field>
          </div>
          <div v-else>
            <b-notification type="is-danger">Błąd urządzenia - nie można wysłać zlecenia</b-notification>
          </div>
        </section>
        <footer class="modal-card-foot buttons is-right">
          <b-button :loading="requestInProgress" icon-left="close" @click="isActiveModal = false">Anuluj </b-button>
          <b-button
            type="is-primary"
            :loading="requestInProgress"
            :disabled="
              !deviceObject ||
              !examEmployee ||
              !examEmployeeId ||
              !selectedPacksProcedure ||
              (deviceObject &&
                deviceObject.modalities != null &&
                deviceObject.modalities.length == 0 &&
                tempModality == null) ||
              (deviceObject && deviceObject.aeTitle == null && tempAeTitle.length == 0) ||
              disabledNoReferral
            "
            @click="createRisAppointmentNew"
            icon-left="send">
            Potwierdź i wyślij
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Action } from "@/store/config_actions"
import CommonMixins from "@/mixins/commons"
import RisMixins from "@/mixins/ris"

export default {
  name: "RISWorkshopAddUsgDetails",
  mixins: [CommonMixins, RisMixins],

  components: {},

  props: {
    ticket: { type: Object, required: true },
    appointment: { type: Object, required: true },
    deviceId: { type: String, required: true },
    trigger: { type: Number, required: false },
  },

  data: function () {
    return {
      requestInProgress: false,
      isActiveModal: false,
      employeeName: "",
      deviceObject: null,
      selectedUmxService: null,

      /*
        USG REGISTRATION EXAMS SEND
      */
      examEmployee: null,
      examEmployeeId: null,
      selectedPacksProcedure: null,
      isCdRequired: false,
      descriptionByReferrer: false,
      hasReferralInOtherSystem: false,
      multiProcedure: false,
      examinatorComment: "",
      tempAeTitle: "",
      tempModality: "",

    }
  },

  watch: {
    trigger() {
      this.examEmployee = null
      this.examEmployeeId = null
      this.selectedPacksProcedure = null
      this.employeeName = ""

      this.isActiveModal = true
      if (this.appointment) {
        this.getDevice(this.appointment.device)
      }

      if (this.ticket && this.ticket.procedures) {
        if(this.ticket.procedures.length === 1) {
          this.selectedPacksProcedure = this.ticket.procedures[0]
        }
      }
    },
  },

  computed: {
    me() {
      return this.$store.state.employee.me;
    },

    disabledNoReferral() {
      if (
        this.ticket &&
        this.ticket.referral &&
        this.ticket.referral.isPaperReferral &&
        this.ticket.referral.idReferralFile == null &&
        this.examEmployee &&
        !this.examEmployee.roles.includes(167) &&
        this.hasReferralInOtherSystem === false
      ) {
        return true
      }

      return false
    },

    employees() {
      return this.$store.state.employee.all
    },

    filteredEmployees() {
      if (this.employees) {
        return this.employees.filter((option) => {
          if (option.name) {
            return option.name.toString().toLowerCase().indexOf(this.employeeName.toLowerCase()) >= 0
          } else return false
        })
      } else return []
    },

    allOffices() {
      return this.$store.state.offices.offices ?? []
    },

    appointmentOfficeObject() {
      if (this.appointment.officeId) {
        return this.allOffices.find((office) => office.id === this.appointment.officeId)
      }

      return null
    },

    ogrSubUnits() {
      return this.$store.state.orgUnit.subunits ?? []
    },

    appointmentSubUnit() {
      if (this.appointmentOfficeObject) {
        return this.ogrSubUnits.find((subunit) => subunit.id === this.appointmentOfficeObject.subUnitId)
      }

      return null
    },

    subjects() {
      return this.$store.state.finance.subjects ?? []
    },

    appointmentSubject() {
      if (this.appointmentSubUnit) {
        return this.subjects.find((subject) => subject.id === this.appointmentSubUnit.ownerId)
      }

      return null
    },

    appointmentOrgUnit() {
      if (this.appointmentSubUnit) {
        return this.subjects.find((subject) => subject.id === this.appointmentSubUnit.localOrganizationalUnitId)
      }

      return null
    },

    typesImage() {
      return this.$store.state.results.examImageType ?? []
    },

    referralsType() {
      return this.$store.state.config.referrals ?? []
    },
  },

  methods: {
    getDevice(id) {
      this.deviceObject = null

      this.$store
        .dispatch(Action.MEDICAL_DEVICE_GET, id)
        .then((x) => {
          this.deviceObject = x
        })
        .catch((error) => {
          this.deviceObject = null
          this.apiProblem(error)
        })
    },

    selectWorker(option) {
      if (option) {
        this.examEmployee = option
        this.examEmployeeId = option.id
      } else {
        this.examEmployee = null
        this.examEmployeeId = null
      }
    },

    getWorker(id) {
      if (this.employees && id) {
        let emp = this.employees.find((e) => e.id === id)

        if (emp) {
          return emp
        } else return null
      } else null
    },

    getWorkerNamePWZ(id) {
      if (this.employees && id) {
        let emp = this.employees.find((e) => e.id === id)

        if (emp) {
          return `${emp.lastName} ${emp.firstName}, PWZ: ${emp.pwz ? emp.pwz : "Brak danych"}`
        } else return ""
      } else ""
    },

    createRisAppointmentNew() {
      this.requestInProgress = true

      let referralType = null
      let procedure = null
      let procedureId = null
      let simpStage = 0

      if (this.ticket && this.ticket.referral && this.ticket.referral.type) {
        let found = this.referralsType.find((r) => r.id == this.ticket.referral.type)

        if (found) {
          referralType = found
        }
      }

      if (
        this.selectedPacksProcedure != null &&
        this.ticket &&
        this.ticket.procedures &&
        this.ticket.procedures.length > 0
      ) {
        let indexProc = this.ticket.procedures.indexOf(this.selectedPacksProcedure)
        procedure = this.selectedPacksProcedure
        procedureId = indexProc
      }

      if (procedure != null) {
        if (procedure.isSimp && referralType) {
          simpStage = referralType.simpStage
        } else {
          simpStage = 0
        }
      }

      const payload = {
        appointmentId: this.appointment.id,
        domainId: this.appointment.domainId,
        ticketId: this.ticket.id,
        procedureIndex: procedureId,
        examinatedBy: this.examEmployeeId,
        supervisorId: -1,
        assistants: this.assistants,
        examinatorComment: this.examinatorComment,
        tempAETitle: this.tempAeTitle,
        tempModality: this.tempModality,
        isCdRequired: this.isCdRequired,
        descriptionByReferrer: this.descriptionByReferrer,
        hasReferralInOtherSystem: this.hasReferralInOtherSystem,
        multiProcedure: this.multiProcedure,
        isSendToPacks: this.deviceObject.generatesPacksImage,
        simpStage: simpStage,
        createdBy: this.examEmployeeId
      }

      this.$store
        .dispatch(Action.RIS_APPOINTMENT_CREATE_V2, payload)
        .then(() => {
          let scheduleUpdate = {
            id: this.appointment.id,
            data: {
              workerId: this.examEmployeeId,
              status: 1,
              assistants: [],
              version: this.appointment.version
            },
          }

          this.$store
            .dispatch(Action.S2_POST_STATUS, scheduleUpdate)
            .then(() => {
              this.requestInProgress = false
              this.successSnackbar("Zlecenie wysłane")
              this.$emit("send-usg")
            })
            .catch((error) => {
              this.apiProblem(error)
              this.requestInProgress = false
            })
        })
        .catch((error) => {
          this.apiProblem(error)
          this.requestInProgress = false
        })
    },
  },
}
</script>

<style scoped lang="scss"></style>
