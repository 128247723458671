import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from "../config_mutations"
import axios from 'axios'

export default {
  namespaced: false,

  state: {
    cashRegisters: [],

    cashRegistersOperation: [],

    cashRegistersDocuments: [],
  },

  mutations: {
    // CASH REGISTERS

    [Mutation.CASH_REGISTERS_ADD](state, payload) {
      state.cashRegisters.push(payload)
    },

    [Mutation.CASH_REGISTERS_DELETE](state, payload) {
      let index = state.cashRegisters.findIndex((u) => u.id === payload)

      if (index > -1) {
        state.cashRegisters.splice(index, 1)
      }
    },

    [Mutation.CASH_REGISTERS_EDIT](state, payload) {
      let cashRegister = state.cashRegisters.find((u) => u.id === payload.id)

      if (cashRegister) {
        cashRegister = payload
      }
    },

    // CASH REGISTERS OPERATION

    [Mutation.CASH_REGISTERS_OPERATION_ADD](state, payload) {
      state.cashRegistersOperation.push(payload)
    },

    [Mutation.CASH_REGISTERS_OPERATION_DELETE](state, payload) {
      let index = state.cashRegistersOperation.findIndex(
        (u) => u.id === payload
      )

      if (index > -1) {
        state.cashRegistersOperation.splice(index, 1)
      }
    },

    [Mutation.CASH_REGISTERS_OPERATION_EDIT](state, payload) {
      let cashRegistersOperation = state.cashRegistersOperation.find(
        (u) => u.id === payload.id
      )

      if (cashRegistersOperation) {
        cashRegistersOperation = payload
      }
    },

    // CASH REGISTERS DOCUMENTS

    [Mutation.CASH_REGISTERS_DOCUMENTS_ADD](state, payload) {
      state.cashRegistersDocuments.push(payload)
    },

    [Mutation.CASH_REGISTERS_DOCUMENTS_DELETE](state, payload) {
      let index = state.cashRegistersDocuments.findIndex(
        (u) => u.id === payload
      )

      if (index > -1) {
        state.cashRegistersDocuments.splice(index, 1)
      }
    },

    [Mutation.CASH_REGISTERS_DOCUMENTS_EDIT](state, payload) {
      let cashRegistersDocuments = state.cashRegistersOperation.find(
        (u) => u.id === payload.id
      )

      if (cashRegistersDocuments) {
        cashRegistersDocuments = payload
      }
    },
  },

  actions: {
    /**
     *---------------------------------------------------------
     * ******************* CASH OPERATIONS *******************
     *---------------------------------------------------------
     */

    /**
     * Adds cash operation.
     */
    [Action.CASH_OPERATION_CREATE](context, cashOperation) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "post",
            url: Apis.CASH_OPERATION_GET_ALL,
            data: cashOperation,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    /** 
     * Get all cash operation objects
    */
    [Action.CASH_OPERATION_GET_ALL](context) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "get",
            url: Apis.CASH_OPERATION_GET_ALL,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    /** 
     * Single cash operation object get
    */
    [Action.CASH_OPERATION_GET](context, cashOperationId) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "get",
            url: Apis.CASH_OPERATION_GET.replace("{id}", cashOperationId),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    /**
     * Cash operation object delete
     */
    [Action.CASH_OPERATION_DELETE](context, cashOperationId) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "delete",
            url: Apis.CASH_OPERATION_GET.replace("{id}", cashOperationId),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    /**
     * Cash operation object update
     */
    [Action.CASH_OPERATION_UPDATE](context, cashOperation) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "put",
            url: Apis.CASH_OPERATION_GET.replace("{id}", cashOperation.id),
            data: cashOperation,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    /**
     *---------------------------------------------------------
     * ******************* FISCAL DOCUMENTS *******************
     *---------------------------------------------------------
     */
 
    /**
     * Adds fiscal document object.
     */
    [Action.FISCAL_DOCUMENTS_CREATE](context, fiscalDocument) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.FISCAL_DOCUMENTS_GET_ALL,
              data: fiscalDocument,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /** 
       * Get all fiscal documents objects
      */
      [Action.FISCAL_DOCUMENTS_GET_ALL](context) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.FISCAL_DOCUMENTS_GET_ALL,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /** 
       * Single fiscal documents object get
      */
      [Action.FISCAL_DOCUMENTS_GET](context, fiscalDocumentId) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.FISCAL_DOCUMENTS_GET.replace("{id}", fiscalDocumentId),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /**
       * Cash operation object delete
       */
      [Action.FISCAL_DOCUMENTS_DELETE](context, fiscalDocumentId) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "delete",
              url: Apis.FISCAL_DOCUMENTS_GET.replace("{id}", fiscalDocumentId),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /**
       * Fiscal documents object update
       */
      [Action.FISCAL_DOCUMENTS_UPDATE](context, fiscalDocument) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "put",
              url: Apis.FISCAL_DOCUMENTS_GET.replace("{id}", fiscalDocument.id),
              data: fiscalDocument,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
    /**
     *---------------------------------------------------------
     * ******************* PRODUCTS CART *******************
     *---------------------------------------------------------
     */
 
    /**
     * Adds products cart object.
     */
    [Action.PRODUCTS_CART_CREATE](context, productsCart) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.PRODUCTS_CART_GET_ALL,
              data: productsCart,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /** 
       * Get all products cart objects
      */
      [Action.PRODUCTS_CART_GET_ALL](context) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.PRODUCTS_CART_GET_ALL,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /** 
       * Single fiscal documents object get
      */
      [Action.PRODUCTS_CART_GET](context, productsCartId) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.PRODUCTS_CART_GET.replace("{id}", productsCartId),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /**
       * Products cart object delete
       */
      [Action.PRODUCTS_CART_DELETE](context, productsCartId) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "delete",
              url: Apis.PRODUCTS_CART_GET.replace("{id}", productsCartId),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /**
       * Products cart object update
       */
      [Action.PRODUCTS_CART_UPDATE](context, productsCart) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "put",
              url: Apis.PRODUCTS_CART_GET.replace("{id}", productsCart.id),
              data: productsCart,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },


    /**
     *---------------------------------------------------------
     * ******************* TRANSACTION PAYMENT *******************
     *---------------------------------------------------------
     */
 
    /**
     * Adds cash register transaction payment object.
     */
    [Action.TRANSACTION_PAYMENT_CREATE](context, transaction) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.TRANSACTION_PAYMENT_GET_ALL,
              data: transaction,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /** 
       * Get all cash register transaction payment objects
      */
      [Action.TRANSACTION_PAYMENT_GET_ALL](context) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.TRANSACTION_PAYMENT_GET_ALL,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /** 
       * Single cash register transaction payment object get
      */
      [Action.TRANSACTION_PAYMENT_GET](context, transactionId) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.TRANSACTION_PAYMENT_GET.replace("{id}", transactionId),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /**
       * Cash register transaction payment object delete
       */
      [Action.TRANSACTION_PAYMENT_DELETE](context, transactionId) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "delete",
              url: Apis.TRANSACTION_PAYMENT_GET.replace("{id}", transactionId),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  
      /**
       * Cash register transaction payment object update
       */
      [Action.TRANSACTION_PAYMENT_UPDATE](context, transaction) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "put",
              url: Apis.TRANSACTION_PAYMENT_GET.replace("{id}", transaction.id),
              data: transaction,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

    /**
     *---------------------------------------------------------
     * ******************* FISCAL PRINTER METHODS *******************
     *---------------------------------------------------------
     */

      [Action.FISCAL_PRINTER_RECEIPT](context, fiscalPrinterReceipt) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.FISCAL_PRINTER_RECEIPT,
              data: fiscalPrinterReceipt,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.FISCAL_PRINTER_INVOICE](context, fiscalPrinterInvoice) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.FISCAL_PRINTER_INVOICE,
              data: fiscalPrinterInvoice,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.FISCAL_PRINTER_REPORT](context, fiscalPrinterReport) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.FISCAL_PRINTER_REPORT,
              data: fiscalPrinterReport,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.FISCAL_PRINTER_HEALTHCHECK](context, fiscalPrinterHealthcheck) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.FISCAL_PRINTER_HEALTHCHECK,
              data: fiscalPrinterHealthcheck,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  },
}
