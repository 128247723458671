<template>
  <div>
    <!-- <b-button size="is-small" @click="isActiveModalUpload = true">Dane teleadresowe</b-button> -->

    <b-button
      @click="isActiveModal = true"
      icon-left="clipboard-flow-outline" size="is-small"
      type="is-secondary"
    >Modyfikuj skierowanie AP-KOLCE</b-button>

    <ValidationObserver ref="observerExternalSubjectApkolce" v-slot="{ invalid }">
    <b-modal
      v-model="isActiveModal"
      has-modal-card
      aria-role="dialog"
      aria-modal
      scroll="keep"
      :can-cancel="false"
      class="p-5"
      trap-focus
      :destroy-on-hide="false"
    >
      <template #default="props">
          <div class="modal-card" style="overflow: visible">
            <header class="modal-card-head has-background-kin">
              <p class="modal-card-title has-text-light">Modyfikuj skierowanie AP-KOLCE:</p>
            </header>
            <section class="modal-card-body" style="min-height: 350px">
                  <ContentPanel
                    title="APKolce - skierowanie"
                    icon="heart-half-full"
                  >
                          <b-field class="mt-3">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors, valid }"
                            >
                              <b-field
                                label="Nazwa podmiotu"
                                label-position="on-border"
                                :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }"
                                :message="t(errors[0])"
                              >
                                <b-input v-model="referralApkolce.subject"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field>
                            <ValidationProvider
                              rules="regon14|required"
                              v-slot="{ errors, valid }"
                            >
                              <b-field
                                label="REGON (14 cyfr)"
                                label-position="on-border"
                                :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }"
                                :message="t(errors[0])"
                              >
                                <b-input v-model="referralApkolce.regon"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field>
                            <ValidationProvider
                              rules="digitsPL:12|required"
                              v-slot="{ errors, valid }"
                            >
                              <b-field
                                label="Kod część I (12 cyfr)"
                                label-position="on-border"
                                :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }"
                                :message="t(errors[0])"
                              >
                                <b-input v-model="referralApkolce.code1st"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field>
                            <ValidationProvider
                              rules="digitsPL:3|required"
                              v-slot="{ errors, valid }"
                            >
                              <b-field
                                label="Kod część VII (3 cyfry)"
                                label-position="on-border"
                                :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }"
                                :message="t(errors[0])"
                              >
                                <b-input v-model="referralApkolce.code7th"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field>
                            <ValidationProvider
                              rules="digitsPL:4|required"
                              v-slot="{ errors, valid }"
                            >
                              <b-field
                                label="Kod część VIII (4 cyfry)"
                                label-position="on-border"
                                    :type="{
                                      'is-danger': errors[0],
                                      'is-normal': valid,
                                    }"
                                    :message="t(errors[0])"
                              >
                                <b-input v-model="referralApkolce.code8th"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field class="mt-3">
                            <ValidationProvider
                              rules="numPL|length:5,6"
                              v-slot="{ errors, valid }"
                            >
                              <b-field
                                label="Kod miejsca udzielania świadczeń (5-6 cyfr, nieobowiązkowe)"
                                label-position="on-border"
                                :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }"
                                :message="t(errors[0])"
                              >
                                  <b-input v-model="referralApkolce.musCode"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>

                          <b-field>
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors, valid }"
                            >
                            <b-field
                              label="Data wystawienia"
                              label-position="on-border"
                              :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }"
                              :message="t(errors[0])"
                            >
                              <b-input type="date"
                                v-model="referralApkolce.issueDate"
                                icon="calendar-today"
                              ></b-input>
                            </b-field>
                            </ValidationProvider>
                          </b-field>
                      </ContentPanel>
            </section>
            <footer class="modal-card-foot">
              <b-button icon-left="close" @click="props.close">Anuluj</b-button>
              <b-button
                :loading="isLoading"
                :disabled="invalid"
                @click="saveExam"
                type="is-primary"
              >Zapisz zmiany</b-button>
            </footer>
          </div>
      </template>
    </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import RISMixins from "@/mixins/ris";
import { Action } from "../../../../store/config_actions";
import { Mutation } from "../../../../store/config_mutations";
import moment from "moment"
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "RISPatientChangeAPKolce",
  mixins: [CommonHelpers, RISMixins],
  components: {
    ValidationProvider, ValidationObserver,
  },

  props: { ticket: { type: Object, require: true } },

  data: function () {
    return {
      isLoading: false,
      requestInProgress: false,
      isActiveModal: false,

      referralApkolce: {
        subject: "",
        type: 0,
        issueDate: null,
        regon: "",
        code1st: "",
        code7th: "",
        code8th: "",
        musCode: "",
      },
    };
  },

  mounted() {
  },

  watch: {
    isActiveModal() {
      if (this.isActiveModal == true) {
          if (this.ticket) {
            if (this.ticket && this.ticket.referral && this.ticket.referral.apkolce) {
                this.referralApkolce.subject = this.ticket.referral.apkolce.subject
                this.referralApkolce.type = this.ticket.referral.apkolce.type
                this.referralApkolce.issueDate = moment(this.ticket.referral.apkolce.issueDate).format('YYYY-MM-DD')
                this.referralApkolce.regon = this.ticket.referral.apkolce.regon
                this.referralApkolce.code1st = this.ticket.referral.apkolce.code1st ?? ""
                this.referralApkolce.code7th = this.ticket.referral.apkolce.code7th
                this.referralApkolce.code8th = this.ticket.referral.apkolce.code8th
                this.referralApkolce.musCode = this.ticket.referral.apkolce.musCode
            }
          }
        this.$store.commit(Mutation.RIS_SET_IS_EDITING_APP, true);
      } else {
        this.$store.commit(Mutation.RIS_SET_IS_EDITING_APP, false);
        this.resetData()
      }  
    }
  },

  computed: {
    me() {
      return this.$store.state.employee.me;
    },

  },

  methods: {

    saveExam() {
      this.ticket.referral.apkolce.subject = this.referralApkolce.subject
      this.ticket.referral.apkolce.type = this.referralApkolce.type
      this.ticket.referral.apkolce.issueDate = moment(this.referralApkolce.issueDate).toDate(),
      this.ticket.referral.apkolce.regon = this.referralApkolce.regon
      this.ticket.referral.apkolce.code1st = this.referralApkolce.code1st
      this.ticket.referral.apkolce.code7th = this.referralApkolce.code7th
      this.ticket.referral.apkolce.code8th = this.referralApkolce.code8th
      this.ticket.referral.apkolce.musCode = this.referralApkolce.musCode

      this.updateTicket(this.ticket);
    },

    updateTicket(ticket) {
      this.isActiveModal = false;
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.TICKET_PUT, ticket)
        .then((resp) => {
          this.requestInProgress = false;
          this.successSnackbar("Zmiany zapisane");
          this.$store.commit(Mutation.RIS_PUSH_TICKET_TO_STORE, resp);
          this.$emit('updated');
          this.$emit("add-IKZ");
        })
        .catch((error) => {
          this.requestInProgress = false;
          this.apiProblem(error);
        });
    },

    resetData() {
      this.referralApkolce = {
        subject: "",
        type: 0,
        issueDate: null,
        regon: "",
        code1st: "",
        code7th: "",
        code8th: "",
        musCode: "",
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
