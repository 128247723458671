import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        appointments: [],
        offices: [],
        areas: [],
        clinics: [],
        clusters: [],
        facilities: [],
        referrals: [],
        dictionaries: [],
        patientFiles: [],
        medEvents: [],
        currentFacility: null,
        link: null,
        linkActive: false,
        mySettings: false,

        printLoading: false
    },

    mutations: {
        [Mutation.CONFIG_SETUP_SCHEDULE_CONFIG](state, payload) {

            if (payload) {
                let parsed = payload // JSON.parse(payload.config)
                if (parsed) {
                    state.appointments = parsed.appointments ?? []
                    state.offices = parsed.offices ?? []
                    state.areas = parsed.areas ?? []
                    state.clinics = parsed.clinics ?? []
                    state.referrals = parsed.referrals ?? []
                    state.patientFiles = parsed.patientFiles ?? []
                    state.medEvents = parsed.medEvents ?? []
                }
            }
            else {
                state.appointments = []
                state.offices = []
                state.areas = []
                state.clinics = []
                state.referrals = []
                state.patientFiles = []
                state.medEvents = []
            }
        },
        [Mutation.FINANCE_SET_CLUSTERS](state, payload) {
            if (payload) {
                state.clusters = payload
            }
            else {
                state.clusters = []
            }
        },
        [Mutation.CONFIG_SET_LINK](state, payload) {
            state.link = payload
        },
        [Mutation.CONFIG_SETUP_DICTIONARIES](state, payload) {
            state.dictionaries = payload
        },
        [Mutation.CONFIG_SET_LINK_STATE](state, payload) {
            state.linkState = payload
        },
        [Mutation.FINANCE_SET_FACILTIES](state, payload) {
            if (payload) {
                payload.forEach(p => {
                    p.joined = `${p.name} [${p.shortName}], ${p.address.street}`
                    p.searchName = `${p.name} [${p.shortName}], ${p.address.street} ${p.address.buildingNumber}${p.address.apartmentNumber ? "/" + p.address.apartmentNumber : ""}, ${p.address.zipCode} ${p.address.city}`
                })
                state.facilities = payload
            }
            else {
                state.facilities = []
            }
        },
        [Mutation.CONFIG_SET_CURRENT_FACILITY](state, payload) {
            state.currentFacility = payload
        },

        [Mutation.SET_MY_SETTINGS](state, payload) {
            if (payload) {
                state.mySettings = payload
                // state.mySettings.officeSettings = {
                //     dosageType: 0,
                //     templateReferrals:[
                //         {
                //             type:0,
                //             comment:"Skierowanie na cokolwiek"
                //         }
                //     ]
                // }
            }
        },

        [Mutation.UPDATE_MY_SETTINGS](state, payload) {
            if (payload) {
                state.mySettings.public = payload.public
                state.mySettings.private = payload.private
                state.mySettings.officeSettings = payload.officeSettings
            }
        },
        [Mutation.CONFIG_SET_PRINT_LOADING](state, payload) {
            state.printLoading = payload
        },
    },

    actions: {

        [Action.CONFIG_GET_ALL_DICTIONARIES](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CONFIG_GET_ALL_DICTIONARIES,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.CONFIG_SETUP_DICTIONARIES, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },




        [Action.S3_CONFIG_POST](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SCHEDULE3_CONFIG_POST,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },



        [Action.EMPLOYEE_SETTINGS_MODIFY](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_SETTINGS_MODIFY.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.UPDATE_MY_SETTINGS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.MY_SETTINGS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MY_SETTINGS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.SET_MY_SETTINGS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },




        [Action.FINANCE_ORGANIZATIONALUNIT_GET_CLUSTERS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_GET_CLUSTERS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_SET_CLUSTERS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.S3_CONFIG_GET](context, subject) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    context.commit(Mutation.CONFIG_SETUP_SCHEDULE_CONFIG, null)
                    axios({
                        method: 'get',
                        url: Apis.SCHEDULE3_CONFIG.replace('{subjectId}', subject),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.CONFIG_SETUP_SCHEDULE_CONFIG, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },












        [Action.EMPLOYEE_SMS_CONFIG_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_SMS_CONFIG_ALL,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.EMPLOYEE_SMS_CONFIG_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_SMS_CONFIG_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EMPLOYEE_SMS_CONFIG_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_SMS_CONFIG_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.EMPLOYEE_SMS_CONFIG_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_SMS_CONFIG_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.EMPLOYEE_SMS_CONFIG_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_SMS_CONFIG_BY_ID.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}