export default {

  data: () => {
    return {
      /**Typy wizyt
       * Appointment types
       */
      appointmentTypes: [
        {
          id: 0,
          name: 'Kontrolna'
        },
      ],

      worktimeAreas: [
        { id: 0, name: 'Konsultacje', icon: 'hospital' },
        { id: 1, name: 'Nagłe', icon: 'hospital' },
        { id: 2, name: 'Zabiegi', icon: 'hospital' },
        { id: 3, name: 'Administracyjne', icon: 'hospital' },
        { id: 4, name: 'Patronaż', icon: 'hospital' },
        { id: 5, name: 'Cytologia', icon: 'hospital' },
        { id: 6, name: 'Punkt pobrań', icon: 'hospital' },
        { id: 7, name: 'Poradnictwo położnej', icon: 'hospital' },
        { id: 8, name: 'Zabiegowa', icon: 'hospital' },
        { id: 9, name: 'Przeglądy lekowe', icon: 'hospital' },
        { id: 10, name: 'Poradnictwo lekowe', icon: 'hospital' },
        { id: 11, name: 'Poradnictwo interwencyjne', icon: 'hospital' },
        { id: 12, name: 'Ciężarne', icon: 'hospital' },
        { id: 13, name: 'Konsultacyjno-diagnostyczna', icon: 'hospital' },
        { id: 100000, name: 'Gabinet endoskopowy', icon: 'hospital' },
        { id: 100100, name: 'Punkt pobrań', icon: 'hospital' },
        { id: 100200, name: 'Gabinet zabiegowy', icon: 'hospital' },
        { id: 100300, name: 'Gabinet pielęgniarski', icon: 'hospital' },
        { id: 100400, name: 'Gabinet diagnostyczny USG', icon: 'hospital' },
        { id: 100500, name: 'Pracownia diagnostyczna', icon: 'hospital' },
        { id: 100600, name: 'Gabinet cytologiczny', icon: 'hospital' },
        { id: 100700, name: 'Punkt pobrań COVID', icon: 'hospital' },
      ],
      /**Rodzaje pomieszczeń
       * Types of offices
       */
      mOfficeAreas: [
        {
          id: 100000, name: 'Gabinet endoskopowy STARY', subcategories: [
            { id: 100001, name: 'Gastroskopia' },
            { id: 100002, name: 'Kolonoskopia' },
            { id: 100003, name: 'Cytoskopia' }
          ], icon: 'hospital', isOffice: true
        },
        {
          id: 100100, name: 'Punkt pobrań', icon: 'hospital', subcategories: [
            { id: 100101, name: 'Pobrania' },
          ], isOffice: true
        },
        {
          id: 100200, name: 'Gabinet zabiegowy', subcategories: [
            { id: 100201, name: 'Zabiegi' },
            { id: 100202, name: 'Szczepienia', time: 5 },
            { id: 100203, name: 'Zakładanie Holterów' },
            { id: 100203, name: 'Pomiary' }
          ], icon: 'hospital', isOffice: true
        },
        {
          id: 100300, name: 'Gabinet pielęgniarski', subcategories: [
            { id: 100301, name: 'Edukacje' },
            { id: 100302, name: 'Konsultacje' },
            { id: 100303, name: 'Pomiary' },
          ], icon: 'hospital', isOffice: true
        },
        {
          id: 100400, name: 'Gabinet diagnostyczny USG', subcategories: [
            { id: 100401, name: 'USG' },
            { id: 100402, name: 'Echo serca' },
          ], icon: 'hospital', isOffice: true
        },
        {
          id: 100500, name: 'Pracownia diagnostyczna', subcategories: [
            { id: 100501, name: 'Mammografia' },
            { id: 100502, name: 'RTG' },
            { id: 100503, name: 'Tomografia komputerowa' },
            { id: 100504, name: 'Densytometria' },
          ], icon: 'hospital', isOffice: true
        },
        {
          id: 100600, name: 'Gabinet cytologiczny', subcategories: [
            {
              id: 111, name: '[POŁ] Pobranie', subcategories: [
                { id: 11100, name: '[POŁ] Cytologii', time: 10, },
              ]
            },
          ], icon: 'hospital', isOffice: true
        },
        {
          id: 100700, name: 'Punkt pobrań COVID', icon: 'hospital', subcategories: [
            {
              id: 130, name: '[PP] Pobranie', time: 10, subcategories: [
                { id: 13000, name: '[PP] COVID', time: 10, },
              ], isOffice: true
            },
          ]
        },
      ],

      /* Doctor appointment categories */
      appointmentCategories: [

        // RODZINNY
        { id: 0, name: '[LR/PR] Kontrolna', time: 15, icon: 'heart-half-full' },
        { id: 1, name: '[LR/PR] Kompleksowa', time: 30, icon: 'clipboard-account' },
        { id: 2, name: '[LR/PR] Bilansowa B', time: 20, icon: 'magnify' },
        { id: 3, name: '[LR/PR] Doraźna', time: 10, icon: 'flash' },
        { id: 4, name: '[LR/PR] Kwalifikacja na szczepienie', time: 10, icon: 'needle' },
        {
          id: 5, name: '[LR/PR] Administracyjna', time: 10, subcategories: [
            { id: 500, name: '[LR/PR] Orzeczenia/zaświadczenia' },
            { id: 501, name: '[LR/PR] Recepturowa', time: 5 },
            { id: 502, name: '[LR/PR] Konsylia' }
          ], icon: 'briefcase'
        },
        { id: 6, name: '[LR/PR] Kwalifikacja do programu', time: 10, icon: 'check-circle-outline' },

        // PEDIATRA,
        { id: 20, name: '[LRP/PPDZ] Kwalifikacja na szczepienie' },
        { id: 21, name: '[LRP/PPDZ] Wizyta patronażowa' },
        { id: 22, name: '[LRP/PPDZ] Badanie profilaktyczne niemowląt' },
        {
          id: 23, name: '[LRP/PPDZ] Bilans', subcategories: [
            { id: 2300, name: '[LRP/PPDZ] 2 latka' },
            { id: 2301, name: '[LRP/PPDZ] 4 latka' },
            { id: 2302, name: '[LRP/PPDZ] 6 latka' },
            { id: 2303, name: '[LRP/PPDZ] 10 latka' },
            { id: 2304, name: '[LRP/PPDZ] 13 latka' },
            { id: 2305, name: '[LRP/PPDZ] 16 latka' },
            { id: 2306, name: '[LRP/PPDZ] 18 latka' }
          ]
        },
        { id: 24, name: '[LRP/PPDC] Doraźna' },
        { id: 25, name: '[LRP/PPDC] Kompleksowa' },
        { id: 26, name: '[LRP/PPDC] Kontrolna' },
        {
          id: 27, name: '[LRP/PPDC] Administracyjna', subcategories: [
            { id: 2700, name: '[LRP/PPDC] Orzeczenie' },
            { id: 2701, name: '[LRP/PPDC] Recepturowa' },
            { id: 2702, name: '[LRP/PPDC] Konsylia' },
          ]
        },

        // PIELĘGNIARKA ŚROD-RODZINNA
        { id: 40, name: '[P/ŚR] Patronaż (pielęgniarski)', time: 5 },
        { id: 41, name: '[P/ŚR] Bilansowa A', time: 15 },

        // PIELĘGNIARKA OPIEKI DŁUGOTERMINOWEJ
        {
          id: 50, name: '[P/OD] Pomiar', time: 5, subcategories: [
            { id: 5000, name: '[P/OD] Niemowlęcia' },
            { id: 5001, name: '[P/OD] Dziecka' },
            { id: 5002, name: '[P/OD] Dorosłego' },
          ]
        },

        // PIELĘGNIARKA SOCJALNA
        {
          id: 60, name: '[P/?] Zabieg', time: 10, subcategories: [
            { id: 6000, name: '[P/?] Iniekcje' },
            { id: 6001, name: '[P/?] Zdjęcia szwów' },
            { id: 6002, name: '[P/?] Wlewy dożylne' },
            { id: 6003, name: '[P/?] Założenie wenflonu' },
            { id: 6004, name: '[P/?] Podania doustne leków' },
            { id: 6005, name: '[P/?] Płukanie uszu' },
            { id: 6006, name: '[P/?] Usunięcie kleszcza' },
            { id: 6007, name: '[P/?] Cewnikowanie pacjenta' },
            { id: 6008, name: '[P/?] Opatrunek' },
            { id: 6009, name: '[P/?] Leczenie ran' },
            { id: 6010, name: '[P/?] Testy' },
            { id: 6011, name: '[P/?] EKG' }
          ]
        },
        {
          id: 61, name: '[P/?] Szczepienie', time: 5, subcategories: [
            { id: 5000, name: '[P/?] ?' },
          ]
        },
        {
          id: 62, name: '[P/?] Porada interwencyjna', subcategories: [
            { id: 6200, name: '[P/?] ?' },
          ]
        },
        {
          id: 63, name: '[P/?] Porada przesiewowa', subcategories: [
            { id: 6300, name: '[P/?] ?' },
          ]
        },
        {
          id: 64, name: '[P/?] Kontrolna', time: 5, subcategories: [
            { id: 6400, name: '[P/?] Standard' },
            { id: 6401, name: '[P/?] Umówienie wizyt' },
            { id: 6402, name: '[P/?] Pomoc w wypełnieniu ankiety' },
            { id: 6403, name: '[P/?] Prolongacja recepty' },
            { id: 6404, name: '[P/?] Ordynacja recepty' },
            { id: 6405, name: '[P/?] Zaświadczenia' },
            { id: 6406, name: '[P/?] Digitalizacja pacjenta' },
            { id: 6407, name: '[P/?] Przegląd profilaktyczny', time: 10 },
          ]
        },

        // PIELĘGNIARKA GENETYCZNA
        { id: 90, name: '[P/GEN] Wizyta kw. do programu genetycznego' },
        { id: 91, name: '[P/GEN] Edukacja długa', time: 15 },
        {
          id: 92, name: '[P/GEN] Pobranie', time: 5, subcategories: [
            { id: 9200, name: '[P/GEN] Pobranie krwi' },
            { id: 9201, name: '[P/GEN] Pobranie wymazu' },
            { id: 9202, name: '[P/GEN] Pobranie materiału na posiew' },
            { id: 9203, name: '[P/GEN] Przyjęcie materiału biologicznego - mocz, kał' }
          ]
        },
        {
          id: 93, name: '[P/GEN] Badanie diagnostyczne', time: 10, subcategories: [
            { id: 9300, name: '[P/GEN] EKG spoczynkowe' },
            { id: 9301, name: '[P/GEN] Spirometria' },
            { id: 9302, name: '[P/GEN] Pikflometria', time: 5 },
            { id: 9303, name: '[P/GEN] Holter EKG' },
            { id: 9304, name: '[P/GEN] Holter ciśnieniowy', time: 5 },
            { id: 9305, name: '[P/GEN] EEG', time: 20 },
            { id: 9306, name: '[P/GEN] USG' },
            { id: 9307, name: '[P/GEN] RTG' },
            { id: 9308, name: '[P/GEN] TK' },
            { id: 9309, name: '[P/GEN] Test wysiłkowy EKG' },
            { id: 9310, name: '[P/GEN] KTG' },
            { id: 9311, name: '[P/GEN] Audiometria' },
            { id: 9312, name: '[P/GEN] Biopsja ' },
            { id: 9313, name: '[P/GEN] Nakłucie dostawowe ' },
            { id: 9314, name: '[P/GEN] Kolposkopia ' },
            { id: 9315, name: '[P/GEN] Kolonoskopia ' },
            { id: 9316, name: '[P/GEN] Gastroskopia ' },
            { id: 9317, name: '[P/GEN] Badania endoskopowe ' },
            { id: 9318, name: '[P/GEN] Badanie pola widzenia ' }
          ]
        },

        // POŁOŻNA
        { id: 110, name: '[POŁ] Wizyta patronażowa' },
        {
          id: 111, name: '[POŁ] Pobranie', icon: 'needle', time: 15, subcategories: [
            { id: 11100, name: '[POŁ] Cytologii' },
            { id: 11101, name: '[POŁ] Biocenozy' },
            { id: 11102, name: '[POŁ] Paciorkowca' },
          ]
        },
        {
          id: 112, name: '[POŁ] Wizyta kompleksowa', subcategories: [
            { id: 11200, name: '[POŁ] Ciąża' },
          ]
        },
        {
          id: 113, name: '[POŁ] Porada', subcategories: [
            { id: 11300, name: '[POŁ] Klimakterium' },
            { id: 11301, name: '[POŁ] Senium' },
            { id: 11302, name: '[POŁ] Pokwitanie' },
            { id: 11303, name: '[POŁ] Przekwitanie' },
          ]
        },
        {
          id: 114, name: '[POŁ] Zabieg', subcategories: [
            { id: 11400, name: '[POŁ] Iniekcje' },
            { id: 11401, name: '[POŁ] Zdjęcia szwów' },
            { id: 11402, name: '[POŁ] Wlewy dożylne' },
            { id: 11403, name: '[POŁ] Założenie wenflonu' },
            { id: 11404, name: '[POŁ] Podania doustne leków' },
            { id: 11405, name: '[POŁ] Płukanie uszu' },
            { id: 11406, name: '[POŁ] Usunięcie kleszcza' },
            { id: 11407, name: '[POŁ] Cewnikowanie pacjenta' },
            { id: 11408, name: '[POŁ] Opatrunek' },
          ]
        },
        {
          id: 115, name: '[POŁ] Badanie diagnostyczne', subcategories: [
            { id: 11500, name: '[POŁ] KTG ciężarnej' },
            { id: 11501, name: '[POŁ] Badanie piersi' },
          ]
        },

        {
          id: 130, name: '[PP] Pobranie', icon: 'needle', subcategories: [
            { id: 13000, name: '[PP] COVID' },
          ]
        },

        // FARMACEUTA
        { id: 140, name: '[FAR] Przegląd lekowy' },
        {
          id: 141, name: '[FAR] Porada lekowa', subcategories: [
            { id: 14100, name: '[FAR] Ocena zestawu leków pod kątem rozpoznania' },
            { id: 14101, name: '[FAR] Digitalizacja pacjenta' },
            { id: 14102, name: '[FAR] Przegląd profilaktyczny' },
          ]
        },
        { id: 142, name: '[FAR] Porada interwencyjna' },

        // AOS (INNE ?)
        {
          id: 180,
          name: '[AOS] Wizyta wstępna'
        },
        {
          id: 181,
          name: '[AOS] Pierwszorazowa'
        },
        {
          id: 182,
          name: '[AOS] Kontrolna'
        },
        {
          id: 183,
          name: '[AOS] Kompleksowa?'
        },
        {
          id: 184,
          name: '[AOS] Wizyta kontrolna ciężarnej',
          time: 15
        },
        {
          id: 185,
          name: '[AOS] Wizyta specjalistyczna',
          time: 15,
          subcategories: [
            { id: 18500, name: '[AOS] Pierwszorazowa', time: 15 },
            { id: 18501, name: '[AOS] Kontrolna', time: 10 },
            { id: 18502, name: '[AOS] Kompleksowa', time: 20 },
          ]
        },
        {
          id: 186,
          name: '[AOS] USG',
          time: 15,
          subcategories: [
            { id: 18600, name: '[AOS] Położnicze', time: 15 },
            { id: 18601, name: '[AOS] Ginekologiczne', time: 10 },
          ]
        },

        // GABINET RATOWNIKA
        {
          id: 99999,
          name: '[POZ] Transport medyczny'
        },

        // AOS
        {
          id: 200,
          name: '[AOS/?] Wizyta'
        },

        // REHABILITANT
        {
          id: 220,
          name: '[RHB] Wizyta'
        },

        // FIZJOTERAPEUTA
        {
          id: 240,
          name: '[FIZ] Wizyta'
        },

        // DIETETYK
        {
          id: 260,
          name: '[DTK/POZ] Porada'
        },

        {
          id: 300,
          name: '[DTK/POZ] Konsultacja dietetyczna'
        },

        // WSPARCIE
        {
          id: 280,
          name: '[WSP/?] Wizyta/wsparcie'
        },

        // TEST
        {
          id: 999,
          name: '[TEST] Zabiegowa'
        },

        // PORADNIE
        {
          id: 1000,
          name: "Poradnia chorób wewnętrznych (1000)"
        },
        {
          id: 1008,
          name: "Poradnia chorób metabolicznych (1008)"
        },
        {
          id: 1010,
          name: "Poradnia alergologiczna (1010)"
        },
        {
          id: 1020,
          name: "Poradnia diabetologiczna (1020)"
        },
        {
          id: 1022,
          name: "Poradnia leczenia zespołu stopy cukrzycowej (1022)"
        },
        {
          id: 1024,
          name: "Poradnia leczenia ran przewlekłych (1024)"
        },
        {
          id: 1027,
          name: "Poradnia endokrynologii i diabetologii dziecięcej (1027)"
        },
        {
          id: 1030,
          name: "Poradnia endokrynologiczna (1030)"
        },
        {
          id: 1032,
          name: "Poradnia endokrynologiczno-ginekologiczna (1032)"
        },
        {
          id: 1034,
          name: "Poradnia andrologiczna (1034)"
        },
        {
          id: 1036,
          name: "Poradnia leczenia niepłodności (1036)"
        },
        {
          id: 1038,
          name: "Poradnia chorób tarczycy (1038)"
        },
        {
          id: 1040,
          name: "Poradnia endokrynologiczna osteoporozy (1040)"
        },
        {
          id: 1050,
          name: "Poradnia gastroenterologiczna (1050)"
        },
        {
          id: 1056,
          name: "Poradnia hepatologiczna (1056)"
        },
        {
          id: 1060,
          name: "Poradnia geriatryczna (1060)"
        },
        {
          id: 1070,
          name: "Poradnia hematologiczna (1070)"
        },
        {
          id: 1072,
          name: "Poradnia nowotworów krwi (1072)"
        },
        {
          id: 1080,
          name: "Poradnia immunologiczna (1080)"
        },
        {
          id: 1100,
          name: "Poradnia kardiologiczna (1100)"
        },
        {
          id: 1102,
          name: "Poradnia wad serca (1102)"
        },
        {
          id: 1104,
          name: "Poradnia nadciśnienia tętniczego (1104)"
        },
        {
          id: 1120,
          name: "Poradnia chorób naczyń (1120)"
        },
        {
          id: 1130,
          name: "Poradnia nefrologiczna (1130)"
        },
        {
          id: 1150,
          name: "Poradnia toksykologiczna (1150)"
        },
        {
          id: 1160,
          name: "Poradnia medycyny pracy (1160)"
        },
        {
          id: 1180,
          name: "Poradnia medycyny paliatywnej (1180)"
        },
        {
          id: 1200,
          name: "Poradnia dermatologiczna (1200)"
        },
        {
          id: 1202,
          name: "Poradnia wenerologiczna (1202)"
        },
        {
          id: 1210,
          name: "Poradnia genetyczna (1210)"
        },
        {
          id: 1212,
          name: "Poradnia genetyczno-onkologiczna (1212)"
        },
        {
          id: 1220,
          name: "Poradnia neurologiczna (1220)"
        },
        {
          id: 1222,
          name: "Poradnia leczenia bólu (1222)"
        },
        {
          id: 1240,
          name: "Poradnia onkologiczna (1240)"
        },
        {
          id: 1242,
          name: "Poradnia chemioterapii (1242)"
        },
        {
          id: 1244,
          name: "Poradnia radioterapii (1244)"
        },
        {
          id: 1249,
          name: "Poradnia onkologii i hematologii dziecięcej (1249)"
        },
        {
          id: 1250,
          name: "Poradnia medycyny nuklearnej (1250)"
        },
        {
          id: 1270,
          name: "Poradnia gruźlicy i chorób płuc (1270)"
        },
        {
          id: 1272,
          name: "Poradnia chorób płuc (1272)"
        },
        {
          id: 1276,
          name: "Poradnia leczenia mukowiscydozy (1276)"
        },
        {
          id: 1280,
          name: "Poradnia reumatologiczna (1280)"
        },
        {
          id: 1300,
          name: "Poradnia rehabilitacyjna (1300)"
        },
        {
          id: 1302,
          name: "Poradnia rehabilitacji narządu ruchu (1302)"
        },
        {
          id: 1304,
          name: "Poradnia zaopatrzenia ortopedycznego (1304)"
        },
        {
          id: 1306,
          name: "Poradnia rehabilitacji neurologicznej (1306)"
        },
        {
          id: 1308,
          name: "Poradnia rehabilitacji kardiologicznej (1308)"
        },
        {
          id: 1310,
          name: "Dział (pracownia) fizjoterapii (1310)"
        },
        {
          id: 1312,
          name: "Dział (pracownia) fizykoterapii (1312)"
        },
        {
          id: 1314,
          name: "Dział (pracownia) kinezyterapii (1314)"
        },
        {
          id: 1316,
          name: "Dział (pracownia) hydroterapii (1316)"
        },
        {
          id: 1318,
          name: "Dział (pracownia) krioterapii (1318)"
        },
        {
          id: 1320,
          name: "Dział (pracownia) masażu leczniczego (1320)"
        },
        {
          id: 1330,
          name: "Dział (pracownia) balneoterapii (1330)"
        },
        {
          id: 1340,
          name: "Poradnia chorób zakaźnych (1340)"
        },
        {
          id: 1342,
          name: "Poradnia profilaktyczno-lecznicza (HIV/AIDS) (1342)"
        },
        {
          id: 1346,
          name: "Poradnia chorób tropikalnych (1346)"
        },
        {
          id: 1348,
          name: "Poradnia chorób odzwierzęcych i pasożytniczych (1348)"
        },
        {
          id: 1370,
          name: "Poradnia medycyny sportowej (1370)"
        },
        {
          id: 1390,
          name: "Poradnia leczenia obrzęku limfatycznego (1390)"
        },
        {
          id: 1401,
          name: "Poradnia pediatryczna (1401)"
        },
        {
          id: 1405,
          name: "Poradnia zaburzeń i wad rozwojowych dzieci (1405)"
        },
        {
          id: 1409,
          name: "Poradnia pediatryczna szczepień dla dzieci z grup wysokiego ryzyka (1409)"
        },
        {
          id: 1421,
          name: "Poradnia neonatologiczna (1421)"
        },
        {
          id: 1450,
          name: "Poradnia położniczo-ginekologiczna (1450)"
        },
        {
          id: 1452,
          name: "Poradnia ginekologiczna (1452)"
        },
        {
          id: 1453,
          name: "Poradnia ginekologiczna dla dziewcząt (1453)"
        },
        {
          id: 1454,
          name: "Poradnia patologii ciąży (1454)"
        },
        {
          id: 1458,
          name: "Poradnia profilaktyki chorób piersi (1458)"
        },
        {
          id: 1460,
          name: "Poradnia ginekologii onkologicznej (1460)"
        },
        {
          id: 1470,
          name: "Poradnia planowania rodziny i rozrodczości (1470)"
        },
        {
          id: 1472,
          name: "Szkoła rodzenia (1472)"
        },
        {
          id: 1474,
          name: "Poradnia laktacyjna (1474)"
        },
        {
          id: 1500,
          name: "Poradnia chirurgii ogólnej (1500)"
        },
        {
          id: 1506,
          name: "Poradnia proktologiczna (1506)"
        },
        {
          id: 1520,
          name: "Poradnia chirurgii klatki piersiowej (1520)"
        },
        {
          id: 1530,
          name: "Poradnia chirurgii naczyniowej (1530)"
        },
        {
          id: 1540,
          name: "Poradnia chirurgii onkologicznej (1540)"
        },
        {
          id: 1550,
          name: "Poradnia chirurgii plastycznej (1550)"
        },
        {
          id: 1552,
          name: "Poradnia chirurgii ręki (1552)"
        },
        {
          id: 1554,
          name: "Poradnia leczenia oparzeń (1554)"
        },
        {
          id: 1560,
          name: "Poradnia kardiochirurgiczna (1560)"
        },
        {
          id: 1562,
          name: "Poradnia kontroli rozruszników i kardiowerterów (1562)"
        },
        {
          id: 1570,
          name: "Poradnia neurochirurgiczna (1570)"
        },
        {
          id: 1572,
          name: "Poradnia kontroli systemów neuromodulacji (1572)"
        },
        {
          id: 1580,
          name: "Poradnia chirurgii urazowo-ortopedycznej (1580)"
        },
        {
          id: 1583,
          name: "Poradnia wad postawy (1583)"
        },
        {
          id: 1584,
          name: "Poradnia osteoporozy (1584)"
        },
        {
          id: 1587,
          name: "Poradnia preluksacyjna (1587)"
        },
        {
          id: 1600,
          name: "Poradnia okulistyczna (1600)"
        },
        {
          id: 1604,
          name: "Poradnia leczenia zeza (1604)"
        },
        {
          id: 1610,
          name: "Poradnia otorynolaryngologiczna (1610)"
        },
        {
          id: 1612,
          name: "Poradnia audiologiczna (1612)"
        },
        {
          id: 1614,
          name: "Poradnia foniatryczna (1614)"
        },
        {
          id: 1616,
          name: "Poradnia logopedyczna (1616)"
        },
        {
          id: 1630,
          name: "Poradnia chirurgii szczękowo-twarzowej (1630)"
        },
        {
          id: 1640,
          name: "Poradnia urologiczna (1640)"
        },
        {
          id: 1642,
          name: "Ambulatoryjna stacja dializ (1642)"
        },
        {
          id: 1650,
          name: "Poradnia transplantologiczna (1650)"
        },
        {
          id: 1670,
          name: "Poradnia anestezjologiczna (1670)"
        },
        {
          id: 1700,
          name: "Poradnia zdrowia psychicznego (1700)"
        },
        {
          id: 1704,
          name: "Poradnia psychogeriatryczna (1704)"
        },
        {
          id: 1706,
          name: "Poradnia leczenia nerwic (1706)"
        },
        {
          id: 1708,
          name: "Poradnia dla osób z autyzmem dziecięcym (1708)"
        },
        {
          id: 1710,
          name: "Poradnia psychosomatyczna (1710)"
        },
        {
          id: 1740,
          name: "Poradnia leczenia uzależnień (1740)"
        },
        {
          id: 1742,
          name: "Poradnia antynikotynowa (1742)"
        },
        {
          id: 1744,
          name: "Poradnia terapii uzależnienia od alkoholu i współuzależnienia (1744)"
        },
        {
          id: 1746,
          name: "Poradnia terapii uzależnienia od substancji psychoaktywnych (1746)"
        },
        {
          id: 1750,
          name: "Poradnia promocji zdrowia psychicznego (1750)"
        },
        {
          id: 1780,
          name: "Poradnia seksuologiczna i patologii współżycia (1780)"
        },
        {
          id: 1790,
          name: "Poradnia psychologiczna (1790)"
        },
        {
          id: 1800,
          name: "Poradnia stomatologiczna (1800)"
        },
        {
          id: 1810,
          name: "Poradnia periodontologiczna oraz chorób błon śluzowych przyzębia (1810)"
        },
        {
          id: 1820,
          name: "Poradnia ortodontyczna (1820)"
        },
        {
          id: 1830,
          name: "Poradnia protetyki stomatologicznej (1830)"
        },
        {
          id: 1840,
          name: "Poradnia chirurgii stomatologicznej (1840)"
        },
        {
          id: 1850,
          name: "Poradnia uzdrowiskowa (1850)"
        },
        {
          id: 1860,
          name: "Poradnia domowego leczenia tlenem (1860)"
        },
        {
          id: 1870,
          name: "Poradnia Żywieniowa (1870)"
        }










      ],

      /* Doctor schedule areas */
      timetableAreas: [
        {
          id: 0,
          name: 'Konsultacje'
        },
        {
          id: 1,
          name: 'Nagłe'
        },
        {
          id: 2,
          name: 'Recepturowe'
        },
        {
          id: 3,
          name: 'Administracyjne'
        },
        {
          id: 4,
          name: 'Prywatne'
        }
      ],

      appointmentCancelReasons: [
        {
          id: 0,
          name: "Anulowanie automatyczne"
        },
        {
          id: 1,
          name: "Pacjent zrezygnował ze świadczenia"
        },
        {
          id: 2,
          name: "Brak ubezpieczenia pacjenta"
        },
        {
          id: 3,
          name: "Niemożliwe potwierdzenie tożsamości pacjenta"
        },
        {
          id: 4,
          name: "Odmowa wykonania świadczenia przez personel"
        },
        {
          id: 5,
          name: "Pomyłka przy zapisie pacjenta"
        },
        {
          id: 6,
          name: "Usługę wykonano w innym miejscu"
        },
        {
          id: 7,
          name: "Niemożność skontaktowania się z pacjentem"
        },
        {
          id: 8,
          name: "Zgon pacjenta przed rozpoczęciem wizyty"
        }
      ],

      emptyAppointmentState: {
        id: -1,
        icon: "timelapse",
        type: "is-warning",
        text: "Brak informacji o statusie wizyty"
      },

      mAppointmentStates: [
        {
          id: -1,
          icon: "timelapse",
          type: "is-warning",
          text: "Brak informacji"
        },
        {
          id: 0,
          icon: "clock-outline",
          type: "is-dark",
          text: "Nierozpoczęta"
        },
        {
          id: 1,
          icon: "timelapse",
          type: "is-danger",
          text: "Niezakończona"
        },
        {
          id: 2,
          icon: "clock-out",
          type: "is-danger",
          text: "Współdzielona"
        },
        {
          id: 5,
          icon: "timelapse",
          type: "is-success",
          text: "Zakończona"
        },
        {
          id: 6,
          icon: "calendar-remove",
          type: "is-danger",
          text: "Anulowana"
        },
        {
          id: 7,
          icon: "calendar-repeat",
          type: "is-info",
          text: "Przełożona"
        },
        {
          id: 8,
          icon: "calendar-remove",
          type: "is-danger",
          text: "Termin wizyty minął"
        },
        {
          id: 9,
          icon: "calendar-edit",
          type: "is-orange",
          text: "Edycja wizyty"
        },
        {
          id: 100,
          icon: "calendar-text",
          type: "is-info",
          text: "Rezerwacja wstępna"
        }
      ],

      mAppointmentLogTypes: [
        {
          id: 1,
          name: "Wejście na wizytę"
        },
        {
          id: 2,
          name: "Wyjście z wizyty"
        },
        {
          id: 3,
          name: "Zakończenie wizyty"
        },
        {
          id: 4,
          name: "Otwarcie wizyty do korekty"
        },
        {
          id: 5,
          name: "Zakończenie korekty"
        }
      ],

      mAppointmentExitReason: [
        {
          id: 0,
          name: "Pomyłka"
        },
        {
          id: 1,
          name: "Nie można pobrać materiału"
        },
        {
          id: 2,
          name: "Przerwa w wizycie ze względów administracyjnych"
        },
        {
          id: 3,
          name: "Odmowa świadczenia"
        },
        {
          id: 9,
          name: "Wyjście z korekty bez zapisu zmian"
        }
      ],

      mVisitCorrectionReason: [
        {
          id: 101,
          name: "Błąd w lekach"
        },
        {
          id: 102,
          name: "Błąd w diagnozie"
        },
        {
          id: 103,
          name: "Błąd w wywiadzie"
        },
        {
          id: 104,
          name: "Błąd w badaniach"
        },
        {
          id: 105,
          name: "Błąd w zwolnieniu"
        },
        {
          id: 106,
          name: "Błąd w zaświadczeniu"
        },
        {
          id: 107,
          name: "Błąd w skierowaniu"
        },
        {
          id: 108,
          name: "Błąd w planie leczenia"
        },
        {
          id: 109,
          name: "Błąd w świadczeniach"
        },
        {
          id: 199,
          name: "Inny"
        },

      ],

      mAppointmentPayerType: [
        {
          id: 0,
          name: "Komercja"
        },
        {
          id: 1,
          name: "NFZ"
        },
        {
          id: 2,
          name: "MZ"
        }
      ],

      mAppointmentServiceType: [
        {
          id: 0,
          name: "Przychodnia"
        },
        {
          id: 1,
          name: "Teleporada"
        },
        {
          id: 2,
          name: "Domowa"
        },
      ],

      mAppointmentSubtypeAge: [
        {
          id: 2300,
          agePossible: [1, 3]
        },
        {
          id: 2301,
          agePossible: [3, 5]
        },
        {
          id: 2302,
          agePossible: [5, 7]
        },
        {
          id: 2303,
          agePossible: [9, 11]
        },
        {
          id: 2304,
          agePossible: [11, 13]
        },
        {
          id: 2305,
          agePossible: [15, 17]
        },
        {
          id: 2306,
          agePossible: [17, 19]
        }
      ]
    }
  },
  /**Metoda wywoływania statusu wizyty
   * Method for getting appointment status
   */
  methods: {
    mGetAppointmentState(id) {
      let state = this.mAppointmentStates.find(s => s.id == id);
      if (state) return state
      else return this.mAppointmentStates.find(s => s.id == -1) ?? this.emptyAppointmentState;
    },

    mGetAppointmentStateIcon(id) {
      let state = this.mAppointmentStates.find(s => s.id == id);
      if (!state) {
        state = this.emptyAppointmentState;
      }
      return state.icon
    },

    mGetAppointmentStateText(id) {
      let state = this.mAppointmentStates.find(s => s.id == id);
      if (!state) {
        state = this.emptyAppointmentState;
      }
      return state.text
    },

    mGetAppointmentStateType(id) {
      let state = this.mAppointmentStates.find(s => s.id == id);
      if (!state) {
        state = this.emptyAppointmentState;
      }
      return state.type
    },

    getWorktimeAreaName(id) {
      let category = this.worktimeAreas.find(c => c.id == id)
      if (category) return category.name
      else return `??? [${id}]`
    },

    getOfficeAreaName(id) {
      let category = this.mOfficeAreas.find(c => c.id == id)
      if (category) return category.name
      else return `??? [${id}]`
    },

    mGetOfficeName(id) {
      let category = this.mOfficeAreas.find(c => c.id == id)
      if (category) return category.name
      else return `??? [${id}]`
    },

    getOfficeAreaObject(id) {
      let category = this.mOfficeAreas.find(c => c.id == id)
      if (category) return category
      else return { name: `??? (${id})` }
    },

    getAppointmentCategoryName(id) {
      let category = this.appointmentCategories.find(c => c.id == id)
      if (category) return category.name
      else return `??? [${id}]`
    },

    getAppointmentSubcategoryCategoryName(id, sid) {

      if (sid === null || sid < 0) return 'Brak'

      let category = this.appointmentCategories.find(c => c.id == id)

      if (category) {
        if (category.subcategories) {
          let subcategory = category.subcategories.find(s => s.id == sid)
          if (subcategory) {
            return subcategory.name
          }
        }
      }

      else return `??? [${id}]`
    },

    getAreaIcon(id) {
      let category = this.worktimeAreas.find(c => c.id == id)
      if (category) return category.icon
      else return `help-circle`
    },

    getAppointmentIcon(id) {
      let category = this.appointmentCategories.find(c => c.id == id)

      if (category) {
        if (category.icon) {
          return category.icon
        }
      }

      return `help`
    },

    getNurseAppointmentCategory(id) {
      let category = this.nurseAppointmentCategories.find(c => c.id === id)
      return category ? category.name : "???"
    },

    getTimetableAreaIcon(item) {
      switch (item) {
        case 0: return 'heart-half-full'
        case 1: return 'flash'
        case 2: return 'file-document-edit-outline'
        case 3: return 'briefcase'
        case 4: return 'cellphone-iphone'
        case 5: return 'currency-usd'
        default: return 'help-circle-outline'
      }
    },

    getAppointmentTypeIcon(item) {
      switch (item) {
        case 0: return 'heart-half-full'
        case 1: return 'clipboard-account'
        case 2: return 'magnify'
        case 3: return 'flash'
        case 4: return 'needle'
        case 5: return 'briefcase'
        case 6: return 'file-document-edit-outline'
        case 7: return 'currency-usd'
        default: return 'help-circle-outline'
      }
    },

    getAppointmentCategoryNameOLD(id) {
      switch (id) {
        case 0: return 'Kontrolna'
        case 1: return 'Kompleksowa'
        case 2: return 'Bilansowa'
        case 3: return 'Doraźna'
        case 4: return 'Szczepienie'
        case 5: return 'Administracyjna'
        case 6: return 'Recepturowa'
        default: return '???'
      }
    },

    getServiceTypeIcon(item) {
      switch (item) {
        case 0: return 'hospital-box'
        case 1: return 'phone'
        case 2: return 'home'
        default: return 'question'
      }
    },

    getServiceTypeLabel(item) {
      switch (item) {
        case 0: return 'Przychodnia'
        case 1: return 'Teleporada'
        case 2: return 'Domowa'
        default: return '???'
      }
    },

    getTimetableAreaName(id) {
      switch (id) {
        case 0: return 'Konsultacje'
        case 1: return 'Nagłe'
        case 2: return 'Recepturowe'
        case 3: return 'Administracyjne'
        case 4: return 'Prywatne'
        default: return '???'
      }
    },

    getAppointmentPayerType(id) {
      switch (id) {
        case -1: return 'Brak płatników'
        case 0: return 'Komercja'
        case 1: return 'NFZ'
        case 2: return 'MZ'
        default: return '???'
      }
    },

    getAppointmentCancelReasons(id) {
      let cancelReason = this.appointmentCancelReasons.find(c => c.id === id)
      return cancelReason ? cancelReason.name : "Brak danych"
    },

    mGetVisitCorrectionReason(id) {
      let reason = this.mVisitCorrectionReason.find(r => r.id == id)
      return reason ? reason.name : "Brak danych"
    },

    mGetAppointmentLogTypes(id) {
      let reason = this.mAppointmentLogTypes.find(r => r.id == id)
      return reason ? reason.name : "Brak danych"
    },

    mGetAppointmentExitReason(id) {
      let reason = this.mAppointmentExitReason.find(r => r.id == id)
      return reason ? reason.name : "Brak danych"
    }

  }
}
