import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        patients: [],
        patient: null,
        medicalInformation: null,
        tickets: [],
        declarations: [],
        privileges: [],
        programs: []
    },

    mutations: {
        [Mutation.PATIENT_SET_PATIENTS](state, payload) {
            state.patients = payload
        },
        [Mutation.PATIENT_SET_MEDICAL_INFORMATION](state, payload) {
            state.medicalInformation = payload
        },
        [Mutation.PATIENT_SET_TELEINFORMATION](state, payload) {
            state.patient = payload
        },
        [Mutation.PATIENT_SET_TICKETS](state, payload) {
            state.tickets = payload
        },
        [Mutation.PATIENT_SET_DECLARATIONS](state, payload) {
            state.declarations = payload
        },
        [Mutation.PATIENT_ADD_TICKET](state, ticket) {
            state.tickets.push(ticket)
        },
        [Mutation.PATIENT_SET_TICKET_STATUS](state, payload) {
            let findTicket = state.tickets.find(ticket => ticket.id === payload.id)
            if (findTicket) {
                findTicket.state = payload.state
            }
        },
        [Mutation.PRIVILEGES_SET_ALL](state, items) {

            if (items) {
                items.forEach(x => {
                    x.pharse = `${x.code} ${x.description} ${x.category} ${x.legalBasis}`
                })
            }

            state.privileges = items
        },
        [Mutation.PATIENT_SET_PROGRAMS](state, payload) {
            state.programs = payload
        }
    },

    actions: {

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_GET_BY_PESEL](context, pesel) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_BY_PESEL.replace('{pesel}', pesel),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DEKLE_GENERATE](context, payload) {
            var fileDownload = require('js-file-download');
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                axios({
                    method: 'get',
                    responseType: 'blob',
                    url: Apis.PATIENT_DEKLE_GENERATE.replace('{year}', payload.year).replace('{month}', payload.month),
                    headers: { Authorization: "Bearer " + token }
                }).then(response => {
                    var fileName = payload.year + "_" + payload.month
                    fileDownload(response.data, fileName + '.xml')
                    resolve(response)
                }).catch(error => { reject(error) });
            })
        },

        /** Attempt to get patient. Uses GET request */
        [Action.PATIENT_GET_MY_DATA](context, patient) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                context.commit(Mutation.PATIENT_SET_TELEINFORMATION, patient)
                axios({
                    method: 'get',
                    url: Apis.PATIENT_GET_MEDICAL_INFORMATION.replace('{patientId}', patient.id),
                    headers: { Authorization: "Bearer " + token }
                }).then(response => {
                    context.commit(Mutation.PATIENT_SET_MEDICAL_INFORMATION, response.data)
                    resolve();
                }).catch(error => { reject(error) });
            })
        },

        /** Attempt to get patient. Uses GET request */
        [Action.PATIENT_GET_DATA](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                axios({
                    method: 'get',
                    url: Apis.PATIENT_GET_MEDICAL_INFORMATION.replace('{patientId}', id),
                    headers: { Authorization: "Bearer " + token }
                }).then(response => {
                    resolve(response);
                }).catch(error => { reject(error) });
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_GET_FAMILY_INFO](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_FAMILY.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.RIGET_HISTORY](context, p) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIGET_HISTORY.replace('{id}', p.externalId).replace('{pesel}', p.pesel),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_INSURANCE_INFO](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_INSURANCE_INFO.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_GET_CHECKLIST](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_CHECKLIST.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_GET_PREGNANCY_INFO](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_PREGNANCY_INFO.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_IDS_INFO](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_IDS_INFO.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_SEND_PREGNANCY_INFO](context, info) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_PREGNANCY_INFO,
                        data: info,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.PATIENT_SEND_IDS_INFO](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_SEND_IDS_INFO.replace(`{version}`, payload.patient.version),
                        data: payload.identificationInfo,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.PATIENT_SEND_FAMILY_INFO](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_FAMILY.replace(`{version}`, payload.patient.version),
                        data: payload.familyInfo,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_SEND_INSURANCE_INFO](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_SEND_INSURANCE_INFO.replace(`{version}`, payload.patient.version),
                        data: payload.insurances,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_GET_EWUS](context, pesel) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TEST_EWUS.replace('{pesel}', pesel),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_ADD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_SEARCH](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_SEARCH.replace('{type}', payload.type).replace('{pharse}', payload.pharse.replaceAll("/", "%2F")),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_SEARCH_DOUBLE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_SEARCH_DOUBLE.replace('{type}', payload.type).replace('{pharse}', payload.pharse.replaceAll("/", "%2F")).replace('{type2}', payload.type2).replace('{pharse2}', payload.pharse2.replaceAll("/", "%2F")),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_UPLOAD_FILE](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {

                    const payload = new FormData();
                    payload.append('patientId', form.patientId);
                    payload.append('workerId', form.workerId);
                    payload.append('datetime', form.datetime);
                    payload.append('uploadedFile', form.uploadedFile);
                    payload.append('uploadType', form.uploadType);
                    payload.append('description', form.description);

                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_FILE,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_UPDATE_NOTE](context, info) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_HANDLE_NOTE.replace('{id}', info.patient.id),
                        data: info.payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_MODIFY](context, patient) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_PUT.replace('{id}', patient.id),
                        data: patient,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_GET_RISKS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_GET_RISKS,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_GET_MANY](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_GET_MANY,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //PATINENT_GET_FOR_40_PLUS
        [Action.PATIENT_GET_FOR_40_PLUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_FOR_40_PLUS.replace("{domain}", payload.domain),
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // STATUSES ******************************************************

        [Action.PATIENT_SEND_COMPLIENCE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_SET_COMPLIENCE.replace('{id}', payload.patientId).replace('{complience}', payload.complience),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_SEND_EXPERIENCE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_SET_EXPERIENCE.replace('{id}', payload.patientId).replace('{experience}', payload.experience),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_SEND_DISABILITY_STATUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_SET_DISABILITY,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_SEND_HEARING_STATUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_SET_HEARING,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_SEND_HOMELESS_STATUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_SET_HOMELESS,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_SEND_QUARANTINE_STATUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_SET_QUARANTINE,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_SEND_DEBT_STATUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_SET_DEBT,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // [Action.PATIENT_GET_DECLARATIONS](context, id) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         if (token != null) {
        //             axios({
        //                 method: 'get',
        //                 url: Apis.PATIENT_GET_DECLARATIONS.replace('{id}', id),
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 resolve(response);
        //             }).catch(error => { reject(error) });
        //         } else reject('401')
        //     })
        // },

        // [Action.PATIENT_POST_DECLARATIONS](context, payload) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         if (token != null) {
        //             axios({
        //                 method: 'post',
        //                 url: Apis.PATIENT_POST_DECLARATIONS.replace(`{version}`, payload.patient.version),
        //                 data: payload.declarations,
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 resolve(response);
        //             }).catch(error => { reject(error) });
        //         } else reject('401')
        //     })
        // },

        [Action.PATIENT_SEND_DECLARATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_DECLARATION,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_DECLARATIONS_BY_PATIENT_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_DECLARATIONS_BY_PATIENT_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PATIENT_SET_DECLARATIONS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_ALL_MONGO_INCIDENTS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_ALL_MONGO_INCIDENTS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_MONGO_INCIDENT_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_MONGO_INCIDENT_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_MONGO_INCIDENTS_BY_PATIENT_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_MONGO_INCIDENTS_BY_PATIENT_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_CREATE_MONGO_INCIDENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_CREATE_MONGO_INCIDENT,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_UPDATE_MONGO_INCIDENT_BY_ID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_UPDATE_MONGO_INCIDENT_BY_ID.replace('{id}', payload.id),
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },



        [Action.PATIENT_UPDATE_THERAPEUTIC_PROGRAM](context, program) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_UPDATE_PROGRAM_STATUS.replace('{id}', program.id),
                        data: program,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.PATIENT_POST_STATUSES](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_STATUSES.replace(`{version}`, payload.patient.version),
                        data: payload.stats,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_STATUSES](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_STATUSES.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_MANY_STATUSES](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_GET_MANY_STATUSES,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_POST_NOTES](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_NOTES.replace(`{version}`, payload.patient.version),
                        data: payload.notes,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_NOTES](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_NOTES.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_FAMILY_INFOS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_FAMILY_INFOS.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_INSURANCES](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_INSURANCES.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_UPDATE_STATS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_UPDATE_STATS.replace(`{version}`, payload.version),
                        data: payload.stats,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ADD_FILE_INFO](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_ADD_FILE_INFO.replace(`{patientId}`, payload.patientId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_PATIENT_FILES](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_PATIENT_FILES.replace('{patientId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_PATIENT_FILES_PROGRAM](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_PATIENT_FILES_PROGRAM.replace('{programId}', payload.programId).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_FILES_USED_IN_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_FILES_USED_IN_APPOINTMENT.replace('{patientId}', payload.patientId).replace('{appId}', payload.appId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_FILES](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_FILES.replace('{patientId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //PATIENT_DOWNLOAD_FILE
        [Action.PATIENT_DOWNLOAD_FILE](context, fileProps) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {

                    let currentUrl = Apis.PATIENT_DOWNLOAD_FILE.replace('{PatientId}', fileProps.PatientId);
                    currentUrl = currentUrl.replace('{FileNameId}', fileProps.FileNameId);
                    currentUrl = currentUrl.replace('{WorkerId}', fileProps.WorkerId);

                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: currentUrl,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        // context.commit(Mutation.FINANCE_UPLOAD_DOWNLOAD_PUSH, {id: fileId, blob: response.data});
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_REMOVE_FILE](context, object) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_REMOVE_FILE.replace('{patientId}', object.patientId),
                        data: object.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_RESTORE_FILE](context, object) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_RESTORE_FILE.replace('{patientId}', object.patientId),
                        data: object.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_REMOVE_FILE_UPLOAD](context, object) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_REMOVE_FILE_UPLOAD.replace('{id}', object.id),
                        data: object.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_RESTORE_FILE_UPLOAD](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_RESTORE_FILE_UPLOAD.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_CHECK_USE_IN_APPOINTMENT_FILE_UPLOAD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_CHECK_USE_IN_APPOINTMENT_FILE_UPLOAD.replace('{id}', payload.id),
                        data: payload.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_UNCHECK_USE_IN_APPOINTMENT_FILE_UPLOAD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_UNCHECK_USE_IN_APPOINTMENT_FILE_UPLOAD.replace('{id}', payload.id),
                        data: payload.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_COUNT_VISITS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_COUNT_VISITS.replace('{patientId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_POST_MED_GROUPS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_MED_GROUPS.replace(`{version}`, payload.patient.version),
                        data: payload.medGroups,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_MED_GROUPS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_MED_GROUPS.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_POST_NURSE_PRESCRIPTIONS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_NURSE_PRESCRIPTIONS.replace(`{version}`, payload.patient.version),
                        data: payload.nursePrescriptions,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_NURSE_PRESCRIPTIONS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_NURSE_PRESCRIPTIONS.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_CHANGELOG](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_CHANGELOG.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_CYTO_QUESTIONS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_CYTO_QUESTIONS.replace('{patientId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_END_APPOINTMENT_BY_EXTERNAL_SCHEDULE_ID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_END_APPOINTMENT_BY_EXTERNAL_SCHEDULE_ID.replace('{patientId}', payload.patientId).replace('{externalScheduleId}', payload.externalScheduleId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_END_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_END_APPOINTMENT.replace('{patientId}', payload.patientId).replace('{appId}', payload.appId).replace('{domainId}', payload.domainId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_END_APPOINTMENT_EDM](context, bundleId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_END_APPOINTMENT_EDM.replace('{bundleId}', bundleId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_END_APPOINTMENT_HISTORY_DIAGNOSES](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_END_APPOINTMENT_HISTORY_DIAGNOSES.replace('{patientId}', patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_ONGOING_APPOINTMENT_BY_EXTERNAL_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_ONGOING_APPOINTMENT_BY_EXTERNAL_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_ONGOING_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_ONGOING_APPOINTMENT.replace('{domainId}', payload.domainId).replace('{appointmentId}', payload.appointmentId).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_POST_ONGOING_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_ONGOING_APPOINTMENT,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_UPDATE_ONGOING_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_UPDATE_ONGOING_APPOINTMENT.replace('{id}', payload.id),
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DELETE_ONGOING_APPOINTMENT_BY_EXTERNAL_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_DELETE_ONGOING_APPOINTMENT_BY_EXTERNAL_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DELETE_ONGOING_APPOINTMENT_FOR_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_DELETE_ONGOING_APPOINTMENT_FOR_APPOINTMENT.replace('{domainId}', payload.domainId).replace('{appId}', payload.appId).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //
        [Action.PATIENT_DELETE_ONGOING_APPOINTMENT_FOR_CONTROL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_DELETE_ONGOING_APPOINTMENT_FOR_CONTROL.replace('{domainId}', payload.domainId).replace('{appId}', payload.appId).replace('{patientId}', payload.patientId).replace('{control}', payload.control),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.ONGOING_FOR_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.ONGOING_FOR_APPOINTMENT.replace('{domainId}', payload.domainId).replace('{appId}', payload.appId).replace('{lastOnly}', payload.lastOnly),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_APPOINTMENT_HISTORY](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_APPOINTMENT_HISTORY.replace('{PatientId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_CODES](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_CODES.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_TASKS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_TASKS.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ADD_TASK](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_ADD_TASK,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PUT_TASK](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_PUT_TASK.replace('{id}', payload.id),
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ADD_TASK_STATUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_ADD_TASK_STATUS.replace('{id}', payload.id),
                        data: payload.status,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DELETE_TASK](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_DELETE_TASK.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_DECLARATIONS_V2_BY_PATIENT_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_DECLARATIONS_V2_BY_PATIENT_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PATIENT_SET_DECLARATIONS, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_DECLARATIONS_V2_BY_PATIENT_PESEL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_DECLARATIONS_V2_BY_PATIENT_PESEL.replace('{domainId}', payload.domainId).replace('{pesel}', payload.pesel),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PATIENT_SET_DECLARATIONS, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_DECLARATION_V2_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_DECLARATION_V2_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PUT_DECLARATION_V2](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_PUT_DECLARATION_V2.replace('{id}', payload.id),
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DELETE_DECLARATION_V2](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_DELETE_DECLARATION_V2.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_POST_DECLARATION_V2](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_DECLARATION_V2,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_DECLARATIONS_V2_STATS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_GET_DECLARATIONS_V2_STATS,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_FILTER_DECLARATIONS_V2](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_FILTER_DECLARATIONS_V2,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_FILTER_DECLARATIONS_V2_WITH_ERRORS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_FILTER_DECLARATIONS_V2_WITH_ERRORS,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DECLARATIONS_V2_MULTI_CANCEL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_DECLARATIONS_V2_MULTI_CANCEL,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DECLARATIONS_V2_ADD_FILE](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('declarationId', form.declarationId)
                    payload.append('uploadedFile', form.file)
                    payload.append('info', form.info)

                    axios({
                        method: 'post',
                        url: Apis.PATIENT_DECLARATIONS_V2_ADD_FILE,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.PATIENT_CHANGES_DECLARATIONS_V2_BY_PATIENT_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_CHANGES_DECLARATIONS_V2_BY_PATIENT_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_CHANGES_DECLARATIONS_V2_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_CHANGES_DECLARATIONS_V2_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_GET_DRUGS_INFO_BY_PATIENT_ID](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PD_GET_DRUGS_INFO_BY_PATIENT_ID.replace('{patientId}', patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_GET_DIAGNOSES_INFO_BY_PATIENT_ID](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PD_GET_DIAGNOSES_INFO_BY_PATIENT_ID.replace('{patientId}', patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_GET_DIAGNOSES_WITH_DRUGS_BY_PATIENT_ID](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PD_GET_DIAGNOSES_WITH_DRUGS_BY_PATIENT_ID.replace('{patientId}', patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_UPDATE_DRUG_INFO](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PD_UPDATE_DRUG_INFO.replace('{patientId}', payload.patientId).replace('{baseVersion}', payload.baseVersion),
                        data: payload.drugInfo,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_UPDATE_DIAGNOSE_INFO](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PD_UPDATE_DIAGNOSE_INFO.replace('{patientId}', payload.patientId).replace('{baseVersion}', payload.baseVersion),
                        data: payload.diagnoseInfo,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PROGRAM_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_PROGRAM_ADD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_PROGRAM_FILTER,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_PROGRAM_PUT.replace('{id}', payload.id),
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_PROGRAM_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_GET_PROGRAM_WITH_PATIENTS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PROGRAM_GET_PROGRAM_WITH_PATIENTS.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_GET_MANY_PROGRAMS_WITH_PATIENTS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_PROGRAM_GET_MANY_PROGRAMS_WITH_PATIENTS,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_GET_FOR_PATIENT](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PROGRAM_GET_FOR_PATIENT.replace('{id}', patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PATIENT_SET_PROGRAMS, response.data)
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_GET_FOR_MANY_PATIENTS](context, patientsIds) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_PROGRAM_GET_FOR_MANY_PATIENTS,
                        data: patientsIds,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /**
         * Returns array of objects:
         * @returns patientPrograms - array of patient program api objects grouped by 'programId' property
         * @returns program - program realization object, taken from 'programId' property
         * @returns programConfig - program config object, taken from program realization' 'programConfig' property
         * @param {int} - PatientId
         */
        [Action.PATIENT_PROGRAM_GET_WITH_CYCLES](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PROGRAM_GET_WITH_CYCLES.replace('{patientId}', patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PATIENT_SET_PROGRAMS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_ADD_EVENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_PROGRAM_ADD_EVENT.replace('{id}', payload.id),
                        data: payload.event,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_EDIT_EVENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_PROGRAM_EDIT_EVENT.replace('{id}', payload.id),
                        data: payload.event,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PROGRAM_STATS_BY_PROGRAM](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PROGRAM_STATS_BY_PROGRAM.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PATIENT_SET_PROGRAMS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_RANDOM_BY_AGE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_GET_RANDOM_BY_AGE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //LOG APPOINTMENT
        [Action.PATIENT_UPDATE_LOG_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_UPDATE_LOG_APPOINTMENT.replace('{id}', payload.id),
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_CREATE_LOG_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_CREATE_LOG_APPOINTMENT,
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DELETE_LOG_APPOINTMENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_DELETE_LOG_APPOINTMENT.replace('{id}', id),
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_LOG_APPOINTMENT_BY_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_LOG_APPOINTMENT_BY_APPOINTMENT.replace('{domainId}', payload.domainId).replace('{appointmentId}', payload.appointmentId).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_LOG_APPOINTMENT_BY_PATIENT](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_LOG_APPOINTMENT_BY_PATIENT.replace('{id}', patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_LOG_APPOINTMENT_BY_EMPLOYEE](context, employeeId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_LOG_APPOINTMENT_BY_EMPLOYEE.replace('{id}', employeeId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_GET_LOG_APPOINTMENT_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_LOG_APPOINTMENT_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // ****************************************************************************************
        // PATIENT ALERTS
        // ****************************************************************************************

        [Action.PATIENT_ALERT_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_ALERT_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ALERT_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_ALERT_ADD,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ALERT_PUT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_ALERT_GET_BY_ID.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ALERT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_ALERT_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ALERT_ADD_STATUS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_ALERT_ADD_STATUS.replace('{taskId}', payload.taskId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ALERT_GET_FOR_PATIENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_ALERT_GET_FOR_PATIENT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ALERT_GET_BY_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_ALERT_GET_BY_FILTER,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_IPOM_BUNDLE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_IPOM_BUNDLE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /*********************************
         * 
         * PATIENT REPORT
         * 
        **********************************/

        [Action.PATIENT_REPORT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_REPORT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_REPORT_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_REPORT_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_REPORT_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_REPORT_GET_ALL,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_REPORT_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_REPORT_GET.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_REPORT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_REPORT_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_REPORT_GET_BY_PATIENT_ID](context, filter) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_REPORT_GET_BY_PATIENT_ID.replace('{domain}', filter.domain).replace('{patientId}', filter.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_REPORT_GET_BY_APPOINTMENT_ID](context, filter) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_REPORT_GET_BY_APPOINTMENT_ID.replace('{domain}', filter.domain).replace('{patientId}', filter.patientId).replace('{appid}', filter.appid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_REPORT_REMOVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_REPORT_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_REPORT_RESTORE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_REPORT_RESTORE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },





        [Action.PRIVILEGE_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PRIVILEGE_TYPES_GENERAL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PRIVILEGES_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PRIVILEGE_TYPE_ADD](context, item) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PRIVILEGE_TYPES_GENERAL,
                        data: item,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PRIVILEGE_TYPE_MODIFY](context, item) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        data: item,
                        url: Apis.PRIVILEGE_TYPES_GENERAL_BY_ID.replace('{id}', item.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PRIVILEGE_TYPE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PRIVILEGE_TYPES_GENERAL_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },






        [Action.PATIENT_PRIVILEGE_ADD](context, item) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_PRIVILEGE_GENERAL,
                        data: item,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PRIVILEGE_MODIFY](context, item) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        data: item,
                        url: Apis.PATIENT_PRIVILEGE_GENERAL_BY_ID.replace('{id}', item.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PRIVILEGE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PRIVILEGE_GENERAL_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PRIVILEGE_GET_ACTIVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PRIVILEGE_ACTIVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_PRIVILEGE_GET_ALL](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PRIVILEGE_ALL.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_CREATE_MED_QUESTIONS_FROM_TEMPLATES](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_CREATE_MED_QUESTIONS_FROM_TEMPLATES,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.PATIENT_DOCTOR_NOTE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_DOCTOR_NOTE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_DOCTOR_NOTE_GET_ALL,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.PATIENT_DOCTOR_NOTE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_DOCTOR_NOTE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_GET_HISTORY_NOTE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_DOCTOR_NOTE_GET_HISTORY_NOTE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_GET_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_DOCTOR_NOTE_GET_BY_PATIENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_GET_SPECIAL_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_DOCTOR_NOTE_GET_SPECIAL_BY_PATIENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_GET_BY_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_DOCTOR_NOTE_GET_BY_APPOINTMENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId).replace('{appId}', payload.appId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_UPDATE_NODE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_DOCTOR_NOTE_UPDATE_NODE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_REMOVE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_DOCTOR_NOTE_REMOVE.replace('{id}', payload.id).replace('{appId}', payload.appId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_RESTORE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_DOCTOR_NOTE_RESTORE.replace('{id}', payload.id).replace('{appId}', payload.appId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_DOCTOR_NOTE_GET_BY_ID.replace('{id}', payload.id),
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_DELETE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_DOCTOR_NOTE_GET_BY_ID.replace('{id}', payload.id),
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_DOCTOR_NOTE_CONTENT_RESTORE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_DOCTOR_NOTE_CONTENT_RESTORE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_PATIENT_DATA_QUESTIONS_GET_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PD_PATIENT_DATA_QUESTIONS_GET_BY_PATIENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_PATIENT_DATA_QUESTIONS_GET_BY_REFERRAL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PD_PATIENT_DATA_QUESTIONS_GET_BY_REFERRAL.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId).replace('{referralId}', payload.referralId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_PATIENT_DATA_QUESTIONS_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PD_PATIENT_DATA_QUESTIONS_GET_ALL,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_PATIENT_DATA_QUESTIONS_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PD_PATIENT_DATA_QUESTIONS_GET.replace('{id}', payload.id),
                        data: payload,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_PATIENT_DATA_QUESTIONS_REMOVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PD_PATIENT_DATA_QUESTIONS_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PD_PATIENT_DATA_QUESTIONS_RESTORE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PD_PATIENT_DATA_QUESTIONS_RESTORE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ORDER_IMMUNIZATION_GET_BY_QUALIFICATION](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_ORDER_IMMUNIZATION_GET_BY_QUALIFICATION.replace('{qualificationId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ORDER_IMMUNIZATION_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_ORDER_IMMUNIZATION_GET_ALL,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ORDER_IMMUNIZATION_GET_BY_NURSE_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_ORDER_IMMUNIZATION_GET_BY_NURSE_APPOINTMENT.replace('{domain}', payload.domain).replace('{nurseAppId}', payload.nurseAppId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ORDER_IMMUNIZATION_GET_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_ORDER_IMMUNIZATION_GET_BY_PATIENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.PATIENT_ORDER_IMMUNIZATION_GET_BY_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_ORDER_IMMUNIZATION_GET_BY_APPOINTMENT.replace('{domain}', payload.domain).replace('{appId}', payload.appId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        }
    }
}