import Vue from 'vue'
import Vuex from 'vuex'
import cookies from 'vue-cookies'

import timeModule from './modules/time'
import statsModule from './modules/stats'
import identityModule from './modules/identity'
import workersModule from './modules/workers'
import patientsModule from './modules/patients'
import mobiModule from './modules/mobi'
import ordersModule from './modules/orders'
import registrationModule from './modules/registration'
import scheduleModule from './modules/schedule'
import scheduleModule2 from './modules/s2'
import pozModule from './modules/poz'
import clinicsModule from './modules/clinic'
import pharmaModule from './modules/pharma'
import nurseModule from './modules/nurse'
import eduModule from './modules/edu'
import inventoryModule from './modules/inventory'
import employeeModule from './modules/employee'
import testModule from './modules/test'
import rehabilitationModule from './modules/rehabilitation'
import ticketsModule from './modules/tickets'
import labModule from './modules/lab'
import configModule from './modules/config'
import crmModule from './modules/crm'
import devicesModule from './modules/devices'
import contractorsModule from './modules/contractors'
import tpkModule from './modules/tpkSzok'
import teleModule from './modules/teleSzok'
import employeePermissionModule from './modules/employee_permission.js'
import employeePositionModule from './modules/employee_position.js'
import financeModule from './modules/finance'
import risModule from './modules/ris'
import p12Module from './modules/p12'
import buildingsModule from './modules/buildings.js'
import officesModule from './modules/office.js'
import ewusModule from './modules/ewus.js'
import organizationalUnit from './modules/organizational_unit'
import uacModule from './modules/uac_plc'
import uacWebsockets from './modules/uac'
import cytoModule from './modules/cytology'
import nfzTypesModule from './modules/nfzTypes'
import resultsModule from './modules/results'
import slotModule from './modules/slot'
import zusModule from './modules/zus'
import attestationsModule from './modules/attestations'
import hubModule from './modules/hub'
import priceListModule from './modules/price_list'
import refferalModule from './modules/refferal'
import prescriptionModule from './modules/prescription'
import medQuestionsModule from './modules/med_questions'
import digitmedModules from './modules/digitmed'
import employeeFormulaModule from './modules/employee_formula'
import solabModule from './modules/solab'
import locals from './modules/locals'
import swx from './modules/swx'
import ezwm from './modules/ezwm'
import medServiceModule from './modules/med_service'
import programsModule from './modules/programs'
import programsAppointmentModule from './modules/programs_appointment'
import talk from './modules/talk'
import templateReferralsModule from './modules/template_referrals'
import diloModule from './modules/dilo'
import recommendationsModule from './modules/recommendations'
import ipomModule from './modules/ipom'
import cashRegistersModule from './modules/cash_registers'
import miscModule from './modules/misc'
import apkolceModule from './modules/apkolce'

import { Action } from './config_actions'
import { Mutation } from './config_mutations'
import { Cookies } from './config_cookies'
//import { RUN_MODE } from '@/store/config_apis'

Vue.use(Vuex)

// I've decided to split store into seperate modules, because many things 
// will be added over the time, and I don't want too much spaghetti here.
// Modules are NOT namespaced, and that means action/mutation names are unique.
// This allows names to be kept in ouside files (config_actions.js, config_mutations.js).

/**********************************************************************************************/

export default new Vuex.Store({
  modules: {
    identity: identityModule,
    time: timeModule,
    stats: statsModule,
    workers: workersModule,
    patients: patientsModule,
    mobi: mobiModule,
    registration: registrationModule,
    schedule: scheduleModule,
    s2: scheduleModule2,
    poz: pozModule,
    clinic: clinicsModule,
    pharma: pharmaModule,
    nurse: nurseModule,
    orders: ordersModule,
    edu: eduModule,
    inventory: inventoryModule,
    employee: employeeModule,
    test: testModule,
    rehabilitation: rehabilitationModule,
    tickets: ticketsModule,
    config: configModule,
    lab: labModule,
    crm: crmModule,
    devices: devicesModule,
    contractors: contractorsModule,
    tpkSzok: tpkModule,
    teleSzok: teleModule,
    employeePermission: employeePermissionModule,
    employeePosition: employeePositionModule,
    finance: financeModule,
    orgUnit: organizationalUnit,
    ris: risModule,
    p12: p12Module,
    buildings: buildingsModule,
    offices: officesModule,
    ewus: ewusModule,
    uac: uacModule,
    uac2: uacWebsockets,
    cytology: cytoModule,
    nfzTypes: nfzTypesModule,
    results: resultsModule,
    slots: slotModule,
    zus: zusModule,
    attestations: attestationsModule,
    hub: hubModule,
    priceList: priceListModule,
    refferal: refferalModule,
    prescription: prescriptionModule,
    medQuestions: medQuestionsModule,
    digitmed: digitmedModules,
    employeeFormula: employeeFormulaModule,
    solab: solabModule,
    locals: locals,
    swx: swx,
    ezwm: ezwm,
    medService: medServiceModule,
    programs: programsModule,
    programsAppointment: programsAppointmentModule,
    talk: talk,
    templateReferrals: templateReferralsModule,
    dilo: diloModule,
    recommendations: recommendationsModule,
    ipom: ipomModule,
    cashRegisters: cashRegistersModule,
    misc: miscModule,
    apkolce: apkolceModule
  },

  state: {
    actions: Action,
    mutations: Mutation,
    cookies: Cookies,

    cookiesAccepted: false,
    reloadPending: false,
    initialDataCount: 1,
    tpk: false,
    tele: false,
    device: null,
    enableEzwm: true,
    enableDilo: true  //RUN_MODE == 'T' || RUN_MODE == 'K'
  },

  mutations: {
    [Mutation.GENERAL_COOKIES_ACCEPT](currentState) {
      cookies.set(Cookies.SZOK_COOKIE_ACCEPTED, "accepted");
      currentState.cookiesAccepted = true
    },

    [Mutation.GENERAL_RESET](currentState) {
      currentState.commit(Mutation.EMPLOYEE_CLEAR)
    },

    [Mutation.TELE_SWITCH](currentState, value) {
      currentState.tele = value
    },

    [Mutation.INITIAL_LOADING](currentState, value) {
      currentState.initialDataCount = value
    },

    [Mutation.SET_RELOAD_PENDING](currentState, value) {
      currentState.reloadPending = value
    },

    [Mutation.SET_DEVICE_ID](currentState, value) {
      currentState.device = value
    },
  }
})
