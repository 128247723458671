import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { SERVICE_IDENTITY } from '@/store/config_apis'
const signalR = require("@microsoft/signalr");
import moment from 'moment';

export default {
    namespaced: false,

    state: {
        /**
         * Current connection.
         */
        connection: null,

        /**
         * Connecting indicator.
         */
        connecting: false,

        /**
         * Last update.
         */
        lastUpdate: moment(),

        /**
         * Last update.
         */
        auth: null,

        /**
         * Last update.
         */
        message: null,

        /**
         * Last update.
         */
        broadcast: null,
    },

    mutations: {
        [Mutation.UAC_NULLIFY_CONNECTION](state) {

            if (state.connection) {
                state.connection.stop()
            }

            state.connection = null
        },

        [Mutation.UAC_SET_CONNECTION](state, payload) {
            state.connection = payload

            state.connection.on("UacResponse", function (value, message) {
                state.message = message
                state.auth = value
            });
        },

        [Mutation.UAC_SET_CONNECTING_INDICATOR](state, payload) {
            state.connecting = payload
        },

        [Mutation.UAC_SET_CURRENT](state, payload) {
            state.current = payload
            state.lastUpdate = moment()
        },

        [Mutation.UAC_SET_LAST_UPDATE](state) {
            state.lastUpdate = moment()
        },
    },

    actions: {
        [Action.UAC_CREATE_CONNECTION](context) {

            context.commit(Mutation.UAC_SET_CONNECTING_INDICATOR, true)
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;

            return new Promise((resolve, reject) => {
                if (context.state.connection == null) {
                    // setup SignalR
                    let connection = new signalR.HubConnectionBuilder()
                        .withUrl(`${SERVICE_IDENTITY}/authub/uac`, { accessTokenFactory: () => token })
                        .configureLogging(signalR.LogLevel.Information)
                        .build();

                    context.commit(Mutation.UAC_SET_CONNECTION, connection)

                    // start connection
                    connection.start()
                        .then(() => {
                            context.commit(Mutation.UAC_SET_CONNECTING_INDICATOR, false)
                            resolve(context.state.connection)
                        })
                        .catch(function (error) {
                            setTimeout(function () {
                                context.dispatch(Action.UAC_CREATE_CONNECTION)
                            }, 4000);
                            reject(error)
                        });
                }
                else {
                    if (context.state.connection.state == 'Connected') {
                        resolve(context.state.connection)
                    }
                    else {
                        context.state.connection.start()
                            .then(() => {
                                context.commit(Mutation.UAC_SET_CONNECTING_INDICATOR, false)
                                resolve(context.state.connection)
                            })
                            .catch(function (error) {
                                context.commit(Mutation.UAC_NULLIFY_CONNECTION)

                                setTimeout(function () {
                                    context.dispatch(Action.UAC_CREATE_CONNECTION)
                                }, 4000);
                                reject(error)
                            });
                    }
                }
            })
        },

        [Action.UAC_ACTIVITY_SEND](context, data) {
            return new Promise((resolve, reject) => {

                let now = moment()
                let diff = now.diff(context.state.lastUpdate, 'miliseconds')

                if (diff > 2000 || data.force) {


                    context.commit(Mutation.UAC_SET_LAST_UPDATE)
                    context.state.connection.invoke("UacActivity", data.screen)
                        .then(() => {
                            resolve()
                        })
                        .catch(function (error) {
                            reject(error)
                        });
                }
                else {
                    resolve()
                }
            })
        },

        [Action.UAC_LOGOUT_CONNECTION](context, data) {
            return new Promise((resolve, reject) => {
                context.state.connection.invoke("LogoutUser", data.id, data.message)
                    .then(() => {
                        resolve()
                    })
                    .catch(function (error) {
                        reject(error)
                    });
            })
        },
    }
}