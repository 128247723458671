import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        populationConfig: [],

        events: [],
        profiles: [],

        activePath:null,
        patientTickets:[],
        currentIpomEvents:[]
    },

    mutations: {
        [Mutation.PROGRAMS_POPULATION_CONFIG_SET_ALL](currentState, payload) {
            currentState.populationConfig = payload
        },
        
        [Mutation.PROGRAMS_POPULATION_EVENT_SET_ALL](currentState, payload) {
            currentState.events = payload
        },

        [Mutation.PROGRAMS_POPULATION_PROFILE_SET_ALL](currentState, payload) {
            currentState.profiles = payload
        },

        [Mutation.PROGRAMS_POPULATION_SET_ACTIVE_PATH](currentState, payload) {
            currentState.activePath = payload
        },

        [Mutation.PROGRAMS_POPULATION_SET_PATIENT_TICKETS](currentState, payload) {
            currentState.patientTickets = payload ?? []
        },

        [Mutation.PROGRAMS_POPULATION_EVENT_TO_IPOM_RESET](currentState){ 
            currentState.currentIpomEvents = []
        },

        [Mutation.PROGRAMS_POPULATION_ADD_EVENT_TO_IPOM](currentState,payload){            
            if(currentState.currentIpomEvents == null) currentState.currentIpomEvents = []

            if(payload.programId != null && payload.event != null){
                if(!currentState.currentIpomEvents.some((item)=>{ return (item.programId == payload.programId && item.event.internalId == payload.event.internalId ) })){
                    currentState.currentIpomEvents.push(payload)
                }
            }
        },

        [Mutation.PROGRAMS_POPULATION_RESET_IPOM](currentState){
            if(currentState.currentIpomEvents){
                currentState.currentIpomEvents.length = 0
            }else{
                currentState.currentIpomEvents = []
            }

            if(currentState.patientTickets){
                currentState.patientTickets.length = 0
            }else{
                currentState.patientTickets = []
            }
        }
    },

    actions: {

        // ****************************************************************************************
        // PROGRAMS POPULATION
        // ****************************************************************************************

        /** Attempt get all programs. Uses GET request without parameter */
        [Action.PROGRAMS_POPULATION_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_GET_FOR_CONFIG](context, internalId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_GET_FOR_CONFIG.replace('{internalId}', internalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_GET_BY_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PROGRAMS_POPULATION_GET_BY_FILTER,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add program. Uses POST request and requires program data object (check config_apis.js) */
        [Action.PROGRAMS_POPULATION_CREATE](context, program) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PROGRAMS_POPULATION_GET_ALL,
                        data: program,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program edit. Uses PUT request with id and requires program data object (check config_apis.js)*/
        [Action.PROGRAMS_POPULATION_UPDATE](context, program) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PROGRAMS_POPULATION_GET.replace('{id}', program.id),
                        data: program,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program delete. Uses DELETE request */
        [Action.PROGRAMS_POPULATION_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PROGRAMS_POPULATION_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // ****************************************************************************************
        // PROGRAMS POPULATION CONFIG
        // ****************************************************************************************

        /** Attempt get all programs. Uses GET request without parameter */
        [Action.PROGRAMS_POPULATION_CONFIG_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PROGRAMS_POPULATION_CONFIG_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_CONFIG_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_CONFIG_GET_BY_INTERNAL_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_GET_BY_INTERNAL_ID.replace('{internalId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add program config. Uses POST request and requires program config data object (check config_apis.js) */
        [Action.PROGRAMS_POPULATION_CONFIG_CREATE](context, programConfig) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_GET_ALL,
                        data: programConfig,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program edit. Uses PUT request with id and requires program data object (check config_apis.js)*/
        [Action.PROGRAMS_POPULATION_CONFIG_UPDATE](context, program) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_GET.replace('{id}', program.id),
                        data: program,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program delete. Uses DELETE request */
        [Action.PROGRAMS_POPULATION_CONFIG_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt program event add. Uses POST request */
        [Action.PROGRAMS_POPULATION_CONFIG_ADD_EVENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_ADD_EVENT.replace('{internalId}', payload.internalId),
                        data: payload.event,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt program event update. Uses POST request */
        [Action.PROGRAMS_POPULATION_CONFIG_UPDATE_EVENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_UPDATE_EVENT.replace('{internalId}', payload.internalId).replace('{eventId}', payload.eventId),
                        data: payload.event,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt program event delete. Uses DELETE request */
        [Action.PROGRAMS_POPULATION_CONFIG_DELETE_EVENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_DELETE_EVENT.replace('{internalId}', payload.internalId).replace('{eventId}', payload.eventId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        
        /** Attempt program get events. Uses GET request */
        [Action.PROGRAMS_POPULATION_CONFIG_GET_EVENTS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_GET_EVENTS.replace('{internalId}', payload.internalId).replace('{type}', payload.type),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        // ****************************************************************************************
        // PROGRAMS POPULATION ATTACHMENT
        // ****************************************************************************************

        [Action.PROGRAMS_POPULATION_ATTACHMENT_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('domainId', form.domainId)
                    payload.append('type', form.type)
                    payload.append('subType', form.subType)
                    payload.append('employee', form.employee)
                    payload.append('programId', form.programId)
                    payload.append('programConfigId', form.programConfigId)
                    payload.append('name', form.name)
                    payload.append('description', form.description)
                    payload.append('file', form.file)

                    axios({
                        method: 'post',
                        url: Apis.PROGRAMS_POPULATION_ATTACHMENT_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.PROGRAMS_POPULATION_ATTACHMENT_DOWNLOAD](context, fileId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.PROGRAMS_POPULATION_ATTACHMENT_DOWNLOAD.replace('{id}', fileId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all programs. Uses GET request without parameter */
        [Action.PROGRAMS_POPULATION_ATTACHMENT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_ATTACHMENT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_ATTACHMENT_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_ATTACHMENT_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_ATTACHMENT_GET_FOR_PROGRAM](context, internalId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_ATTACHMENT_GET_FOR_PROGRAM.replace('{internalId}', internalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_ATTACHMENT_GET_FOR_PROGRAM_CONFIG](context, internalId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_ATTACHMENT_GET_FOR_PROGRAM_CONFIG.replace('{internalId}', internalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program edit. Uses PUT request with id and requires program data object (check config_apis.js)*/
        [Action.PROGRAMS_POPULATION_ATTACHMENT_UPDATE](context, program) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PROGRAMS_POPULATION_ATTACHMENT_GET.replace('{id}', program.id),
                        data: program,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program delete. Uses DELETE request */
        [Action.PROGRAMS_POPULATION_ATTACHMENT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PROGRAMS_POPULATION_ATTACHMENT_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        // ****************************************************************************************
        // PROGRAMS POPULATION EVENT
        // ****************************************************************************************

        /** Attempt get all programs. Uses GET request without parameter */
        [Action.PROGRAMS_POPULATION_EVENT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_EVENT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_EVENT_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_EVENT_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add program. Uses POST request and requires program data object (check config_apis.js) */
        [Action.PROGRAMS_POPULATION_EVENT_CREATE](context, program) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PROGRAMS_POPULATION_EVENT_GET_ALL,
                        data: program,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program edit. Uses PUT request with id and requires program data object (check config_apis.js)*/
        [Action.PROGRAMS_POPULATION_EVENT_UPDATE](context, program) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PROGRAMS_POPULATION_EVENT_GET.replace('{id}', program.id),
                        data: program,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program delete. Uses DELETE request */
        [Action.PROGRAMS_POPULATION_EVENT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PROGRAMS_POPULATION_EVENT_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // ****************************************************************************************
        // PROGRAMS POPULATION EVENT TYPE
        // ****************************************************************************************

        /** Attempt get all programs. Uses GET request without parameter */
        [Action.PROGRAMS_POPULATION_EVENT_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_EVENT_TYPE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_EVENT_TYPE_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_EVENT_TYPE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add program. Uses POST request and requires program data object (check config_apis.js) */
        [Action.PROGRAMS_POPULATION_EVENT_TYPE_CREATE](context, program) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PROGRAMS_POPULATION_EVENT_TYPE_GET_ALL,
                        data: program,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program edit. Uses PUT request with id and requires program data object (check config_apis.js)*/
        [Action.PROGRAMS_POPULATION_EVENT_TYPE_UPDATE](context, program) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PROGRAMS_POPULATION_EVENT_TYPE_GET.replace('{id}', program.id),
                        data: program,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program delete. Uses DELETE request */
        [Action.PROGRAMS_POPULATION_EVENT_TYPE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PROGRAMS_POPULATION_EVENT_TYPE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // ****************************************************************************************
        // PROGRAMS POPULATION CONFIG EVENT
        // ****************************************************************************************

        /** Attempt get all programs. Uses GET request without parameter */
        [Action.PROGRAMS_POPULATION_CONFIG_EVENT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_EVENT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PROGRAMS_POPULATION_EVENT_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_CONFIG_EVENT_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_EVENT_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_CONFIG_EVENT_GET_BY_INTERNAL](context, internalId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_EVENT_GET_BY_INTERNAL.replace('{id}', internalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_CONFIG_EVENT_GET_BY_PROGRAM_CONFIG](context, internalId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_EVENT_GET_BY_PROGRAM_CONFIG.replace('{internalId}', internalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add program. Uses POST request and requires program data object (check config_apis.js) */
        [Action.PROGRAMS_POPULATION_CONFIG_EVENT_CREATE](context, programConfigEvent) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_EVENT_GET_ALL,
                        data: programConfigEvent,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt program edit. Uses PUT request with id and requires program data object (check config_apis.js)*/
        [Action.PROGRAMS_POPULATION_CONFIG_EVENT_UPDATE](context, programConfigEvent) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_EVENT_GET.replace('{id}', programConfigEvent.id),
                        data: programConfigEvent,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program delete. Uses DELETE request */
        [Action.PROGRAMS_POPULATION_CONFIG_EVENT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_EVENT_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // ****************************************************************************************
        // PROGRAMS POPULATION CONFIG PROFILE
        // ****************************************************************************************

        /** Attempt get all programs. Uses GET request without parameter */
        [Action.PROGRAMS_POPULATION_CONFIG_PROFILE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_PROFILE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PROGRAMS_POPULATION_PROFILE_SET_ALL, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_CONFIG_PROFILE_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_PROFILE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_INTERNAL](context, internalId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_INTERNAL.replace('{id}', internalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get program. Uses GET request */
        [Action.PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_PROGRAM_CONFIG](context, internalId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_PROGRAM_CONFIG.replace('{internalId}', internalId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add program. Uses POST request and requires program data object (check config_apis.js) */
        [Action.PROGRAMS_POPULATION_CONFIG_PROFILE_CREATE](context, programConfigProfile) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_PROFILE_GET_ALL,
                        data: programConfigProfile,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt program edit. Uses PUT request with id and requires program data object (check config_apis.js)*/
        [Action.PROGRAMS_POPULATION_CONFIG_PROFILE_UPDATE](context, programConfigProfile) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_PROFILE_GET.replace('{id}', programConfigProfile.id),
                        data: programConfigProfile,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        /** Attempt program delete. Uses DELETE request */
        [Action.PROGRAMS_POPULATION_CONFIG_PROFILE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_PROFILE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /**
         * Returns array of ConfigProfile API objects with:
         * @returns eventPathExtras - array of profile events with fetched ConfigEvents API objects - eventObject - by internalId property
         * @param {string} - PatientProgram GUID
         */
        [Action.PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_PATIENT_PROGRAM_ID](context, patientProgramId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_PATIENT_PROGRAM_ID.replace('{id}', patientProgramId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        }

    }
}


