import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        focus: null,
        dekleFocus: null,
    },

    mutations: {
        [Mutation.SWX_SET_FOCUS](state, payload) {
            state.focus = payload
        },
        [Mutation.DEKLE_SET_FOCUS](state, payload) {
            state.dekleFocus = payload
        },
    },

    actions: {

        // DEKLE

        //get all
        [Action.DEKLE_GET_ALL](context, domain) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.DEKLE_GET_FOR_DOMAIN.replace('{domain}', domain),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RIS_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //get by id
        [Action.DEKLE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.DEKLE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.DEKLE_PROGRESS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.DEKLE_PROGRESS.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //xml by id
        [Action.DEKLE_DOWNLOAD](context, item) {
            var fileDownload = require('js-file-download');
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.DEKLE_DOWNLOAD.replace('{id}', item.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        fileDownload(response.data, item.name)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        // upload
        [Action.DEKLE_RESPONSE_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('id', form.id)
                    payload.append('file', form.file)

                    axios({
                        method: 'post',
                        url: Apis.DEKLE_RESPONSE_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.DEKLE_CREATE](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.DEKLE_GET_ALL_OR_CREATE,
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.FINANCE_CONTRACTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // upload
        [Action.RIS_SWX_RESPONSE_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('swxId', form.swxId)
                    payload.append('file', form.file)

                    axios({
                        method: 'post',
                        url: Apis.RIS_SWX_RESPONSE_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        // upload multi
        [Action.RIS_SWX_MULTI_RESPONSE_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();

                    if (form.files && form.files.length > 0) {

                        payload.append(`domain`, form.domain)

                        for (let index = 0; index < form.files.length; index++) {
                            const element = form.files[index];
                            payload.append(`files`, element)
                        }

                        axios({
                            method: 'post',
                            url: Apis.RIS_SWX_MULTI_RESPONSE_UPLOAD,
                            headers: { Authorization: "Bearer " + token },
                            data: payload
                        }).then(response => {
                            resolve(response.data)
                        }).catch(error => {
                            reject(error)
                        });
                    }
                    else reject('brak plików')
                } else reject('401')
            })
        },

        //get all
        [Action.RIS_SWX_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_SWX_GENERAL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RIS_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //get by id
        [Action.RIS_SWX_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_SWX_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_SWX_GET_BY_DOMAIN](context, body) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_SWX_GET_BY_DOMAIN.replace('{domain}', body.domain).replace('{year}', body.year).replace('{month}', body.month),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.UMX_GET_ACTIVE_WITH_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.UMX_GET_ACTIVE_WITH_ID.replace('{domain}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.UMX_ACTIVE_FOR_SWD](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.UMX_ACTIVE_FOR_SWD.replace('{swd}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_SWX_UPDATE](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RIS_SWX_GET_BY_ID.replace('{id}', model.id),
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.FINANCE_CONTRACTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //xml by id
        [Action.RIS_SWX_PREVALIDATE](context, item) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;

                let id = item.id
                let mode = item.mode

                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_SWX_PREVALIDATE.replace('{id}', id).replace('{mode}', mode),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //xml by id
        [Action.RIS_SWX_GENERATE_XML](context, item) {
            var fileDownload = require('js-file-download');
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;

                let id = item.id
                let mode = item.mode

                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.RIS_SWX_GENERATE_XML.replace('{id}', id).replace('{mode}', mode),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {

                        var filename = item.id

                        if (item.display && item.display.length > 0) {
                            filename = item.display
                        }

                        fileDownload(response.data, filename + '.SWX')
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //mark sent
        [Action.RIS_SWX_NOTE](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RIS_SWX_NOTE,
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_SWX_CREATE](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_SWX_GENERAL,
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.FINANCE_CONTRACTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RIS_SWX_CREATE_FOR_CONTRACT](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_SWX_CREATE_FOR_CONTRACT,
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.FINANCE_CONTRACTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },







        [Action.ZBPOZ_CREATE](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.ZBPOZ_GENERAL,
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.FINANCE_CONTRACTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //get all
        [Action.ZBPOZ_GET_ALL](context, body) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.ZBPOZ_FILTER.replace('{domain}', body.domain).replace('{year}', body.year).replace('{month}', body.month),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.ZBPOZ_MODIFY](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.ZBPOZ_BY_ID.replace('{id}', model.id),
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //xml by id
        [Action.ZBPOZ_DOWNLOAD](context, id) {
            var fileDownload = require('js-file-download');
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.ZBPOZ_GENERATE_XML.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        fileDownload(response.data, id + '.PZX')
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },








        [Action.LIOCZ_CREATE](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.LIOCZ_GENERAL,
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.FINANCE_CONTRACTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //get all
        [Action.LIOCZ_GET_ALL](context, body) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.LIOCZ_BY_DOMAIN.replace('{domain}', body.domain).replace('{year}', body.year).replace('{month}', body.month),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.LIOCZ_MODIFY](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.LIOCZ_BY_ID.replace('{id}', model.id),
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //xml by id
        [Action.LIOCZ_DOWNLOAD](context, item) {
            var fileDownload = require('js-file-download');
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.LIOCZ_GENERATE_XML.replace('{id}', item.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {

                        var filename = item.id
                        var tobe = item.internalCode ? item.internalCode.toString() : null

                        if (tobe && tobe.length > 0) {
                            filename = tobe
                        }

                        fileDownload(response.data, filename + '.KLX')

                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


























        // upload
        [Action.NFZ_TEMPLATE_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('domain', form.domain)
                    payload.append('file', form.file)

                    axios({
                        method: 'post',
                        url: Apis.NFZ_TEMPLATE_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        //xml by id
        [Action.NFZ_TEMPLATE_DOWNLOAD](context, item) {
            var fileDownload = require('js-file-download');
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                let id = item.id

                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.NFZ_TEMPLATE_DOWNLOAD.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {

                        var filename = item.id
                        var tobe = item.templateId ? item.templateId.toString() : null

                        if (tobe && tobe.length > 0) {
                            filename = tobe
                        }

                        fileDownload(response.data, filename + '.ZRZ')
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.NFZ_INVOICE_GENERATE_XML](context, item) {
            var fileDownload = require('js-file-download');
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                let id = item.id

                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.NFZ_INVOICE_GENERATE_XML.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {

                        var filename = item.id
                        var tobe = item.internalCode ? item.internalCode : null

                        if (tobe && tobe.length > 0) {
                            filename = tobe
                        }

                        fileDownload(response.data, filename + '.EFX')
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //get all
        [Action.NFZ_TEMPLATE_GET_ALL](context, domain) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_TEMPLATE_GENERAL.replace('{domain}', domain),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //get by id
        [Action.NFZ_TEMPLATE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_TEMPLATE_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },





        [Action.NFZ_INVOICE_ADD_FROM_UMX](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_INVOICE_ADD_FROM_UMX.replace('{umxId}', payload.id).replace('{month}', payload.month),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


        [Action.NFZ_INVOICE_PREVIEW_FROM_TEMPLATE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_INVOICE_PREVIEW_FROM_TEMPLATE.replace('{templateId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },



        [Action.NFZ_INVOICE_ADD_FROM_TEMPLATE](context, model) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.NFZ_INVOICE_ACCEPT_FROM_TEMPLATE,
                        data: model,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.NFZ_INVOICE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_INVOICE_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //get all
        [Action.NFZ_INVOICE_FILTER](context, pay) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.NFZ_INVOICE_FILTER.replace('{domain}', pay.domain).replace('{year}', pay.year).replace('{month}', pay.month).replace('{type}', pay.type),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

    }
}