import Vue from 'vue'

// Vue doesn't publicly export this constructor
const Observer = Object.getPrototypeOf(Vue.observable({}).__ob__).constructor;

export function blockObserverMethod (obj) {
    if (obj && !Object.prototype.hasOwnProperty.call(obj, '__ob__')) {
      Object.defineProperty(obj, '__ob__', {
      value: new Observer({}),
      enumerable: false,
      configurable: false,
      writeable: false,
    });
  }

  return obj;
}
