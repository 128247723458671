import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        diloCards:[],
        cancelCards:[]
    },

    mutations: {
        [Mutation.DILO_ADD](currentState, payload){
            currentState.diloCards.push(payload);
        },

        [Mutation.DILO_UPDATE](currentState, payload){
            currentState.diloCards[payload.index] = payload.order
        },

        [Mutation.DILO_REMOVE](currentState, payload) {
            let index = currentState.diloCards.indexOf(payload)

            if (index > -1) {
                currentState.diloCards.splice(index, 1)
            }
        },

        [Mutation.DILO_CANCEL_ADD](currentState,payload){
            currentState.cancelCards.push(payload)
        },

        [Mutation.DILO_RESET](currentState){
            currentState.diloCards =[],
            currentState.cancelCards = []
        }
    },

    actions: {
        [Action.DILO_GET](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.DILO_GET,
                        headers: { Authorization: "Bearer " + token },
                        data: payload,
                        params:{
                            id:payload.id
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.DILO_SEND](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.DILO_SEND,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.DILO_DOWNLOAD_RESULT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.DILO_DOWNLOAD_RESULT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.DILO_WORKERCREDS_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.DILO_WORKERCREDS_CREATE,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.DILO_WORKERCREDS_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.DILO_WORKERCREDS_UPDATE,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.DILO_WORKERCREDS_GET](context, workerGuid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.DILO_WORKERCREDS_GET,
                        headers: { Authorization: "Bearer " + token },
                        params:{
                            workerGuid:workerGuid
                        }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //DILO_WORKERCREDS_ADD_CONFIGURATION
        [Action.DILO_WORKERCREDS_ADD_CONFIGURATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.DILO_WORKERCREDS_ADD_CONFIGURATION,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.DILO_WORKERCREDS_UPDATE_CONFIGURATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.DILO_WORKERCREDS_UPDATE_CONFIGURATION,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.DILO_WORKERCREDS_SETACTIVE_CONFIGURATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.DILO_WORKERCREDS_SETACTIVE_CONFIGURATION,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.DILO_WORKERCREDS_GET_BY_WORKER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.DILO_WORKERCREDS_GET_BY_WORKER.replace('{workerId}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.DILO_WORKERCREDS_UPDATE_PASSWORD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.DILO_WORKERCREDS_UPDATE_PASSWORD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}