import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'
import cookies from 'vue-cookies'
import { Cookies } from '../store/config_cookies'
import { Mutation } from '../store/config_mutations'
import routes from './config_routes_3'

Vue.use(VueRouter)

const router = new VueRouter({ routes })


// // Route guard!
// router.beforeEach((to, from, next) => {
//   store.commit("ROUTER_SWITCH", true)
//   store.commit("ROUTER_LABEL", to.meta.desc ?? "SZOK")

//   let isAuth = store.getters.isUserAuthorized
//   let isAdmin = store.getters.isAdmin
//   let allowedAnonymous = ['login', 'reset-password', 'reset-password-request']
//   if (allowedAnonymous.includes(to.name)) {
//     next()
//   }
//   else {

//     if (isAuth) {

//       if (to.name.includes('admin')) {
//         if (isAdmin) {
//           next()
//         }
//         else {
//           next({ name: 'home' })
//         }
//       }
//       else {
//         next()
//       }
//     }
//     else {
//       let cookieUser = cookies.get(Cookies.SZOK_USER_AUTH);

//       if (cookieUser) {
//         store.commit(Mutation.IDENTITY_OPEN_SESSION, cookieUser)
//         next()
//       }
//       else {
//         next({ name: 'login' })
//       }
//     }
//   }

// })

// router.afterEach(() => {
//   store.commit("ROUTER_SWITCH", false)
// })







// Route guard!
router.beforeEach((to, from, next) => {
  store.commit("ROUTER_SWITCH", true)
  store.commit("ROUTER_LABEL", to.meta.desc ?? "SZOK")

  let isAuth = store.getters.isUserAuthorized
  let isAdmin = store.getters.isAdmin
  let allowedAnonymous = ['login', 'reset-password', 'reset-password-request']
  if (allowedAnonymous.includes(to.name)) {
    next()
  }
  else {

    let allowAccess = false
    let pendingLogout = false

    if (isAuth) {
      // if (store.state.employee && store.state.employee.permits) {
      //   if (store.state.employee.permits.admin || store.state.employee.permits.subadmin) {
      //     allowAccess = true
      //   }
      // }

      if (store.state && store.state.employee && store.state.employee.me && to.meta.permits) {
        to.meta.permits.forEach(permit => {
          if (store.state.employee.permits[permit]) {
            allowAccess = true
          }
        });
      }

      if (to.meta.permits && to.meta.permits.length == 0) {
        allowAccess = true
      }

      if (to.name.includes('admin')) {
        if (isAdmin) {
          allowAccess = true
        }
        else {
          next({ name: 'home' })
        }
      }
    }
    else {
      let cookieUser = cookies.get(Cookies.SZOK_USER_AUTH);

      if (cookieUser) {
        store.commit(Mutation.IDENTITY_OPEN_SESSION, cookieUser)
        allowAccess = true
      }
      else {
        pendingLogout = true
        next({ name: 'login' })
      }
    }

    if (allowAccess) {
      // access to view granted
      next()
    }
    else {
      if (!pendingLogout) {
        window.alert('Brak wymaganych uprawnień do modułu: ' + to.meta.desc)
      }

      store.commit("ROUTER_SWITCH", false)
    }
  }

})

router.afterEach((to) => {
  store.commit("ROUTER_SWITCH", false)
  let allowedAnonymous = ['login', 'reset-password', 'reset-password-request']

  if (to.name != 'home' && !allowedAnonymous.includes(to.name)) {
    setTimeout(() => {
      let allowAccess = false

      // if (store.state.employee && store.state.employee.permits) {
      //   if (store.state.employee.permits.admin || store.state.employee.permits.subadmin) {
      //     allowAccess = true
      //   }
      // }

      if (store.state && store.state.employee && store.state.employee.me && to.meta.permits) {
        to.meta.permits.forEach(permit => {
          if (store.state.employee.permits[permit]) {
            allowAccess = true
          }
        });
      }

      if (to.meta.permits && to.meta.permits.length == 0) {
        allowAccess = true
      }

      if (!allowAccess) {
        window.alert('Brak wymaganych uprawnień do modułu: ' + to.meta.desc)
        router.push({ name: 'home' })
      }

    }, 1000);
  }
})

export default router

