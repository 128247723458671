export default {
  methods: {
    getEwusStatus(id) {
      switch (id) {
        case 0: return 'Błąd!'
        case 1: return 'Ubezpieczony'
        case 2: return 'Ubezpieczony/Kwarantanna'
        case 3: return 'Brak ubezpieczenia'
        case 4: return 'Brak ubezpieczenia/Kwarantanna'
        case 5: return 'Niepoprawny PESEL'
        case 6: return 'W trakcie...'
        case 7: return 'Błąd - nie znaleziono pracownika'
        case 8: return 'Ubezpieczony (UKR)'
        case 9: return 'Ubezpieczony/Kwarantanna (UKR)'
        case 10: return 'Ubezpieczony/Izolacja domowa (UKR)'
        case 11: return 'Ubezpieczony/Izolacja domowa'
        default: return 'Brak informacji'
      }
    },
    getEwusType(id) {
      switch (id) {
        case 0: return 'is-danger'
        case 1: return 'is-success'
        case 2: return 'is-warning'
        case 3: return 'is-danger'
        case 4: return 'is-danger'
        case 5: return 'is-danger'
        case 7: return 'is-danger'
        default: return 'is-dark'
      }
    },
    getEwusTextColor(id) {
      switch (id) {
        case 0: return 'has-text-danger'
        case 1: return 'has-text-forest'
        case 2: return 'has-text-warning'
        case 3: return 'has-text-danger'
        case 4: return 'has-text-danger'
        case 7: return 'has-text-danger'
        default: return 'has-text-dark'
      }
    },
    getEwusProblem(id) {
      switch (id) {
        case 0: return '0'
        case 1: return '1'
        case 2: return '2'
        case 3: return '3'
        default: return 'Brak'
      }
    },
    getEwusDN(id) {
      switch (id) {
        case 1: return 'Tak'
        case 2: return 'Nie'
        default: return 'Brak informacji'
      }
    }
  }
}