<template>
  <div class="columns">
    <div class="column is-3">
      <SzokTalkGroups @selected="groupdChanged"></SzokTalkGroups>
      <code v-if="connection">HUB: {{connection.state}}</code>
    </div>

    <div class="column">
      <div class="pr-2 pb-3">
        <SzokTalkConversation :group="selectedGroup"></SzokTalkConversation>
      </div>
    </div>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/commons'
import SzokTalkConversation from './SzokTalkConversation'
import SzokTalkGroups from './SzokTalkGroups'

export default {
  name: 'SzokTalkDashboard',
  mixins: [CommonMixins],

  components: {
    SzokTalkConversation,
    SzokTalkGroups
  },

  data: function () {
    return {

      /**
       * Currently selected group.
       */
      selectedGroup: null,
    }
  },

  computed: {

    /**
     * Current connection.
     */
    connection() { return this.$store.state.talk.connection },

    /**
     * User groups.
     */
    groups() { return this.$store.state.talk.groups },
  },

  methods: {

    /**
     * On group changed.
     */
    groupdChanged(val) {
      this.selectedGroup = val
    },

  }
}
</script>

<style scoped lang="scss">
</style>
