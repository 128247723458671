export default {
  data: function () {
    return {
      specs: [
        {
          id: 0,
          name: 'Brak'
        },
        {
          id: 1,
          name: 'Rodzinny'
        },
        {
          id: 2,
          name: 'Kardiolog'
        },
        {
          id: 3,
          name: 'Położna'
        },
        {
          id: 4,
          name: 'Pielęgniarka'
        }
      ],


    }
  },

  methods: {
    getSpecs(id) {
      if (id) {
        let spec = this.specs.find(x => x.id === id)
        return spec ? spec.name : `[?] ${id}`
      }
      else return null
    }
  }
}
