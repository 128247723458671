<template>
  <div>
    <!-- <b-button size="is-small" @click="isActiveModalUpload = true">Dane teleadresowe</b-button> -->

    <b-button
      @click="isActiveModal = true"
      icon-left="clipboard-flow-outline" size="is-small"
      type="is-secondary"
      >Modyfikuj zlecenie</b-button
    >

    <b-modal
      v-model="isActiveModal"
      has-modal-card
      aria-role="dialog"
      aria-modal
      scroll="keep"
      :can-cancel="false"
      class="p-5"
      trap-focus
      full-screen
      :destroy-on-hide="false"
    >
      <template #default="props" v-if="translatedAppointment">
        <ValidationObserver ref="observerDoctor" v-slot="{ invalid }">
          <div class="modal-card" style="overflow: visible; width: auto;">
            <header class="modal-card-head has-background-kin">
              <p class="modal-card-title has-text-light">Modyfikuj zlecenie</p>
            </header>
            <section class="modal-card-body">
              <div class="columns is-centered">
                <div class="column ml-5 mr-5">
                  <ContentPanel
                    v-if="translatedAppointment.ticket && ikzAdded"
                    title="Usługa"
                    icon="format-list-bulleted-square"
                  >
                    <div style="min-height: 2.5rem">
                      <template slot="addon">
                        <b-tag
                          v-if="ikzAdded.length < 1"
                          type="is-danger"
                          rounded
                          >Wymagane</b-tag
                        >
                      </template>
                      <b-field
                        label="Szukaj usługi"
                        label-position="on-border"
                        open-on-focus
                        v-if="ikzAdded.length == 0"
                        :type="ikzAdded.length < 1 ? 'is-danger' : ''"
                      >
                        <b-autocomplete
                          :open-on-focus="true"
                          :data="filteredIkz"
                          clear-on-select
                          field="pharse"
                          placeholder="Zacznij pisać, aby wyświetlić sugerowane usługi"
                          @select="ikzSelected"
                          v-model="ikzFilter"
                        >
                          <template slot-scope="props">
                            <div style="white-space: pre-wrap">
                              <b-tag
                                v-if="props.option.icd9"
                                type="is-secondary"
                                class="mr-2"
                                >{{ props.option.icd9 }}</b-tag
                              >
                              <span style="has-text-weight-semibold">{{
                                props.option.name
                              }}</span>
                              <small class="ml-1">{{
                                props.option.code
                              }}</small>
                            </div>
                          </template>
                        </b-autocomplete>
                      </b-field>
                      <div
                        class="card p-1"
                        v-for="(item, index) in ikzAdded"
                        :key="item.index"
                      >
                        <b-tag
                          v-if="item.icd9"
                          type="is-secondary"
                          class="ml-1 mr-2"
                          >{{ item.icd9 }}</b-tag
                        >
                        <span style="has-text-weight-semibold">{{
                          item.name
                        }}</span>
                        <b-button
                          type="is-text"
                          size="is-small"
                          @click="removeIkz(index)"
                          >Usuń</b-button
                        >
                      </div>
                    </div>
                  </ContentPanel>

                  <RISWorkshopAddExamination
                    v-if="
                      selectClinicType != null &&
                      payerType != null
                    "
                    :selectClinicType="selectClinicType"
                    :readonly="false"
                    :deviceType="deviceType"
                    :payerType="payerType"
                    :examSuggestion="suggestedExaminationAdd"
                    :referralType="referralType ? referralType : {}"
                    @add-procedure="addExamination"
                  />

                  <ContentPanel title="Dodane badania" icon="plus-box">
                    <div v-for="(item, index) in ticketProcedures" :key="index">
                      <p class="mb-3">
                        {{ item.description }}
                        <b-button
                          class="ml-3"
                          type="is-danger"
                          icon-left="delete"
                          size="is-small"
                          @click="ticketProcedures.splice(index, 1)"
                          >Usuń</b-button
                        >
                      </p>
                      <b-field
                        v-if="
                          item.typeImage &&
                          item.typeImage.examinationType == 2 &&
                          item.params
                        "
                        class="card p-3 white-label"
                        label-position="on-border"
                        label="Projekcje"
                      >
                        <b-checkbox
                          v-for="(item2, index2) in mProjectionTypes"
                          :key="index2"
                          :native-value="item2.id"
                          size="is-small"
                          class="mr-3"
                          v-model="item.params"
                          >{{ item2.name }}</b-checkbox
                        >
                      </b-field>
                      <b-field
                        v-if="
                          item.typeImage &&
                          item.typeImage.options &&
                          item.typeImage.options.length > 0
                        "
                        :type="item.bodySide == null ? 'is-danger' : ''"
                        label="Strona"
                        label-position="on-border"
                      >
                        <b-select expanded v-model="item.bodySide">
                          <option
                            v-for="(item3, index3) in item.typeImage.options"
                            :key="index3"
                          >
                            {{ item3 }}
                          </option>
                        </b-select>
                      </b-field>
                      <hr />
                    </div>
                  </ContentPanel>
                </div>
                <div class="column ml-5 mr-5">
                  <ContentPanel title="Miejsce odesłania wyniku" icon="home" v-if="referralType">
                    <b-field>
                      <b-autocomplete
                        :data="facilitiesFiltered"
                        v-model="facilityFilter"
                        placeholder="Wybierz"
                        open-on-focus
                        clearable
                        field="name"
                        @select="
                          (option) =>
                            (resultSentToLocation = option
                              ? option.internalId
                              : null)
                        "
                      >
                        <template slot-scope="props">
                          <p>{{ props.option.name }}</p>
                        </template>
                      </b-autocomplete>
                    </b-field>
                  </ContentPanel>

                  <ContentPanel
                    title="Poradnia kierująca"
                    icon="medical-bag"
                    :borderless="false"
                    v-if="referralType"
                  >
                    <template slot="addon">
                      <b-field>
                        <b-checkbox
                          v-model="internalClinic"
                          :disabled="
                            appointmentType &&
                            appointmentType.apkolce &&
                            referralType &&
                            referralType.isPaperReferral == true &&
                            referralType.id == 0
                          "
                          type="is-orange"
                          :class="{
                            'has-text-weight-semibold': internalClinic,
                          }"
                        >
                          Poradnia wewnętrzna
                        </b-checkbox>
                      </b-field>
                    </template>
                    <div>
                      <b-field
                        label="Wybierz poradnię:"
                        v-if="internalClinic"
                        label-position="on-border"
                      >
                        <b-autocomplete
                          v-model="subunitName"
                          :open-on-focus="true"
                          :data="filteredSubunits"
                          :keep-first="true"
                          field="name"
                          @select="
                            (option) =>
                              option != null
                                ? (internalClinicId = option.id)
                                : (internalClinicId = null)
                          "
                          clearable
                          expanded
                        >
                          <template slot-scope="props">
                            <div style="white-space: pre-wrap">
                              <span
                                :class="{
                                  'has-text-success has-text-weight-bold':
                                    internalClinicId === props.option.id,
                                }"
                                >{{ props.option.name }}</span
                              >
                              <br />
                              <span>{{ props.option.facilityFullName }}</span>
                            </div>
                          </template>
                        </b-autocomplete>
                      </b-field>
                      <b-field
                        label="Poradnia kierująca"
                        v-else
                        label-position="on-border"
                      >
                        <b-input v-model="referralClinic"></b-input>
                      </b-field>
                    </div>
                  </ContentPanel>

                  <ContentPanel
                    title="Lekarz kierujący"
                    icon="briefcase-account"
                    :borderless="false"
                    v-if="referralType"
                  >
                    <template slot="addon">
                      <b-field>
                        <b-checkbox
                          v-model="internalDoctor"
                          type="is-orange"
                          :class="{
                            'has-text-weight-semibold': internalDoctor,
                          }"
                        >
                          Lekarz wewnętrzny
                        </b-checkbox>
                        <b-tag
                          v-if="
                            internalDoctor && referredBy < 0 && referralType
                          "
                          :class="{
                            'is-warning':
                              referralType.isDoctorRequired == false,
                            'is-danger': referralType.isDoctorRequired == true,
                          }"
                        >
                          {{
                            referralType.isDoctorRequired == false
                              ? "(Nie wymagane)"
                              : "(wymagane)"
                          }}
                        </b-tag>
                        <b-tag
                          v-if="
                            !internalDoctor &&
                            (referralDoctorFirstName.length <= 0 ||
                              referralDoctorLastName.length <= 0) &&
                            referralType
                          "
                          :class="{
                            'is-warning':
                              referralType.isDoctorRequired == false,
                            'is-danger': referralType.isDoctorRequired == true,
                          }"
                        >
                          {{
                            referralType.isDoctorRequired == false
                              ? "(Nie wymagane)"
                              : "(wymagane)"
                          }}
                        </b-tag>
                      </b-field>
                    </template>
                    <b-field v-if="internalDoctor">
                      <ValidationProvider
                        :rules="{
                          required:
                            referralType && referralType.isDoctorRequired,
                        }"
                        v-slot="{ errors, valid }"
                      >
                        <b-field
                          label="Lekarz"
                          label-position="on-border"
                          :type="{
                            'is-danger': errors[0],
                            'is-normal': valid,
                          }"
                          :message="t(errors[0])"
                        >
                          <b-autocomplete
                            :data="filteredEmployees"
                            v-model="employeeName"
                            :icon="
                              referralType && referralType.isDoctorRequired
                                ? 'alert-circle-outline'
                                : ''
                            "
                            :placeholder="
                              referralType && referralType.isDoctorRequired
                                ? 'Wyszukaj pracownika (wymagane)'
                                : 'Wyszukaj pracownika'
                            "
                            open-on-focus
                            clearable
                            field="name"
                            @select="
                              (option) =>
                                option != null
                                  ? (referredBy = option.id)
                                  : (referredBy = -1)
                            "
                            :keep-first="true"
                          >
                            <template slot-scope="props">
                              <span>
                                {{ props.option.name }} (PWZ:{{
                                  props.option.pwz
                                }})
                              </span>
                            </template>
                          </b-autocomplete>
                        </b-field>
                      </ValidationProvider>
                    </b-field>
                    <div v-else>
                      <b-field
                        label="Słownik zewnętrznych lekarzy"
                        label-position="on-border"
                        style="word-wrap: break-word; white-space: pre-wrap"
                      >
                        <b-autocomplete
                          v-model="externalDoctorFormulaFilter"
                          :data="externalDoctorFormulaFiltered"
                          expanded
                          placeholder="Wybierz lekarza..."
                          open-on-focus
                          clear-on-select
                          field="title"
                          @select="selectExternalDoctorFormula"
                        >
                          <template #empty>
                            <p>Brak zewnętrznych lekarzy</p>
                          </template>
                          <template slot-scope="props">
                            <p
                              v-if="props.option.title"
                              style="
                                word-wrap: break-word;
                                white-space: pre-wrap;
                                margin: 0;
                                padding: 0;
                              "
                              aria-multiline="true"
                            >
                              {{ props.option.title }}
                            </p>
                          </template>
                        </b-autocomplete>
                        <p class="control">
                          <b-tooltip label="Dodaj zewnętrznego lekarza">
                            <b-button
                              icon-left="plus"
                              @click="externalDoctorFormulaModal = true"
                              >Dodaj lekarza</b-button
                            >
                          </b-tooltip>
                        </p>
                      </b-field>
                      <b-field grouped>
                        <b-field expanded>
                          <ValidationProvider rules v-slot="{ errors, valid }">
                            <b-field
                              label="Tytuł"
                              label-position="on-border"
                              :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }"
                              :message="t(errors[0])"
                            >
                              <b-input v-model="referralDoctorPrefix"></b-input>
                            </b-field>
                          </ValidationProvider>
                        </b-field>
                        <b-field expanded>
                          <ValidationProvider
                            :rules="{
                              required:
                                appointmentType != -1 &&
                                appointmentType &&
                                appointmentType.apkolce &&
                                !apkolceReferralDataNotRequired,
                              pwznumber: true,
                            }"
                            v-slot="{ errors, valid }"
                          >
                            <b-field
                              label="PWZ"
                              label-position="on-border"
                              :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }"
                              :message="t(errors[0])"
                            >
                              <b-input
                                v-model="referralDoctorPwz"
                                :icon="
                                  appointmentType != -1 &&
                                  appointmentType &&
                                  appointmentType.apkolce &&
                                  !apkolceReferralDataNotRequired
                                    ? 'alert-circle-outline'
                                    : ''
                                "
                                :placeholder="
                                  appointmentType != -1 &&
                                  appointmentType &&
                                  appointmentType.apkolce &&
                                  !apkolceReferralDataNotRequired
                                    ? 'Wwymagane'
                                    : ''
                                "
                              ></b-input>
                            </b-field>
                          </ValidationProvider>
                        </b-field>
                      </b-field>
                      <b-field grouped>
                        <b-field expanded>
                          <ValidationProvider
                            :rules="{
                              required:
                                referralType && referralType.isDoctorRequired,
                              name: true,
                            }"
                            v-slot="{ errors, valid }"
                          >
                            <b-field
                              label="Imie:"
                              label-position="on-border"
                              :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }"
                              :message="t(errors[0])"
                              expanded
                            >
                              <b-input
                                :icon="
                                  referralType && referralType.isDoctorRequired
                                    ? 'alert-circle-outline'
                                    : ''
                                "
                                :placeholder="
                                  referralType && referralType.isDoctorRequired
                                    ? 'Wymagane'
                                    : ''
                                "
                                v-model="referralDoctorFirstName"
                                expanded
                              ></b-input>
                            </b-field>
                          </ValidationProvider>
                        </b-field>
                        <b-field expanded>
                          <ValidationProvider
                            :rules="{
                              required:
                                referralType && referralType.isDoctorRequired,
                              alpha_space_betwen_wordsPL: true,
                            }"
                            v-slot="{ errors, valid }"
                          >
                            <b-field
                              label="Nazwisko:"
                              label-position="on-border"
                              :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }"
                              :message="t(errors[0])"
                              expanded
                            >
                              <b-input
                                :icon="
                                  referralType && referralType.isDoctorRequired
                                    ? 'alert-circle-outline'
                                    : ''
                                "
                                :placeholder="
                                  referralType && referralType.isDoctorRequired
                                    ? 'Wymagane'
                                    : ''
                                "
                                expanded
                                v-model="referralDoctorLastName"
                              ></b-input>
                            </b-field>
                          </ValidationProvider>
                        </b-field>
                      </b-field>
                    </div>
                  </ContentPanel>

                  <template
                    v-if="
                      referralType &&
                      referralType.isContractorAllowed &&
                      appointmentType != -1 &&
                      appointmentType &&
                      !appointmentType.apkolce
                    "
                  >
                    <ContentPanel
                      title="Kontrahent"
                      icon="account-star"
                      :borderless="false"
                    >
                      <template slot="addon">
                        <b-field>
                          <b-checkbox
                            v-model="showAllContractors"
                            type="is-orange"
                            :class="{
                              'has-text-weight-semibold': showAllContractors,
                            }"
                          >
                            Wszyscy kontrahenci
                          </b-checkbox>
                        </b-field>
                      </template>
                      <b-field expanded>
                        <b-field
                          label="Kontrahent"
                          label-position="on-border"
                          expanded
                        >
                          <b-autocomplete
                            v-model="contractorInput"
                            :data="filteredSubjectsContractor"
                            clearable
                            field="nameFull"
                            :keep-first="true"
                            :open-on-focus="true"
                            @select="
                              (option) =>
                                (contractor = option ? option.id : null)
                            "
                          >
                            <template slot-scope="props">
                              <p
                                :class="{
                                  'has-text-success has-text-weight-bold':
                                    contractor === props.option.id,
                                }"
                              >
                                {{ props.option.nameFull }}
                              </p>
                              <p class="mt-0" v-if="props.option.nameShort">
                                Skrót: {{ props.option.nameShort }}
                              </p>
                            </template>
                          </b-autocomplete>
                        </b-field>
                      </b-field>
                    </ContentPanel>
                  </template>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <b-button icon-left="close" @click="props.close">Anuluj</b-button>
              <b-button
                :loading="isLoading"
                :disabled="
                  invalid ||
                  ikzAdded.length < 1 ||
                  ticketProcedures.length < 1 ||
                  (referralType &&
                    referralType.isDoctorRequired == true &&
                    internalDoctor &&
                    referredBy < 0)
                "
                @click="saveExam"
                type="is-primary"
                >Zapisz zmiany</b-button
              >
            </footer>
          </div>
        </ValidationObserver>
      </template>
    </b-modal>

    <ValidationObserver ref="observerExternalDoctor" v-slot="{ invalid }">
      <b-modal
        v-model="externalDoctorFormulaModal"
        has-modal-card
        aria-role="dialog"
        aria-modal
        trap-focus
        :destroy-on-hide="false"
        style="z-index: 200"
      >
        <div class="modal-card has-background-kin">
          <header class="modal-card-head has-background-kin">
            <p
              class="modal-card-title has-text-primelight"
              style="line-height: 2rem"
            >
              Dodawanie zewnętrznego lekarza do słownika
            </p>
          </header>
          <section class="modal-card-body">

                          <b-notification
                            :closable="false"
                            v-if="externalDoctorExists"
                            type="is-danger"
                            class="mb-3 p-2"
                            style="font-size: 0.9rem;"
                          >
                            Lekarz o podanym numerze PWZ został wprowadzony do słownika
                          </b-notification>

                          <b-field grouped>
                            <b-field expanded>
                              <ValidationProvider
                                rules
                                v-slot="{ errors, valid }"
                              >
                                <b-field
                                  label="Tytuł"
                                  label-position="on-border"
                                  :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }"
                                  :message="t(errors[0])"
                                >
                                  <b-input
                                    v-model="referralDoctorPrefix"
                                  ></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>
                            <b-field expanded>
                              <ValidationProvider
                                :rules="{
                                  required: true,
                                  pwznumber: true,
                                }"
                                v-slot="{ errors, valid }"
                              >
                                <b-field
                                  label="PWZ"
                                  label-position="on-border"
                                  :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }"
                                  :message="t(errors[0])"
                                >
                                  <b-input v-model="referralDoctorPwz"
                                    icon="alert-circle-outline"
                                    placeholder="Wymagane"
                                  ></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>
                          </b-field>
                          <b-field grouped>
                            <b-field expanded>
                              <ValidationProvider
                                :rules="{
                                  required: true,
                                  name: true,
                                }"
                                v-slot="{ errors, valid }"
                              >
                                <b-field
                                  label="Imie:"
                                  label-position="on-border"
                                  :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }"
                                  :message="t(errors[0])"
                                  expanded
                                >
                                  <b-input
                                    icon="alert-circle-outline"
                                    placeholder="Wymagane"
                                    v-model="referralDoctorFirstName"
                                    expanded
                                  ></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>
                            <b-field expanded>
                              <ValidationProvider
                                :rules="{
                                  required: true,
                                  alpha_space_betwen_wordsPL: true,
                                }"
                                v-slot="{ errors, valid }"
                              >
                                <b-field
                                  label="Nazwisko:"
                                  label-position="on-border"
                                  :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }"
                                  :message="t(errors[0])"
                                  expanded
                                >
                                  <b-input
                                    icon="alert-circle-outline"
                                    placeholder="Wymagane"
                                    expanded
                                    v-model="referralDoctorLastName"
                                  ></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>
                          </b-field>
          </section>
        </div>
        <footer class="modal-card-foot buttons is-right">
          <div>
            <b-button
              :loading="isLoading"
              icon-left="close"
              size="is-small"
              @click="externalDoctorFormulaModal = false"
              >Zamknij</b-button
            >

            <b-button
              :disabled="invalid || externalDoctorExists"
              :loading="isLoading"
              icon-left="plus"
              size="is-small"
              @click="addDoctorFormula"
              >Dodaj zewnętrznego lekarza
            </b-button>
          </div>
        </footer>
      </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import RISMixins from "@/mixins/ris";
import { Action } from "@/store/config_actions";
import OrdersMixins from "@/mixins/orders/poz_orders";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ReferralMixins from "@/mixins/referrals.js";
import IkzExam from "@/mixins/ikz_exams.js";

import RISWorkshopAddExamination from "../RISWorkshopAddExaminationVer2";
// import ContentPanel from "@/components/tools/ContentPanel";

export default {
  name: "SchedulePatientChangeOrderVer2",
  mixins: [CommonHelpers, RISMixins, OrdersMixins, ReferralMixins, IkzExam],
  components: {
    ValidationProvider,
    ValidationObserver,
    RISWorkshopAddExamination,
  //  ContentPanel,
  },

  props: { examination: { type: Object, required: true }, ticket: { type: Object, required: true } },

  data: function () {
    return {
      isLoading: false,

      isActiveModal: false,
      requestInProgress: false,

      referralDoctorPrefix: "",
      referralDoctorFirstName: "",
      referralDoctorLastName: "",
      referralDoctorPwz: "",
      referralDoctor: "",
      referralClinic: "",

      referredBy: -1,
      internalReferringClinicId: null,
      internalDoctor: true,
      contractorInput: "",
      contractor: null,

      internalClinic: true,
      subunitName: "",
      internalClinicId: null,

      employeeName: "",
      typeReferral: null,
      referralType: null,

      suggestedExaminationAdd: [],
      ikzFilter: "",
      ikzAdded: [],
      ikzIncluded: [],
      appointmentType: null,

      resultSentToLocation: null,
      facilityFilter: "",

      showAllContractors: false,

      ticketProcedures: [],
      ticketTypeImages: [],

      externalDoctorFormulaModal: false,
      externalDoctorFormulaFilter: "",
      externalDoctorFormula: [],

      translatedAppointment: null,
    };
  },

  mounted() {

    if (this.medicalDevices.length === 0) {
      this.downloadMedicalDevices();
    }

    if (!this.$store.state.finance.subjects) {
      this.getSubjects();
    }

    if (!this.$store.state.finance.specialSubjects) {
      this.getRISSubjects();
    }

    if (!this.$store.state.orgUnit.subunits || !this.$store.state.orgUnit.all) {
      // this.getSubUnits();
    }

    if (this.typesImage && this.typesImage.length > 0) {
      //ok, we have image types
    } else {
      this.getImageTypes();
    }

    if (!this.externalDoctorFormula.length) {
      this.getExternalDoctorFormula();
    }
  },

  watch: {

    isActiveModal() {
      if (this.isActiveModal == true) {
        this.translateAppointment();
        this.setPropsFromTicket()
      }
    },
  },

  computed: {
    ikzs() {
      if (this.appointmentType) {
        let selected = [];
        let all = this.$store.state.finance.ikz;

        all.forEach((element) => {
          if (this.ikzIncluded.includes(element.internalId)) {
            selected.push(element);
          }
        });

        return selected;
      } else {
        return this.$store.state.finance.ikz;
      }
    },

    perms() {
      return this.$store.state.employee.permits;
    },

    me() {
      return this.$store.state.employee.me;
    },

    employeesAll() {
      return this.$store.state.employee.all ?? [];
    },

    filteredIkz() {
      if (this.ikzFilter.length < 1) {
        return this.ikzs;
      } else {
        this.ikzs.forEach((element) => {
          element.apc = element.pharse
            .toString()
            .toLowerCase()
            .indexOf(this.ikzFilter.toLowerCase());
        });

        var nonfiltered = this.ikzs.filter((option) => {
          return option.apc >= 0;
        });
        var sorted = nonfiltered.sort((a, b) => {
          return a.apc - b.apc;
        });

        return sorted;
      }
    },

    employees() {
      let array = [];
      if (this.employeesAll) {
        this.employeesAll.forEach((emp) => {
          if (
            emp.roles.includes(10) ||
            emp.roles.includes(11) ||
            emp.roles.includes(12) ||
            emp.roles.includes(20) ||
            emp.roles.includes(21)
          ) {
            if (!emp.roles.includes(999) && !emp.roles.includes(997)) {
              array.push(emp);
            }
          }
        });
      }
      return array;
    },

    subunitsAll() {
      return this.$store.state.orgUnit.subunits ?? [];
    },

    unitsAll() {
      return this.$store.state.orgUnit.all ?? [];
    },

    facilities() {
      return this.$store.state.clinic.clinics;
    },

    facilitiesFiltered() {
      return this.facilities.filter((option) => {
        if (option.name) {
          return (
            option.name
              .toLowerCase()
              .indexOf(this.facilityFilter.toLowerCase()) >= 0
          );
        } else return false;
      });
    },

    filteredEmployees() {
      if (this.employees) {
        return this.employees.filter((option) => {
          if (option.name) {
            return (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.employeeName.toLowerCase()) >= 0
            );
          } else return false;
        });
      } else return [];
    },

    subjects() {
      let subjects = this.$store.state.finance.subjects;
      if (subjects) {
        return subjects;
        // return subjects.filter((item) => item.isContractor === true);
      } else return [];
    },

    risSubjects() {
      return this.$store.state.finance.specialSubjects ?? [];
    },

    subjectsContractor() {
      if (this.showAllContractors)
        return this.$store.state.finance.subjects ?? [];
      else return this.risSubjects;
    },

    filteredSubjectsContractor() {
      if (this.subjectsContractor) {
        return this.subjectsContractor.filter((option) => {
          if (option.nameFull && option.domain != 1000) {
            let resultNameFull = false;
            let resultNameShort = false;

            resultNameFull =
              option.nameFull
                .toString()
                .toLowerCase()
                .indexOf(this.contractorInput.toLowerCase()) >= 0;

            if (option.nameShort) {
              resultNameShort =
                option.nameShort
                  .toString()
                  .toLowerCase()
                  .indexOf(this.contractorInput.toLowerCase()) >= 0;
            }

            return resultNameFull || resultNameShort;
          }
          return false;
        });
      } else return [];
    },

    transletedSubunits() {
      let translated = this.subunitsAll;

      translated.forEach((sub) => {
        if (sub) {
          if (sub.facilityId != null) {
            sub.facilityName = this.getFacility(sub.facilityId);
            sub.facilityObject = this.getFacilityObject(sub.facilityId);
            sub.facilityFullName = this.getFacilityFullName(sub.facilityId);
          } else {
            sub.facilityName = "Nieokreślona";
            sub.facilityObject = null;
          }
        }
      });
      return translated;
    },

    filteredSubunits() {
      if (this.transletedSubunits) {
        return this.transletedSubunits.filter((option) => {
          let resultSubunitName = false;
          let resultFacility = false;

          if (option.facilityFullName) {
            resultFacility =
              option.facilityFullName
                .toString()
                .toLowerCase()
                .indexOf(this.subunitName.toLowerCase()) >= 0;
          }

          if (option.name) {
            resultSubunitName =
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.subunitName.toLowerCase()) >= 0;
          }

          return resultSubunitName || resultFacility;
        });
      }

      return [];
    },

    referralsType() {
      return this.$store.state.config.referrals ?? [];
    },

    appTypes() {
      return this.$store.state.config.appointments ?? [];
    },

    configClinics() {
      return this.$store.state.config.clinics ?? [];
    },

    selectClinicType() {
      if (this.translatedAppointment && this.translatedAppointment.base && this.translatedAppointment.base.clinic) {
        let found = this.configClinics.find(
          (clinic) => clinic.id == this.translatedAppointment.base.clinic
        );

        if (found) {
          return found;
        }

        return null;
      }

      return null;
    },

    payerType() {
      return this.translatedAppointment.base.payerType;
    },

    deviceType() {
      if (this.translatedAppointment.deviceObject) {
        return this.translatedAppointment.deviceObject.type;
      }

      return -1;
    },

    typesImage() {
      return this.$store.state.results.examImageType ?? [];
    },

    externalDoctorFormulaFiltered() {
      return this.externalDoctorFormula.filter((option) => {
        if (option.title) {
          return (
            option.title
              .toString()
              .toLowerCase()
              .indexOf(this.externalDoctorFormulaFilter.toLowerCase()) >= 0
          );
        }
        return false;
      });
    },

    externalDoctorExists() {
      let findDoctor = this.externalDoctorFormula.find((option) => {
        if(option.doctor && option.doctor.pwz == this.referralDoctorPwz) {
          return true
        } else {
          return false
        }
      })

      return findDoctor
    },

    medicalDevices() {
      return this.$store.state.devices.medDevices ?? [];
    }
  },

  methods: {
    removeIkz(index) {
      if (this.ikzAdded) {
        this.ikzFilter = "";
        this.ikzAdded.splice(index, 1);
        this.suggestedExaminationAdd = [-1];
      }
    },

    ikzSelected(item) {
      if (this.ikzAdded) {
        this.ikzAdded.push(item);
        this.suggestedExaminationAdd = this.mGetIkzExam(item.internalId);
      }
    },

    getSubjects() {
      this.loading = true;
      this.$store
        .dispatch(Action.FINANCE_SUBJECTS_GET_ALL)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    getFacility(id) {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.id === id);
        return found != null ? found.name : `Brak (${id})`;
      } else return `Brak placówki (${id})`;
    },

    getFacilityObject(id) {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.id === id);
        return found;
      } else return null;
    },

    getWorker(id) {
      if (this.employeesAll && id) {
        let emp = this.employeesAll.find((e) => e.id === id);

        if (emp) {
          return emp.lastName + " " + emp.firstName;
        } else return "Brak informacji";
      } else return "Brak informacji";
    },

    getSubject(id) {
      if (this.subjects) {
        let subject = this.subjects.find((s) => s.id === id);
        if (subject) {
          return subject.nameFull;
        }
      }
      return "Nie znaleziono";
    },

    getSubunit(id) {
      if (this.subunitsAll) {
        let subunit = this.subunitsAll.find((s) => s.id === id);
        if (subunit) {
          return subunit.name;
        }
      }
      return "Nie znaleziono";
    },

    saveExam() {
      this.isActiveModal = false;

      this.translatedAppointment.ticket.procedures = [];

      this.ticketProcedures.forEach((item) => {
        if (this.contractor != null) {
          item.externalContractor = this.contractor;
        }

        this.translatedAppointment.ticket.procedures.push(
          JSON.parse(JSON.stringify(item))
        );
      });

      if (this.contractor != null) {
        this.translatedAppointment.procedure.externalContractor = this.contractor;
      }

      if (this.translatedAppointment.ticket && this.translatedAppointment.ticket.referral) {
        this.referralDoctor =
            this.referralDoctorLastName +
            "^" +
            this.referralDoctorFirstName +
            "^" +
            this.referralDoctorPwz +
            "^" +
            this.referralDoctorPrefix;
        if (this.internalDoctor) {
            this.translatedAppointment.ticket.referral.referredBy = this.referredBy;
        } else {
            this.translatedAppointment.ticket.referral.externalReferredBy =
            this.referralDoctor;
            this.translatedAppointment.ticket.referral.referredBy = -1;
        }

        if (this.internalClinic) {
            this.translatedAppointment.ticket.referral.internalReferringClinicId =
            this.internalClinicId;
        } else {
            this.translatedAppointment.ticket.referral.externalReferringClinic =
            this.referralClinic;
            this.translatedAppointment.ticket.referral.internalReferringClinicId = null;
        }
      }

      this.translatedAppointment.ticket.ikz = this.ikzAdded;

      if (this.resultSentToLocation) {
        this.translatedAppointment.ticket.resultSentToLocation =
          this.resultSentToLocation;
      }

      this.updateTicket(this.translatedAppointment.ticket);
    },

    updateTicket(ticket) {
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.TICKET_PUT, ticket)
        .then((resp) => {
          this.examination.ticketInfo = resp
          this.translatedAppointment.ticket = resp
          this.requestInProgress = false;
          this.successSnackbar("Zmiany zapisane");
          this.$emit("updated");
        })
        .catch((error) => {
          this.requestInProgress = false;
          this.apiProblem(error);
        });
    },

    addExamination(option) {
      let tImage = this.typesImage.find(
        (image) => image.internalId == option.procedureId
      );
      option.typeImage = JSON.parse(JSON.stringify(tImage));
      this.ticketProcedures.push(option);
    },

    getSubUnits() {
      this.loading = true;
      this.$store
        .dispatch(Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_ALL)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    getFacilityObjectInternal(id) {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.internalId === id);
        return found;
      } else return null;
    },

    getFacilityFullName(id) {
      if (this.facilities) {
        let found = this.facilities.find((x) => x.id === id);
        if (found) {
          let formatAdress = `Placówka: ${found.name} \nAdres: ${
            found.address.street
          } ${found.address.buildingNumber}${
            found.address.apartmentNumber
              ? ` / ${found.address.apartmentNumber}`
              : ""
          } ${found.address.zipCode} ${found.address.city}`;
          return formatAdress;
        } else return `Brak (${id})`;
      } else return null;
    },

    getRISSubjects() {
      this.loading = true;
      var medSpecType = [2]; //get from mContractSpecType; 2-radiologia
      this.$store
        .dispatch(Action.FINANCE_SUBJECTS_GET_WITH_MEDSPEC_TYPE, medSpecType)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    getExternalDoctorFormula() {
      this.isLoading = true;

      let filters = {
        type: 2,
        type2: -1,
        type3: -1,
      };

      this.$store
        .dispatch(Action.EMPLOYEE_FORMULA_GET_BY_TYPE, filters)
        .then((response) => {
          this.externalDoctorFormula = response;
          this.isLoading = false;

          this.externalDoctorFormula.forEach((object) => {
            object.doctor = JSON.parse(object.formula);
          })
        })
        .catch((error) => {
          this.isLoading = false;
          this.dangerSnackbar(error);
        });
    },

    addDoctorFormula() {
      this.isLoading = true;
      let formulaTitle = `${this.referralDoctorFirstName} ${this.referralDoctorLastName} (PWZ: ${this.referralDoctorPwz})`;
      let formulaContent = {
        firstName: this.referralDoctorFirstName,
        lastName: this.referralDoctorLastName,
        pwz: this.referralDoctorPwz,
        title: this.referralDoctorPrefix,
      };

      let payload = {
        employee: -1,
        type: 2, // LEKARZ ZEWNĘTRZNY
        type2: -1,
        type3: -1,
        title: formulaTitle,
        formula: JSON.stringify(formulaContent),
      };

      this.$store
        .dispatch(Action.EMPLOYEE_FORMULA_CREATE, payload)
        .then((response) => {
          this.externalDoctorFormula.push(response);
          this.isLoading = false;
          this.externalDoctorFormulaModal = false;
          this.successSnackbar("Dodano zewnętrznego lekarza do słownika");
        })
        .catch((error) => {
          this.isLoading = false;
          this.dangerSnackbar(error);
        });
    },

    selectExternalDoctorFormula(option) {
      let formulaContent = JSON.parse(option.formula);

      if (formulaContent) {
        this.referralDoctorFirstName = formulaContent.firstName.toUpperCase();
        this.referralDoctorLastName = formulaContent.lastName.toUpperCase();
        this.referralDoctorPwz = formulaContent.pwz;
        this.referralDoctorPrefix = formulaContent.title;
      }
    },

    getImageTypes() {
      //this.loading = true;
      this.$store
        .dispatch(Action.RESULTS_IMAGE_EXAMINATION_TYPE_GET_ALL)
        .then(() => {
          //this.loading = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          //this.loading = false;
        });
    },

    getMedicalDevicesName(id) {
      let device = this.medicalDevices.find((d) => d.id == id);
      if (device) {
        return device.name;
      } else {
        return " ";
      }
    },

    getMedicalDevicesShortName(id) {
      let device = this.medicalDevices.find((d) => d.id == id);
      if (device) {
        return device.shortName;
      } else {
        return " ";
      }
    },

    getMedicalDevice(id) {
      let device = this.medicalDevices.find((d) => d.id == id);
      if (device) {
        return device;
      } else {
        return null;
      }
    },

    downloadMedicalDevices() {
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.MEDICAL_DEVICE_GET_ALL)
        .then(() => {
          this.requestInProgress = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.requestInProgress = false;
        });
    },


    translateAppointment() {
      let trans = {
        base: this.examination,
        id: this.examination.id,
        workerId: this.examination.workerId,
        description: this.examination.description,
        isUrgent: this.examination.isUrgent,
        isPregnant: false,
        isPrivate: this.examination.isPrivate,
        status: this.examination.state,
        device: this.getMedicalDevicesName(this.examination.device),
        ticket: this.ticket,
        deviceObject: this.getMedicalDevice(this.examination.device),
        procedure: null,
        procedureId: -1,
        typeImage: null,
        referralType: null,
        procedures: [],
        imageTypes: [],
      };
      
      if (trans.base.plannedProcedures.length > 0) {
        let ticketProcedure = this.ticket.procedures[trans.base.plannedProcedures[0]] ?? null;

        if(ticketProcedure !== null && ticketProcedure !== undefined) {
          trans.procedure = ticketProcedure
          trans.procedureId = trans.base.plannedProcedures[0]
        } else {
          trans.procedure = this.ticket.procedures[0]
          trans.procedureId = 0
        }
      } else {
        if (this.ticket && this.ticket.procedures.length > 0) {
          trans.procedure = this.ticket.procedures[0];
          trans.procedureId = 0;
        } else {
          trans.procedure = null;
        }
      }

      if (trans.procedure) {
        trans.typeImage = this.typesImage.find(
          (image) => image.internalId == trans.procedure.procedureId
        );
      }

      if(this.ticket.procedures && this.ticket.procedures.length > 0) {
        let array = []
        let imageTypes = []
        this.ticket.procedures.forEach(procedure => {
          array.push(procedure);
          let imageT = this.typesImage.find(
          (image) => image.internalId == procedure.procedureId
        );
          if(imageT){
            if(!imageTypes.includes(imageT)){
              imageTypes.push(imageT)
            }
          }
        });


        trans.procedures = array;
        trans.imageTypes = imageTypes;
      }

      this.translatedAppointment = trans
    },

    setPropsFromTicket() {
          this.ticketProcedures = JSON.parse(
            JSON.stringify(this.translatedAppointment.procedures)
          );
          this.ticketTypeImages = JSON.parse(
            JSON.stringify(this.translatedAppointment.imageTypes)
          );
        
          if(this.ticketProcedures && this.ticketTypeImages) {
            this.ticketProcedures.forEach((item) => {
                let tImage = this.ticketTypeImages.find(
                (image) => image.internalId == item.procedureId
                );
                item.typeImage = JSON.parse(JSON.stringify(tImage));
            });
          }

          if (this.translatedAppointment.ticket && this.translatedAppointment.ticket.referral) {
            this.referredBy = this.translatedAppointment.ticket.referral.referredBy;
            let found = this.referralsType.find(
              (ref) => ref.id == this.translatedAppointment.ticket.referral.type
            );
            if (found) {
              this.referralType = found;
            } else {
              this.referralType = null;
            }
            // this.typeReferral = this.referralType = this.mGetReferralType(
            //   this.translatedAppointment.ticket.referral.type
            // );
            if (this.referredBy > -1) {
              this.internalDoctor = true;
              this.employeeName = this.getWorker(this.referredBy);
            } else {
              this.internalDoctor = false;
              this.employeeName = "";
              let arrayDoc =
                this.translatedAppointment.ticket.referral.externalReferredBy.split("^");
              if (arrayDoc.length > 3) {
                this.referralDoctorLastName = arrayDoc[0];
                this.referralDoctorFirstName = arrayDoc[1];
                this.referralDoctorPwz = arrayDoc[2];
                this.referralDoctorPrefix = arrayDoc[3];
              }
            }

            if (
                this.translatedAppointment.ticket &&
                this.translatedAppointment.ticket.referral &&
                this.translatedAppointment.ticket.referral.internalReferringClinicId != null
            ) {
                this.internalClinic = true;
                this.internalClinicId =
                this.translatedAppointment.ticket.referral.internalReferringClinicId;
                this.subunitName = this.getSubunit(this.internalClinicId);
            } else {
                this.internalClinic = false;
                this.referralClinic =
                this.translatedAppointment.ticket.referral.externalReferringClinic;
            }
          }

          if (
            this.translatedAppointment.procedure &&
            this.translatedAppointment.procedure.externalContractor
          ) {
            this.contractorInput = this.getSubject(
              this.translatedAppointment.procedure.externalContractor
            );
          } else {
            this.contractorInput = "";
          }

          if (this.translatedAppointment.procedure && this.translatedAppointment.procedure.externalContractor) {
            this.contractor = this.translatedAppointment.procedure.externalContractor;
          }

        if (this.translatedAppointment.base) {
          this.appointmentType = this.appTypes.find(
            (x) => x.id == this.translatedAppointment.base.type
          );
        }

        if (this.translatedAppointment.ticket && this.translatedAppointment.ticket.ikz) {
          this.ikzAdded = JSON.parse(
            JSON.stringify(this.translatedAppointment.ticket.ikz)
          );
        }

        if (this.translatedAppointment.deviceObject) {
          this.ikzIncluded = this.mGetServicesForDevice(
            this.translatedAppointment.deviceObject.type
          );
        }

        if (
          this.translatedAppointment.ticket &&
          this.translatedAppointment.ticket.resultSentToLocation
        ) {
          this.resultSentToLocation =
            this.translatedAppointment.ticket.resultSentToLocation;
          let facilityObject = this.getFacilityObjectInternal(
            this.translatedAppointment.ticket.resultSentToLocation
          );
          if (facilityObject) {
            this.facilityFilter = facilityObject.name;
          }
        }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
