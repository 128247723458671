import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        rooms: []
    },

    mutations: {

        setupRooms(state, payload) {
            state.rooms = payload
        },
    },

    actions: {
        [Action.INVENTORY_GET_ROOM](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_UPDATE_ROOM.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.INVENTORY_GET_ALL_ROOMS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_ADD_ROOM,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.INVENTORY_GET_ROOMS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_GET_ROOMS.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit("setupRooms", response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.INVENTORY_GET_ITEMS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_GET_ITEMS.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.INVENTORY_GET_FULL_FACILITY_INFO](context, facilityId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_FULL_INFO.replace('{facilityId}', facilityId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.INVENTORY_RELEASE_BARCODE](context, code) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_RELEASE_BARCODE.replace('{code}', code),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.INVENTORY_SEND_ROOM](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.INVENTORY_ADD_ROOM,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.INVENTORY_SEND_ITEM](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.INVENTORY_ADD_ITEM,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.INVENTORY_GET_MULTIPLE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.INVENTORY_GET_MULTIPLE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.INVENTORY_UPDATE_ROOM](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.INVENTORY_UPDATE_ROOM.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.INVENTORY_UPDATE_ITEM](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.INVENTORY_UPDATE_ITEM.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.INVENTORY_DELETE_ITEM](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.INVENTORY_UPDATE_ITEM.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.INVENTORY_DELETE_ROOM](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.INVENTORY_UPDATE_ROOM.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.INVENTORY_GET_ITEM_BY_BARCODE](context, barcode) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_GET_ITEM_BY_BARCODE.replace('{id}', barcode),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.INVENTORY_GET_ITEM_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_UPDATE_ITEM.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.INVENTORY_SEARCH_BARCODE](context, barcode) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_SEARCH_BARCODE.replace('{id}', barcode),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.INVENTORY_SEARCH_SERIAL](context, serial) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_SEARCH_SERIAL.replace('{id}', serial),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all inventory workplaces. Uses GET request without parameter */
        [Action.INVENTORY_WORKPLACE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_WORKPLACE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get inventory workplaces. Uses GET request */
        [Action.INVENTORY_WORKPLACE_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_WORKPLACE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get inventory workplace full info. Uses GET request */
        [Action.INVENTORY_WORKPLACE_GET_FULL_INFO](context, workplaceId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_WORKPLACE_GET_FULL_INFO.replace('{id}', workplaceId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get inventory workplace by room id. Uses GET request */
        [Action.INVENTORY_WORKPLACE_GET_BY_ROOM](context, roomId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_WORKPLACE_GET_BY_ROOM.replace('{roomId}', roomId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get inventory workplace by category id. Uses GET request */
        [Action.INVENTORY_WORKPLACE_GET_BY_CATEGORY](context, categoryId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.INVENTORY_WORKPLACE_GET_BY_CATEGORY.replace('{categoryId}', categoryId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt inventory workplace delete. Uses DELETE request */
        [Action.INVENTORY_WORKPLACE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.INVENTORY_WORKPLACE_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt inventory workplace edit. Uses PUT request with id and requires inventory workplaces data object (check config_apis.js)*/
        [Action.INVENTORY_WORKPLACE_UPDATE](context, invWorkplace) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.INVENTORY_WORKPLACE_GET.replace('{id}', invWorkplace.id),
                        data: invWorkplace,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to add inventory workplace. Uses POST request and requires inventory workplace data object (check config_apis.js) */
        [Action.INVENTORY_WORKPLACE_CREATE](context, invWorkplace) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.INVENTORY_WORKPLACE_GET_ALL,
                        data: invWorkplace,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

    },
}