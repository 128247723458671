/**
 * This configures validation with VeeValidate.
 * Messages returned from here are actually paths to localization strings.
 */

//import { min } from 'moment';
import { extend } from 'vee-validate';

// Import built in validators.
import { required, digits, email, alpha, alpha_num, between, confirmed, numeric, max, min, min_value } from "vee-validate/dist/rules";

extend('required', { ...required, message: 'validation.required' })
extend('alpha', { ...alpha, message: 'validation.alpha' })
extend('digits', { ...digits, message: 'validation.digits' })
extend('email', { ...email, message: 'validation.emailFormat' })
extend('alpha_num', { ...alpha_num, message: 'validation.alphaNum' })
extend('between', { ...between })
extend('confirmed', { ...confirmed, message: 'validation.confirm' })
extend('numeric', { ...numeric, message: 'validation.digits' })
extend('max', { ...max, message: 'validation.max' })
extend('min', { ...min, message: 'validation.min' })
extend('min_value', { ...min_value, message: 'validation.min_value' })

// Only base letters and numbers (regular alpha_num allows polish letters)
extend('alpha_num_base', {
    validate: (value => {
        let regex = /^[a-zA-Z0-9]*$/
        return regex.test(value)
    }),
    message: 'validation.alphaNumBase'
})

// PESEL validation
extend('pesel', {
    validate: (value => {

        if (value == null || value.length != 11) {
            return false
        }
        else {
            let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
            let sum = 0;
            let controlNumber = parseInt(value.substring(10, 11));

            for (let i = 0; i < weight.length; i++) {
                sum += (parseInt(value.substring(i, i + 1)) * weight[i]);
            }
            sum = sum % 10;
            return (10 - sum) % 10 === controlNumber;
        }
    }),
    message: 'validation.pesel'
})

// REGON validation
extend('regon9', {
    validate: (value => {    
        if (value == null || value.length != 9) {
            return false
        }
        else {
            let weight = [8, 9, 2, 3, 4, 5, 6, 7];
            let sum = 0;
            let controlNumber = parseInt(value.substring(8, 9));

            for (let i = 0; i < weight.length; i++) {
                sum += (parseInt(value.substring(i, i + 1)) * weight[i]);
            }
            sum = sum % 11;

            if (sum === 10) {
                sum = 0;
            }


            return sum === controlNumber;
        }
    }),
    message: 'Sprawdź poprawność 9-znakowego numeru REGON'
})

extend('regon14', {
    validate: (value => {

        if (value == null || value.length != 14) {
            return false
        }
        else {
            let weight = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
            let sum = 0;
            let controlNumber = parseInt(value.substring(13, 14));

            for (let i = 0; i < weight.length; i++) {
                sum += (parseInt(value.substring(i, i + 1)) * weight[i]);
            }
            sum = sum % 11;

            if (sum === 10) {
                sum = 0;
            }


            return sum === controlNumber;
        }
    }),
    message: 'Sprawdź poprawność 14-znakowego numeru REGON'
})

// Length between specified params.
extend('length', {
    params: ['min', 'max'],

    validate: (value, { min, max }) => {
        return value.length >= min && value.length <= max
    },

    message: 'Maksymalna liczba znaków to {max}'
})

extend('lengthMinMax', {
    params: ['min', 'max'],

    validate: (value, { min, max }) => {
        return value.length >= min && value.length <= max
    },

    message: 'Liczba znaków od {min} do {max}'
})

extend('lengthMin', {
    params: ['min'],

    validate: (value, { min }) => {
        return value.length >= min
    },

    message: 'Minimalna liczba znaków to {min}'
})

// Length between specified params.
extend('lengthDigit', {
    params: ['min', 'max'],

    validate: (value, { min, max }) => {
        let valueInt = parseInt(value)
        let valueString = valueInt.toString()
        if(valueString !== null || valueString !== undefined) return valueString.length >= min && valueString.length <= max
        else return false
    },

    message: 'Maksymalna liczba znaków to {max}'
})

// Length between specified params.
extend('lengthDecimal', {
    params: ['min', 'max'],

    validate: (value, { min, max }) => {
        let valueString = value.toString()
        let isDecimal = (value % 1) != 0
        if(isDecimal) {
            if(valueString) {
                let decimalString = valueString.split(".")[1];
                return decimalString.length >= min && decimalString.length <= max
            } else return false
        } else {
            return true
        }
    },

    message: 'Maksymalna liczba znaków po przecniku to {max}'
})

// Length between specified params.
extend('length_phone_num', {
    params: ['min', 'max'],

    validate: (value, { min, max }) => {
        return value.length >= min && value.length <= max
    },

    message: 'validation.length_phone_num'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('userpass', {
    validate: (value => {
        let regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/
        return regex.test(value)
    }),
    message: 'validation.userpass'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('userpassEzwm', {
    validate: (value => {
        let regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@\-_$#&])/
        return regex.test(value)
    }),
    message: 'validation.userpassEzwm'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('alpha_space_slash', {
    validate: (value => {
        let regex = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s\-/]*$/
        return regex.test(value)
    }),
    message: 'validation.alphaSpaceSlash'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('surname', {
    validate: (value => {
        //let regex = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ-]*$/
        let regex = /^[\p{L}-]*$/gu
        return regex.test(value)
    }),
    message: 'validation.surname'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('name', {
    validate: (value => {
        //let regex = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s-]*$/
        let regex = /^[\p{L}\s-]*$/gu
        return regex.test(value)
    }),
    message: 'validation.name'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('alpha_space', {

    validate: (value => {
        let regex = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s]*$/
        return regex.test(value)
    }),

    message: 'validation.alphaSpace'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('alpha_num_space_slash', {

    validate: (value => {
        let regex = /^[a-zA-Z0-9żźćńółęąśŻŹĆĄŚĘŁÓŃ\s\-/]*$/
        return regex.test(value)
    }),

    message: 'validation.alphaNumSpaceSlash'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('alpha_num_space_slash_comma_dot', {

    validate: (value => {
        let regex = /^[a-zA-Z0-9żźćńółęąśŻŹĆĄŚĘŁÓŃ\s\-/,.]*$/
        return regex.test(value)
    }),

    message: 'validation.alphaNumSpaceSlashCommaDot'
})


// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('alpha_num_slash', {

    validate: (value => {
        let regex = /^[a-zA-Z0-9żźćńółęąśŻŹĆĄŚĘŁÓŃ\-/]*$/
        return regex.test(value)
    }),

    message: 'validation.alphaNumSlash'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('zip_code', {

    validate: (value => {
        let regex = /^\d\d-\d\d\d$/
        return regex.test(value)
    }),

    message: 'validation.zipCode'
})

// Telephone validation.
// TODO: More complex werification :)
extend('telephone', {

    validate: (value => {
        let regex = /^[0-9]{6,16}$/
        return regex.test(value)
    }),

    message: 'validation.telephone'
})

// PL MESSAGES
extend('maxPL', {
    params: ['max'],

    validate: (value, { max }) => {
        return value.length <= max
    },
    message: "Maksymalna liczba znaków - {max}."
})

extend('surnamePL', {
    validate: (value => {
        //let regex = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ-]*$/
        let regex = /^[\p{L}\s-]*$/gu
        return regex.test(value)
    }),
    message: 'Tylko litery.'
})

extend('requiredPL', { ...required, message: 'Wymagane.' })

extend('alpha_numPL', { ...alpha_num, message: 'Tylko litery i cyfry.' })

extend('numPL', {
    validate: (value => {
        let regex = /^[0-9]*$/
        return regex.test(value)
    }),
    message: 'Tylko cyfry.'
})

extend('alpha_space_slashPL', {

    validate: (value => {
        let regex = /^[a-zA-Z0-9żźćńółęąśŻŹĆĄŚĘŁÓŃ\s\-/]*$/
        return regex.test(value)
    }),

    message: 'Tylko litery, spacja, ukosnik (/) i myślnik (-)'
})

extend('digitsPL', { ...digits, message: 'Nieprawidłowy format.' })

extend('nip', {
    validate: (value => {
        let regex = /^[0-9]{10}$/
        return regex.test(value)
    }),

    message: 'validation.nip'
})

extend('code5', {
    validate: (value => {
        let regex = /^[0-9]{1,3}$/
        return regex.test(value)
    }),

    message: 'validation.code5'
})

extend('code7', {
    validate: (value => {
        let regex = /^[0-9]{1,3}$/
        return regex.test(value)
    }),

    message: 'validation.code7'
})

//PZW number validation
extend('pwznumber', {

    validate: (value => {
        //let regex = /^[0-9]{7}$/
        let regex = /^[A-Z0-9]{3,20}$/
        return regex.test(value)
    }),

    message: 'validation.pwzNumber'
})

//PZW number validation
extend('pwzdlnumber', {

    validate: (value => {
        let regex = /^[A-Z0-9]{3,20}$/
        return regex.test(value)
    }),

    message: 'validation.pwzdlNumber'
})

// Length between specified params.
extend('min_max', {
    params: ['min', 'max'],

    validate: (value, { min, max }) => {
        return value >= min && value <= max
    },

    message: 'Wartość musi być z zakresu ({min} , {max})'
})

extend('regon', {
    validate: (value => {
        let regex = /^(\d{9}|\d{14})$/
        return regex.test(value)
    }),

    message: 'Nieprawidłowa liczba znaków lub format'
})

//format of root oid
extend('root_oid', {
    validate: (value => {
        let regex = /^([0-9]+\.){9}([0-9]+){1}$/
        return regex.test(value)
    }),

    message: 'Nieprawidłowy format. ex. 2.16.840.1.113883.3.4424.2.7.{x}'
})

extend('prefix', {
    validate: (value => {
        let regex = /^[0-9+]*$/
        return regex.test(value)
    }),
    message: "Nieprawidłowy format - dopuszczalne cyfry i '+'"
})


extend('alpha_space_betwen_wordsPL', {

    validate: (value => {
        let regex = /^[\p{L}-]+( [\p{L}-]+){0,2}$/gu
        return regex.test(value)
    }),

    message: 'Tylko litery, pojedyncza spacja między słowami (maksymalnie 3 słowa)'
})

extend('input_date', {

    validate: (value => {
        let regex = /^\d{4}-\d{2}-\d{2}$/
        return regex.test(value)
    }),

    message: 'Niewłaściwy format daty'
})


