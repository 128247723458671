import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import moment from 'moment'

export default {
    namespaced: false,

    state: {
        employees: [],
        selectedPatient: null,


        tmp: [
            {
                id: 0,

                start: moment().format('YYYY-MM-DDT08:00'),
                stop: moment().format('YYYY-MM-DDT09:00'),
                session: 1,
                status: 1,
                station: null,
                worker: null,
                patientName: "Ewusiowa Grażyna",
                patientPesel: "00000000000",

                procedures: [
                    {
                        id: 910300,
                        completed: false
                    },
                    {
                        id: 920700,
                        completed: false
                    },
                    {
                        id: 920700,
                        completed: false
                    }
                ],

                patient: {
                    "id": 2,
                    "firstName": "Grażyna",
                    "lastName": "Ewusiowa",
                    "pesel": "00000000000",
                    "secondName": "N/A",
                    "familyName": "N/A",
                    "dateOfBirth": "1979-06-08T00:00:00",
                    "gender": "K",
                    "identityDocument": "N",
                    "identityDocumentNumber": "N/A",
                    "teleAddressStreet": "Karmelicka",
                    "teleAddressHouseNumber": "44",
                    "teleAddressApartmentNumber": "6",
                    "teleAddressCity": "Zakopane",
                    "teleAddressZipCode": "12345",
                    "teleAddressCountry": "Polska",
                    "teleAddressTelephoneNumber": "N/A",
                    "email": "gs@asdasf.pl",
                    "insuranceNfz": "N",
                    "insuranceInsuranceBasis": "N",
                    "insuranceTypeOfInsurance": "N",
                    "insuranceInsuranceNumber": "N/A",
                    "insuranceInsuranceDuplicateNumber": "N/A",
                    "insuranceInsuranceFund": "N",
                    "insuranceSocialStatus": "N",
                    "insuranceEntitlement": "N",
                    "insuranceAdditionalEntitlement": "N",
                    "insuranceLevelOfDisability": "N"
                }
            },
            {
                id: 0,

                start: moment().format('YYYY-MM-DDT08:00'),
                stop: moment().format('YYYY-MM-DDT09:00'),
                session: 1,
                status: 1,
                station: null,
                worker: null,
                patientName: "Gąbka Ksawery",
                patientPesel: "19323197988",

                procedures: [
                    {
                        id: 920100,
                        completed: false
                    },
                    {
                        id: 920200,
                        completed: false
                    },
                    {
                        id: 920300,
                        completed: false
                    }
                ],

                patient: {
                    "id": 5,
                    "firstName": "Ksawery",
                    "lastName": "Gąbka",
                    "pesel": "19323197988",
                    "secondName": "",
                    "familyName": "",
                    "dateOfBirth": "2000-06-04T00:00:00",
                    "gender": "s",
                    "identityDocument": "s",
                    "identityDocumentNumber": "",
                    "teleAddressStreet": "Bociania",
                    "teleAddressHouseNumber": "66",
                    "teleAddressApartmentNumber": "2",
                    "teleAddressCity": "Gdańsk",
                    "teleAddressZipCode": "34554",
                    "teleAddressCountry": "Polska",
                    "teleAddressTelephoneNumber": "123456789",
                    "email": "kajsbd@kajsda.pl",
                    "insuranceNfz": "s",
                    "insuranceInsuranceBasis": "s",
                    "insuranceTypeOfInsurance": "s",
                    "insuranceInsuranceNumber": "string",
                    "insuranceInsuranceDuplicateNumber": "string",
                    "insuranceInsuranceFund": "s",
                    "insuranceSocialStatus": "s",
                    "insuranceEntitlement": "s",
                    "insuranceAdditionalEntitlement": "s",
                    "insuranceLevelOfDisability": "s"
                }
            },
            {
                id: 0,

                start: moment().format('YYYY-MM-DDT08:00'),
                stop: moment().format('YYYY-MM-DDT09:00'),
                session: 1,
                status: 1,
                station: null,
                worker: null,
                patientName: "Jakiśtam Franek",
                patientPesel: "19323197971",

                procedures: [
                    {
                        id: 950200,
                        completed: false
                    },
                    {
                        id: 980100,
                        completed: false
                    },
                ],

                patient: {
                    "id": 4,
                    "firstName": "Franek",
                    "lastName": "Jakiśtam",
                    "pesel": "19323197971",
                    "secondName": "Gerwazy",
                    "familyName": "Jakiśtam",
                    "dateOfBirth": "1945-06-04T00:00:00",
                    "gender": "s",
                    "identityDocument": "s",
                    "identityDocumentNumber": "",
                    "teleAddressStreet": "Stroma",
                    "teleAddressHouseNumber": "3B",
                    "teleAddressApartmentNumber": "2",
                    "teleAddressCity": "Zielona Górka",
                    "teleAddressZipCode": "32333",
                    "teleAddressCountry": "Polska",
                    "teleAddressTelephoneNumber": "444333222",
                    "email": "string@smail.com",
                    "insuranceNfz": "s",
                    "insuranceInsuranceBasis": "s",
                    "insuranceTypeOfInsurance": "s",
                    "insuranceInsuranceNumber": "string",
                    "insuranceInsuranceDuplicateNumber": "string",
                    "insuranceInsuranceFund": "s",
                    "insuranceSocialStatus": "s",
                    "insuranceEntitlement": "s",
                    "insuranceAdditionalEntitlement": "s",
                    "insuranceLevelOfDisability": "s"
                }
            },
            {
                id: 0,

                start: moment().format('YYYY-MM-DDT08:00'),
                stop: moment().format('YYYY-MM-DDT09:00'),
                session: 1,
                status: 1,
                station: null,
                worker: null,
                patientName: "Gonzalez Don Pedro",
                patientPesel: "00032948271",

                procedures: [
                    {
                        id: 910200,
                        completed: false
                    },
                    {
                        id: 910300,
                        completed: false
                    },
                    {
                        id: 910400,
                        completed: false
                    },
                    {
                        id: 930200,
                        completed: false
                    },
                    {
                        id: 930500,
                        completed: false
                    }
                ],

                patient: {
                    "id": 3,
                    "firstName": "Don Pedro",
                    "lastName": "Gonzalez",
                    "pesel": "00032948271",
                    "secondName": "Perrito",
                    "familyName": "N/A",
                    "dateOfBirth": "1979-01-01T00:00:00",
                    "gender": "M",
                    "identityDocument": "N",
                    "identityDocumentNumber": "N/A",
                    "teleAddressStreet": "Niska",
                    "teleAddressHouseNumber": "8",
                    "teleAddressApartmentNumber": "5",
                    "teleAddressCity": "Gaworzno",
                    "teleAddressZipCode": "55667",
                    "teleAddressCountry": "Polska",
                    "teleAddressTelephoneNumber": "694567766",
                    "email": "asd@dsa.pl",
                    "insuranceNfz": "N",
                    "insuranceInsuranceBasis": "N",
                    "insuranceTypeOfInsurance": "N",
                    "insuranceInsuranceNumber": "N/A",
                    "insuranceInsuranceDuplicateNumber": "N/A",
                    "insuranceInsuranceFund": "N",
                    "insuranceSocialStatus": "N",
                    "insuranceEntitlement": "N",
                    "insuranceAdditionalEntitlement": "N",
                    "insuranceLevelOfDisability": "N"
                }
            }
        ],
        tmp2: [
            {
                id: 0,

                start: moment().format('YYYY-MM-DDT08:00'),
                stop: moment().format('YYYY-MM-DDT09:00'),
                session: 1,
                status: -1,
                station: null,
                worker: null,
                patientName: "Gonzalez Don Pedro",
                patientPesel: "00032948271",

                procedures: [
                    {
                        id: 910200,
                        completed: false
                    },
                    {
                        id: 910300,
                        completed: false
                    },
                    {
                        id: 910400,
                        completed: false
                    },
                    {
                        id: 930200,
                        completed: false
                    },
                    {
                        id: 930500,
                        completed: false
                    }
                ],

                patient: {
                    "id": 3,
                    "firstName": "Don Pedro",
                    "lastName": "Gonzalez",
                    "pesel": "00032948271",
                    "secondName": "Perrito",
                    "familyName": "N/A",
                    "dateOfBirth": "1979-01-01T00:00:00",
                    "gender": "M",
                    "identityDocument": "N",
                    "identityDocumentNumber": "N/A",
                    "teleAddressStreet": "Niska",
                    "teleAddressHouseNumber": "8",
                    "teleAddressApartmentNumber": "5",
                    "teleAddressCity": "Gaworzno",
                    "teleAddressZipCode": "55667",
                    "teleAddressCountry": "Polska",
                    "teleAddressTelephoneNumber": "694567766",
                    "email": "asd@dsa.pl",
                    "insuranceNfz": "N",
                    "insuranceInsuranceBasis": "N",
                    "insuranceTypeOfInsurance": "N",
                    "insuranceInsuranceNumber": "N/A",
                    "insuranceInsuranceDuplicateNumber": "N/A",
                    "insuranceInsuranceFund": "N",
                    "insuranceSocialStatus": "N",
                    "insuranceEntitlement": "N",
                    "insuranceAdditionalEntitlement": "N",
                    "insuranceLevelOfDisability": "N"
                }
            },
            {
                id: 0,

                start: moment().format('YYYY-MM-DDT08:00'),
                stop: moment().format('YYYY-MM-DDT09:00'),
                session: 1,
                status: -1,
                station: null,
                worker: null,
                patientName: "Ewusiowa Grażyna",
                patientPesel: "00000000000",

                procedures: [
                    {
                        id: 910300,
                        completed: false
                    },
                    {
                        id: 920700,
                        completed: false
                    },
                    {
                        id: 920700,
                        completed: false
                    }
                ],

                patient: {
                    "id": 2,
                    "firstName": "Grażyna",
                    "lastName": "Ewusiowa",
                    "pesel": "00000000000",
                    "secondName": "N/A",
                    "familyName": "N/A",
                    "dateOfBirth": "1979-06-08T00:00:00",
                    "gender": "K",
                    "identityDocument": "N",
                    "identityDocumentNumber": "N/A",
                    "teleAddressStreet": "Karmelicka",
                    "teleAddressHouseNumber": "44",
                    "teleAddressApartmentNumber": "6",
                    "teleAddressCity": "Zakopane",
                    "teleAddressZipCode": "12345",
                    "teleAddressCountry": "Polska",
                    "teleAddressTelephoneNumber": "N/A",
                    "email": "gs@asdasf.pl",
                    "insuranceNfz": "N",
                    "insuranceInsuranceBasis": "N",
                    "insuranceTypeOfInsurance": "N",
                    "insuranceInsuranceNumber": "N/A",
                    "insuranceInsuranceDuplicateNumber": "N/A",
                    "insuranceInsuranceFund": "N",
                    "insuranceSocialStatus": "N",
                    "insuranceEntitlement": "N",
                    "insuranceAdditionalEntitlement": "N",
                    "insuranceLevelOfDisability": "N"
                }
            },
            {
                id: 0,

                start: moment().format('YYYY-MM-DDT08:00'),
                stop: moment().format('YYYY-MM-DDT09:00'),
                session: 1,
                status: 0,
                station: null,
                worker: null,
                patientName: "Gąbka Ksawery",
                patientPesel: "19323197988",

                procedures: [
                    {
                        id: 920100,
                        completed: false
                    },
                    {
                        id: 920200,
                        completed: false
                    },
                    {
                        id: 920300,
                        completed: false
                    }
                ],

                patient: {
                    "id": 5,
                    "firstName": "Ksawery",
                    "lastName": "Gąbka",
                    "pesel": "19323197988",
                    "secondName": "",
                    "familyName": "",
                    "dateOfBirth": "2000-06-04T00:00:00",
                    "gender": "s",
                    "identityDocument": "s",
                    "identityDocumentNumber": "",
                    "teleAddressStreet": "Bociania",
                    "teleAddressHouseNumber": "66",
                    "teleAddressApartmentNumber": "2",
                    "teleAddressCity": "Gdańsk",
                    "teleAddressZipCode": "34554",
                    "teleAddressCountry": "Polska",
                    "teleAddressTelephoneNumber": "123456789",
                    "email": "kajsbd@kajsda.pl",
                    "insuranceNfz": "s",
                    "insuranceInsuranceBasis": "s",
                    "insuranceTypeOfInsurance": "s",
                    "insuranceInsuranceNumber": "string",
                    "insuranceInsuranceDuplicateNumber": "string",
                    "insuranceInsuranceFund": "s",
                    "insuranceSocialStatus": "s",
                    "insuranceEntitlement": "s",
                    "insuranceAdditionalEntitlement": "s",
                    "insuranceLevelOfDisability": "s"
                }
            },
            {
                id: 0,

                start: moment().format('YYYY-MM-DDT08:00'),
                stop: moment().format('YYYY-MM-DDT09:00'),
                session: 1,
                status: 0,
                station: null,
                worker: null,
                patientName: "Jakiśtam Franek",
                patientPesel: "19323197971",

                procedures: [
                    {
                        id: 950200,
                        completed: false
                    },
                    {
                        id: 980100,
                        completed: false
                    },
                ],

                patient: {
                    "id": 4,
                    "firstName": "Franek",
                    "lastName": "Jakiśtam",
                    "pesel": "19323197971",
                    "secondName": "Gerwazy",
                    "familyName": "Jakiśtam",
                    "dateOfBirth": "1945-06-04T00:00:00",
                    "gender": "s",
                    "identityDocument": "s",
                    "identityDocumentNumber": "",
                    "teleAddressStreet": "Stroma",
                    "teleAddressHouseNumber": "3B",
                    "teleAddressApartmentNumber": "2",
                    "teleAddressCity": "Zielona Górka",
                    "teleAddressZipCode": "32333",
                    "teleAddressCountry": "Polska",
                    "teleAddressTelephoneNumber": "444333222",
                    "email": "string@smail.com",
                    "insuranceNfz": "s",
                    "insuranceInsuranceBasis": "s",
                    "insuranceTypeOfInsurance": "s",
                    "insuranceInsuranceNumber": "string",
                    "insuranceInsuranceDuplicateNumber": "string",
                    "insuranceInsuranceFund": "s",
                    "insuranceSocialStatus": "s",
                    "insuranceEntitlement": "s",
                    "insuranceAdditionalEntitlement": "s",
                    "insuranceLevelOfDisability": "s"
                }
            },
            {
                id: 0,

                start: moment().format('YYYY-MM-DDT08:00'),
                stop: moment().format('YYYY-MM-DDT09:00'),
                session: 1,
                status: 0,
                station: null,
                worker: null,
                patientName: "Gonzalez Don Pedro",
                patientPesel: "00032948271",

                procedures: [
                    {
                        id: 910200,
                        completed: false
                    },
                    {
                        id: 910300,
                        completed: false
                    },
                    {
                        id: 910400,
                        completed: false
                    },
                    {
                        id: 930200,
                        completed: false
                    },
                    {
                        id: 930500,
                        completed: false
                    }
                ],

                patient: {
                    "id": 3,
                    "firstName": "Don Pedro",
                    "lastName": "Gonzalez",
                    "pesel": "00032948271",
                    "secondName": "Perrito",
                    "familyName": "N/A",
                    "dateOfBirth": "1979-01-01T00:00:00",
                    "gender": "M",
                    "identityDocument": "N",
                    "identityDocumentNumber": "N/A",
                    "teleAddressStreet": "Niska",
                    "teleAddressHouseNumber": "8",
                    "teleAddressApartmentNumber": "5",
                    "teleAddressCity": "Gaworzno",
                    "teleAddressZipCode": "55667",
                    "teleAddressCountry": "Polska",
                    "teleAddressTelephoneNumber": "694567766",
                    "email": "asd@dsa.pl",
                    "insuranceNfz": "N",
                    "insuranceInsuranceBasis": "N",
                    "insuranceTypeOfInsurance": "N",
                    "insuranceInsuranceNumber": "N/A",
                    "insuranceInsuranceDuplicateNumber": "N/A",
                    "insuranceInsuranceFund": "N",
                    "insuranceSocialStatus": "N",
                    "insuranceEntitlement": "N",
                    "insuranceAdditionalEntitlement": "N",
                    "insuranceLevelOfDisability": "N"
                }
            }
        ]
    },

    mutations: {
        [Mutation.REHABILITATION_ADD_EMPLOYEE](currentState, payload) {
            let index = currentState.employees.indexOf(payload)

            if (index < 0) {
                currentState.employees.push(payload)
            }
        },

        [Mutation.REHABILITATION_REMOVE_EMPLOYEE](currentState, payload) {
            let index = currentState.employees.indexOf(payload)

            if (index > -1) {
                currentState.employees.splice(index, 1)
            }
        },

        [Mutation.REHABILITATION_SET_SELECTED](currentState, payload) {
            currentState.selectedPatient = payload
        },






        [Mutation.REHABILITATION_RESET](currentState) {
            currentState.employees = []
        },
    },

    actions: {
        [Action.TEST_CREATE_PRESCRIPTIONXXXX](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TEST_PRESCRIPTION_SEND,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => { reject(error) });


                } else reject('401')
            })
        },

    }
}