import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
// import { Mutation } from '../config_mutations';

export default {
    namespaced: false,

    state: {},

    getters: {},

    mutations: {},

    actions: {

        /** Attempt to add employee formula. Uses POST request and requires employee formula data object (check config_apis.js) */
        [Action.EMPLOYEE_FORMULA_CREATE](context, employeeFormula) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EMPLOYEE_FORMULA_GET_ALL,
                        data: employeeFormula,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all employee formula. Uses GET request without parameter */
        [Action.EMPLOYEE_FORMULA_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_FORMULA_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get employee formula. Uses GET request */
        [Action.EMPLOYEE_FORMULA_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_FORMULA_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt employee formula delete. Uses DELETE request */
        [Action.EMPLOYEE_FORMULA_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.EMPLOYEE_FORMULA_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt employee formula edit. Uses PUT request with id and requires employee formula data object (check config_apis.js)*/
        [Action.EMPLOYEE_FORMULA_UPDATE](context, employeeFormula) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.EMPLOYEE_FORMULA_GET_BY_ID.replace('{id}', employeeFormula.id),
                        data: employeeFormula,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get employee formula by employee. Uses GET request */
        [Action.EMPLOYEE_FORMULA_GET_BY_EMPLOYEE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_FORMULA_GET_BY_EMPLOYEE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },    
        
        /** Attempt to get employee formula by employee. Uses GET request */
        [Action.EMPLOYEE_FORMULA_GET_BY_TYPE](context, filters) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EMPLOYEE_FORMULA_GET_BY_TYPE.replace('{type}', filters.type).replace('{type2}', filters.type2).replace('{type3}', filters.type3),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },    

        
    }
}