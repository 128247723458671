export default {
    data: () => {
        return {
            /**Rodzaje zabiegów
             * Order types
             */
            orderTypes: [
                {
                    id: 0,
                    name: "Zabieg",
                    icdName: "dla zabiegu",
                    icdTooltip: "Kod ICD zależny od wybranego rodzaju zabiegu",
                    ticketType: 60,
                    isVisible: true,
                    items: [
                        {
                            id: 5,
                            name: "Podanie leku",
                            icdName: "99.97900",
                            icdTooltip: "Farmakoterapia doustna",
                            ticketSubType: 6004
                        },
                        {
                            id: 0,
                            name: "Leczenie ran",
                            icdName: "89.04",
                            icdTooltip: "Opieka pielęgniarki lub położnej",
                            ticketSubType: 6009
                        },
                        {
                            id: 1,
                            name: "Iniekcja",
                            icdName: "99.97 + doprecyzowanie",
                            icdTooltip: "Leczenie farmakologiczne + doprecyzowanie ze względu na miejsce podania",
                            ticketSubType: 6000
                        },
                        {
                            id: 2,
                            name: "Usunięcie szwów",
                            icdName: "doprecyzowanie po lokalizacji",
                            icdTooltip: "Doprecyzowanie ze względu na lokalizację szwu",
                            ticketSubType: 6001
                        },
                        {
                            id: 4,
                            name: "Wenflon",
                            icdName: "38.93",
                            icdTooltip: "Cewnikowanie żył - inne",
                            ticketSubType: 6003
                        },
                        {
                            id: 10,
                            name: "Odczulanie",
                            icdName: "99.97 + doprecyzowanie",
                            icdTooltip: "Leczenie farmakologiczne + doprecyzowanie ze względu na miejsce podania",
                            ticketSubType: 6000
                        },
                        {
                            id: 9,
                            name: "Opatrunek",
                            icdName: "93.57",
                            icdTooltip: "Założenie opatrunku na ranę - inne",
                            ticketSubType: 6008
                        },
                        {
                            id: 3,
                            name: "Wlewy dożylne",
                            icdName: "99.1",
                            icdTooltip: "Wstrzyknięcie lub wlew substancji leczniczej lub profilaktycznej",
                            ticketSubType: 6002
                        },
                        {
                            id: 6,
                            name: "Płukanie uszu",
                            icdName: "96.52",
                            icdTooltip: "Płukanie ucha",
                            ticketSubType: 6005
                        },
                        {
                            id: 7,
                            name: "Usunięcie kleszcza",
                            icdName: "98.20",
                            icdTooltip: "Usunięcie ciała obcego bez nacięcia - inne",
                            ticketSubType: 6006
                        },
                        {
                            id: 8,
                            name: "Cewnikowanie",
                            icdName: "57.01",
                            icdTooltip: "Cewnikowanie pęcherza przez cewkę jednorazowe",
                            ticketSubType: 6007
                        },
                    ]
                },
                {
                    id: 1,
                    name: "Szczepienie",
                    icdName: "dla leku",
                    icdTooltip: "Doprecyzowanie ze względu na wybraną szczepionkę lub ICD: 99.599 - Szczepienie nieokreślone",
                    ticketType: 61,
                    isVisible: true,
                },
                {
                    id: 2,
                    name: "Edukacja",
                    ticketType: 91,
                    isVisible: false,
                },
                {
                    id: 3,
                    name: "Pomiary",
                    icdName: "93.07",
                    icdTooltip: "Pomiary ciała",
                    ticketType: 50,
                    isVisible: true,
                },
                {
                    id: 4,
                    name: "Wyrób medyczny",
                    ticketType: 91,
                    isVisible: false,
                },
                {
                    id: 5,
                    name: "Transport medyczny",
                    ticketType: 99999,
                    isVisible: false,
                },
                {
                    id: 6,
                    name: "Testy diagnostyczne",
                    ticketType: 60,
                    isVisible: true,
                    items: [
                        {
                            id: 0,
                            name: "Test CRP",
                            ticketSubType: 6010
                        },
                        {
                            id: 1,
                            name: "Strep test",
                            ticketSubType: 6010
                        },
                        {
                            id: 2,
                            name: "Grypa",
                            ticketSubType: 6010
                        },
                        {
                            id: 3,
                            name: "Troponiny",
                            ticketSubType: 6010
                        },
                        {
                            id: 4,
                            name: "Mocz",
                            ticketSubType: 6010
                        },
                        {
                            id: 5,
                            name: "Test antygenowy w kierunku: SARS-CoV-2 / grypy A+B / RSV",
                            ticketSubType: 6010
                        }
                    ]
                },
                {
                    id: 7,
                    name: "EKG",
                    icdName: "89.51",
                    icdTooltip: "Elektrokardiogram (ocena rytmu serca)",
                    ticketType: 60,
                    isVisible: true,
                    items: [
                        {
                            id: 0,
                            name: "EKG",
                            ticketSubType: 6011
                        },
                        {
                            id: 1,
                            name: "EKG Holter",
                            ticketSubType: 6011
                        }
                    ]
                },
                {
                    id: 8,
                    name: "Testy alergiczne",
                    icdName: "99.591",
                    icdTooltip: "Testy skórne",
                    ticketType: 60,
                    isVisible: true,
                },
                {
                    id: 9,
                    name: "Echo serca",
                    icdName: "88.721",
                    icdTooltip: "Echokardiografia",
                    ticketType: 60,
                    isVisible: true,
                },
                {
                    id: 10,
                    name: "Holter",
                    icdName: "",
                    icdTooltip: "",
                    ticketType: 60,
                    isVisible: true,
                    items: [
                        {
                            id: 0,
                            name: "Holter RR",
                            ticketSubType: 6014,
                            types: [2450]
                        },
                        {
                            id: 1,
                            name: "EKG Holter",
                            ticketSubType: 6014,
                            types: [2451]
                        }
                    ]
                },
                {
                    id: 11,
                    name: "Inne zlecenia",
                    icdName: "",
                    icdTooltip: "",
                    ticketType: 60,
                    isVisible: true,
                },
            ],

            /**Lokalizacja przeprowadzanego zabiegu
             * Localization of performed order
             */
            orderLocalizations: [
                {
                    id: 0,
                    name: "Głowa",
                    icdName: "97.38"
                },
                {
                    id: 1,
                    name: "Szyja",
                    icdName: "97.38"
                },
                {
                    id: 2,
                    name: "Bark lewy",
                    icdName: "97.891"
                },
                {
                    id: 3,
                    name: "Bark prawy",
                    icdName: "97.891"
                },
                {
                    id: 4,
                    name: "Plecy",
                    icdName: "97.84"
                },
                {
                    id: 5,
                    name: "Brzuch",
                    icdName: "97.83"
                },
                {
                    id: 6,
                    name: "Ramię lewe",
                    icdName: "97.891"
                },
                {
                    id: 7,
                    name: "Ramię prawe",
                    icdName: "97.891"
                },
                {
                    id: 8,
                    name: "Staw łokciowy lewy",
                    icdName: "97.891"
                },
                {
                    id: 9,
                    name: "Staw łokciowy prawy",
                    icdName: "97.891"
                },
                {
                    id: 10,
                    name: "Przedramię lewe",
                    icdName: "97.891"
                },
                {
                    id: 11,
                    name: "Przedramię prawe",
                    icdName: "97.891"
                },
                {
                    id: 12,
                    name: "Nadgarstek lewy",
                    icdName: "97.891"
                },
                {
                    id: 13,
                    name: "Nadgarstek prawy",
                    icdName: "97.891"
                },
                {
                    id: 14,
                    name: "Dłoń lewa",
                    icdName: "97.891"
                },
                {
                    id: 15,
                    name: "Dłoń prawa",
                    icdName: "97.891"
                },
                {
                    id: 16,
                    name: "Krocze",
                    icdName: "97.891"
                },
                {
                    id: 17,
                    name: "Pośladek lewy",
                    icdName: "97.891"
                },
                {
                    id: 18,
                    name: "Pośladek prawy",
                    icdName: "97.891"
                },
                {
                    id: 19,
                    name: "Pachwina lewa",
                    icdName: "97.891"
                },
                {
                    id: 20,
                    name: "Pachwina prawa",
                    icdName: "97.891"
                },
                {
                    id: 21,
                    name: "Udo lewe",
                    icdName: "97.891"
                },
                {
                    id: 22,
                    name: "Udo prawe",
                    icdName: "97.891"
                },
                {
                    id: 23,
                    name: "Staw kolanowy lewy",
                    icdName: "97.891"
                },
                {
                    id: 24,
                    name: "Staw kolanowy prawy",
                    icdName: "97.891"
                },
                {
                    id: 25,
                    name: "Podudzie lewe",
                    icdName: "97.891"
                },
                {
                    id: 26,
                    name: "Podudzie prawe",
                    icdName: "97.891"
                },
                {
                    id: 27,
                    name: "Staw skokowy lewy",
                    icdName: "97.891"
                },
                {
                    id: 28,
                    name: "Staw skokowy prawy",
                    icdName: "97.891"
                },
                {
                    id: 29,
                    name: "Stopa lewa",
                    icdName: "97.891"
                },
                {
                    id: 30,
                    name: "Stopa prawa",
                    icdName: "97.891"
                },
                {
                    id: 31,
                    name: "Inna",
                    icdName: "97.891"
                },
                {
                    id: 32,
                    name: "Klatka piersiowa",
                    icdName: "97.43"
                }      
            ],
            orderLocalizations2: [
                {
                    id: 0,
                    name: "Grzbiet dłoni",
                },
                {
                    id: 1,
                    name: "Przedramię"
                },
                {
                    id: 2,
                    name: "Zgięcie łokciowe"
                },
                {
                    id: 3,
                    name: "Inne"
                }
            ],
            orderLocalizations3: [
                {
                    id: 0,
                    name: "Lewe ucho"
                },
                {
                    id: 1,
                    name: "Prawe ucho"
                },
                {
                    id: 2,
                    name: "Prawe i lewe ucho"
                }
            ],
            orderLocalizations4: [
                {
                    id: 0,
                    name: "Dół podkolanowy prawy"
                },
                {
                    id: 1,
                    name: "Dół podkolanowy lewy"
                },
                {
                    id: 2,
                    name: "Dół pachowy prawy"
                },
                {
                    id: 3,
                    name: "Dół pachowy lewy"
                },
                {
                    id: 4,
                    name: "Szyja"
                },
                {
                    id: 5,
                    name: "Głowa"
                },
                {
                    id: 6,
                    name: "Pachwina - strona prawa"
                },
                {
                    id: 7,
                    name: "Pachwina - strona lewa"
                },
                {
                    id: 8,
                    name: "Stopa prawa"
                },
                {
                    id: 9,
                    name: "Stopa lewa"
                },
                {
                    id: 10,
                    name: "Pośladek prawy"
                },
                {
                    id: 11,
                    name: "Pośladek lewy"
                },
                {
                    id: 12,
                    name: "Dół łokciowy prawy"
                },
                {
                    id: 13,
                    name: "Dół łokciowy lewy"
                },
                {
                    id: 14,
                    name: "Brzuch"
                },
                {
                    id: 15,
                    name: "Plecy"
                }
            ],
            /**Rodzaje ran
             * Wound types
             */
            orderWoundTypes: [
                {
                    id: 0,
                    name: "Pourazowa"
                },
                {
                    id: 1,
                    name: "Odleżyna"
                },
                {
                    id: 2,
                    name: "Stopa cukrzycowa"
                },
                {
                    id: 3,
                    name: "Pooperacyjna"
                },
                {
                    id: 4,
                    name: "Inna"
                }
            ],
            orderWoundExactTypes: [
                {
                    id: 0,
                    name: "Szarpana"
                },
                {
                    id: 1,
                    name: "Cięta"
                },
                {
                    id: 2,
                    name: "Kłuta"
                },
                {
                    id: 3,
                    name: "Tłuczona"
                },
                {
                    id: 4,
                    name: "Kąsana"
                },
                {
                    id: 5,
                    name: "Postrzałowa"
                },
                {
                    id: 6,
                    name: "Zatruta"
                },
                {
                    id: 7,
                    name: "Inna"
                }
            ],
            /**Metody leczenia ran
             * Methods for treating wounds
             */

             orderTreatmentMethods: [
                {
                    id: 0,
                    name: "Metoda tradycyjna/sucha"
                },
                {
                    id: 1,
                    name: "Metoda wilgotna (opatrunki specjalistyczne)"
                },
                {
                    id: 2,
                    name: "Inna"
                }
            ],

            orderTreatmentMethodsExtended: [
                {
                    id: 0,
                    name: "metoda tradycyjna/sucha"
                },
                {
                    id: 1,
                    name: "metoda wilgotna (opatrunki specjalistyczne)"
                },
                {
                    id: 2,
                    name: "terapia podciśnieniowa"
                },
                {
                    id: 3,
                    name: "terapia podciśnieniowa z wkraplaniem"
                },
                {
                    id: 4,
                    name: "biochirurgia"
                },
                {
                    id: 5,
                    name: "larwoterapia"
                },
                {
                    id: 6,
                    name: "oczyszczanie ultradźwiękowe"
                },
                {
                    id: 7,
                    name: "hydrochirurgiczne opracowanie ran"
                },
                {
                    id: 8,
                    name: "terapia tlenem"
                },
                {
                    id: 9,
                    name: "ozonoterapia"
                },
                {
                    id: 10,
                    name: "elektrostymulacja wysokonapięciowa"
                }
            ],
            /**Rodzaje opatrunków
             * Bandage types
             */
            orderBandageTypes: [
                {
                    id: 0,
                    name: "Suchy"
                },
                {
                    id: 1,
                    name: "Specjalistyczny"
                },
                {
                    id: 2,
                    name: "Kompres z gazy"
                },
                {
                    id: 3,
                    name: "Kompres z bawełny"
                },
                {
                    id: 4,
                    name: "Opatrunek hydrożelowy"
                },
                {
                    id: 5,
                    name: "Opatrunek żelowy"
                },
                {
                    id: 6,
                    name: "Opatrunek hydrokoloidowy"
                },
                {
                    id: 7,
                    name: "Opatrunek hydrowłóknisty"
                },
                {
                    id: 8,
                    name: "Opatrunek alginianowy"
                },
                {
                    id: 9,
                    name: "Opatrunek ze srebrem"
                },
                {
                    id: 10,
                    name: "Opatrunek siatkowy"
                },
                {
                    id: 11,
                    name: "Opatrunek piankowy (okluzyjny)"
                },
                {
                    id: 12,
                    name: "Inny"
                }
            ],
            /**Rodzaj zabiegu (kontynuacja)
             * Type of order (continuation)
             */
            orderContinuation: [
                {
                    id: 0,
                    name: "Jednorazowe"
                },
                {
                    id: 1,
                    name: "Okresowe"
                },
                {
                    id: 2,
                    name: "Stałe"
                }
            ],
            /**Rodzaje szwów
             * Stitches types
             */
            orderStitchesTypes: [
                {
                    id: 0,
                    name: "Szew okrężny"
                },
                {
                    id: 1,
                    name: "Szew pojedynczy węzełkowy"
                },
                {
                    id: 2,
                    name: "Szew poziomy materacowy"
                },
                {
                    id: 3,
                    name: "Szew pionowy materacowy"
                },
                {
                    id: 4,
                    name: "Szew ciągły"
                },
                {
                    id: 5,
                    name: "Szew ciągły przekładany"
                },
                {
                    id: 6,
                    name: "Szew ciągły poziomy materacowy"
                },
                {
                    id: 7,
                    name: "Szew śródskórny"
                },
                {
                    id: 8,
                    name: "Inne"
                }
            ],
            /**Cele przeprowadzanego zabiegu
             * Performed order goals
             */
            orderGoalTypes: [
                {
                    id: 1,
                    name: "Interwencyjnie"
                },
                {
                    id: 2,
                    name: "Na okres"
                },
                {
                    id: 0,
                    name: "Przed kolonoskopią"
                }
            ],
            /**Kategorie zlecanych edukacji
             * Order's education categories
             */
            orderEducationCategories: [
                {
                    id: 0,
                    name: "położnicze",
                    items: [
                        {
                            id: 0,
                            name: "położnicza"
                        }
                    ]
                },
                {
                    id: 1,
                    name: "profilaktyczne",
                    items: [
                        {
                            id: 0,
                            name: "dietetyczna"
                        },
                        {
                            id: 1,
                            name: "antynikotynowa"
                        },
                        {
                            id: 2,
                            name: "w chorobie"
                        }
                    ]
                },
                {
                    id: 2,
                    name: "specjalistyczne",
                    items: [
                        {
                            id: 0,
                            name: "diabetologiczna"
                        },
                        {
                            id: 1,
                            name: "kardiologiczna"
                        }
                    ]
                },
                {
                    id: 3,
                    name: "obsługa urządzenia pomiarowego",
                    items: [
                        {
                            id: 0,
                            name: "glukometr"
                        },
                        {
                            id: 1,
                            name: "EKG"
                        },
                        {
                            id: 2,
                            name: "ciśnieniomierz"
                        },
                        {
                            id: 3,
                            name: "puls"
                        }
                    ]
                },
                {
                    id: 4,
                    name: "przygotowanie do badania",
                    items: [
                        {
                            id: 0,
                            name: "kolonoskopia"
                        },
                        {
                            id: 1,
                            name: "mammografia"
                        },
                        {
                            id: 2,
                            name: "cytologia"
                        },
                        {
                            id: 3,
                            name: "kolposkopia"
                        },
                        {
                            id: 4,
                            name: "EEG"
                        },
                        {
                            id: 5,
                            name: "USG"
                        },
                        {
                            id: 6,
                            name: "RTG"
                        },
                        {
                            id: 7,
                            name: "tomografia komputerowa"
                        }
                    ]
                },
                {
                    id: 5,
                    name: "dietetyczne",
                    items: [
                        {
                            id: 0,
                            name: "dieta obniżająca masę ciała"
                        },
                        {
                            id: 1,
                            name: "dieta w cukrzycy"
                        },
                        {
                            id: 2,
                            name: "dieta w chorobach ukł. krążenia"
                        },
                        {
                            id: 3,
                            name: "dieta w niedożywieniu"
                        },
                        {
                            id: 4,
                            name: "dieta przy leczeniu p/krzepliwym"
                        },
                        {
                            id: 5,
                            name: "dieta w chorobach ukł. kostnego"
                        },
                        {
                            id: 6,
                            name: "dieta w anemii"
                        },
                        {
                            id: 7,
                            name: "dieta w chorobach tarczycy"
                        },
                        {
                            id: 8,
                            name: "dieta w celiakii"
                        },
                        {
                            id: 9,
                            name: "dieta w dnie moczanowej"
                        }
                    ]
                },
                {
                    id: 6,
                    name: "pielęgniarskie",
                    items: [
                        {
                            id: 0,
                            name: "edukacja w cukrzycy"
                        },
                        {
                            id: 1,
                            name: "edukacja w CHUK"
                        },
                        {
                            id: 2,
                            name: "edukacja w POCHP"
                        },
                        {
                            id: 3,
                            name: "edukacja w astmie"
                        },
                        {
                            id: 4,
                            name: "edukacja dla choroby Alzheimera"
                        },
                        {
                            id: 5,
                            name: "edukacja w stanach otępienia"
                        },
                        {
                            id: 6,
                            name: "edukacja w chorobie Parkinsona"
                        },
                        {
                            id: 7,
                            name: "edukacja w przewlekłym zespole bólowym kręgosłupa"
                        },
                        {
                            id: 8,
                            name: "edukacja w nadciśnieniu tętniczym"
                        },
                        {
                            id: 9,
                            name: "edukacja w chorobie wieńcowej"
                        },
                        {
                            id: 10,
                            name: "edukacja w migotaniu i trzepotaniu przedsionków"
                        },
                        {
                            id: 11,
                            name: "edukacja w chorobach tarczycy"
                        },
                        {
                            id: 12,
                            name: "edukacja w chorobie nerwów rdzeniowych"
                        },
                        {
                            id: 13,
                            name: "edukacja w otyłości"
                        }
                    ]
                },
                {
                    id: 7,
                    name: "przedporodowe",
                    items: [
                        {
                            id: 0,
                            name: "przedporodowa"
                        }
                    ]
                }
            ],
            /**Dawka podawanego leku
             * Dose of medication
             */
            orderWhichDose: [
                {
                    id: 0,
                    name: "jednorazowa"
                },
                {
                    id: 1,
                    name: "pierwsza"
                },
                {
                    id: 2,
                    name: "druga"
                },
                {
                    id: 3,
                    name: "trzecia"
                },
                {
                    id: 4,
                    name: "przypominająca"
                }
            ],
            orderTransportPositions: [
                {
                    id: 0,
                    name: "siedząca"
                },
                {
                    id: 1,
                    name: "leżąca"
                }
            ],
            orderTransportTypes: [
                {
                    id: 0,
                    name: "zwykły"
                },
                {
                    id: 1,
                    name: "pilny"
                }
            ],
            orderTransportTemplates: [
                {
                    id: 0,
                    name: "Formularz NFZ"
                },
                {
                    id: 1,
                    name: "Wydruk własny"
                }
            ],
            orderVehicleTypes: [
                {
                    id: 1,
                    name: "Karetka przewozowa"
                },
                {
                    id: 99,
                    name: "Inny"
                }
            ],
            /**Droga podania leku
             * Drug route of administration
             */
            orderInjectonRouteOfAdministration: [
                {
                    id: 0,
                    name: "Domięśniowo",
                    icdName: "99.97902",
                    icdTooltip: "Farmakoterapia domięśniowa",
                    options_localization: [
                        {
                            id: 0,
                            name: "Mięsień pośladkowy średni"
                        },
                        {
                            id: 1,
                            name: "Mięsień czworogłowy uda"
                        },
                        {
                            id: 2,
                            name: "Mięsień trójgłowy ramienia"
                        },
                        {
                            id: 3,
                            name: "Inne",
                            options: [
                                {
                                    placeholder: "..."
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 1,
                    name: "Podskórnie",
                    icdName: "99.97906",
                    icdTooltip: "Farmakoterapia podskórna",
                    options_localization: [
                        {
                            id: 0,
                            name: "Ramię"
                        },
                        {
                            id: 1,
                            name: "Brzuch"
                        },
                        {
                            id: 2,
                            name: "Udo"
                        },
                        {
                            id: 3,
                            name: "Okolica łopatki"
                        },
                        {
                            id: 4,
                            name: "Inne",
                            options: [
                                {
                                    placeholder: "..."
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 2,
                    name: "Dożylnie",
                    icdName: "99.97904",
                    icdTooltip: "Farmakoterapia dożylna",
                    options_localization: [
                        {
                            id: 0,
                            name: "Zgięcie łokciowe"
                        },
                        {
                            id: 1,
                            name: "Nadgarstek"
                        },
                        {
                            id: 2,
                            name: "Grzbietowa część dłoni"
                        },
                        {
                            id: 3,
                            name: "Grzbietowa część stopy"
                        },
                        {
                            id: 4,
                            name: "Inne",
                            options: [
                                {
                                    placeholder: "..."
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 3,
                    name: "Śródskórnie",
                    icdName: "99.97905",
                    icdTooltip: "Farmakoterapia śródskórna",
                    options_localization: [
                        {
                            id: 0,
                            name: "Wewnętrzna część przedramienia"
                        },
                        {
                            id: 1,
                            name: "Inne",
                            options: [
                                {
                                    placeholder: "..."
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 4,
                    name: "Inne",
                    icdName: "99.97",
                    icdTooltip: "Leczenie farmakologiczne",
                    options_localization: [
                        {
                            id: 0,
                            name: "Inne",
                            options: [
                                {
                                    placeholder: "..."
                                }
                            ]
                        }
                    ]
                }
            ],
            /**Rodzaj szczepienia
             * Vaccination type
             */
             orderVaccineType: [
                {
                    id: 0,
                    name: "Obowiązkowe"
                },
                {
                    id: 1,
                    name: "Zalecane"
                }
            ],
            /**Droga podania szczepionki
             * Vaccine route of administration
             */
            orderVaccineRouteOfAdministration: [
                {
                    id: 0,
                    name: "Domięśniowa"
                },
                {
                    id: 1,
                    name: "Podskórna"
                },
                {
                    id: 2,
                    name: "Doustna"
                },
                {
                    id: 3,
                    name: "Śródskórna"
                }
            ],
            /**Dawka szczepionki
             * Vaccine dose
             */
            orderVaccineDoseOrder: [
                {
                    id: 0,
                    name: "Jednorazowo"
                },
                {
                    id: 1,
                    name: "Pierwsza"
                },
                {
                    id: 2,
                    name: "Druga"
                },
                {
                    id: 3,
                    name: "Trzecia"
                },
                {
                    id: 4,
                    name: "Przypominająca"
                }
            ],
            /**Powód opóźnienia w podaniu szczepionki
             * Reason of delay in vaccine administration
             */
            orderVaccineDelayReson: [
                {
                    id: 0,
                    name: "Nie dotyczy"
                },
                {
                    id: 1,
                    name: "Choroba"
                },
                {
                    id: 2,
                    name: "Wcześniactwo"
                },
                {
                    id: 3,
                    name: "Brak zgody"
                }
            ],

            orderVaccinneEanDrugs: [
                {
                  "icd9": "99.31",
                  "ean": "7350099220067"
                },
                {
                  "icd9": "99.32",
                  "ean": "5909990952311"
                },
                {
                  "icd9": "99.32",
                  "ean": "5909990023417"
                },
                {
                  "icd9": "99.32",
                  "ean": "5909991397401"
                },
                {
                  "icd9": "99.33",
                  "ean": "5909990021017"
                },
                {
                  "icd9": "99.34",
                  "ean": "brak"
                },
                {
                  "icd9": "99.35",
                  "ean": "brak"
                },
                {
                  "icd9": "99.391",
                  "ean": "5909990003716"
                },
                {
                  "icd9": "99.392",
                  "ean": "5909991288372"
                },
                {
                  "icd9": "99.392",
                  "ean": "5909990674367"
                },
                {
                  "icd9": "99.392",
                  "ean": "5909991086268"
                },
                {
                  "icd9": "99.393",
                  "ean": "5909990420278"
                },
                {
                  "icd9": "99.393",
                  "ean": "5909991302276"
                },
                {
                  "icd9": "99.393",
                  "ean": "5909991428082"
                },
                {
                  "icd9": "99.393",
                  "ean": "5909990082780"
                },
                {
                  "icd9": "99.394",
                  "ean": "5909990891672"
                },
                {
                  "icd9": "99.394",
                  "ean": "5909990625284"
                },
                {
                  "icd9": "99.395",
                  "ean": "5909991063498"
                },
                {
                  "icd9": "99.395",
                  "ean": "5909990940417"
                },
                {
                  "icd9": "99.41",
                  "ean": "5909990050246"
                },
                {
                  "icd9": "99.42",
                  "ean": "brak"
                },
                {
                  "icd9": "99.43",
                  "ean": "5909990661015"
                },
                {
                  "icd9": "99.44",
                  "ean": "5909990063017"
                },
                {
                  "icd9": "99.44",
                  "ean": "5909990002429"
                },
                {
                  "icd9": "99.45",
                  "ean": "brak"
                },
                {
                  "icd9": "99.46",
                  "ean": "brak"
                },
                {
                  "icd9": "99.47",
                  "ean": "brak"
                },
                {
                  "icd9": "99.48",
                  "ean": "5909990643660"
                },
                {
                  "icd9": "99.48",
                  "ean": "5909990483112"
                },
                {
                  "icd9": "99.481",
                  "ean": "5909990083121"
                },
                {
                  "icd9": "99.49",
                  "ean": "5909990794881"
                },
                {
                  "icd9": "99.491",
                  "ean": "5909990756810"
                },
                {
                  "icd9": "99.491",
                  "ean": "5909990711031"
                },
                {
                  "icd9": "99.491",
                  "ean": "5909990658831"
                },
                {
                  "icd9": "99.492",
                  "ean": "5909990056484"
                },
                {
                  "icd9": "99.492",
                  "ean": "5909990056514"
                },
                {
                  "icd9": "99.492",
                  "ean": "5909990840526"
                },
                {
                  "icd9": "99.492",
                  "ean": "5909990840328"
                },
                {
                  "icd9": "99.51",
                  "ean": "brak"
                },
                {
                  "icd9": "99.52",
                  "ean": "5909991347369"
                },
                {
                  "icd9": "99.52",
                  "ean": "5909991302122"
                },
                {
                  "icd9": "99.52",
                  "ean": "5000456061346"
                },
                {
                  "icd9": "99.52",
                  "ean": "5909991382377"
                },
                {
                  "icd9": "99.53",
                  "ean": "5715036000775"
                },
                {
                  "icd9": "99.53",
                  "ean": "5715036000782"
                },
                {
                  "icd9": "99.53",
                  "ean": "5909991134853"
                },
                {
                  "icd9": "99.53",
                  "ean": "5909991082772"
                },
                {
                  "icd9": "99.54",
                  "ean": "brak"
                },
                {
                  "icd9": "99.5501",
                  "ean": "5909990052721"
                },
                {
                  "icd9": "99.5501",
                  "ean": "5909990003914"
                },
                {
                  "icd9": "99.5502",
                  "ean": "5909990003815"
                },
                {
                  "icd9": "99.5502",
                  "ean": "5909990482610"
                },
                {
                  "icd9": "99.5503",
                  "ean": "5909991065317"
                },
                {
                  "icd9": "99.5504",
                  "ean": "5909990003518"
                },
                {
                  "icd9": "99.5504",
                  "ean": "5905179570581"
                },
                {
                  "icd9": "99.5505",
                  "ean": "5909990023516"
                },
                {
                  "icd9": "99.5506",
                  "ean": "5909990616053"
                },
                {
                  "icd9": "99.5506",
                  "ean": "5909990704828"
                },
                {
                  "icd9": "99.5507",
                  "ean": "5909990678129"
                },
                {
                  "icd9": "99.5507",
                  "ean": "5909990737413"
                },
                {
                  "icd9": "99.5507",
                  "ean": "5901549324839"
                },
                {
                  "icd9": "99.5508",
                  "ean": "5909991101237"
                },
                {
                  "icd9": "99.5508",
                  "ean": "5909990734368"
                },
                {
                  "icd9": "99.5508",
                  "ean": "5909990963126"
                },
                {
                  "icd9": "99.5508",
                  "ean": "5907636977131"
                },
                {
                  "icd9": "99.5509",
                  "ean": "5909990469161"
                },
                {
                  "icd9": "99.5509",
                  "ean": "191778021929"
                },
                {
                  "icd9": "99.551",
                  "ean": "5909991386160"
                },
                {
                  "icd9": "99.5511",
                  "ean": "5909991364892"
                },
                {
                  "icd9": "99.5512",
                  "ean": "5901878600628"
                },
                {
                  "icd9": "99.5513",
                  "ean": "brak"
                },
                {
                  "icd9": "99.552",
                  "ean": "brak"
                },
                {
                  "icd9": "99.553",
                  "ean": "brak"
                },
                {
                  "icd9": "99.554",
                  "ean": "brak"
                },
                {
                  "icd9": "99.555",
                  "ean": "brak"
                },
                {
                  "icd9": "99.556",
                  "ean": "brak"
                },
                {
                  "icd9": "99.557",
                  "ean": "5909991445492"
                },
                {
                  "icd9": "99.557",
                  "ean": "5909991445591"
                },
                {
                  "icd9": "99.557",
                  "ean": "5909991448295"
                },
                {
                  "icd9": "99.557",
                  "ean": "5413868119817"
                },
                {
                  "icd9": "99.557",
                  "ean": "5909991477080"
                },
                {
                  "icd9": "99.558",
                  "ean": "5909990572700"
                },
                {
                  "icd9": "99.558",
                  "ean": "5909990609284"
                },
                {
                  "icd9": "99.559",
                  "ean": "5909990064731"
                },
                {
                  "icd9": "99.559",
                  "ean": "191778016130"
                },
                {
                  "icd9": "99.559",
                  "ean": "5909991441524"
                },
                {
                  "icd9": "99.56",
                  "ean": "5909991055417"
                },
                {
                  "icd9": "99.56",
                  "ean": "5909991055318"
                },
                {
                  "icd9": "99.599",
                  "ean": "pozostałe"
                }
            ],

            /**Poziom niepełnosprawności
             * Level of disability
             */
            orderLevelOfDisability: [
                {
                    id: 0,
                    name: "Lekki"
                },
                {
                    id: 1,
                    name: "Umiarkowany"
                },
                {
                    id: 2,
                    name: "Znaczny"
                }
            ],
            orderLevelOfDisabilityNew: [
                {
                    id: 1,
                    name: "I stopień niesprawności"
                },
                {
                    id: 2,
                    name: "II stopień niesprawności"
                },
                {
                    id: 3,
                    name: "Brak"
                }
            ],
            
            orderTransportGoals: [
                {
                    id: 1,
                    name: "Konieczność podjęcia natychmiastowego leczenia w zakładzie opieki zdrowotnej"
                },
                {
                    id: 2,
                    name: "Potrzeba kontynuacji leczenia (kontynuowania leczenia w danym zakładzie lub przekazania do dalszego leczenia w innym zakładzie) "
                },
                {
                    id: 3,
                    name: "Dysfunkcja narządu ruchu uniemożliwiająca korzystanie ze środków transportu publicznego (w celu przejazdu na leczenie do najbliższego zakładu opieki zdrowotnej udzielającego świadczeń we właściwym zakresie i z powrotem)"
                },
                {
                    id: 4,
                    name: "Inne wyżej nie wymienione"
                }
            ],

            /**Typ cewnika
             * Type of catheter
             */
            orderCatheterizationType: [
                {
                    id: 0,
                    name: "cewnik zewnętrzny"
                },
                {
                    id: 1,
                    name: "cewnik Foleya"
                },
                {
                    id: 2,
                    name: "cewnik Pezzera"
                },
                {
                    id: 3,
                    name: "cewnik Nelatona"
                },
                {
                    id: 4,
                    name: "cewnik Tiemanna"
                },
                {
                    id: 5,
                    name: "cewnik Couvelaira"
                },
                {
                    id: 6,
                    name: "cewnik Malecota"
                },
                {
                    id: 7,
                    name: "cewnik Dufoura z balonem"
                },
                {
                    id: 8,
                    name: "cewnik Caspra"
                }
            ],
            /**Wielkość cewnika
             * Size of catheter
             */
            orderCatheterizationSize: [
                {
                    id: 0,
                    name: "4F"
                },
                {
                    id: 1,
                    name: "5F"
                },
                {
                    id: 2,
                    name: "6F"
                },
                {
                    id: 3,
                    name: "7F"
                },
                {
                    id: 4,
                    name: "8F"
                },
                {
                    id: 5,
                    name: "9F"
                },
                {
                    id: 6,
                    name: "10F"
                },
                {
                    id: 7,
                    name: "11F"
                },
                {
                    id: 8,
                    name: "12F"
                },
                {
                    id: 9,
                    name: "13F"
                },
                {
                    id: 10,
                    name: "14F"
                },
                {
                    id: 11,
                    name: "15F"
                },
                {
                    id: 12,
                    name: "16F"
                },
                {
                    id: 13,
                    name: "17F"
                },
                {
                    id: 14,
                    name: "18F"
                },
                {
                    id: 15,
                    name: "19F"
                },
                {
                    id: 16,
                    name: "20F"
                }
            ],

            orderPVCPurpose: [
                {
                    id: 0,
                    name: "Założenie wenflonu"
                },
                {
                    id: 1,
                    name: "Zdjęcie wenflonu"
                },
            ],

            /**Rodzaje zlecanych pomiarów
             * Types of ordered measurements
             */
            orderMeasurementTicketTypes: [
                {
                    id: 5000,
                    name: "Niemowlę"
                },
                {
                    id: 5001,
                    name: "Dziecko"
                },
                {
                    id: 5002,
                    name: "Dorosły"
                }
            ],

            /**Rodzaje zlecanych pomiarów
             * Types of ordered measurements
             */
            orderMeasurementTypes: [
                {
                    id: 1,
                    name: "Waga",
                    isAdult: true,
                    isChild: true
                },
                {
                    id: 2,
                    name: "Wzrost",
                    isAdult: true,
                    isChild: true
                },
                {
                    id: 3,
                    name: "BMI",
                    isAdult: true,
                    isChild: false
                },
                {
                    id: 4,
                    name: "BSA",
                    isAdult: true,
                    isChild: false
                },
                {
                    id: 5,
                    name: "Ciśnienie tętnicze",
                    isAdult: true,
                    isChild: true
                },
                {
                    id: 6,
                    name: "Obwód pasa",
                    isAdult: true,
                    isChild: false
                },
                {
                    id: 7,
                    name: "Glikemia",
                    isAdult: true,
                    isChild: true
                },
                {
                    id: 8,
                    name: "Obwód głowy",
                    isAdult: false,
                    isChild: true
                },
                {
                    id: 9,
                    name: "Obwód klatki piersiowej",
                    isAdult: false,
                    isChild: true
                },
                {
                    id: 10,
                    name: "Ciemię przednie",
                    isAdult: false,
                    isChild: true
                },
                {
                    id: 11,
                    name: "Ciemię tylne",
                    isAdult: false,
                    isChild: true
                },
                {
                    id: 12,
                    name: "Centyle",
                    isAdult: false,
                    isChild: true
                },
                {
                    id: 13,
                    name: "Spirometria",
                    isAdult: true,
                    isChild: true
                }
            ],
            ticketObject: {
                workerId: -1,
                patientId: -1,
                releaseDate: "",
                startDate: "",
                expiryDate: "",
                ticketType: -1,
                ticketSubType: -1,
                procedures: [],
                vaccinations: [],
                educations: [],
                medicalDevices: [],
                transport: [],
                diagTests: [],
                measurements: [],
                ekg: [],
                referral: {},
                ticketZone: 0,
                targetClinic: 0,
                serviceCode: [],
                ikzCodes: [],
                employeePositions: [],
                ticketOffice: "",
                state: 1,
                pathId: "",
                reservationTermType: 0,
                reservationMethods: [
                    0, 1, 2, 3, 4
                ],
                entityId: 0,
                created: "",
                createdBy: -1,
                isTimeless: false,
                minMinutes: 0,
                adds: [],
                apkolce: null
            },
            ticketProcedure: {
                procedureId: 0,
                icd9: "",
                ticketType: -1,
                description: "",
                localization: -1,
                localizationDescription: "",
                drug: null,
                drugGivenMethod: -1,
                dose: "",
                reason: "",
                goal: "",
                woundType: -1,
                treatmentMethod: -1,
                bandageType: -1,
                frequency: -1,
                frequencyValue: -1,
                frequencyUnits: -1,
                frequencyPeriod: -1,
                frequencyPeriodValue: -1,
                predictedCareTime: -1,
                predictedCareTimeUnits: -1,
                stitchesType: -1,
                continuation: -1,
                educationCategory: -1,
                educationCourse: -1,
                transportFrom: "",
                transportTo: "",
                transportPosition: -1,
                transportType: -1,
                isTransportDestination: false,
                vaccinationType: -1,
                delayReason: "",
                levelOfDisability: -1,
                comment: "",
                bodySide: null,
                isHighCost: false,
                isNfz: false,
                isPrivate: false,
                isSimp: false,
                externalContractor: null,
                params:[]
            },
            proceduresObject: {
                procedureId: 0,
                isScheduled: false,
                icd9: "",
                ticketType: -1,
                description: "",

                localization: -1,
                localizationDescription: "",
                drug: null,
                dose: "",
                doseNumber: "",
                doseOrder: "",
                reason: "",
                purpose: -1,
                woundType: -1,
                woundExactType: -1,
                treatmentMethod: -1,
                routeOfAdministration: -1,
                routeOfAdministratonLocation: -1,
                routeOfAdministratonLocationDescription: "",
                bandageType: -1,
                stitchesType: -1,
                stitchesDayAfter: -1,
                continuation: -1,
                comment: "",
                bodySide: null,
                isHighCost: false,
                isNfz: false,
                isPrivate: false,
                isSimp: false,
                externalContractor: null
            },
            referralObject: {
                type: 0,
                isPaperReferral: false,
                idReferralFile: null,
                eReferral: {
                    id: null,
                    eSkierowanieKod: "",
                    eSkierowanieKlucz: "",
                    eSkierowanieNumer: "",
                    eSkierowanie: "",
                    isNFZ: false,
                    authorUnitCode: "",
                },
                isReferralExternal: false,
                externalReferringClinic: "",
                internalReferringClinicId: null,
                externalReferredBy: "",
                referredBy: -1,
                statusSkierowaniaP1: -1,
                canceledId: null,
                acceptedId: null
            },
            vaccinationObject: {
                procedureId: 0,
                icd9: "",
                ticketType: -1,
                description: "",

                vaccinationType: -1,
                vaccinationDoseOrder: -1,
                vaccinationRouteOfAdministration: -1,
                vaccinationDelayReason: -1,
                comment: "",
                drug: null,
            },
            educationObject: {
                procedureId: 0,
                icd9: "",
                ticketType: -1,
                description: "",

                educationCategory: -1,
                educationCourse: -1,
            },
            medicalDevicesObject: {
                procedureId: 0,
                icd9: "",
                ticketType: -1,
                description: "",
                comment: "",
                omment47ZN: "",

                icd: null,
                mzCode: '',
                nfzCode: '',
                name: null,
                eligibilityCriteria: null,
                typeExecution: -1,
                monthly: false,
                date: new Date(),
                quantity: 0,
                quantityMonth: 0,
                months: 0,
                medicalDeviceOrderContinuation: false,
                stomia: false,
                others: false,
                fistulasNumber: 0,
                fistulasType: "",
                pregnancyWeek: 0,

                isPregnancy:false,
                isLocalization:false,
                isRightSite:false,
                isLeftSite:false,
                eligibilityCriterias:[],
                fistulasTypeEzwm:0,

                workerConfigurationId:-1,
                nfzDomain: null,
                npwzType: 0,
                senderIdType: 0,
                senderId: null,
                senderInstId: null,
                placeHasContractNFZ: false,
                placeOwNFZ: null,
                placeCodeSwd: null,

                isTestConfig: false,
                pwz: null,
                firstName: null,
                lastName: null,
                selectedInsurence:null
            },
            transportObject: {
                procedureId: 0,
                icd9: "",
                icd9Code: "",
                ticketType: -1,
                description: "",

                date: new Date(),
                hour: new Date(),
                transportFrom: "",
                transportTo: "",
                transportPosition: -1,
                transportType: -1,
                transportVehicleType: -1,
                transportVehicleText: "",
                transportPlaceClosest: -1,
                levelOfDisability: -1,
                goalType: -1,
                goal: "",
                comment: "",
                isTransportReturn: false,
                formType: null
            },
            diagTestsObject: {
                procedureId: 0,
                icd9: "",
                ticketType: -1,
                description: "",
                test: null,
            },
            examObject: {
                procedureId: 0,
                icd9: "",
                ticketType: -1,
                description: "",
            },
            measurementObject: {
                procedureId: 0,
                icd9: "",
                ticketType: -1,
                description: "",
                types: []
            },
            ekgObject: {
                procedureId: 0,
                icd9: "",
                test: null,
                ticketType: -1,
                description: "",
                comment: ""
            },

        }
    },
    methods: {
        /**Metoda wywoływania typu zlecenia
         * Method for getting order type
         */
        getOrderType(item) {
            let type = this.orderTypes.find(c => c.id == item)
            if (type) return type.name
            else return `Typ nieokreślony`
        },

        getOrderTypeId(ticketType, ticketSubType) {
            if(ticketType === 60 && ticketSubType === 6010) {
                return 6
            } else if(ticketType == 60 && ticketSubType === 6011) {
                return 7
            } else if(ticketType == 60 && ticketSubType === 6012) {
                return 8
            } else if(ticketType == 60 && ticketSubType === 6013) {
                return 9
            } else if(ticketType == 60 && ticketSubType === 6014) {
                return 10
            } else if(ticketType == 60 && ticketSubType === 6015) {
                return 11
            } else {
                let type = this.orderTypes.find(c => c.ticketType == ticketType)
                if (type) return type.id
                else return -1
            }
        },

        /**Metoda wywoływania rodzaju zastosowanego leczenia
         * Method for getting type of used treatment
         */
        getTreatmentType(item) {
            let type = this.orderTypes[0].items.find(c => c.id == item)
            if (type) return type.name
            else return `Zabieg nieokreślony`
        },

        getTreatmentTypeId(item) {
            let type = this.orderTypes[0].items.find(c => c.ticketSubType == item)
            if (type) return type.id
            else return -1
        },

        /**Metoda wywoływania
         * Method for getting
         */
        getOrderArrayValue(array, value) {
            let doc = array.find(s => s.id === value)
            return doc ? doc.name : 'Brak informacji'
        },

        getOrderTypeTicket(item) {
            let type = this.orderTypes.find(c => c.ticketType == item.ticketType)
            if (type) return type.name
            else return `Typ nieokreślony`
        },

        getTreatmentTypeTicket(item) {
            let type = this.orderTypes[0].items.find(c => c.ticketSubType == item.ticketSubType)
            if (type) return type.name
            else return `Zabieg nieokreślony`
        },

        mTranslateTicketToOrder(ticket){
            let order ={
                id:ticket.id,
                orderType: this.getOrderTypeId(ticket.ticketType, ticket.ticketSubType),
                treatmentType: null,
                procedures: [],
                vaccinations: [],
                educations: [],
                medicalDevices: [],
                transport: [],
                diagTests: [],
                measurements: [],
                ekg: [],
              }
            //   order.workerName = this.getWorkerName(ticket.workerId)
            //   order.workerPWZ = this.getWorkerPWZ(ticket.workerId)
            //   order.targetClinicName = this.getClinicName(ticket.ticketOffice)
            //   order.orderTypeName = this.getOrderType(ticket.orderType)
              
  
              if(order.orderType === 0) {
                order.treatmentType = this.getTreatmentTypeId(ticket.ticketSubType)
              } else {
                order.treatmentType = -1
              }
  
            //   if (order.facilityParams) {
            //     order.ticketOfficeName = ticket.facilityParams.clinicTypeCode + ' - ' + ticket.facilityParams.clinicTypeName
            //   } else {
            //     order.ticketOfficeName = '-'
            //   }

              if (order.orderType  === 0) {
                ticket.procedures.forEach((procedure) => {
                  let proceduresObject = JSON.parse(JSON.stringify(procedure));
                  proceduresObject.orderLocalizationsFilter = "";
                  proceduresObject.orderLocalizations4Filter = "";
                  order.procedures.push(proceduresObject);
                });
              } else if (order.orderType === 1) {
                order.vaccinations = JSON.parse(
                  JSON.stringify(ticket.vaccinations)
                );
              } else if (order.orderType === 2) {
                order.educations = JSON.parse(
                  JSON.stringify(ticket.educations)
                );
              } else if (order.orderType === 3) {
                order.measurements = JSON.parse(
                  JSON.stringify(ticket.measurements)
                );
              } else if (order.orderType === 4) {
                order.medicalDevices = JSON.parse(
                  JSON.stringify(ticket.medicalDevices)
                );
              } else if (order.orderType === 5) {
                order.transport = JSON.parse(
                  JSON.stringify(ticket.transport)
                );
              } else if (order.orderType === 6) {
                order.diagTests = JSON.parse(
                  JSON.stringify(ticket.diagTests)
                );
              } else if (order.orderType === 7) {
                order.ekg = JSON.parse(JSON.stringify(ticket.ekg));
              }

            return order
        }
    }
}