<template>
  <div>
    <b-dropdown aria-role="list" position="is-bottom-left" :key="trigger">
      <a
        style="width:20rem;"
        @click="fullscreeen"
        v-if="locks.length > 0"
        slot="trigger"
        class="dropdown-item"
      >
        <b-icon
          icon="lock"
          size="is-small"
          class="menu-larger-icon"
          :data-badge="locks.length"
          :class="{'has-badge-danger': locks.length > 0}"
        ></b-icon>Blokady pacjentów
      </a>
      <a
        style="width:20rem;"
        aria-role="listitem"
        class="dropdown-item"
        @click="fullscreeen"
        v-else
        slot="trigger"
      >
        <b-icon icon="lock-open" size="is-small" class="menu-larger-icon"></b-icon>Blokady pacjentów
      </a>

      <!-- <b-button
        outlined
        size="is-small"
        v-if="locks.length > 0"
        :type="locks.length > 0 ? 'is-danger' : ''"
        icon-left="lock"
        :data-badge="locks.length"
        :class="{'has-badge-danger': locks.length > 0}"
      >Blokady pacjentów</b-button>
      <b-button
        v-else
        outlined
        size="is-small"
        :type="locks.length > 0 ? 'is-danger' : ''"
        icon-left="lock-open"
      >Blokady pacjentów</b-button>-->

      <b-dropdown-item v-if="locks.length< 1" aria-role="listitem">
        <b-icon icon="lock-open-variant" size="is-small" class="mr-2"></b-icon>Brak blokad
      </b-dropdown-item>

      <b-dropdown-item
        v-for="item in locks"
        :key="item.index"
        @click="unlock(item)"
        aria-role="listitem"
      >
        <b-icon icon="lock" size="is-small" class="mr-2"></b-icon>
        <span v-if="item.p">{{item.p.lastName}} {{item.p.firstName}}</span>
        <span v-else>Brak informacji (ID: {{item.patient}})</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { Action } from '../../store/config_actions'

export default {
  name: 'NavPLC',
  data() {
    return {
      locks: [],
      plcInterval: null,
      patients: [],
      trigger: 0
    }
  },

  mounted() {
    //this.refresh();
    // clearInterval(this.plcInterval);
    // this.plcInterval = setInterval(function () {
    //   this.refresh();
    // }.bind(this), 10000);
  },

  methods: {
    fullscreeen() {
      // do nothing
    },

    refresh() {
      this.$store
        .dispatch(Action.PLC_ME)
        .then((response) => {
          this.locks = response

          this.locks.forEach(element => {
            this.getPatient(element.patient, element)
          });
        })
        .catch(error => {
          this.apiProblem(error);
        });
    },

    getPatient(id, item) {
      var existing = this.patients.find(p => p.id === id)

      if (existing) {
        item.p = existing
      }
      else {
        this.downloadPatient(item)
      }
    },

    downloadPatient(item) {
      this.$store
        .dispatch(Action.PATIENT_GET_BY_ID, item.patient)
        .then((response) => {
          this.patients.push(response.data)
          item.p = response.data
          this.trigger++
        })
        .catch(error => {
          this.apiProblem(error);
        });
    },

    unlock(item) {
      this.$buefy.dialog.confirm({
        message: 'Odblokować pacjenta?',
        scroll: 'keep',
        cancelText: 'Anuluj',
        okText: 'Odblokuj',
        hasIcon: true,
        type: 'is-danger',
        onConfirm: () => {
          this.$store
            .dispatch(Action.PLC_UNLOCK, item.patient)
            .then(() => {
              this.successSnackbar('Odblokowano pacjenta')
              this.refresh()
            })
            .catch(error => {
              this.apiProblem(error);
            });
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">
</style>
