import axios from 'axios'
import { Action } from '../config_actions'
import { Apis } from '../config_apis'

export default {
    namespaced: false,

    state: {

    },

    mutations: {

    },

    actions: {

        // UAC STARTS ***********************************************

        [Action.IDENTITY_UAC_CHECK](context, desc) {
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.IDENTITY_UAC2,
                    data: { action: desc },
                    headers: { Authorization: "Bearer " + token }
                }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error)
                });
            })
        },

        [Action.IDENTITY_FORCE](context, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.IDENTITY_FORCE,
                    data: payload
                }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error)
                });
            })
        },

        [Action.IDENTITY_UACS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                axios({
                    method: 'get',
                    url: Apis.IDENTITY_UAC,
                    headers: { Authorization: "Bearer " + token }
                }).then(response => {
                    resolve(response.data);
                }).catch(error => { reject(error) });
            })
        },

        // UAC ENDS *************************************************


        // PLC STARTS ***********************************************

        [Action.PLC_ALL](context) {
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: Apis.PLC_ALL,
                    headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response.data);
                }).catch(error => { reject(error) });
            })
        },

        [Action.PLC_ME](context) {
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: Apis.PLC_ME,
                    headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response.data);
                }).catch(error => { reject(error) });
            })
        },

        [Action.PLC_CHECK](context, id) {
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: Apis.PLC_CHECK.replace('{id}', id),
                    headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response.data);
                }).catch(error => { reject(error) });
            })
        },

        [Action.PLC_LOCK](context, id) {
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: Apis.PLC_LOCK.replace('{id}', id),
                    headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response.data);
                }).catch(error => { reject(error) });
            })
        },

        [Action.PLC_UNLOCK](context, id) {
            let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: Apis.PLC_UNLOCK.replace('{id}', id),
                    headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response.data);
                }).catch(error => { reject(error) });
            })
        },

        // PLC ENDS *************************************************
    }


}