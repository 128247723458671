import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'

export default {
    namespaced: false,

    state: {},

    getters: {},

    mutations: {},

    actions: {
    
        /** Attempt to add attestation. Uses POST request and requires attestation data object (check config_apis.js) */
        [Action.PATIENT_ATTESTATION_CREATE](context, attestation) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_ATTESTATION_GET_ALL,
                        data: attestation,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all attestations. Uses GET request without parameter */
        [Action.PATIENT_ATTESTATION_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_ATTESTATION_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get attestation. Uses GET request */
        [Action.PATIENT_ATTESTATION_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_ATTESTATION_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get attestation by patient id. Uses GET request */
        [Action.PATIENT_ATTESTATION_GET_BY_PATIENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_ATTESTATION_GET_BY_PATIENT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt attestation delete. Uses DELETE request */
        [Action.PATIENT_ATTESTATION_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_ATTESTATION_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt attestation edit. Uses PUT request with id and requires attestation data object (check config_apis.js)*/
        [Action.PATIENT_ATTESTATION_UPDATE](context, attestation) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_ATTESTATION_GET.replace('{id}', attestation.id),
                        data: attestation,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt attestation mark as remove. Uses POST request and requires attestation id (check config_apis.js) */
        [Action.PATIENT_ATTESTATION_REMOVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_ATTESTATION_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt attestation mark as not remove. Uses POST request and requires attestation id (check config_apis.js) */
        [Action.PATIENT_ATTESTATION_RESTORE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_ATTESTATION_RESTORE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}