<template>
  <div>
    <!-- <b-button size="is-small" @click="isActiveModalUpload = true">Dane teleadresowe</b-button> -->

    <b-button
      @click="isActiveModal = true"
      icon-left="clipboard-flow-outline" size="is-small"
      type="is-secondary"
    >Modyfikuj DILO</b-button>

    <ValidationObserver ref="observerDILO" v-slot="{ invalid }">
    <b-modal
      v-model="isActiveModal"
      has-modal-card
      aria-role="dialog"
      aria-modal
      scroll="keep"
      :can-cancel="false"
      class="p-5"
      trap-focus
      :destroy-on-hide="false"
    >
      <template #default="props">
          <div class="modal-card" style="overflow: visible">
            <header class="modal-card-head has-background-kin">
              <p class="modal-card-title has-text-light">Modyfikuj DILO:</p>
            </header>
            <section class="modal-card-body" style="min-height: 350px">
                  <ContentPanel
                    title="Karta DILO"
                    icon="file-document"
                  >

                          <b-field>
                            <ValidationProvider
                              :rules="{
                                required: true,
                                digitsPL: 11,
                              }"
                              v-slot="{ errors, valid }"
                            >
                              <b-field
                                label="Numer karty DILO (11 cyfr)"
                                label-position="on-border"
                                    :type="{
                                      'is-danger': errors[0],
                                      'is-normal': valid,
                                    }"
                                    :message="t(errors[0])"
                              >
                                <b-input v-model="diloNumber"
                                  icon="alert-circle-outline"
                                  placeholder="Wymagane"
                                ></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                </ContentPanel>
            </section>
            <footer class="modal-card-foot">
              <b-button icon-left="close" @click="props.close">Anuluj</b-button>
              <b-button
                :loading="isLoading"
                :disabled="invalid"
                @click="saveExam"
                type="is-primary"
              >Zapisz zmiany</b-button>
            </footer>
          </div>
      </template>
    </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import RISMixins from "@/mixins/ris";
import { Action } from "../../../../store/config_actions";
import { Mutation } from "../../../../store/config_mutations";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "RISPatientChangeDILO",
  mixins: [CommonHelpers, RISMixins],
  components: {
    ValidationProvider, ValidationObserver,
  },

  props: { ticket: { type: Object, require: true } },

  data: function () {
    return {
      isLoading: false,
      requestInProgress: false,
      isActiveModal: false,
    

      diloNumber: "",
    };
  },

  mounted() {
  },

  watch: {
    isActiveModal() {
      if (this.isActiveModal == true) {
          if (this.ticket) {
            if (this.ticket && this.ticket.referral) {
                this.diloNumber = this.ticket.referral.diloNumber
            }
          }
        this.$store.commit(Mutation.RIS_SET_IS_EDITING_APP, true);
      } else {
        this.$store.commit(Mutation.RIS_SET_IS_EDITING_APP, false);
        this.resetData()
      }  
    }
  },

  computed: {
    me() {
      return this.$store.state.employee.me;
    },

  },

  methods: {

    saveExam() {
      this.ticket.referral.diloNumber = this.diloNumber
      this.updateTicket(this.ticket);
    },

    updateTicket(ticket) {
      this.isActiveModal = false;
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.TICKET_PUT, ticket)
        .then((resp) => {
          this.requestInProgress = false;
          this.successSnackbar("Zmiany zapisane");
          this.$store.commit(Mutation.RIS_PUSH_TICKET_TO_STORE, resp);
          this.$emit('updated')
        })
        .catch((error) => {
          this.requestInProgress = false;
          this.apiProblem(error);
        });
    },

    resetData() {
      this.diloNumber = ""
    },
  },
};
</script>

<style scoped lang="scss">
</style>
