<template>
  <div class="avatar-background" :style="getBackgroundStyle">
    <p :style="getForegroundStyle" class="has-text-weight-semibold">{{initials}}</p>
  </div>
</template>

<script>

export default {
  name: 'MyAvatar',

  props: {
    size: { type: String, required: false },
    workerId: { type: Number, required: false }
  },

  computed: {
    me() {
      return this.$store.state.employee.me;
    },
    employees() {
      return this.$store.state.employee.all ?? [];
    },
    mySettings() {
      return this.$store.state.config.mySettings
    },

    publicSettings() {
      if (this.worker && this.mySettings && this.mySettings.others) {
        var sets = this.mySettings.others.find(x => x.employeeId == this.worker.id)

        if (this.worker.id == this.me.id) {
          return this.mySettings.public
        }

        if (sets) {
          return sets
        }
        else {
          return {
            "avatarBackground": "#398cbf",
            "avatarForeground": "#ffffff"
          }
        }
      }
      else {
        return {
          "avatarBackground": "#398cbf",
          "avatarForeground": "#ffffff"
        }
      }
    },

    getForegroundStyle() {
      return `min-width: ${this.sizeX};min-height: ${this.sizeX};width: ${this.sizeX};height: ${this.sizeX};display: table-cell;padding-bottom: calc(${this.sizeX} * 0.04);vertical-align: middle;text-align: center;text-decoration: none;font-size: calc(${this.sizeX} * 0.5);`
    },

    getBackgroundStyle() {
      if (this.mySettings) return `background-color: ${this.publicSettings.avatarBackground};color: ${this.publicSettings.avatarForeground} !important;`
      else return `background-color: #398cbf;color: #ffffff;`
    },

    sizeX() {
      if (this.size) return this.size
      else return '3rem'
    },

    worker() {
      let worker = this.me
      if (this.workerId) worker = this.getWorker(this.workerId)
      return worker
    },

    initials() {

      let val = ""

      if (this.worker) {
        val = this.worker.firstName[0] + this.worker.lastName[0]
      }
      else {
        val = "?"
      }

      return val
    }
  },

  methods: {
    getWorker(id) {
      if (this.employees) return this.employees.find((e) => e.id === id);
      else return null
    },
  }
}
</script>

<style scoped lang="scss">
.avatar-background {
  display: inline-block;
  border-radius: 50%;
}
</style>
