<template>
  <div>
   
     <a @click="isComponentModalActive = true" class="dropdown-item">
      <b-icon icon="flash" size="is-small" class="menu-larger-icon"></b-icon>Wyslij zgłoszenie
    </a>

    <!-- Report MODAL -->
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <div class="modal-card">
        <header
          class="modal-card-head has-background-kin" 
        >
          <p
            class="modal-card-title has-text-light"
            style="line-height: 2rem"
          >
            <span class="mb-2">Nowe zgłoszenie</span>
            <br />
            <span v-if="me">
              Zgłaszający: {{ me.firstName }} {{ me.lastName }} 
            </span>
          </p>
        </header>

        <section class="modal-card-body">
          <b-field label="Priorytet" label-position="on-border">
            <p class="control">
              <b-radio-button
                v-model="payload.reporterPriority"
                :native-value="1"
              >
                Bardzo ważne
              </b-radio-button>
            </p>
            <p class="control">
              <b-radio-button
                v-model="payload.reporterPriority"
                :native-value="2"
              >
                Ważne
              </b-radio-button>
            </p>
            <p class="control">
              <b-radio-button
                v-model="payload.reporterPriority"
                :native-value="3"
              >
                Standardowe
              </b-radio-button>
            </p>
          </b-field>

          <b-field expanded label="Dział" label-position="on-border">
            <b-select expanded v-model="payload.departmentId" icon="account">
              <option v-for="item in mCrmDepartment" :key="item.id" :value="item.id">
                {{item.name}}
              </option>
            </b-select>
          </b-field>

          <b-field expanded label="Kategoria" label-position="on-border">
            <b-select
              v-if="payload.departmentId == null"
              expanded
              v-model="payload.category"
              icon="account"
            >
              <option>Wybierz dział</option>
            </b-select>

            <b-select v-if="payload.departmentId == 0" expanded v-model="payload.category" icon="account">
              <option v-for="item in mCrmDigitmedCategory" :key="item.id" :value="item.id">
                {{item.name}}
              </option>
            </b-select>

            <b-select v-if="payload.departmentId == 1" expanded v-model="payload.category" icon="account">
              <option v-for="item in mCrmITCategory" :key="item.id" :value="item.id">
                  {{item.name}}
              </option>
            </b-select>

            <b-select v-if="payload.departmentId == 2" expanded v-model="payload.category" icon="account">
              <option v-for="item in mCrmMaintenanceCategory" :key="item.id" :value="item.id">
                  {{item.name}}
              </option>
            </b-select>

            <b-select v-if="payload.departmentId == 3" expanded v-model="payload.category" icon="account">
              <option v-for="item in mCrmRodoCategory" :key="item.id" :value="item.id">
                  {{item.name}}
              </option>
            </b-select>

            <b-select v-if="payload.departmentId == 4" expanded v-model="payload.category" icon="account">
              <option v-for="item in mCrmCustomerServiceCategory" :key="item.id" :value="item.id">
                  {{item.name}}
              </option>
            </b-select>

            <b-select v-if="payload.departmentId == 5" expanded v-model="payload.category" icon="account">
              <option v-for="item in mCrmHrCategory" :key="item.id" :value="item.id">
                  {{item.name}}
              </option>
            </b-select>

            <b-select v-if="payload.departmentId == 6" expanded v-model="payload.category" icon="account">
              <option v-for="item in mCrmPromotionCategory" :key="item.id" :value="item.id">
                  {{item.name}}
              </option>
            </b-select>             
          </b-field>

          <b-field
            v-if="payload.category == 'hasla' && payload.departmentId == 1"
            expanded
            label="Podkategoria"
            label-position="on-border"
          >
            <b-select expanded v-model="temporarily.subCategory" icon="account">
              <option :value="1">Medis</option>
              <option :value="2">Solab</option>
              <option :value="3">Terminal</option>
              <option :value="4">Intranet</option>
              <option :value="5">SZOI (Apkolce, Ewuś, Dilo, EZWM)</option>
            </b-select>
          </b-field>

          <b-field label="Jednostka organizacyjna" label-position="on-border" expanded>
            <b-autocomplete
              :data="filteredUnits"
              v-model="nameUnit"
              placeholder="Szukaj"
              :open-on-focus="true"
              field="name"
              @select="setUnit"
              :keep-first="true"
              expanded
            >
              <template slot-scope="props">
                <span
                  class="color-black"
                  > 
                  <p>{{ props.option.name }} ({{ props.option.internalId }})</p>
                  <template v-if="props.option.facility">
                    <p>Placówka: {{props.option.facility.name}}</p>
                    <p>
                      {{ props.option.facility.address.street }}
                      {{ props.option.facility.address.buildingNumber }}
                      {{ props.option.facility.address.apartmentNumber ? "/ " : "" }}
                      {{ props.option.facility.address.apartmentNumber }}
                    </p>
                    <p>
                      {{ props.option.facility.address.city }}
                      {{ props.option.facility.address.zipCode ? ", " : "" }}
                      {{ props.option.facility.address.zipCode }}
                    </p>
                  </template>
                  <template v-else>
                    <p>Placówka: Nieokreślona</p>
                  </template>
                </span>
              </template>
            </b-autocomplete>
            <p class="control">
                <b-button class="button"  @click="nameUnit=''"><b-icon icon="close" type="is-danger"></b-icon></b-button>
            </p>
          </b-field>

          <div v-if="tempFacility" class="content pb-1 ml-1">
            <strong>Placówka:</strong>
            <p class="ml-2">{{tempFacility.name}}<br />
              {{ tempFacility.address.street }}
              {{ tempFacility.address.buildingNumber }}
              {{ tempFacility.address.apartmentNumber ? "/ " : "" }}
              {{ tempFacility.address.apartmentNumber }}<br/>
              {{ tempFacility.address.city }}
              {{ tempFacility.address.zipCode ? ", " : "" }}
              {{ tempFacility.address.zipCode }}
            </p>
          </div>

          <b-field 
            label="Tytuł zgłoszenia" label-position="on-border"
            :message="{'Wprowadź conajmniej 3 znaki': (payload.title.length < 3) == true}"
            :type="{
              'is-danger': (payload.title.length < 3) == true
            }" 

          >
            <b-input      
              placeholder="Minimum 3 znaki"
              type="input"
              v-model="payload.title"
              maxlength="100"
            ></b-input>
          </b-field>

          <b-field 
            label="Treść zgłoszenia"
            label-position="on-border"
            :message="{'Wprowadź conajmniej 10 znaków': (payload.contentByUser.length < 10) == true}"
            :type="{
              'is-danger': (payload.contentByUser.length < 10) == true
            }" 
          >
            <b-input
              placeholder="Minimum 10 znaków"
              type="textarea"
              v-model="payload.contentByUser"
              maxlength="300"
            ></b-input>
          </b-field>

          

          <p class="content">
            <b>Wybrane przedmioty:</b>
            <section>
              <b-field
                v-for="(item, index) in downloadInventoryItems"
                :key="index"
              >
                <b-tag type="is-primary" closable @close="removeInventoryItem(item)">
                  {{ item.barcode }} ({{
                    mGetName(mInventoryItemCategories, item.category)
                  }}, {{ item.description }}, {{getFacilityByInternalIdFromRoom(item.room)}}, pokój nr: {{item.room.number}})
                </b-tag>

              </b-field>
            </section>
          </p>
          <b-field
            label="Wyszukaj przedmiot po numerze katalogowym"
            label-position="on-border"
            expanded
          >
            <b-input
              expanded
              placeholder="np. P000073"
              type="search"
              v-model="barcodeSearch"
            ></b-input>
            <p class="control">
              <b-button
                class="button is-primary"
                @click="getItemInventory"
                :loading="loading"
                :disabled="!barcodeSearch"
                >Wyszukaj</b-button
              >
            </p>
          </b-field>

          <p class="content">
            <b>Wgrane pliki:</b>
            <section>
            <!-- <span v-for="(media, index) in payload.media" :key="index">{{
              media
            }}</span> -->
            <b-field v-for="(media, index) in uploadedFiles" :key="index">
              <b-tag type="is-primary" closable @close="removeUploadFile(media)">{{media.name}} ({{media.description}})</b-tag>
            </b-field>
            </section>
          </p>
          <!-- <b-field
            class="file is-primary"
            :class="{ 'has-name': !!currentFile.file }"
            :message="uploadFileMessage"
            :type="{
              'is-danger': uploadFileMessage != null,
              'is-success': uploadFileMessage == null,
            }"
          >
            <b-upload
              v-model="currentFile.file"
              class="file-label"
              accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/msword,application/rtf,text/plain,image/*"
            >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Wybierz plik</span>
              </span>
              <span class="file-name" v-if="currentFile.file">{{
                currentFile.file.name
              }}</span>
            </b-upload>
          </b-field> -->
          <b-field>
            <p class="control">
                <b-field
                  class="file is-primary"
                  :class="{ 'has-name': !!currentFile.file }"
                  :message="uploadFileMessage"
                  :type="{
                    'is-danger': uploadFileMessage != null,
                    'is-success': uploadFileMessage == null,
                  }"
                >
              <b-upload
                v-model="currentFile.file"
                class="file-label"
                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/msword,application/rtf,text/plain,image/*"
              >
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-cta file-name" style="max-width:200px" v-if="currentFile.file">{{
                  currentFile.file.name
                }}</span>
                  <span class="file-label" v-else>Wybierz plik</span>

                </span>
                
              </b-upload>
            </b-field>
            </p>
            <b-input
              type="text"
              placeholder="Opis..."
              v-model="currentFile.description"
            ></b-input>
            <p class="control">
              <b-button type="is-primary" :disabled="!currentFile.file" @click="sendFile"
                >Załącz plik do raportu</b-button
              >
            </p>
          </b-field>
        </section>

        <footer class="modal-card-foot buttons is-right">
          <b-button
            @click="cancel"
          >
            Zamknij
          </b-button>
          <b-button
            :disabled="
              payload.contentByUser.length < 10 ||
              payload.reporterPriority === null ||
              payload.departmentId === null ||
              payload.category === ''||
              payload.title.length < 3
            "
            :loading="loading"
            type="is-primary"
            @click="sendReport"
            >Wyślij</b-button
          >
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
//import moment from "moment";
import CommonMixins from "../../mixins/commons";
import InventoryMixins from "@/mixins/inventory";
import { Action } from "../../store/config_actions";
import CrmMixins from "../../mixins/crm/crm";

export default {
  name: "CrmReportForm",
  mixins: [CommonMixins, InventoryMixins, CrmMixins],

  components: {},

  props: {
    // target: { type: String, required: true }
  },

  watch: {
    isComponentModalActive(val) {
      if (val === false) {
        this.downloadInventoryItems = [];
        this.barcodeSearch = null;
        this.currentFile = {
          description: "",
          type: 0,
          file: null,
          name: "",
        };
      }
    },

    fileSize(val) {
      if (val != null) {
        if (val > 1100000) {
          this.uploadFileMessage = "Za duży rozmiar pliku (max. 1.1 MB)";
        } else {
          this.uploadFileMessage = null;
        }
      } else {
        this.uploadFileMessage = null;
      }
    },
  },

  mounted() {
    if (!this.facilities || this.facilities.length <= 0) {
      this.downloadFacilities();
    }

    if (!this.units || this.units.length <= 0) {
      this.getUnits();
    } else {
      this.translateUnits();
    }    
  },

  data: function () {
    return {
      isComponentModalActive: false,
      nameUnit: "",
      loading: false,
      temporarily: {
        reportMethod: 0,
        subCategory: 0,
      },
      payload: {
        reportedBy: -1,
        category: 2,
        title: "",
        contentByUser: "",
        reporterPriority: 3,
        departmentId: 0,
        reported: this.mNow(),
        checklists: [],
        inventoryItems: [],
        media: [],
        organizationalUnitId: null,
        email: "",
        reportMethod:0
      },
      checklist: {
        created: this.mNow(),
        createdBy: -1,
      },
      item_sign: "",
      file: null,
      items: ["ZZ78562", "AA75627", "BB36485", "CC73610", "DD984351"],
      selected_item: null,
      downloadInventoryItems: [],
      uploadedFiles: [],
      barcodeSearch: null,
      currentFile: {
        description: "",
        type: 0,
        file: null,
        name: "",
      },
      uploadFileMessage: null,
      unitsTranslated: [],
      tempFacility:null
    };
  },

  computed: {
    myId() {
      return this.$store.state.employee.me
        ? this.$store.state.employee.me.id
        : -1;
    },
    me() {
      return this.$store.state.employee.me;
    },
    clinic() {
      return this.$store.state.clinic.activeClinic;
    },
    filteredItemsArray() {
      return this.items.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.item_sign.toLowerCase()) >= 0
        );
      });
    },

    fileSize() {
      if (this.currentFile && this.currentFile.file) {
        return this.currentFile.file["size"];
      } else return null;
    },

    units() {
      return this.$store.state.orgUnit.all ?? [];
    },

    facilities() {
      return this.$store.state.clinic.clinics ?? [];
    },

    filteredUnitsOld() {
      return this.unitsTranslated.filter((option) => {
          if (option.name) {
            return (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.nameUnit.toLowerCase()) >= 0
            );
          }
          if(option.facility){
            if(option.facility.name){
              return (
                option.facility.name
                  .toString()
                  .toLowerCase()
                  .indexOf(this.nameUnit.toLowerCase()) >= 0
              );
            }

            if(option.facility.address.street){
              return (
                option.facility.address.street
                  .toString()
                  .toLowerCase()
                  .indexOf(this.nameUnit.toLowerCase()) >= 0
              );
            }

            if(option.facility.address.city){
              return (
                option.facility.address.city
                  .toString()
                  .toLowerCase()
                  .indexOf(this.nameUnit.toLowerCase()) >= 0
              );
            }
          }
        
      });
    },

    filteredUnits() {
      return this.unitsTranslated.filter((option) => {
        let result = false;
          if (option.name) {
            result = (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.nameUnit.toLowerCase()) >= 0
            );
          }
          if(!result && option.facility){
            if(option.facility.name){
              result = (
                option.facility.name
                  .toString()
                  .toLowerCase()
                  .indexOf(this.nameUnit.toLowerCase()) >= 0
              );
            }

            if(!result && option.facility.address.street){
              result =  (
                option.facility.address.street
                  .toString()
                  .toLowerCase()
                  .indexOf(this.nameUnit.toLowerCase()) >= 0
              );
            }

            if(!result && option.facility.address.city){
              result =  (
                option.facility.address.city
                  .toString()
                  .toLowerCase()
                  .indexOf(this.nameUnit.toLowerCase()) >= 0
              );
            }

          }

          return result;
        
      });
    },
  },

  methods: {
    async sendReport() {
      if (this.currentFile.file != null) {
        await this.sendFile()
      }

      this.loading = true;
      this.checklist.created = this.mNow();
      this.checklist.createdBy = this.myId;
      this.payload.reported = this.mNow();
      this.payload.reportedBy = this.myId;
      this.payload.inventoryItems = this.downloadInventoryItems.map(
        (item) => item.id
      );
      this.payload.media = [];
      this.payload.media = this.uploadedFiles.map((media) => media.id);
      if (this.temporarily.subCategory == 1) {
        this.payload.title = this.payload.title.concat(" (Medis)");
      }
      if (this.temporarily.subCategory == 2) {
        this.payload.title = this.payload.title.concat(" (Solab)");
      }
      if (this.temporarily.subCategory == 3) {
        this.payload.title = this.payload.title.concat(" (Terminal)");
      }
      if (this.temporarily.subCategory == 4) {
        this.payload.title = this.payload.title.concat(" (Intranet)");
      }
      if (this.temporarily.subCategory == 5) {
        this.payload.title = this.payload.title.concat(" (SZOI)");
      }
      if (this.me && this.me.email) {
        this.payload.email = this.me.email;
      }
      this.$store
        .dispatch(Action.CRM_ADD_CHECKLIST, this.checklist)
        .then((response) => {
          this.payload.checklists.push(response.id);
          //this.successSnackbar("Checklista dodana");
          this.$store
            .dispatch(Action.CRM_ADD_REPORT, this.payload)
            .then(() => {
              this.successSnackbar("Zgłoszenie wysłane");
              this.loading = false;
              this.isComponentModalActive = false;
              this.payload.contentByUser = "";
              this.payload.category = 2;
              this.payload.reported = this.mNow();
              this.payload.reporterPriority = 3;
              this.payload.departmentId = 0;
              this.downloadInventoryItems = [];
              this.uploadedFiles = [];
              this.payload.organizationalUnitId = null;
              this.payload.title = "";
              this.nameUnit = "";
              this.tempFacility = null;
            })
            .catch((error) => {
              this.apiProblem(error);
              this.loading = false;
            });
          this.payload.checklists = [];
          this.loading = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.loading = false;
        });
    },

    cancel() {
      this.isComponentModalActive = false;
      this.payload.reportedBy = -1;
      this.payload.category = 2;
      this.payload.title = "";
      this.payload.contentByUser = "";
      this.payload.reporterPriority = 3;
      this.payload.departmentId = 0;
      this.payload.reported = this.mNow();
      this.payload.checklists = [];
      this.payload.organizationalUnitId = null;
      this.payload.email = "";
    },

    showAddItem() {
      this.$buefy.dialog.prompt({
        message: `Dodaj przedmiot`,
        inputAttrs: {
          placeholder: "np. ZZ876529",
          maxlength: 20,
          value: this.item_sign,
        },
        confirmText: "Dodaj",
        onConfirm: (value) => {
          this.items.push(value);
          //this.$refs.autocomplete.setSelected(value);
        },
      });
    },

    setUnit(option) {
      if (option) {
        this.payload.organizationalUnitId = option.id;
        this.tempFacility = option.facility
      } else {
        this.payload.organizationalUnitId = null;
        this.tempFacility = null;
      }
    },

    removeInventoryItem(item) {
      let indexItem = this.downloadInventoryItems.indexOf(item);
      if (indexItem >= 0) {
        this.downloadInventoryItems.splice(indexItem, 1);
      }
    },

    removeUploadFile(item) {
      let indexFile = this.uploadedFiles.indexOf(item);
      if (indexFile >= 0) {
        this.uploadedFiles.splice(indexFile, 1);
        this.removeFile(item.id);
      } else {
        this.dangerSnackbar("Nie udało się usunąć pliku");
      }
    },

    getItemInventory() {
      if(!this.barcodeSearch){
        this.dangerSnackbar("Proszę wpisać numer ewidencyjny")
      }else{
      let itemInventory = this.downloadInventoryItems.find(
        (item) => item.barcode === this.barcodeSearch
      );

      if (itemInventory) {
        this.snackbar(
          "Przedmiot już dodany nie można dodać dwóch takich samych"
        );
      } else {
          this.loading = true;
          this.$store
            .dispatch(Action.INVENTORY_GET_ITEM_BY_BARCODE, this.barcodeSearch)
            .then((response) => {
              if (response) {
                let translateItem = response;
                this.$store
                  .dispatch(Action.INVENTORY_GET_ROOM, response.roomId)
                  .then((data) => {
                    translateItem.room = data;
                    this.downloadInventoryItems.push(translateItem);
                    this.loading = false;
                  })
                  .catch((error) => {
                    this.downloadInventoryItems.push(translateItem);
                    this.apiProblem(error);
                    this.loading = false;
                  });
              } else {
                this.dangerSnackbar(
                  `Nie znaleziono przedmiotu z barcode:${this.barcodeSearch}`
                );
              }
              this.loading = false;
            })
            .catch((error) => {
              this.apiProblem(error);
              this.loading = false;
            });
          }
      }
    },

    translateUnits() {
      this.unitsTranslated = [];
      if (this.units && this.units.length > 0) {
        this.units.forEach((unit) => {

          if(![103, 105, 106, 113, 114, 115].includes(unit.type)) {
            return;
          }

          let facility = null;
          let pos = this.facilities.find((s) => s.id === unit.facilityId);

          if (pos) {
            facility = pos;
          }

          let trans = {
            id: unit.id,
            name: unit.name,
            internalId: unit.internalId,
            facility: facility,
          };
          this.unitsTranslated.push(trans);
        });
      }
    },

    getUnits() {
      this.loading = true;
      this.$store
        .dispatch(Action.FINANCE_ORGANIZATIONALUNIT_GET_ALL)
        .then(() => {
          this.translateUnits();
          // this.successSnackbar("pobrano jednostki");
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar("Nie pobrano jednostek");
          this.apiProblem(error);
          this.loading = false;
        });
    },

    downloadFacilities() {
      this.$store
        .dispatch(Action.FACILITIES_GET_ALL)
        .then(() => {
          //this.successSnackbar("Pobrano dane placówek");
          this.translateUnits();
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },

    getFacilityByInternalIdFromRoom(room) {
      let app = null;
      if (room) {
        app = this.facilities.find((a) => a.internalId === room.facilityId);
      }

      return app ? app.name : `Nieokreślona placówka`;
    },

    async sendFile() {
      if (this.currentFile.file != null) {
        if (this.currentFile.file["size"] <= 1100000) {
          this.currentFile.name = this.currentFile.file.name;
          this.loading = true;
          await this.$store
            .dispatch(Action.CRM_CRMMEDIA_UPLOAD, this.currentFile)
            .then((response) => {
              //this.payload.media.push(response.id);
              this.uploadedFiles.push(response);
              this.currentFile = {
                description: "",
                type: 0,
                file: null,
                name: "",
              };
              this.loading = false;
            })
            .catch((error) => {
              this.apiProblem(error);
              this.loading = false;
            });
        } else {
          this.dangerSnackbar(
            "Rozmiar pliku przekracza dopuszczalny rozmiar(1.1MB). "
          );
        }
      } else {
        this.dangerSnackbar("Musisz wybrać plik!");
      }
    },

    removeFile(id) {
      this.loading = true;
      this.$store
        .dispatch(Action.CRM_CRMMEDIA_DELETE, id)
        .then(() => {
          this.loading = false;
          this.successSnackbar("Usunięto plik");
        })
        .catch((error) => {
          this.apiProblem(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap");
.coolFont {
  color: black;
  font-family: "Lato", sans-serif;
}
</style>
