import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        modules:[],
        databases: [],
        clients: []
    },

    mutations: {
        [Mutation.DIGITMED_MODULES_SET_ALL](currentState,payload){
            currentState.modules = payload
        },

        [Mutation.FINANCE_DATABASES_SET_ALL](currentState,payload){
            currentState.databases = payload
        },

        [Mutation.FINANCE_DATABASES_PUSH](currentState, database)
        {
            let found = currentState.databases.find(item => item.id == database.id)

            if(found){
                let index = currentState.databases.indexOf(found)
                currentState.databases.splice(index, 1, database)
            }else{
                currentState.databases.push(database);
            }
        },

        [Mutation.FINANCE_DATABASES_SPLICE](currentState, id)
        {
            let found = currentState.databases.find(item => item.id == id)

            if(found){
                let index = currentState.databases.indexOf(found)
                currentState.databases.splice(index, 1)
            }
        },

        setClients(currentState,payload){
            currentState.clients = payload
        },

        pushClient(currentState,payload){
            let found = currentState.clients.find(item => item.id == payload.id)

            if(found){
                let index = currentState.clients.indexOf(found)
                currentState.clients.splice(index, 1, payload)
            }else{
                currentState.clients.push(payload);
            }
        },

        spliceClient(currentState,id){
            let found = currentState.clients.find(item => item.id == id)

            if(found){
                let index = currentState.clients.indexOf(found)
                currentState.clients.splice(index, 1)
            }
        }
    },

    actions: {
        [Action.FINANCE_DATABASE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_DATABASE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_DATABASES_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_DATABASE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_DATABASE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_DATABASE_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_DATABASE_CREATE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.FINANCE_DATABASES_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_DATABASE_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_DATABASE_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.FINANCE_DATABASES_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_DATABASE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_DATABASE_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.FINANCE_DATABASES_SPLICE, id)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


        //Clients
        [Action.FINANCE_CLIENT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_CLIENT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit("setClients", response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENT_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_CLIENT_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENT_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_CLIENT_CREATE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit("pushClient", response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENT_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_CLIENT_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit("pushClient", response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_CLIENT_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit("spliceClient", response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //Clients frame
        [Action.FINANCE_CLIENTFRAME_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_CLIENTFRAME_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENTFRAME_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_CLIENTFRAME_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        
        [Action.FINANCE_CLIENTFRAME_GET_BY_CLIENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_CLIENTFRAME_GET_BY_CLIENT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENTFRAME_CREATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_CLIENTFRAME_CREATE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENTFRAME_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_CLIENTFRAME_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENTFRAME_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_CLIENTFRAME_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENTFRAME_STRATEGY_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_CLIENTFRAME_STRATEGY_ADD.replace('{frameId}',payload.frameId),
                        data: payload.strategy,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENTFRAME_STRATEGY_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_CLIENTFRAME_STRATEGY_UPDATE.replace('{frameId}',payload.frameId).replace('{strategyId}',payload.strategyId),
                        data: payload.strategy,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_CLIENTFRAME_STRATEGY_DELETE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_CLIENTFRAME_STRATEGY_DELETE.replace('{frameId}',payload.frameId).replace('{strategyId}',payload.strategyId),                        
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}