import Vue from 'vue'
import { MLInstaller, MLCreate } from 'vue-multilanguage'

import polishLocalization from './polski/config'
//import englishLocalization from './english/config'

Vue.use(MLInstaller)

export default new MLCreate({
    initial: 'polski',
    save: process.env.NODE_ENV === 'production',
    languages: [polishLocalization]
    //languages: [polishLocalization, englishLocalization]
})

