import axios from 'axios'
import moment from 'moment'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        // clinics: [{
        //     "clinicId": "-1",
        //     "clinicName": "Brak"
        // }],
        clinics: [],
        tmpClinics: [],

        activeClinic: {
            "clinicId": "-1",
            "clinicName": "Brak"
        }
    },

    mutations: {
        [Mutation.CLINICS_SET_ALL](currentState, clinics) {
            currentState.clinics = clinics
        },

        [Mutation.CLINICS_SET_ALL_TMP](currentState, clinics) {

            clinics.forEach(c => c.internalId = c.clinicId)
            currentState.tmpClinics = clinics

            if (currentState.tmpClinics.length > 0) {
                // currentState.activeClinic = currentState.tmpClinics[0]
            }
        },

        [Mutation.CLINICS_SET_ACTIVE](currentState, clinic) {
            currentState.activeClinic = clinic
        },
    },

    actions: {
        //it's old method, mobi version 1
        // [Action.CLINICS_ADD_CLINIC_ADDRESS](context, clinic) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         if (token != null) {

        //             let mobiAccount = {
        //                 fullName: clinic.name,
        //                 city: clinic.address.city,
        //                 zip: clinic.address.zipCode,
        //                 street: clinic.address.street,
        //                 buildingNumber: clinic.address.buildingNumber,
        //                 apartmentNumber: clinic.address.apartmentNumber ? clinic.address.apartmentNumber : '',
        //                 created: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        //                 externalId: clinic.shortName,
        //                 parcelUserType: 2
        //             }

        //             if (clinic.type === 1) {
        //                 mobiAccount.parcelUserType = 10
        //             }

        //             axios({
        //                 method: 'post',
        //                 //url: Apis.MOBI_ADD_USER,
        //                 url: Apis.MOBI2_ADD_USER,
        //                 data: mobiAccount,
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 let savedMobi = response.data
        //                 clinic.mobiAccountId = savedMobi.id

        //                 axios({
        //                     method: 'post',
        //                     url: Apis.FINANCE_FACILITIES_CREATE,
        //                     data: clinic,
        //                     headers: { Authorization: "Bearer " + token }
        //                 }).then(response2 => {
        //                     resolve(response2.data);
        //                 }).catch(error => { reject(error) });
        //             }).catch(error => { reject(error) });


        //         } else reject('401')
        //     })
        // },

        [Action.CLINICS_ADD_CLINIC_ADDRESS](context, clinic) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {

                    axios({
                        method: 'post',
                        url: Apis.FINANCE_FACILITIES_CREATE,
                        data: clinic,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response2 => {
                        resolve(response2.data);

                        let mobiAccount = {
                            fullName: clinic.name,
                            city: clinic.address.city,
                            zip: clinic.address.zipCode,
                            street: clinic.address.street,
                            buildingNumber: clinic.address.buildingNumber,
                            apartmentNumber: clinic.address.apartmentNumber ? clinic.address.apartmentNumber : '',
                            created: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                            externalId: response2.data.id,
                            parcelUserType: 2
                        }
    
                        if (clinic.type === 1) {
                            mobiAccount.parcelUserType = 10
                        }
    
                        axios({
                            method: 'post',
                            //url: Apis.MOBI_ADD_USER,
                            url: Apis.MOBI2_ADD_USER,
                            data: mobiAccount,
                            headers: { Authorization: "Bearer " + token }
                        }).then(response => {
                            let savedMobi = response.data
                            clinic.mobiAccountId = savedMobi.id                        
                        }).catch(error => { reject(error) });


                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // [Action.CLINICS_UPDATE_ADDRESS](context, clinic) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         if (token != null) {

        //             let mobiAccount = {
        //                 fullName: clinic.name,
        //                 city: clinic.address.city,
        //                 zip: clinic.address.zipCode,
        //                 street: clinic.address.street,
        //                 buildingNumber: clinic.address.buildingNumber,
        //                 apartmentNumber: clinic.address.apartmentNumber ? clinic.address.apartmentNumber : '',
        //                 created: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        //                 externalId: clinic.shortName,
        //                 parcelUserType: 2
        //             }

        //             axios({
        //                 method: 'put',
        //                 url: Apis.MOBI_EDIT_USER.replace('{id}', clinic.mobiAccountId),
        //                 data: mobiAccount,
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 let savedMobi = response.data
        //                 clinic.mobiAccountId = savedMobi.id

        //                 axios({
        //                     method: 'put',
        //                     url: Apis.FINANCE_FACILITIES_UPDATE.replace('{id}', clinic.id),
        //                     data: clinic,
        //                     headers: { Authorization: "Bearer " + token }
        //                 }).then(response2 => {
        //                     resolve(response2.data);
        //                 }).catch(error => { reject(error) });

        //             }).catch(error => { reject(error) });


        //         } else reject('401')
        //     })
        // },

        [Action.CLINICS_UPDATE_ADDRESS](context, clinic) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_FACILITIES_UPDATE.replace('{id}', clinic.id),
                        data: clinic,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response2 => {
                        resolve(response2.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_FACILITY_UPDATE](context, clinic) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_FACILITIES_UPDATE.replace('{id}', clinic.id),
                        data: clinic,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response2 => {
                        resolve(response2.data);
                    }).catch(error => { reject(error) });

                } else reject('401')
            })
        },



        // [Action.CLINICS_GET_ADRESSES](context) {
        //     return new Promise((resolve, reject) => {

        //         if (context.rootState.identity.session) {
        //             let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //             if (token != null) {
        //                 axios({
        //                     method: 'get',
        //                     url: Apis.CLINICS_GET_CLINIC_ADRESSES,
        //                     headers: { Authorization: "Bearer " + token }
        //                 }).then(response => {
        //                     context.commit(Mutation.CLINICS_SET_ALL, response.data)
        //                     resolve(response);
        //                 }).catch(error => { reject(error) });
        //             } else reject('401')
        //         } else reject('401')

        //     })
        // },

        [Action.FACILITIES_GET_ALL](context) {
            return new Promise((resolve, reject) => {

                if (context.rootState.identity.session) {
                    let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                    if (token != null) {
                        axios({
                            method: 'get',
                            url: Apis.FINANCE_FACILITIES_GET_ALL,
                            headers: { Authorization: "Bearer " + token }
                        }).then(response => {

                            response.data.forEach(c => {
                                c.clinicId = c.internalId
                            })

                            context.commit(Mutation.CLINICS_SET_ALL, response.data)
                            context.commit(Mutation.FINANCE_SET_FACILTIES, response.data)
                            resolve(response);
                        }).catch(error => { reject(error) });
                    } else reject('401')
                } else reject('401')

            })
        },

        [Action.FINANCE_FACILITIES_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {

                if (context.rootState.identity.session) {
                    let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                    if (token != null) {
                        axios({
                            method: 'get',
                            url: Apis.FINANCE_FACILITIES_GET_BY_ID.replace('{id}', id),
                            headers: { Authorization: "Bearer " + token }
                        }).then(response => {
                            resolve(response.data);
                        }).catch(error => { reject(error) });
                    } else reject('401')
                } else reject('401')

            })
        },



        /** Attempt to get worker. Uses GET request */
        [Action.CLINICS_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CLINICS_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.CLINICS_SET_ALL_TMP, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.FACILITY_REMOVE](context, clinic) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_FACILITIES_DELETE.replace('{id}', clinic.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        }
    }
}