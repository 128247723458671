export default {
  data: function () {
    return {
      /**Oddziały wojewódzkie NFZ
       * NFZ Regional Branches
       */
      mBranchNfz: [
        {
          value: 1,
          text: '01 DOLNOŚLĄSKI OW NFZ',
        },
        {
          value: 2,
          text: '02 KUJAWSKO-POMORSKI OW NFZ',
        },
        {
          value: 3,
          text: '03 LUBELSKI OW NFZ',
        },
        {
          value: 4,
          text: '04 LUBUSKI OW NFZ',
        },
        {
          value: 5,
          text: '05 ŁÓDZKI OW NFZ',
        },
        {
          value: 6,
          text: '06 MAŁOPOLSKI OW NFZ',
        },
        {
          value: 7,
          text: '07 MAZOWIECKI OW NFZ',
        },
        {
          value: 8,
          text: '08 OPOLSKI OW NFZ',
        },
        {
          value: 9,
          text: '09 PODKARPACKI OW NFZ',
        },
        {
          value: 10,
          text: '10 PODLASKI OW NFZ',
        },
        {
          value: 11,
          text: '11 POMORSKI OW NFZ',
        }, {
          value: 12,
          text: '12 ŚLĄSKI OW NFZ',
        },
        {
          value: 13,
          text: '13 ŚWIĘTOKRZYSKI OW NFZ',
        },
        {
          value: 14,
          text: '14 WARMIŃSKO-MAZURSKI OW NFZ',
        },
        {
          value: 15,
          text: '15 WIELKOPOLSKI OW NFZ',
        },
        {
          value: 16,
          text: '16 ZACHODNIOPOMORSKI OW NFZ',
        }
      ],
    }
  },

  methods: {
    /**Metoda wywoływania id oddziału wojewódzkiego NFZ
     * Method for getting NFZ Regional Branch id
     */
    mGetBranchNfz(id) {
      let type = this.mBranchNfz.find(c => c.value == id)
      if (type) return type
      else return `??? [${id}]`
    },
    /**Metoda wywoływania nazwy oddziału wojewódzkiego NFZ
     * Method for getting NFZ Regional Branch name
     */
    mGetBranchNfzName(id) {
      let type = this.mBranchNfz.find(c => c.value == id)
      if (type) return type.text
      else return `??? [${id}]`
    },
    mGetBranchNfzNameParsed(idRaw) {
      if (idRaw) {
        let id = parseInt(idRaw)
        let type = this.mBranchNfz.find(c => c.value == id)
        if (type) return type.text
        else return `??? [${id}]`
      }
      else {
        return 'Brak danych'
      }
    }
  }
}