<template>
  <div class="in-line">
    <b-tooltip type="is-dark" label="SZOK Talk" :delay="500" class="hand" position="is-bottom">
      <b-button
        :type="unread > 0 ? 'is-orange':'is-primelight'"
        :outlined="unread < 1"
        size="is-small"
        @click="show"
        style="position: relative;"
        icon-left="chat"
      >
        <span v-if="unread > 0" class="has-text-weight-bold">{{unread}}</span>
      </b-button>
    </b-tooltip>
  </div>
</template>

<script>

export default {
  name: "TalkNavigationButton",

  computed: {
    /** 
     * User chat groups.
     */
    groups() { return this.$store.state.talk.groups },

    /**
     * Messages unread by me.
     */
    unread() {
      if (this.groups) {
        let count = 0
        this.groups.forEach(element => {
          if (element.items) {
            count += element.items.filter(x => this.isUnread(x)).length
          }
        });

        return count
      }
      else return 0
    },

    /** 
     * My account. 
     */
    me() { return this.$store.state.employee.me },
  },

  methods: {
    /**
     * Unread message marker.
     */
    isUnread(item) {
      if (item.readByEmployees) {
        let mark = item.readByEmployees.find(x => x.id == this.me.id)

        if (mark) return false
        else return true

      }
      else return true
    },

    /**
     * Emit show signal.
     */
    show() {
      this.$emit('show')
    },

  },
};
</script>

<style scoped>
</style>
