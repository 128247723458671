import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        slots: [],
        openOneclickAddModal:false,
        currentBlock:null
    },

    mutations: {
        [Mutation.SCHEDULE_SET_SLOTS](currentState, payload) {
            currentState.slots = payload
        },

        [Mutation.SCHEDULE_SET_ONECLICK_ADD_MODAL](currentState, payload) {
            currentState.openOneclickAddModal = payload
        },

        [Mutation.SCHEDULE_SET_CURRENT_BLOCK](currentState,payload){
            currentState.currentBlock = payload
        }
    },

    actions: {

        /** Attempt to get worker. Uses GET request */
        [Action.SCHEDULE_GET_GROUPS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                let cid = context.rootState.clinic.activeClinic.clinicId;
                let url = Apis.SCHEDULE_GET_GROUPS.replace('{cid}', cid)

                if (token != null) {
                    axios({
                        method: 'post',
                        url: url,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.SCHEDULE_ADD](context, payload) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    data: payload,
                    url: Apis.SCHEDULE_ADD_WITH_POZ.replace('{cid}', cid)
                }).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                });
            })
        },

        [Action.SCHEDULE_FREESLOT_ADD](context, payload) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    data: payload,
                    url: Apis.SCHEDULE_FREESLOT_ADD_OR_GET.replace('{cid}', cid)
                }).then((response) => {
                    resolve(response);
                }).catch(error => { reject(error) });
            })
        },

        [Action.SCHEDULE_REMOVE](context, id) {
            let cid = context.rootState.clinic.activeClinic.clinicId;
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.SCHEDULE_REMOVE_APPOINTMENT.replace('{id}', id).replace('{cid}', cid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}