export default {

  data: () => {
    return {

      /* Doctor appointment categories */
      mClinics: [
        // PORADNIE I PRACOWNIE
        {
          id: 10,
          code: "0010",
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia (gabinet) lekarza podstawowej opieki zdrowotnej (0010)"
        },
        {
          id: 12,
          code: "0012",
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia (gabinet) podstawowej opieki zdrowotnej (0012)"
        },
        {
          id: 14,
          code: "0014",
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia (gabinet) lekarza rodzinnego (0014)"
        },
        {
          id: 16,
          code: "0016",
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia (gabinet) nocnej i świątecznej opieki zdrowotnej (0016)"
        },
        {
          id: 20,
          code: "0020",
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Punkt felczerski (0020)"
        },
        {
          id: 32,
          code: "0032",
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia (gabinet) pielęgniarki podstawowej opieki zdrowotnej (0032)"
        },
        {
          id: 34,
          code: "0034",
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia (gabinet) położnej podstawowej opieki zdrowotnej (0034)"
        },
        {
          id: 36,
          code: "0036",
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia (gabinet) pielęgniarki rodzinnej (0036)"
        },
        {
          id: 38,
          code: "0038",
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia (gabinet) położnej rodzinnej (0038)"
        },
        {
          id: 40,
          code: "0040",
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Gabinet profilaktyki zdrowotnej i pomocy przedlekarskiej (0040)"
        },
        {
          id: 1000,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chorób wewnętrznych (1000)"
        },
        {
          id: 1008,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chorób metabolicznych (1008)"
        },
        {
          id: 1010,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia alergologiczna (1010)"
        },
        {
          id: 1020,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia diabetologiczna (1020)"
        },
        {
          id: 1022,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia leczenia zespołu stopy cukrzycowej (1022)"
        },
        {
          id: 1024,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia leczenia ran przewlekłych (1024)"
        },
        {
          id: 1027,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia endokrynologii i diabetologii dziecięcej (1027)"
        },
        {
          id: 1030,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia endokrynologiczna (1030)"
        },
        {
          id: 1032,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia endokrynologiczno-ginekologiczna (1032)"
        },
        {
          id: 1034,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia andrologiczna (1034)"
        },
        {
          id: 1036,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia leczenia niepłodności (1036)"
        },
        {
          id: 1038,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chorób tarczycy (1038)"
        },
        {
          id: 1040,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia endokrynologiczna osteoporozy (1040)"
        },
        {
          id: 1050,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia gastroenterologiczna (1050)"
        },
        {
          id: 1056,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia hepatologiczna (1056)"
        },
        {
          id: 1060,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia geriatryczna (1060)"
        },
        {
          id: 1070,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia hematologiczna (1070)"
        },
        {
          id: 1072,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia nowotworów krwi (1072)"
        },
        {
          id: 1080,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia immunologiczna (1080)"
        },
        {
          id: 1100,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia kardiologiczna (1100)"
        },
        {
          id: 1102,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia wad serca (1102)"
        },
        {
          id: 1104,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia nadciśnienia tętniczego (1104)"
        },
        {
          id: 1120,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chorób naczyń (1120)"
        },
        {
          id: 1130,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia nefrologiczna (1130)"
        },
        {
          id: 1150,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia toksykologiczna (1150)"
        },
        {
          id: 1160,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia medycyny pracy (1160)"
        },
        {
          id: 1180,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia medycyny paliatywnej (1180)"
        },
        {
          id: 1200,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia dermatologiczna (1200)"
        },
        {
          id: 1202,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia wenerologiczna (1202)"
        },
        {
          id: 1210,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia genetyczna (1210)"
        },
        {
          id: 1212,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia genetyczno-onkologiczna (1212)"
        },
        {
          id: 1220,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia neurologiczna (1220)"
        },
        {
          id: 1222,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia leczenia bólu (1222)"
        },
        {
          id: 1240,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia onkologiczna (1240)"
        },
        {
          id: 1242,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chemioterapii (1242)"
        },
        {
          id: 1244,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia radioterapii (1244)"
        },
        {
          id: 1249,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia onkologii i hematologii dziecięcej (1249)"
        },
        {
          id: 1250,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia medycyny nuklearnej (1250)"
        },
        {
          id: 1270,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia gruźlicy i chorób płuc (1270)"
        },
        {
          id: 1272,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chorób płuc (1272)"
        },
        {
          id: 1276,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia leczenia mukowiscydozy (1276)"
        },
        {
          id: 1280,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia reumatologiczna (1280)"
        },
        {
          id: 1300,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia rehabilitacyjna (1300)"
        },
        {
          id: 1302,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia rehabilitacji narządu ruchu (1302)"
        },
        {
          id: 1304,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia zaopatrzenia ortopedycznego (1304)"
        },
        {
          id: 1306,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia rehabilitacji neurologicznej (1306)"
        },
        {
          id: 1308,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia rehabilitacji kardiologicznej (1308)"
        },
        {
          id: 1310,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział (pracownia) fizjoterapii (1310)"
        },
        {
          id: 1312,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział (pracownia) fizykoterapii (1312)"
        },
        {
          id: 1314,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział (pracownia) kinezyterapii (1314)"
        },
        {
          id: 1316,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział (pracownia) hydroterapii (1316)"
        },
        {
          id: 1318,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział (pracownia) krioterapii (1318)"
        },
        {
          id: 1320,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział (pracownia) masażu leczniczego (1320)"
        },
        {
          id: 1330,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział (pracownia) balneoterapii (1330)"
        },
        {
          id: 1340,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chorób zakaźnych (1340)"
        },
        {
          id: 1342,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia profilaktyczno-lecznicza (HIV/AIDS) (1342)"
        },
        {
          id: 1346,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chorób tropikalnych (1346)"
        },
        {
          id: 1348,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chorób odzwierzęcych i pasożytniczych (1348)"
        },
        {
          id: 1370,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia medycyny sportowej (1370)"
        },
        {
          id: 1390,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia leczenia obrzęku limfatycznego (1390)"
        },
        {
          id: 1401,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia pediatryczna (1401)"
        },
        {
          id: 1405,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia zaburzeń i wad rozwojowych dzieci (1405)"
        },
        {
          id: 1409,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia pediatryczna szczepień dla dzieci z grup wysokiego ryzyka (1409)"
        },
        {
          id: 1421,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia neonatologiczna (1421)"
        },
        {
          id: 1450,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia położniczo-ginekologiczna (1450)"
        },
        {
          id: 1452,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia ginekologiczna (1452)"
        },
        {
          id: 1453,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia ginekologiczna dla dziewcząt (1453)"
        },
        {
          id: 1454,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia patologii ciąży (1454)"
        },
        {
          id: 1458,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia profilaktyki chorób piersi (1458)"
        },
        {
          id: 1460,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia ginekologii onkologicznej (1460)"
        },
        {
          id: 1470,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia planowania rodziny i rozrodczości (1470)"
        },
        {
          id: 1472,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Szkoła rodzenia (1472)"
        },
        {
          id: 1474,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia laktacyjna (1474)"
        },
        {
          id: 1500,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chirurgii ogólnej (1500)"
        },
        {
          id: 1506,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia proktologiczna (1506)"
        },
        {
          id: 1520,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chirurgii klatki piersiowej (1520)"
        },
        {
          id: 1530,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chirurgii naczyniowej (1530)"
        },
        {
          id: 1540,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chirurgii onkologicznej (1540)"
        },
        {
          id: 1550,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chirurgii plastycznej (1550)"
        },
        {
          id: 1552,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chirurgii ręki (1552)"
        },
        {
          id: 1554,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia leczenia oparzeń (1554)"
        },
        {
          id: 1560,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia kardiochirurgiczna (1560)"
        },
        {
          id: 1562,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia kontroli rozruszników i kardiowerterów (1562)"
        },
        {
          id: 1570,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia neurochirurgiczna (1570)"
        },
        {
          id: 1572,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia kontroli systemów neuromodulacji (1572)"
        },
        {
          id: 1580,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chirurgii urazowo-ortopedycznej (1580)"
        },
        {
          id: 1583,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia wad postawy (1583)"
        },
        {
          id: 1584,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia osteoporozy (1584)"
        },
        {
          id: 1587,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia preluksacyjna (1587)"
        },
        {
          id: 1600,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia okulistyczna (1600)"
        },
        {
          id: 1604,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia leczenia zeza (1604)"
        },
        {
          id: 1610,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia otorynolaryngologiczna (1610)"
        },
        {
          id: 1612,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia audiologiczna (1612)"
        },
        {
          id: 1614,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia foniatryczna (1614)"
        },
        {
          id: 1616,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia logopedyczna (1616)"
        },
        {
          id: 1630,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chirurgii szczękowo-twarzowej (1630)"
        },
        {
          id: 1640,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia urologiczna (1640)"
        },
        {
          id: 1642,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Ambulatoryjna stacja dializ (1642)"
        },
        {
          id: 1650,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia transplantologiczna (1650)"
        },
        {
          id: 1670,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia anestezjologiczna (1670)"
        },
        {
          id: 1700,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia zdrowia psychicznego (1700)"
        },
        {
          id: 1704,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia psychogeriatryczna (1704)"
        },
        {
          id: 1706,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia leczenia nerwic (1706)"
        },
        {
          id: 1708,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia dla osób z autyzmem dziecięcym (1708)"
        },
        {
          id: 1710,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia psychosomatyczna (1710)"
        },
        {
          id: 1740,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia leczenia uzależnień (1740)"
        },
        {
          id: 1742,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia antynikotynowa (1742)"
        },
        {
          id: 1744,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia terapii uzależnienia od alkoholu i współuzależnienia (1744)"
        },
        {
          id: 1746,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia terapii uzależnienia od substancji psychoaktywnych (1746)"
        },
        {
          id: 1750,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia promocji zdrowia psychicznego (1750)"
        },
        {
          id: 1780,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia seksuologiczna i patologii współżycia (1780)"
        },
        {
          id: 1790,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia psychologiczna (1790)"
        },
        {
          id: 1800,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia stomatologiczna (1800)"
        },
        {
          id: 1810,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia periodontologiczna oraz chorób błon śluzowych przyzębia (1810)"
        },
        {
          id: 1820,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia ortodontyczna (1820)"
        },
        {
          id: 1830,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia protetyki stomatologicznej (1830)"
        },
        {
          id: 1840,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia chirurgii stomatologicznej (1840)"
        },
        {
          id: 1850,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia uzdrowiskowa (1850)"
        },
        {
          id: 1860,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia domowego leczenia tlenem (1860)"
        },
        {
          id: 1870,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia Żywieniowa (1870)"
        },
        {
          id: 7100,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Medyczne laboratorium diagnostyczne (laboratorium) (7100)"
        },
        {
          id: 7102,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia genetyczna (7102)"
        },
        {
          id: 7104,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia toksykologiczna (7104)"
        },
        {
          id: 7110,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: true,
          isOrderTicketAllowed: true,
          isOrderInternalAllowed: true,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Punkt pobrań materiałów do badań (7110)"
        },
        {
          id: 7120,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia diagnostyki mikrobiologicznej (7120)"
        },
        {
          id: 7130,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia serologiczna (7130)"
        },
        {
          id: 7140,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia cytologiczna (7140)"
        },
        {
          id: 7150,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia histopatologii (7150)"
        },
        {
          id: 7210,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia USG (7210)"
        },
        {
          id: 7220,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia tomografii komputerowej (7220)"
        },
        {
          id: 7222,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia pozytonowej tomografii emisyjnej (7222)"
        },
        {
          id: 7230,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia radiologii zabiegowej (7230)"
        },
        {
          id: 7232,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia hemodynamiki (7232)"
        },
        {
          id: 7234,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia elektrofizjologii (7234)"
        },
        {
          id: 7236,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia urządzeń wszczepialnych serca (7236)"
        },
        {
          id: 7240,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia rentgenodiagnostyki ogólnej (7240)"
        },
        {
          id: 7242,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia mammografii (7242)"
        },
        {
          id: 7244,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia densytometryczna (7244)"
        },
        {
          id: 7250,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia rezonansu magnetycznego (7250)"
        },
        {
          id: 7290,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Mobilna pracownia badań diagnostycznych (7290)"
        },
        {
          id: 7300,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Zakład patomorfologii (7300)"
        },
        {
          id: 7400,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Zakład rehabilitacji leczniczej (7400)"
        },
        {
          id: 7500,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Ośrodek terapii hiperbarycznej (7500)"
        },
        {
          id: 7910,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia endoskopii (7910)"
        },
        {
          id: 7912,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia urodynamiczna (7912)"
        },
        {
          id: 7920,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia badań słuchu (7920)"
        },
        {
          id: 7930,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia dopasowania systemów implantów słuchowych (7930)"
        },
        {
          id: 7950,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia lub zakład medycyny nuklearnej (7950)"
        },
        {
          id: 7960,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia lub zakład teleradioterapii (7960)"
        },
        {
          id: 7962,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia lub zakład brachyterapii (7962)"
        },
        {
          id: 7998,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Inne pracownie diagnostyczne lub zabiegowe (7998)"
        },
        {
          id: 8100,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia protetyki dentystycznej (8100)"
        },
        {
          id: 8200,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia lub punkt zaopatrzenia w środki pomocnicze (8200)"
        },
        {
          id: 8210,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia lub punkt zaopatrzenia w środki wspomagania słuchu (8210)"
        },
        {
          id: 8220,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia lub punkt zaopatrzenia w środki optyczne (8220)"
        },
        {
          id: 8230,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia lub punkt zaopatrzenia w wyroby medyczne będące przedmiotami ortopedycznymi (8230)"
        },
        {
          id: 8500,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Bank krwi (8500)"
        },
        {
          id: 8502,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: true,
          isOrderTicketAllowed: true,
          isOrderInternalAllowed: true,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Punkt pobrań krwi (8502)"
        },
        {
          id: 8510,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Bank tkanek i komórek (8510)"
        },
        {
          id: 8512,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Punkt pobrań tkanek i szpiku kostnego (8512)"
        },
        {
          id: 8520,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział krwiodawstwa i krwiolecznictwa (8520)"
        },
        {
          id: 9000,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Inna i nieokreślona komórka działalności medycznej (9000)"
        },
        {
          id: 9010,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia akupresury i akupunktury (9010)"
        },
        {
          id: 9012,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Pracownia ziołolecznictwa (9012)"
        },
        {
          id: 9200,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział higieny i epidemiologii (9200)"
        },
        {
          id: 9202,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział nadzoru radiologicznego (9202)"
        },
        {
          id: 9204,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział nadzoru sanitarnego (9204)"
        },
        {
          id: 9206,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział nadzoru zapobiegawczego (9206)"
        },
        {
          id: 9210,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział oświaty i promocji zdrowia (9210)"
        },
        {
          id: 9220,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział żywności, żywienia i przedmiotów użytku (9220)"
        },
        {
          id: 9230,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dział orzecznictwa o stanie zdrowia (9230)"
        },
        {
          id: 9250,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: true,
          isOrderTicketAllowed: true,
          isOrderInternalAllowed: true,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Zespół transportu medycznego - świadczenia zdrowotne udzielane w specjalistycznym środku transportu (9250)"
        },
        {
          id: 9400,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Szpitalny zespół wspierający opieki paliatywnej (9400)"
        },
        {
          id: 9450,
          isReferralRequired: false,
          isEReferralAllowed: false,
          isPaperReferralAllowed: false,
          isOrderRequired: true,
          isOrderTicketAllowed: true,
          isOrderInternalAllowed: true,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Gabinet diagnostyczno-zabiegowy (9450)"
        },
        {
          id: 9600,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Punkt szczepień (9600)"
        },
        {
          id: 9700,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Poradnia promocji zdrowia (9700)"
        },
        {
          id: 9800,
          isReferralRequired: true,
          isEReferralAllowed: true,
          isPaperReferralAllowed: true,
          isOrderRequired: false,
          isOrderTicketAllowed: false,
          isOrderInternalAllowed: false,
          isMedicalDeviceRequired: false,
          medicalDeviceTypes: [],
          name: "Dyspozytornia medyczna (9800)"
        }
      ]
    }
  },

  methods: {
    mGetClinic(id) {

      if (id < 0) return 'Brak poradni'

      let category = this.mClinics.find(c => c.id == id)
      if (category) return category.name
      else return `Poradnia nieokreślona [${id}]`
    },

    mGetClinicObject(id) {
      if (id < 0) return null
  
      let category = this.mClinics.find(c => c.id == id)
      if (category) return category
      else return null
    }
  },
}
