<template>
  <div>
    <b-button
      @click="isActiveModal = true"
      icon-left="clipboard-flow-outline"
      type="is-secondary"
    >Modyfikuj badania</b-button>

    <b-modal
      v-model="isActiveModal"
      has-modal-card
      aria-role="dialog"
      aria-modal
      scroll="keep"
      :can-cancel="false"
      class="p-5"
      trap-focus
      :destroy-on-hide="false"
    >
      <template>
          <div class="modal-card" style="overflow: visible">
            <header class="modal-card-head has-background-kin">
              <p class="modal-card-title has-text-light">Modyfikuj badania</p>
            </header>
            <section class="modal-card-body" style="min-height: 350px">
                <div style="min-height:2.5rem;">

                  <p>Badania:</p>
                  <b-field>
                    <b-switch
                      v-model="isAosVisible"
                      type="is-primary"
                      size="is-small"
                      class="mt-2"
                      >Pokaż badania nierefundowane w POZ</b-switch
                    >
                    <b-switch
                      v-model="isExamsOutsideSolabVisible"
                      type="is-danger"
                      size="is-small"
                      class="mt-2"
                      >Pokaż badania spoza bazy SOLAB</b-switch
                    >
                  </b-field>
                  <b-field
                    label="Wybierz badanie:"
                    label-position="on-border"
                    class="multiselect__container"
                  >
                    <multiselect
                      @select="(option) => addExamination2(option)"
                      :placeholder="
                        newProcedureName
                          ? newProcedureName
                          : 'Zacznij pisać, aby wyświetlić sugerowane pozycje'
                      "
                      size="small"
                      :options="filteredProceduresArray2"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :searchable="true"
                      :internal-search="false"
                      :show-no-results="false"
                      :preserveSearch="true"
                      :taggable="false"
                      :show-labels="false"
                      :loading="isLoading"
                      open-direction="bottom"
                      :max-height="200"
                      @search-change="(option) => (newProcedureName = option)"
                    >
                      <template slot="option" slot-scope="props">
                        <div
                          style="word-wrap: break-word; white-space: pre-wrap; font-size: 0.8rem"
                        >
                          <span style="float: left">
                            <b-checkbox
                              :value="
                                addedExaminations.find(
                                  (e) => e.id === props.option.id
                                )
                                  ? 'true'
                                  : 'false'
                              "
                              type="is-success"
                              class="mr-0"
                            ></b-checkbox>
                          </span>
                          <span
                            style="word-wrap: break-word; white-space: pre-wrap"
                          >
                            {{ props.option.name }}
                          </span>
                          <span v-if="props.option.codeICD9">
                            ({{ props.option.codeICD9 }})</span
                          >
                          <span style="float: right">
                            <b-tooltip label="Refundacja" position="is-left">
                              <b-tag
                                v-if="props.option.isRefundedPOZ"
                                type="is-success"
                                class="mr-1"
                                >POZ</b-tag
                              >
                              <b-tag
                                v-if="props.option.isRefundedAOS"
                                type="is-primary"
                                >AOS</b-tag
                              >
                            </b-tooltip>
                            <b-tooltip
                              label="Badanie znajduje się w bazie SOLAB"
                              type="is-secondary"
                              position="is-left"
                            >
                              <b-tag
                                v-if="props.option.solabName"
                                type="is-secondary"
                                class="ml-1"
                                >S</b-tag
                              >
                            </b-tooltip>
                          </span>
                        </div>
                      </template>
                      <template slot="noOptions">
                        Zacznij pisać, aby wyświetlić sugerowane pozycje
                      </template>
                    </multiselect>
                    <b-tooltip
                      class="ml-1"
                      label="Pokaż badania nierefundowane w POZ"
                      position="is-left"
                      type="is-primary"
                    >
                      <b-switch
                        v-model="isAosVisible"
                        type="is-primary"
                        size="is-small"
                        class="mt-2"
                      ></b-switch>
                    </b-tooltip>
                  </b-field>
                  <b-field
                    label="lub pakiet badań:"
                    label-position="on-border"
                    class="multiselect__container"
                    v-if="is40Plus === false"
                  >
                    <multiselect
                      @select="(option) => addPackage2(option)"
                      :placeholder="
                        newPackageName
                          ? newPackageName
                          : 'Zacznij pisać, aby wyświetlić sugerowany pakiet'
                      "
                      size="small"
                      :options="filteredPackagesArray"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :searchable="true"
                      :internal-search="false"
                      :show-no-results="false"
                      :preserveSearch="true"
                      :taggable="false"
                      :show-labels="false"
                      :loading="isLoading"
                      open-direction="bottom"
                      :max-height="330"
                      @search-change="(option) => (newPackageName = option)"
                    >
                      <template slot="option" slot-scope="props">
                        <div
                          class="mb-1"
                          style="word-wrap: break-word; white-space: pre-wrap"
                        >
                          <b>{{ props.option.name }}</b>
                        </div>
                        <p
                          v-for="(item, item_index) in packageExamsList2(
                            props.option
                          )"
                          :key="item_index"
                          class="clearfix"
                          style="font-size: 0.8rem; word-wrap: break-word; white-space: pre-wrap"
                        >
                          <span style="float: left">
                            <b-checkbox

                              :value="
                                addedExaminations.find((e) => e.id === item.id)
                                  ? 'true'
                                  : 'false'
                              "
                              type="is-success"
                              size="is-small"
                            ></b-checkbox>
                          </span>
                          <span>{{ item.name }}</span>
                          <span> ({{ item.codeICD9 }})</span>
                        </p>
                      </template>
                      <template slot="noOptions">
                        Zacznij pisać, aby wyświetlić sugerowany pakiet
                      </template>
                    </multiselect>
                    <b-tooltip
                      label="Dodaj własny pakiet badań"
                      position="is-left"
                    >
                      <b-button>
                        <b-icon
                          icon="plus-circle-outline"
                          style="vertical-align: middle"
                          type="is-primary"
                        ></b-icon>
                      </b-button>
                    </b-tooltip>
                    <b-tooltip
                      label="Dodaj wszystkie badania refundowane w POZ"
                      position="is-left"
                    >
                      <b-button @click="addExaminationPOZ">
                        <b-icon
                          icon="multiplication-box"
                          style="vertical-align: middle"
                          type="is-primary"
                        ></b-icon>
                      </b-button>
                    </b-tooltip>
                    <b-tooltip
                      label="Usuń wszystkie dodane badania"
                      type="is-danger"
                      position="is-left"
                    >
                      <b-button
                        @click="removeAllExaminations"
                        :disabled="!addedExaminations || addedExaminations.length == 0"
                      >
                        <b-icon
                          icon="delete"
                          style="vertical-align: middle"
                          type="is-danger"
                        ></b-icon>
                      </b-button>
                    </b-tooltip>
                  </b-field>
                  <div v-if="addedExaminations.length > 0" class="ml-3">
                    <p class="mb-3" style="font-size: 0.9rem">
                      Wybrane badania:
                    </p>
                    <b-field>
                      <table
                        class="table is-narow border-bottom"
                        style="width: 100%; font-size: 0.9rem"
                      >
                        <tr
                          v-for="(exam, index_exam) in addedExaminations"
                          :key="index_exam"
                        >
                          <td>{{ exam.codeICD9 }} - {{ exam.name }}</td>
                          <td class="has-text-right" style="width: 30%">
                            <b-tooltip label="Refundacja" position="is-left">
                              <b-tag
                                v-if="exam.isRefundedPOZ"
                                type="is-success"
                                size="is-small"
                                >POZ</b-tag
                              >
                              <b-tag
                                size="is-small"
                                v-if="exam.isRefundedAOS"
                                type="is-primary"
                                >AOS</b-tag
                              >
                            </b-tooltip>
                            <b-button
                              @click="deleteExam(index_exam)"
                              type="is-text"
                              size="is-small"
                            >
                              <b-icon
                                icon="close"
                                type="is-danger"
                                style="vertical-align: middle"
                              ></b-icon>
                            </b-button>
                          </td>
                        </tr>
                      </table>
                    </b-field>
                  </div>
                </div>
            </section>
            <footer class="modal-card-foot">
              <b-button icon-left="close" @click="isActiveModal = false">Anuluj</b-button>
              <b-button
                :loading="isLoading"
                type="is-primary"
                @click="updateTicket"
                :disabled="addedExaminations.length < 1"
              >Zapisz zmiany</b-button>
            </footer>
          </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import OrdersMixins from "@/mixins/orders/poz_orders";
import ReferralMixins from "@/mixins/referrals.js";

import { Action } from "@/store/config_actions";

import packagesData from "@/assets/icd9_lab_packages.json";

import Multiselect from "vue-multiselect";

export default {
  name: "CollectionPointExamChange",
  mixins: [CommonHelpers, OrdersMixins, ReferralMixins],
  components: {
    Multiselect,
  },

  props: { 
    appointment: { type: Object, require: true }
  },

  data: function () {
    return {
      isActiveModal: false,
      isLoading: false,
      requestInProgress: false,

      newProcedureName: "",
      addedExaminations: [],
      appointmentExaminations: [],
      isAosVisible: false,
      isExamsOutsideSolabVisible: false,

      newPackageName: "",
      packages: packagesData,

    };
  },

  mounted() {
    if (this.types.length === 0) {
      this.getTypes();
    }
  },

  watch: {
      isActiveModal() {
          if(this.isActiveModal === true) {

            this.addedExaminations = []
            this.appointmentExaminations = []

            this.appointment.ticketInfo.procedures.forEach((proc) => {
                let examObj = this.types.find(
                    (e) => e.internalId === proc.procedureId
                );

                if (examObj != null) {
                    this.addedExaminations.push(JSON.parse(JSON.stringify(examObj)));
                }
            });
          }
      }
  },

  computed: {
    is40Plus() {
      return this.appointment.type === 94
    },

    types() {
      if(this.is40Plus) {
        let examLabType = this.$store.state.results.examLabType ?? []

        examLabType = examLabType.filter(examLab => {
          if(examLab.additionalGroups && examLab.additionalGroups.includes(1)) {
            return true
          }

          return false
        })

        return examLabType
      } 
      
      let examLabType = this.$store.state.results.examLabType ?? []
      return examLabType.filter(examLab => {
        if(examLab.additionalGroups && examLab.additionalGroups.length > 0) {
          return false
        }

        return true
      })
    },

    filteredProceduresArray2() {
      return this.types.filter((option) => {
        if (
          (option.isRefundedPOZ &&
            option.solabName != null &&
            option.solabName != "") ||
          (!option.isRefundedPOZ &&
            this.isAosVisible &&
            option.solabName != null &&
            option.solabName != "") ||
          ((option.solabName == null || option.solabName == "") &&
            this.isExamsOutsideSolabVisible)
        ) {
          if (
            this.newProcedureName.length > 0 &&
            option != null &&
            option.name != null &&
            option.codeICD9 != null
          ) {
            return (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.newProcedureName.toLowerCase()) >= 0 ||
              option.codeICD9
                .toString()
                .toLowerCase()
                .indexOf(this.newProcedureName.toLowerCase()) >= 0 ||
              option.solabName
                .toString()
                .toLowerCase()
                .indexOf(this.newProcedureName.toLowerCase()) >= 0
            );
          }
        }
      });
    },

    filteredPackagesArray() {
      return this.packages.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.newPackageName.toLowerCase()) >= 0
        );
      });
    },
  },

  methods: {
    updateTicket() {
      this.appointment.ticketInfo.procedures = []
      this.appointment.procedures = []
      this.appointment.plannedProcedures = []

      if (this.addedExaminations && this.addedExaminations.length > 0) {
        this.addedExaminations.forEach((element) => {
          let newProcedure = this.ticketProcedure;
          newProcedure.procedureId = element.internalId;
          newProcedure.ticketType = this.appointment.type;
          newProcedure.description = element.name;
          newProcedure.icd9 = element.codeICD9;
          this.appointment.ticketInfo.procedures.push(JSON.parse(JSON.stringify(newProcedure)));
          this.appointment.procedures.push(element.codeICD9)
          this.appointment.plannedProcedures.push(element.internalId)
        });
      }

      this.isLoading = true;
      this.$store
        .dispatch(Action.TICKET_PUT, this.appointment.ticketInfo)
        .then((resp) => {
            this.appointment.ticketInfo = resp

            this.$store
            .dispatch(Action.S2_EDIT_APPOINTMENT, this.appointment)
            .then((response) => {
                this.$emit('apps-trigger', response)
                this.isLoading = false;
                this.isActiveModal = false;
                this.successSnackbar("Zmieniono badania");
            })
            .catch((error) => {
                this.isLoading = false;
                this.apiProblem(error);
            });

        })
        .catch((error) => {
          this.isLoading = false;
          this.apiProblem(error);
        });
    },

    getTypes() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.RESULTS_LAB_EXAMINATION_TYPE_GET_ALL)
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.snackbar("Nie pobrano typów");
          this.apiProblem(error);
          this.isLoading = false;
        });
    },

    addExamination2(option) {
      if (option != null) {
        let isExamOnList = this.addedExaminations.find((e) => e.id === option.id);

        if (isExamOnList) {
          let index = this.addedExaminations.indexOf(isExamOnList);
          this.deleteExam(index);
        } else {
          this.addedExaminations.push(JSON.parse(JSON.stringify(option)));
        }
      }
    },

    addPackage2(option) {
      if (option != null) {
        option.examinations.forEach((examId) => {
          let examObj = this.types.find(
            (e) => e.internalId.toString() === examId
          );

          if (examObj != null) {
            let isExamOnList = this.addedExaminations.find(
              (e) => e.internalId.toString() === examId
            );

            if (isExamOnList) {
              this.snackbar(
                "Badanie " +
                examObj.codeICD9 +
                ": " +
                examObj.name +
                " znajduje się już na liście"
              );
            } else {
              this.addedExaminations.push(JSON.parse(JSON.stringify(examObj)));
            }
          }
        });
      }
    },

    deleteExam(index) {
      this.addedExaminations.splice(index, 1);
    },

    addExaminationPOZ() {
      this.$buefy.dialog.confirm({
        message:
          "Czy na pewno chcesz dodać wszystkie badania refundowane w POZ?",
        type: "is-info",
        cancelText: "Nie",
        confirmText: "Tak",
        onConfirm: () => {
          let pozExams = this.types.filter((ex) => ex.isRefundedPOZ == true);

          if (pozExams) {
            pozExams.forEach((option) => {
              let isExamOnList = this.addedExaminations.find((e) => e.id === option.id);
              if (isExamOnList) {
                this.snackbar(
                  "Badanie " +
                  option.codeICD9 +
                  ": " +
                  option.name +
                  " znajduje się już na liście"
                );
              } else {
                this.addedExaminations.push(JSON.parse(JSON.stringify(option)));
              }
            });
          }
        }
      });
    },

    removeAllExaminations() {
      this.$buefy.dialog.confirm({
        message: "Czy na pewno chcesz usunąć wszystkie dodane badania?",
        type: "is-danger",
        cancelText: "Nie",
        confirmText: "Tak",
        onConfirm: () => {
          this.addedExaminations = [];
        },
      });
    },

    packageExamsList2(option) {
      let examsArray = [];
      option.examinations.forEach((examId) => {
        let examObj = this.types.find(
          (e) => e.internalId.toString() === examId
        );
        if (examObj != null) {
          examsArray.push(JSON.parse(JSON.stringify(examObj)));
        }
      });
      return examsArray;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.multiselect__container {
  width: 100%;
  &::v-deep .multiselect {
    font-size: 14px;
    .multiselect__tags {
      font-size: 14px ;
      background-color: #f2fafd;
      border-color: #d6d6d6;
      border-radius: 4px;
      color: #363636;
    }
    .multiselect__option--highlight {
      background-color: whitesmoke;
      color: #0a0a0a;
      outline: none;
    }

    .multiselect__tags-icon {
      &:hover {
        background: #0c6d9a;
      }
      &:after {
        color: #fff;
      }
    }
    .multiselect__input {
      font-size: 14px;
      background-color: #f2fafd;
      border-color: #d6d6d6;
      color: #363636;
      &:focus {
        font-size: 14px;
        background-color: #f2fafd;
        border-color: #d6d6d6;
        color: #363636;
      }
      &::placeholder {
        font-size: 14px;
        background-color: #f2fafd;
        border-color: #d6d6d6;
        color: #363636;
      }
    }
    .multiselect__content {
      width: 100%;
    }
    .multiselect__element {
      width: 100%;
    }
  }
}
.clearfix {
  overflow: auto;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
</style>
