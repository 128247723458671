import { Action } from '../config_actions'
//import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        slots: [],
        employeeSlots:[]
    },

    mutations: {
        [Mutation.SLOT_SET_ALL](currentState, slots){
            currentState.slots = slots;
        },
        [Mutation.SLOT_SET_EMPLOYEE](currentState, slots){
            currentState.employeeSlots = slots;
        },

        [Mutation.SLOT_REFRESH](currentState,slot){
            let tempSlot = currentState.slots.find((s) =>s.id==slot.id)

            if(tempSlot){
                let index = currentState.slots.indexOf(tempSlot)

                if(index>-1){
                    currentState.slots[index] = slot;
                }
            }
        }
    },

    actions: {
        //SLOT CRUD

        [Action.SLOT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SLOT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.SLOT_SET_ALL,response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },        

        [Action.SLOT_GET_BY_SUBUNIT](context,subunitId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SLOT_GET_BY_SUBUNIT.replace('{id}', subunitId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        
        [Action.SLOT_GET_BY_EMPLOYEE](context,employeeId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SLOT_GET_BY_EMPLOYEE.replace('{id}', employeeId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {                        
                        context.commit(Mutation.SLOT_SET_EMPLOYEE,response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.SLOT_GET_BY_ID](context,id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SLOT_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.SLOT_ADD](context, invoice) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.SLOT_ADD,
                        data: invoice,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.SLOT_UPDATE_HR](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.SLOT_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.SLOT_REFRESH,response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.SLOT_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.SLOT_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.SLOT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.SLOT_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
    }
}