<template>
  <ContentPanel
    title="e-Learning"
    subtitle="Przegląd dostępnych kursów"
    icon="book-open-variant"
  >
    <template slot="addon">
      <b-button
        size="is-small"
        @click="proceedTo('elearning')"
        icon-right="menu-right"
        type="is-primary"
        :loading="loading"
      >Przejdź do e-Learning</b-button>
    </template>
    <div v-if="Courses.length > 0" class="pl-1 pt-0 pr-1" style="margin-top: -0.5rem;">
      <div v-for="(course, index_course) in Courses" :key="index_course" class="mb-1">
        <div class="pt-3">
          <table>
            <tr>
              <td class="pl-1 pr-4">
                <b-icon icon="folder-information-outline" size="is-large"></b-icon>
              </td>
              <td>
                <p class="title is-6 mb-1">{{ course.title }}</p>
                <p class="fs-09 mb-3">
                  <b-tooltip label="Szacowany czas">
                    <b-icon icon="clock" size="is-small"></b-icon>
                  </b-tooltip>
                  <span class="ml-1 mr-2">{{ course.estimatedTime }}min</span>
                  <b-tooltip label="Autor">
                    <b-icon icon="account-circle" size="is-small"></b-icon>
                  </b-tooltip>
                  <span class="ml-1">{{ getWorker(course.authorId) }}</span>
                </p>
                <p class="mb-3">
                  <span>{{ course.description }}</span>
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="fs-09 section">Brak dostępnych kursów.</p>
    </div>
  </ContentPanel>
</template>

<script>
import CommonMixins from '../../mixins/commons'
// import ContentPanel from '../tools/ContentPanel'
import { Action } from '../../store/config_actions'

export default {
  name: 'ELearningDashboardCard',
  mixins: [CommonMixins],

  components: {
    //  ContentPanel
  },

  data: function () {
    return {
      Courses: [],
      loading: false,
    }
  },

  computed: {
    employees() { return this.$store.state.employee.all },
    myRoles() {
      if (this.$store.state.employee.me) {
        return this.$store.state.employee.me.roles
      } else return []
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    getWorker(id) {
      if (this.employees) {
        let emp = this.employees.find(e => e.id === id)

        if (emp) {
          return emp.firstName + ' ' + emp.lastName
        }
        else return '-'
      }
    },
    proceedTo(name) {
      this.open = false
      this.$router.push({ name: name })
    },
    goToCourse() {
      this.$router.push({ name: 'elearning-course' })
    },
    getMyCourses(items) {
      let allCourses = items
      let myCourses = []
      allCourses.forEach(element => {
        let filteredArray = element.viewRoles.filter(value => this.myRoles.includes(value))
        if (filteredArray.length > 0 && element.status == 0) {
          myCourses.push(element)
        }
      });
      this.Courses = myCourses
    },
    getData() {
      this.loading = true
      this.$store
        .dispatch(Action.EDU_GET_COURSES_LITE)
        .then((items) => {
          // this.successSnackbar('Pobrano kursy');
          this.getMyCourses(items)
          this.loading = false
        })
        .catch(error => {
          this.apiProblem(error);
          this.loading = false
        });
    },
  }
}
</script>

<style scoped lang="scss">
.fs-09 {
  font-size: 0.9rem;
}
</style>
