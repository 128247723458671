import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'
//import moment from 'moment'

export default {
    namespaced: false,

    state: {
        courses: [],
        progress: null,
    },

    mutations: {
        [Mutation.EDU_SET_COURSES](state, payload) {
            state.courses = payload
        },
        [Mutation.EDU_SET_PROGRESS](state, payload) {
            state.progress = payload
        },
        [Mutation.EDU_ADD_COURSE_M](state, payload) {
            state.courses.push(payload)
        },
    },

    actions: {

        // COURSES ****************************************************************************

        [Action.SWAGGER_DOCS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SWAGGER_DOCS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },















        [Action.EDU_GET_COURSES](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EDU_COURSES_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EDU_SET_COURSES, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.EDU_GET_COURSES_LITE](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EDU_COURSES_GET_ALL_LITE,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.EDU_ADD_COURSE](context, course) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EDU_POST_NEW,
                        data: course,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EDU_ADD_COURSE_M, response.data)
                        resolve();
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.EDU_MODIFY_COURSE](context, course) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EDU_MODIFY_COURSE.replace('{idSZOKEdu}', course.idSZOKEDU),
                        data: course,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.EDU_UPLOAD_FILE](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('workerId', form.workerId);
                    payload.append('datetime', form.datetime);
                    payload.append('uploadedFile', form.uploadedFile);
                    payload.append('uploadType', form.uploadType);
                    payload.append('description', form.description);
                    axios({
                        method: 'post',
                        url: Apis.EDU_POST_FILE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.EDU_GET_FILE](context, FileNameId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.EDU_GET_FILE.replace('{FileNameId}', FileNameId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.EDU_DOWNLOAD_INSTRUCTION](context, id) {
            let tab = window.open();
            tab.document.write(`
                <div style="padding-top: 5rem;text-align:center;font-size:1.5rem;font-family:Arial;">Ładowanie instrukcji...</div>
            `);

            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.EDU_DOWNLOAD_INSTRUCTION.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        var fileURL = window.URL.createObjectURL(response.data);
                        tab.location.href = fileURL;

                        resolve();
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.EDU_DOWNLOAD_INSTRUCTION_FOR_USER_MANUAL](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.EDU_DOWNLOAD_INSTRUCTION.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.EDU_GET_PROGRESS](context, WorkerId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EDU_GET_PROGRESS.replace('{WorkerId}', WorkerId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.EDU_SET_PROGRESS, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        context.commit(Mutation.EDU_SET_PROGRESS, null)
                        reject(error);
                    });
                } else reject('401')
            })
        },
        [Action.EDU_MODIFY_PROGRESS](context, progress) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EDU_MODIFY_PROGRESS.replace('{WorkerId}', progress.workerId),
                        data: progress,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}