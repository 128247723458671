export default {

  data: () => {
    return {

      facilityTypes: [
        { id: 0, name: 'Brak informacji' },
        { id: 1, name: 'Miejska' },
        { id: 2, name: 'Wiejska' },
      ],

      facilityCharacter: [
        { id: 0, name: 'Brak informacji' },
        { id: 1, name: 'Przychodnia' },
        { id: 2, name: 'DDW/DDOM' },
      ],

      facilityRole: [
        { id: 0, name: 'Brak informacji' },
      ],
      /**Lokalizacja pomieszczenia
       * Room localization
       */
      mRoomLocalization: [
        { id: 0, name: 'Brak informacji' },
        { id: 1, name: 'Przychodnia' },
        { id: 2, name: 'Parking' },
        { id: 3, name: 'Plac zabaw' },
        { id: 4, name: 'Chodnik publiczny' },
        { id: 5, name: 'Tereny zielone' },
        { id: 6, name: 'Budynek gospodarczy' }
      ],
      /**Typy pomieszczeń
       * Room types
       */
      mRoomTypes: [
        { id: 0, name: 'Brak' },
        { id: 1, name: 'Gabinet zabiegowy' },
        { id: 2, name: 'Gabinet lekarski' },
        { id: 3, name: 'Gabinet specjalistyczny' },
        { id: 4, name: 'Gabinet pielęgniarki' },
        { id: 5, name: 'Gabinet farmaceuty' },
        { id: 6, name: 'Punkt pobrań' },
        { id: 7, name: 'Sala terapeutyczna' },
        { id: 8, name: 'Sala operacyjno-zabiegowa' },
        { id: 9, name: 'Pomieszczenie diagnostyczne' },
        { id: 10, name: 'Pomieszczenie biurowe' },
        { id: 11, name: 'Schowek' },
        { id: 12, name: 'Korytarz' },
        { id: 13, name: 'Rejestracja' },
        { id: 14, name: 'Socjalny' },
        { id: 15, name: 'Toaleta damska' },
        { id: 16, name: 'Toaleta męska' },
        { id: 17, name: 'Toaleta dla niepełnosprawnych' },
        { id: 19, name: 'Klatka schodowa' },
        { id: 20, name: 'Przedsionek' },
        { id: 21, name: 'Metadon' },
        { id: 22, name: 'Poczekalnia' },
        { id: 23, name: 'Łazienka w gabinecie' },
        { id: 24, name: 'Toaleta damsko-męska' },
        { id: 25, name: 'Toaleta dla personelu' },
        { id: 26, name: 'Toaleta dla pacjenta' },
        { id: 27, name: 'Toaleta ogólnodostępna' }
      ],
      /**Kategorie urządzeń w inwentaryzacji
       * Inventory item categories
       */
      mInventoryItemCategories: [
        { id: 0, name: 'Brak' },
        { id: 1, name: 'Komputer' },
        { id: 2, name: 'Monitor' },
        { id: 3, name: 'Drukarka lub skaner' },
        { id: 4, name: 'Urzadzenia wejścia/wyjścia' },
        { id: 5, name: 'Urządzenia mobilne' },
        { id: 6, name: 'Urządzenia sieciowe' },
        { id: 7, name: 'Pozostałe urządzenia elektroniczne' },
        { id: 8, name: 'Sprzęt medyczny' },
        { id: 9, name: 'Wyposażenie biurowe' },
        { id: 10, name: 'Rama informacyjna' },
        { id: 11, name: 'Gaśnica' },
        { id: 12, name: 'Meble' },
        { id: 13, name: 'Pozostały sprzęt gaśniczy' },
        { id: 14, name: 'Inne użytkowe' },
        { id: 15, name: 'Stanowisko' },
        { id: 16, name: 'Powierzchnie reklamowe' },
        { id: 17, name: 'Urządzenia medyczne' },
      ],
      /**Podkategorie urządzeń w inwentaryzacji
       * Inventory item subcategories
       */
      mInventoryItemSubcategory: [
        { id: 0, name: "Brak" },

        { id: 401, name: "Myszka" },
        { id: 402, name: "Klawiatura" },
        { id: 403, name: "Czytnik" },
        { id: 404, name: "Kasa fiskalna" },
        { id: 405, name: "Mikrofon" },
        { id: 406, name: "Głośnik" },
        { id: 407, name: "Zestaw głośnomówiący" },
        { id: 408, name: "Kamera CCTV" },
        { id: 409, name: "Kamera do komputera" },
        { id: 410, name: "Rzutnik" },
        { id: 411, name: "E-długopis" },
        { id: 412, name: "Niszczarka" },
        { id: 413, name: "Terminal płatniczy" },
        { id: 414, name: "Drukarka fiskalna" },


        { id: 501, name: "Telefon stacjonarny z dockiem" },
        { id: 502, name: "Telefon komórkowy" },
        { id: 503, name: "Tablet" },
        { id: 504, name: "Telefon VoIP z bazą" },

        { id: 601, name: "Router" },
        { id: 602, name: "Switch" },
        { id: 603, name: "Switch zarządzalny" },
        { id: 604, name: "Serwer" },
        { id: 605, name: "Dysk sieciowy" },
        { id: 606, name: "Dysk zewnętrzny" },
        { id: 607, name: "Pendrive szyfrowany" },
        { id: 608, name: "Modem" },

        { id: 701, name: "Lodówka" },
        { id: 702, name: "Klimatyzator" },
        { id: 703, name: "Zamrażarka" },
        { id: 704, name: "Przedłużacz" },
        { id: 705, name: "Winda" },
        { id: 706, name: "Gniazdo Ethernetowe" },
        { id: 707, name: "UPS" },

        { id: 901, name: "Tablica magnetyczna" },
        { id: 902, name: "Flipchart" },
        { id: 903, name: "Roll-up" },
        { id: 904, name: "Stojak na ulotki" },
        { id: 905, name: "Stojak na gazety" },

        { id: 1201, name: "Ławka" },
        { id: 1202, name: "Sofa" },
        { id: 1203, name: "Fotel" },
        { id: 1204, name: "Krzesło" },
        { id: 1205, name: "Zestaw krzeseł" },
        { id: 1206, name: "Biurko" },
        { id: 1207, name: "Stolik" },
        { id: 1208, name: "Leżanka" },
        { id: 1209, name: "Parawan" },
        { id: 1210, name: "Stół zabiegowy" },
        { id: 1211, name: "Wieszak" },
        { id: 1212, name: "Stolik rozkładany" },
        { id: 1213, name: "Krzesło składane" },
        { id: 1214, name: "Rack" },
        { id: 1215, name: "Krzesło obrotowe z oparciem" },
        { id: 1216, name: "Szafa" },
        { id: 1217, name: "Wózek" },
        { id: 1218, name: "Kontener z wysuwanymi szufladami" },
        { id: 1219, name: "Zabudowa" },
        { id: 1220, name: "Kozetka" },
        { id: 1221, name: "Szafa na kartoteki" },

        { id: 1401, name: "Dozownik" },
        { id: 1402, name: "Kosz" },
        { id: 1403, name: "Zegar" },
        { id: 1404, name: "Rolety" },

        { id: 1601, name: "Drogowskaz" },
        { id: 1602, name: "Baner" },
        { id: 1603, name: "Billboard" },

        { id: 1701, name: "USG" },
        { id: 1702, name: "KTG" },
        { id: 1703, name: "Sonda" },
        { id: 1704, name: "RTG" },
        { id: 1705, name: "MGF" },
        { id: 1706, name: "Densytometr" },
        { id: 1707, name: "Tomograf komputerowy" },
        { id: 1708, name: "Mammograf" },
        { id: 1709, name: "System do wprowadzania środka kontrastujacego" },
      ],
      /**Status urządzeń
       * Inventory item state
       */
      mInventoryItemState: [
        { id: 0, name: "Używany" },
        { id: 1, name: "Wyłączony z użytkowania" },
        { id: 2, name: "W magazynie" },
        { id: 3, name: "Awaria" },
        { id: 4, name: "W naprawie" }
      ],

      mInventoryItemComputerType: [
        { id: 0, name: "PC" },
        { id: 1, name: "Laptop" },
        { id: 2, name: "AIO" },
        { id: 3, name: "SFF" },
        { id: 4, name: "Microtower" }
      ],

      mInventoryItemComputerState: [
        { id: 0, name: "Dobry" },
        { id: 1, name: "Wystarczający" },
        { id: 2, name: "Zły" }
      ],

      mInventoryItemMonitorType: [
        { id: 0, name: 'Płaski' },
        { id: 1, name: 'Kineskopowy' },
      ],

      mInventoryItemPrinterScannerType: [
        { id: 0, name: 'Drukarka' },
        { id: 1, name: 'Ksero' },
        { id: 2, name: 'Skaner' },
        { id: 3, name: 'Drukarka do etykiet' }
      ],

      mInventoryItemPrinterScannerSize: [
        { id: 0, name: 'Biurkowa' },
        { id: 1, name: 'Kombajn' },
      ],

      mInventoryItemSubcategoryInputOutput: [
        { id: 0, name: "Brak" },
        { id: 401, name: "Myszka" },
        { id: 402, name: "Klawiatura" },
        { id: 403, name: "Czytnik" },
        { id: 404, name: "Kasa fiskalna" },
        { id: 405, name: "Mikrofon" },
        { id: 406, name: "Głośnik" },
        { id: 407, name: "Zestaw głośnomówiący" },
        { id: 408, name: "Kamera CCTV" },
        { id: 409, name: "Kamera do komputera" },
        { id: 410, name: "Rzutnik" },
        { id: 411, name: "E-długopis" },
        { id: 412, name: "Niszczarka" },
        { id: 413, name: "Terminal płatniczy" },
        { id: 414, name: "Drukarka fiskalna" }
      ],

      mInventoryItemSubcategoryMobile: [
        { id: 0, name: "Brak" },
        { id: 501, name: "Telefon stacjonarny z dockiem" },
        { id: 502, name: "Telefon komórkowy" },
        { id: 503, name: "Tablet" },
        { id: 504, name: "Telefon VoIP z bazą" },
      ],

      mInventoryItemMobileSoftware: [
        { id: 0, name: "Brak" },
        { id: 1, name: "Inne" },
        { id: 2, name: "Android" },
        { id: 3, name: "Windows" },
        { id: 4, name: "Google" },
        { id: 5, name: "iOS" }
      ],

      mInventoryItemMobileChargingSocket: [
        { id: 0, name: "Inne" },
        { id: 1, name: "Micro USB" },
        { id: 2, name: "USB C" },
        { id: 3, name: "Apple" }
      ],

      mInventoryItemSubcategoryNetwork: [
        { id: 0, name: "Brak" },
        { id: 601, name: "Router" },
        { id: 602, name: "Switch" },
        { id: 603, name: "Switch zarządzalny" },
        { id: 604, name: "Serwer" },
        { id: 605, name: "Dysk sieciowy" },
        { id: 606, name: "Dysk zewnętrzny" },
        { id: 607, name: "Pendrive szyfrowany" },
        { id: 608, name: "Modem" }
      ],

      mInventoryItemSubcategoryOtherElectronic: [
        { id: 0, name: "Brak" },
        { id: 701, name: "Lodówka" },
        { id: 702, name: "Klimatyzator" },
        { id: 703, name: "Zamrażarka" },
        { id: 704, name: "Przedłużacz" },
        { id: 705, name: "Winda" },
        { id: 706, name: "Gniazdo Ethernetowe" },
        { id: 707, name: "UPS" }
      ],

      mInventoryItemElevatorType: [
        { id: 0, name: "Winda" },
        { id: 1, name: "Dźwig osobowy" },
        { id: 2, name: "Podnośnik na schody" }
      ],

      mInventoryItemSubcategoryEquipment: [
        { id: 0, name: "Brak" },
        { id: 901, name: "Tablica magnetyczna" },
        { id: 902, name: "Flipchart" },
        { id: 903, name: "Roll-up" },
        { id: 904, name: "Stojak na ulotki" },
        { id: 905, name: "Stojak na gazety" }
      ],

      mInventoryItemInfoScreenType: [
        { id: 0, name: "Korkowa" },
        { id: 1, name: "Magnetyczna" },
        { id: 2, name: "Prezentacyjna" },
        { id: 3, name: "Na szybę" },
        { id: 4, name: "Plastikowa" },
      ],

      mInventoryItemInfoScreenPlasticType: [
        { id: 0, name: "Zatrzaskowa" },
        { id: 1, name: "Na imbus" },
        { id: 2, name: "Wsadowa" }
      ],

      mInventoryItemInfoScreenOrientation: [
        { id: 0, name: "Pionowa" },
        { id: 1, name: "Pozioma" }
      ],

      mInventoryItemInfoScreenDestination: [
        { id: 0, name: "Marketing" },
        { id: 1, name: "Obowiązek informacyjny" },
        { id: 2, name: "Informacje organizacyjne placówki" }
      ],

      mInventoryItemInfoScreenPlacement: [
        { id: 0, name: "Ściana" },
        { id: 1, name: "Drzwi" }
      ],

      mInventoryItemInfoScreenSize: [
        { id: 0, name: "Inne" },
        { id: 1, name: "A3" },
        { id: 2, name: "B2" },
        { id: 3, name: "A4" },
        { id: 4, name: "A5" }
      ],

      mInventoryItemSubcategoryFurniture: [
        { id: 0, name: "Brak" },
        { id: 1201, name: "Ławka" },
        { id: 1202, name: "Sofa" },
        { id: 1203, name: "Fotel" },
        { id: 1204, name: "Krzesło" },
        { id: 1205, name: "Zestaw krzeseł" },
        { id: 1206, name: "Biurko" },
        { id: 1207, name: "Stolik" },
        { id: 1208, name: "Leżanka" },
        { id: 1209, name: "Parawan" },
        { id: 1210, name: "Stół zabiegowy" },
        { id: 1211, name: "Wieszak" },
        { id: 1212, name: "Stolik rozkładany" },
        { id: 1213, name: "Krzesło składane" },
        { id: 1214, name: "Rack" },
        { id: 1215, name: "Krzesło obrotowe z oparciem" },
        { id: 1216, name: "Szafa" },
        { id: 1217, name: "Wózek" },
        { id: 1218, name: "Kontener z wysuwanymi szufladami" },
        { id: 1219, name: "Zabudowa" },
        { id: 1220, name: "Kozetka" },
        { id: 1221, name: "Szafa na kartoteki" },
      ],

      mInventoryBuiltInFurnitureCategory: [
        { id: 0, name: "Wisząca" },
        { id: 1, name: "Stojąca" }
      ],

      mInventoryItemMedicalDevice: [
        { id: 0, name: "Brak" },
        { id: 1, name: "Otoskop" },
        { id: 2, name: "Oftalmoskop" },
        { id: 3, name: "Pikflometr" },
        { id: 4, name: "TermoHigrometr" },
        { id: 5, name: "Torba pielęgniarki, torba lekarza POZ" },
        { id: 6, name: "Młotek neurologiczny" },
        { id: 7, name: "Tablice Snellena" },
        { id: 8, name: "Stolik zabiegowy" },
        { id: 9, name: "Stetoskop" },
        { id: 10, name: "Przewijak" },
        { id: 11, name: "Glukometr" },
        { id: 12, name: "Ciśnieniomierz" },
        { id: 13, name: "Waga" },
        { id: 14, name: "Termometr" },
        { id: 15, name: "Aparat EKG" },
        { id: 16, name: "Waga elektroniczna" },
        { id: 17, name: "Pulsoksymetr" },
        { id: 18, name: "Zamrażarka" },
        { id: 19, name: "Lodówka na odpady medyczne" },
        { id: 20, name: "Zasłony" },
        { id: 21, name: "Sprzęt USG" }
      ],

      mInventoryItemSubcategoryOtherUtility: [
        { id: 0, name: "Brak" },
        { id: 1401, name: "Dozownik" },
        { id: 1402, name: "Kosz" },
        { id: 1403, name: "Zegar" },
        { id: 1404, name: "Rolety" }
      ],

      mInventoryItemSubcategoryAdvertisingSpace: [
        { id: 0, name: "Brak" },
        { id: 1601, name: "Drogowskaz" },
        { id: 1602, name: "Baner" },
        { id: 1603, name: "Billboard" },
      ],

      mInventoryItemSubcategoryMedicalMachine: [
        { id: 0, name: "Brak" },
        { id: 1701, name: "USG" },
        { id: 1702, name: "KTG" },
        { id: 1703, name: "Sonda" },
        { id: 1704, name: "RTG" },
        { id: 1705, name: "MGF" },
        { id: 1706, name: "Densytometr" },
        { id: 1707, name: "Tomograf komputerowy" },
        { id: 1708, name: "Mammograf" },
        { id: 1709, name: "System do wprowadzania środka kontrastujacego" },
      ],

      mInventoryNoCategoryFilter: [0],
      mInventoryElectronicsCategoryFilter: [1, 2, 3, 4, 5, 6, 7],
      mInventoryFurnitureCategoryFilter: [9, 12, 14],
      mInventoryFireEquipmentCategoryFilter: [11, 13],
      mInventoryPromotionCategoryFilter: [10, 16],
      mInventoryMedicalDevicesCategoryFilter: [8, 17],


      mInventoryElectronicsSubcategoryFilter: [
        401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414,
        501, 502, 503, 504,
        601, 602, 603, 604, 605, 606, 607, 608,
        701, 702, 703, 704, 705, 706, 707
      ],
      mInventoryFurnitureSubcategoryFilter: [
        901, 902,
        1201, 1202, 1203, 1204, 1205, 1206, 1207, 1211, 1212, 1213, 1214, 1215, 1216, 1217,
        1218, 1219, 1221,
        1401, 1402, 1403, 1404,
      ],
      mInventoryFireEquipmentSubcategoryFilter: [

      ],
      mInventoryPromotionSubcategoryFilter: [
        903, 904, 905,
        1601, 1602, 1603,
      ],
      mInventoryMedicalDevicesSubcategoryFilter: [
        1208, 1209, 1210, 1220,
      ],

      mInventoryWorkplaceCategory: [
        { id: 0, name: "Domyślna" },
        { id: 1, name: "Rejestracja" },
      ],

      mInventoryWorkplaceStatus: [
        { id: 0, name: "Domyślny" },
        { id: 1, name: "Aktywne" },
        { id: 2, name: "Nieaktywne" },
      ]

    }
  },

  methods: {
    mGetName(source, id) {
      if (source) {
        let target = source.find(c => c.id == id)
        if (target) return target.name
      }

      else return `??? [${id}]`
    },

    mGetRoomType(id) {
      if (this.mRoomTypes) {
        let target = this.mRoomTypes.find(c => c.id == id)
        if (target) return target.name
      }

      else return `??? [${id}]`
    },

    mIsInCategory(source, value) {
      if (source) {
        let search = source.includes(value)
        return search
      }
      else return false
    },
  }
}
