import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import axios from 'axios'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        trigger:0,
        triggerCrm:0,
        allReports:[],
        myReports:[],
        lastAllocatedId:null,
        indexAllocated:null,
        indexCompleted:null,
        indexWaiting:null,
        tabIndex:0
    },

    mutations: {
        [Mutation.CRM_TRIGGER_UP](currentState){
            currentState.trigger++;
        },

        [Mutation.CRM_TRIGGER_CRM_UP](currentState){
            currentState.triggerCrm++;
        },

        [Mutation.CRM_SET_REPORTS](currentState,reports){
            currentState.allReports = reports;
        },

        [Mutation.CRM_SET_MYREPORTS](currentState,reports){
            currentState.myReports = reports;
        },

        [Mutation.CRM_SET_LAST_ALLOCATED](currentState,lastAllocated){
            currentState.lastAllocatedId = lastAllocated;
        },

        [Mutation.CRM_SET_INDEX_ALLOCATED](currentState, index){
            currentState.indexAllocated = index;
        },
        [Mutation.CRM_SET_INDEX_COMPLETED](currentState, index){
            currentState.indexCompleted = index;
        },

        [Mutation.CRM_SET_INDEX_WAITING](currentState, index){
            currentState.indexWaiting = index;
        },

        [Mutation.CRM_SET_TABINDEX](currentState, index){
            currentState.tabIndex = index;
        }
    },

    actions: {

        [Action.CRM_ADD_REPORT](context, report) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.CRM_POST_REPORT,
                        data: report,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.CRM_TRIGGER_UP);
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_GET_ALL_REPORTS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_GET_REPORTS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.CRM_SET_REPORTS,response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_GET_REPORT_BY_ID](context, report) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_GET_REPORT_BY_ID.replace('{id}', report.id),
                        data: report,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_MODIFY_REPORT](context, report) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.CRM_PUT_REPORT.replace('{id}', report.id),
                        data: report,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_REMOVE_REPORT](context, report) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.CRM_DELETE_REPORT.replace('{id}', report.id),
                        data: report,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_GET_FULL_REPORT_BY_ID](context, report) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_GET_FULL_REPORT_BY_ID.replace('{id}', report.id),
                        data: report,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_REPORT_GET_BY_USER_ID](context, userId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_REPORT_GET_BY_USER_ID.replace('{id}', userId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.CRM_SET_MYREPORTS,response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_REPORT_GET_BY_USER_EMAIL](context, userEmail) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_REPORT_GET_BY_USER_EMAIL.replace('{email}', userEmail),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_PUT_ANOTHER_DIAGNOSIS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.CRM_PUT_ANOTHER_DIAGNOSIS.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_GET_REPORT_TASKS_PERFORMER_CHANGELOG](context, reportId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_GET_REPORT_TASKS_PERFORMER_CHANGELOG.replace('{id}', reportId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_ADD_TASK](context, task) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.CRM_POST_TASK,
                        data: task,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_GET_ALL_TASKS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_GET_TASKS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_GET_TASK_BY_ID](context, task) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_GET_TASK_BY_ID.replace('{id}', task.id),
                        data: task,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_MODIFY_TASK](context, task) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.CRM_PUT_TASK.replace('{id}', task.id),
                        data: task,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_REMOVE_TASK](context, task) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.CRM_DELETE_TASK.replace('{id}', task.id),
                        data: task,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_ADD_CHECKLIST](context, checklist) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.CRM_POST_CHECKLIST,
                        data: checklist,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_GET_ALL_CHECKLISTS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_GET_CHECKLISTS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_GET_CHECKLIST_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_GET_CHECKLIST_BY_ID.replace('{id}', id),
                        data: id,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_MODIFY_CHECKLIST](context, checklist) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.CRM_PUT_CHECKLIST.replace('{id}', checklist.id),
                        data: checklist,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_REMOVE_CHECKLIST](context, checklist) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.CRM_DELETE_CHECKLIST.replace('{id}', checklist.id),
                        data: checklist,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_GET_FULL_CHECKLIST_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_GET_FULL_CHECKLIST_BY_ID.replace('{id}', id),
                        data: id,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_CRMMEDIA_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_CRMMEDIA_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_CRMMEDIA_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();
                    payload.append('type', form.type)
                    payload.append('name', form.name)
                    payload.append('description', form.description)
                    payload.append('file', form.file)

                    axios({
                        method: 'post',
                        url: Apis.CRM_CRMMEDIA_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_CRMMEDIA_DELETE](context, mediaId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.CRM_CRMMEDIA_DELETE.replace('{id}', mediaId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_CRMMEDIA_GET_BY_ID](context, mediaId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_CRMMEDIA_GET_BY_ID.replace('{id}', mediaId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.CRM_CRMMEDIA_DOWNLOAD](context, fileId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.CRM_CRMMEDIA_DOWNLOAD.replace('{id}', fileId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //CRM_REPORT_ADD_FILE
        [Action.CRM_REPORT_ADD_FILE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.CRM_REPORT_ADD_FILE.replace('{reportId}', payload.reportId),
                        data: payload,
                        params:{
                            mediaId:payload.mediaId
                        },
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


        // CONVERSATIONS
        [Action.CRM_GET_CONVERSATION](context, reportId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CRM_CONVERSATION_GET.replace('{id}', reportId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.CRM_CONVERSATION_REPLY](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        data: payload,
                        url: Apis.CRM_CONVERSATION_REPLY,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}