export default {
  data: function () {
    return {
      /**Oddziały wojewódzkie NFZ
       * NFZ Regional Branches
       */
      nfz: {
        id: 'nfz',
        values: [
          {
            value: 1,
            text: '01 DOLNOŚLĄSKI OW NFZ',
            valueText: "01"
          },
          {
            value: 2,
            text: '02 KUJAWSKO-POMORSKI OW NFZ',
            valueText: "02"
          },
          {
            value: 3,
            text: '03 LUBELSKI OW NFZ',
            valueText: "03"
          },
          {
            value: 4,
            text: '04 LUBUSKI OW NFZ',
            valueText: "04"
          },
          {
            value: 5,
            text: '05 ŁÓDZKI OW NFZ',
            valueText: "05"
          },
          {
            value: 6,
            text: '06 MAŁOPOLSKI OW NFZ',
            valueText: "06"
          },
          {
            value: 7,
            text: '07 MAZOWIECKI OW NFZ',
            valueText: "07"
          },
          {
            value: 8,
            text: '08 OPOLSKI OW NFZ',
            valueText: "08"
          },
          {
            value: 9,
            text: '09 PODKARPACKI OW NFZ',
            valueText: "09"
          },
          {
            value: 10,
            text: '10 PODLASKI OW NFZ',
            valueText: "10"
          },
          {
            value: 11,
            text: '11 POMORSKI OW NFZ',
            valueText: "11"
          }, {
            value: 12,
            text: '12 ŚLĄSKI OW NFZ',
            valueText: "12"
          },
          {
            value: 13,
            text: '13 ŚWIĘTOKRZYSKI OW NFZ',
            valueText: "13"
          },
          {
            value: 14,
            text: '14 WARMIŃSKO-MAZURSKI OW NFZ',
            valueText: "14"
          },
          {
            value: 15,
            text: '15 WIELKOPOLSKI OW NFZ',
            valueText: "15"
          },
          {
            value: 16,
            text: '16 ZACHODNIOPOMORSKI OW NFZ',
            valueText: "16"
          }
        ]
      },
      /**Podstawa ubezpieczenia
       * Basis of insurance
       */
      insuranceBasis: {
        id: 'insuranceBasis',
        values: [
          {
            value: 0,
            text: 'U: Pacjenci ubezpieczeni z wyłączeniem przepisów o koordynacji oraz niektórych punktów art.2 i art.12',
          },
          {
            value: 1,
            text: 'N: Pacjenci uprawnieni na podstawie art.2 ust.1 pkt 2 (decyzja wójta)',
          },
          {
            value: 2,
            text: 'IA: Pacjenci uprawnieni na podstawie art.2 ust.1 pkt 3 lit. a. ustawy - dzieci do 18 roku życia',
          },
          {
            value: 3,
            text: 'IAU: Pacjenci uprawnieni na podstawie art.2 ust.1 pkt 3 lit. b. ustawy - dzieci do 18 roku życia',
          },
          {
            value: 4,
            text: 'IB: Pacjenci uprawnieni na podstawie art.2 ust.1 pkt 4 lit. a. ustawy',
          },
          {
            value: 5,
            text: 'IBU: Pacjenci uprawnieni na podstawie art.2 ust.1 pkt 4 lit. b. ustawy',
          }
        ]
      },
      /**Typ ubezpieczenia
       * Type of insurance
       */
      typeOfInsurance: {
        id: 'typeOfInsurance',
        values: [
          {
            value: 0,
            text: 'U - Osoba podlegająca obowiązkowi ubezpieczenia',
          },
          {
            value: 1,
            text: 'D - Osoba objęta dobrowolnym ubezpieczeniem',
          },
          {
            value: 2,
            text: 'C - Osoba będąca członkiem rodziny osoby podlegającej ubezpieczeniu',
          },
          {
            value: 3,
            text: 'Osoba do 18 roku życia oraz kobiety w okresie ciąży, porodu i połogu',
          },
          {
            value: 4,
            text: 'Osoba posiadająca prawo do świadczeń na podstawie decyzji wójta/burmistrza/prezydenta gminy',
          },
          {
            value: 5,
            text: 'Osoba podlegająca przepisom o koordynacji',
          },
          {
            value: 6,
            text: '00 - Osoba korzystająca z ubezpieczenia innych osób',
          },
          {
            value: 7,
            text: '01 - Osoba objęta ubezpieczeniem społecznym',
          },
          {
            value: 8,
            text: '02 - Żołnierz zawodowy',
          },
          {
            value: 9,
            text: '03 - Policjant',
          },
          {
            value: 10,
            text: '04 - Funkcjonariusz Urzędu Ochrony Państwa',
          },
          {
            value: 11,
            text: '05 - Funkcjonariusz Straży Granicznej',
          },
          {
            value: 12,
            text: '06 - Funkcjonariusz Służby Więziennej',
          },
          {
            value: 13,
            text: '07 - Funkcjonariusz Państwowej Służby Pożarnej',
          },
          {
            value: 14,
            text: '08 - Poseł lub senator',
          },
          {
            value: 15,
            text: '09 - Sędzia lub prokurator',
          },
          {
            value: 16,
            text: '10 - Osoba pobierajaca emeryturę lub rentę',
          },
          {
            value: 17,
            text: '11 - Dziecko, uczeń lub słuchacz zakładu kształcenia nauczycieli',
          },
          {
            value: 18,
            text: '12 - Student lub słuchacz studiów doktoranckich',
          },
          {
            value: 19,
            text: '13 - Słuchacz Krajowej Szkoły Administracji Publicznej',
          },
          {
            value: 20,
            text: '14 - Osoba pobierająca stypendium sportowe po ukończeniu 15 roku życia',
          },
          {
            value: 21,
            text: '15 - Bezrobotny',
          },
          {
            value: 22,
            text: '16 - Osoba pobierająca zasiłek przedemerytalny lub świadczenie przedemerytalne',
          },
          {
            value: 23,
            text: '17 - Osoba pobierająca rentę socjalną, zasiłek stały wyrównawczy lub gwarantowany zasiłek okresowy',
          },
          {
            value: 24,
            text: '18 - Kombatant',
          },
          {
            value: 25,
            text: '19 - Osoba korzystająca z urlopu wychowawczego',
          },
          {
            value: 26,
            text: '20 - Rolnik lub jego domownik',
          },
          {
            value: 27,
            text: '21 - Cudzoziemiec, który przebywa na terytorium RP na podstawie karty stałego pobytu lub karty tymczasowego pobytu',
          }
        ]
      },
      /**Płatnik ubezpieczenia
       * Payer of insurance
       */
      insuranceFund: {
        id: 'insuranceFund',
        values: [
          {
            value: 0,
            text: 'ZUS',
          },
          {
            value: 1,
            text: 'KRUS',
          },
          {
            value: 2,
            text: 'Inne w Polsce',
          },
          {
            value: 3,
            text: 'W innym państwie',
          }
        ]
      },
      /**Status socjalny pacjenta
       * Patient's social status
       */
      socialStatus: {
        id: 'socialStatus',
        values: [
          {
            value: 0,
            text: 'Uczeń/student',
          },
          {
            value: 1,
            text: 'Robotnik/rolnik',
          },
          {
            value: 2,
            text: 'Pracownik umysłowy',
          },
          {
            value: 3,
            text: 'Bezrobotny',
          },
          {
            value: 4,
            text: 'Emeryt/rencista',
          },
          {
            value: 5,
            text: 'Bezdomny',
          }
        ]
      },
      /**Uprawnienie
       * Entitlement
       */
      entitlement: {
        id: 'entitlement',
        values: [
          {
            value: 0,
            text: 'IB - Inwalidzi wojenni, małżonkowie inwalidów wojennych, osoby represjonowane, cywilne niewidome ofiary działań wojennych',
          },
          {
            value: 1,
            text: 'IW - Inwalidzi wojskowi',
          },
          {
            value: 2,
            text: 'ZK - Zasłużony honorowy dawca krwi lub dawca przeszczepu',
          },
          {
            value: 3,
            text: 'AZ - Uprawnienia na podstawie ustawy o zakazie stosowania azbestu',
          },
          {
            value: 4,
            text: 'WP - Żołnierze zawodowi w trakcie szkoleń i działań wojennych',
          },
          {
            value: 5,
            text: 'PO - Uprawnieni żołnierze lub pracownicy/Weteran poszkodowany',
          },
          {
            value: 6,
            text: 'CN - Ciężarna nieubezpieczona',
          },
          {
            value: 7,
            text: 'DN - Dziecko nieubezpieczone do 18 roku życia',
          },
          {
            value: 8,
            text: 'IN - Inny niż ubezpieczony',
          },
          {
            value: 9,
            text: 'BW - Uprawnienia na podstawie decyzji wójta, burmistrza lub prezydenta',
          },
          {
            value: 10,
            text: 'S - Osoby po ukończeniu 75 roku życia',
          },
          {
            value: 11,
            text: 'C - Kobieta w ciąży',
          },
          {
            value: 12,
            text: 'WE - Weteran poszkodowany, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%',
          }
        ]
      },
      /**Uprawnienia dodatkowe
       * Additional entitlements
       */
      additionalEntitlement: {
        id: 'additionalEntitlement',
        values: [
          {
            value: 0,
            text: 'ubezpieczony',
          },
          {
            value: 1,
            text: '42MON, 47MON,24A, 57MON: uprawnieni żołnierze oraz pracownicy',
          },
          {
            value: 2,
            text: '42MSW: policjanci, SG, BOR, PSP, SOP',
          },
          {
            value: 3,
            text: '47CZ: posiadający tytuł ZHDK lub ZDP',
          },
          {
            value: 4,
            text: '47IB, 47CIB, 57IW, 57IB: inwalida wojenny',
          },
          {
            value: 5,
            text: '47IW, 47CIW, 57IW: inwalida wojskowy',
          },
          {
            value: 6,
            text: '47CK, 57K: kombatant',
          },
          {
            value: 7,
            text: '42WŻ, 24BŻ, 24CŻ, 472AŻ, 57Ż: żołnierz-weteran',
          },
          {
            value: 8,
            text: '42WF, 24BF, 24CF, 472AF, 57F: funkcjonariusz-weteran',
          },
          {
            value: 9,
            text: '42WŻ30, 472AŻ30, 47C11, 57Ż30: żołnierz-weteran-30%',
          },
          {
            value: 10,
            text: '42WF30, 472AF30, 47C11, 57F30: funckjonariusz-weteran-30%',
          },
          {
            value: 11,
            text: '47C10, 57MON30: uprawnieni żołnierze oraz pracownicy-30%',
          },
          {
            value: 12,
            text: '57ZS: żołnierz zastępczej służby wojskowej',
          },
          {
            value: 13,
            text: '47CN, 57CN: cywilne niewidome ofiary działań wojennych',
          },
          {
            value: 14,
            text: '47OR, 57OR: osoby represjonowane (OR)',
          },
          {
            value: 15,
            text: '47CRP, 57RP: osoby represjonowane z przyczyn politycznych (RP)',
          },
          {
            value: 16,
            text: 'C, 31C: kobieta w okresie ciąży, porodu i połogu',
          },
          {
            value: 17,
            text: '31D: dzieci i młodzież do ukończenia 18. roku życia - stomatologia',
          },
          {
            value: 18,
            text: '57M: dzieci i młodzież',
          },
          {
            value: 19,
            text: '47DN, 47CDN, 57CP,ZZDN, 57M: świadczeniobiorca do 18. roku życia',
          },
          {
            value: 20,
            text: '47CC: kobieta w ciąży',
          },
          {
            value: 21,
            text: 'ZZC: kobieta w ciąży powikłanej',
          },
          {
            value: 22,
            text: '47COA, 57OA: działacz',
          },
          {
            value: 23,
            text: '47ZN, 47CZN, 57ZN, ZSN: niepełnosprawny',
          },
          {
            value: 24,
            text: '31N, 57M: niepełnosprawne dzieci i młodz. 16-18',
          },
          {
            value: 25,
            text: '31N18, 57M: niepełnosprawna młodz. 18+',
          },
          {
            value: 26,
            text: '47CZND, 57ZND, ZSND, 47ZND: niepeł. ze wskaz.',
          },
          {
            value: 27,
            text: '47CPP, 47PP, 57PP: osoba deportowana do pracy przymusowej',
          },
          {
            value: 28,
            text: '*42WABW, 24BABW, 24CABW, 472ABW, 57ABW: funkcjonariusz ABW-weteran',
          },
          {
            value: 29,
            text: '*nieubezpieczony, uprawniony do świadczeń',
          },
          {
            value: 30,
            text: '*służba kandydacka MS',
          },
          {
            value: 31,
            text: '*osoba pracująca przy azbeście',
          },
          {
            value: 32,
            text: '32aDILO: osoba, której wystawiono kartę diagnostyki i leczenia onkologicznego'
          },
          {
            value: 33,
            text: '34M: osoba małoletnia'
          },
          {
            value: 34,
            text: '34N: osoba posiadająca orzeczenie o znacznym stopniu niepełnosprawności'
          }
        ]
      },
      /**Stopień niepełnosprawności pacjenta
       * Patient's level of disability
       */
      levelOfDisability: {
        id: 'levelOfDisability',
        values: [
          {
            value: 0,
            text: 'brak',
          },
          {
            value: 1,
            text: 'lekki',
          },
          {
            value: 2,
            text: 'umiarkowany',
          },
          {
            value: 3,
            text: 'znaczny',
          },
          {
            value: 4,
            text: 'dziecko do 16. roku życia',
          },
        ]
      },
      /**Kraj ubezpieczenia (Polska lub UE)
       * Country of insurance (Poland or EU)
       */
      insuredIn: {
        id: 'insuredIn',
        values: [
          {
            value: 0,
            text: 'Polsce',
          },
          {
            value: 1,
            text: 'kraju EU lub EFTA',
          },
        ]
      },
      /**Dokument potwierdzający prawo do świadczeń (wydany w Polsce)
       * Insurance document (issued in Poland)
       */
      insuranceDocumentPL: {
        id: 'insuranceDocumentPL',
        values: [
          {
            value: 0,
            text: 'Legitymacja rencisty/emeryta',
          },
          {
            value: 1,
            text: 'Zgłoszenie do ubezp. z dowodem opłacenia składek (ZUA/ZZA/ZCNA)',
          },
          {
            value: 2,
            text: 'Inny dokument potwierdzający uprawnienia',
          },
          {
            value: 3,
            text: 'Oświadczenie',
          },
        ]
      },
      /**Dokument potwierdzjący prawo do świadczeń (wydany w EU)
       * Insurance document (issued in EU)
       */
      insuranceDocumentEU: {
        id: 'insuranceDocumentEU',
        values: [
          {
            value: 0,
            text: 'Certyfikat',
          },
          {
            value: 1,
            text: 'Karta EKUZ',
          },
          {
            value: 2,
            text: 'Poświadczenie',
          },
        ]
      },
      /**Kraj ubezpieczenia
       * Country of insurance
       */
      insuranceCountry: {
        id: 'insuranceCountry',
        values: [
          {
            value: 0,
            text: 'Austria',
          },
          {
            value: 1,
            text: 'Belgia',
          },
          {
            value: 2,
            text: 'Bułgaria',
          },
          {
            value: 3,
            text: 'Chorwacja',
          },
          {
            value: 4,
            text: 'Cypr',
          },
          {
            value: 5,
            text: 'Czechy',
          },
          {
            value: 6,
            text: 'Dania',
          },
          {
            value: 7,
            text: 'Estonia',
          },
          {
            value: 8,
            text: 'Finlandia',
          },
          {
            value: 9,
            text: 'Francja',
          },
          {
            value: 10,
            text: 'Grecja',
          },
          {
            value: 11,
            text: 'Hiszpania',
          },
          {
            value: 12,
            text: 'Holandia',
          },
          {
            value: 13,
            text: 'Irlandia',
          },
          {
            value: 14,
            text: 'Islandia',
          },
          {
            value: 15,
            text: 'Liechtenstein',
          },
          {
            value: 16,
            text: 'Litwa',
          },
          {
            value: 17,
            text: 'Luksemburg',
          },
          {
            value: 18,
            text: 'Łotwa',
          },
          {
            value: 19,
            text: 'Malta',
          },
          {
            value: 20,
            text: 'Niemcy',
          },
          {
            value: 21,
            text: 'Norwegia',
          },
          {
            value: 22,
            text: 'Portugalia',
          },
          {
            value: 23,
            text: 'Rumunia',
          },
          {
            value: 24,
            text: 'Słowacja',
          },
          {
            value: 25,
            text: 'Słowenia',
          },
          {
            value: 26,
            text: 'Szwajcaria',
          },
          {
            value: 27,
            text: 'Szwecja',
          },
          {
            value: 28,
            text: 'Węgry',
          },
          {
            value: 29,
            text: 'Włochy',
          },
        ]
      },
      /**Dodatkowa podstawa ubezpieczenia
       * Additional basis of insurance
       */
      insuranceBasis2: {
        id: 'insuranceBasis2',
        values: [
          {
            value: 0,
            text: 'Artykuł 22.1.a.i, 22.3',
          },
          {
            value: 1,
            text: 'Artykuł 31.a',
          },
          {
            value: 2,
            text: 'Artykuł 34.a',
          },
        ]
      },
      /**Dodatkowa podstawa ubezpieczenia
       * Additional basis of insurance
       */
      insuranceBasis3: {
        id: 'insuranceBasis3',
        values: [
          {
            value: 0,
            text: 'Objęcie ubezpieczeniem zdrowotnym',
          },
          {
            value: 1,
            text: 'Uzyskanie decyzji, o której mowa w art.54 ustawy o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych z dnia 27.08.2004r.',
          },
          {
            value: 2,
            text: 'Art.2 ust.1 pkt 4 lit.a ustawy o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych z dnia 27.08.2004r.',
          },
          {
            value: 3,
            text: 'Art.2 ust.1 pkt 4 lit.b ustawy o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych z dnia 27.08.2004r.',
          },
          {
            value: 4,
            text: 'Art.67 ust.4-7 ustawy o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych z dnia 27.08.2004r.',
          },
        ]
      },
      /**Grupy uprawnień w POZ
       * POZ entitlement groups
       */
      grupy_upr_poz: [
        {
          kod: "1",
          opis: "Prawo do świadczeń opieki zdrowotnej niezakwalifikowanych jako świadczenia gwarantowane",
          status: "A",
        },
        {
          kod: "2",
          opis: "Prawo do wyrobów medycznych - wyroby bezpłatne",
          status: "A",
        },
        {
          kod: "3",
          opis: "Prawo do wyrobów medycznych - bez okresów użytkowania",
          status: "A",
        },
        {
          kod: "4",
          opis: "Prawo do świadczeń poza kolejnością",
          status: "A",
        },
        {
          kod: "5",
          opis: "Prawo do świadczeń ambulatoryjnych bez skierowania",
          status: "A",
        },
        {
          kod: "6",
          opis: "Pozostałe dla dzieci, młodzieży, kobiet w ciąży i niepełnosprawnych",
          status: "A",
        },
        {
          kod: "7",
          opis: "Prawo do diagnostyki onkologicznej i leczenia na podstawie karty DILO",
          status: "A",
        },
        {
          kod: "8",
          opis: "Prawo do świadczeń - powikłania w okresie prenatalnym i okresie porodu",
          status: "A",
        },
        {
          kod: "9",
          opis: "xxxxx",
          status: "N",
        }
      ],

      obsz_stos_poz: [
        {
          kod: "SWIAD",
          opis: "SWIAD",
          status: "A",
        },
        {
          kod: "ZPOSP",
          opis: "ZPOSP",
          status: "A",
        }
      ],
      /**Typy dokumentów dotyczących uprawnień */
      typy_dok_poz: [
        {
          kod: "1",
          opis: "Książka inwalidy wojennego - wojskowego",
          wym_nr_dok: "T",
          wym_data_wazn: "T",
          wym_data_wyst: "T",
          wym_npwz: "N",
          status: "A",
        },
        {
          kod: "2",
          opis: "Legitymacja cywilnej niewidomej ofiary działań wojennych",
          wym_nr_dok: "T",
          wym_data_wazn: "T",
          wym_data_wyst: "T",
          wym_npwz: "N",
          status: "A",
        },
        {
          kod: "3",
          opis: "Legitymacja osoby represjonowanej",
          wym_nr_dok: "T",
          wym_data_wazn: "T",
          wym_data_wyst: "T",
          wym_npwz: "N",
          status: "A",
        },
        {
          kod: "4",
          opis: "Legitymacja osoby poszkodowanej poza granicami państwa",
          wym_nr_dok: "T",
          wym_data_wazn: "T",
          wym_data_wyst: "T",
          wym_npwz: "N",
          status: "A",
        },
        {
          kod: "5",
          opis: "Karta - Dokument potwierdzający uprawnienia przysługujące weteranowi poszkodowanemu - funkcjonariuszowi do korzystania ze świadczeń opieki zdrowotnej. Ważny wraz z dokumentem tożsamości.",
          wym_nr_dok: "N",
          wym_data_wazn: "N",
          wym_data_wyst: "T",
          wym_npwz: "N",
          status: "A",
        },
        {
          kod: "6",
          opis: "Legitymacja weterana poszkodowanego",
          wym_nr_dok: "T",
          wym_data_wazn: "N",
          wym_data_wyst: "T",
          wym_npwz: "N",
          status: "A",
        },
        {
          kod: "7",
          opis: "Zaświadczenie lekarza podstawowej opieki zdrowotnej lub lekarza ubezpieczenia zdrowotnego posiadającego, specjalizację II stopnia lub tytuł specjalisty w dziedzinie położnictwa i ginekologii, perinatologii, neonatologii lub pediatrii, neurologii dziecięcej, kardiologii dziecięcej i chirurgii dziecięcej.",
          wym_nr_dok: "N",
          wym_data_wazn: "N",
          wym_data_wyst: "T",
          wym_npwz: "T",
          status: "A",
        },
        {
          kod: "8",
          opis: "Orzeczenie o niepełnosprawności w stopniu znacznym (od 16 roku życia) albo inne traktowane równorzędnie (w tym wyroki sądowe i legitymacje)",
          wym_nr_dok: "T",
          wym_data_wazn: "T",
          wym_data_wyst: "T",
          wym_npwz: "N",
          status: "A",
        },
        {
          kod: "9",
          opis: "Orzeczenie o niepełnosprawności (do 16 roku życia) łącznie ze wskazaniem konieczności stałej lub długotrwałej opieki lub pomocy innej osoby w związku ze znacznie ograniczoną możliwością samodzielnej egzystencji oraz konieczności stałego współudziału na co dzień opiekuna dziecka w procesie jego leczenia, rehabilitacji i edukacji.",
          wym_nr_dok: "T",
          wym_data_wazn: "T",
          wym_data_wyst: "T",
          wym_npwz: "N",
          status: "A",
        },
        {
          kod: "10",
          opis: "Oświadczenie",
          wym_nr_dok: "N",
          wym_data_wazn: "N",
          wym_data_wyst: "T",
          wym_npwz: "N",
          status: "A",
        },
        {
          kod: "11",
          opis: "Decyzja o przyznaniu statusu weterana poszkodowanego",
          wym_nr_dok: "T",
          wym_data_wazn: "N",
          wym_data_wyst: "T",
          wym_npwz: "N",
          status: "A",
        }
      ],

      mPoz: [
        {
          value: 0,
          kod: "47IW",
          opis: "bezpłatne wyroby medyczne - inwalida wojskowy",
          kategoria: "inwalida wojskowy",
          podstawa: "art. 47 ust. 1 ustawy",
          status: "A",
        },
        {
          value: 1,
          kod: "47IB",
          opis: "bezpłatne wyroby medyczne - inwalida wojenny",
          kategoria: "inwalida wojenny",
          podstawa: "art. 47 ust. 1 ustawy",
          status: "A",
        },
        {
          value: 2,
          kod: "47OR",
          opis: "bezpłatne wyroby medyczne - osoba represjonowana",
          kategoria: "osoba represjonowana",
          podstawa: "art. 47 ust. 1 ustawy",
          status: "A",
        },
        {
          value: 3,
          kod: "47CN",
          opis: "bezpłatne wyroby medyczne - cywilna niewidoma ofiara działań wojennych",
          kategoria: "cywilna niewidoma ofiara działań wojennych",
          podstawa: "art. 47 ust. 1 ustawy",
          status: "A",
        },
        {
          value: 4,
          kod: "47MON",
          opis: "bezpłatne wyroby medyczne w zakresie leczenia urazów lub chorób nabytych podczas wykonywania zadań poza granicami państwa - uprawniony żołnierz lub pracownik",
          kategoria: "uprawniony żołnierz lub pracownik",
          podstawa: "art. 47 ust. 2 ustawy",
          status: "A",
        },
        {
          value: 5,
          kod: "472AŻ",
          opis: "bezpłatne wyroby medyczne w zakresie leczenia urazów lub chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - żołnierz",
          kategoria: "weteran poszkodowany - żołnierz",
          podstawa: "art. 47 ust. 2a ustawy",
          status: "A",
        },
        {
          value: 6,
          kod: "472AF",
          opis: "bezpłatne wyroby medyczne w zakresie leczenia urazów lub chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - - weteran poszkodowany - funkcjonariusz i zwolniony ze służby funkcjonariusz Policji, Straży Granicznej, Biura Ochrony Rządu, Służby Ochrony Państwa, Agencji Bezpieczeństwa Wewnętrznego, Agencji Wywiadu oraz strażak i zwolniony ze służby strażak Państwowej Straży Pożarnej",
          kategoria: "weteran poszkodowany- weteran poszkodowany - funkcjonariusz i zwolniony ze służby funkcjonariusz Policji, Straży Granicznej, Biura Ochrony Rządu, Służby Ochrony Państwa, Agencji Bezpieczeństwa Wewnętrznego, Agencji Wywiadu oraz strażak i zwolniony ze służby strażak Państwowej Straży Pożarnej",
          podstawa: "art. 47 ust. 2a ustawy",
          status: "A",
        },
        {
          value: 7,
          kod: "472ABW",
          opis: "bezpłatne wyroby medyczne w zakresie leczenia urazów lub chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - funkcjonariusz Agencji Bezpieczeństwa Wewnętrznego",
          kategoria: "weteran poszkodowany - funkcjonariusz Agencji Bezpieczeństwa Wewnętrznego",
          podstawa: "art. 47 ust. 2a ustawy",
          status: "A",
        },
        {
          value: 8,
          kod: "47DN",
          opis: "wyroby medyczne bez uwzględnienia okresów użytkowania, w liczbie wskazanej w zleceniu - świadczeniobiorca do 18. roku życia, u którego stwierdzono ciężkie i nieodwracalne upośledzenie albo nieuleczalną chorobę zagrażającą życiu, które powstały w prenatalnym okresie rozwoju dziecka lub w czasie porodu",
          kategoria: "świadczeniobiorca do 18. roku życia, u którego stwierdzono ciężkie i nieodwracalne upośledzenie albo nieuleczalną chorobę zagrażającą życiu, które powstały w prenatalnym okresie rozwoju dziecka lub w czasie porodu",
          podstawa: "art. 47 ust. 1a ustawy",
          status: "A",
        },
        {
          value: 9,
          kod: "47ZN",
          opis: "wyroby medyczne bez uwzględnienia okresów użytkowania, w liczbie wskazanej w zleceniu - świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności",
          kategoria: "świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności",
          podstawa: "art. 47 ust. 1b ustawy",
          status: "A",
        },
        {
          value: 10,
          kod: "47ZND",
          opis: "wyroby medyczne bez uwzględnienia okresów użytkowania, w liczbie wskazanej w zleceniu - świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności (do 16 roku życia)",
          kategoria: "świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności (do 16 roku życia)",
          podstawa: "art. 47 ust. 1b ustawy",
          status: "A",
        },
        {
          value: 11,
          kod: "42MON",
          opis: "świadczenia opieki zdrowotnej, niezakwalifikowane jako świadczenia gwarantowane oraz odpłatne świadczenia opieki zdrowotnej, w związku z urazami i chorobami nabytymi podczas wykonywania zadań poza granicami państwa - uprawniony żołnierz lub pracownik, także po zwolnieniu ze służby lub ustaniu umowy o pracę",
          kategoria: "uprawniony żołnierz lub pracownik, także po zwolnieniu ze służby lub ustaniu umowy o pracę",
          podstawa: "art. 42 ust. 1 ustawy",
          status: "A",
        },
        {
          value: 12,
          kod: "42WŻ",
          opis: "świadczenia opieki zdrowotnej, niezakwalifikowane jako świadczenia gwarantowane oraz odpłatne świadczenia opieki zdrowotnej, w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - żołnierz",
          kategoria: "weteran poszkodowany - żołnierz",
          podstawa: "art. 42 ust. 1a ustawy",
          status: "A",
        },
        {
          value: 13,
          kod: "42WF",
          opis: "świadczenia opieki zdrowotnej, niezakwalifikowane jako świadczenia gwarantowane oraz odpłatne świadczenia opieki zdrowotnej, w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - funkcjonariusz",
          kategoria: "weteran poszkodowany - funkcjonariusz i zwolniony ze służby funkcjonariusz Policji, Straży Granicznej, Biura Ochrony Rządu, Służby Ochrony Państwa, Agencji Bezpieczeństwa Wewnętrznego, Agencji Wywiadu oraz strażak i zwolniony ze służby strażak Państwowej Straży Pożarnej",
          podstawa: "art. 42 ust. 1a ustawy",
          status: "A",
        },
        {
          value: 14,
          kod: "42WABW",
          opis: "świadczenia opieki zdrowotnej, niezakwalifikowane jako świadczenia gwarantowane oraz odpłatne świadczenia opieki zdrowotnej, w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - funkcjonariusz Agencji Bezpieczeństwa Wewnętrznego",
          kategoria: "weteran poszkodowany - funkcjonariusz Agencji Bezpieczeństwa Wewnętrznego",
          podstawa: "art. 42 ust. 1a ustawy",
          status: "A",
        },
        {
          value: 15,
          kod: "42MSW",
          opis: "świadczenia opieki zdrowotnej, niezakwalifikowane jako świadczenia gwarantowane oraz odpłatne świadczenia opieki zdrowotnej, w związku z urazami i chorobami nabytymi podczas wykonywania zadań poza granicami państwa - policjant, funkcjonariusz Straży Granicznej, funkcjonariusz Służby Ochrony Państwa, strażak Państwowej Straży Pożarnej, a także pracownik tych służb, także po zwolnieniu ze służby lub ustaniu umowy o pracę",
          kategoria: "policjant, funkcjonariusz Straży Granicznej, funkcjonariusz Służby Ochrony Państwa, strażak Państwowej Straży Pożarnej, a także pracownik tych służb, także po zwolnieniu ze służby lub ustaniu umowy o pracę",
          podstawa: "art. 42 ust. 2 ustawy",
          status: "A",
        },
        {
          value: 16,
          kod: "24A",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej, o których mowa w art. 20 ustawy, w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa",
          kategoria: "uprawniony żołnierz lub pracownik",
          podstawa: "art. 24a ustawy",
          status: "A",
        },
        {
          value: 17,
          kod: "24BŻ",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej, o których mowa w art. 20 ustawy, w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - żołnierz",
          kategoria: "weteran poszkodowany - żołnierz",
          podstawa: "art. 24b ustawy",
          status: "A",
        },
        {
          value: 18,
          kod: "24BF",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej, o których mowa w art. 20 ustawy, w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - funkcjonariusz",
          kategoria: "weteran poszkodowany - funkcjonariusz",
          podstawa: "art. 24b ustawy",
          status: "A",
        },
        {
          value: 19,
          kod: "24BABW",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej, o których mowa w art. 20 ustawy, w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - funkcjonariusz Agencji Bezpieczeństwa Wewnętrznego",
          kategoria: "weteran poszkodowany - funkcjonariusz Agencji Bezpieczeństwa Wewnętrznego",
          podstawa: "art. 24b ustawy",
          status: "A",
        },
        {
          value: 20,
          kod: "24CŻ",
          opis: "prawo do korzystania poza kolejnością ze stacjonarnych i całodobowych świadczeń opieki zdrowotnej, innych niż te, o których mowa w art. 20 ustawy, w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - żołnierz",
          kategoria: "weteran poszkodowany - żołnierz",
          podstawa: "art. 24c ustawy",
          status: "A",
        },
        {
          value: 21,
          kod: "24CF",
          opis: "prawo do korzystania poza kolejnością ze stacjonarnych i całodobowych świadczeń opieki zdrowotnej, innych niż te, o których mowa w art. 20 ustawy, w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - funkcjonariusz",
          kategoria: "weteran poszkodowany - funkcjonariusz",
          podstawa: "art. 24c ustawy",
          status: "A",
        },
        {
          value: 22,
          kod: "24CABW",
          opis: "prawo do korzystania poza kolejnością ze stacjonarnych i całodobowych świadczeń opieki zdrowotnej, innych niż te, o których mowa w art. 20 ustawy, w zakresie leczenia urazów i chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - funkcjonariusz Agencji Bezpieczeństwa Wewnętrznego",
          kategoria: "weteran poszkodowany - funkcjonariusz Agencji Bezpieczeństwa Wewnętrznego",
          podstawa: "art. 24c ustawy",
          status: "A",
        },
        {
          value: 23,
          kod: "47CZ",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - świadczeniobiorca, który posiada tytuł 'Zasłużonego Honorowego Dawcy Krwi', 'Dawcy Przeszczepu' lub 'Zasłużonego Dawcy Przeszczepu'",
          kategoria: "świadczeniobiorca, który posiada tytuł 'Zasłużonego Honorowego Dawcy Krwi', 'Dawcy Przeszczepu' lub 'Zasłużonego Dawcy Przeszczepu'",
          podstawa: "art. 47c ustawy albo art. 23 ust. 1 ustawy z dnia 1 lipca 2005 r. o pobieraniu, przechowywaniu i przeszczepianiu komórek, tkanek i narządów (Dz. U. z 2017 r. poz. 1000)",
          status: "A",
        },
        {
          value: 24,
          kod: "47CIB",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - inwalida wojenny",
          kategoria: "inwalida wojenny",
          podstawa: "art. 47c ustawy albo art. 23 ust. 1 ustawy z dnia 1 lipca 2005 r. o pobieraniu, przechowywaniu i przeszczepianiu komórek, tkanek i narządów (Dz. U. z 2017 r. poz. 1000)",
          status: "A",
        },
        {
          value: 25,
          kod: "47CIW",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - inwalida wojskowy",
          kategoria: "inwalida wojskowy",
          podstawa: "art. 47c ustawy albo art. 23 ust. 1 ustawy z dnia 1 lipca 2005 r. o pobieraniu, przechowywaniu i przeszczepianiu komórek, tkanek i narządów (Dz. U. z 2017 r. poz. 1000)",
          status: "A",
        },
        {
          value: 26,
          kod: "47CK",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - kombatant",
          kategoria: "kombatant",
          podstawa: "art. 47c ustawy albo art. 23 ust. 1 ustawy z dnia 1 lipca 2005 r. o pobieraniu, przechowywaniu i przeszczepianiu komórek, tkanek i narządów (Dz. U. z 2017 r. poz. 1000)",
          status: "A",
        },
        {
          value: 27,
          kod: "47CC",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - kobieta w ciąży",
          kategoria: "kobieta w ciąży",
          podstawa: "art. 47c ustawy albo art. 23 ust. 1 ustawy z dnia 1 lipca 2005 r. o pobieraniu, przechowywaniu i przeszczepianiu komórek, tkanek i narządów (Dz. U. z 2017 r. poz. 1000)",
          status: "A",
        },
        {
          value: 28,
          kod: "47CDN",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - świadczeniobiorca do 18. roku życia, u którego stwierdzono ciężkie i nieodwracalne upośledzenie albo nieuleczalną chorobę zagrażającą życiu, które powstały w prenatalnym okresie rozwoju dziecka lub w czasie porodu",
          kategoria: "świadczeniobiorca do 18. roku życia, u którego stwierdzono ciężkie i nieodwracalne upośledzenie albo nieuleczalną chorobę zagrażającą życiu, które powstały w prenatalnym okresie rozwoju dziecka lub w czasie porodu",
          podstawa: "art. 47c ustawy albo art. 23 ust. 1 ustawy z dnia 1 lipca 2005 r. o pobieraniu, przechowywaniu i przeszczepianiu komórek, tkanek i narządów (Dz. U. z 2017 r. poz. 1000)",
          status: "A",
        },
        {
          value: 29,
          kod: "47CZN",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności",
          kategoria: "świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności",
          podstawa: "art. 47c ustawy albo art. 23 ust. 1 ustawy z dnia 1 lipca 2005 r. o pobieraniu, przechowywaniu i przeszczepianiu komórek, tkanek i narządów (Dz. U. z 2017 r. poz. 1000)",
          status: "A",
        },
        {
          value: 30,
          kod: "47COA",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - działacz opozycji antykomunistycznej",
          kategoria: "działacz opozycji antykomunistycznej",
          podstawa: "art. 47c ustawy albo art. 23 ust. 1 ustawy z dnia 1 lipca 2005 r. o pobieraniu, przechowywaniu i przeszczepianiu komórek, tkanek i narządów (Dz. U. z 2017 r. poz. 1000)",
          status: "A",
        },
        {
          value: 31,
          kod: "47CRP",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - osoba represjonowana z powodów politycznych",
          kategoria: "osoba represjonowana z powodów politycznych",
          podstawa: "art. 47c ustawy albo art. 23 ust. 1 ustawy z dnia 1 lipca 2005 r. o pobieraniu, przechowywaniu i przeszczepianiu komórek, tkanek i narządów (Dz. U. z 2017 r. poz. 1000)",
          status: "A",
        },
        {
          value: 32,
          kod: "57IB",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - inwalida wojenny",
          kategoria: "inwalida wojenny",
          podstawa: "art. 57 ust. 2 pkt 10 ustawy",
          status: "A",
        },
        {
          value: 33,
          kod: "57IW",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - inwalida wojskowy",
          kategoria: "inwalida wojskowy",
          podstawa: "art. 57 ust. 2 pkt 10 ustawy",
          status: "A",
        },
        {
          value: 34,
          kod: "57K",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - kombatant",
          kategoria: "kombatant",
          podstawa: "art. 57 ust. 2 pkt 10 ustawy",
          status: "A",
        },
        {
          value: 35,
          kod: "57OR",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - osoba represjonowana",
          kategoria: "osoba represjonowana",
          podstawa: "art. 57 ust. 2 pkt 10 ustawy",
          status: "A",
        },
        {
          value: 36,
          kod: "57OA",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - działacz opozycji antykomunistycznej",
          kategoria: "działacz opozycji antykomunistycznej",
          podstawa: "art. 57 ust. 2 pkt 10 ustawy",
          status: "A",
        },
        {
          value: 37,
          kod: "57RP",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - osoba represjonowana z powodów politycznych",
          kategoria: "osoba represjonowana z powodów politycznych",
          podstawa: "art. 57 ust. 2 pkt 10 ustawy",
          status: "A",
        },
        {
          value: 38,
          kod: "57CN",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - cywilna niewidoma ofiara działań wojennych",
          kategoria: "cywilna niewidoma ofiara działań wojennych",
          podstawa: "art. 57 ust. 2 pkt 10a ustawy",
          status: "A",
        },
        {
          value: 39,
          kod: "57MON",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania, w zakresie leczenia urazów lub chorób nabytych podczas wykonywania zadań poza granicami państwa - uprawniony żołnierz lub pracownik",
          kategoria: "uprawniony żołnierz lub pracownik",
          podstawa: "art. 57 ust. 2 pkt 12 ustawy",
          status: "A",
        },
        {
          value: 40,
          kod: "57Ż",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania, w zakresie leczenia urazów lub chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - żołnierz",
          kategoria: "weteran poszkodowany - żołnierz",
          podstawa: "art. 57 ust. 2 pkt 13 ustawy",
          status: "A",
        },
        {
          value: 41,
          kod: "57F",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania, w zakresie leczenia urazów lub chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - funkcjonariusz",
          kategoria: "weteran poszkodowany - funkcjonariusz",
          podstawa: "art. 57 ust. 2 pkt 13 ustawy",
          status: "A",
        },
        {
          value: 42,
          kod: "57ABW",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania, w zakresie leczenia urazów lub chorób nabytych podczas wykonywania zadań poza granicami państwa - weteran poszkodowany - funkcjonariusz Agencji Bezpieczeństwa Wewnętrznego",
          kategoria: "weteran poszkodowany - funkcjonariusz Agencji Bezpieczeństwa Wewnętrznego",
          podstawa: "art. 57 ust. 2 pkt 13 ustawy",
          status: "A",
        },
        {
          value: 43,
          kod: "57CP",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - osoba posiadająca zaświadczenie, o którym mowa w art. 47 ust. 1a ustawy",
          kategoria: "osoba posiadająca zaświadczenie, o którym mowa w art. 47 ust. 1a ustawy",
          podstawa: "art. 57 ust. 2 pkt 14 ustawy",
          status: "A",
        },
        {
          value: 44,
          kod: "57ZN",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności",
          kategoria: "świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności",
          podstawa: "art. 57 ust. 2 pkt 14 ustawy",
          status: "A",
        },
        {
          value: 45,
          kod: "31D",
          opis: "dodatkowe świadczenia zdrowotne lekarza dentysty oraz materiały stomatologiczne stosowane przy udzielaniu tych świadczeń, zakwalifikowane jako świadczenia gwarantowane - dzieci i młodzież do ukończenia 18. roku życia",
          kategoria: "dzieci i młodzież do ukończenia 18. roku życia",
          podstawa: "art. 31 ust. 3 ustawy oraz przepisy wydane na podstawie art. 31d ustawy określające świadczenia gwarantowane z zakresu leczenie stomatologicznego",
          status: "A",
        },
        {
          value: 46,
          kod: "31C",
          opis: "dodatkowe świadczenia zdrowotne lekarza dentysty oraz materiały stomatologiczne stosowane przy udzielaniu tych świadczeń, zakwalifikowane jako świadczenia gwarantowane - kobieta w ciąży i w okresie połogu",
          kategoria: "kobieta w ciąży i w okresie połogu",
          podstawa: "art. 31 ust. 3 ustawy oraz przepisy wydane na podstawie art. 31d ustawy określające świadczenia gwarantowane z zakresu leczenie stomatologicznego",
          status: "A",
        },
        {
          value: 47,
          kod: "31N",
          opis: "dodatkowe świadczenia zdrowotne lekarza dentysty oraz materiały stomatologiczne stosowane przy udzielaniu tych świadczeń, zakwalifikowane jako świadczenia gwarantowane - dzieci i młodzież niepełnosprawna w stopniu umiarkowanym i znacznym od ukończenia 16. do ukończenia 18. roku życia",
          kategoria: "dzieci i młodzież niepełnosprawna w stopniu umiarkowanym i znacznym od ukończenia 16. do ukończenia 18. roku życia",
          podstawa: "art. 31 ust. 3 ustawy oraz przepisy wydane na podstawie art. 31d ustawy określające świadczenia gwarantowane z zakresu leczenie stomatologicznego",
          status: "A",
        },
        {
          value: 48,
          kod: "31N18",
          opis: "dodatkowe świadczenia zdrowotne lekarza dentysty oraz materiały stomatologiczne stosowane przy udzielaniu tych świadczeń, zakwalifikowane jako świadczenia gwarantowane - młodzież niepełnosprawna w stopniu umiarkowanym i znacznym, która ukończyła 18. rok życia",
          kategoria: "młodzież niepełnosprawna w stopniu umiarkowanym i znacznym, która ukończyła 18. rok życia",
          podstawa: "art. 31 ust. 3 ustawy oraz przepisy wydane na podstawie art. 31d ustawy określające świadczenia gwarantowane z zakresu leczenie stomatologicznego",
          status: "A",
        },
        {
          value: 49,
          kod: "ZSN",
          opis: "świadczenia z zakresu rehabilitacji leczniczej - świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności",
          kategoria: "świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności",
          podstawa: "art. 3 ustawy z dnia 9 maja 2018 r. o szczególnych rozwiązaniach wspierających osoby o znacznym stopniu niepełnosprawności (Dz. U. poz. 932) w związku z art. 136 ust. 2 pkt 1a ustawy",
          status: "A",
        },
        {
          value: 50,
          kod: "32aDILO",
          opis: "prawo do diagnostyki onkologicznej lub leczenia onkologicznego na podstawie karty diagnostyki i leczenia onkologicznego - osoba, której wystawiono kartę diagnostyki i leczenia onkologicznego",
          kategoria: "osoba, której wystawiono kartę diagnostyki i leczenia onkologicznego",
          podstawa: "art. 32a ustawy",
          status: "A",
        },
        {
          value: 51,
          kod: "ZZC",
          opis: "prawo do świadczeń opieki zdrowotnej, skierowanych do kobiet w ciąży powikłanej oraz dzieci, u których zdiagnozowano ciężkie i nieodwracalne upośledzenie albo nieuleczalną chorobę zagrażającą życiu, które powstały w prenatalnym okresie rozwoju lub w czasie porodu",
          kategoria: "kobieta w ciąży powikłanej",
          podstawa: "art. 7 ust. 1 ustawy z dnia 4 listopada 2016 r. o wsparciu kobiet w ciąży i rodzin 'Za życiem' (Dz. U. poz. 1860 oraz z 2018 r. poz. 1076)",
          status: "A",
        },
        {
          value: 52,
          kod: "ZZDN",
          opis: "prawo do świadczeń opieki zdrowotnej, skierowanych do kobiet w ciąży powikłanej oraz dzieci, u których zdiagnozowano ciężkie i nieodwracalne upośledzenie albo nieuleczalną chorobę zagrażającą życiu, które powstały w prenatalnym okresie rozwoju lub w czasie porodu",
          kategoria: "dziecko, u którego zdiagnozowano ciężkie i nieodwracalne upośledzenie albo nieuleczalną chorobę zagrażającą życiu, które powstały w prenatalnym okresie rozwoju lub w czasie porodu",
          podstawa: "art. 7 ust. 1 ustawy z dnia 4 listopada 2016 r. o wsparciu kobiet w ciąży i rodzin 'Za życiem' (Dz. U. poz. 1860 oraz z 2018 r. poz. 1076)",
          status: "A",
        },
        {
          value: 53,
          kod: "47CZND",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności (do 16 roku życia)",
          kategoria: "świadczeniobiorca posiadający orzeczenie o niepełnosprawności łącznie ze wskazaniami: konieczności stałej lub długotrwałej opieki lub pomocy innej osoby w związku ze znacznie ograniczoną możliwością samodzielnej egzystencji oraz konieczności stałego współudziału na co dzień opiekuna dziecka w procesie jego leczenia, rehabilitacji i edukacji",
          podstawa: "art. 47c ustawy albo art. 23 ust. 1 ustawy z dnia 1 lipca 2005 r. o pobieraniu, przechowywaniu i przeszczepianiu komórek, tkanek i narządów (Dz. U. z 2017 r. poz. 1000)",
          status: "A",
        },
        {
          value: 54,
          kod: "57ZND",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności (do 16 roku życia)",
          kategoria: "świadczeniobiorca posiadający orzeczenie o niepełnosprawności łącznie ze wskazaniami: konieczności stałej lub długotrwałej opieki lub pomocy innej osoby w związku ze znacznie ograniczoną możliwością samodzielnej egzystencji oraz konieczności stałego współudziału na co dzień opiekuna dziecka w procesie jego leczenia, rehabilitacji i edukacji",
          podstawa: "art. 57 ust. 2 pkt 14 ustawy",
          status: "A",
        },
        {
          value: 55,
          kod: "ZSND",
          opis: "świadczenia z zakresu rehabilitacji leczniczej - świadczeniobiorca posiadający orzeczenie o niepełnosprawności łącznie ze wskazaniami: konieczności stałej lub długotrwałej opieki lub pomocy innej osoby w związku ze znacznie ograniczoną możliwością samodzielnej egzystencji oraz konieczności stałego współudziału na co dzień opiekuna dziecka w procesie jego leczenia, rehabilitacji i edukacji",
          kategoria: "świadczeniobiorca posiadający orzeczenie o znacznym stopniu niepełnosprawności (do 16 roku życia)",
          podstawa: "art. 3 ustawy z dnia 9 maja 2018 r. o szczególnych rozwiązaniach wspierających osoby o znacznym stopniu niepełnosprawności (Dz. U. poz. 932) w związku z art. 136 ust. 2 pkt 1a ustawy",
          status: "A",
        },
        {
          value: 56,
          kod: "34M",
          opis: "prawo do objęcia dodatkową opieką pielęgnacyjną przez przedstawiciela ustawowego lub opiekuna faktycznego przez jego pobyt w podmiocie leczniczym wykonującym działalność leczniczą w rodzaju stacjonarne i całodobowe świadczenia zdrowotne bez konieczności ponoszenia kosztów tego pobytu",
          kategoria: "osoba małoletniej",
          podstawa: "art. 34 ust. 3 ustawy w związku z art.. 35 ust. 4 z dnia 6 listopada 2008r. O prawach pacjenta i Rzeczniku Praw Pacjenta (Dz.U. z 2019r. Poz. 1127 z późn.zm.)",
          status: "A",
        },
        {
          value: 57,
          kod: "34N",
          opis: "prawo do objęcia dodatkową opieką pielęgnacyjną przez przedstawiciela ustawowego lub opiekuna faktycznego przez jego pobyt w podmiocie leczniczym wykonującym działalność leczniczą w rodzaju stacjonarne i całodobowe świadczenia zdrowotne bez konieczności ponoszenia kosztów tego pobytu",
          kategoria: "osoba posiadającej orzeczenie o znacznym stopniu niepełnosprawności",
          podstawa: "art. 34 ust. 3 ustawy w związku z art.. 35 ust. 4 z dnia 6 listopada 2008r. O prawach pacjenta i Rzeczniku Praw Pacjenta (Dz.U. z 2019r. Poz. 1127 z późn.zm.)",
          status: "A",
        },
        {
          value: 58,
          kod: "47CPP",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - osoba deportowana do pracy przymusowej",
          kategoria: "osoba deportowana do pracy przymusowej",
          podstawa: "art..47c [Uprawnienia kombatanckie]",
          status: "A",
        },
        {
          value: 59,
          kod: "47PP",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania",
          kategoria: "osoba deportowana do pracy przymusowej",
          podstawa: "art. 57 ust. 2 pkt 10 ustawy",
          status: "N",
        },
        {
          value: 60,
          kod: "57PP",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania",
          kategoria: "osoba deportowana do pracy przymusowej",
          podstawa: "art. 57 ust. 2 pkt 10 ustawy",
          status: "A",
        },
        {
          value: 61,
          kod: "57M",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych psychologicznych, psychoterapeutycznych i środowiskowych bez wymaganego skierowania",
          kategoria: "dzieci i młodzież",
          podstawa: "art. 57 ust. 2 pkt 15 ustawy - prawo dla dzieci i młodzieży do ambulatoryjnych świadczeń specjalistycznych psychologicznych, psychoterapeutycznych i środowiskowych",
          status: "A",
        },
        {
          value: 62,
          kod: "42WF30",
          opis: "świadczenia opieki zdrowotnej, niezakwalifikowane jako świadczenia gwarantowane oraz odpłatne świadczenia opieki zdrowotnej",
          kategoria: "weteran poszkodowany -funkcjonariusz i zwolniony ze służby funkcjonariusz Policji, Straży Granicznej, Biura Ochrony Rządu, Służby Ochrony Państwa, Agencji Bezpieczeństwa Wewnętrznego, Agencji Wywiadu oraz strażak i zwolniony ze służby strażak Państwowej Straży Pożarnej, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          podstawa: "art. 42 ust. 1a ustawy",
          status: "A",
        },
        {
          value: 63,
          kod: "42WŻ30",
          opis: "świadczenia opieki zdrowotnej, niezakwalifikowane jako świadczenia gwarantowane oraz odpłatne świadczenia opieki zdrowotnej",
          kategoria: "weteran poszkodowany - żołnierz, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          podstawa: "art. 42 ust. 1a ustawy",
          status: "A",
        },
        {
          value: 64,
          kod: "47C10",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej- uprawnieni żołnierze lub pracownicy, których ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          kategoria: "uprawnieni żołnierze lub pracownicy, których ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          podstawa: "art. 47c ust.10 ustawy",
          status: "A",
        },
        {
          value: 65,
          kod: "47C11",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej - weterani poszkodowani, których ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          kategoria: "weterani poszkodowani, których ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          podstawa: "art. 47c ust.11 ustawy",
          status: "A",
        },
        {
          value: 66,
          kod: "57ZS",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - żołnierz zastępczej służby wojskowej",
          kategoria: "żołnierz zastępczej służby wojskowej",
          podstawa: "art. 57 ust. 2 pkt 10 ustawy",
          status: "A",
        },
        {
          value: 67,
          kod: "57MON30",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania -uprawniony żołnierz lub pracownik, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          kategoria: "uprawniony żołnierz lub pracownik, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          podstawa: "art. 57 ust. 2 pkt 12 ustawy",
          status: "A",
        },
        {
          value: 68,
          kod: "57Ż30",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - weteran poszkodowany - żołnierz, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          kategoria: "weteran poszkodowany - żołnierz, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          podstawa: "art. 57 ust. 2 pkt 13 ustawy",
          status: "A",
        },
        {
          value: 69,
          kod: "57F30",
          opis: "prawo do ambulatoryjnych świadczeń specjalistycznych bez wymaganego skierowania - weteran poszkodowany - funkcjonariusz i zwolniony ze służby funkcjonariusz Policji, Straży Granicznej, Biura Ochrony Rządu, Służby Ochrony Państwa, Agencji Bezpieczeństwa Wewnętrznego, Agencji Wywiadu oraz strażak i zwolniony ze służby strażak Państwowej Straży Pożarnej, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          kategoria: "weteran poszkodowany - funkcjonariusz i zwolniony ze służby funkcjonariusz Policji, Straży Granicznej, Biura Ochrony Rządu, Służby Ochrony Państwa, Agencji Bezpieczeństwa Wewnętrznego, Agencji Wywiadu oraz strażak i zwolniony ze służby strażak Państwowej Straży Pożarnej, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          podstawa: "art. 57 ust. 2 pkt 13 ustawy",
          status: "A",
        },
        {
          value: 70,
          kod: "472AŻ30",
          opis: "bezpłatne wyroby medyczne- weteran poszkodowany - żołnierz, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          kategoria: "weteran poszkodowany - żołnierz, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          podstawa: "art. 47 ust. 2a ustawy",
          status: "A",
        },
        {
          value: 71,
          kod: "472AF30",
          opis: "bezpłatne wyroby medyczne- weteran poszkodowany - funkcjonariusz i zwolniony ze służby funkcjonariusz Policji, Straży Granicznej, Biura Ochrony Rządu, Służby Ochrony Państwa, Agencji Bezpieczeństwa Wewnętrznego, Agencji Wywiadu oraz strażak i zwolniony ze służby strażak Państwowej Straży Pożarnej, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          kategoria: "weteran poszkodowany - funkcjonariusz i zwolniony ze służby funkcjonariusz Policji, Straży Granicznej, Biura Ochrony Rządu, Służby Ochrony Państwa, Agencji Bezpieczeństwa Wewnętrznego, Agencji Wywiadu oraz strażak i zwolniony ze służby strażak Państwowej Straży Pożarnej, którego ustalony procentowy uszczerbek na zdrowiu wynosi co najmniej 30%",
          podstawa: "art. 47 ust. 2a ustawy",
          status: "A",
        },
        {
          value: 72,
          kod: "47CA",
          opis: "prawo do korzystania poza kolejnością ze świadczeń opieki zdrowotnej w zakresie ustawy leczenia stomatologicznego w gabinecie dentystycznym w szkole oraz w gabinecie dentystycznym prowadzonym przez podmiot wykonujący działalność leczniczą, z którym organ prowadzący szkołę zawarł porozumienie - przez uczniów tej szkoły",
          kategoria: "uczeń",
          podstawa: "47ca ustawy",
          status: "A"
        },
        {
          value: 73,
          kod: "47C13",
          opis: "prawo do korzystania poza kolejnością ze świadczeń w podmiotach leczniczych, dla których podmiotem tworzącym jest Minister Obrony Narodowej, oraz przez niego nadzorowanych - żołnierze zawodowi",
          kategoria: "żołnierze zawodowi, o których mowa w art. 287 ust. 2 i 3 ustawy z dnia 11 marca 2022 r. o obronie Ojczyzny, w przypadku korzystania ze świadczeń w podmiotach leczniczych, dla których podmiotem tworzącym jest Minister Obrony Narodowej, oraz przez niego nadzorowanych",
          podstawa: "art. 47c ust.1 pkt 13 ustawy",
          status: "A"
        },
        {
          value: 74,
          kod: "47C14",
          opis: "prawo do korzystania poza kolejnością ze świadczeń w podmiotach leczniczych, dla których podmiotem tworzącym jest Minister Obrony Narodowej, oraz przez niego nadzorowanych - żołnierze pełniący terytorialną służbę wojskową",
          kategoria: "żołnierze pełniący terytorialną służbę wojskową, o których mowa w art. 325 ust. 2 ustawy z dnia 11 marca 2022 r. o obronie Ojczyzny, w przypadku korzystania ze świadczeń udzielanych w podmiotach leczniczych, dla których podmiotem tworzącym jest Minister Obrony Narodowej, oraz przez niego nadzorowanych",
          podstawa: "art. 47c ust. 1 pkt 14 ustawy",
          status: "A"
        }
      ],

      powiazania_grupa_obszar: [
        {
          grupa_kod: "1",
          obszar_kod: "SWIAD",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "2",
          obszar_kod: "ZPOSP",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "3",
          obszar_kod: "ZPOSP",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          obszar_kod: "SWIAD",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          obszar_kod: "SWIAD",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "6",
          obszar_kod: "SWIAD",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "7",
          obszar_kod: "SWIAD",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "8",
          obszar_kod: "SWIAD",
          data_od: "0001-01-01",
        }
      ],

      powiazania_uprawnienie_grupa: [
        {
          grupa_kod: "1",
          upraw_kod: "42MON",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "1",
          upraw_kod: "42WŻ",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "1",
          upraw_kod: "42WF",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "1",
          upraw_kod: "42WABW",
          data_od: "1999-01-01",
          data_do: "2019-06-30",
        },
        {
          grupa_kod: "1",
          upraw_kod: "42MSW",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "2",
          upraw_kod: "47IB",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "2",
          upraw_kod: "47IW",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "2",
          upraw_kod: "47CN",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "2",
          upraw_kod: "47OR",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "2",
          upraw_kod: "47MON",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "2",
          upraw_kod: "472AŻ",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "2",
          upraw_kod: "472AF",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "2",
          upraw_kod: "472ABW",
          data_od: "1999-01-01",
          data_do: "2019-06-30",
        },
        {
          grupa_kod: "3",
          upraw_kod: "47DN",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "3",
          upraw_kod: "47ZN",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "3",
          upraw_kod: "47ZND",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "24A",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "24BŻ",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "24BF",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "24BABW",
          data_od: "1999-01-01",
          data_do: "2019-06-30",
        },
        {
          grupa_kod: "4",
          upraw_kod: "24CŻ",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "24CF",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "24CABW",
          data_od: "1999-01-01",
          data_do: "2019-06-30",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47CC",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47CDN",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47CIB",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47CIW",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47CK",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47CZN",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47CZND",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47COA",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47CRP",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47CZ",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57IB",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57IW",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57K",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57OR",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57OA",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57RP",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57CN",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57MON",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57Ż",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57F",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57ABW",
          data_od: "1999-01-01",
          data_do: "2019-06-30",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57CP",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57ZN",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57ZND",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "6",
          upraw_kod: "31D",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "6",
          upraw_kod: "31C",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "6",
          upraw_kod: "31N",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "6",
          upraw_kod: "31N18",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "6",
          upraw_kod: "ZSN",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "6",
          upraw_kod: "ZSND",
          data_od: "2018-07-01",
        },
        {
          grupa_kod: "7",
          upraw_kod: "32aDILO",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "8",
          upraw_kod: "ZZDN",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "8",
          upraw_kod: "ZZC",
          data_od: "0001-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57M",
          data_od: "2019-09-01",
        },
        {
          grupa_kod: "1",
          upraw_kod: "42WF30",
          data_od: "2020-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47CPP",
          data_od: "2018-10-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57PP",
          data_od: "2018-10-01",
        },
        {
          grupa_kod: "6",
          upraw_kod: "34M",
          data_od: "2019-07-01",
        },
        {
          grupa_kod: "6",
          upraw_kod: "34N",
          data_od: "2019-07-01",
        },
        {
          grupa_kod: "1",
          upraw_kod: "42WŻ30",
          data_od: "2020-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47C10",
          data_od: "2020-01-01",
        },
        {
          grupa_kod: "4",
          upraw_kod: "47C11",
          data_od: "2020-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57ZS",
          data_od: "2020-01-01",
        },
        {
          grupa_kod: "6",
          upraw_kod: "57MON30",
          data_od: "2020-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57Ż30",
          data_od: "2020-01-01",
        },
        {
          grupa_kod: "5",
          upraw_kod: "57F30",
          data_od: "2020-01-01",
        },
        {
          grupa_kod: "2",
          upraw_kod: "472AŻ30",
          data_od: "2020-01-01",
        },
        {
          grupa_kod: "2",
          upraw_kod: "472AF30",
          data_od: "2020-01-01",
        }
      ],

      powiazania_uprawnienie_dok: [
        {
          dok_kod: "8",
          upraw_kod: "ZSN",
          data_od: "2018-07-01",
        },
        {
          dok_kod: "9",
          upraw_kod: "ZSND",
          data_od: "2018-07-01",
        },
        {
          dok_kod: "11",
          upraw_kod: "42WŻ30",
          data_od: "2020-01-01",
        },
        {
          dok_kod: "11",
          upraw_kod: "42WF30",
          data_od: "2020-01-01",
        },
        {
          dok_kod: "2",
          upraw_kod: "47CN",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "8",
          upraw_kod: "47CZN",
          data_od: "2019-07-01",
        },
        {
          dok_kod: "9",
          upraw_kod: "47CZND",
          data_od: "2019-07-01",
        },
        {
          dok_kod: "11",
          upraw_kod: "47C10",
          data_od: "2020-01-01",
        },
        {
          dok_kod: "11",
          upraw_kod: "47C11",
          data_od: "2020-01-01",
        },
        {
          dok_kod: "7",
          upraw_kod: "47DN",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "1",
          upraw_kod: "47IB",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "1",
          upraw_kod: "47IW",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "4",
          upraw_kod: "47MON",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "3",
          upraw_kod: "47OR",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "8",
          upraw_kod: "47ZN",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "9",
          upraw_kod: "47ZND",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "4",
          upraw_kod: "472AŻ",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "11",
          upraw_kod: "472AŻ30",
          data_od: "2020-01-01",
        },
        {
          dok_kod: "6",
          upraw_kod: "472ABW",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "5",
          upraw_kod: "472AF",
          data_od: "0001-01-01",
        },
        {
          dok_kod: "11",
          upraw_kod: "472AF30",
          data_od: "2020-01-01",
        },
        {
          dok_kod: "11",
          upraw_kod: "57Ż30",
          data_od: "2020-01-01",
        },
        {
          dok_kod: "7",
          upraw_kod: "57CP",
          data_od: "2018-10-01",
        },
        {
          dok_kod: "11",
          upraw_kod: "57F30",
          data_od: "2020-01-01",
        },
        {
          dok_kod: "8",
          upraw_kod: "57ZN",
          data_od: "2019-07-01",
        },
        {
          dok_kod: "9",
          upraw_kod: "57ZND",
          data_od: "2019-07-01",
        }
      ],

    }
  },

  methods: {
    mGetGroupByCode(code) {
      let groupConnect = this.powiazania_uprawnienie_grupa.find((obj) => obj.upraw_kod === code)
      if (groupConnect) {

        let group = this.grupy_upr_poz.find((obj) => obj.kod === groupConnect.grupa_kod)
        if (group) {
          return group.opis ? group.opis : "Brak informacji"
        }
      }

      return "Brak informacji"
    },

    mGetGroupCodeByCodeUpraw(code) {
      let groupConnect = this.powiazania_uprawnienie_grupa.find((obj) => obj.upraw_kod === code)
      if (groupConnect) {

        let group = this.grupy_upr_poz.find((obj) => obj.kod === groupConnect.grupa_kod)
        if (group) {
          return group.kod ? group.kod : "-1"
        }
      }

      return "-1"
    },

    mGetDocumentByCode(code) {
      let groupConnect = this.powiazania_uprawnienie_dok.find((obj) => obj.upraw_kod === code)
      if (groupConnect) {
        let document = this.typy_dok_poz.find((obj) => obj.kod === groupConnect.dok_kod)
        if (document) {
          return document
        }
      }

      return null
    },

    mGetDocumentNameByCode(code) {
      let groupConnect = this.powiazania_uprawnienie_dok.find((obj) => obj.upraw_kod === code)
      if (groupConnect) {
        let document = this.typy_dok_poz.find((obj) => obj.kod === groupConnect.dok_kod)
        if (document) {
          return document.opis ? document.opis : "Brak informacji"
        }
      }

      return "Brak informacji"
    },

    mGetDocumentDescription(value) {
      let document = this.mPoz.find(obj => obj.value === value)
      if (document) {
        return document.opis ? document.opis : "Brak informacji"
      }

      return "Brak informacji"
    },

    mGetDocumentCode(value) {
      let document = this.mPoz.find(obj => obj.value === value)
      if (document) {
        return document.kod ? document.kod : "Brak informacji"
      }

      return "Brak informacji"
    },

    mGetNfzDepartment(value) {
      let nfzDepartment = this.nfz.values.find(obj => obj.value === value)
      if (nfzDepartment) {
        return nfzDepartment.text ? nfzDepartment.text : "Brak informacji"
      }

      return "Brak informacji"
    },

    mGetNfzDepartmentForValueText(value) {
      let nfzDepartment = this.nfz.values.find(obj => obj.valueText === value)
      if (nfzDepartment) {
        return nfzDepartment.text ? nfzDepartment.text : "Brak informacji"
      }

      return "Brak informacji"
    }
  }

}