import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
// import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {},

    mutations: {},

    actions: {

        // *********************** ARCHIVE SEARCH ***********************

        [Action.TPK_ARCHIVE_SEARCH](context, pesel) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TPK_ARCHIVE_SEARCH.replace('{id}', pesel),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        // *********************** RESULT ***********************

        [Action.TPK_EDM_RESULT_SEND](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TPK_EDM_RESULT_SEND,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        }

    }
}