export default {
  data: function () {
    return {
      /**Dane pacjenta
       * Patient's data
       */
      mPatientEmpty: {
        "domainId": -1,
        "firstName": '',
        "lastName": '',
        "pesel": '',
        "secondName": '',
        "familyName": '',
        "dateOfBirth": null,
        "gender": 'M',
        "identityDocument": 0,
        "identityDocumentNumber": '',
        "nationalities": [],
        "citizenship": '',
        "citizenshipSubdivision": '',
        "ekuzCard": '',
        "teleAddressStreet": '',
        "teleAddressHouseNumber": '',
        "teleAddressApartmentNumber": '',
        "teleAddressCity": '',
        "teleAddressZipCode": '',
        "teleAddressCountry": '',

        "addressStreet": '',
        "addressHouseNumber": '',
        "addressApartmentNumber": '',
        "addressCity": '',
        "addressZipCode": '',
        "addressCountry": '',

        "teleAddressTelephoneNumber": '',
        "teleAddressTelephonePrefix": '',
        "phoneNumberOwner": "pacjent",

        "email": '',
        "preferredContact": -1,
        "hasPortalAccount": false,
        "familyInfoId": '',
        "notes": [],
        "medicalDataAuthorizations": [],

        "complience": 0,
        "experience": 0,
        "isPregnant": false,
        "isHomeless": false,
        "isDifficult": false,
        "isUnhappy": false,
        "isHardOfHearing": false,
        "hearingInfo": "",
        "isDisabled": false,
        "disabilityInfo": "",
        "isQuarantined": false,
        "isDebtor": false,
        "statusUpdated": "0001-01-01T16:56:07.643Z",
        "isContractor": false,
        "contractorId": -1,
        "contractorNIP": "",
        "insuranceNfz": -1,
        "insuranceBasis": -1,
        "insuranceTypeOfInsurance": -1,
        "insuranceInsuranceNumber": "",
        "insuranceInsuranceDuplicateNumber": "",
        "insuranceInsuranceFund": -1,
        "insuranceSocialStatus": -1,
        "insuranceEntitlements": [],
        "insuranceAdditionalEntitlements": [],
        "insuranceLevelOfDisability": -1,
        "insuranceInsuredIn": -1,
        "insuranceDocumentPL": -1,
        "insuranceDocumentEU": -1,
        "insuranceDocumentName": "",
        "insuranceDocumentNumber": "",
        "insuranceDocumentIssuedBy": "",
        "insuranceCountry": -1,
        "insuranceBasis2": -1,
        "insuranceBasis3": -1,
        "insuranceOtherBasis": "",
        "insuranceDocumentIssuedDate": "0001-01-01T16:56:07.643Z",
        "insuranceDocumentValidFrom": "0001-01-01T16:56:07.643Z",
        "insuranceDocumentValidUntil": "0001-01-01T16:56:07.643Z",
        "version": -1
      },

      /**Dane pacjenta
       * Patient's data
       */
      patientResource: {
        "firstName": '',
        "lastName": '',
        "pesel": '',
        "secondName": '',
        "familyName": '',
        "dateOfBirth": null,
        "gender": 'u',
        "identityDocument": -1,
        "identityDocumentNumber": '',
        "teleAddressStreet": '',
        "teleAddressHouseNumber": '',
        "teleAddressApartmentNumber": '',
        "teleAddressCity": '',
        "teleAddressZipCode": '',
        "teleAddressCountry": '',
        "teleAddressTelephoneNumber": '',
        "email": '',
        "preferredContact": -1,
        "hasPortalAccount": false,
        "familyInfoId": '',
        "notes": [],
        "medicalDataAuthorizations": [],
        "complience": 0,
        "isPregnant": false,
        "isContractor": false,
        "contractorId": -1,
        "domainId": -1,
        "insuranceNfz": null,
        "insuranceBasis": null,
        "insuranceTypeOfInsurance": null,
        "insuranceInsuranceNumber": null,
        "insuranceInsuranceDuplicateNumber": null,
        "insuranceInsuranceFund": null,
        "insuranceSocialStatus": null,
        "insuranceEntitlements": [],
        "insuranceAdditionalEntitlements": [],
        "insuranceLevelOfDisability": null,
        "insuranceInsuredIn": null,
        "insuranceDocumentPL": null,
        "insuranceDocumentEU": null,
        "insuranceDocumentName": null,
        "insuranceDocumentNumber": null,
        "insuranceDocumentIssuedBy": null,
        "insuranceCountry": null,
        "insuranceBasis2": null,
        "insuranceBasis3": null,
        "insuranceOtherBasis": null,
        "insuranceDocumentIssuedDate": null,
        "insuranceDocumentValidFrom": null,
        "insuranceDocumentValidUntil": null
      }
    }
  }
}