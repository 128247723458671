import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'
import moment from 'moment'

export default {
    namespaced: false,

    state: {
        myMobiAccount: null,
        myPlaceMobiAccount: null,
        mobiAccounts: [],
        mobiRoutes: [],
        mobiParcels: [],
        mobiVehicles: [],
        employeeAccount: null,
    },

    mutations: {
        [Mutation.MOBI_SET_PARCELS](state, payload) {
            state.mobiParcels = payload
        },
        [Mutation.MOBI_ADD_PARCEL](state, payload) {
            state.mobiParcels.push(payload.parcel)
        },
        [Mutation.MOBI_ADD_PARCELS](state, payload) {
            payload.forEach(p => {
                state.mobiParcels.splice(0, 0, p.parcel)
            })
        },
        [Mutation.MOBI_MODIFY_PARCEL](state, payload) {
            let parcel = state.mobiParcels.find(u => u.id === payload.parcel.id)

            if (parcel) {
                parcel.statusId = payload.status.status
            }
        },
        [Mutation.MOBI_MODIFY_PARCEL_DIRECT](state, payload) {
            let parcel = state.mobiParcels.find(u => u.id === payload.id)

            if (parcel) {
                parcel.statusId = payload.statusId
            }
        },

        [Mutation.MOBI_SET_MY_ACCOUNT](state, payload) {
            state.myMobiAccount = payload
        },

        [Mutation.MOBI_SET_ACCOUNTS](state, payload) {

            payload.forEach(p => {
                //p.searchName = p.fullName + ", " + p.street + " " + p.buildingNumber + " " + p.zip + " " + p.city
                p.searchName = `${p.fullName}${p.parcelUserType == 2 ? " [" + p.short + "]" : ""}, ${p.street} ${p.buildingNumber}${p.apartmentNumber ? "/" + p.apartmentNumber : ""}, ${p.zip} ${p.city}`
            })

            state.mobiAccounts = payload.sort((a, b) => {
                return a.parcelUserType == 2 && b.parcelUserType != 2 ? -1 : 1;
            });
        },
        [Mutation.MOBI_SET_ACCOUNT](state, payload) {
            state.mobiAccounts.push(payload)
        },

        [Mutation.MOBI_SET_EMPLOYEE_ACCOUNT](state, payload) {
            state.employeeAccount = payload
        },

        [Mutation.MOBI_ADD_VEHICLE](state, payload) {
            state.mobiVehicles.push(payload)
        },
        [Mutation.MOBI_REMOVE_VEHICLE](state, payload) {
            let index = state.mobiVehicles.findIndex(u => u.id === payload)

            if (index > -1) {
                state.mobiVehicles.splice(index, 1)
            }
        },
        [Mutation.MOBI_SET_VEHICLES](state, payload) {
            state.mobiVehicles = payload
        },
        [Mutation.MOBI_EDIT_VEHICLE](state, payload) {
            let vehicle = state.mobiVehicles.find(u => u.id === payload.id)

            if (vehicle) {
                vehicle = payload
            }
        },



        [Mutation.MOBI_ADD_ROUTE](state, payload) {
            state.mobiRoutes.push(payload)
        },
        [Mutation.MOBI_REMOVE_ROUTE](state, payload) {
            let index = state.mobiRoutes.findIndex(u => u.id === payload)

            if (index > -1) {
                state.mobiRoutes.splice(index, 1)
            }
        },
        [Mutation.MOBI_SET_ALL_ROUTES](state, payload) {
            state.mobiRoutes = payload
        },
        [Mutation.MOBI_EDIT_ROUTE](state, payload) {
            let vehicle = state.mobiRoutes.find(u => u.id === payload.id)

            if (vehicle) {
                vehicle = payload
            }
        },


        [Mutation.MOBI_CHANGE_PLACE](state, payload) {

            let place = state.mobiAccounts.find(a => a.id === payload)

            if (place) {
                state.myPlaceMobiAccount = place
            }
            else {
                state.myPlaceMobiAccount = null
            }
        },



        [Mutation.MOBI_RESET](state) {
            state.myMobiAccount = null
            state.mobiAccounts = []
            state.mobiParcels = []
            state.mobiVehicles = []
            state.mobiRoutes = []
        },
    },

    actions: {



        [Action.MOBI2_GET_PARCELS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI2_GET_PARCELS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_PARCELS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI2_FILTER_PARCELS](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_FILTER_PARCELS,
                        data: data,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_SET_PARCELS, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_COUNT_PARCELS](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_COUNT_PARCELS,
                        data: data,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_FILTER_PARCELS_BY_CONTENT](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_FILTER_PARCELS_BY_CONTENT,
                        data: data,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_SET_PARCELS, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.MOBI2_ADD_PARCEL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_ADD_PARCEL,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_ADD_PARCEL, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_ADD_PARCEL_GROUP](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_ADD_PARCEL_GROUP,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_ADD_PARCELS, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_ADD_FLAG](context, bundle) {


            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MOBI2_ADD_FLAG.replace('{id}', bundle.id),
                        data: bundle,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_MODIFY_PARCEL_DIRECT, response.data)
                        resolve(response);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_CHANGE_STATUS](context, bundle) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MOBI2_MODIFY_STATUS.replace('{id}', bundle.parcelId),
                        data: bundle.status,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_MODIFY_PARCEL, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI2_GET_PARCEL_BY_CODE](context, code) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI2_GET_PARCEL_BY_CODE.replace('{code}', code),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI2_GET_PARCELS_FOR_USER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI2_GET_PARCELS_FOR_USER.replace('{id}', payload.account).replace('{workerId}', payload.worker),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_PARCELS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },





        // ADDRESS GROUPS *****************************************

        [Action.MOBI2_GET_GROUP](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI2_GET_GROUP_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI2_GET_GROUPS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI2_GET_GROUPS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_ADD_GROUP](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_ADD_GROUP,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_DELETE_GROUP](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MOBI2_REMOVE_GROUP.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI2_MODIFY_GROUP](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MOBI2_EDIT_GROUP.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.MOBI2_GET_MY_ACCOUNT](context) {
            return new Promise((resolve, reject) => {
                let myId = context.rootState.identity.session.email
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI2_GET_MY_ACCOUNT.replace('{id}', myId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_MY_ACCOUNT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI2_GET_ACCOUNT_BY_EXTERNAL](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI2_GET_MY_ACCOUNT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI2_GET_ACCOUNTS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI2_GET_USERS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_ACCOUNTS, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_ADD_NEW_ACCOUNT](context, account) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                account.created = moment().format('YYYY-MM-DDTHH:mm:ss')
                account.parcelUserType = 1
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_ADD_USER,
                        data: account,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_SET_ACCOUNT, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_ADD_NEW_FACILITY_ACCOUNT](context, account) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                account.created = moment().format('YYYY-MM-DDTHH:mm:ss')
                account.parcelUserType = 2
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_ADD_USER,
                        data: account,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        // context.commit(Mutation.MOBI_SET_ACCOUNT, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_ADD_MY_ACCOUNT](context, account) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                account.created = moment().format('YYYY-MM-DDTHH:mm:ss')
                account.externalId = context.rootState.identity.session.email
                account.parcelUserType = 1
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_ADD_USER,
                        data: account,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_SET_ACCOUNT, response.data)
                        context.commit(Mutation.MOBI_SET_MY_ACCOUNT, response.data)
                        resolve();
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_ADD_ACCOUNT](context, account) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                account.created = moment().format('YYYY-MM-DDTHH:mm:ss')
                account.externalId = moment.utc().add(1, 'hours').format('YYMMDDHHmmssSSS')
                //account.parcelUserType = 10
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_ADD_USER,
                        data: account,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.dispatch(Action.MOBI2_GET_ACCOUNTS)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_EDIT_ACCOUNT](context, account) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MOBI2_EDIT_USER.replace('{id}', account.id),
                        data: account,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.dispatch(Action.MOBI2_GET_ACCOUNTS)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_DELETE_ACCOUNT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MOBI2_DELETE_USER.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.dispatch(Action.MOBI2_GET_ACCOUNTS)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // VEHICLES 

        [Action.MOBI2_GET_VEHICLE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI2_GET_VEHICLE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI2_GET_VEHICLES](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI2_GET_VEHICLES,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_VEHICLES, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI2_ADD_VEHICLE](context, vehicle) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI2_ADD_VEHICLE,
                        data: vehicle,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_ADD_VEHICLE, response.data)
                        resolve();
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.MOBI2_DELETE_VEHICLE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MOBI2_DELETE_VEHICLE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_REMOVE_VEHICLE, id)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.MOBI2_MODIFY_VEHICLE](context, vehicle) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MOBI2_MODIFY_VEHICLE.replace('{id}', vehicle.id),
                        data: vehicle,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },





























        // STAROCIE
        // *********************************************


        [Action.MOBI_GET_MY_ACCOUNT](context) {
            return new Promise((resolve, reject) => {
                let myId = context.rootState.identity.session.email
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_MY_ACCOUNT.replace('{id}', myId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_MY_ACCOUNT, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI_GET_ACCOUNT_BY_EXTERNAL](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_MY_ACCOUNT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI_GET_ACCOUNTS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_USERS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_ACCOUNTS, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_ADD_NEW_ACCOUNT](context, account) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                account.created = moment().format('YYYY-MM-DDTHH:mm:ss')
                account.parcelUserType = 1
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_ADD_USER,
                        data: account,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_SET_ACCOUNT, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_ADD_NEW_FACILITY_ACCOUNT](context, account) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                account.created = moment().format('YYYY-MM-DDTHH:mm:ss')
                account.parcelUserType = 2
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_ADD_USER,
                        data: account,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        // context.commit(Mutation.MOBI_SET_ACCOUNT, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_ADD_MY_ACCOUNT](context, account) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                account.created = moment().format('YYYY-MM-DDTHH:mm:ss')
                account.externalId = context.rootState.identity.session.email
                account.parcelUserType = 1
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_ADD_USER,
                        data: account,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_SET_ACCOUNT, response.data)
                        context.commit(Mutation.MOBI_SET_MY_ACCOUNT, response.data)
                        resolve();
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_ADD_ACCOUNT](context, account) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                account.created = moment().format('YYYY-MM-DDTHH:mm:ss')
                account.externalId = moment.utc().add(1, 'hours').format('YYMMDDHHmmssSSS')
                //account.parcelUserType = 10
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_ADD_USER,
                        data: account,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.dispatch(Action.MOBI_GET_ACCOUNTS)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_EDIT_ACCOUNT](context, account) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MOBI_EDIT_USER.replace('{id}', account.id),
                        data: account,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.dispatch(Action.MOBI_GET_ACCOUNTS)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_DELETE_ACCOUNT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MOBI_DELETE_USER.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.dispatch(Action.MOBI_GET_ACCOUNTS)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },



        [Action.MOBI_GET_PARCELS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_PARCELS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_PARCELS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.MOBI_FILTER_PARCELS](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_FILTER_PARCELS,
                        data: data,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_SET_PARCELS, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_FILTER_PARCELS_BY_CONTENT](context, data) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_FILTER_PARCELS_BY_CONTENT,
                        data: data,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_SET_PARCELS, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.MOBI_ADD_PARCEL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_ADD_PARCEL,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_ADD_PARCEL, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_ADD_PARCEL_GROUP](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_ADD_PARCEL_GROUP,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_ADD_PARCELS, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.MOBI_CHANGE_STATUS](context, bundle) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MOBI_MODIFY_STATUS.replace('{id}', bundle.parcelId),
                        data: bundle.status,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_MODIFY_PARCEL, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI_GET_PARCEL_BY_CODE](context, code) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_PARCEL_BY_CODE.replace('{code}', code),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI_GET_PARCELS_FOR_USER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_PARCELS_FOR_USER.replace('{id}', payload.account).replace('{workerId}', payload.worker),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_PARCELS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // VEHICLES *****************************************

        [Action.MOBI_GET_VEHICLE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_VEHICLE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI_GET_VEHICLES](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_VEHICLES,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_VEHICLES, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_ADD_VEHICLE](context, vehicle) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_ADD_VEHICLE,
                        data: vehicle,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_ADD_VEHICLE, response.data)
                        resolve();
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.MOBI_DELETE_VEHICLE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MOBI_DELETE_VEHICLE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_REMOVE_VEHICLE, id)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.MOBI_MODIFY_VEHICLE](context, vehicle) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MOBI_MODIFY_VEHICLE.replace('{id}', vehicle.id),
                        data: vehicle,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },






        // ROUTES *****************************************

        [Action.MOBI_GET_ROUTE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_ROUTE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI_GET_ALL_ROUTES](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_ROUTES,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_SET_ALL_ROUTES, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_CREATE_ROUTE](context, route) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_ADD_ROUTE,
                        data: route,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.MOBI_ADD_ROUTE, response.data)
                        resolve();
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /** Attempt worker delete. Uses DELETE request */
        [Action.MOBI_DELETE_ROUTE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MOBI_REMOVE_ROUTE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_REMOVE_ROUTE, id)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt worker edit. Uses PUT request with id and requires worker data object (check config_apis.js)*/
        [Action.MOBI_EDIT_ROUTE](context, route) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MOBI_EDIT_ROUTE.replace('{id}', route.id),
                        data: route,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MOBI_EDIT_ROUTE, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        // ADDRESS GROUPS *****************************************

        [Action.MOBI_GET_GROUP](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_GROUP_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI_GET_GROUPS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MOBI_GET_GROUPS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_ADD_GROUP](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.MOBI_ADD_GROUP,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.MOBI_DELETE_GROUP](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.MOBI_REMOVE_GROUP.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.MOBI_MODIFY_GROUP](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.MOBI_EDIT_GROUP.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

    }
}