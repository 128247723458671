<template>
  <div>
    <div class="modal-card" style="width: auto;min-width: 48rem;">
      <header class="modal-card-head has-background-kin p-3 pl-4">
        <p class="modal-card-title has-text-light pr-2 is-size-5">
          <b-icon icon="calendar" class="mb-0 mr-3 ml-1" size="is-small"></b-icon>
          Szczegóły wizyty
          <b-tag class="is-pulled-right" rounded type="is-kin">#{{block.appointment.id}}</b-tag>
        </p>
        <b-button outlined type="is-primelight" icon-left="close" @click="$emit('close')" size="is-small">Zamknij</b-button>
      </header>
      <section class="modal-card-body pl-5 pr-5 pt-3 pb-2 has-text-dark" v-if="appointment && !isVehicle">
        <b-notification
          animation="none"
          type="is-primary"
          class="mb-3"
          v-if="block.appointment.isGroup && !isHistory"
        >
          Wizyta grupowa
          <b-button
            size="is-small"
            v-if="registrationPatient && block.appointment.groupPatients.includes(registrationPatient.id)"
            class="is-pulled-right"
            disabled
            icon-left="check"
            rounded
          >Pacjent zapisany</b-button>
          <b-button
            size="is-small"
            @click="addToGroup"
            v-else-if="registrationPatient"
            class="is-pulled-right"
            :loading="loading"
            icon-left="account-plus"
            rounded
          >Dopisz pacjenta do wizyty</b-button>
        </b-notification>
        <b-notification
          type="is-kin"
          animation="none"
          class="mb-3"
          v-if="block.appointment.state == 8"
        >
          <b>Wizyta nie odbyła się.</b> Termin wizyty minął.
          <b-button
            class="button is-pulled-right"
            size="is-small"
            rounded
            icon-left="swap-horizontal-bold"
            @click="reschedule"
            :loading="requestInProgress"
            :disabled="
            (block.appointment.state > 4 && block.appointment.state != 8) ||
            (block.appointment.state > 0 && isHistory && block.appointment.state != 8) ||
            editmode ||
            focusBlock
          "
          >Przełóż wizytę</b-button>
        </b-notification>
        <b-notification
          type="is-danger"
          animation="none"
          class="mb-3"
          v-else-if="block.appointment.state > 4"
        >Wizyta się już odbyła.</b-notification>
        <b-notification animation="none" type="is-danger" class="mb-3" v-else-if="isHistory">
          Wizyta historyczna.
          <span v-if="block.appointment.state == 1">
            <br />Wizyta rozpoczęta, lecz niezakończona. Przełożenie niedostępne.
          </span>
        </b-notification>
        <b-notification
          animation="none"
          type="is-secondary"
          class="mb-3"
          v-if="isHistory && block.appointment.state == 0"
        >
          Wizyta nie została rozpoczęta.
          <b-button
            class="button is-pulled-right"
            size="is-small"
            rounded
            icon-left="swap-horizontal-bold"
            @click="reschedule"
            :loading="requestInProgress"
            :disabled="
            (block.appointment.state > 4 && block.appointment.state != 8) ||
            (block.appointment.state > 0 && isHistory && block.appointment.state != 8) ||
            editmode ||
            focusBlock
          "
          >Przełóż wizytę</b-button>
        </b-notification>
        <div class="columns is-variable is-0 mb-0">
            <div class="column is-6 pr-4">
              <p
                v-if="!block.appointment.isTimeless"
                class="has-text-weight-medium has-text-primary mb-0"
              >Termin</p>
              <p v-if="!block.appointment.isTimeless" class="subtitle m-0">
                <b-icon icon="calendar" size="is-small" class="mb-1 mr-2"></b-icon>
                {{ mDate(block.appointment.start) }}
              </p>
              <p v-if="!block.appointment.isTimeless" class="subtitle mb-3">
                <b-icon icon="clock" size="is-small" class="mb-1 mr-2"></b-icon>
                {{ mTime(block.appointment.start) }}
                <span>- {{ mTime(block.appointment.stop) }}</span>
              </p>
              <b-notification
                type="is-danger"
                v-if="editmode && minDurationEditedVisit>5 && duration<minDurationEditedVisit"
              >
                <span>Uwaga! Wizyta nie może trwać krócej niż {{minDurationEditedVisit}} minut.</span>
              </b-notification>
              <b-notification
                type="is-warning"
                v-if="editmode && minDurationEditedVisitInfo>5 && duration<minDurationEditedVisitInfo"
              >
                <span>Uwaga! Czas wizyty jest niższy niż {{minDurationEditedVisitInfo}} minut.</span>
              </b-notification>
              <b-field
                v-if="editmode"
                :label="`Czas trwania (max ${maxDurationEditedVisit} minut)`"
                label-position="on-border"
              >
                <b-numberinput
                  v-model="duration"
                  :min="minDurationEditedVisit"
                  :min-step="5"
                  :step="5"
                  :max="maxDurationEditedVisit"
                  controls-position="compact"
                  size="is-small"
                  expanded
                ></b-numberinput>
              </b-field>
              <p class="has-text-weight-medium has-text-primary mb-0">Sposób wykonania</p>
              <b-select v-if="editmode" expanded class="mb-3" v-model="appCopy.service">
                <option
                  v-for="wayItem in getAppWays(appCopy.type)"
                  :key="wayItem.id"
                  :value="wayItem.id"
                >{{wayItem.name}}</option>
                <!-- <option :value="0">Wizyta w przychodni</option>
                <option :value="1">Teleporada</option>
                <option :value="2">Wizyta domowa</option>-->
              </b-select>
              <p v-else class="subtitle mb-4">
                <b-icon :icon="mGetServiceIcon(block.appointment.service)" size="is-small" class="mb-1 mr-2"></b-icon>
                {{ getServiceTypeLabel(block.appointment.service) }}
              </p>

              <p class="has-text-weight-medium has-text-primary mb-0">Typ</p>
              <div v-if="editmode">
                <b-field expanded label="Typ wizyty" class="mt-3" label-position="on-border">
                  <b-select
                    expanded
                    placeholder="Brak typów wizyt dla wybranego pracownika"
                    @input="onTypeChanged"
                    v-model="appointmentObjectTmp"
                  >
                    <option v-for="item in filteredAppTypes" :key="item.id" :value="item.id">
                      {{ item.name }}
                      <span v-if="item.apkolce">[AP-KOLCE]</span>
                    </option>
                  </b-select>
                  <p
                    class="control"
                    v-if="
                    (block.worktimeArea && block.worktimeArea.officeId != null) ||
                    (block.worktimeArea === null && block.appointment.officeId)
                  "
                  >
                    <b-checkbox-button :native-value="true" v-model="showAllAppTypes">
                      <div v-if="showAllAppTypes">
                        <b-icon icon="account-circle"></b-icon>
                      </div>
                      <div v-else>
                        <b-icon icon="home"></b-icon>
                      </div>
                    </b-checkbox-button>
                  </p>
                </b-field>
                <b-field
                  v-if="subcategories && subcategories.length > 0"
                  label="Podtyp"
                  label-position="on-border"
                >
                  <b-select
                    placeholder="Wybierz"
                    v-model="appCopy.subtype"
                    @input="onSubTypeChanged"
                    expanded
                  >
                    <option
                      v-for="item in subcategories"
                      :key="item.id"
                      :value="item.id"
                    >{{ item.name }}</option>
                  </b-select>
                </b-field>
              </div>
              <p v-else class="subtitle mb-4">
                <b-icon :icon="getAppIcon(block.appointment.type)" size="is-small" class="mb-1 mr-2"></b-icon>
                {{ getAppName(block.appointment.type) }}
                <span v-if="block.appointment.subtype > -1">
                  <br />
                  <b-icon :icon="getAppIcon(block.appointment.type)" size="is-small" class="mb-1 mr-2"></b-icon>
                  {{ getAppSubCategory( block.appointment.type, block.appointment.subtype) }}
                </span>
              </p>

              <div v-if="isRISOffice" class="mb-4">
                <div v-if="block.appointment.ticketInfo" class="mb-4">
                  <p class="has-text-weight-medium has-text-primary mb-0">Usługa</p>
                  <p v-if="block.appointment.ticketInfo.ikz" class="subtitle">
                    <span
                      v-for="item in block.appointment.ticketInfo.ikz"
                      :key="item.id"
                    >{{item.name}}</span>
                  </p>
                </div>

                <!-- <div v-if="block.appointment.procedure" class="mb-2">
                  <p class="has-text-weight-medium has-text-primary mb-0">Badanie</p>
                  <p class="subtitle">
                    {{block.appointment.procedure.description}}
                    <span
                      v-if="block.appointment.procedure.bodySide"
                    >, strona ciała: {{block.appointment.procedure.bodySide}}</span>
                  </p>
                </div> -->

                <div v-if="block.appointment.ticketInfo" class="mb-2">
                  <p class="has-text-weight-medium has-text-primary mb-0">Badania</p>
                  <!-- <p class="subtitle">
                    {{block.appointment.procedure.description}}
                    <span
                      v-if="block.appointment.procedure.bodySide"
                    >, strona ciała: {{block.appointment.procedure.bodySide}}</span>
                  </p> -->
                  <p class="subtitle" v-if="block.appointment.ticketInfo && block.appointment.ticketInfo.procedures && block.appointment.ticketInfo.procedures.length >0">                
                    <ul>
                    <li v-for="(item, index) in block.appointment.ticketInfo.procedures" :key="index"><span>{{item.description}}</span>
                      <span v-if="item.bodySide">
                        , strona ciała:
                        <span class="has-text-weight-bold">
                          {{ item.bodySide }}
                        </span>
                      </span>
                      <span
                        v-if="
                          item.icd9 != null &&
                          item.icd9 != ''
                        "
                        > ({{ item.icd9 }})</span
                      >
                      <span v-if="(index+1)<block.appointment.ticketInfo.length">, </span>
                      <div style="font-size: 0.9rem;" v-if="item.params && item.params.length>0">
                        Projekcje:
                        <span v-for="x in item.params" :key="x.index">{{mGetProjectionName(x)}}, </span>
                      </div>
                    </li>
                    </ul>
                  </p>
                  <p v-else>-</p> 
                </div>
              </div>

              <div
                v-if="(block.appointment.type === 1000 || 
                block.appointment.type === 1012 || 
                block.appointment.type === 1023 || 
                block.appointment.type === 10000 || 
                block.appointment.type === 10001) && block.appointment.ticketInfo && block.appointment.ticketInfo.referral" class="mb-4"
              >
                  <p class="has-text-weight-medium has-text-primary mb-0">Numer karty DILO</p>
                  <p class="subtitle">
                    {{block.appointment.ticketInfo.referral.diloNumber}}
                  </p>
              </div>

              <p class="has-text-weight-medium has-text-primary mb-0">Płatnik</p>
              <div v-if="editmode">
                <b-select expanded class="mb-3" v-model="appCopy.payerType">
                  <option
                    v-for="payerItem in getAppPayers(appCopy.type)"
                    :key="payerItem.id"
                    :value="payerItem.id"
                  >{{payerItem.name}}</option>
                </b-select>

                <b-switch
                  expanded
                  type="is-success"
                  v-if="appCopy.isPrivate"
                  v-model="appCopy.isPaid"
                >Opłacona</b-switch>

                <b-field
                  expanded
                  v-if="appCopy.isPaid"
                  class="mt-3"
                  label="Identyfikator płatności"
                  label-position="on-border"
                >
                  <b-input
                    expanded
                    v-model="appCopy.paymentId"
                    maxlength="128"
                    :has-counter="false"
                  ></b-input>
                </b-field>
              </div>
              <p class="subtitle mb-4" v-else>{{ getAppointmentPayerType(block.appointment.payerType) }}</p>

              <p class="has-text-weight-medium has-text-primary mb-0">Powód wizyty</p>
              <b-field v-if="editmode">
                <b-input v-model="appCopy.reason" placeholder="Powód wizyty"></b-input>
              </b-field>
              <p
                class="subtitle mb-4"
                v-else-if="block.appointment.reason"
              >{{ block.appointment.reason }}</p>
              <p v-else class="subtitle mb-4">Brak</p>
              <p class="has-text-weight-medium has-text-primary mb-0">Dodatkowe uwagi</p>
              <b-field v-if="editmode">
                <b-input v-model="appCopy.description" placeholder="Dodatkowe uwagi"></b-input>
              </b-field>
              <p
                class="subtitle mb-0"
                v-else-if="block.appointment.description"
              >{{ block.appointment.description }}</p>
              <p v-else class="subtitle mb-0">Brak</p>
            </div>
            <div class="column is-6 pl-1">
              <p v-if="block.appointment.state == 1" class="subtitle has-text-danger">
                <b-icon icon="exclamation-thick" size="is-small" class="mb-1 mr-2"></b-icon>Wizyta w trakcie
              </p>
              <div v-if="block.appointment.isGroup && block.appointment.groupPatients != null">
                <p class="has-text-weight-medium has-text-primary mb-0">Pacjenci</p>
                <div class="content">
                  <ul class="mb-2 mt-2">
                    <li v-for="item in groupPatients" :key="item.index">
                      {{ item.lastName }} {{ item.firstName }}
                      {{ item.pesel ? `, PESEL: ${item.pesel}` : '' }}
                      {{ item.identityDocument === 0 && item.identityDocumentNumber ? `, dowód osobisty: ${item.identityDocumentNumber}` : ''}}
                      {{ item.identityDocument === 1 && item.identityDocumentNumber ? `, paszport: ${item.identityDocumentNumber}` : ''}}
                      <a
                        class="is-size-7 has-text-danger"
                        @click="removePatientFromGroup(item)"
                      >Usuń</a>
                    </li>
                    <li v-if="block.appointment.groupPatients.length < 1">Brak</li>
                  </ul>
                </div>
              </div>
              <div v-else-if="patient" class="pb-4">
                <table class="w-100">
                  <tr>
                    <td class="has-text-weight-medium has-text-primary">Pacjent</td>
                    <td class="title is-5 pb-0 has-text-right has-text-primary">
                      {{ patient.lastName }} {{ patient.firstName }}
                    </td>
                  </tr>
                  <tr v-if="patient.pesel">
                    <td>PESEL</td>
                    <td class="pl-3 has-text-right">{{patient.pesel}}</td>
                  </tr>
                  <tr v-if="patient.identityDocument === 0 && patient.identityDocumentNumber">
                    <td>Dowód osobisty</td>
                    <td class="pl-3 has-text-right">{{patient.identityDocumentNumber}}</td>
                  </tr>
                  <tr v-if="patient.identityDocument === 1 && patient.identityDocumentNumber">
                    <td>Paszport</td>
                    <td class="pl-3 has-text-right">{{patient.identityDocumentNumber}}</td>
                  </tr>
                  <tr v-if="patient.teleAddressTelephoneNumber">
                    <td>Numer telefonu</td>
                    <td class="pl-3 has-text-right">{{patient.teleAddressTelephoneNumber}} ({{patient.phoneNumberOwner ? patient.phoneNumberOwner : "brak informacji"}})
                      <span
                        v-if="patient.phoneNumberOtherOwner"
                      >- {{patient.phoneNumberOtherOwner}}</span></td>
                  </tr>
                </table>
              </div>
              <div v-else class="pb-4">
                <b-skeleton width="20%" :animated="true"></b-skeleton>
                <b-skeleton width="100%" size="is-large" :animated="true"></b-skeleton>
              </div>

              <div v-if="employee" class="pb-4">
                <p class="has-text-weight-medium has-text-primary mb-0">Pracownik wykonujący</p>
                <p class="subtitle">{{ employee.lastName }} {{ employee.firstName }}</p>
              </div>
              <div v-else class="pb-4">
                <b-skeleton width="20%" :animated="true"></b-skeleton>
                <b-skeleton width="100%" size="is-large" :animated="true"></b-skeleton>
              </div>

              <div v-if="employeeCreated" class="pb-4">
                <p class="has-text-weight-medium has-text-primary mb-0">Pracownik zapisujący</p>
                <p class="subtitle">{{ employeeCreated.lastName }} {{ employeeCreated.firstName }}</p>
              </div>
              <div v-else class="pb-4">
                <b-skeleton width="20%" :animated="true"></b-skeleton>
                <b-skeleton width="100%" size="is-large" :animated="true"></b-skeleton>
              </div>

              <div v-if="facility" class="pb-4">
                <p class="has-text-weight-medium has-text-primary mb-0">Placówka</p>
                <p class="subtitle">{{ facility.name ? facility.name : "Brak" }}</p>
              </div>

              <div v-if="room" class="pb-4">
                <p class="has-text-weight-medium has-text-primary mb-0">Pokój</p>
                <p class="subtitle">{{ room.nameFull ? room.nameFull : "Brak" }}</p>
              </div>

              <b-tag
                v-if="block.appointment.isApkolce"
                type="is-warning"
                size="is-medium"
                rounded
                class="mt-1 mb-1"
              >
                <b>Rejestrowana w AP-KOLCE</b>
              </b-tag>
              <ContentPanel
                v-if="patient && block.appointment.ticketInfo && block.appointment.ticketInfo.referral"
                title="Skierowanie"
                icon="alpha-s-circle-outline"
                class="mt-3"
                :colapse="true"
              >
                <template v-if="block.appointment.ticketInfo.referral.isPaperReferral == true">
                  <template v-if="block.appointment.ticketInfo.referral.idReferralFile != null">
                    <div class="buttons are-small">
                      <b-button
                        type="is-primary"
                        @click="
                          getDownloadedFile(block.appointment.ticketInfo.referral.idReferralFile)
                        "
                        icon-left="magnify"
                        :loading="loading"
                      >Podgląd skierowania papierowego</b-button>
                    </div>
                  </template>
                  <template v-else>Brak skanu skierownia</template>
                </template>
                <template v-else>
                  <template v-if="block.appointment.ticketInfo.referral.eReferral != null">
                    <div class="buttons">
                      <b-button
                        type="is-primary"
                        @click="printEReferral"
                        icon-left="printer"
                        :loading="loading"
                      >Drukuj e-skierowanie</b-button>
                    </div>

                    <div
                      id="summary"
                      v-html="block.appointment.ticketInfo.referral.eReferral.eSkierowanie"
                    ></div>

                    <div style="display: none;">
                      <div>
                        <div
                          id="printEReferral"
                          v-html="block.appointment.ticketInfo.referral.eReferral.eSkierowanie"
                        ></div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div>Błąd skierowania</div>
                  </template>
                </template>
              </ContentPanel>

              <ContentPanel 
                v-if="block.appointment.ticketInfo && block.appointment.ticketInfo.apkolce" 
                icon="alpha-s-circle-outline" title="APKolce"
                class="mt-3"
                :colapse="true"
                :paddingless="true"
              >
                    <table v-if="block.appointment.ticketInfo.apkolce" class="table is-narrow mb-3" style="width: 100%">
                      <tr>
                        <th colspan="2" class="has-text-primary">APKolce</th>
                      </tr>
                      <tr>
                        <td>Przypadek:</td>
                        <td class="has-text-weight-medium">
                          {{block.appointment.ticketInfo.apkolce.isUrgent ? "Pilny" : "Stabilny"}}
                        </td>
                      </tr>
                      <tr>
                        <td>Status:</td>
                        <td class="has-text-weight-medium">
                          {{block.appointment.ticketInfo.apkolce.registrationType ? block.appointment.ticketInfo.apkolce.registrationType : "Brak"}}
                        </td>
                      </tr>
                      <tr v-if="block.appointment.ticketInfo.apkolce.icd10Code && block.appointment.ticketInfo.apkolce.icd10Name">
                        <td>Rozpoznanie główne:</td>
                        <td class="has-text-weight-medium">
                          <span>
                            <b-tag type="is-primary" class="ml-1 mr-1 has-text-weight-bold">
                              <b>{{ block.appointment.ticketInfo.apkolce.icd10Code }}</b>
                            </b-tag>
                            {{ block.appointment.ticketInfo.apkolce.icd10Name }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="block.appointment.ticketInfo.apkolce.icd10Additional">
                        <td>Rozpoznania dodatkowe:</td>
                        <td class="has-text-weight-medium">
                          <span v-for="(item, index) in block.appointment.ticketInfo.apkolce.icd10Additional" :key="index">
                            <b-tag type="is-primary" class="ml-1 mr-1 has-text-weight-bold">
                              {{ item.code }}
                            </b-tag>
                            {{ item.name }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Komentarz:</td>
                        <td class="has-text-weight-medium">
                            {{ block.appointment.ticketInfo.apkolce.comment ? block.appointment.ticketInfo.apkolce.comment : "Brak" }}
                        </td>
                      </tr>
                      <tr>
                        <td>Części ciała:</td>
                        <td class="has-text-weight-medium">
                          <span v-for="(item, index) in block.appointment.ticketInfo.apkolce.bodyParts" :key="index">
                            <b-tag type="is-primary" class="ml-1 mr-1 has-text-weight-bold">
                              {{ item.code }}
                            </b-tag>
                            {{ item.name }}
                          </span>
                        </td>
                      </tr>
                    </table>

                    <table v-if="block.appointment.ticketInfo.referral && block.appointment.ticketInfo.referral.apkolce" class="table is-narrow mt-3" style="width: 100%">
                      <tr>
                        <th colspan="2" class="has-text-primary">Skierowanie APKolce</th>
                      </tr>
                      <tr>
                        <td>Nazwa podmiotu:</td>
                        <td class="has-text-weight-medium">
                          {{block.appointment.ticketInfo.referral.apkolce.subject}}
                        </td>
                      </tr>
                      <tr>
                        <td>REGON:</td>
                        <td class="has-text-weight-medium">
                          {{block.appointment.ticketInfo.referral.apkolce.regon}}
                        </td>
                      </tr>
                      <tr>
                        <td>Kod część I:</td>
                        <td class="has-text-weight-medium">
                          {{block.appointment.ticketInfo.referral.apkolce.code1st}}
                        </td>
                      </tr>
                      <tr>
                        <td>Kod część VII:</td>
                        <td class="has-text-weight-medium">
                          {{block.appointment.ticketInfo.referral.apkolce.code7th}}
                        </td>
                      </tr>
                      <tr>
                        <td>Kod część VIII:</td>
                        <td class="has-text-weight-medium">
                          {{block.appointment.ticketInfo.referral.apkolce.code8th}}
                        </td>
                      </tr>
                      <tr>
                        <td>Kod miejsca udzielania świadczeń:</td>
                        <td class="has-text-weight-medium">
                          {{block.appointment.ticketInfo.referral.apkolce.musCode}}
                        </td>
                      </tr>
                      <tr>
                        <td>Data wystawienia:</td>
                        <td class="has-text-weight-medium">
                          <span v-if="mDate(block.appointment.ticketInfo.referral.apkolce.issueDate) !== '0001-01-01'">
                            {{mDate(block.appointment.ticketInfo.referral.apkolce.issueDate)}}
                          </span>
                        </td>
                      </tr>
                    </table>
              </ContentPanel>

              <div v-if="block.appointment.sms" class="pb-2">
                <p class="has-text-weight-medium has-text-primary mb-0">Wiadomości SMS:</p>
                <p class="pb-3" v-for="sms in block.appointment.sms" :key="sms.index">
                  {{ mDateTime(sms.created) }}: <i>{{ sms.content }}</i>
                </p>
              </div>

              <div
                v-if="editmode && isRISOffice && block.appointment.state < 1
                && block.appointment.ticketInfo" class="mb-1"
              >
                <SchedulePatientChangeOrder @updated="changeOrderUpdate" :examination="block.appointment" :ticket="block.appointment.ticketInfo"></SchedulePatientChangeOrder>
              </div>

              <div
                v-if="editmode && isRISOffice && block.appointment.state < 1" class="mb-1"
              >
                <SchedulePatientChangeIKZ @updated="changeIKZUpdate" :examination="block.appointment"></SchedulePatientChangeIKZ>
              </div>

              <div
                v-if="editmode && isRISOffice && block.appointment.state < 1 
              && block.appointment.ticketInfo && block.appointment.ticketInfo.apkolce" class="mb-1"
              >
                <RISPatientChangeAPKolce
                  @updated="changeAPKolceUpdate"
                  :ticket="block.appointment.ticketInfo"
                ></RISPatientChangeAPKolce>
              </div>

              <div
                v-if="editmode && isRISOffice && block.appointment.state < 1 
              && block.appointment.ticketInfo && block.appointment.ticketInfo.referral && block.appointment.ticketInfo.referral.apkolce" class="mb-1"
              >
                <RISPatientChangeAPKolceReferral
                  @updated="changeAPKolceReferralUpdate"
                  :ticket="block.appointment.ticketInfo"
                ></RISPatientChangeAPKolceReferral>
              </div>

              <div
                v-if="editmode && block.appointment.state < 1 
                && (block.appointment.type === 1000 || 
                block.appointment.type === 1012 || 
                block.appointment.type === 1023 || 
                block.appointment.type === 10000 || 
                block.appointment.type === 10001)"
              >              
                <RISPatientChangeDILO
                  @updated="changeDILOUpdate"
                  :ticket="block.appointment.ticketInfo"
                ></RISPatientChangeDILO>
              </div>


              <p
                class="mt-4"
                v-if="
                  (block.appointment.isUrgent || block.appointment.isPrivate) &&
                  !editmode
                "
              >Inne</p>
              <div v-if="editmode" class="mt-4">
                <b-switch type="is-danger" v-model="appCopy.isUrgent">Pilne</b-switch>
              </div>
              <div v-else>
                <p class="subtitle">
                  <span v-if="block.appointment.isUrgent" class="has-text-danger">
                    <b-icon icon="alert" size="is-small" class="mb-1 mr-2"></b-icon>Pilne!
                    <br />
                  </span>
                  <span v-if="block.appointment.isPrivate">
                    <b-icon icon="cash" size="is-small" class="mb-1 mr-2"></b-icon>Prywatna
                    <br />
                  </span>
                </p>
              </div>

              <div v-if="!editmode" class="mt-4">
                <p v-if="block.appointment.isPrivate">Opłacona</p>
                <p
                  v-if="block.appointment.isPrivate && block.appointment.isPaid"
                  class="subtitle has-text-success"
                >
                  <b-icon icon="cash-plus" size="is-small" class="mb-1 mr-2"></b-icon>Tak
                </p>
                <p
                  v-if="
                    block.appointment.isPrivate && !block.appointment.isPaid
                  "
                  class="subtitle has-text-danger"
                >
                  <b-icon icon="cash-plus" size="is-small" class="mb-1 mr-2"></b-icon>Nie
                </p>
              </div>
            </div>
        </div>
        <!-- Print -->
        <div id="printDetails" style="display: none">
          <!-- <template v-if="subunit && facility">
            <DocsHeadlineSubunit
              :subunit="subunit"
              :facility="facility"
              :app="block.appointment"
            />
          </template>-->

          <p
            class="has-text-centered has-text-weight-bold is-size-4"
            style="
              margin-top: 20px;
              margin-bottom: 0px;
              padding-bottom: 0px;
              border-bottom: 3px solid black;
            "
          >UMÓWIONA WIZYTA</p>

          <div v-if="patient">
            <p class="is-size-6 has-text-weight-bold">
              {{ patient.firstName ? patient.firstName.toUpperCase() : ' - ' }}
              {{ patient.lastName ? patient.lastName.toUpperCase() : ' - ' }}
            </p>
            <!-- <p class="is-size-6">
              PESEL:
              <span class="has-text-weight-bold">{{ patient.pesel ? patient.pesel : '-' }}</span>
            </p>-->
            <p
              class="is-size-6"
            >Data urodzenia: {{ patient.dateOfBirth ? mDate(patient.dateOfBirth) : '-' }}</p>
            <p class="is-size-6">
              {{ patient.teleAddressStreet ? patient.teleAddressStreet.toUpperCase() : '-' }}
              {{ patient.teleAddressHouseNumber ? patient.teleAddressHouseNumber : '-' }}
              {{ patient.teleAddressApartmentNumber ? '/' : ''}}
              {{ patient.teleAddressApartmentNumber}}, {{ patient.teleAddressZipCode ? patient.teleAddressZipCode : '-' }}
              {{ patient.teleAddressCity ? patient.teleAddressCity.toUpperCase() : '-' }}
              {{ patient.teleAddressCountry ? patient.teleAddressCountry.toUpperCase() : '-' }}
            </p>
          </div>

          <p
            class="has-text-centered is-size-5"
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid black;
            "
          >{{ mGetClinic(block.appointment.clinic).toUpperCase() }}</p>

          <p
            class="has-text-centered is-size-5"
            style="margin-bottom: 10px"
          >{{ getAppName(block.appointment.type).toUpperCase() }}</p>

          <p
            v-if="employee && !isRISOffice"
            class="has-text-centered is-size-5"
            style="margin-top: 30px; margin-bottom: 10px"
          >
            LEK. {{ employee.firstName ? employee.firstName.toUpperCase() : '-' }}
            {{ employee.lastName ? employee.lastName.toUpperCase() : '-' }}
          </p>

          <p class="has-text-centered is-size-5" style="margin-top: 30px">
            Termin
            <span class="ml-3 has-text-weight-bold">
              {{
              getDate(block.appointment.start)
              }}
            </span>
          </p>
          <p class="has-text-centered is-size-5" style="margin-bottom: 10px">
            Godzina
            <span class="ml-3 has-text-weight-bold">
              <span v-if="!block.appointment.isTimeless">{{ getHour(block.appointment.start) }}</span>
              <span v-else>-</span>
            </span>
          </p>

          <p
            v-if="room"
            class="has-text-centered is-size-5"
          >Pokój {{ room.nameFull ? room.nameFull.toUpperCase() : '-' }}</p>

          <p
            v-if="facility"
            class="has-text-centered is-size-5"
            style="margin-bottom: 15px"
          >{{ facility.name ? facility.name.toUpperCase() : "-" }}</p>

          <p v-if="facility" class="has-text-centered is-size-5">
            {{ facility.address.street ? facility.address.street.toUpperCase() : '-' }}
            {{ facility.address.buildingNumber ? facility.address.buildingNumber : '-' }}
            {{ facility.address.apartmentNumber ? "/ " : "" }}
            {{ facility.address.apartmentNumber }}
            {{ facility.address.city ? facility.address.city.toUpperCase() : '-' }}
            {{ facility.address.zipCode ? ", " : "" }}
            {{ facility.address.zipCode ? facility.address.zipCode.toUpperCase() : '-' }}
            {{ facility.address.country ? facility.address.country.toUpperCase() : '-' }}
          </p>
        </div>
        <p class="has-text-centered">
          <b-button
            v-if="block.appointment.isGroup"
            type="is-secondary"
            icon-left="alert"
            class="mt-3"
            rounded
            size="is-small"
            @click="splitGroup"
            :disabled="block.appointment.groupPatients.length < 1"
          >Rozdziel na wizyty pojedyncze</b-button>
        </p>
      </section>
      <section class="modal-card-body pl-5 pr-5 has-text-dark" v-else-if="appointment && isVehicle">
        <div class="columns">
          <div class="column">
            <p class="has-text-weight-medium has-text-primary mb-0">Pojazd</p>
            <p class="subtitle">
              <span
                style="word-wrap: break-all; white-space: pre-wrap"
              >{{ getVehicle(block.appointment.vehicle) }}</span>
            </p>

            <p class="has-text-weight-medium has-text-primary mb-0">Typ</p>
            <div v-if="editmode">
              <b-field expanded label="Typ rezerwacji" label-position="on-border">
                <b-select v-model="appCopy.type" expanded>
                  <option :value="-1">Brak</option>
                  <option
                    v-for="item in vehicleApps"
                    :key="item.id"
                    :value="item.id"
                  >{{ item.name }}</option>
                </b-select>
              </b-field>
            </div>
            <p v-else class="subtitle">
              <span
                style="word-wrap: break-all; white-space: pre-wrap"
              >{{ getMobiReservationType(block.appointment.type) }}</span>
            </p>

            <p class="has-text-weight-medium has-text-primary mb-0">Deklarowany kierowca</p>
            <div v-if="editmode">
              <b-field expanded label="Deklarowany kierowca" label-position="on-border">
                <b-autocomplete
                  :data="elmpoyeeFiltered"
                  v-model="employeeFilter"
                  placeholder="Wybierz"
                  open-on-focus
                  :custom-formatter="(object) => {return `${object.lastName} ${object.firstName}` }"
                  keep-first
                  expanded
                  @select="(option) => appCopy.employee = option ? option.id : -1"
                  :loading="requestInProgress"
                  clearable
                >
                  <template slot-scope="props">
                    <p
                      :class="{
                          'has-text-success has-text-weight-bold':
                            appCopy.employee === props.option.id,
                        }"
                    >
                      {{ props.option.lastName }}
                      {{ props.option.firstName }}
                    </p>
                  </template>
                </b-autocomplete>
              </b-field>
            </div>
            <p v-else class="subtitle">{{ getEmployee(block.appointment.employee) }}</p>

            <p class="has-text-weight-medium has-text-primary mb-0">Data wyjazdu</p>
            <p class="subtitle">{{ mDateTime(block.appointment.start) }}</p>

            <p class="has-text-weight-medium has-text-primary mb-0">Data przyjazdu</p>
            <p class="subtitle">{{ mDateTime(block.appointment.stop) }}</p>
          </div>
          <div class="column">
            <p class="has-text-weight-medium has-text-primary mb-0">Placówka</p>
            <div v-if="editmode && vehicleReservation">
              <div>
                <p class="mb-2">
                  Placówka:
                  <b-switch
                    size="is-small"
                    v-model="vehicleReservation.reservationDetails.internalClinic"
                  >
                    {{
                    vehicleReservation.reservationDetails.internalClinic == true
                    ? "Placówka CMD"
                    : "Placówka inna"
                    }}
                  </b-switch>
                </p>
              </div>
              <b-field label="Miejsce" label-position="on-border">
                <b-autocomplete
                  v-if="vehicleReservation.reservationDetails.internalClinic"
                  :data="facilitiesFiltered"
                  v-model="facilityFilter"
                  clearable
                  open-on-focus
                  field="name"
                  placeholder="Wybierz"
                  keep-first
                  @select="(option) => vehicleReservation.reservationDetails.destinationClinic = option ? option.internalId : -1"
                >
                  <template slot-scope="props">
                    <p
                      :class="{
                                'has-text-success has-text-weight-bold':
                                  vehicleReservation.reservationDetails.destinationClinic === props.option.id,
                              }"
                    >{{ props.option.name }}</p>
                    <p>
                      {{ props.option.address.street }}
                      {{ props.option.address.buildingNumber }}
                      {{ props.option.address.apartmentNumber ? "/ " : "" }}
                      {{ props.option.address.apartmentNumber }}
                    </p>
                    <p>
                      {{ props.option.address.city }}
                      {{ props.option.address.zipCode ? ", " : "" }}
                      {{ props.option.address.zipCode }}
                    </p>
                  </template>
                </b-autocomplete>
                <b-input
                  v-else
                  v-model="vehicleReservation.reservationDetails.destinationText"
                  placeholder="Nazwa placówki"
                />
              </b-field>
            </div>
            <p
              v-else
              class="subtitle"
            >{{ vehicleReservationDetails.internalClinic && vehicleReservationDetails.destinationClinic !== -1 ? getFacilityNameByInternalId(vehicleReservationDetails.destinationClinic) : vehicleReservationDetails.destinationText }}</p>

            <p class="has-text-weight-medium has-text-primary mb-0">Liczba osób</p>
            <b-field
              v-if="editmode && vehicleReservation"
              label="Liczba osób"
              label-position="on-border"
            >
              <b-input type="number" v-model="vehicleReservation.reservationDetails.peopleNumber" />
            </b-field>
            <p v-else class="subtitle">{{ vehicleReservationDetails.peopleNumber }}</p>

            <p class="has-text-weight-medium has-text-primary mb-0">Potrzebna przestrzeń dodatkowa</p>
            <div v-if="editmode && vehicleReservation">
              <b-field>
                <b-checkbox
                  v-model="vehicleReservation.reservationDetails.isAdditionalSpace"
                >Potrzebna dodatkowa przestrzeń ładunkowa</b-checkbox>
              </b-field>

              <b-field
                v-if="vehicleReservation.reservationDetails.isAdditionalSpace"
                label="Co chcesz przewieźć?"
                label-position="on-border"
              >
                <b-input v-model="vehicleReservation.reservationDetails.additionalSpace" />
              </b-field>
            </div>
            <p
              v-else
              class="subtitle"
            >{{ vehicleReservationDetails.isAdditionalSpace ? `Tak, ${vehicleReservationDetails.additionalSpace}` : `Nie` }}</p>

            <p class="has-text-weight-medium has-text-primary mb-0">Uwagi</p>
            <b-field v-if="editmode && vehicleReservation" label="Uwagi" label-position="on-border">
              <b-input type="textarea" v-model="vehicleReservation.reservationDetails.notes" />
            </b-field>
            <p v-else class="subtitle">{{ vehicleReservationDetails.notes }}</p>
          </div>
        </div>
      </section>
      <footer v-if="isVehicle" class="modal-card-foot">
        <b-button
          size="is-small"
          icon-left="calendar-remove"
          :loading="requestInProgress"
          class="button is-danger"
          @click="cancelAppointmentVehicle()"
          :disabled="
            block.appointment.state > 4 ||
            (block.appointment.state > 0 && isHistory) ||
            editmode ||
            focusBlock
          "
        >Odwołaj wizytę</b-button>
        <!-- <b-button
          class="button is-primary"
          size="is-small"
          icon-left="swap-horizontal-bold"
          @click="reschedule"
          :loading="requestInProgress"
          :disabled="
            block.appointment.state > 4 ||
            (block.appointment.state > 0 && isHistory) ||
            editmode ||
            focusBlock
          "
        >Przełóż</b-button>
        <b-button
          size="is-small"
          class="button is-primary"
          icon-left="swap-horizontal-bold"
          @click="markForReschedule"
          :loading="requestInProgress"
          v-if="!block.appointment.isForReschedule"
          :disabled="
            block.appointment.state > 4 ||
            (block.appointment.state > 0 && isHistory) ||
            editmode ||
            focusBlock
          "
        >Zleć przełożenie</b-button>-->

        <b-button
          class="button is-primary"
          v-if="editmode"
          icon-left="check"
          size="is-small"
          :loading="requestInProgress"
          :disabled="
            maxDurationEditedVisit < duration ||
            duration < minDurationEditedVisit ||
            duration % 5 != 0
          "
          @click="saveUpdatesVehicle"
        >Zapisz zmiany</b-button>
        <b-button
          type="is-warning"
          v-if="editmode"
          size="is-small"
          icon-left="close"
          :loading="requestInProgress"
          @click="editmodeCancel"
        >Anuluj</b-button>
        <b-button
          class="button is-primary"
          v-else
          :loading="requestInProgress"
          @click="editmode = true"
          size="is-small"
          icon-left="pencil"
          :disabled="block.appointment.state > 4 || isHistory"
        >Edytuj informacje</b-button>
        <!-- <b-button
          expanded
          icon-left="printer"
          type="is-primary"
          size="is-small"
          @click="print"
          :loading="loadingSubunit"
          :disabled="editmode"
        >Drukuj</b-button>-->
        <!-- <ScheduleModalAppointmentDetailsPrint :appointment="block.appointment" :employee="employee"/> -->
      </footer>
      <footer v-else class="modal-card-foot p-2">
        <b-button
          size="is-small"
          icon-left="calendar-remove"
          :loading="requestInProgress"
          class="button is-danger"
          @click="isCancelAppointmentModalActive = true"
          :disabled="
            block.appointment.state > 4 ||
            (block.appointment.state > 0 && isHistory) ||
            editmode ||
            focusBlock
          "
        >Odwołaj wizytę</b-button>
        <b-button
          class="button is-primary"
          size="is-small"
          icon-left="swap-horizontal-bold"
          @click="reschedule"
          :loading="requestInProgress"
          :disabled="
            (block.appointment.state > 4 && block.appointment.state != 8) ||
            (block.appointment.state > 0 && isHistory && block.appointment.state != 8) ||
            editmode ||
            focusBlock
          "
        >Przełóż</b-button>
        <!-- <b-button
          size="is-small"
          class="button is-primary"
          icon-left="swap-horizontal-bold"
          @click="markForReschedule"
          :loading="requestInProgress"
          v-if="!block.appointment.isForReschedule"
          :disabled="
            block.appointment.state > 4 ||
            (block.appointment.state > 0 && isHistory) ||
            editmode ||
            focusBlock
          "
        >Zleć przełożenie</b-button>-->

        <b-button
          class="button is-primary"
          v-if="editmode"
          icon-left="check"
          size="is-small"
          :loading="requestInProgress"
          :disabled="
            maxDurationEditedVisit < duration ||
            duration < minDurationEditedVisit ||
            duration % 5 != 0
          "
          @click="saveUpdates"
        >Zapisz zmiany</b-button>
        <b-button
          type="is-warning"
          v-if="editmode"
          size="is-small"
          icon-left="close"
          :loading="requestInProgress"
          @click="editmodeCancel"
        >Anuluj</b-button>
        <b-button
          class="button is-primary"
          v-else
          :loading="requestInProgress"
          @click="editmode = true"
          size="is-small"
          icon-left="pencil"
          :disabled="block.appointment.state > 4 || isHistory"
        >Edytuj informacje</b-button>
        <b-button
          icon-left="printer"
          type="is-primary"
          size="is-small"
          @click="print"
          :loading="loadingSubunit"
          :disabled="editmode"
        >Drukuj</b-button>
        <!-- <ScheduleModalAppointmentDetailsPrint :appointment="block.appointment" :employee="employee"/> -->
      </footer>
    </div>

    <b-modal
      :active.sync="isCancelAppointmentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="overflow: visible">
        <header class="modal-card-head has-background-kin">
          <span class="modal-card-title has-text-primary">Odwołaj wizytę</span>
        </header>
        <section class="modal-card-body" style="color: black;">
          <div class="pb-2">
            <p class="subtitle is-centered mb-5">Powód odwołania wizyty</p>
            <b-field position="is-centered" v-for="item in appointmentCancelReasons" :key="item.id">
              <b-radio v-model="cancelAppointmentReason" :native-value="item.id" :disabled="item.id == 0">{{item.name}}</b-radio>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot has-background-kin buttons is-right pt-2 pb-1">
          <b-button
            :loading="requestInProgress"
            @click="isCancelAppointmentModalActive = false"
          >Anuluj</b-button>
          <b-button
            :disabled="block.appointment === null || cancelAppointmentReason === null"
            :loading="requestInProgress"
            @click="cancelAppointment"
            type="is-danger"
          >Odwołaj wizytę</b-button>
        </footer>
      </div>
    </b-modal>

    <AppointmentCancelReferralP1
      @eReferral-canceled="cancelAppointmentEreferralCancelled"
      :trigger="triggerCancelReferral"
      :ticket="block.appointment.ticketInfo"
      :referral="null"
    />
  </div>
</template>

<script>
import CommonMixins from "@/mixins/commons";
import DateHelpers from "@/mixins/date_helpers";
import AppMixin from "../../mixins/appointments";
import moment from "moment";
import { Action } from "../../store/config_actions";
import { Mutation } from "../../store/config_mutations";
import ClinicsMixins from "@/mixins/clinics";
import MedEnumsMixins from "@/mixins/med_enums";
import AppointmentsMixins from "@/mixins/appointments";
import InvMixins from "@/mixins/inventory";
import MobiMixins from "@/mixins/mobi"
import RisMixins from "@/mixins/ris"
import PersonnelMixin from "@/mixins/personnel";

// import ContentPanel from "@/components/tools/ContentPanel";

// import DocsHeadlineSubunit from "@/components/tools/DocsHeadlineSubunit";
import AppointmentCancelReferralP1 from '@/components/appointments/AppointmentCancelReferralP1'

import SchedulePatientChangeOrder from '@/components/ris/ris-workshops/patients/SchedulePatientChangeOrderVer2'
import SchedulePatientChangeIKZ from '@/components/ris/ris-workshops/patients/SchedulePatientChangeIKZ'
import RISPatientChangeAPKolce from '@/components/ris/ris-workshops/patients/RISPatientChangeAPKolce'
import RISPatientChangeAPKolceReferral from '@/components/ris/ris-workshops/patients/RISPatientChangeAPKolceReferral'
import RISPatientChangeDILO from '@/components/ris/ris-workshops/patients/RISPatientChangeDILO'

import IkzRegistrationMixins from '@/mixins/finance/ikz_registration'

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=no"],
  styles: ["buefy_for_print_only.css", "margins_paddings_for_print_only.css"],
};

export default {
  name: "ScheduleModalAppointmentDetails",
  mixins: [CommonMixins, DateHelpers, AppMixin, ClinicsMixins, MedEnumsMixins, AppointmentsMixins, 
  InvMixins, MobiMixins, RisMixins, PersonnelMixin, IkzRegistrationMixins],
  components: {
    AppointmentCancelReferralP1,
    SchedulePatientChangeOrder,
    SchedulePatientChangeIKZ,
    RISPatientChangeAPKolce,
    RISPatientChangeAPKolceReferral,
    RISPatientChangeDILO,
  //  ContentPanel,
    // DocsHeadlineSubunit 
  },

  data: function () {
    return {
      appointment: null,
      appointmentObject: null,
      appointmentObjectTmp: null,
      subcategories: null,
      appStartDate: moment(this.block.start).toDate(),
      minTime: moment(this.block.start).toDate(),
      maxTime: moment(this.block.end).subtract(5, "minutes").toDate(),
      unselectable: [],
      visitLength: 5,
      // patient: null,
      employee: null,
      employeeCreated: null,
      employeeWorktime: null,
      requestInProgress: false,
      editmode: false,
      appCopy: JSON.parse(JSON.stringify(this.block.appointment)),

      //print props
      subunit: null,
      facility: null,
      room: null,
      loadingSubunit: false,
      loading: false,

      duration: 0,
      editTrigger: 0,
      groupPatients: [],

      isCancelAppointmentModalActive: false,
      cancelAppointmentReason: null,
      triggerCancel: 0,
      triggerCancelReferral: 0,

      comment: "",

      employeeFilter: "",
      facilityFilter: "",
      vehicleReservation: null,

      fileProps: null,

      filteredAppTypes: [],
      showAllAppTypes: false,
      patient: null
    };
  },

  watch: {
    employee() {
      this.myAppointmentTypes();
    },

    // appointmentObject() {
    //   this.appCopy.type = this.appointmentObject.id;
    //   this.appCopy.subtype =
    //     this.subcategories && this.subcategories.length > 0
    //       ? this.subcategories[0].id
    //       : -1;
    // },

    appStartDate() {
      this.appointment.start = moment(this.appStartDate).format(
        "YYYY-MM-DDTHH:mm:00"
      );
      if (this.visitLength > this.maxDuration)
        this.visitLength = this.maxDuration;
      this.appointment.stop = moment(this.appStartDate)
        .add(this.visitLength, "minutes")
        .format("YYYY-MM-DDTHH:mm:00");
    },

    visitLength() {
      this.appointment.stop = moment(this.appStartDate)
        .add(this.visitLength, "minutes")
        .format("YYYY-MM-DDTHH:mm:00");
    },

    editmode() {
      if (this.editmode) {

        if(this.appCopy.isTimeless) {
          this.duration = this.visitLength
        } else {
          this.duration = moment(this.appCopy.stop).diff(moment(this.appStartDate), "minutes")
        }

        if (this.isVehicle) {
          let detailsObj = JSON.parse(this.block.appointment.description)
          this.vehicleReservation = detailsObj

          this.employeeFilter = this.getEmployee(this.appCopy.employee)
          if (this.vehicleReservation.reservationDetails.internalClinic) {
            this.facilityFilter = this.getFacilityNameByInternalId(this.vehicleReservation.reservationDetails.destinationClinic)
          }
        }
      }
    },

    "appCopy.payerType": function () {
      if (this.appCopy.payerType === 0) {
        this.appCopy.isPrivate = true
      } else {
        this.appCopy.isPrivate = false
        this.appCopy.isPaid = false
        this.appCopy.paymentId = null
      }
    },

    showAllAppTypes() {
      this.myAppointmentTypes();
    },

  },

  mounted() {

    if (this.block && this.block.appointment && this.block.appointment.patient) {
      this.patient = this.block.appointment.patient
    }

    this.appointment = this.reset();
    this.defUnselectable();
    this.getFullInfo();
    this.getFullInfoCreated();
    this.getFullInfoWorktime();
    this.getSubunit();
    this.getPatients();
    this.getTicket();
    this.myAppointmentTypes();
  },

  props: {
    block: { type: Object, required: true },
    blocks: { type: Array, required: false },
    // patient: {
    //   validator: (prop) => typeof prop === "object" || prop === null,
    //   required: true,
    // },
  },

  computed: {


    maxDuration() {
      return moment(this.block.end).diff(moment(this.appStartDate), "minutes");
    },

    registrationPatient() {
      return this.$store.state.registration.patient
    },

    permits() { return this.$store.state.employee.permits },

    canShorterAppointment() {
      if (this.permits && this.permits.shorterAppointments) return true;
      else return false;
    },

    minDurationEditedVisit() {
      let minDuration = 5;
      if (this.appointmentObject) {
        if (!this.canShorterAppointment && this.appointmentObject.minTime !== null && this.appointmentObject.minTime !== undefined && this.appointmentObject.blockTime) {
          minDuration = this.appointmentObject.minTime
        }
      }
      return minDuration;
    },

    minDurationEditedVisitInfo() {
      let minDuration = 5;
      if (this.appointmentObject) {
        if (this.appointmentObject.minTime !== null && this.appointmentObject.minTime !== undefined && (this.appointmentObject.blockTime === false || this.canShorterAppointment)) {
          minDuration = this.appointmentObject.minTime
        }
      }
      return minDuration;
    },

    maxDurationEditedVisit() {
        if(this.blocks) {
          let index = this.blocks.indexOf(this.block)
          if (index != -1) {
            let nextBlock = this.blocks[index + 1]

            if (nextBlock.appointment) {
              return moment(nextBlock.start).diff(moment(this.appCopy.start), "minutes")
            }
            return moment(nextBlock.end).diff(moment(this.appCopy.start), "minutes")
          }
        }

        if(this.block.end) {
          return moment(this.block.end).diff(moment(this.appStartDate), "minutes")
        } else return this.visitLength

    },

    me() {
      return this.$store.state.employee.me;
    },

    meParentFacilityObject() {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.internalId === this.me.parentFacility);
        return found;
      } else return null;
    },

    registrationIkz() {
      return this.$store.state.registration.ikz
    },

    apps() {
      return this.$store.state.config.appointments;
    },

    vehicleApps() {
      return this.apps.filter(option => option.isVehicle === true)
    },

    focusBlock() {
      return this.$store.state.poz.focusBlock;
    },

    isHistory() {
      let now = moment().startOf("day");
      return moment(this.block.appointment.start).isBefore(now);
    },

    facilities() {
      return this.$store.state.clinic.clinics ?? [];
    },

    facilitiesFiltered() {
      return this.facilities.filter((option) => {
        if (option.name) {
          return (
            option.name
              .toLowerCase()
              .indexOf(this.facilityFilter.toLowerCase()) >= 0
          );
        } else return false;
      });
    },

    isVehicle() {
      return this.block.worktimeArea && this.block.worktimeArea.vehicle > 0
    },

    isRISOffice() {
      if (this.focusOffice) {
        return this.mTypesRiSClinics.includes(this.focusOffice.clinicId);
      } else if (this.block && this.block.worktimeArea && this.block.worktimeArea.clinic) {
        return this.mTypesRiSClinics.includes(this.block.worktimeArea.clinic);
      } else return false;
    },


    docs() { return this.$store.state.employee.all },

    vehicles() {
      return this.$store.state.mobi.mobiVehicles
    },

    vehicleReservationDetails() {
      let detailsObj = JSON.parse(this.block.appointment.description)
      return detailsObj.reservationDetails
    },

    elmpoyeeFiltered() {
      return this.docs.filter(option => {
        let id = (option.lastName + " " + option.firstName).toLowerCase()
        if (this.employeeFilter != null) {
          return id.indexOf(this.employeeFilter.toLowerCase()) >= 0
        }
        else return false
      })
    },

    configOffices() {
      return this.$store.state.config.offices;
    },

    allOffices() {
      return this.$store.state.offices.offices;
    },

    configAreas() {
      return this.$store.state.config.areas;
    },

  },

  methods: {

    getPatients() {
      if (this.block.appointment.isGroup) {
        this.$store.dispatch(Action.PATIENT_GET_MANY, this.block.appointment.groupPatients)
          .then((x) => {
            this.groupPatients = x.data
          })
          .catch((error) => {
            this.apiProblem(error)
          })
      }
    },

    addToGroup() {
      this.$buefy.dialog.confirm({
        message: 'Na pewno dołączyć pacjenta ' + this.registrationPatient.lastName + ' ' + this.registrationPatient.firstName + ' do spotkania grupowego?',
        hasIcon: true,
        type: 'is-info',
        scroll: 'keep',
        confirmText: 'Tak, dołącz',
        cancelText: 'Anuluj',
        onConfirm: () => {
          this.loading = true
          var payload = { id: this.block.appointment.id, patientId: this.registrationPatient.id }
          this.$store
            .dispatch(Action.S3_ADD_TO_GROUP, payload)
            .then((data) => {
              this.block.appointment.groupPatients = data.groupPatients
              this.loading = false
              this.$emit('close')
              this.$emit("created", data);
            })
            .catch(error => {
              this.apiProblem(error);
              this.loading = false
            });
        }
      })
    },

    removePatientFromGroup(pat) {
      this.$buefy.dialog.confirm({
        message: 'Na pewno usunąć pacjenta ' + pat.lastName + ' ' + pat.firstName + ' ze spotkania grupowego?',
        hasIcon: true,
        type: 'is-danger',
        scroll: 'keep',
        confirmText: 'Tak, usuń',
        cancelText: 'Anuluj',
        onConfirm: () => {
          this.loading = true
          var payload = { id: this.block.appointment.id, patientId: pat.id }
          this.$store
            .dispatch(Action.S3_REMOVE_FROM_GROUP, payload)
            .then((data) => {
              this.block.appointment.groupPatients = data.groupPatients
              this.loading = false
              this.$emit('close')
            })
            .catch(error => {
              this.apiProblem(error);
              this.loading = false
            });
        }
      })
    },

    getAppWays(appType) {
      let ways = []
      var apNone = { id: -1, name: 'Brak konfiguracji' }
      var ap0 = { id: 0, name: 'Wizyta w przychodni', icon: 'home-city-outline' }
      var ap1 = { id: 1, name: 'Teleporada', icon: 'phone-in-talk-outline' }
      var ap2 = { id: 2, name: 'Wizyta domowa', icon: 'car-outline' }

      if (appType || appType === 0) {

        let configured = this.apps.find(x => x.id == appType)

        if (this.appointmentObject) {
          configured = this.appointmentObject
        }

        if (configured && configured.ways) {
          configured.ways.forEach(w => {
            if (w === 0) ways.push(ap0)
            if (w === 1) ways.push(ap1)
            if (w === 2) ways.push(ap2)
          })

          if (ways.length < 1) {
            ways.push(apNone)
          }
        }
        else {
          ways.push(apNone)
        }
      }
      else {
        ways.push(apNone)
      }

      return ways
    },

    getAppPayers(appType) {
      let payers = []
      var apNone = { id: -1, name: 'Brak płatników' }
      var ap0 = { id: 0, name: 'Komercja' }
      var ap1 = { id: 1, name: 'NFZ' }
      var ap2 = { id: 2, name: 'MZ' }

      if (appType || appType === 0) {
        let configured = this.apps.find(x => x.id == appType)

        if (this.appointmentObject) {
          configured = this.appointmentObject
        }

        if (configured && configured.payers) {
          configured.payers.forEach(p => {
            if (p === 0) payers.push(ap0)
            if (p === 1) payers.push(ap1)
            if (p === 2) payers.push(ap2)
          })

          if (payers.length < 1) {
            payers.push(apNone)
          }
        }
        else {
          payers.push(apNone)
        }
      }
      else {
        payers.push(apNone)
      }

      return payers.sort((a, b) => b.id - a.id)
    },

    reschedule() {
      this.$store.commit(Mutation.APPOINTMENT_SIMPLE_FOCUS, this.block);
      this.$emit("reschedule-ordered");
      this.$emit("close");
      this.toast("Wybierz nowy termin na terminarzu")
    },

    getAppName(id) {
      let category = this.apps.find((c) => c.id == id);
      if (category) return category.name;
      else return `Nieznana strefa`;
    },

    getAppSubCategory(id, sid) {
      if (sid === null || sid < 0) return 'Brak'

      let category = this.apps.find(c => c.id == id)

      if (category) {
        if (category.subcategories) {
          let subcategory = category.subcategories.find(s => s.id == sid)
          if (subcategory) {
            return subcategory.name
          }
        }
      }

      return `??? [${sid}]`
    },


    getAppIcon(id) {
      let category = this.apps.find((c) => c.id == id);
      if (category) return category.icon;
      else return `help`;
    },

    getFullInfo() {
      if (this.block.appointment.employee === -1) {
        this.employee = { firstName: "", lastName: "Brak" };
      } else {
        this.$store
          .dispatch(Action.EMPLOYEE_GET, this.block.appointment.employee)
          .then((x) => {
            this.employee = x.data;
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }

      // this.$store.dispatch(Action.PATIENT_GET_BY_ID, this.block.appointment.patientId)
      //   .then((x) => {
      //     this.patient = x.data
      //   })
      //   .catch((error) => {
      //     this.apiProblem(error)
      //   })
    },

    getFullInfoCreated() {
      if (this.block.appointment.createdBy === -1) {
        this.employeeCreated = { firstName: "", lastName: "Brak" };
      } else {
        this.$store
          .dispatch(Action.EMPLOYEE_GET, this.block.appointment.createdBy)
          .then((x) => {
            this.employeeCreated = x.data;
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    getFullInfoWorktime() {
      if (this.block.worktimeArea && this.block.worktimeArea.employee > -1) {
        if (this.block.worktimeArea.employee === this.block.appointment.employee) {
          this.employeeWorktime = this.employee
        } else {
          this.$store
            .dispatch(Action.EMPLOYEE_GET, this.block.appointment.createdBy)
            .then((x) => {
              this.employeeWorktime = x.data;
            })
            .catch((error) => {
              this.apiProblem(error);
            });
        }
      }
    },

    saveUpdates() {
      this.editmode = false
      this.appCopy.type = this.appointmentObjectTmp
      this.appCopy.stop = moment(new Date(this.appCopy.start)).add(this.duration, "minutes").format("YYYY-MM-DDTHH:mm:00")
      this.$buefy.dialog.confirm({
        message: "Czy na pewno zmienić dane wizyty?",
        confirmText: "Tak",
        cancelText: "Anuluj",
        scroll: "keep",
        hasIcon: true,
        type: "is-info",
        onConfirm: () => {
          this.requestInProgress = true;
          this.$store
            .dispatch(Action.S2_EDIT_APPOINTMENT, this.appCopy)
            .then(() => {
              
              //ADD IKZ - EDYCJA WIZYTY
              this.addIKZVisitEdit()

              this.successSnackbar("Zapisano")
              this.requestInProgress = false
              this.block.appointment = this.appCopy
              this.$emit("edited", this.block.appointment)
            })
            .catch((error) => {
              this.requestInProgress = false
              this.apiProblem(error)
            });
        },
      });
    },

    markForReschedule() {
      this.editmode = false;
      this.$buefy.dialog.confirm({
        message: "Czy na pewno oznaczyć do przełożenia?",
        confirmText: "Tak",
        cancelText: "Anuluj",
        scroll: "keep",
        hasIcon: true,
        type: "is-info",
        onConfirm: () => {
          this.appCopy.isForReschedule = true;
          this.requestInProgress = true;
          this.$store
            .dispatch(Action.S2_EDIT_APPOINTMENT, this.appCopy)
            .then(() => {
              this.successSnackbar("Zapisano");
              this.$emit("removed", this.block.appointment);
              this.requestInProgress = false;
            })
            .catch((error) => {
              this.requestInProgress = false;
              this.apiProblem(error);
            });
        },
      });
    },

    splitGroup() {
      this.$buefy.dialog.confirm({
        message: "Czy na pewno rozdzielić wizytę na mniejsze dla każdego pacjenta? Zostanie utworzone <strong>" + this.block.appointment.groupPatients.length + " nowych wizyt</strong> w miejsce obecnej grupowej.",
        scroll: "keep",
        hasIcon: true,
        confirmText: 'Tak, rozdziel',
        cancelText: 'Anuluj',
        type: "is-danger",
        onConfirm: () => {
          this.requestInProgress = true;
          this.$store
            .dispatch(Action.S3_SPLIT_GROUP, this.block.appointment.id)
            .then(() => {
              this.$emit("split");
              this.requestInProgress = false;
            })
            .catch((error) => {
              this.requestInProgress = false;
              this.apiProblem(error);
            });
        },
      });
    },

    onSubTypeChanged(val) {
      this.switchSubWithType(val)
      // if (val.time) this.visitLength = val.time;
      // else this.visitLength = 5;
    },

    switchSubWithType(val) {
      if (this.subcategories && this.subcategories.length > 0) {
        let candidate = this.subcategories.find(x => x.id == val)

        if (candidate) {
          this.appointmentObject = candidate
          this.appointment.subtype = candidate.id;
        }
      }
    },

    onTypeChanged(val) {
      let appObject = this.filteredAppTypes.find(x => x.id == val)
      if (appObject) {
        this.appointmentObject = appObject
        if (appObject.subcategories) {
          this.subcategories = appObject.subcategories;

          if (this.subcategories && this.subcategories.length > 0) {
            this.appCopy.subtype = this.subcategories[0].id;
          }
        } else {
          this.appCopy.subtype = -1;
          this.subcategories = null;
        }
      }

    },

    defUnselectable() {
      this.unselectable = [];
      let tmp = moment(this.block.start);

      while (tmp.isBefore(this.block.end)) {
        tmp.add(1, "minutes");

        if (tmp.minutes() % 5 > 0) {
          this.unselectable.push(tmp.toDate());
        }
      }
    },

    reset() {
      return {
        state: 0,
        subject: 0,
        spec: 0,
        patientId: 0,
        start: this.block.start,
        stop: moment(this.block.start)
          .add(5, "minutes")
          .format("YYYY-MM-DDTHH:mm:00"),
        area: 0,
        plannedProcedures: [],
        type: 0,
        subtype: 0,
        service: 0,
        description: "",
        reason: "",
        isUrgent: false,
        isPrivate: false,
        isTimeless: false,
        destination: 0,
        externalId: 0,
        created: this.mNow(),
        createdBy: this.me ? this.me.id : -1,
        modified: this.mNow(),
        modifiedBy: this.me ? this.me.id : -1,
      };
    },

    //print function
    getDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    getHour(date) {
      return moment(date).format("HH:mm");
    },

    //printMethords
    print() {
      this.$htmlToPaper("printDetails", options);
      this.addIkzPrintDetails()
    },

    getSubunit() {
      if (this.block.appointment.officeId) {
        this.loadingSubunit = true;
        this.$store
          .dispatch(
            Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_OFFICE_ID,
            this.block.appointment.officeId
          )
          .then((response) => {
            this.subunit = response;
            if (response && response.facilityId) {
              this.facility = this.getFacility(response.facilityId);
            } else {
              this.facility = null;
            }
            this.loadingSubunit = false;
          })
          .catch((error) => {
            this.subunit = null;
            this.facility = null;
            this.loadingSubunit = false;
            this.apiProblem(error);
          });
      } else if (this.block.appointment.subunitId) {
        this.loadingSubunit = true;
        this.$store
          .dispatch(
            Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_ID,
            this.block.appointment.subunitId
          )
          .then((response) => {
            this.subunit = response;
            if (response && response.facilityId) {
              this.facility = this.getFacility(response.facilityId);
            } else {
              this.facility = null;
            }
            this.loadingSubunit = false;
          })
          .catch((error) => {
            this.subunit = null;
            this.facility = null;
            this.loadingSubunit = false;
            this.apiProblem(error);
          });
      } else if (this.block.appointment.facility > 0) {
        this.facility = this.getFacilityByInternalId(this.block.appointment.facility);
      } else {
        this.facility = null;
      }

      if (this.block.appointment.officeRoomId) {
        this.$store
          .dispatch(Action.INVENTORY_GET_ROOM, this.block.appointment.officeRoomId)
          .then((data) => {
            if (data) {
              data.nameFull = `Nr ${data.number ?? "?"}, ${this.mGetName(
                this.mRoomTypes,
                data.type
              )} ${data.name ?? "-"}, ${data.description}`;
            }
            this.room = data;
          })
          .catch((error) => {
            this.room = null;
            this.apiProblem(error);
          });
      } else {
        this.room = null;
      }
    },

    getFacility(id) {
      if (this.facilities) {
        let pos = this.facilities.find((s) => s.id === id);
        if (pos) return pos;
        else return null;
      } else return null;
    },

    getFacilityByInternalId(id) {
      if (this.facilities) {
        let pos = this.facilities.find((s) => s.internalId === id);
        if (pos) return pos;
        else return null;
      } else return null;
    },

    getFacilityNameByInternalId(id) {
      if (this.facilities) {
        let pos = this.facilities.find((s) => s.internalId === id);
        if (pos) return pos.name;
        else return "Brak danych";
      } else return "Brak danych";
    },
    //end print methods

    getEmployee(id) {
      let emp = this.docs.find(x => x.id === id)
      if (emp) return `${emp.lastName} ${emp.firstName}`
      else return `Brak danych`
    },

    getVehicle(id) {
      let vehicle = this.vehicles.find(x => x.id === id)
      if (vehicle) return `${vehicle.brand} ${vehicle.model} \nRejestracja: ${vehicle.registrationNumber}`
      else return `Brak danych`
    },

    getTicket() {
      if (this.block.appointment.ticket) {
        this.requestInProgress = true;
        this.$store.dispatch(Action.TICKET_GET, this.block.appointment.ticket)
          .then((resp) => {
            if (resp) {
              this.block.appointment.ticketInfo = resp

              if (this.block.appointment.plannedProcedures && this.block.appointment.plannedProcedures.length > 0) {
                let ticketProcedure = resp.procedures[this.block.appointment.plannedProcedures[0]] ?? null;

                if(ticketProcedure !== null && ticketProcedure !== undefined) {
                  this.block.appointment.procedure = ticketProcedure;
                  this.block.appointment.procedureId = this.block.appointment.plannedProcedures[0];
                } else {
                  this.block.appointment.procedure = resp.procedures[0]
                  this.block.appointment.procedureId = 0
                }
              } else {
                if (resp && resp.procedures.length > 0) {
                  this.block.appointment.procedure = resp.procedures[0];
                  this.block.appointment.procedureId = 0;
                } else {
                  this.block.appointment.procedure = null;
                }
              }
            }
            this.requestInProgress = false;
          })
          .catch((error) => {
            this.apiProblem(error)
            this.requestInProgress = false;
          })
      }
    },

    cancelAppointment() {

      if (
        this.block.appointment.ticketInfo 
        && this.block.appointment.ticketInfo.referral
        && this.block.appointment.ticketInfo.referral.canceledId == null
        && this.block.appointment.ticketInfo.referral.eReferral 
        && this.block.appointment.ticketInfo.referral.eReferral.id != null) {
        this.successSnackbar('Anulowanie skierowania')
        this.triggerCancelReferral += 1
      } else {
        this.cancelAppointmentNoReferral()
      }
    },

    cancelAppointmentEreferralCancelled() {
      this.addIkzReferralCancel()
      this.cancelAppointmentNoReferral()
    },

    cancelAppointmentNoReferral() {
      if (this.patient) {
        this.cancelAppointmentNoReferralNextStep()
      }
      else if (this.block.appointment.patientId > 0) {
        this.$store
        .dispatch(Action.PATIENT_GET_BY_ID, this.block.appointment.patientId)
        .then((response) => {
          this.patient = response.data
          this.cancelAppointmentNoReferralNextStep()
        })
        .catch(error => {
          this.apiProblem(error);
        });
      }
    },

    cancelAppointmentNoReferralNextStep() {
      if (this.patient) {
        this.requestInProgress = true
        // let obj = {
        //   version: this.patient.version,
        //   stats: {
        //     patientId: this.patient.id,
        //     appointmentsAttended: this.patient.appointmentsAttended,
        //     appointmentsMissed: this.patient.appointmentsMissed,
        //     appointmentsCancelled: this.patient.appointmentsCancelled,
        //     appointmentsLate: this.patient.appointmentsLate,
        //     appointmentsRescheduled: this.patient.appointmentsRescheduled
        //   }
        // }
        let payload = {
          id: this.block.appointment.id,
          reason: this.cancelAppointmentReason
        }

        this.$store.dispatch(Action.S2_CANCEL_APPOINTMENT, payload)
          .then(() => {
            this.requestInProgress = false
            this.successSnackbar('Odwołano wizytę')
            this.isCancelAppointmentModalActive = false
            this.$emit("removed", this.block.appointment);

            //ADD IKZ - ODWOŁANIE WIZYTY
            this.addIkzAppointmentCancel()

            if (this.block.appointment.clinic == 7110 && this.block.appointment.ticketInfo) {
              this.setTicketFree(this.block.appointment.ticketInfo)
            }



            // this.$store.dispatch(Action.PATIENT_UPDATE_STATS, obj)
            //   .then(() => {
            //       this.$emit('close')
            //       this.requestInProgress = false

            //   })
            //   .catch((error) => {
            //     this.apiProblem(error)
            //     this.requestInProgress = false
            //   })
          })
          .catch((error) => {
            this.apiProblem(error)
            this.requestInProgress = false
          })
      } else if (this.block.appointment.isGroup) {
        let payload = {
          id: this.block.appointment.id,
          reason: this.cancelAppointmentReason
        }

        this.$store.dispatch(Action.S2_CANCEL_APPOINTMENT, payload)
          .then(() => {
            this.successSnackbar('Odwołano wizytę')
            this.$emit("removed", this.block.appointment);
            this.isCancelAppointmentModalActive = false
          })
          .catch((error) => {
            this.apiProblem(error)
            this.requestInProgress = false
          })
      }
      else {
        this.snackbar("Wystąpił problem z danymi. Prosimy o ponowne pobranie terminarza.")
      }
    },

    setTicketFree(ticket) {
      this.requestInProgress = true
      if (ticket) {
        this.$store.dispatch(Action.TICKET_SET_STATUS, { id: ticket.id, status: 1 })
          .then(() => {
            this.requestInProgress = false
            this.successSnackbar('Uwolniono bilet')

            //ADD IKZ - UWOLNIENIE BILETU
            this.addIkzTicketAvailable()
          })
          .catch((error) => {
            this.requestInProgress = false
            this.apiProblem(error)
          })
      }
    },

    cancelAppointmentVehicle() {
      this.$store.dispatch(Action.S2_DELETE_APPOINTMENT, this.block.appointment.id)
        .then(() => {
          this.successSnackbar('Odwołano rezerwację')
          this.$emit("removed");
        })
        .catch((error) => {
          this.apiProblem(error)
          this.requestInProgress = false
        })
    },

    saveUpdatesVehicle() {
      this.editmode = false
      this.appCopy.stop = moment(new Date(this.appCopy.start)).add(this.duration, "minutes").format("YYYY-MM-DDTHH:mm:00")
      this.$buefy.dialog.confirm({
        message: "Czy na pewno zmienić dane wizyty?",
        confirmText: "Tak",
        cancelText: "Anuluj",
        scroll: "keep",
        hasIcon: true,
        type: "is-info",
        onConfirm: () => {

          this.requestInProgress = true;
          this.appCopy.description = JSON.stringify({ reservationDetails: this.vehicleReservation.reservationDetails })
          this.$store
            .dispatch(Action.S2_EDIT_APPOINTMENT_VEHICLE, this.appCopy)
            .then(() => {
              this.successSnackbar("Zapisano")
              this.requestInProgress = false
              this.block.appointment = this.appCopy
              this.$emit("edited")
            })
            .catch((error) => {
              this.requestInProgress = false
              this.apiProblem(error)
            });
        },
      });
    },

    getDownloadedFile(fileId) {
      this.requestInProgress = true;
      //this.currentBlob = null;
      this.fileProps = {
        FileNameId: fileId,
        WorkerId: this.me.id,
        PatientId: this.patient.id,
      };
      this.$store
        .dispatch(Action.PATIENT_DOWNLOAD_FILE, this.fileProps)
        .then((response) => {
          //this.fileOptions(option, currentFile);
          let url = URL.createObjectURL(response.data);
          window.open(url, "_blank", "location=yes,scrollbars=yes");
          URL.revokeObjectURL(url);

          this.requestInProgress = false;
        })
        .catch((error) => {
          this.requestInProgress = false;
          this.dangerSnackbar(error.toString());
          //this.apiProblem(error);
        });
    },

    getOffice(id) {
      let category = this.allOffices.find((c) => c.id == id);
      if (category) return category.name;
      else return `Brak informacji`;
    },
    getOfficeObject(id) {
      return this.allOffices.find((c) => c.id == id);
    },
    getOfficeTypeApps(id) {
      let category = this.configOffices.find((c) => c.id == id);
      if (category && category.appointments) return category.appointments;
      else return [];
    },

    myAppointmentTypes() {
      let types = [];

      if (this.block.worktimeArea && this.block.worktimeArea.device) {
        if (this.isRISOffice) {
          let office = this.getOfficeObject(this.block.worktimeArea.officeId);
          if (office.type > -1) {
            let possibles = this.getOfficeTypeApps(office.type);
            let filtered = this.apps.filter((a) => {
              return possibles.includes(a.id);
            });

            filtered.forEach((el) => {
              if (types.includes(el)) {
                // ok
              } else {
                types.push(el);
              }
            });
          }
        } else {
          types.push({ id: 0, name: "Ogólna" });
        }
      } else if ((this.block.worktimeArea === null || this.block.worktimeArea === undefined) && this.block.appointment.device) {
        types.push({ id: 0, name: "Ogólna" });
      } else if (
        this.block.worktimeArea &&
        (this.block.worktimeArea.officeId === null ||
          (this.block.worktimeArea.employee > -1 && this.showAllAppTypes))
      ) {
        if (this.employee && this.employee.roles) {
          this.employee.roles.forEach((pos) => {
            let posObject = this.mGetRoleObject(pos);
            if (posObject) {
              let possibles = posObject.appointments;
              let filtered = this.apps.filter((a) => {
                return possibles.includes(a.id);
              });
              filtered.forEach((el) => {
                if (types.includes(el)) {
                  // ok
                } else {
                  types.push(el);
                }
              });
            }
          });
        }
      } else if (
        this.block.worktimeArea &&
        this.block.worktimeArea.employee > -1 &&
        this.showAllAppTypes
      ) {
        if (this.employeeWorktime && this.employeeWorktime.roles) {
          this.employeeWorktime.roles.forEach((pos) => {
            let posObject = this.mGetRoleObject(pos);
            if (posObject) {
              let possibles = posObject.appointments;
              let filtered = this.apps.filter((a) => {
                return possibles.includes(a.id);
              });
              filtered.forEach((el) => {
                if (types.includes(el)) {
                  // ok
                } else {
                  types.push(el);
                }
              });
            }
          });
        }
      } else if (
        (this.block.worktimeArea === null || this.block.worktimeArea === undefined) &&
        (this.block.appointment.officeId == null || (this.block.appointment.employee > -1 && this.showAllAppTypes))
      ) {
        if (this.employee && this.employee.roles) {
          this.employee.roles.forEach((pos) => {
            let posObject = this.mGetRoleObject(pos);
            if (posObject) {
              let possibles = posObject.appointments;
              let filtered = this.apps.filter((a) => {
                return possibles.includes(a.id);
              });
              filtered.forEach((el) => {
                if (types.includes(el)) {
                  // ok
                } else {
                  types.push(el);
                }
              });
            }
          });
        }
      } else if (this.block.worktimeArea) {
        //let area = this.getOfficeAreaObject(this.block.worktimeArea.office)
        let office = this.getOfficeObject(this.block.worktimeArea.officeId);
        if (office.type > -1) {
          let possibles = this.getOfficeTypeApps(office.type);
          let filtered = this.apps.filter((a) => {
            return possibles.includes(a.id);
          });

          filtered.forEach((el) => {
            if (types.includes(el)) {
              // ok
            } else {
              types.push(el);
            }
          });
        }
      } else if (this.block.appointment.officeId) {
        //let area = this.getOfficeAreaObject(this.block.worktimeArea.office)
        let office = this.getOfficeObject(this.block.appointment.officeId);
        if (office && office.type > -1) {
          this.focusOffice = office;
          let possibles = this.getOfficeTypeApps(office.type);
          let filtered = this.apps.filter((a) => {
            return possibles.includes(a.id);
          });

          filtered.forEach((el) => {
            if (types.includes(el)) {
              // ok
            } else {
              types.push(el);
            }
          });
        }
      }

      this.filteredAppTypes = types;

      if (this.block.appointment.type > -1 && this.filteredAppTypes.length > 0) {
        let appType = this.filteredAppTypes.find((x) => x.id === this.block.appointment.type)

        if (appType) {
          this.appointmentObject = appType
        }

        this.appointmentObjectTmp = this.appointmentObject.id
        this.onTypeChanged(this.appointmentObject.id);
      }
      else if (this.block.appointment.area > -1 && this.filteredAppTypes.length > 0) {
        var workConfig = this.configAreas.find(x => x.id === this.block.appointment.area)

        if (workConfig) {
          var defApp = this.filteredAppTypes.find(a => a.id === workConfig.defaultAppointment)

          if (defApp) {
            this.appointmentObject = defApp;
          }
          else {
            this.appointmentObject = this.filteredAppTypes[0];
          }
        }
        else {
          this.appointmentObject = this.filteredAppTypes[0];
        }

        this.appointmentObjectTmp = this.appointmentObject.id
        this.onTypeChanged(this.appointmentObject.id);
      }
    },

    printEReferral() {
      const options = {
        styles: [
          'http://localhost:8080/printStylesEReferral.css',
          'http://t1.digitmed.pl/printStylesEReferral.css',
        ]
      }
      this.$htmlToPaper("printEReferral", options)
      this.addIkzPrintEreferral()
    },

    editmodeCancel() {
      this.editmode = false
    },

    addIKZVisitEdit() {
      let selected = this.registrationIkz.find(x => x.internalId == 525)
      this.addIkz(selected)
    },

    addIKZVisitEditCancel() {
      let selected = this.registrationIkz.find(x => x.internalId == 617)
      this.addIkz(selected)
    },

    addIkzPrintDetails() {
      let selected = this.registrationIkz.find(x => x.internalId == 606)
      this.addIkz(selected)
    },

    addIkzPrintEreferral() {
      let selected = this.registrationIkz.find(x => x.internalId == 610)
      this.addIkz(selected)
    },

    addIkzReferralCancel() {
      let selected = this.registrationIkz.find(x => x.internalId == 599)
      this.addIkz(selected)
    },

    addIkzAppointmentCancel() {
      let selected = this.registrationIkz.find(x => x.internalId == 483)
      this.addIkz(selected)
    },

    changeOrderUpdate() {
      this.addIkzChangeOrder()
      this.getTicket()
    },

    addIkzChangeOrder() {
      let selected = this.registrationIkz.find(x => x.internalId == 488)
      this.addIkz(selected)
    },

    changeIKZUpdate() {
      this.addIkzChangeIkz() 
      this.getTicket()
    },

    addIkzChangeIkz() {
      let selected = this.registrationIkz.find(x => x.internalId == 489)
      this.addIkz(selected)
    },

    changeAPKolceUpdate() {
      this.addIkzChangeAPKolce()
      this.getTicket()
    },
    
    addIkzChangeAPKolce() {
      let selected = this.registrationIkz.find(x => x.internalId == 490)
      this.addIkz(selected)
    },

    changeAPKolceReferralUpdate() {
      this.addIkzChangeAPKolceReferral()
      this.getTicket()
    },

    addIkzChangeAPKolceReferral() {
      let selected = this.registrationIkz.find(x => x.internalId == 491)
      this.addIkz(selected)
    },

    changeDILOUpdate() {
      this.addIkzDILOUpdate()
      this.getTicket()
    },

    addIkzDILOUpdate() {
      let selected = this.registrationIkz.find(x => x.internalId == 492)
      this.addIkz(selected)
    },

    addIkzTicketAvailable() {
      let selected = this.registrationIkz.find(x => x.internalId == 631)
      this.addIkz(selected)
    },

    addIkz(selected) {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient ? this.patient.id : 0,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }
        
        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>