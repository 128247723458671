

export default {
    data: function () {
        return {

        }
    },

    methods: {
    }
}