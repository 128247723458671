<template>
  <div>
    <div class="p-2">
      <b-dropdown aria-role="list" ref="newGroupDropdown">
        <template #trigger>
          <b-button size="is-small" icon-right="plus" class="mr-1">Nowa grupa</b-button>
        </template>

        <div class="pl-3 pr-3 pt-1 pb-1">
          <b-field label="Typ grupy" label-position="on-border">
            <b-select expanded size="is-small" v-model="newUserGroupType" class="mr-1">
              <option :value="0">Wewnętrzna</option>
              <option :value="1">Prywatna rozmowa</option>
              <option :value="2">Prywatna z pacjentem</option>
              <option :value="3">Medyczna z pacjentem</option>
            </b-select>
          </b-field>
          <b-field label="Nazwa grupy" label-position="on-border">
            <b-input
              expanded
              maxlength="40"
              style="width: 14rem;"
              size="is-small"
              v-model="newUserGroupName"
              class="mr-1"
            />
          </b-field>
          <b-button
            size="is-small"
            :loading="loading"
            expanded
            @click="createGroup"
            icon-left="check"
            :disabled="newUserGroupName < 3"
            placeholder="Minimum 3 znaki"
          >Utwórz</b-button>
        </div>
      </b-dropdown>
      <b-button size="is-small" :loading="loading" @click="refreshMyGroups" icon-left="refresh"></b-button>
    </div>
    <!-- GROUPS TABLE -->
    <b-table :data="groups" hoverable :loading="loading" class="talk-groups-table" :selected.sync="selectedGroup">
      <b-table-column width="1rem" v-slot="props">
        <b-icon :icon="getIcon(props.row.type)" size="is-medium" type="is-secondary"></b-icon>
      </b-table-column>
      <b-table-column v-slot="props" sortable label="Grupy" field="name" cell-class="pt-1">
        <p style="cursor: default;line-height: 1.2rem;" class="mb-1">{{ props.row.name }}</p>
        <p style="cursor: default; font-size: 0.7rem;">{{ mDateTime(props.row.updated )}}</p>
      </b-table-column>
      <b-table-column v-slot="props" width="1rem" sortable field="updated">
        <b-tag rounded v-if="getUnread(props.row)" type="is-secondary">{{getUnread(props.row)}}</b-tag>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/commons'
import { Action } from '@/store/config_actions'

export default {
  name: 'SzokTalkGroups',
  mixins: [CommonMixins],

  data: function () {
    return {
      /** 
       * Loading indicator 
       */
      loading: false,

      /**  
       * Currently selected group 
       */
      selectedGroup: null,

      /** 
       * Name of group to be created 
       */
      newUserGroupName: '',

      /** 
       * Type of group to be created 
       */
      newUserGroupType: 0
    }
  },

  watch: {
    selectedGroup(val) {
      this.$emit('selected', val)
    },
  },

  computed: {
    /** 
     * My employee account.
     */
    me() { return this.$store.state.employee.me },

    /** 
     * User chat groups.
     */
    groups() { return this.$store.state.talk.groups },
  },

  methods: {
    getIcon(type) {
      let icon = 'chat'

      switch (type) {
        case 0: icon = "forum"
          break
        case 1: icon = "account-circle"
          break
        case 2: icon = "medical-bag"
          break
        case 3: icon = "hexagon-multiple"
          break
      }

      return icon
    },

    /**
     * Messages unread by me in group.
     */
    getUnread(group) {
      if (group && group.items) {
        return group.items.filter(x => this.isUnread(x)).length
      }
      else return 0
    },

    /**
     * Unread message marker.
     */
    isUnread(item) {
      if (item.readByEmployees) {
        let mark = item.readByEmployees.find(x => x.id == this.me.id)

        if (mark) return false
        else return true

      }
      else return true
    },

    /**
     * Downloads user groups.
     */
    refreshMyGroups() {
      this.loading = true
      this.$store
        .dispatch(Action.TALK_CREATE_CHAT_CONNECTION)
        .then((response) => {
          this.loading = false
          this.$emit('groups-updated', response)
        })
        .catch(error => {
          this.loading = false
          this.apiProblem(error);
        });
    },

    /**
     * Creates user group.
     */
    createGroup() {

      var payload = {
        name: this.newUserGroupName,
        type: this.newUserGroupType
      }

      this.loading = true
      this.$store
        .dispatch(Action.TALK_CRETE_GROUP, payload)
        .then(() => {
          this.loading = false
          this.newUserGroupName = ''

          // Get updated groups
          this.refreshMyGroups()

          // Hide dropdown
          if (this.$refs.newGroupDropdown) {
            this.$refs.newGroupDropdown.toggle()
          }
        })
        .catch(error => {
          this.loading = false
          this.apiProblem(error);
        });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
