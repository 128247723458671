import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        code: null,
        pending: false,

        requests: [],
        tmpExemptions: [],
        cancelledExemptions: [],
        exemptions: [],

        zusRequestsResult: {
            zusPatient: null,
            zusPatientAddress: null,
            zusLekarzData: null,
            zusCzlonekRodziny: null,
            zusPlatnikUbezpieczonego: null
        },

        trigger: 0
    },

    mutations: {
        [Mutation.ZUS_PASSWORD_CODE_ADD](state, payload) {
            state.code = payload
            state.pending = false
        },
        [Mutation.ZUS_PASSWORD_REQUEST](state, payload) {
            state.pending = payload
        },
        [Mutation.ZUS_GUID_ADD](state, payload) {
            state.requests.push(payload)
        },
        [Mutation.ZUS_UPDATE_REQUEST](state, payload) {
            let obj = state.requests.find(p => p.guid == payload.guid)
            let index = state.requests.indexOf(obj)
            if (index > -1) {
                state.requests[index] = payload
            }
            state.trigger++
        },
        [Mutation.ZUS_REMOVE_REQUEST](currentState, payload) {
            let index = currentState.requests.indexOf(payload)

            if (index > -1) {
                currentState.requests.splice(index, 1)
            }
        },
        [Mutation.ZUS_ADD_TMP_EXEMPTION](currentState, payload) {
            currentState.tmpExemptions.push(payload)
        },
        [Mutation.ZUS_UPDATE_TMP_EXEMPTION](currentState, payload) {
            currentState.tmpExemptions[payload.index] = payload.exemption
            currentState.trigger++
        },
        [Mutation.ZUS_REMOVE_TMP_EXEMPTION](currentState, payload) {
            let index = currentState.tmpExemptions.indexOf(payload)

            if (index > -1) {
                currentState.tmpExemptions.splice(index, 1)
            }
        },
        [Mutation.ZUS_ADD_EXEMPTION](currentState, payload) {
            currentState.exemptions.push(payload)
        },
        [Mutation.ZUS_ADD_EXEMPTION_FOR_CANCEL](currentState, payload) {
            currentState.cancelledExemptions.push(payload)
        },
        [Mutation.ZUS_TRIGGER_UP](state) {
            state.trigger++
        },
        [Mutation.ZUS_DATA_UPDATE](state, payload) {
            if (payload.zusAction == 0) {
                state.zusRequestsResult.zusPatient = payload.result.zusPatient
                state.zusRequestsResult.zusPatientAddress = payload.result.zusPatientAddress
            } else if (payload.zusAction == 1) {
                state.zusRequestsResult.zusPlatnikUbezpieczonego = payload.result.zusPlatnikUbezpieczonego
            } else if (payload.zusAction == 2) {
                if (payload && payload.result && payload.result.zusLekarzData && payload.result.zusLekarzData.miejsceWykonywaniaZawodu === null) {
                    payload.result.zusLekarzData.miejsceWykonywaniaZawodu = []
                }
                state.zusRequestsResult.zusLekarzData = payload.result.zusLekarzData
            } else if (payload.zusAction == 3) {
                state.zusRequestsResult.zusCzlonekRodziny = payload.result.zusCzlonekRodziny
            }
            state.trigger++
        },
        [Mutation.ZUS_RESET](state) {
            state.code = null
            state.pending = false
            state.requests = []
            state.tmpExemptions = []
            state.exemptions = []
            state.cancelledExemptions = []
            state.zusRequestsResult = {
                zusPatient: null,
                zusPatientAddress: null,
                zusLekarzData: null,
                zusCzlonekRodziny: null,
                zusPlatnikUbezpieczonego: null
            }
        },
        [Mutation.ZUS_RESET_2](state) {
            state.pending = false
            state.requests = []
            // state.tmpExemptions = []
            // state.exemptions = []
            state.zusRequestsResult = {
                zusPatient: null,
                zusPatientAddress: null,
                zusLekarzData: null,
                zusCzlonekRodziny: null,
                zusPlatnikUbezpieczonego: null
            }
        },
        [Mutation.ZUS_RESET_3](state) {
            state.pending = false
            state.requests = []
            // state.tmpExemptions = []
            // state.exemptions = []
            state.zusRequestsResult = {
                zusPatient: null,
                zusPatientAddress: null,
                zusLekarzData: null,
                zusCzlonekRodziny: null,
                zusPlatnikUbezpieczonego: null
            }
        }
    },

    actions: {
        [Action.ZUS_CHECK_PASSWORD](context, code) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.ZUS_CHECK_PASSWORD,
                        data: code,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        if (response.data.p12ResultEnum === 0) {
                            context.commit(Mutation.ZUS_PASSWORD_CODE_ADD, code)
                        }
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.ZUS_ENTRY_ACTION](context, exemption) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        responseType: 'text',//only for string value
                        transformResponse: [data => data],//only for string value
                        url: Apis.ZUS_ENTRY_ACTION,
                        data: exemption,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.ZUS_GET_RESULT](context, guid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.ZUS_GET_RESULT,
                        data: guid,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        }
    }
}