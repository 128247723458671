// import { Action } from '../config_actions'
// import { Mutation } from '../config_mutations'
// import { Apis } from '../config_apis'
// import axios from 'axios'
// import moment from 'moment'


export default {
    namespaced: false,

    state: {
        tmpData: [],
        appointments: [],
        patients: [],
        ongoingAppointment: null,
        focusAppointment: null
    },

    mutations: {

        // [Mutation.POZ_TMP_SAVE](state, payload) {
        //     state.tmpData.push(payload)
        // },

        // [Mutation.POZ_ADD_EXAMINATION](state, payload) {
        //     let index = state.currentExaminations.findIndex(u => u.examination.id === payload.examination.id)
        //     if (index < 0) {
        //         state.currentExaminations.push(payload)
        //     }
        // },

        // [Mutation.POZ_SET_APPOINTMENTS](state, payload) {
        //     state.appointments = payload
        // },

        // [Mutation.POZ_SET_PATIENTS](state, payload) {
        //     state.patients = payload
        // },

        // [Mutation.POZ_SET_ONGOING_APPOINTMENT](state, payload) {
        //     state.ongoingAppointment = payload
        // },

        // [Mutation.POZ_SET_FOCUS_APPOINTMENT](state, payload) {
        //     if (state.patients.length > 0) {
        //         let patient = state.patients.find(p => p.id === payload.patientId)

        //         if (patient) {
        //             payload.patient = patient
        //             state.focusAppointment = payload
        //         }
        //     }

        // },

        // [Mutation.POZ_CLEAR_ONGOING_APPOINTMENT](state) {
        //     state.ongoingAppointment = null
        // },

        // [Mutation.POZ_SET_FOCUS_APPOINTMENT_FROM_LIST](state) {
        //     if (state.appointments.length > 0) {
        //         state.focusAppointment = state.appointments[0]
        //         state.focusAppointment.patient = {}

        //         if (state.patients.length > 0) {
        //             let patient = state.patients.find(p => p.id === state.focusAppointment.patientId)

        //             if (patient) {
        //                 state.focusAppointment.patient = patient
        //             }
        //         }
        //     }
        // },

        // [Mutation.POZ_REMOVE_EXAMINATION](state, payload) {
        //     let index = state.currentExaminations.indexOf(payload)
        //     if (index > -1) {
        //         state.currentExaminations.splice(index, 1)
        //     }
        // },

        // [Mutation.POZ_RESET_EXAMINATIONS](state) {
        //     state.currentExaminations = []
        // },

        // [Mutation.POZ_RESET](state) {
        //     state.currentExaminations = []
        //     state.appointments = []
        //     state.patients = []
        //     state.ongoingAppointment = null
        //     state.focusAppointment = null
        // }
    },

    actions: {
        /** Attempt get all workers. Uses GET request without parameter */
        // [Action.POZ_BEGIN_APPOINTMENT](context) {
        //     return new Promise((resolve, reject) => {

        //         if (context.state.focusAppointment) {
        //             context.commit(Mutation.POZ_SET_ONGOING_APPOINTMENT, context.state.focusAppointment)
        //             resolve()
        //         }

        //         reject()

        //         // let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         // if (token != null) {
        //         //     axios({
        //         //         method: 'get',
        //         //         url: Apis.WORKER_GET_ALL,
        //         //         headers: { Authorization: "Bearer " + token }
        //         //     }).then(response => {
        //         //         context.commit(Mutation.WORKER_PUSH_ALL, response.data)
        //         //         resolve();
        //         //     }).catch(error => { reject(error) });
        //         // } else reject('401')
        //     })
        // },


        // [Action.SCHEDULE_GET_APPOINTMENTS_FOR_WORKER](context, id) {

        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         if (token != null) {
        //             axios({
        //                 method: 'get',
        //                 url: Apis.SCHEDULE_GET_APPOINTMENTS_FOR_WORKER.replace('{id}', id),
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 if (response.data.patients) {
        //                     context.commit(Mutation.POZ_SET_PATIENTS, response.data.patients)
        //                 }

        //                 if (response.data.appointments) {
        //                     if (response.data.appointments.length > 0) {
        //                         let ordered = response.data.appointments.sort((a, b) => {
        //                             return moment(a.date).isBefore(moment(b.date)) ? -1 : 1
        //                         })
        //                         let orderedDay = ordered[0].appointments.sort((a, b) => {
        //                             return moment(a.dateTimeInfoStart).isBefore(moment(b.dateTimeInfoStart)) ? -1 : 1
        //                         })

        //                         context.commit(Mutation.POZ_SET_APPOINTMENTS, orderedDay)
        //                         context.commit(Mutation.POZ_SET_FOCUS_APPOINTMENT_FROM_LIST)
        //                     }
        //                 }

        //                 resolve(response);
        //             }).catch(error => {
        //                 reject(error)
        //             });
        //         } else {
        //             reject('401')
        //         }
        //     })
        // },
    }
}