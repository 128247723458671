<template>
  <div>
    <b-field
      label-position="on-border"
      label="Placówka"
      class="white-label card ml-3 mr-3 mt-1 mb-2 pl-3 pr-3 pb-2 has-text-weight-bold"
      style="padding-top: 0.55rem;"
    >
      <p v-if="currentFacility" class="has-text-primary">{{currentFacility.name}}</p>
      <p v-else class="has-text-danger">Brak aktualnej placówki</p>
    </b-field>
    <a @click="isComponentModalActive = true" class="dropdown-item">
      <b-icon icon="home-city-outline" size="is-small" class="menu-larger-icon"></b-icon>Zmień placówkę
    </a>

    <!-- FEEDBACK MODAL -->
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      @after-enter="focus"
      scroll="keep"
    >
      <ContentPanel :rounded="true" style="min-width: 40vw;" title="Wybierz aktualne miejsce pracy" icon="home">
        <template slot="addon">
          <b-button
            :disabled="selected === null"
            icon-left="check"
            size="is-small"
            type="is-secondary"
            @click="setFacility"
            class="mr-1"
          >Zatwierdź aktualną placówkę</b-button>
          <b-button
            size="is-small"
            type="is-danger"
            icon-left="close"
            @click="isComponentModalActive = false"
          >Anuluj</b-button>
        </template>
        <b-field label-position="on-border" label="Lokalizacja">
          <b-autocomplete
            v-model="facilityName"
            :open-on-focus="true"
            :data="filteredFacilities"
            field="joined"
            @select="(option) => changeFacility(option)"
            placeholder="Wybierz"
            expanded
            ref="autofac"
          ></b-autocomplete>
          <p class="control">
            <b-button icon-left="close" @click="clearFacility"></b-button>
          </p>
        </b-field>
      </ContentPanel>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from '../../mixins/commons'
// import ContentPanel from '@/components/tools/ContentPanel'
import { Action } from '../../store/config_actions';
import { Mutation } from '../../store/config_mutations';

export default {
  name: 'AppointmentHistory',
  mixins: [CommonMixins],

  components: {
  //  ContentPanel
  },

  props: {
  },

  watch: {
    facilities() {
      if (this.me && this.me.parentFacility > -1) {
        if (this.facilities) {
          let parent = this.facilities.find(f => f.internalId === this.me.parentFacility)
          if (parent) {
            this.selected = parent
            this.facilityName = parent.joined
            this.$store.commit(Mutation.CONFIG_SET_CURRENT_FACILITY, this.selected)
          }
        }
      }
    }
  },

  data: function () {
    return {
      isComponentModalActive: false,
      facilityName: '',
      selected: null
    }
  },

  mounted() {
    this.getData()
  },

  computed: {
    facilities() {
      return this.$store.state.config.facilities;
    },
    me() {
      return this.$store.state.employee.me;
    },
    perms() {
      return this.$store.state.employee.permits;
    },

    currentFacility() {
      return this.$store.state.config.currentFacility;
    },

    filteredFacilities() {
      if (!this.facilities) {
        return []
      }

      return this.facilities.filter((option) => {
        return (option.joined ? option.joined.toString().toLowerCase().indexOf(this.facilityName.toLowerCase()) >= 0 : true);
      });
    },
  },

  methods: {
    clearFacility() {
      this.selected = null
      this.$store.commit(Mutation.CONFIG_SET_CURRENT_FACILITY, this.selected)
      this.facilityName = ''
    },

    getData() {
      this.$store
        .dispatch(Action.FACILITIES_GET_ALL)
        .then(() => {
          // this.trigger()
        }).catch(error => {
          this.apiProblem(error);
        })
    },

    trigger() {
      if (this.perms.skipFacility) {
        // lucky
      }
      else {
        this.isComponentModalActive = true
      }
    },

    focus() {
      if (this.$refs.autofac) {
        //  this.$refs.autofac.focus()
      }
    },

    changeFacility(item) {
      if (item) {
        this.selected = item
      }
    },

    setFacility() {
      this.$store.commit(Mutation.CONFIG_SET_CURRENT_FACILITY, this.selected)
      this.isComponentModalActive = false
    }
  }
}
</script>

<style scoped lang="scss">
</style>
