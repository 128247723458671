<template>
  <div>
    <a class="dropdown-item" style="width:20rem;" aria-role="listitem" @click="isComponentModalActive = true">
      <b-icon icon="draw" size="is-small" class="menu-larger-icon"></b-icon>
      <span>{{active ? 'Urządzenie Link połączone':'Połącz z Link'}}</span>
    </a>

    <!-- FEEDBACK MODAL -->
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      scroll="keep"
      @after-enter="pauseInterval"
      @after-leave="resumeInterval"
    >
      <div class="modal-card" style="overflow: visible;width:auto;">
        <header class="modal-card-head has-background-kin">
          <p class="modal-card-title has-text-light is-size-5">
            <b-icon icon="draw" class="mr-3"></b-icon>Połącz z aplikacją Link
          </p>
        </header>
        <section class="modal-card-body" style="overflow: visible;">
          <b-field label-position="on-border" label="Numer Link">
            <b-input v-model="device"></b-input>
            <p class="control" v-if="device && device.length > 0">
              <b-tooltip type="is-light" label="Usuń kod urządzenia">
                <b-button type="is-danger" outlined icon-left="close" @click="disconnect"></b-button>
              </b-tooltip>
            </p>
            <p class="control">
              <b-button
                :loading="loading"
                @click="checkDevice(true)"
                :type="color"
              >{{active? "Połączono" : "Połącz"}}</b-button>
            </p>
          </b-field>
        </section>
        <footer class="modal-card-foot pt-2 pb-2">
          <b-button size="is-small" @click="isComponentModalActive = false">Zamknij</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from '../../mixins/commons'
import { Action } from '../../store/config_actions'
import { Cookies } from '../../store/config_cookies'
import { Mutation } from '../../store/config_mutations'

export default {
  name: 'LinkConnector',
  mixins: [CommonMixins],

  props: {
  },

  data: function () {
    return {
      loading: false,
      device: null,
      active: false,
      isComponentModalActive: false,
      intervalCheck: null,
      color: 'is-primelight',
    }
  },

  mounted() {
    let dev = this.$cookies.get(Cookies.LINK_DEVICE_NUMBER);

    if (dev) {
      this.device = dev
      this.$store.commit(Mutation.CONFIG_SET_LINK, dev);
      this.setupInterval()
    }
  },

  computed: {
    link() { return this.$store.state.config.link },
    links() { return this.$store.state.config.linkState }
  },

  methods: {
    resumeInterval() {
      this.setupInterval()
    },

    pauseInterval() {
      clearInterval(this.intervalCheck)
    },

    setupInterval() {
      if (this.link && this.link.length > 0) {
        const self = this;
        clearInterval(this.intervalCheck)
        this.intervalCheck = setInterval(function () {
          self.checkDevice()
        }, 5000)
      }
    },

    disconnect() {
      this.$store.commit(Mutation.CONFIG_SET_LINK, '');
      this.$cookies.remove(Cookies.LINK_DEVICE_NUMBER);
      clearInterval(this.intervalCheck)
      this.device = ''
      this.active = false
      this.color = 'is-primelight'
    },

    checkDevice(showMessage) {

      if (this.device && this.device.length > 0) {
        this.$store.commit(Mutation.CONFIG_SET_LINK, this.device);
        this.$cookies.set(Cookies.LINK_DEVICE_NUMBER, this.device);

        this.loading = true
        this.$store
          .dispatch(Action.HUB_CHECK_DEVICE, this.device)
          .then((response) => {
            this.active = response
            this.loading = false
            this.setupInterval()
            this.$store.commit(Mutation.CONFIG_SET_LINK_STATE, response);

            if (response) {
              this.color = 'is-success'
              if (showMessage) this.successSnackbar('Urządzenie połączone')
            }
            else {
              this.color = 'is-danger'
              if (showMessage) this.dangerSnackbar('Urządzenie niedostępne')
            }
          })
          .catch(() => {
            // this.apiProblem(error);
            this.loading = false
          });
      }
    },
  }
}
</script>

<style scoped lang="scss">
</style>
