import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        patient: null,
        ewus: null,
        icds: [],
        drugs: [],
        prescriptions: [],
        empPrescriptions: [],
        employeePrescriptions: [],

        referrals: [],
        empReferrals: [],
        employeeReferrals: [],
        exemptions: [],

        trigger: 0,

        zusData: null,

    },

    mutations: {
        [Mutation.TEST_TRIGGER_UP](currentState) {
            currentState.trigger++
        },
        [Mutation.TEST_SET_PATIENT](currentState, payload) {
            currentState.patient = payload
        },
        [Mutation.TEST_SET_EWUS](currentState, payload) {
            currentState.ewus = payload
        },

        [Mutation.TEST_ADD_ICD](currentState, payload) {
            currentState.icds.push(payload)
        },
        [Mutation.TEST_REMOVE_ICD](currentState, payload) {
            let index = currentState.icds.indexOf(payload)

            if (index > -1) {
                currentState.icds.splice(index, 1)
            }
        },

        [Mutation.TEST_ADD_DRUG](currentState, payload) {
            currentState.drugs.push(payload)
        },
        [Mutation.TEST_REMOVE_DRUG](currentState, payload) {
            let index = currentState.drugs.indexOf(payload)

            if (index > -1) {
                currentState.drugs.splice(index, 1)
            }
        },

        [Mutation.TEST_REMOVE_ALL_DRUGS](currentState) {
            currentState.drugs = []
        },



        // ************** REFERRALS ********************

        [Mutation.TEST_EMP_REFFERALS](currentState, payload) {
            currentState.empReferrals = payload
        },

        [Mutation.TEST_EMPLOYEE_REFFERALS](currentState, payload) {
            currentState.employeeReferrals = payload
        },

        [Mutation.TEST_UPDATE_REFERRALS](currentState, payload) {

            currentState.empReferrals = payload

            payload.forEach(refServ => {
                var local = currentState.referrals.find(r => r.guid === refServ.guid)

                if (local != null) {
                    local.status = refServ.workStatus
                }
            });
        },

        [Mutation.TEST_UPDATE_REFERRAL](currentState, payload) {
            var local = currentState.referrals.indexOf(payload.item)

            if (local > -1) {
                currentState.referrals[local].status = 0
                currentState.referrals[local].guid = payload.guid
            }
        },

        [Mutation.TEST_ADD_REFERRAL](currentState, payload) {
            currentState.referrals.push(payload)
        },
        [Mutation.TEST_REMOVE_REFERRAL](currentState, payload) {
            let index = currentState.referrals.indexOf(payload)

            if (index > -1) {
                currentState.referrals.splice(index, 1)
            }
        },

        // ************** PRESCRIPTIONS ********************

        [Mutation.TEST_EMP_PRESCRIPTIONS](currentState, payload) {
            currentState.empPrescriptions = payload
        },

        [Mutation.TEST_EMPLOYEE_PRESCRIPTIONS](currentState, payload) {
            currentState.employeePrescriptions = payload
        },

        [Mutation.TEST_UPDATE_PRESCRIPTIONS](currentState, payload) {

            currentState.empPrescriptions = payload

            payload.forEach(refServ => {
                var local = currentState.prescriptions.find(r => r.guid === refServ.guid)

                if (local != null) {
                    local.status = refServ.workStatus
                }
            });
        },

        [Mutation.TEST_UPDATE_PRESCRIPTION](currentState, payload) {
            var local = currentState.prescriptions.indexOf(payload.item)

            if (local > -1) {
                currentState.prescriptions[local].status = 0
                currentState.prescriptions[local].guid = payload.guid
            }
        },

        [Mutation.TEST_ADD_PRESCRIPTION](currentState, payload) {
            currentState.prescriptions.push(payload)

            for (let index = 0; index < currentState.drugs.length; index++) {
                const drug = currentState.drugs[index]
                drug.isUsed = true
            }

            currentState.trigger++
        },

        [Mutation.TEST_REMOVE_PRESCRIPTION](currentState, payload) {
            let index = currentState.prescriptions.indexOf(payload)

            if (index > -1) {
                currentState.prescriptions.splice(index, 1)
            }
        },

        // ************** EXEMPTIONS ********************

        [Mutation.TEST_ADD_EXEMPTION](currentState, payload) {
            currentState.exemptions.push(payload)
        },
        [Mutation.TEST_REMOVE_EXEMPTION](currentState, payload) {
            let index = currentState.exemptions.indexOf(payload)

            if (index > -1) {
                currentState.exemptions.splice(index, 1)
            }
        },



        [Mutation.TEST_RESET](currentState) {
            currentState.patient = null
            currentState.ewus = null
            currentState.icds = []
            currentState.drugs = []
            currentState.prescriptions = []
            currentState.referrals = []
            currentState.empReferrals = []
            currentState.employeeReferrals = []
            currentState.exemptions = []
        },
    },

    actions: {
        // PATIENT SEARCH
        [Action.TEST_PATIENT_SEARCH](context, pesel) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEST_PATIENT.replace('{pesel}', pesel),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.TEST_SET_PATIENT, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        // ARCHIVE SEARCH
        // [Action.TPK_ARCHIVE_SEARCH](context, pesel) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         if (token != null) {
        //             axios({
        //                 method: 'get',
        //                 url: Apis.TPK_ARCHIVE_SEARCH.replace('{id}', pesel),
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 resolve(response.data);
        //             }).catch(error => { reject(error) })
        //         } else reject('401')
        //     })
        // },

        // EWUS SEARCH
        [Action.TEST_EWUS_SEARCH](context, pesel) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TEST_EWUS.replace('{pesel}', pesel),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.TEST_SET_EWUS, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        // *********************** PRESCRIPTION NEW ***********************

        [Action.TPK_RECEIPT_SEND](context, prescription) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        responseType: 'text',
                        transformResponse: [data => data],
                        url: Apis.TPK_RECEIPT_SEND,
                        data: prescription,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },

        [Action.TPK_RECEIPT_GET](context, prescription) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TPK_RECEIPT_GET,
                        data: prescription,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },

        // *********************** REFERRAL NEW ***********************

        [Action.TPK_REFERRAL_SEND](context, prescription) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        responseType: 'text',//only for string value
                        transformResponse: [data => data],//only for string value
                        url: Apis.TPK_REFERRAL_SEND,
                        data: prescription,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },

        [Action.TPK_REFERRAL_GET](context, referral) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TPK_REFERRAL_GET,
                        data: referral,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },

        [Action.TEST_CREATE_PRESCRIPTION](context, prescription) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TEST_PRESCRIPTION_SEND,
                        data: prescription,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.TEST_UPDATE_PRESCRIPTION, { item: prescription, guid: response.data })
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },


        [Action.TEST_RESEND_PRESCRIPTION](context, prescription) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEST_PRESCRIPTION_RESEND.replace('{guid}', prescription.guid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.TEST_UPDATE_PRESCRIPTION, { item: prescription, guid: prescription.guid })
                        resolve(response.data);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        [Action.TEST_ASK_PRESCRIPTIONS_BY_PATIENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEST_PRESCRIPTIONS_ASK_BY_PATIENT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.TEST_UPDATE_PRESCRIPTIONS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        [Action.TEST_GET_PRESCRIPTIONS_BY_WORKER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEST_PRESCRIPTIONS_ALL_BY_WORKER.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.TEST_EMPLOYEE_PRESCRIPTIONS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        [Action.TEST_GET_COMPLETED_PRESCRIPTION](context, guid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEST_PRESCRIPTION_GET_COMPLETED.replace('{guid}', guid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        // [Action.TPK_EDM_RESULT_SEND](context, payload) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
        //         if (token != null) {
        //             axios({
        //                 method: 'post',
        //                 url: Apis.TPK_EDM_RESULT_SEND,
        //                 data: payload,
        //                 headers: { Authorization: "Bearer " + token }
        //             }).then(response => {
        //                 resolve(response.data)
        //             }).catch(error => {
        //                 reject(error)
        //             });
        //         } else reject('401')
        //     })
        // },

        // *********************** REFERRALS ***********************

        [Action.TEST_CREATE_REFERRAL](context, referral) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TEST_REFERRAL_SEND,
                        data: referral.payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.TEST_UPDATE_REFERRAL, { item: referral, guid: response.data })
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },

        [Action.TEST_ASK_REFERRALS_BY_PATIENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEST_REFERRALS_ASK_BY_PATIENT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.TEST_UPDATE_REFERRALS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        [Action.TEST_GET_REFERRALS_BY_WORKER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEST_REFERRALS_ALL_BY_WORKER.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.TEST_EMPLOYEE_REFFERALS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        [Action.TEST_GET_COMPLETED_REFERRAL](context, guid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEST_REFERRAL_GET_COMPLETED.replace('{guid}', guid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },


        // *********************** EXEMPTIONS ***********************

        [Action.TEST_ZUS_ACTION](context, object) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.TEST_ZUS_ACTION,
                        data: object,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.TEST_ZUS_GET_WORK_STATUS_TASKS_BY_GUID](context, guid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEST_ZUS_GET_WORK_STATUS_TASKS_BY_GUID.replace('{guid}', guid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },
        [Action.TEST_ZUS_GET_COMPLETED_TASKS_BY_GUID](context, guid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEST_ZUS_GET_COMPLETED_TASKS_BY_GUID.replace('{guid}', guid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        // *********************** EDM ***********************

        [Action.EDM_VISITSUMMARY_SEND](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        responseType: 'text',//only for string value
                        transformResponse: [data => data],//only for string value
                        url: Apis.EDM_VISITSUMMARY_SEND,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },

        [Action.EDM_VISITSUMMARY_GET](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EDM_VISITSUMMARY_GET,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },

        //EDM_VISITSUMMARY_GET_DIAGNOSIS
        [Action.EDM_VISITSUMMARY_GET_DIAGNOSIS](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.EDM_VISITSUMMARY_GET_DIAGNOSIS,
                        params: {
                            id: id
                        },
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },

        [Action.GENERATE_PDF_FROM_HTML](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        responseType: 'blob',
                        url: Apis.GENERATE_PDF_FROM_HTML,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.GOLD_TOOLS_PDF_FROM_HTML](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        responseType: 'blob',
                        url: Apis.GOLD_TOOLS_PDF_FROM_HTML,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // *********************** IPOM ***********************
        [Action.IPOM_SEND](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        responseType: 'text',//only for string value
                        transformResponse: [data => data],//only for string value
                        url: Apis.IPOM_SEND,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },

        [Action.IPOM_GET](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.IPOM_GET,
                        headers: { Authorization: "Bearer " + token },
                        params: {
                            id: payload.id
                        }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });


                } else reject('401')
            })
        },

    }
}