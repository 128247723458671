export const Cookies = {
    SZOK_USER_AUTH: "SZOK_USER_AUTH",
    SZOK_ACTIVITY: "SZOK_ACTIVITY",
    SZOK_LANGUAGE: "SZOK_LANGUAGE",
    SZOK_COOKIE_ACCEPTED: "SZOK_COOKIE_ACCEPTED",
    UAC_ALERT: "UAC_ALERT",
    TIMEOUT_ALERT: "TIMEOUT_ALERT",
    DEVICE_ID: "DEVICE_ID",
    LINK_DEVICE_NUMBER: "LINK_DEVICE_NUMBER",
    RIS_APPOINTMENTS_FILTERS: "RIS_APPOINTMENTS_FILTERS",
    SCHEDULE_MULTICHOICE_RIS: "SCHEDULE_MULTICHOICE_RIS",
    SCHEDULE_MULTICHOICE_REG: "SCHEDULE_MULTICHOICE_REG",


    LOCALS_SCHEDULE_APP_TYPE_VISIBILITY: "LOCALS_SCHEDULE_APP_TYPE_VISIBILITY_{eid}",
    LOCALS_SCHEDULE2_SETTINGS: "LOCALS_SCHEDULE2_SETTINGS_{eid}",
};