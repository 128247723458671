<template>
  <div>
    <b-button
      type="is-secondary"
      @click="searchModal = true"
      :icon-left="buttonIcon"
      :size="size"
      >{{ buttonPharse }}</b-button
    >
    <b-modal
      v-model="searchModal"
      has-modal-card
      aria-role="dialog"
      aria-modal
      trap-focus
      :destroy-on-hide="false"
      scroll="keep"
      @after-enter="focus"
      :can-cancel="false"
    >
      <template>
        <div class="modal-card" style="overflow: visible; width: auto">
          <header class="modal-card-head has-background-kin">
            <p class="modal-card-title has-text-light">Wyszukiwanie pacjenta</p>
            <button
              type="button"
              class="delete is-medium"
              @click="searchModal = false"
            />
          </header>
          <section class="modal-card-body" style="overflow: visible">
            <div>
              <div>
                <b-field grouped class="mb-3">
                  <b-field label="Typ wyszukiwania" label-position="on-border">
                    <b-select
                      v-model="searchType"
                      :placeholder="t('common.choose')"
                    >
                      <option :value="0">PESEL</option>
                      <option :value="1">Nazwisko</option>
                      <option :value="3">Nr dokumentu</option>
                    </b-select>
                    <b-input
                      v-model="pharse"
                      ref="searchInput"
                      placeholder="Wyszukaj"
                      @keydown.native.enter="search"
                      v-if="searchType == 0 || searchType == 3"
                    ></b-input>
                    <b-input
                      v-model="pharse"
                      ref="searchInput"
                      placeholder="Wyszukaj"
                      v-else
                    ></b-input>
                  </b-field>
                  <b-field
                    v-if="searchType != 0 && searchType != 3"
                    label="Dodatkowe kryterium"
                    label-position="on-border"
                  >
                    <b-select
                      v-model="searchType2"
                      :placeholder="t('common.choose')"
                    >
                      <option :value="4">Telefon</option>
                      <option :value="5">Data urodzenia</option>
                    </b-select>
                    <b-input
                      v-if="searchType2 === 4"
                      v-model="pharse2"
                      placeholder="Wyszukaj"
                      @keydown.native.enter="search2"
                    ></b-input>
                    <b-input
                      v-if="searchType2 === 5"
                      v-model="pharse2"
                      @input="controlDateInput"
                      placeholder="DD-MM-RRRR"
                      @keydown.native.enter="search2"
                      maxlength="10"
                      :has-counter="false"
                    ></b-input>
                  </b-field>

                  <b-button
                    @click="search"
                    :loading="loading"
                    icon-left="magnify"
                    type="is-primary"
                    v-if="searchType == 0 || searchType == 3"
                    :disabled="pharse.length < 1"
                    >Szukaj</b-button
                  >

                  <b-button
                    @click="search2"
                    :loading="loading"
                    icon-left="magnify"
                    v-else
                    type="is-primary"
                    :disabled="pharse.length < 1 || pharse2.length < 1"
                    >Szukaj</b-button
                  >

                  <b-button
                    @click="searchModal = false"
                    icon-left="magnify"
                    class="ml-3"
                    type="is-secondary"
                    v-if="labSearch && searchCompletedOnce"
                    >Dodaj nowego pacjenta</b-button
                  >
                </b-field>

                <p v-if="labSearch" class="mb-1 has-text-weight-semibold">
                  Baza pacjentów:
                </p>

                <b-table
                  :data="patients ? patients : []"
                  narrowed
                  hoverable
                  bordered
                  detail-key="id"
                  :loading="loading"
                  :row-class="
                    (row, index) =>
                      row.domainId != me.domainId && 'has-text-grey'
                  "
                >
                  <b-table-column width="1rem" v-slot="props" cell-class="hand">
                    <b-button
                      icon-left="account-check"
                      size="is-small"
                      :disabled="labSearch && labLoading"
                      :type="
                        props.row.domainId == me.domainId ? 'is-secondary' : ''
                      "
                      @click="selectPatient(props.row)"
                      >Wybierz</b-button
                    >
                  </b-table-column>
                  <b-table-column
                    field="lastName"
                    searchable
                    sortable
                    label="Nazwisko"
                    v-slot="props"
                    >{{ props.row.lastName }}</b-table-column
                  >
                  <b-table-column
                    field="firstName"
                    searchable
                    sortable
                    label="Imię"
                    v-slot="props"
                    >{{ props.row.firstName }}</b-table-column
                  >
                  <b-table-column
                    field="pesel"
                    searchable
                    label="PESEL lub nr dokumentu"
                    v-slot="props"
                  >
                    <span v-if="props.row.pesel">{{ props.row.pesel }}</span>
                    <span v-else>{{ props.row.identityDocumentNumber }}</span>
                  </b-table-column>
                  <b-table-column
                    field="teleAddressTelephoneNumber"
                    searchable
                    sortable
                    label="Inne"
                    v-slot="props"
                    cell-class="is-size-7"
                  >
                    <p>ur. {{ mDate(props.row.dateOfBirth) }}</p>
                    <p>tel. {{ props.row.teleAddressTelephoneNumber }}</p>
                    <p>
                      {{ props.row.addressStreet }}
                      {{ props.row.addressHouseNumber }}
                      <span
                        v-if="
                          props.row.addressApartmentNumber != null &&
                          props.row.addressApartmentNumber != ''
                        "
                        >/{{ props.row.addressApartmentNumber }}</span
                      >
                    </p>
                    <p>
                      {{ props.row.addressZipCode }},
                      {{ props.row.addressCity }}
                    </p>
                  </b-table-column>
                  <b-table-column
                    field="domainId"
                    label="Obszar"
                    v-slot="props"
                  >
                    <b-tag
                      class="hand"
                      :type="
                        props.row.domainId == me.domainId
                          ? 'is-secondary'
                          : 'is-warning'
                      "
                      >{{ mGetDomainName(props.row.domainId) }}</b-tag
                    >
                  </b-table-column>
                  <template slot="detail" slot-scope="props">
                    <pre>{{ props.row }}</pre>
                    <PatientPanelModifier
                      v-if="props.row"
                      :initial="props.row"
                      :key="props.row.id"
                      :readonly="true"
                    ></PatientPanelModifier>
                  </template>
                  <template slot="empty">
                    <section class="pt-5 pb-5">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon
                            icon="account-reactivate"
                            size="is-large"
                          ></b-icon>
                        </p>
                        <p>Brak pacjentów</p>
                      </div>
                    </section>
                  </template>
                </b-table>

                <p v-if="labSearch" class="mt-2 mb-1 has-text-weight-semibold">
                  Pacjenci z wcześniejszych badań:
                </p>

                <b-table
                  v-if="labSearch && searchType != 1"
                  :data="examinations ? examinations : []"
                  narrowed
                  hoverable
                  bordered
                  detail-key="id"
                  :loading="labLoading"
                  :row-class="
                    (row, index) =>
                      row.domainId != me.domainId && 'has-text-grey'
                  "
                >
                  <b-table-column width="1rem" v-slot="props" cell-class="hand">
                    <b-tooltip
                      v-if="loading || patients.length > 0"
                      label="Należy wybrać pacjenta z bazy pacjentów (powyższa tabela)"
                      position="is-right"
                    >
                      <b-button
                        icon-left="account-check"
                        size="is-small"
                        outlined
                        :type="
                          props.row.domainId == me.domainId
                            ? 'is-secondary'
                            : ''
                        "
                        @click="selectExamination(props.row)"
                        disabled
                        >Wybierz</b-button
                      >
                    </b-tooltip>
                    <b-button
                      v-else
                      icon-left="account-check"
                      size="is-small"
                      :type="
                        props.row.domainId == me.domainId ? 'is-secondary' : ''
                      "
                      @click="selectExamination(props.row)"
                      >Wybierz</b-button
                    >
                  </b-table-column>
                  <b-table-column
                    field="lastName"
                    searchable
                    sortable
                    label="Nazwisko"
                    v-slot="props"
                    >{{ props.row.lastName }}</b-table-column
                  >
                  <b-table-column
                    field="firstName"
                    searchable
                    sortable
                    label="Imię"
                    v-slot="props"
                    >{{ props.row.firstName }}</b-table-column
                  >
                  <b-table-column
                    field="pesel"
                    searchable
                    label="PESEL lub nr dokumentu"
                    v-slot="props"
                  >
                    <span v-if="props.row.pesel">{{ props.row.pesel }}</span>
                    <span v-else>{{ props.row.documentNumber }}</span>
                  </b-table-column>
                  <b-table-column
                    field="teleAddressTelephoneNumber"
                    searchable
                    sortable
                    label="Inne"
                    v-slot="props"
                    cell-class="is-size-7"
                  >
                    <p>ur. {{ mDate(props.row.birthdate) }}</p>
                    <p>tel. {{ props.row.telephone }}</p>
                    <p>
                      {{ props.row.street }}
                      {{ props.row.building }}
                      <span
                        v-if="
                          props.row.apartment != null &&
                          props.row.apartment != ''
                        "
                        >/{{ props.row.apartment }}</span
                      >
                    </p>
                    <p>{{ props.row.zip }} {{ props.row.city }}</p>
                  </b-table-column>
                  <b-table-column
                    field="domainId"
                    label="Obszar"
                    v-slot="props"
                  >
                    <b-tag
                      class="hand"
                      :type="
                        props.row.domainId == me.domainId
                          ? 'is-secondary'
                          : 'is-warning'
                      "
                      >{{ mGetDomainName(props.row.domainId) }}</b-tag
                    >
                  </b-table-column>
                  <template slot="detail" slot-scope="props">
                    <pre>{{ props.row }}</pre>
                    <PatientPanelModifier
                      v-if="props.row"
                      :initial="props.row"
                      :key="props.row.id"
                      :readonly="true"
                    ></PatientPanelModifier>
                  </template>
                  <template slot="empty">
                    <section class="pt-5 pb-5">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon
                            icon="account-reactivate"
                            size="is-large"
                          ></b-icon>
                        </p>
                        <p>Brak badań</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
              <div></div>
            </div>
          </section>
          <footer class="modal-card-foot p-2">
            <b-button
              icon-left="close"
              size="is-small"
              @click="searchModal = false"
              >Zamknij</b-button
            >
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Action } from '../../store/config_actions'
import PatientPanelModifier from './PatientPanelModifier'
import Comms from '@/mixins/commons'
import Domains from '@/mixins/domains'

export default {
  name: 'PatientPanelSearch2',
  components: { PatientPanelModifier },
  mixins: [Comms, Domains],

  data: function () {
    return {
      searchType: 1,
      pharse: '',
      searchType2: 5,
      pharse2: '',
      patients: [],
      examinations: [],
      loading: false,
      labLoading: false,
      selectedPatient: null,
      searchModal: false,
      searchDate: null,

      buttonIcon: 'cog',
      buttonPharse: 'Więcej opcji',
      searchCompletedOnce: false
    }
  },

  props: {
    type: { type: Number, required: false },
    trigger: { type: Number, required: false },
    init: { type: String, required: false },
    button: { type: String, required: false },
    icon: { type: String, required: false },
    labSearch: { type: Boolean, required: false },
    size: { type: String, required: false, default: '' },
    // buttonPharse: { type: String, required: false, default: 'Więcej opcji' }
  },

  watch: {
    searchType() {
      this.pharse2 = ''
      this.pharse = ''
    },
    searchType2() {
      this.pharse2 = ''
    },
    trigger() {
      this.searchModal = true
    },
    searchModal() {
      this.pharse2 = ''
      this.pharse = ''
    }
  },

  mounted() {
    if (this.button) {
      this.buttonPharse = this.button
    }
    if (this.icon) {
      this.buttonIcon = this.icon
    }
    if (this.type != null && this.type != undefined) {
      this.searchType = this.type
    }
  },

  computed: {

    me() {
      return this.$store.state.employee.me
    }
  },

  methods: {

    controlDateInput(val) {
      // if (val.length == 4) this.pharse2 += '-'
      // if (val.length == 7) this.pharse2 += '-'
      //TODO
      if (val.length == 2) this.pharse2 += '-'
      if (val.length == 5) this.pharse2 += '-'
    },

    selectPatient(item) {
      if (item) {
        if (this.examinations.length > 0) {
          let xam = this.examinations[0]

          if ((xam.pesel == item.pesel || xam.documentNumber == item.identityDocumentNumber) && item.lastName.toUpperCase() == xam.lastName.toUpperCase() && item.firstName.toUpperCase() == xam.firstName.toUpperCase()) {

            if (item.teleAddressTelephoneNumber == null || item.teleAddressTelephoneNumber.length < 1) {
              item.teleAddressTelephoneNumber = xam.telephone
            }
            if (item.addressVoivodeship == null || item.addressVoivodeship.length < 1) {
              item.addressVoivodeship = xam.voivodeship
            }
            if (item.addressCounty == null || item.addressCounty.length < 1) {
              item.addressCounty = xam.community
            }
            if (item.addressCommune == null || item.addressCommune.length < 1) {
              item.addressCommune = xam.county
            }
            if (item.addressStreet == null || item.addressStreet.length < 1) {
              item.addressStreet = xam.street
            }
            if (item.addressHouseNumber == null || item.addressHouseNumber.length < 1) {
              item.addressHouseNumber = xam.building
            }
            if (item.addressApartmentNumber == null || item.addressApartmentNumber.length < 1) {
              item.addressApartmentNumber = xam.apartment
            }
            if (item.addressZipCode == null || item.addressZipCode.length < 1) {
              item.addressZipCode = xam.zip
            }
            if (item.addressCity == null || item.addressCity.length < 1) {
              item.addressCity = xam.city
            }
            if (item.addressTeryt == null || item.addressTeryt.length < 1) {
              item.addressTeryt = xam.teryt
            }
          }
        }

        this.$emit('selected', item)
        this.searchModal = false

      }
    },

    selectExamination(item) {
      this.$emit('selected-examination', item)
      this.searchModal = false
    },

    updated(pat) {
      this.mCopy(pat, this.selectedPatient)
    },

    focus() {
      if (this.type != null && this.type != undefined && this.init) {
        this.pharse = this.init
        this.searchType = this.type

        this.search()
      }
      else {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.focus()
        }
      }
    },


    complete() {
      let payload = {
        type: this.searchType,
        pharse: this.pharse,
        data: this.patients
      }

      this.$emit('completed', payload)
    },

    search() {
      this.patients = []
      this.examinations = []
      if (this.pharse.length > 0) {
        this.loading = true
        this.labLoading = true
        this.selectedPatient = null

        this.$store.dispatch(Action.PATIENT_SEARCH, { type: this.searchType, pharse: this.pharse })
          .then((response) => {

            this.loading = false
            this.searchCompletedOnce = true
            let sorted = []

            response.forEach(x => {
              if (x.domainId == this.me.domainId) {
                sorted.splice(0, 0, x)
              }
              else {
                sorted.push(x)
              }
            })

            this.patients = sorted

            let amount = response.length
            this.snackbar(`Znaleziono pacjentów w bazie: ${amount}`)

            this.complete()
          })
          .catch((error) => {
            this.loading = false
            this.searchCompletedOnce = true
            this.snackbar(error)
          })


        this.$store.dispatch(Action.LAB_SEARCH_TELEADDRESS, { pharse: this.pharse })
          .then((response) => {
            this.labLoading = false
            this.searchCompletedOnce = true
            this.examinations = []

            if (response) {
              this.examinations.push(response)
              this.snackbar(`Znaleziono pacjenta w historycznych badaniach`)
            }
          })
          .catch((error) => {
            this.labLoading = false
            this.searchCompletedOnce = true
            this.snackbar(error)
          })
      }
    },

    search2() {
      if (this.pharse.length > 0) {
        this.loading = true
        this.selectedPatient = null
        this.$store.dispatch(Action.PATIENT_SEARCH_DOUBLE, { type: this.searchType, pharse: this.pharse, type2: this.searchType2, pharse2: this.pharse2 })
          .then((response) => {

            this.loading = false
            let sorted = []

            response.forEach(x => {
              if (x.domainId == this.me.domainId) {
                sorted.splice(0, 0, x)
              }
              else {
                sorted.push(x)
              }
            })

            this.patients = sorted

            let amount = response.length
            this.toast(`Znaleziono pacjentów: ${amount}`)

            this.complete()
          })
          .catch((error) => {
            this.loading = false
            this.snackbar(error)
          })
      }
    },
  }
}
</script>

<style scoped lang="scss">
</style>
