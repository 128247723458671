// **********************************

export const RUN_MODE = 'T'
export const APP_DATE = `2024/09/22`
export const APP_SUB = `1`
export const APP_PS = 'Bizmut'

export const APP_NR = `1.14.${APP_DATE.replaceAll('/', '').replace('202', '')}.${APP_SUB}`
export const APP_VERSION = `${APP_NR}${RUN_MODE} ${APP_PS}`

// **********************************

/**
 * General APIs configuration.
 */

const gate = RUN_MODE == 'P' ? "https://agw.digitmed.pl" : "https://klongw.digitmed.pl"
export const SERVICE_IDENTITY = RUN_MODE == 'T' ? "https://a.digitmed.pl" : gate
export const SERVICE_WORKER = RUN_MODE == 'T' ? "https://w.digitmed.pl" : gate
export const SERVICE_PATIENT = RUN_MODE == 'T' ? "https://s1.digitmed.pl" : gate
export const SERVICE_PATIENT_DATA = RUN_MODE == 'T' ? "https://pd.digitmed.pl" : gate
export const SERVICE_EWUS = RUN_MODE == 'T' ? "https://nf1.digitmed.pl" : gate
export const SERVICE_POZ = RUN_MODE == 'T' ? "https://pz.digitmed.pl" : gate
export const SERVICE_CLINICS_GATEWAY = RUN_MODE == 'T' ? "https://cgate.digitmed.pl" : gate
export const SERVICE_MOBI = RUN_MODE == 'T' ? "https://mobi.digitmed.pl" : gate
export const SERVICE_PHARMA = RUN_MODE == 'T' ? "https://ph.digitmed.pl" : gate
export const SERVICE_PHARMA_LOCAL = RUN_MODE == 'T' ? "https://localhost:44360" : gate
export const SERVICE_STATS = RUN_MODE == 'T' ? "https://st.digitmed.pl" : gate
export const SERVICE_ORDERS = RUN_MODE == 'T' ? "https://ord.digitmed.pl" : gate
export const SERVICE_EDU = RUN_MODE == 'T' ? "https://ed.digitmed.pl" : gate
export const SERVICE_INVENTORY = RUN_MODE == 'T' ? "https://in.digitmed.pl" : gate
export const SERVICE_CLINICS_1 = RUN_MODE == 'T' ? "https://sc1.digitmed.pl" : gate
export const SERVICE_CLINICS_0 = RUN_MODE == 'T' ? "https://sc.digitmed.pl" : gate
export const SERVICE_TEST = RUN_MODE == 'T' ? "https://test.digitmed.pl" : gate
export const SERVICE_LAB = RUN_MODE == 'T' ? "https://lb.digitmed.pl" : gate
export const SERVICE_TALK = RUN_MODE == 'T' ? "https://tk.digitmed.pl" : gate
export const SERVICE_CRM = RUN_MODE == 'T' ? "https://crm.digitmed.pl" : gate
export const SERVICE_ERC = RUN_MODE == 'T' ? "https://erc.digitmed.pl" : gate
export const SERVICE_TELE = RUN_MODE == 'T' ? "https://night.digitmed.pl" : gate
export const SERVICE_FINANCE = RUN_MODE == 'T' ? "https://guldeny.digitmed.pl" : gate
export const SERVICE_HUB = RUN_MODE == 'T' ? "https://hub.digitmed.pl" : gate
export const SERVICE_RIS = RUN_MODE == 'T' ? "https://ris.digitmed.pl" : gate
export const SERVICE_RESULTS = RUN_MODE == 'T' ? "https://r2.digitmed.pl" : gate
export const SERVICE_ZUS = RUN_MODE == 'T' ? "https://zus.digitmed.pl" : gate
export const SERVICE_SOLAB = RUN_MODE == 'T' ? "https://sol.digitmed.pl" : gate
export const SERVICE_PROGRAMS = RUN_MODE == 'T' ? "https://ord.digitmed.pl" : gate
export const SERVICE_DILO = RUN_MODE == 'T' ? "https://dilo.digitmed.pl" : gate
export const SERVICE_MISC = RUN_MODE == 'T' ? "https://misc.digitmed.pl" : gate
export const SERVICE_APKOLCE = RUN_MODE == 'T' ? "https://localhost:44377" : gate

export const Apis = {
    // ****************************************************************************************
    // TALK SERVICE
    // ****************************************************************************************

    TALK_GET_MY_GROUPS: SERVICE_TALK + '/api/talk/MyGroups',
    TALK_CRETE_GROUP: SERVICE_TALK + '/api/talk/CreateByEmployee',
    TALK_ADD_EMPLOYEE_TO_GROUP: SERVICE_TALK + '/api/talk/AddEmployeeToGroup/{gid}/{eid}',
    TALK_REMOVE_EMPLOYEE_FROM_GROUP: SERVICE_TALK + '/api/talk/RemoveEmployeeFromGroup/{gid}/{eid}',
    TALK_GET_MESSAGES: SERVICE_TALK + '/api/talk/GetMessages/{group}/{count}',

    // ****************************************************************************************
    // MOBI SERVICE
    // ****************************************************************************************

    // MOBI 2 #######################

    MOBI2_GET_GROUPS: SERVICE_MOBI + "/api/MobiAccountGroup",
    MOBI2_GET_GROUP_BY_ID: SERVICE_MOBI + "/api/MobiAccountGroup/{id}",
    MOBI2_ADD_GROUP: SERVICE_MOBI + "/api/MobiAccountGroup",
    MOBI2_EDIT_GROUP: SERVICE_MOBI + "/api/MobiAccountGroup/{id}",
    MOBI2_REMOVE_GROUP: SERVICE_MOBI + "/api/MobiAccountGroup/{id}",

    MOBI2_GET_MY_ACCOUNT: SERVICE_MOBI + "/api/MobiAccount/external/{id}",
    MOBI2_GET_USERS: SERVICE_MOBI + "/api/MobiAccount",
    MOBI2_GET_USER: SERVICE_MOBI + "/api/MobiAccount/{id}",
    MOBI2_EDIT_USER: SERVICE_MOBI + "/api/MobiAccount/{id}",
    MOBI2_ADD_USER: SERVICE_MOBI + "/api/MobiAccount",
    MOBI2_DELETE_USER: SERVICE_MOBI + "/api/MobiAccount/{id}",

    MOBI_GET_ROUTES: SERVICE_MOBI + "/api/mobiroute",
    MOBI_GET_ROUTE: SERVICE_MOBI + "/api/mobiroute/{id}",
    MOBI_ADD_ROUTE: SERVICE_MOBI + "/api/mobiroute",
    MOBI_EDIT_ROUTE: SERVICE_MOBI + "/api/mobiroute/{id}",
    MOBI_REMOVE_ROUTE: SERVICE_MOBI + "/api/mobiroute/{id}",

    MOBI2_GET_VEHICLES: SERVICE_MOBI + "/api/MobiVehicle",
    MOBI2_GET_VEHICLE: SERVICE_MOBI + "/api/MobiVehicle/{id}",
    MOBI2_ADD_VEHICLE: SERVICE_MOBI + "/api/MobiVehicle",
    MOBI2_MODIFY_VEHICLE: SERVICE_MOBI + "/api/MobiVehicle/{id}",
    MOBI2_DELETE_VEHICLE: SERVICE_MOBI + "/api/MobiVehicle/{id}",

    MOBI2_GET_PARCELS: SERVICE_MOBI + "/api/MobiParcel",
    MOBI2_GET_PARCEL: SERVICE_MOBI + "/api/MobiParcel/{id}",
    MOBI2_GET_PARCEL_BY_CODE: SERVICE_MOBI + "/api/MobiParcel/code/{code}",
    MOBI2_GET_PARCELS_FOR_USER: SERVICE_MOBI + "/api/MobiParcel/GetParcelsForUser/{id}/{workerId}",
    MOBI2_ADD_PARCEL: SERVICE_MOBI + "/api/MobiParcel",
    MOBI2_ADD_PARCEL_GROUP: SERVICE_MOBI + "/api/MobiParcel/group",
    MOBI2_MODIFY_PARCEL: SERVICE_MOBI + "/api/MobiParcel/{id}",
    MOBI2_DELETE_PARCEL: SERVICE_MOBI + "/api/MobiParcel/{id}",
    MOBI2_MODIFY_STATUS: SERVICE_MOBI + "/api/MobiParcel/status/{id}",
    MOBI2_ADD_FLAG: SERVICE_MOBI + "/api/MobiParcel/flag/{id}",
    MOBI2_FILTER_PARCELS: SERVICE_MOBI + "/api/MobiParcel/filter",
    MOBI2_FILTER_PARCELS_BY_CONTENT: SERVICE_MOBI + "/api/MobiParcel/FilterByContent",
    MOBI2_COUNT_PARCELS: SERVICE_MOBI + "/api/MobiTools/CountParcels",

    // ****************************************************************************************
    // HUB SERVICE
    // ****************************************************************************************

    HUB_DEVICE_ADD: SERVICE_HUB + "/api/device",
    HUB_DEVICE_ALL: SERVICE_HUB + "/api/device",
    HUB_DEVICE_GET_BY_ID: SERVICE_HUB + "/api/device/{id}",
    HUB_DEVICE_PUT: SERVICE_HUB + "/api/device/{id}",
    HUB_DEVICE_DELETE: SERVICE_HUB + "/api/device/{id}",
    HUB_DEVICE_ACTIVE: SERVICE_HUB + "/api/device/active",
    HUB_CHECK_DEVICE: SERVICE_HUB + "/api/device/active/{code}",
    HUB_DEVICE_GET_BY_CODE: SERVICE_HUB + "/api/device/code/{code}",
    HUB_UPLOAD: SERVICE_HUB + "/api/upload/upload",
    HUB_JOB_GET_BY_ID: SERVICE_HUB + "/api/job/{id}",
    HUB_JOB_ACCEPT: SERVICE_HUB + "/api/job/accept/{id}",
    HUB_JOB_REJECT: SERVICE_HUB + "/api/job/reject/{id}",
    HUB_JOB_CHECK: SERVICE_HUB + "/api/job/status/{id}",
    HUB_VIEW_SIGNED: SERVICE_HUB + "/api/job/signed/{id}",
    HUB_CREATE_JOB: SERVICE_HUB + "/api/job",


    // ****************************************************************************************
    // SMS SERVICE
    // ****************************************************************************************

    // NEW NEW NEW FOR HUB SERVICE
    HUB_DOCUMENT_TEMPLATE_GET_ALL: SERVICE_HUB + "/api/DocumentTemplate",
    HUB_DOCUMENT_TEMPLATE_GET_BY_ID: SERVICE_HUB + "/api/DocumentTemplate/{id}",
    HUB_DOCUMENT_TEMPLATE_GET_BY_CODE: SERVICE_HUB + "/api/DocumentTemplate/Code/{code}",
    HUB_DOCUMENT_TEMPLATE_UPLOAD: SERVICE_HUB + "/api/DocumentTemplate/Upload",
    HUB_DOCUMENT_TEMPLATE_GET_ZIP_BY_ID: SERVICE_HUB + "/api/DocumentTemplate/Zip/{id}",

    // ****************************************************************************************
    // TEST SERVICE
    // ****************************************************************************************

    SMS_SEND: SERVICE_CLINICS_GATEWAY + "/api/SmsArchive/sendsms",
    SMS_FILTER: SERVICE_CLINICS_GATEWAY + "/api/SmsArchive/filter",
    SMS_ARCHIVE_PATIENT: SERVICE_CLINICS_GATEWAY + "/api/SmsArchive/ForPatient/{domain}/{patientId}",
    SCHEDULE2_WORKTIME: SERVICE_CLINICS_GATEWAY + "/api/szokschedule/createworktimearea/{clinicId}",
    SCHEDULE2_WORKTIME_DELETE: SERVICE_CLINICS_GATEWAY + "/api/szokschedule/deleteworktime/{id}/{clinicId}",
    SCHEDULE2_APPOINTMENT_POZ: SERVICE_CLINICS_GATEWAY + "/api/szokschedule/createappointmentwithpoz/{clinicId}",
    SCHEDULE2_EDIT_APPOINTMENT_POZ: SERVICE_CLINICS_GATEWAY + "/api/s3/ModifyAppointmentPoz/{clinicId}",
    SCHEDULE2_APPOINTMENT: SERVICE_CLINICS_GATEWAY + "/api/szokschedule/createappointment/{clinicId}",
    SCHEDULE2_APPOINTMENT_EDIT: SERVICE_CLINICS_GATEWAY + "/api/S3/ModifyAppointment/{id}/{clinicId}", //NEW
    SCHEDULE2_APPOINTMENT_DELETE: SERVICE_CLINICS_GATEWAY + "/api/szokschedule/deleteappointment/{id}/{clinicId}",
    SCHEDULE2_APPOINTMENT_GET: SERVICE_CLINICS_GATEWAY + "/api/szokschedule/getappointmentbyid/{id}/{clinicId}",

    S3_APPOINTMENT_PATIENT_ABSENT: SERVICE_CLINICS_GATEWAY + "/api/S3/PatientAbsent/{id}/{value}/{domain}",
    S3_APPOINTMENT_PATIENT_LATE: SERVICE_CLINICS_GATEWAY + "/api/S3/PatientLate/{id}/{value}/{domain}",

    SCHEDULE2_SCHEDULE: SERVICE_CLINICS_GATEWAY + "/api/szokschedule/getschedule/{clinicId}",
    SCHEDULE2_SEARCH: SERVICE_CLINICS_GATEWAY + "/api/s2/search/{clinicId}",
    SCHEDULE2_SET_STATUS: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/SetAppointmentStatus/{id}/{workerId}/{status}/{clinicId}",
    SCHEDULE2_POST_STATUS: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/SetAppointmentStart/{id}/{clinicId}",

    S3_MARK_PATIENT_WAITING: SERVICE_CLINICS_GATEWAY + "/api/S3/PatientWaiting/{id}/{value}/{domain}",

    S3_GET_UNFINISHED_APPOINTMENTS: SERVICE_CLINICS_GATEWAY + "/api/S3/UnfinishedAppointmentsFor/{id}/{monthsBack}/{domain}",

    S3_SPLIT_GROUP: SERVICE_CLINICS_GATEWAY + "/api/S3/SplitGroup/{id}/{clinicId}",
    S3_ADD_TO_GROUP: SERVICE_CLINICS_GATEWAY + "/api/S3/AddToGroup/{id}/{patientId}/{clinicId}",
    S3_REMOVE_FROM_GROUP: SERVICE_CLINICS_GATEWAY + "/api/S3/RemoveFromGroup/{id}/{patientId}/{clinicId}",

    SCHEDULE2_RESCHEDULE_APPOINTMENT: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/RescheduleAppointment/{clinicId}",

    SCHEDULE2_APPOINTMENT_CANCEL: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/CancelAppointment/{id}/{reason}/{clinicId}",

    SCHEDULE2_APPOINTMENT_CANCEL_EXTENDED: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/CancelExtended/{id}/{reason}/{domain}",

    SCHEDULE2_APPOINTMENT_ADD_TICKET: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/AddTicket/{appointmentId}/{ticketId}/{domain}",
    SCHEDULE2_APPOINTMENT_REMOVE_TICKET: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/RemoveTicket/{appointmentId}/{ticketId}/{domain}",

    CGATE_EMPLOYEES_STATS: SERVICE_CLINICS_GATEWAY + "/api/TimeStats/CalculateScheduleActivity",
    SCHEDULE3_SEARCH: SERVICE_CLINICS_GATEWAY + "/api/s3/search/{clinicId}",
    SCHEDULE4_SEARCH: SERVICE_CLINICS_GATEWAY + "/api/s4/search/{clinicId}",
    SCHEDULE4_ADD_AREA: SERVICE_CLINICS_GATEWAY + "/api/s4/addarea/{clinicId}",
    SCHEDULE4_ADD_AREA_EXPANDED: SERVICE_CLINICS_GATEWAY + "/api/s4/addarea/{mode}/{clinicId}",
    SCHEDULE3_MODIFY_WORKTIME: SERVICE_CLINICS_GATEWAY + "/api/S3/ModifyWorktime/{id}/{clinicId}",
    SCHEDULE3_OVERWRITE_WORKTIMES: SERVICE_CLINICS_GATEWAY + "/api/S3/MultiOverwrite/{clinicId}",
    SCHEDULE3_MOVE_WORKTIME_PRO: SERVICE_CLINICS_GATEWAY + "/api/S3/MovePro/{clinicId}",
    SCHEDULE3_FILL_WORKTIMES: SERVICE_CLINICS_GATEWAY + "/api/S3/MultiFill/{clinicId}",
    SCHEDULE3_DELETE_WORKTIMES: SERVICE_CLINICS_GATEWAY + "/api/S3/MultiDelete/{clinicId}",
    SCHEDULE3_WORKTIMEAREA_MULTIPLE: SERVICE_CLINICS_0 + "/api/WorktimeArea/multiple",

    SCHEDULE3_CONFIG_POST: SERVICE_WORKER + "/api/ScheduleConfiguration",
    //SCHEDULE3_CONFIG: SERVICE_CLINICS_GATEWAY + '/api/ScheduleConfig/{clinicId}',
    SCHEDULE3_CONFIG: SERVICE_WORKER + "/api/ScheduleConfiguration/{subjectId}",

    SCHEDULE3_WORKTIMES_CYCLICAL: SERVICE_CLINICS_GATEWAY + "/api/S3/Cyclical/{clinicId}",

    SCHEDULE3_FILTER_BY_PATIENT: SERVICE_CLINICS_GATEWAY + "/api/S3/FilterByPatient/{clinicId}",
    SCHEDULE3_GET_APPOINTMENTS_FOR_PATIENT: SERVICE_CLINICS_GATEWAY + "/api/S3/ForPatient/{patientId}/{monthsBack}/{domain}", //NEW

    SCHEDULE3_FILTERV2: SERVICE_CLINICS_GATEWAY + "/api/S3/FilterV2/{domain}",

    TOOLS_FILTER_APPS: SERVICE_CLINICS_GATEWAY + "/api/Tools/FilterApps/{clinicId}",

    SCHEDULE2_REFERRAL_PROBLEMS: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/ReferralProblems/{clinicId}",
    SCHEDULE2_REFERRAL_PROBLEMS_FOR_PATIENT: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/ReferralProblemsForPatient/{patientId}/{clinicId}",

    SCHEDULE2_GET_APPOINTMENTS_BY_TICKET: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/GetAppointmentsByTicket/{id}/{domain}",

    SCHEDULE2_GET_APPOINTMENTS_BY_MANY_TICKETS: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/GetAppointmentGroupsByTickets/{domain}/{mode}",

    EWUS_UPDATE_APPOINTMENT_INFO: SERVICE_CLINICS_GATEWAY + "/api/EwusSync/UpdateEwusInfo",

    SCHEDULE2_GET_APPOINTMENT_VERSION: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/GetAppointmentVersion/{id}/{domain}",



    // ****************************************************************************************
    // ORDERS SERVICE
    // ****************************************************************************************

    ORDERS_PRODUCTS_GET_ALL: SERVICE_ORDERS + "​/api/product",
    ORDERS_PRODUCTS_POST_NEW: SERVICE_ORDERS + "/api/product",
    ORDERS_PRODUCTS_DELETE: SERVICE_ORDERS + "/api/product/{id}",
    ORDERS_PRODUCTS_MODIFY: SERVICE_ORDERS + "/api/product/{id}",
    ORDERS_SUPPLIERS_GET_ALL: SERVICE_ORDERS + "​/api/supplier",
    ORDERS_SUPPLIERS_POST_NEW: SERVICE_ORDERS + "/api/supplier",
    ORDERS_SUPPLIERS_DELETE: SERVICE_ORDERS + "/api/supplier/{id}",
    ORDERS_SUPPLIERS_MODIFY: SERVICE_ORDERS + "/api/supplier/{id}",
    ORDERS_GET_ALL: SERVICE_ORDERS + "​/api/order",
    ORDERS_POST_NEW: SERVICE_ORDERS + "/api/order",
    ORDERS_DELETE: SERVICE_ORDERS + "/api/order/{id}",
    ORDERS_GET: SERVICE_ORDERS + "/api/order/getorderbyid/{id}",

    // ****************************************************************************************
    // EDU SERVICE
    // ****************************************************************************************

    EDU_COURSES_GET_ALL: SERVICE_EDU + "​/api/trainingcourse/getallcourses",
    EDU_COURSES_GET_ALL_LITE: SERVICE_EDU + "​/api/trainingcourse/getallcourseslite",
    EDU_POST_NEW: SERVICE_EDU + "/api/trainingcourse/AddNewCourse",
    EDU_MODIFY_COURSE: SERVICE_EDU + "/api/trainingcourse/updateSelectedCourse/{idSZOKEdu}",
    EDU_POST_FILE: SERVICE_EDU + "/api/FileUploadEdu/Upload",
    EDU_GET_FILE: SERVICE_EDU + "/api/fileUploadEdu/getFile/{FileNameId}",
    EDU_GET_PROGRESS: SERVICE_EDU + "/api/trainingcourse/getEduProgresses/{WorkerId}",
    EDU_MODIFY_PROGRESS: SERVICE_EDU + "/api/trainingCourse/updateSelectedEduProgress/{WorkerId}",

    EDU_DOWNLOAD_INSTRUCTION: SERVICE_EDU + "/api/Instructions/{id}/file",

    // ****************************************************************************************
    // CLINICS SERVICE
    // ****************************************************************************************

    CLINICS_GET_ALL: SERVICE_CLINICS_GATEWAY + "/api/GatewayInfo",

    // ****************************************************************************************
    // STATS SERVICE
    // ****************************************************************************************

    STATS_CHANGE_LOG_GENERAL: SERVICE_STATS + "/api/changelog",
    STATS_CHANGE_LOG_BY_ID: SERVICE_STATS + "/api/changelog/{id}",

    STATS_SEND_CRASH_LOG: SERVICE_STATS + "/api/crashlog",
    STATS_GET_CRASH_LOGS: SERVICE_STATS + "/api/crashlog",
    STATS_MODIFY_CRASH: SERVICE_STATS + "/api/crashlog/{id}",
    STATS_SEND_API_ERROR: SERVICE_STATS + "/api/apierror",
    STATS_GET_API_ERRORS: SERVICE_STATS + "/api/apierror",
    STATS_API_ERROR_BY_ID: SERVICE_STATS + "/api/apierror/{id}",
    STATS_SEND_FEEDBACK: SERVICE_STATS + "/api/workerfeedback",
    STATS_GET_FEEDBACKS: SERVICE_STATS + "/api/workerfeedback",
    STATS_COMPLETE_FEEDBACK: SERVICE_STATS + "/api/workerfeedback/complete/{id}",
    STATS_DELETE_FEEDBACK: SERVICE_STATS + "/api/workerfeedback/{id}",
    STATS_EMPLOYEE_TIME: SERVICE_STATS + "/api/WorkerTimestamp/Calculate",
    STATS_EMPLOYEE_NIGHTIME: SERVICE_STATS + "/api/WorkerTimestamp/NightTable",

    STATS_POST_PATIENT_TICKET: SERVICE_STATS + "/api/Ticket",
    STATS_GET_TICKETS_MULTIPLE: SERVICE_STATS + "/api/Ticket/GetMultiple",
    STATS_GET_PATIENT_TICKETS_ACTIVE: SERVICE_STATS + "/api/Ticket/GetActiveByPatientId/{id}",
    STATS_GET_PATIENT_TICKETS_ALL: SERVICE_STATS + "/api/Ticket/GetAllByPatientId/{id}",
    STATS_GET_SET_TICKET_STATUS: SERVICE_STATS + "/api/Ticket/SetStatus/{id}/{status}",
    STATS_GET_PATIENT_TICKET: SERVICE_STATS + "/api/Ticket/{id}",
    STATS_PUT_PATIENT_TICKET: SERVICE_STATS + "/api/Ticket/{id}",
    STATS_DELETE_PATIENT_TICKET: SERVICE_STATS + "/api/Ticket/{id}",
    STATS_TICKET_FILTER: SERVICE_STATS + "/api/Ticket/Filter", //NEW
    STATS_TICKET_GET_BY_PROGRAM_FOR_PATIENT: SERVICE_STATS + "/api/Ticket/GetByProgramIdForPatient/{programId}/{patientId}",
    STATS_TICKET_GET_BY_PATIENT_PROGRAM: SERVICE_STATS + "/api/Ticket/GetByPatientProgramId/{id}",
    STATS_TICKET_GET_BY_PATIENT_PROGRAM_FOR_PROFILE: SERVICE_STATS + "/api/Ticket/GetByPatientProgramAndProfile/{programid}/{profileId}",
    TICKET_CREATE_WITH_EXTENSION: SERVICE_STATS + "/api/Ticket/CreateWithExtension",
    TICKET_GET_WITH_EXTENSION: SERVICE_STATS + "/api/Ticket/GetWithExtension/{id}",
    TICKET_GET_BY_REFERRAL: SERVICE_STATS + "/api/Ticket/GetByReferral/{mode}/{value}",
    TICKET_GET_VERSION: SERVICE_STATS + "/api/Ticket/GetTicketVersion/{id}",

    TICKET_EXTENSION_CREATE: SERVICE_STATS + "/api/TicketExtension",
    TICKET_EXTENSION_GET: SERVICE_STATS + "/api/TicketExtension/{id}",
    TICKET_EXTENSION_PUT: SERVICE_STATS + "/api/TicketExtension/{id}",
    TICKET_EXTENSION_DELETE: SERVICE_STATS + "/api/TicketExtension/{id}",
    TICKET_SET_CONSULTATION: SERVICE_STATS + "/api/Ticket/SetConsultation/{id}",

    TICKET_PASS_CREATE: SERVICE_STATS + "/api/TicketPass",
    TICKET_PASS_GET: SERVICE_STATS + "/api/TicketPass/{id}",
    TICKET_PASS_GET_BY_PATIENT: SERVICE_STATS + "/api/TicketPass/ByPatient/{patientId}",
    TICKET_PASS_GET_FULL: SERVICE_STATS + "/api/TicketPass/Full/{id}",
    TICKET_PASS_GET_FULL_BY_PATIENT: SERVICE_STATS + "/api/TicketPass/Full/ByPatient/{patientId}",

    STATS_SEND_USER_FEEDBACK: SERVICE_STATS + "/api/UserFeedback",

    // ****************************************************************************************
    // TICKETS
    // ****************************************************************************************
    TICKET_TRANSLATE: SERVICE_CLINICS_GATEWAY + "/api/SzokSchedule/TranslateTicket/{clinicId}",

    //SIGNATURE_TEST: 'https://localhost:44387/api/test',
    //STATS_GET_TIMES: SERVICE_STATS + '/api/workertimelog',

    // ****************************************************************************************
    // PHARMA SERVICE
    // ****************************************************************************************

    PHARMA_SEARCH_BY_NAME_OLD: SERVICE_PHARMA + "/api/PharmacyProductInfo/GetProductsNames/{ProductName}",
    PHARMA_SEARCH_BY_NAME: SERVICE_PHARMA + "/api/PharmacyProductInfo/GetProductInfoV3/{ProductName}",
    PHARMA_SEARCH_DRUG_BY_NAME: SERVICE_PHARMA + "/api/Drugs/Search/{productName}",
    PHARMA_SEARCH_OTC_DRUG_BY_NAME: SERVICE_PHARMA + "/api/Drugs/SearchOtc/{productName}",
    PHARMA_SEARCH_DRUG_BY_SUBSTANCE: SERVICE_PHARMA + "/api/Drugs/SearchBySubstance/{name}",
    PHARMA_SEARCH_OTC_DRUG_BY_SUBSTANCE: SERVICE_PHARMA + "/api/Drugs/SearchOtcBySubstance/{name}",
    PHARMA_SEARCH_ICD9: SERVICE_PHARMA + "/api/Icd/Search/icd9/{name}",
    PHARMA_GET_VACCINATION_ICDS: SERVICE_PHARMA + "/api/Icd/GetVaccinationIcds",
    PHARMA_SEARCH_ICD10: SERVICE_PHARMA + "/api/Icd/Search/icd10/{name}",
    PHARMA_SEARCH_SUBSTANCE: SERVICE_PHARMA + "/api/Drugs/SearchSubstance/{name}",
    PHARMA_SEARCH_GROUPER_ITEM_FULL: SERVICE_PHARMA + "/api/Icd/grouper/all/{name}",
    PHARMA_SEARCH_GROUPER_ITEM_LIMITED: SERVICE_PHARMA + "/api/Icd/grouper/limited/{name}",
    PHARMA_CALCULATE_GROUPER: SERVICE_PHARMA + "/api/Icd/grouper",
    PHARMA_SEARCH_COMPONENT: SERVICE_PHARMA + "/api/Drugs/SearchComponent/{name}",
    PHARMA_DRUG_SEARCH_DELETED: SERVICE_PHARMA + "/api/Drugs/SearchDeleted/{productName}",
    PHARMA_DRUG_GET_DELETED: SERVICE_PHARMA + "/api/Drugs/GetDeleted",

    PHARMA_SEARCH_TOWNS: SERVICE_PHARMA + "/api/Geo/search/towns/{name}",
    PHARMA_SEARCH_TOWNS_WITH_TERYT: SERVICE_PHARMA + "/api/Geo/search/towns/teryts/{name}",
    PHARMA_SEARCH_TERYT: SERVICE_PHARMA + "/api/Geo/search/teryt/{name}",
    PHARMA_SEARCH_FILTER: SERVICE_PHARMA + "/api/Geo/Filter",
    PHARMA_SEARCH_GET_BY_VALUES: SERVICE_PHARMA + "/api/Geo/GetByValues",
    PHARMA_SEARCH_TERYT2: SERVICE_PHARMA + "/api/Geo/search/teryt2/{name}",
    PHARMA_SEARCH_TERYT3: SERVICE_PHARMA + "/api/Geo/search/teryt3/{name}",

    PHARMA_ICD_DIAGNOSIS: SERVICE_PHARMA + "/api/IcdDiagnosis",
    PHARMA_ICD_DIAGNOSIS_BY_ID: SERVICE_PHARMA + "/api/IcdDiagnosis/{id}",

    PHARMA_DRUG_ADDS_GET_ALL: SERVICE_PHARMA + "/api/DrugAdds",
    PHARMA_DRUG_ADDS_GET: SERVICE_PHARMA + "/api/DrugAdds/{id}",
    PHARMA_DRUG_ADD_GET_DANGEROUS: SERVICE_PHARMA + "/api/DrugAdds/Dangerous",
    PHARMA_DRUG_ADD_GET_MEDKITS: SERVICE_PHARMA + "/api/DrugAdds/Medkits",
    PHARMA_DRUG_ADD_GET_VACCINES: SERVICE_PHARMA + "/api/DrugAdds/Vaccines",
    PHARMA_DRUG_ADD_GET_BY_DRUG: SERVICE_PHARMA + "/api/DrugAdds/Drug/{id}",

    PHARMA_DRUG_GET_UNDESCRIBED_ALL: SERVICE_PHARMA + "/api/Drugs/GetUndescribed",
    PHARMA_DRUG_GET_UNDESCRIBED_AND_WITHOUT_SUBSTANCE: SERVICE_PHARMA + "/api/Drugs/GetUndescribedAndWithoutSubstance",

    PHARMA_ACTIVE_SUBSTANCES_GET_ALL: SERVICE_PHARMA + "/api/ActiveSubstance/Names",
    PHARMA_ACTIVE_SUBSTANCE_POST: SERVICE_PHARMA + "/api/ActiveSubstance",

    PHARMA_DRUG_REMOVED_GET_ALL: SERVICE_PHARMA + "/api/Drugs/GetRemoved",

    PHARMA_BMI_GET: SERVICE_PHARMA + "/api/Bmi",

    PHARMA_DRUGS_GET_MANY: SERVICE_PHARMA + "/api/Drugs/GetManyDrugs",

    PHARMA_INDEX_SEARCH_COMPONENT: SERVICE_PHARMA + "/api/PharmIndex/FindRaw",
    PHARMA_INDEX_SEARCH_DRUG: SERVICE_PHARMA + "/api/PharmIndex/FindDrug",
    PHARMA_INDEX_SEARCH_DRUG_VAC: SERVICE_PHARMA + "/api/PharmIndex/FindDrugVAC",
    PHARMA_INDEX_SEARCH_RECIPE: SERVICE_PHARMA + "/api/PharmIndex/FindRecipe",
    PHARMA_INDEX_GET_RECIPE_BY_ID: SERVICE_PHARMA + "/api/PharmIndex/GetRecipe/{id}",
    PHARMA_INDEX_GET_SINGLE_DRUG: SERVICE_PHARMA + "/api/PharmIndex/GetSingleDrug/{nameCode}",
    PHARMA_INDEX_GET_SINGLE_DRUG_MAX: SERVICE_PHARMA + "/api/PharmIndex/GetSingleDrugMaxOLDTester/{nameCode}",
    PHARMA_CREATE_DRUG_HISTORY: SERVICE_PHARMA + "/api/SZOKDrugHistoricParams/CreateSingleDrugHistory",
    PHARMA_EDIT_DRUG_HISTORY: SERVICE_PHARMA + "/api/SZOKDrugHistoricParams/EditSingleDrugHistory",
    PHARMA_GET_DRUG_HISTORY: SERVICE_PHARMA + "/api/SZOKDrugHistoricParams/GetSingleDrugHistory/{ean}/{workerId}",

    // ****************************************************************************************
    // POZ SERVICE
    // ****************************************************************************************

    SCHEDULE_GET_APPOINTMENTS_FOR_WORKER: SERVICE_POZ + "/api/Schedule/GetUpcomingAppointmentsForWorker/{id}",

    POZ_FINISH_APPOINTMENT: SERVICE_POZ + "/api/Appointment",

    // ***************************
    POZ_FINISH_APPOINTMENT_V2: SERVICE_PATIENT_DATA + "/api/PatientData/AddBundleV2", // !!! <---- SERVICE CHANGED TO PD !!!
    POZ_FINISH_APPOINTMENT_V3: SERVICE_PATIENT_DATA + "/api/AppEnd/AddBundleV3", // !!! <---- SERVICE CHANGED TO PD !!!
    // ***************************

    POZ_GET_APPOINTMENT_HISTORY: SERVICE_POZ + "/api/Appointment/AppointmentEndGetPatientItems/{patientId}",
    POZ_GET_ACTIVE_DIAGNOSES: SERVICE_POZ + "/api/Appointment/GetICDPatientItems/{patientId}",
    POZ_START_APPOINTMENT: SERVICE_POZ + "/api/Appointment/AppointmentStart/{patientId}/{workerId}/{scheduleId}",

    POZ_GET_PATHS: SERVICE_POZ + "/api/Appointment/GetPaths/{patientId}",
    POZ_MODIFY_PATH: SERVICE_POZ + "/api/Appointment/UpdateSelectedPath/{patientId}",
    POZ_ADD_PATH: SERVICE_POZ + "/api/Appointment/AddPath",

    POZ_GET_APPOINTMENTS: SERVICE_POZ + "/api/Schedule/GetScheduleByPatientId/{patientId}",

    // ****************************************************************************************
    // SCHEDULE SERVICE
    // ****************************************************************************************

    SCHEDULE_REMOVE_APPOINTMENT: SERVICE_CLINICS_GATEWAY + "/api/UsedSlot/{cid}/{id}",
    SCHEDULE_ADD_OR_GET: SERVICE_CLINICS_GATEWAY + "/api/UsedSlot/{cid}",
    SCHEDULE_ADD_WITH_POZ: SERVICE_CLINICS_GATEWAY + "/api/UsedSlot/createwithpoz/{cid}",
    SCHEDULE_FREESLOT_ADD_OR_GET: SERVICE_CLINICS_GATEWAY + "/api/FreeSlot/{cid}",
    SCHEDULE_GET_GROUPS: SERVICE_CLINICS_GATEWAY + "/api/Schedule/GetSchedulesByDates?clinicId={cid}",

    OFFICE_ADD: SERVICE_WORKER + "/api/Office", //
    OFFICE_GET_ALL_DOMAINLESS: SERVICE_WORKER + "/api/Office/All", //
    OFFICE_GET_ALL: SERVICE_WORKER + "/api/Office", //
    OFFICE_GET: SERVICE_WORKER + "/api/Office/{id}", //
    OFFICE_PUT: SERVICE_WORKER + "/api/Office/{id}", //
    OFFICE_DELETE: SERVICE_WORKER + "/api/Office/{id}", //
    OFFICE_BY_DOMAIN: SERVICE_WORKER + "/api/Office/ByDomain/{domain}", //
    OFFICE_GET_BY_WORKER: SERVICE_WORKER + "/api/Office/GetByEmployee/{id}",

    OFFICEROOM_ADD: SERVICE_WORKER + "/api/OfficeRoom", //
    OFFICEROOM_GET_ALL: SERVICE_WORKER + "/api/OfficeRoom", //
    OFFICEROOM_GET: SERVICE_WORKER + "/api/OfficeRoom/{id}", //
    OFFICEROOM_PUT: SERVICE_WORKER + "/api/OfficeRoom", //
    OFFICEROOM_DELETE: SERVICE_WORKER + "/api/OfficeRoom/{id}", //
    OFFICE_GET_BY_SUBUNIT: SERVICE_WORKER + "/api/Office/GetBySubUnit/{id}",
    OFFICE_GET_BY_TYPE: SERVICE_WORKER + "/api/Office/GetByType/{id}", //
    OFFICE_GET_BY_REPORTED_SUBUNIT: SERVICE_WORKER + "/api/Office/GetByReportedSubunit/{id}",
    OFFICE_GET_BY_MASTER: SERVICE_WORKER + "/api/Office/GetByMaster/{id}",
    OFFICE_GET_STRAY_OFFICES: SERVICE_WORKER + "/api/Office/GetStrayOffices",

    EMPLOYEE_SETTINGS_MODIFY: SERVICE_WORKER + "/api/EmployeeSettings/{id}",
    MY_SETTINGS: SERVICE_WORKER + "/api/EmployeeSettings/MySettings",

    SLOT_ADD: SERVICE_WORKER + "/api/EmployeeSlot",
    SLOT_GET_ALL: SERVICE_WORKER + "/api/EmployeeSlot",
    SLOT_GET: SERVICE_WORKER + "/api/EmployeeSlot/{id}",
    SLOT_UPDATE: SERVICE_WORKER + "/api/EmployeeSlot/{id}",
    SLOT_DELETE: SERVICE_WORKER + "/api/EmployeeSlot/{id}",
    SLOT_GET_BY_SUBUNIT: SERVICE_WORKER + "/api/EmployeeSlot/ForSubUnit/{id}",
    SLOT_GET_BY_EMPLOYEE: SERVICE_WORKER + "/api/EmployeeSlot/ByEmployee/{id}",

    // ****************************************************************************************
    // IDENTITY SERVICE
    // ****************************************************************************************

    IDENTITY_LOGIN: SERVICE_IDENTITY + "/api/identity",
    IDENTITY_ADMIN_USERS: SERVICE_IDENTITY + "/api/identity",
    IDENTITY_DELETE_USER: SERVICE_IDENTITY + "/api/identity",
    IDENTITY_MODIFY_USER: SERVICE_IDENTITY + "/api/identity",
    IDENTITY_MODIFY_EMAIL: SERVICE_IDENTITY + "/api/identity/ChangeEmail",
    IDENTITY_REGISTER: SERVICE_IDENTITY + "/api/identity/add",
    IDENTITY_REGISTER_WORKER: SERVICE_IDENTITY + "/api/identity/addworker",
    IDENTITY_ROLES: SERVICE_IDENTITY + "/api/identity/roles",
    IDENTITY_TOKEN_RENEW: SERVICE_IDENTITY + "/api/identity/renew",
    IDENTITY_ACTIVATE_USER: SERVICE_IDENTITY + "/api/identity/enable",
    IDENTITY_DEACTIVATE_USER: SERVICE_IDENTITY + "/api/identity/disable",
    IDENTITY_CHANGE_PASSWORD: SERVICE_IDENTITY + "/api/identity/updatepassword",
    IDENTITY_LOGOUT: SERVICE_IDENTITY + "/api/identity/logout",
    IDENTITY_PASSWORD_RESET_REQUEST: SERVICE_IDENTITY + "/api/identity/resetpassword/{email}",
    IDENTITY_REMOVE_EMAIL_BLOCK: SERVICE_IDENTITY + "/api/identity/RemoveEmailBlock/{email}",
    //IDENTITY_LOGOUT_USER: SERVICE_IDENTITY + '/api/identity/logoutuser/{email}',
    IDENTITY_LOGOUT_USER: SERVICE_IDENTITY + "/api/identity/logoutuser",
    IDENTITY_PASSWORD_RESET: SERVICE_IDENTITY + "/api/identity/resetpassword",
    IDENTITY_DRIVERS: SERVICE_IDENTITY + "/api/identity/drivers",
    IDENTITY_VERIFY: SERVICE_IDENTITY + "/api/identity/verifyemployee",
    IDENTITY_UAC: SERVICE_IDENTITY + "/api/identity/uac",
    IDENTITY_UAC2: SERVICE_IDENTITY + "/api/identity/uacs",
    IDENTITY_FORCE: SERVICE_IDENTITY + "/api/identity/force",
    QUICKTOKEN_REQUEST: SERVICE_IDENTITY + "/api/QuickToken/Generate",
    QUICKTOKEN_VALIDATE: SERVICE_IDENTITY + "/api/QuickToken/Validate",
    SET_BLOCK_LOGIN: SERVICE_IDENTITY + "/api/identity/SetLoginBlock/{val}",
    IDENTITY_GET_CODES: SERVICE_IDENTITY + "/api/identity/quicktokens/{email}",

    IDENTITY_TELEPHONE_VERIFICATION_TOKEN: SERVICE_IDENTITY + "/api/Identity/QuicktokenForTelephone",
    IDENTITY_TELEPHONE_TOKEN_VALIDATION: SERVICE_PATIENT + "/api/Patient/ValidatePhone",

    // ****************************************************************************************
    // WORKER SERVICE
    // ****************************************************************************************

    WORKER_ADD: SERVICE_WORKER + "/api/worker", //
    WORKER_GET_ALL: SERVICE_WORKER + "/api/worker", //

    WORKER_GET: SERVICE_WORKER + "/api/worker/{id}", //
    WORKER_PUT: SERVICE_WORKER + "/api/worker/{id}", //
    WORKER_DELETE: SERVICE_WORKER + "/api/worker/{id}", //
    WORKER_GET_ME: SERVICE_WORKER + "/api/worker/me", //
    WORKER_GET_ALL_DOCTORS: SERVICE_WORKER + "/api/worker/doctors",

    WORKER_GET_ALL_MEDICAL_TEAMS: SERVICE_WORKER + "/api/MedicalTeam",
    WORKER_ADD_MEDICAL_TEAM: SERVICE_WORKER + "/api/MedicalTeam",
    WORKER_DELETE_MEDICAL_TEAM: SERVICE_WORKER + "/api/MedicalTeam/{id}",

    EMPLOYEE_ADD: SERVICE_WORKER + "/api/employee", //
    EMPLOYEE_GET_ALL_DOMAINLESS: SERVICE_WORKER + "/api/employee", //
    EMPLOYEE_GET_ALL: SERVICE_WORKER + "/api/employee/AllInMyDomain", //
    EMPLOYEE_GET: SERVICE_WORKER + "/api/employee/{id}", //
    EMPLOYEE_PUT: SERVICE_WORKER + "/api/employee/{id}", //
    EMPLOYEE_DELETE: SERVICE_WORKER + "/api/employee/{id}", //
    EMPLOYEE_PUT_EMAIL: SERVICE_WORKER + "/api/employee/email/{id}", //
    EMPLOYEE_CHANGE_MY_DOMAIN: SERVICE_WORKER + "/api/employee/ChangeMyDomain/{target}", //
    EMPLOYEE_GET_CHANGES: SERVICE_WORKER + "/api/employee/Changes/{id}", //
    EMPLOYEE_GET_CHANGE_DETAILS: SERVICE_WORKER + "/api/employee/ChangeDetails/{id}", //

    MEDICAL_DEVICE_ADD: SERVICE_WORKER + "/api/MedicalDevice", //
    MEDICAL_DEVICE_GET_ALL: SERVICE_WORKER + "/api/MedicalDevice", //
    MEDICAL_DEVICE_GET: SERVICE_WORKER + "/api/MedicalDevice/{id}", //
    MEDICAL_DEVICE_PUT: SERVICE_WORKER + "/api/MedicalDevice/{id}", //
    MEDICAL_DEVICE_DELETE: SERVICE_WORKER + "/api/MedicalDevice/{id}", //

    EMPLOYEE_JOBS: SERVICE_WORKER + "/api/employee/job/{id}", //
    EMPLOYEE_GET_ME: SERVICE_WORKER + "/api/employee/me", //
    EMPLOYEE_GET_ALL_BY_POSITION: SERVICE_WORKER + "/api/employee/position?id={id}",
    EMPLOYEE_GET_ALL_BY_POSITIONS: SERVICE_WORKER + "/api/employee/positions",

    CLINICS_ADD_CLINIC: SERVICE_WORKER + "/api/clinicsteleaddress",
    CLINICS_GET_CLINIC_ADRESSES: SERVICE_WORKER + "/api/clinicsteleaddress",
    CLINICS_GET_UPDATE_DELETE: SERVICE_WORKER + "/api/clinicsteleaddress/{id}",

    GDPR_PUT: SERVICE_WORKER + "/api/employee/gdpr/{id}",

    EMPLOYEE_PERMISSION_GET_ALL: SERVICE_WORKER + "/api/EmployeePermission",
    EMPLOYEE_PERMISSION_GET: SERVICE_WORKER + "/api/EmployeePermission/{id}",

    EMPLOYEE_PERMISSION_TYPE_GET_ALL: SERVICE_WORKER + "/api/EmployeePermissionType",
    EMPLOYEE_PERMISSION_TYPE_GET: SERVICE_WORKER + "/api/EmployeePermissionType/{id}",

    EMPLOYEE_POSITION_GET_ALL: SERVICE_WORKER + "/api/EmployeePosition",
    EMPLOYEE_POSITION_GET: SERVICE_WORKER + "/api/EmployeePosition/{id}",
    EMPLOYEE_POSITION_ADD: SERVICE_WORKER + "/api/EmployeePosition", //new
    EMPLOYEE_POSITION_UPDATE: SERVICE_WORKER + "/api/EmployeePosition/{id}", //new
    EMPLOYEE_POSITION_DELETE: SERVICE_WORKER + "/api/EmployeePosition/{id}", //new
    EMPLOYEE_POSITION_GET_ALL_BY_EMPLOYEE: SERVICE_WORKER + "/api/EmployeePosition/Employee/{employeeId}", //NEW

    EMPLOYEE_POSITION_TYPE_GET_ALL: SERVICE_WORKER + "/api/EmployeePositionType",
    EMPLOYEE_POSITION_TYPE_GET: SERVICE_WORKER + "/api/EmployeePositionType/{id}",

    EMPLOYEE_POSITION_SUBTYPE_GET_ALL: SERVICE_WORKER + "/api/EmployeePositionSubtype",
    EMPLOYEE_POSITION_SUBTYPE_GET: SERVICE_WORKER + "/api/EmployeePositionSubtype/{id}",

    EMPLOYEE_CERTIFICATE_UPLOAD: SERVICE_WORKER + "/api/EmployeeCertificate/Upload",
    EMPLOYEE_CERTIFICATE_GET_ALL: SERVICE_WORKER + "/api/EmployeeCertificate",
    EMPLOYEE_CERTIFICATE_GET: SERVICE_WORKER + "/api/EmployeeCertificate/{id}",
    EMPLOYEE_CERTIFICATE_GET_BY_WORKER: SERVICE_WORKER + "/api/EmployeeCertificate/GetForWorker/{id}",
    EMPLOYEE_CERTIFICATE_DOWNLOAD: SERVICE_WORKER + "/api/EmployeeCertificate/Download/{id}",

    EMPLOYEE_FILE_UPLOAD: SERVICE_WORKER + "/api/EmployeeFile/Upload", //New
    EMPLOYEE_FILE_GET_ALL: SERVICE_WORKER + "/api/EmployeeFile", //New
    EMPLOYEE_FILE_GET: SERVICE_WORKER + "/api/EmployeeFile/{id}", //New
    EMPLOYEE_FILE_GET_BY_WORKER: SERVICE_WORKER + "/api/EmployeeFile/GetForWorker/{id}", //NEW
    EMPLOYEE_FILE_DOWNLOAD: SERVICE_WORKER + "/api/EmployeeFile/Download/{id}", //NEW
    EMPLOYEE_FILE_DELETE: SERVICE_WORKER + "/api/EmployeeFile/{id}", //NEW

    EMPLOYEE_CUSTOM_DRUG_POST: SERVICE_WORKER + "/api/CustomDrug",
    EMPLOYEE_CUSTOM_DRUG_GET_BY_EMPLOYEE: SERVICE_WORKER + "/api/CustomDrug/GetByEmployee/{id}",
    EMPLOYEE_CUSTOM_DRUG_PUT: SERVICE_WORKER + "/api/CustomDrug/{id}",
    EMPLOYEE_CUSTOM_DRUG_DELETE: SERVICE_WORKER + "/api/CustomDrug/{id}",

    EMPLOYEE_SMS_CONFIG_ALL: SERVICE_WORKER + "/api/SmsConfiguration",
    EMPLOYEE_SMS_CONFIG_BY_ID: SERVICE_WORKER + "/api/SmsConfiguration/{id}",

    MED_QUESTIONS_CONFIG_GET_ALL: SERVICE_WORKER + "/api/MedQuestionsConfig", //NEW
    MED_QUESTIONS_CONFIG_GET_BY_ID: SERVICE_WORKER + "/api/MedQuestionsConfig/{id}", //NEW
    MED_QUESTIONS_CONFIG_GET_BY_PATIENT: SERVICE_WORKER + "/api/MedQuestionsConfig/ByPatient/{id}", //NEW
    MED_QUESTIONS_CONFIG_GET_BY_EMPLOYEE: SERVICE_WORKER + "/api/MedQuestionsConfig/ByEmployee/{id}", //NEW
    MED_QUESTIONS_CONFIG_GET_FILTER: SERVICE_WORKER + "/api/MedQuestionsConfig/Filter/{clinicId}/{workerId}/{patientId}", //NEW
    MED_QUESTIONS_CONFIG_GET_FULL_BY_ID: SERVICE_WORKER + "/api/MedQuestionsConfig/Full/{id}",
    MED_QUESTIONS_CONFIG_GET_FULL_BY_CLINIC: SERVICE_WORKER + "/api/MedQuestionsConfig/Full/ByClinic/{id}",

    MED_QUESTION_GROUP_GET_ALL: SERVICE_WORKER + "/api/MedQuestionGroup",
    MED_QUESTION_GROUP_GET_BY_ID: SERVICE_WORKER + "/api/MedQuestionGroup/{id}", //NEW
    MED_QUESTION_GROUP_GET_BY_INTERNAL_ID: SERVICE_WORKER + "/api/MedQuestionGroup/ByInternal/{id}",
    MED_QUESTION_GROUP_GET_BY_TYPE: SERVICE_WORKER + "/api/MedQuestionGroup/ByType/{type}",

    MED_QUESTION_GET_ALL: SERVICE_WORKER + "/api/MedQuestion",
    MED_QUESTION_GET_BY_ID: SERVICE_WORKER + "/api/MedQuestion/{id}",
    MED_QUESTION_GET_SORTED: SERVICE_WORKER + "/api/MedQuestion/Sorted/{id}",
    MED_QUESTION_ADD_FROM_OLD: SERVICE_WORKER + "/api/MedQuestion/FromOld",
    MED_QUESTION_ADD_MANY_FROM_OLD: SERVICE_WORKER + "/api/MedQuestion/ManyFromOld",

    MED_QUESTION_ATTACHMENTS_GET_ALL: SERVICE_WORKER + "/api/MedQuestionAttachment",
    MED_QUESTION_ATTACHMENTS_GET_BY_ID: SERVICE_WORKER + "/api/MedQuestionAttachment/{id}",
    MED_QUESTION_ATTACHMENTS_GET_BY_INTERNAL_ID: SERVICE_WORKER + "/api/MedQuestionAttachment/ByInternal/{id}",
    MED_QUESTION_ATTACHMENTS_GET_BY_TYPE: SERVICE_WORKER + "/api/MedQuestionAttachment/ByType/{id}",
    MED_QUESTION_ATTACHMENTS_UPLOAD_ATTACHMENT: SERVICE_WORKER + "/api/MedQuestionAttachment/UploadAttachment",
    MED_QUESTION_ATTACHMENTS_DOWNLOAD: SERVICE_WORKER + "/api/MedQuestionAttachment/Download/{id}",

    EMPLOYEE_FORMULA_GET_ALL: SERVICE_WORKER + "/api/EmployeeFormula",
    EMPLOYEE_FORMULA_GET_BY_ID: SERVICE_WORKER + "/api/EmployeeFormula/{id}",
    EMPLOYEE_FORMULA_GET_BY_EMPLOYEE: SERVICE_WORKER + "/api/EmployeeFormula/ByEmployee/{id}",
    EMPLOYEE_FORMULA_GET_BY_TYPE: SERVICE_WORKER + "/api/EmployeeFormula/ByType/{type}/{type2}/{type3}",

    OFFICES_GET_BY_FACILITY_ID: SERVICE_WORKER + "/api/Office/GetByFacility/{id}",

    EMPLOYEE_EXAMINATION_CONFIGURATION_GET_ALL: SERVICE_WORKER + "/api/ExaminationConfiguration",
    EMPLOYEE_EXAMINATION_CONFIGURATION_ADD: SERVICE_WORKER + "/api/ExaminationConfiguration",
    EMPLOYEE_EXAMINATION_CONFIGURATION_GET_BY_ID: SERVICE_WORKER + "/api/ExaminationConfiguration/{id}",
    EMPLOYEE_EXAMINATION_CONFIGURATION_DELETE_BY_ID: SERVICE_WORKER + "/api/ExaminationConfiguration/{id}",
    EMPLOYEE_EXAMINATION_CONFIGURATION_FILTER: SERVICE_WORKER + "/api/ExaminationConfiguration/Filter",

    EMPLOYEE_EXAMSET_GET_ALL: SERVICE_WORKER + "/api/EmployeeExamSet",
    EMPLOYEE_EXAMSET_ADD: SERVICE_WORKER + "/api/EmployeeExamSet",
    EMPLOYEE_EXAMSET_PUT_BY_ID: SERVICE_WORKER + "/api/EmployeeExamSet/{id}",
    EMPLOYEE_EXAMSET_DELETE_BY_ID: SERVICE_WORKER + "/api/EmployeeExamSet/{id}",
    EMPLOYEE_EXAMSET_GET_BY_WORKER_ID: SERVICE_WORKER + "/api/EmployeeExamSet/ByEmployee/{id}",

    EMPLOYEE_PROJECT_GOAL_GET_ALL: SERVICE_WORKER + "/api/Goal",
    EMPLOYEE_PROJECT_GOAL_GET_BY_ID: SERVICE_WORKER + "/api/Goal/{id}",
    EMPLOYEE_PROJECT_GOAL_PUT: SERVICE_WORKER + "/api/Goal/{id}",
    EMPLOYEE_PROJECT_GOAL_ADD_TASK_GROUPS: SERVICE_WORKER + "/api/Goal/AddTaskGroups/{id}",
    EMPLOYEE_PROJECT_GOAL_ADD_TASKS: SERVICE_WORKER + "/api/Goal/AddTasks/{id}",
    EMPLOYEE_PROJECT_GOAL_POST: SERVICE_WORKER + "/api/Goal",
    EMPLOYEE_PROJECT_GOAL_DELETE: SERVICE_WORKER + "/api/Goal/{id}",
    EMPLOYEE_PROJECT_GOAL_GET_BY_FILTER: SERVICE_WORKER + "/api/Goal/ByFilters",
    EMPLOYEE_PROJECT_GOAL_GET_FULL_INFO: SERVICE_WORKER + "/api/Goal/GetFullInfo/{id}",
    EMPLOYEE_PROJECT_GOAL_REMOVE: SERVICE_WORKER + "/api/Goal/Remove/{id}",
    EMPLOYEE_PROJECT_GOAL_GET_CHILDREN: SERVICE_WORKER + "/api/Goal/GetChildren/{masterId}",

    EMPLOYEE_PROGRAMME_GET_ALL: SERVICE_WORKER + "/api/WorkerProgramme",
    EMPLOYEE_PROGRAMME_ADD: SERVICE_WORKER + "/api/WorkerProgramme",
    EMPLOYEE_PROGRAMME_GET_BY_ID: SERVICE_WORKER + "/api/WorkerProgramme/{id}",
    EMPLOYEE_PROGRAMME_PUT: SERVICE_WORKER + "/api/WorkerProgramme/{id}",
    EMPLOYEE_PROGRAMME_DELETE: SERVICE_WORKER + "/api/WorkerProgramme/{id}",
    EMPLOYEE_PROGRAMME_CREATE_GOAL: SERVICE_WORKER + "/api/WorkerProgramme/CreateGoal/{programmeId}",
    EMPLOYEE_PROGRAMME_DELETE_GOAL: SERVICE_WORKER + "/api/WorkerProgramme/DeleteGoal/{programmeId}/{goalId}",

    EMPLOYEE_PROJECT_GET_ALL: SERVICE_WORKER + "/api/WorkerProject",
    EMPLOYEE_PROJECT_ADD: SERVICE_WORKER + "/api/WorkerProject",
    EMPLOYEE_PROJECT_GET_BY_ID: SERVICE_WORKER + "/api/WorkerProject/{id}",
    EMPLOYEE_PROJECT_PUT: SERVICE_WORKER + "/api/WorkerProject/{id}",
    EMPLOYEE_PROJECT_DELETE: SERVICE_WORKER + "/api/WorkerProject/{id}",
    EMPLOYEE_PROJECT_CREATE_GOAL: SERVICE_WORKER + "/api/WorkerProject/CreateGoal/{projectId}",
    EMPLOYEE_PROJECT_DELETE_GOAL: SERVICE_WORKER + "/api/WorkerProject/DeleteGoal/{projectId}/{goalId}",

    EMPLOYEE_TEAM_GET_ALL: SERVICE_WORKER + "/api/WorkerTeam",
    EMPLOYEE_TEAM_ADD: SERVICE_WORKER + "/api/WorkerTeam",
    EMPLOYEE_TEAM_GET_BY_WORKER_ID: SERVICE_WORKER + "/api/WorkerTeam/GetByEmployee/{employeeId}",
    EMPLOYEE_TEAM_GET_BY_ID: SERVICE_WORKER + "/api/WorkerTeam/{id}",
    EMPLOYEE_TEAM_PUT: SERVICE_WORKER + "/api/WorkerTeam/{id}",
    EMPLOYEE_TEAM_DELETE: SERVICE_WORKER + "/api/WorkerTeam/{id}",
    EMPLOYEE_TEAM_UPLOAD_ATTACHMENT: SERVICE_WORKER + "/api/WorkerTeam/UploadAttachment/{teamId}",
    EMPLOYEE_TEAM_UPLOAD_VERSION_ATTACHMENT: SERVICE_WORKER + "/api/WorkerTeam/UploadVersionAttachment/{id}",
    EMPLOYEE_TEAM_CHECK_EXIST_ATTACHMENT: SERVICE_WORKER + "/api/WorkerTeam/CheckExistAttachment/{id}",
    EMPLOYEE_TEAM_ATTACHMENT_GET_BY_TEAM: SERVICE_WORKER + "/api/WorkerTeam/AttachmentGetByTeam/{teamInternalId}",
    EMPLOYEE_TEAM_CREATE_GOAL: SERVICE_WORKER + "/api/WorkerTeam/CreateGoal/{teamId}",
    EMPLOYEE_TEAM_DELETE_GOAL: SERVICE_WORKER + "/api/WorkerTeam/DeleteGoal/{teamId}/{goalId}",
    EMPLOYEE_TEAM_GET_BY_INTERNAL_ID: SERVICE_WORKER + "/api/WorkerTeam/GetByInternalId/{internalId}",

    EMPLOYEE_TASK_INFO_GET_ALL: SERVICE_WORKER + "/api/TaskInfo",
    EMPLOYEE_TASK_INFO_ADD: SERVICE_WORKER + "/api/TaskInfo",
    EMPLOYEE_TASK_INFO_GET_BY_ID: SERVICE_WORKER + "/api/TaskInfo/{id}",
    EMPLOYEE_TASK_INFO_PUT: SERVICE_WORKER + "/api/TaskInfo/{id}",
    EMPLOYEE_TASK_INFO_DELETE: SERVICE_WORKER + "/api/TaskInfo/{id}",
    EMPLOYEE_TASK_INFO_GET_BY_TEAM: SERVICE_WORKER + "/api/TaskInfo/GetByTeam/{internalId}",
    EMPLOYEE_TASK_INFO_GET_BY_PROJECT: SERVICE_WORKER + "/api/TaskInfo/GetByProject/{internalId}",
    EMPLOYEE_TASK_INFO_GET_BY_PROGRAMME: SERVICE_WORKER + "/api/TaskInfo/GetByProgramme/{internalId}",
    EMPLOYEE_TASK_INFO_GROUPS_GET_ALL: SERVICE_WORKER + "/api/TaskInfo/Groups",
    EMPLOYEE_TASK_INFO_GROUP_GET_BY_ID: SERVICE_WORKER + "/api/TaskInfo/GroupById/{id}",
    EMPLOYEE_TASK_INFO_GROUP_ADD: SERVICE_WORKER + "/api/TaskInfo/CreateGroup",
    EMPLOYEE_TASK_INFO_GROUP_PUT: SERVICE_WORKER + "/api/TaskInfo/UpdateGroup/{id}",
    EMPLOYEE_TASK_INFO_GROUP_DELETE: SERVICE_WORKER + "/api/TaskInfo/DeleteGroup/{id}",
    EMPLOYEE_TASK_INFO_GET_BY_WORKER_ID: SERVICE_WORKER + "/api/TaskInfo/GetByEmployee/{id}",

    EMPLOYEE_PAPER_NUMBER_GROUP_GET_BY_ID: SERVICE_WORKER + "/api/PaperNumberGroup/{id}",
    EMPLOYEE_PAPER_NUMBER_GROUP_UPLOAD: SERVICE_WORKER + "/api/PaperNumberGroup/Upload",
    EMPLOYEE_PAPER_NUMBER_GROUP_CHANGE_STATUS: SERVICE_WORKER + "/api/PaperNumberGroup/ChangeStatus/{id}/{status}",
    EMPLOYEE_PAPER_NUMBER_GROUP_CHANGE_NUMBER_STATUS: SERVICE_WORKER + "/api/PaperNumberGroup/ChangeNumberStatus/{id}",
    EMPLOYEE_PAPER_NUMBER_GROUP_GET_BY_WORKER: SERVICE_WORKER + "/api/PaperNumberGroup/ByWorkerId/{workerId}",
    EMPLOYEE_PAPER_NUMBER_GROUP_GET_PRESCRIPTION_DATA: SERVICE_WORKER + "/api/PaperNumberGroup/GetPrescriptionData",
    EMPLOYEE_PAPER_NUMBER_GROUP_GET_AVAILABLE_FOR_WORKER: SERVICE_WORKER + "/api/PaperNumberGroup/GetWorkerAvailable/{domain}/{workerId}",



    // ****************************************************************************************
    // INVENTORY SERVICE
    // ****************************************************************************************

    SWAGGER_DOCS: SERVICE_INVENTORY + "/api/Docs",

    INVENTORY_ADD_ROOM: SERVICE_INVENTORY + "/api/InventoryRoom",
    INVENTORY_UPDATE_ROOM: SERVICE_INVENTORY + "/api/InventoryRoom/{id}",
    INVENTORY_ADD_ITEM: SERVICE_INVENTORY + "/api/InventoryItem",
    INVENTORY_GET_MULTIPLE: SERVICE_INVENTORY + "/api/InventoryItem/GetMultiple",
    INVENTORY_UPDATE_ITEM: SERVICE_INVENTORY + "/api/InventoryItem/{id}",

    INVENTORY_GET_ITEMS: SERVICE_INVENTORY + "/api/InventoryItem/GetItemsForRoom/{id}",
    INVENTORY_GET_ROOMS: SERVICE_INVENTORY + "/api/InventoryRoom/GetRoomsInFacility/{id}",
    INVENTORY_FULL_INFO: SERVICE_INVENTORY + "/api/InventoryFacility/FullInfo/{facilityId}",

    INVENTORY_GET_ITEM_BY_BARCODE: SERVICE_INVENTORY + "/api/InventoryItem/code/{id}",

    INVENTORY_RELEASE_BARCODE: SERVICE_INVENTORY + "/api/InventoryItem/ReleaseCode/{code}",

    INVENTORY_SEARCH_BARCODE: SERVICE_INVENTORY + "/api/InventoryItem/SearchByCode/{id}",
    INVENTORY_SEARCH_SERIAL: SERVICE_INVENTORY + "/api/InventoryItem/SearchBySerial/{id}",

    INVENTORY_WORKPLACE_GET_ALL: SERVICE_INVENTORY + "/api/InventoryWorkplace",
    INVENTORY_WORKPLACE_GET: SERVICE_INVENTORY + "/api/InventoryWorkplace/{id}",
    INVENTORY_WORKPLACE_GET_FULL_INFO: SERVICE_INVENTORY + "/api/InventoryWorkplace/FullInfo/{id}",
    INVENTORY_WORKPLACE_GET_BY_ROOM: SERVICE_INVENTORY + "/api/InventoryWorkplace/ByRoom/{roomId}",
    INVENTORY_WORKPLACE_GET_BY_CATEGORY: SERVICE_INVENTORY + "/api/InventoryWorkplace/ByCategory/{categoryId}",

    // ****************************************************************************************
    // PATIENT SERVICE
    // ****************************************************************************************

    PRIVILEGE_TYPES_GENERAL: SERVICE_PATIENT + "/api/PrivilegeType",
    PRIVILEGE_TYPES_GENERAL_BY_ID: SERVICE_PATIENT + "/api/PrivilegeType/{id}",

    PATIENT_PRIVILEGE_GENERAL: SERVICE_PATIENT + "/api/PatientPrivilege",
    PATIENT_PRIVILEGE_GENERAL_BY_ID: SERVICE_PATIENT + "/api/PatientPrivilege/{id}",
    PATIENT_PRIVILEGE_ACTIVE: SERVICE_PATIENT + "/api/PatientPrivilege/ActiveForPatient/{id}",
    PATIENT_PRIVILEGE_ALL: SERVICE_PATIENT + "/api/PatientPrivilege/AllForPatient/{id}",








    PATIENT_ADD: SERVICE_PATIENT + "/api/patient",
    PATIENT_SEARCH: SERVICE_PATIENT + "/api/patient/search/{type}/{pharse}",
    PATIENT_SEARCH_DOUBLE: SERVICE_PATIENT + "/api/patient/search/{type}/{pharse}/{type2}/{pharse2}",
    PATIENT_PUT: SERVICE_PATIENT + "/api/patient/{id}",
    PATIENT_DELETE: SERVICE_PATIENT + "/api/patient/{id}",

    PATIENT_GET_BY_ID: SERVICE_PATIENT + "/api/Patient/GetPatientById/{id}",
    PATIENT_GET_BY_PESEL: SERVICE_PATIENT + "/api/Patient/GetPatientByPesel/{pesel}",

    PATIENT_GET_MANY: SERVICE_PATIENT + "/api/Patient/getmany",
    PATIENT_GET_FOR_40_PLUS: SERVICE_PATIENT + "/api/Patient/GetPatients40plus/{domain}",

    PATIENT_SET_COMPLIENCE: SERVICE_PATIENT + "/api/Patient/SetComplience/{id}/{complience}",
    PATIENT_SET_EXPERIENCE: SERVICE_PATIENT + "/api/Patient/SetExperience/{id}/{experience}",

    PATIENT_SET_DISABILITY: SERVICE_PATIENT + "/api/Patient/UpdateDisability",
    PATIENT_SET_HEARING: SERVICE_PATIENT + "/api/Patient/UpdateHearing",
    PATIENT_SET_HOMELESS: SERVICE_PATIENT + "/api/Patient/UpdateHomeless",
    PATIENT_SET_QUARANTINE: SERVICE_PATIENT + "/api/Patient/UpdateQuarantine",
    PATIENT_SET_DEBT: SERVICE_PATIENT + "/api/Patient/UpdateDebt",

    PATIENT_HANDLE_NOTE: SERVICE_PATIENT + "/api/Patient/note/{id}",

    PATIENT_GET_FAMILY: SERVICE_PATIENT + "/api/Patient/GetPatientFamilyInfo/{id}",
    PATIENT_POST_FAMILY: SERVICE_PATIENT + "/api/Patient/UpdatePatientFamilyInfo/{version}",

    PATIENT_GET_INSURANCE_INFO: SERVICE_PATIENT + "/api/Patient/GetInsurances/{id}",
    PATIENT_SEND_INSURANCE_INFO: SERVICE_PATIENT + "/api/Patient/UpdateInsurances/{version}",

    PATIENT_GET_IDS_INFO: SERVICE_PATIENT + "/api/Patient/GetIdentifications/{id}",
    PATIENT_SEND_IDS_INFO: SERVICE_PATIENT + "/api/Patient/UpdateIdentifications/{version}",

    PATIENT_POST_STATUSES: SERVICE_PATIENT + "/api/Patient/UpdateStatuses/{version}",
    PATIENT_GET_STATUSES: SERVICE_PATIENT + "/api/Patient/GetStatuses/{id}",
    PATIENT_GET_MANY_STATUSES: SERVICE_PATIENT + "/api/Patient/GetManyStatuses",

    PATIENT_GET_NOTES: SERVICE_PATIENT + "/api/Patient/GetNotes/{id}",
    PATIENT_POST_NOTES: SERVICE_PATIENT + "/api/Patient/UpdateNotes/{version}",

    // PATIENT_GET_DECLARATIONS: SERVICE_PATIENT + "/api/Patient/GetDeclarations/{id}",
    // PATIENT_POST_DECLARATIONS: SERVICE_PATIENT + "/api/Patient/UpdateDeclarations/{version}",

    PATIENT_GET_FAMILY_INFOS: SERVICE_PATIENT + "/api/Patient/GetPatientFamilyInfo/{id}",

    PATIENT_GET_INSURANCES: SERVICE_PATIENT + "/api/Patient/GetInsurances/{id}",

    PATIENT_POST_DECLARATION: SERVICE_PATIENT_DATA + "/api/PatientData/AddDeclarations",
    PATIENT_GET_DECLARATIONS_BY_PATIENT_ID: SERVICE_PATIENT_DATA + "/api/PatientData/getPatientDeclarations/{id}",

    PATIENT_UPDATE_STATS: SERVICE_PATIENT + "/api/Patient/UpdateStats/{version}",

    PATIENT_DEKLE_GENERATE: SERVICE_PATIENT + "/api/Patient/Tools/GenerateForMonth/{year}/{month}",

    PATIENT_POST_FILE: SERVICE_PATIENT_DATA + "/api/FileUpload/Upload",
    PATIENT_GET_FILES: SERVICE_PATIENT_DATA + "/api/FileUpload/GetFilesData?PatientId={patientId}",
    PATIENT_DOWNLOAD_FILE: SERVICE_PATIENT_DATA + "/api/FileUpload/GetFile/{FileNameId}/{PatientId}/{WorkerId}",
    PATIENT_REMOVE_FILE_UPLOAD: SERVICE_PATIENT_DATA + "/api/FileUpload/Remove/{id}",
    PATIENT_RESTORE_FILE_UPLOAD: SERVICE_PATIENT_DATA + "/api/FileUpload/Restore/{id}",
    PATIENT_CHECK_USE_IN_APPOINTMENT_FILE_UPLOAD: SERVICE_PATIENT_DATA + "/api/FileUpload/CheckUseInAppointment/{id}",
    PATIENT_UNCHECK_USE_IN_APPOINTMENT_FILE_UPLOAD: SERVICE_PATIENT_DATA + "/api/FileUpload/UncheckUseInAppointment/{id}",

    PATIENT_GET_RISKS: SERVICE_PATIENT_DATA + "/api/Risks",
    PATIENT_POST_ALLERGIES: SERVICE_PATIENT_DATA + "/api/PatientData/AddAllergy",
    PATIENT_GET_ALLERGIES: SERVICE_PATIENT_DATA + "/api/PatientData/GetAllergiesItems",
    PATIENT_GET_CHECKLIST_OLD: SERVICE_PATIENT_DATA + "/api/TherapeuticProgram/CheckPrograms?PatientId={id}",
    PATIENT_GET_CHECKLIST: SERVICE_PATIENT_DATA + "/api/TherapeuticProgram/CheckProgramsForPatient/{id}",
    PATIENT_UPDATE_PROGRAM_STATUS: SERVICE_PATIENT_DATA + "/api/TherapeuticProgram/{id}",
    PATIENT_GET_PREGNANCY_INFO: SERVICE_PATIENT_DATA + "/api/PatientData/GetPregnancyInfo/{id}",
    PATIENT_POST_PREGNANCY_INFO: SERVICE_PATIENT_DATA + "/api/PatientData/AddPregnancyInfo",
    PATIENT_GET_MEDICAL_INFORMATION: SERVICE_PATIENT_DATA + "/api/PatientData/GetPatientMedicalInformation/{patientId}",

    PATIENT_GET_ALL_MONGO_INCIDENTS: SERVICE_PATIENT_DATA + "/api/Incident/GetAllIncidentMongo",
    PATIENT_GET_MONGO_INCIDENT_BY_ID: SERVICE_PATIENT_DATA + "/api/Incident/GetIncidentMongoById/{id}",
    PATIENT_GET_MONGO_INCIDENTS_BY_PATIENT_ID: SERVICE_PATIENT_DATA + "/api/Incident/GetIncidentMongoByPatientId/{id}",
    PATIENT_CREATE_MONGO_INCIDENT: SERVICE_PATIENT_DATA + "/api/Incident/CreateIncidentMongo",
    PATIENT_UPDATE_MONGO_INCIDENT_BY_ID: SERVICE_PATIENT_DATA + "/api/Incident/UpdateIncidentMongo/{id}",
    PATIENT_DELETE_MONGO_INCIDENT_BY_ID: SERVICE_PATIENT_DATA + "/api/Incident/DeleteIncidentMongo/{id}",

    PATIENT_ADD_FILE_INFO: SERVICE_PATIENT_DATA + "/api/PatientFiles/AddFileInfo/{patientId}",
    PATIENT_GET_PATIENT_FILES: SERVICE_PATIENT_DATA + "/api/PatientFiles/GetPatientFiles/{patientId}",
    PATIENT_GET_PATIENT_FILES_PROGRAM: SERVICE_PATIENT_DATA + "/api/PatientFiles/GetByProgramIdForPatient/{programId}/{patientId}",
    PATIENT_REMOVE_FILE: SERVICE_PATIENT_DATA + "/api/PatientFiles/Remove/{patientId}",
    PATIENT_RESTORE_FILE: SERVICE_PATIENT_DATA + "/api/PatientFiles/Restore/{patientId}/{fileId}",
    PATIENT_GET_FILES_USED_IN_APPOINTMENT: SERVICE_PATIENT_DATA + "/api/PatientFiles/GetFilesUseinAppointments/{patientId}/{appId}",

    PATIENT_POST_MED_QUESTIONS: SERVICE_PATIENT_DATA + "/api/PatientMedQuestion",
    PATIENT_GET_MED_QUESTIONS: SERVICE_PATIENT_DATA + "/api/PatientMedQuestion/{id}",
    PATIENT_GET_MED_QUESTIONS_BY_PATIENT: SERVICE_PATIENT_DATA + "/api/PatientMedQuestion/ByPatient/{domain}/{patientId}",
    PATIENT_GET_MED_QUESTIONS_BY_APPOINTMENT: SERVICE_PATIENT_DATA + "/api/PatientMedQuestion/ByAppointment/{domain}/{patientId}/{appId}",
    PATIENT_REMOVE_MED_QUESTION: SERVICE_PATIENT_DATA + "/api/PatientMedQuestion/Remove/{id}",
    PATIENT_RESTORE_MED_QUESTION: SERVICE_PATIENT_DATA + "/api/PatientMedQuestion/Restore/{id}",
    PATIENT_CREATE_MED_QUESTIONS_FROM_TEMPLATES: SERVICE_PATIENT_DATA + "/api/PatientMedQuestion/CreateFromTemplates",

    PATIENT_COUNT_VISITS: SERVICE_PATIENT_DATA + "/api/PatientData/CountHistoryAppointments/{patientId}",

    // NEW FOR PATIENT DATA SERVICE
    PATIENT_ATTESTATION_GET_ALL: SERVICE_PATIENT_DATA + "/api/PatientAttestation",
    PATIENT_ATTESTATION_GET: SERVICE_PATIENT_DATA + "/api/PatientAttestation/{id}",
    PATIENT_ATTESTATION_GET_BY_PATIENT: SERVICE_PATIENT_DATA + "/api/PatientAttestation/ByPatientId/{id}",
    PATIENT_ATTESTATION_REMOVE: SERVICE_PATIENT_DATA + "/api/PatientAttestation/Remove/{id}",
    PATIENT_ATTESTATION_RESTORE: SERVICE_PATIENT_DATA + "/api/PatientAttestation/Restore/{id}",


    PD_ADD_BUNDLE: SERVICE_PATIENT_DATA + "/api/PatientData/AddBundle",
    PD_ADD_REGISTRATION_TO_BUNDLE: SERVICE_PATIENT_DATA + "/api/PatientData/AddRegistration/{bundleId}/{status}",
    PD_GET_UNFINISHED_REGISTRATIONS: SERVICE_PATIENT_DATA + "/api/PatientData/UnfinishedRegistrations/{workerId}",
    PD_GET_UNFINISHED_REGISTRATIONS_FACILITY: SERVICE_PATIENT_DATA + "/api/PatientData/UnfinishedRegistrationsInFacility/{facility}",

    PD_GET_DRUGS_INFO_BY_PATIENT_ID: SERVICE_PATIENT_DATA + "/api/PatientData/Info/GetDrugs/{patientId}",
    PD_GET_DIAGNOSES_INFO_BY_PATIENT_ID: SERVICE_PATIENT_DATA + "/api/PatientData/Info/GetDiagnoses/{patientId}",
    PD_GET_DIAGNOSES_WITH_DRUGS_BY_PATIENT_ID: SERVICE_PATIENT_DATA + "/api/PatientData/Info/GetDiagnosesAndDrugs/{patientId}",

    PD_UPDATE_DRUG_INFO: SERVICE_PATIENT_DATA + "/api/PatientData/Info/UpdateDrug/{patientId}/{baseVersion}",
    PD_UPDATE_DIAGNOSE_INFO: SERVICE_PATIENT_DATA + "/api/PatientData/Info/UpdateDiagnosis/{patientId}/{baseVersion}",

    PD_START_APPOINTMENT_OLD: SERVICE_PATIENT_DATA + "/api/PatientData/AppointmentStart/{patientId}",
    PD_START_APPOINTMENT_OLD_V2: SERVICE_PATIENT_DATA + "/api/PatientData/AppointmentStartV2/{patientId}",
    // PD_START_APPOINTMENT: SERVICE_PATIENT_DATA + "/api/PatientData/AppointmentStartV3/{patientId}",
    PD_START_APPOINTMENT: SERVICE_PATIENT_DATA + "/api/AppStart/StartV4/{patientId}",
    PD_BUNDLE_STATS: SERVICE_PATIENT_DATA + "/api/Stats/BundleStats",

    PD_PATIENT_DATA_QUESTIONS_GET_BY_PATIENT: SERVICE_PATIENT_DATA + "/api/PatientDataQuestions/ByPatient/{domain}/{patientId}",
    PD_PATIENT_DATA_QUESTIONS_GET_BY_REFERRAL: SERVICE_PATIENT_DATA + "/api/PatientDataQuestions/ByReferral/{domain}/{patientId}/{referralId}",
    PD_PATIENT_DATA_QUESTIONS_GET_ALL: SERVICE_PATIENT_DATA + "/api/PatientDataQuestions",
    PD_PATIENT_DATA_QUESTIONS_GET: SERVICE_PATIENT_DATA + "/api/PatientDataQuestions/{id}",
    PD_PATIENT_DATA_QUESTIONS_REMOVE: SERVICE_PATIENT_DATA + "/api/PatientDataQuestions/Remove/{id}",
    PD_PATIENT_DATA_QUESTIONS_RESTORE: SERVICE_PATIENT_DATA + "/api/PatientDataQuestions/Restore/{id}",

    DEKLE_GET_FOR_DOMAIN: SERVICE_PATIENT + "/api/Dekle/GetForDomain/{domain}",
    DEKLE_GET_ALL_OR_CREATE: SERVICE_PATIENT + "/api/Dekle",
    DEKLE_GET_BY_ID: SERVICE_PATIENT + "/api/Dekle/GetById/{id}",
    DEKLE_PROGRESS: SERVICE_PATIENT + "/api/Dekle/Progress/{id}",
    DEKLE_DOWNLOAD: SERVICE_PATIENT + "/api/Dekle/Download/{id}",
    DEKLE_RESPONSE_UPLOAD: SERVICE_PATIENT + "/api/Dekle/UploadResponse",

    PATIENT_GET_MED_GROUPS: SERVICE_PATIENT + "/api/Patient/GetMedGroups/{id}",
    PATIENT_POST_MED_GROUPS: SERVICE_PATIENT + "/api/Patient/UpdateMedGroups/{version}",

    PATIENT_GET_NURSE_PRESCRIPTIONS: SERVICE_PATIENT + "/api/Patient/GetNursePrescriptions/{id}",
    PATIENT_POST_NURSE_PRESCRIPTIONS: SERVICE_PATIENT + "/api/Patient/UpdateNursePrescriptions/{version}",

    PATIENT_IPOM_POST: SERVICE_PATIENT + "/api/PatientIpom",
    PATIENT_IPOM_GET: SERVICE_PATIENT + "/api/PatientIpom/{id}",
    PATIENT_IPOM_GET_BY_PATIENT_ID: SERVICE_PATIENT + "/api/PatientIpom/ForPatient/{id}",
    PATIENT_IPOM_LIGHT_GET_BY_PATIENT_ID: SERVICE_PATIENT + "/api/PatientIpom/LightForPatient/{id}",
    PATIENT_IPOM_DIAGNOSES_GET_BY_PATIENT_ID: SERVICE_PATIENT + "/api/PatientIpom/GetIpomDiagnoses/{id}",
    PATIENT_IPOM_CHANGE_STATUS: SERVICE_PATIENT + '/api/PatientIpom/ChangeStatus/{id}/{status}',
    PATIENT_IPOM_ADD_VERSION: SERVICE_PATIENT + '/api/PatientIpom/AddVersion/{id}',
    PATIENT_IPOM_SET_DOCUMENT: SERVICE_PATIENT + '/api/PatientIpom/SetDocument/{id}',
    PATIENT_IPOM_CANCEL_DOCUMENT: SERVICE_PATIENT + '/api/PatientIpom/CancelByNumber',



    PATIENT_HIPOM_POST: SERVICE_PATIENT + "/api/PatientHipom",
    PATIENT_HIPOM_GET: SERVICE_PATIENT + "/api/PatientHipom/{id}",
    PATIENT_HIPOM_GET_BY_PATIENT_ID: SERVICE_PATIENT + "/api/PatientHipom/ForPatient/{id}",
    PATIENT_HIPOM_CHANGE_STATUS: SERVICE_PATIENT + '/api/PatientHipom/ChangeStatus/{id}/{status}',
    PATIENT_HIPOM_ADD_VERSION: SERVICE_PATIENT + '/api/PatientHipom/AddVersion/{id}',
    PATIENT_HIPOM_SET_DOCUMENT: SERVICE_PATIENT + '/api/PatientHipom/SetDocument/{id}',
    PATIENT_HIPOM_CANCEL_DOCUMENT: SERVICE_PATIENT + '/api/PatientHipom/CancelByNumber',







    // PATIENT_GET_MED_GROUPS: SERVICE_PATIENT + '/api/Patient/GetMedGroups/{id}',
    // PATIENT_POST_MED_GROUPS: SERVICE_PATIENT + '/api/Patient/UpdateMedGroups/{version}',

    // PATIENT_GET_NURSE_PRESCRIPTIONS: SERVICE_PATIENT + '/api/Patient/GetNursePrescriptions/{id}',
    // PATIENT_POST_NURSE_PRESCRIPTIONS: SERVICE_PATIENT + '/api/Patient/UpdateNursePrescriptions/{version}',

    PATIENT_GET_CYTO_QUESTIONS: SERVICE_PATIENT_DATA + '/api/PatientData/CytoSimpleQuestions/{patientId}',

    PATIENT_GET_CHANGELOG: SERVICE_PATIENT + '/api/Patient/Changes/{id}',

    PATIENT_GET_END_APPOINTMENT_BY_EXTERNAL_SCHEDULE_ID: SERVICE_PATIENT_DATA + '/api/PatientData/Schedule/{patientId}/{externalScheduleId}',
    PATIENT_GET_END_APPOINTMENT: SERVICE_PATIENT_DATA + '/api/PatientData/Schedule/{patientId}/{appId}/{domainId}',

    PATIENT_GET_END_APPOINTMENT_EDM: SERVICE_PATIENT_DATA + '/api/PatientData/GetEdm/{bundleId}',

    PATIENT_GET_END_APPOINTMENT_HISTORY_DIAGNOSES: SERVICE_PATIENT_DATA + '/api/PatientData/HistoryDiagnoses/{patientId}',

    PATIENT_POST_ONGOING_APPOINTMENT: SERVICE_PATIENT_DATA + '/api/OngoingAppoitmentData',
    PATIENT_GET_ONGOING_APPOINTMENT_BY_EXTERNAL_ID: SERVICE_PATIENT_DATA + '/api/OngoingAppoitmentData/AppointmentExternalId/{id}',
    PATIENT_UPDATE_ONGOING_APPOINTMENT: SERVICE_PATIENT_DATA + '/api/OngoingAppoitmentData/{id}',
    PATIENT_DELETE_ONGOING_APPOINTMENT_BY_EXTERNAL_ID: SERVICE_PATIENT_DATA + '/api/OngoingAppoitmentData/DeleteAllWithExternalId/{id}',
    PATIENT_GET_ONGOING_APPOINTMENT_OLD: SERVICE_PATIENT_DATA + '/api/OngoingAppoitmentData/ByAppointment/{domainId}/{appointmentId}/{patientId}',
    PATIENT_GET_ONGOING_APPOINTMENT: SERVICE_PATIENT_DATA + '/api/OngoingAppoitmentData/ByAppointmentV2/{domainId}/{appointmentId}/{patientId}',
    PATIENT_DELETE_ONGOING_APPOINTMENT_FOR_APPOINTMENT: SERVICE_PATIENT_DATA + '/api/OngoingAppoitmentData/DeleteAllForAppointment/{domainId}/{appId}/{patientId}',
    PATIENT_DELETE_ONGOING_APPOINTMENT_FOR_CONTROL: SERVICE_PATIENT_DATA + '/api/OngoingAppoitmentData/DeleteAllForControl/{domainId}/{appId}/{patientId}/{control}',
    ONGOING_FOR_APPOINTMENT: SERVICE_PATIENT_DATA + '/api/OngoingAppoitmentData/GetByAppointment/{domainId}/{appId}/{lastOnly}',

    RIGET_HISTORY: SERVICE_PATIENT_DATA + "/api/ImportedDataRiget/RigetPatient/{id}/{pesel}",

    //Log appointment
    PATIENT_GET_LOG_APPOINTMENT_BY_ID: SERVICE_PATIENT_DATA + '/api/LogAppointment/{id}',
    PATIENT_UPDATE_LOG_APPOINTMENT: SERVICE_PATIENT_DATA + '/api/LogAppointment/{id}',
    PATIENT_CREATE_LOG_APPOINTMENT: SERVICE_PATIENT_DATA + '/api/LogAppointment',
    PATIENT_DELETE_LOG_APPOINTMENT: SERVICE_PATIENT_DATA + '/api/LogAppointment/{id}',
    PATIENT_GET_LOG_APPOINTMENT_BY_APPOINTMENT: SERVICE_PATIENT_DATA + '/api/LogAppointment/ByAppointment/{domainId}/{appointmentId}/{patientId}',
    PATIENT_GET_LOG_APPOINTMENT_BY_PATIENT: SERVICE_PATIENT_DATA + '/api/LogAppointment/Patient/{id}',
    PATIENT_GET_LOG_APPOINTMENT_BY_EMPLOYEE: SERVICE_PATIENT_DATA + '/api/LogAppointment/Employee/{id}',

    // NEW FOR PATIENT DATA SERVICE
    PATIENT_GET_APPOINTMENT_HISTORY: SERVICE_PATIENT_DATA + "/api/PatientData/AppointmentEndGetPatientItems/{PatientId}",

    // NEW FOR PATIENT DATA SERVICE
    PATIENT_CUSTOM_REFFERAL_GET_ALL: SERVICE_PATIENT_DATA + "/api/PatientCustomRefferal",
    PATIENT_CUSTOM_REFFERAL_GET: SERVICE_PATIENT_DATA + "/api/PatientCustomRefferal/{id}",
    PATIENT_CUSTOM_REFFERAL_GET_BY_PATIENT_ID: SERVICE_PATIENT_DATA + "/api/PatientCustomRefferal/ByPatientId/{id}",
    PATIENT_CUSTOM_REFERRAL_REMOVE: SERVICE_PATIENT_DATA + "/api/PatientCustomRefferal/Remove/{id}",
    PATIENT_CUSTOM_REFERRAL_RESTORE: SERVICE_PATIENT_DATA + "/api/PatientCustomRefferal/Restore/{id}",

    //PatientPaperPrescription
    PATIENT_PAPER_PRESCRIPTION_GET_ALL: SERVICE_PATIENT_DATA + "/api/PatientPaperPrescription",
    PATIENT_PAPER_PRESCRIPTION_GET: SERVICE_PATIENT_DATA + "/api/PatientPaperPrescription/{id}",
    PATIENT_PAPER_PRESCRIPTION_GET_BY_PATIENT: SERVICE_PATIENT_DATA + "/api/PatientPaperPrescription/ByPatient/{domain}/{patientId}",
    PATIENT_PAPER_PRESCRIPTION_REMOVE: SERVICE_PATIENT_DATA + "/api/PatientPaperPrescription/Remove/{id}",
    PATIENT_PAPER_PRESCRIPTION_RESTORE: SERVICE_PATIENT_DATA + "/api/PatientPaperPrescription/Restore/{id}",
    PATIENT_PAPER_PRESCRIPTION_GET_BY_APPOINTMENT: SERVICE_PATIENT_DATA + "/api/PatientPaperPrescription/ByAppointment/{domain}/{patientId}/{appId}",




    PATIENT_GET_CODES: SERVICE_PATIENT_DATA + "/api/PatientData/PatientCodes/{id}",
    PATIENT_IPOM_BUNDLE: SERVICE_PATIENT_DATA + "/api/Queries/IpomBundle",

    PATIENT_REPORT_GET_ALL: SERVICE_PATIENT_DATA + "/api/PatientReport",
    PATIENT_REPORT_GET: SERVICE_PATIENT_DATA + "/api/PatientReport/{id}",
    PATIENT_REPORT_GET_BY_PATIENT_ID: SERVICE_PATIENT_DATA + "/api/PatientReport/ByPatient/{domain}/{patientId}",
    PATIENT_REPORT_GET_BY_APPOINTMENT_ID: SERVICE_PATIENT_DATA + "/api/PatientReport/ByAppointment/{domain}/{patientId}/{appid}",
    PATIENT_REPORT_REMOVE: SERVICE_PATIENT_DATA + "/api/PatientReport/Remove/{id}",
    PATIENT_REPORT_RESTORE: SERVICE_PATIENT_DATA + "/api/PatientReport/Restore/{id}",

    TEMPLATE_REFERRAL_GET_ALL: SERVICE_PATIENT_DATA + "/api/TemplateReferral",
    TEMPLATE_REFERRAL_GET: SERVICE_PATIENT_DATA + "/api/TemplateReferral/{id}",
    TEMPLATE_REFERRAL_GET_BY_PATIENT: SERVICE_PATIENT_DATA + "/api/TemplateReferral/Patient/{id}",
    TEMPLATE_REFERRAL_GET_BY_EMPLOYEE: SERVICE_PATIENT_DATA + "/api/TemplateReferral/Employee/{id}",
    TEMPLATE_REFERRAL_GET_BY_EMPLOYEE_TYPE: SERVICE_PATIENT_DATA + "/api/TemplateReferral/EmployeeAndType/{id}/{type}",
    TEMPLATE_REFERRAL_GET_BY_APPOINTMENT: SERVICE_PATIENT_DATA + "/api/TemplateReferral/ByAppointment/{domainId}/{appointmentId}/{patientId}",
    TEMPLATE_REFERRAL_GET_BY_P1: SERVICE_PATIENT_DATA + "/api/TemplateReferral/ByP1Id/{id}",

    PATIENT_DOCTOR_NOTE_GET_ALL: SERVICE_PATIENT_DATA + "/api/PatientDoctorNote",
    PATIENT_DOCTOR_NOTE_GET_BY_ID: SERVICE_PATIENT_DATA + "/api/PatientDoctorNote/{id}",
    PATIENT_DOCTOR_NOTE_GET_BY_PATIENT: SERVICE_PATIENT_DATA + "/api/PatientDoctorNote/ByPatient/{domain}/{patientId}",
    PATIENT_DOCTOR_NOTE_GET_BY_APPOINTMENT: SERVICE_PATIENT_DATA + "/api/PatientDoctorNote/ByAppointment/{domain}/{patientId}/{appId}",
    PATIENT_DOCTOR_NOTE_UPDATE_NODE: SERVICE_PATIENT_DATA + "/api/PatientDoctorNote/UpdateNote",
    PATIENT_DOCTOR_NOTE_REMOVE: SERVICE_PATIENT_DATA + "/api/PatientDoctorNote/Remove/{id}/{appId}",
    PATIENT_DOCTOR_NOTE_RESTORE: SERVICE_PATIENT_DATA + "/api/PatientDoctorNote/Restore/{id}/{appId}",
    PATIENT_DOCTOR_NOTE_CONTENT_RESTORE: SERVICE_PATIENT_DATA + "/api/PatientDoctorNote/RestoreContent/{id}",
    PATIENT_DOCTOR_NOTE_GET_SPECIAL_BY_PATIENT: SERVICE_PATIENT_DATA + "/api/PatientDoctorNote/ByPatientSpecial/{domain}/{patientId}",
    PATIENT_DOCTOR_NOTE_GET_HISTORY_NOTE: SERVICE_PATIENT_DATA + "/api/PatientDoctorNote/GetHistory/{id}",

    PATIENT_ORDER_IMMUNIZATION_GET_BY_QUALIFICATION: SERVICE_PATIENT_DATA + "/api/PatientOrderImmunization/ByQualification/{qualificationId}",
    PATIENT_ORDER_IMMUNIZATION_GET_ALL: SERVICE_PATIENT_DATA + "/api/PatientOrderImmunization",
    PATIENT_ORDER_IMMUNIZATION_GET_BY_NURSE_APPOINTMENT: SERVICE_PATIENT_DATA + "/api/PatientOrderImmunization/ByNurseAppointment/{domain}/{nurseAppId}",
    PATIENT_ORDER_IMMUNIZATION_GET_BY_PATIENT: SERVICE_PATIENT_DATA + "/api/PatientOrderImmunization/ByPatient/{domain}/{patientId}",
    PATIENT_ORDER_IMMUNIZATION_GET_BY_APPOINTMENT: SERVICE_PATIENT_DATA + "/api/PatientOrderImmunization/ByAppointment/{domain}/{appId}",

    PLC_ALL: SERVICE_PATIENT + "/api/PLCItem",
    PLC_CHECK: SERVICE_PATIENT + "/api/PLCItem/check/{id}",
    PLC_ME: SERVICE_PATIENT + "/api/PLCItem/me",
    PLC_LOCK: SERVICE_PATIENT + "/api/PLCItem/lock/{id}",
    PLC_UNLOCK: SERVICE_PATIENT + "/api/PLCItem/unlock/{id}",

    PATIENT_GET_TASKS: SERVICE_PATIENT + "/api/PatientTask/ForPatient/{id}",
    PATIENT_ADD_TASK: SERVICE_PATIENT + "/api/PatientTask",
    PATIENT_PUT_TASK: SERVICE_PATIENT + "/api/PatientTask/{id}",
    PATIENT_ADD_TASK_STATUS: SERVICE_PATIENT + "/api/PatientTask/AddStatus/{id}",
    PATIENT_DELETE_TASK: SERVICE_PATIENT + "/api/PatientTask/{id}",

    PATIENT_GET_DECLARATIONS_V2_STATS: SERVICE_PATIENT + "/api/DeclarationV2/Stats",
    PATIENT_GET_DECLARATIONS_V2_BY_PATIENT_ID: SERVICE_PATIENT + "/api/DeclarationV2/ForPatient/{id}",
    PATIENT_GET_DECLARATION_V2_BY_ID: SERVICE_PATIENT + "/api/DeclarationV2/{id}",
    PATIENT_PUT_DECLARATION_V2: SERVICE_PATIENT + "/api/DeclarationV2/{id}",
    PATIENT_DELETE_DECLARATION_V2: SERVICE_PATIENT + "/api/DeclarationV2/{id}",
    PATIENT_POST_DECLARATION_V2: SERVICE_PATIENT + "/api/DeclarationV2",
    PATIENT_FILTER_DECLARATIONS_V2: SERVICE_PATIENT + "/api/DeclarationV2/Filter",
    PATIENT_FILTER_DECLARATIONS_V2_WITH_ERRORS: SERVICE_PATIENT + "/api/DeclarationV2/Errors",
    PATIENT_DECLARATIONS_V2_MULTI_CANCEL: SERVICE_PATIENT + "/api/DeclarationV2/MultiCancel",
    PATIENT_CHANGES_DECLARATIONS_V2_BY_PATIENT_ID: SERVICE_PATIENT + "/api/DeclarationV2/ChangesByPatientId/{id}", //NEW
    PATIENT_CHANGES_DECLARATIONS_V2_BY_ID: SERVICE_PATIENT + "/api/DeclarationV2/ChangesByDeclarationId/{id}", //NEW
    PATIENT_DECLARATIONS_V2_ADD_FILE: SERVICE_PATIENT + "/api/DeclarationV2/AddDeclarationFile",
    PATIENT_GET_DECLARATIONS_V2_BY_PATIENT_PESEL: SERVICE_PATIENT + "/api/DeclarationV2/ForPatientPesel/{domainId}/{pesel}",

    PATIENT_PROGRAM_GET_BY_ID: SERVICE_PATIENT + "/api/PatientProgram/{id}",
    PATIENT_PROGRAM_ADD: SERVICE_PATIENT + "/api/PatientProgram",
    PATIENT_PROGRAM_PUT: SERVICE_PATIENT + "/api/PatientProgram/{id}",
    PATIENT_PROGRAM_DELETE: SERVICE_PATIENT + "/api/PatientProgram/{id}",
    PATIENT_PROGRAM_GET_PROGRAM_WITH_PATIENTS: SERVICE_PATIENT + "/api/PatientProgram/ProgramWithPatients/{id}",
    PATIENT_PROGRAM_GET_MANY_PROGRAMS_WITH_PATIENTS: SERVICE_PATIENT + "/api/PatientProgram/ManyProgramWithPatients",
    PATIENT_PROGRAM_GET_FOR_PATIENT: SERVICE_PATIENT + "/api/PatientProgram/ForPatient/{id}",
    PATIENT_PROGRAM_GET_FOR_MANY_PATIENTS: SERVICE_PATIENT + "/api/PatientProgram/ForPatients",
    PATIENT_PROGRAM_ADD_EVENT: SERVICE_PATIENT + "/api/PatientProgram/AddEvent/{id}",
    PATIENT_PROGRAM_EDIT_EVENT: SERVICE_PATIENT + "/api/PatientProgram/EditEvent/{id}",

    PATIENT_PROGRAM_FILTER: SERVICE_PATIENT + "/api/PatientProgram/Filter",
    PATIENT_PROGRAM_STATS_BY_PROGRAM: SERVICE_PATIENT + "/api/PatientProgram/StatsByProgram/{id}",

    /*
        ALERT! - Placed in Programs Service - PopulationProgram API
        but is actually for Patient Service - PatientProgram API data
    */
    PATIENT_PROGRAM_GET_WITH_CYCLES: SERVICE_PROGRAMS + "/api/PopulationProgram/GetByPatient/{patientId}",

    PATIENT_GET_RANDOM_BY_AGE: SERVICE_PATIENT + "/api/Patient/RandomByAge",

    PATIENT_ALERT_GET_BY_ID: SERVICE_PATIENT + "/api/PatientAlert/{id}",
    PATIENT_ALERT_ADD: SERVICE_PATIENT + "/api/PatientAlert",
    PATIENT_ALERT_ADD_STATUS: SERVICE_PATIENT + "/api/PatientAlert/AddStatus/{taskId}",
    PATIENT_ALERT_GET_FOR_PATIENT: SERVICE_PATIENT + "/api/PatientAlert/ForPatient/{id}",
    PATIENT_ALERT_GET_BY_FILTER: SERVICE_PATIENT + "/api/PatientAlert/ByFilter",

    // ****************************************************************************************
    // TEST SERVICE
    // ****************************************************************************************

    TEST_PATIENT: SERVICE_TEST + "/api/Test/FindPatientByPESEL/{pesel}",
    TEST_EWUS: SERVICE_TEST + "/api/Test/FindEWUSState/{pesel}",

    TEST_PRESCRIPTION_SEND: SERVICE_TEST + "/api/Test/CreatePrescriptions",
    TEST_PRESCRIPTION_RESEND: SERVICE_TEST + "/api/Test/ResendPrescription/{guid}",
    TEST_PRESCRIPTION_GET_COMPLETED: SERVICE_TEST + "/api/Test/GetCompletedPrescription/{guid}",
    TEST_PRESCRIPTIONS_ASK_BY_PATIENT: SERVICE_TEST + "/api/Test/CheckPrescriptionByPatientId/{id}",
    TEST_PRESCRIPTIONS_ALL_BY_WORKER: SERVICE_TEST + "/api/Test/GetPrescriptionByWorkerId/{id}",

    TEST_REFERRAL_SEND: SERVICE_TEST + "/api/Test/CreateReferral",
    TEST_REFERRAL_RESEND: SERVICE_TEST + "/api/Test/ResendeSkierowanie/{guid}",
    TEST_REFERRAL_GET_COMPLETED: SERVICE_TEST + "/api/Test/GetCompletedReferral/{guid}",
    TEST_REFERRALS_ASK_BY_PATIENT: SERVICE_TEST + "/api/Test/CheckReferralByPatientId/{id}",
    TEST_REFERRALS_ALL_BY_WORKER: SERVICE_TEST + "/api/Test/GetReferralByWorkerId/{id}",

    TEST_EXEMPTION_SEND: SERVICE_TEST + "/api/Test/CreateExemption",
    TEST_EXEMPTION_ASK: SERVICE_TEST + "/api/Test/CreateExemption",

    TEST_ZUS_ACTION: SERVICE_TEST + "/api/Test/ZUSAction",
    TEST_ZUS_GET_WORK_STATUS_TASKS_BY_GUID: SERVICE_TEST + "/api/Test/ZUSGetWorkStatusTasksGuid/{guid}",
    TEST_ZUS_GET_COMPLETED_TASKS_BY_GUID: SERVICE_TEST + "/api/Test/GetCompletedTasksByGuid/{guid}",

    // ****************************************************************************************
    // LAB SERVICE
    // ****************************************************************************************

    LAB_GET_CONTAINERS: SERVICE_LAB + "/api/LabContainer/GetAllLabContainerMongo",
    LAB_PUT_CONTAINER: SERVICE_LAB + "/api/LabContainer/UpdateLabContainerMongo/{id}",
    LAB_POST_CONTAINER: SERVICE_LAB + "/api/LabContainer/CreateLabContainerMongo",
    LAB_UNPACK_CONTAINER: SERVICE_LAB + "/api/LabContainer/UnpackContainer/{id}/{workerId}",
    LAB_CONTAINER_TEMPERATURE: SERVICE_LAB + "/api/LabContainer/UpdateTemperature/{id}/{temperature}/?workerId={workerId}",
    LAB_RESET_CONTAINER: SERVICE_LAB + "/api/LabContainer/ResetContainer/{id}/{workerId}",

    LAB_CLINICAL_REPORT_GENERATE: SERVICE_LAB + "/api/LabAdds/ClinicalStats/{year}/{dateTypeFiltering}",

    LAB_GET_STANDS: SERVICE_LAB + "/api/LabStand/GetAllLabStand",
    LAB_POST_STAND: SERVICE_LAB + "/api/LabStand/CreateLabStand",
    LAB_GET_MANY_STANDS_BY_CODE: SERVICE_LAB + "/api/LabStand/GetManyLabStandByCode",
    LAB_PUT_STAND: SERVICE_LAB + "/api/LabStand/UpdateLabStand/{id}",
    LAB_GET_STAND_EVENT_BY_ID: SERVICE_LAB + "/api/LabStandEvent/ByLabStandId/{id}",
    LAB_DELETE_STAND: SERVICE_LAB + "/api/LabStand/DeleteLabStand/{id}",
    LAB_GET_STAND_BY_CODE: SERVICE_LAB + "/api/LabStand/GetLabStandByCode/{id}",

    LAB_GET_SAMPLES: SERVICE_LAB + "/api/LabSample/GetAllLabSampleMongo",
    LAB_CREATE_TEST_SAMPLE: SERVICE_LAB + "/api/LabSample/CreateTestSample",
    LAB_PUT_SAMPLE: SERVICE_LAB + "/api/LabSample/UpdateLabSampleMongo/{id}",
    LAB_PUT_SAMPLE_PID: SERVICE_LAB + "/api/LabSample/UpdatePatientId/{id}",
    LAB_SPLIT_MATERIAL: SERVICE_LAB + "/api/LabSample/Split",
    LAB_GET_SAMPLE: SERVICE_LAB + "/api/LabSample/GetLabSampleByInternalCode",
    LAB_GET_SAMPLE_BY_PATIENT: SERVICE_LAB + "/api/LabSample/GetByPatientId",
    LAB_GET_SAMPLE_BY_ID: SERVICE_LAB + "/api/LabSample/GetLabSampleMongoById/{id}",
    LAB_SIGN_SAMPLES: SERVICE_LAB + "/api/LabSample/AcceptSamples/{workerId}",
    LAB_UNSIGN_SAMPLES: SERVICE_LAB + "/api/LabSample/UnsignSamples",
    LAB_DOWNLOAD_SAMPLE: SERVICE_LAB + "/api/LabSample/DownloadResult/{id}",
    LAB_GET_ORDER_BY_TICKET: SERVICE_LAB + "/api/LabSample/GetByOrderTicket/{id}",
    LAB_GET_BY_MANY_ORDER_TICKET: SERVICE_LAB + "/api/LabSample/GetByManyOrderTicket",
    LAB_DOWNLOAD_SAMPLE_UNDONE: SERVICE_LAB + "/api/LabSample/DownloadResultUndone/{id}",
    LAB_DOWNLOAD_SAMPLES: SERVICE_LAB + "/api/LabSample/DownloadMultipleResults",
    LAB_POST_SAMPLE: SERVICE_LAB + "/api/LabSample/CreateLabSampleMongo",
    LAB_CREATE_SAMPLE_SET: SERVICE_LAB + "/api/LabSample/Create",
    LAB_MATERIAL_MARK: SERVICE_LAB + "/api/LabSample/MarkMaterialCollected",
    LAB_MATERIAL_MARK_GROUP: SERVICE_LAB + "/api/LabSample/MarkMaterialCollectedGroup",
    LAB_MATERIAL_REVERT_MARK: SERVICE_LAB + "/api/LabSample/RevertMarkMaterialCollected",
    LAB_FIND_SAMPLES: SERVICE_LAB + "/api/LabSample/FindSample",
    LAB_GET_SAMPLES_BY_DATES: SERVICE_LAB + "/api/LabSample/GetLabSampleMongoById/{from}/{to}/{type}",
    LAB_GET_SAMPLES_FILTERED: SERVICE_LAB + "/api/LabSample/Filter/{from}/{to}/{type}/{material}",
    LAB_GET_SAMPLES_FILTERED_2: SERVICE_LAB + "/api/LabSample/Filter",
    LAB_GET_SAMPLES_FILTERED_2_LIGHT: SERVICE_LAB + "/api/LabSample/FilterLight",
    LAB_GET_SAMPLES_FILTERED_3: SERVICE_LAB + "/api/Search/FilterSamples",
    LAB_SEARCH_TELEADDRESS: SERVICE_LAB + "/api/Search/SearchTeleaddress",
    LAB_GET_SAMPLES_FILTERED_LIGHT: SERVICE_LAB + "/api/LabSample/FilterLight/{from}/{to}/{type}/{material}",
    LAB_GET_SAMPLES_BY_DATES_AND_LOCALIZATION: SERVICE_LAB + "/api/LabSample/GetLabSamples/{from}/{to}/{type}/{localization}",
    LAB_GET_SAMPLES_BY_DATES_WITH_TIME: SERVICE_LAB + "/api/LabSample/GetLabSampleMongoByDates",

    LAB_POST_MULTIPLE: SERVICE_LAB + "/api/LabSample/CreateMultiple",
    LAB_REVERT_SAMPLE: SERVICE_LAB + "/api/LabSample/RevertSample",
    LAB_UPDATE_MULTIPLE: SERVICE_LAB + "/api/LabSample/UpdateMultiple",

    LAB_CREATE_HPV_SOLAB_OR_CYTO: SERVICE_LAB + "/api/LabSampleCreator/CreateHpvWithMaterialOrCytoLbcStandalone",

    LAB_GET_PLATES: SERVICE_LAB + "/api/LabCOVIDPlate/GetAllLabCOVIDPlateMongo",
    LAB_GET_LATEST_PLATES: SERVICE_LAB + "/api/LabCOVIDPlate/Latest",
    LAB_CREATE_PLATE: SERVICE_LAB + "/api/LabCOVIDPlate/CreateLabCOVIDPlateMongo",
    LAB_DOWNLOAD_PLATE_BATCH: SERVICE_LAB + "/api/LabCOVIDPlate/GetPlateCVS/{id}",
    LAB_UPLOAD_PLATE_RESULT: SERVICE_LAB + "/api/LabCOVIDPlate/UploadEXCEL/{id}",
    LAB_UPLOAD_GENERAL_RESULT: SERVICE_LAB + "/api/LabCOVIDPlate/UploadEXCELNew",
    LAB_UPLOAD_HPV_RESULT: SERVICE_LAB + "/api/LabCOVIDPlate/UploadExcellHpv",
    LAB_UPLOAD_HPV_RESULT_LH: SERVICE_LAB + "/api/LabCOVIDPlate/UploadEXCELLHPVLiferiverHarmonia",
    LAB_UPLOAD_COVID2_RESULT: SERVICE_LAB + "/api/LabCOVIDPlate/UploadExcellCovid2",
    LAB_UPLOAD_COVID4_RESULT: SERVICE_LAB + "/api/LabCOVIDPlate/UploadEXCELLCOVID3",
    LAB_UPLOAD_COVID_CL_RESULT: SERVICE_LAB + "/api/LabCOVIDPlate/UploadEXCELLCOVIDClinicalTest",
    LAB_UNDO_PLATE: SERVICE_LAB + "/api/LabCOVIDPlate/UndoPlate/{id}",
    LAB_PUT_PLATE: SERVICE_LAB + "/api/LabCOVIDPlate/UpdateLabCOVIDPlateMongo/{id}",

    LAB_CREATE_COVID_REQUEST: SERVICE_TEST + "/api/Test/COVIDAction",
    LAB_CHECK_COVID_REQUEST: SERVICE_TEST + "/api/Test/CheckCOVID/{guid}",
    LAB_GET_COVID_DATA: SERVICE_TEST + "/api/Test/GetCompletedCOVID/{guid}",

    LAB_CREATE_STRIP: SERVICE_LAB + "/api/LabCOVIDStrip/CreateLabCOVIDStripMongo",
    LAB_ADD_TO_STRIP: SERVICE_LAB + "/api/LabCOVIDStrip/AddSampleToStrip/{id}",
    LAB_REMOVE_FROM_STRIP: SERVICE_LAB + "/api/LabCOVIDStrip/RemoveSample/{id}",
    LAB_GET_STRIPS: SERVICE_LAB + "/api/LabCOVIDStrip/GetAllLabCOVIDStripMongo",
    LAB_GET_UNUSED_STRIPS: SERVICE_LAB + "/api/LabCOVIDStrip/GetUnusedLabCOVIDStripMongo",
    LAB_GET_STRIP_BY_ID: SERVICE_LAB + "/api/LabCOVIDStrip/GetLabCOVIDStripMongoById/{id}",
    LAB_PUT_STRIP: SERVICE_LAB + "/api/LabCOVIDStrip/UpdateLabCOVIDStripMongo/{id}",

    LAB_ACCEPT_SAMPLES: SERVICE_LAB + "/api/LabSample/AcceptSamplseMongo/{workerId}",
    LAB_SAMPLES_VIRO_P12: SERVICE_LAB + "/api/LabSample/AcceptSamplesDiagP12/{workerId}",
    LAB_PUT_SAMPLE_STATUS: SERVICE_LAB + "/api/LabSample/AddLabSampleStatus/{id}",
    LAB_QUEUE_PESEL_SEARCH: SERVICE_LAB + "/api/LabPatientQueue/GetLabPatientQueueByPESEL/{pesel}",

    LAB_GET_CYTO_RESULTS: SERVICE_LAB + "/api/LabSample/GetCytoResultsByPatientId",

    //NEW FOR LAB SERVICE
    LAB_ORDER_EXTERNAL_GET_BY_CODE: SERVICE_LAB + "/api/LabOrder/External/{code}",
    LAB_ORDER_GET_BY_PATIENT: SERVICE_LAB + "/api/LabOrder/ByPatient/{id}",
    LAB_ORDER_GET_BY_DATE: SERVICE_LAB + "/api/LabOrder/Filter/{DateStart}/{DateStop}",
    LAB_ORDER_GET_ALL: SERVICE_LAB + "/api/LabOrder/GetAllLabOrder",
    LAB_ORDER_GET_BY_ID: SERVICE_LAB + "/api/LabOrder/GetLabOrderById/{id}",
    LAB_ORDER_GET_BY_CODE: SERVICE_LAB + "/api/LabOrder/GetLabOrderByCode/{id}",
    LAB_ORDER_CREATE: SERVICE_LAB + "/api/LabOrder/CreateLabOrder",
    LAB_ORDER_UPDATE: SERVICE_LAB + "/api/LabOrder/UpdateLabOrder/{id}",
    LAB_ORDER_DELETE: SERVICE_LAB + "/api/LabOrder/DeleteLabOrder/{id}",

    LAB_SAMPLE_DELETE: SERVICE_LAB + "/api/LabSample/DeleteLabSampleMongo",

    LAB_GET_PATIENTS_HIGH_RISK: SERVICE_LAB + "/api/LabSample/CytoHpvPlus",

    LAB_MATERIAL_REMOVE_FLAG: SERVICE_LAB + "/api/LabSample/DeactivateMaterialFlag/{id}/{flagId}",
    LAB_MATERIAL_ADD_FLAG: SERVICE_LAB + "/api/LabSample/AddMaterialFlag/{id}",

    LAB_SAMPLE_REMOVE_FLAG: SERVICE_LAB + "/api/LabSample/DeactivateSampleFlag/{id}/{flagId}",
    LAB_SAMPLE_ADD_FLAG: SERVICE_LAB + "/api/LabSample/AddSampleFlag/{id}",

    LAB_ACCEPT_SAMPLES_P12_NEW: SERVICE_LAB + "/api/LabSample/AcceptSamplesMongoNEWP12",
    LAB_ACCEPT_SAMPLES_EXTERNAL: SERVICE_LAB + "/api/LabSample/UploadExternalResult",

    LAB_GET_SOLAB_ORDERS: SERVICE_LAB + "/api/LabOrder/SolabOrdersWithMaterials",
    LAB_GET_INFO_ORDERS: SERVICE_LAB + "/api/LabOrder/InfoOrdersWithMaterials",

    LAB_MARK_SOLAB_SIGNED: SERVICE_LAB + "/api/LabSample/MarkSolabSigned",

    LAB_CYTOLOGY_CREATE_DATASET: SERVICE_LAB + "/api/LabSample/CreateV2",
    LAB_MIDWIFE_CREATE_DATASET_V3: SERVICE_LAB + "/api/LabSampleCreator/CreateV3",
    LAB_ADD_MATERIAL_TO_ORDER: SERVICE_LAB + "/api/LabSample/AddMaterialV2/{orderId}",
    LAB_ADD_SAMPLE_TO_MATERIAL: SERVICE_LAB + "/api/LabSample/AddSampleToMaterialV2/{materialId}",

    LAB_FILTER_HISPAT: SERVICE_LAB + "/api/LabSample/FilterHispat",

    LAB_GET_RESULTS_BY_PATIENT_ID: SERVICE_LAB + "/api/LabAdds/GetLabResultsByPatientId/{id}",

    LAB_GET_STATS: SERVICE_LAB + "/api/LabStats/GetStats",
    LAB_GET_STATS_FINAL: SERVICE_LAB + "/api/LabStatS/GetStatsFinal",
    LAB_GET_STATS_RSC: SERVICE_LAB + "/api/LabStats/GetStatsRsc",
    LAB_GET_STATS_GLOBAL: SERVICE_LAB + "/api/LabStats/GetStatsGlobal",
    LAB_GET_STATUS_TIMES: SERVICE_LAB + "/api/LabStats/StatusTimes",

    LAB_MATERIAL_ADD_STATUS: SERVICE_LAB + "/api/LabMaterial/AddStatus/{id}",
    LAB_MATERIAL_ADD_STATUS_GROUPED: SERVICE_LAB + "/api/LabMaterial/AddStatusGrouped",

    LAB_GET_ORDER_INFO_BY_MATERIAL_SOLAB_ID: SERVICE_LAB + "/api/LabSample/GetOrderInfoByMaterialSolabId/{id}",
    LAB_GET_STATS_GLOBAL_COLLECTED_BY: SERVICE_LAB + "/api/LabStats/GetStatsGlobalCollectedBy",
    LAB_GET_STATS_GLOBAL_COLLECTED_BY_SITES: SERVICE_LAB + "/api/LabStats/GetStatsGlobalCollectedBySites",

    LAB_FILTER_BY_LOCATION: SERVICE_LAB + "/api/LabSample/FilterByLocation",

    LAB_COLLECTION_POINT_SAMPLE_UPDATE_ORDER_TYPE: SERVICE_LAB + "/api/LabSample/UpdateOrderType",

    // ****************************************************************************************
    // FINANCE SERVICE
    // ****************************************************************************************

    FINANCE_REPORTS_MONTHLY_MEDSETS: SERVICE_FINANCE + '/api/Stats/MedSetStatsExcel/{domain}/{year}/{month}/{cat}',

    FINANCE_UNIREPORTS_GENERAL: SERVICE_FINANCE + '/api/UniReports/General',
    FINANCE_UNIREPORTS_DOWNLOAD: SERVICE_FINANCE + '/api/UniReports/Download/{id}',

    FINANCE_SUBJECTS_GET_ALL: SERVICE_FINANCE + '/api/Subjects',
    FINANCE_SUBJECTS_CREATE: SERVICE_FINANCE + '/api/Subjects',
    FINANCE_SUBJECTS_GET_BY_ID: SERVICE_FINANCE + '/api/Subjects/{id}',
    FINANCE_SUBJECTS_UPDATE: SERVICE_FINANCE + '/api/Subjects/{id}',
    FINANCE_SUBJECTS_DELETE: SERVICE_FINANCE + '/api/Subjects/{id}',
    FINANCE_SUBJECTS_GET_WITH_MEDSPEC_TYPE: SERVICE_FINANCE + '/api/Subjects/WithMedSpecType',
    FINANCE_SUBJECTS_FILTER: SERVICE_FINANCE + '/api/Subjects/Filter',
    FINANCE_SUBJECTS_GET_ALL_IN_MY_DOMAIN: SERVICE_FINANCE + '/api/Subjects/InMyDomain',

    FINANCE_GET_NFZ_CONTRACT_BY_NIP: SERVICE_FINANCE + '/api/NfzContract/ByNip/{nip}',
    FINANCE_GET_NFZ_CONTRACT_BY_SWD: SERVICE_FINANCE + '/api/NfzContract/BySwd/{swd}',
    FINANCE_GET_NFZ_CONTRACTS: SERVICE_FINANCE + '/api/NfzContract',

    FINANCE_GET_MUS_ITEMS: SERVICE_FINANCE + '/api/UmxData/mus',
    UMX_GET_SERVICES_FOR_OFFICE: SERVICE_FINANCE + '/api/UmxData/ForOffice',
    UMX_GET_ALL: SERVICE_FINANCE + '/api/UmxData',
    UMX_GET_FOR_DOMAIN: SERVICE_FINANCE + '/api/UmxData/ForDomain/{domain}',
    UMX_GET_ACTIVE_WITH_ID: SERVICE_FINANCE + '/api/UmxData/ActiveForDomain/{domain}',
    UMX_UPLOAD: SERVICE_FINANCE + '/api/UmxData/Upload',
    UMX_VALIDATE_SERVICES: SERVICE_FINANCE + '/api/UmxData/ValidateServices/{umxId}/{add}',
    UMX_GET_BY_ID: SERVICE_FINANCE + '/api/UmxData/{id}',
    UMX_GET_SERVICES_FOR_ENDAPP: SERVICE_FINANCE + '/api/UmxData/ForEndAppInfo/{idUmx}',
    UMX_ACTIVE_FOR_SWD: SERVICE_FINANCE + '/api/UmxData/ActiveForSwd/{swd}',

    CONFIG_GET_ALL_DICTIONARIES: SERVICE_FINANCE + '/api/NfzDictionary',

    NFZ_DICTIONARY_GENERAL: SERVICE_FINANCE + '/api/NfzDictionary',
    NFZ_DICTIONARY_BY_ID: SERVICE_FINANCE + '/api/NfzDictionary/{id}',

    NFZ_SERVICE_GENERAL: SERVICE_FINANCE + '/api/NfzService',
    NFZ_SERVICE_RULES_GET_ALL: SERVICE_FINANCE + '/api/NfzServiceRule',
    NFZ_SERVICE_RULES_CHECK_CODE: SERVICE_FINANCE + '/api/NfzServiceRule/ForCode/{clinic}/{code}/{age}',
    NFZ_SERVICE_BY_ID: SERVICE_FINANCE + '/api/NfzService/{id}',

    //FINANCE SUBJECTS WORKERS NEW!!!!
    FINANCE_SUBJECTS_WORKERS_GET_ALL: SERVICE_FINANCE + "/api/Subjects/AllWorkers",
    FINANCE_SUBJECTS_WORKERS_GET_BY_ID: SERVICE_FINANCE + "/api/Subjects/Workers/{id}",
    FINANCE_SUBJECTS_WORKERS_ADD: SERVICE_FINANCE + "/api/Subjects/AddWorker/{subjectId}",
    FINANCE_SUBJECTS_WORKERS_UPDATE: SERVICE_FINANCE + "/api/Subjects/UpdateWorker/{subjectId}",
    FINANCE_SUBJECTS_WORKERS_DELETE: SERVICE_FINANCE + "/api/Subjects/DeleteWorker/{subjectId}/{workerId}",

    FINANCE_SUBJECTS_ADD_WORKER_TO_SUBJECT: SERVICE_FINANCE + "/api/Subjects/AddWorkerToSubject/{subjectId}/{externalWorkerId}",
    FINANCE_SUBJECTS_REMOVE_WORKER_FROM_SUBJECT: SERVICE_FINANCE + "/api/Subjects/RemoveWorkerFromSubject/{subjectId}/{externalWorkerId}",

    FINANCE_CONTRACTS_GET_ALL: SERVICE_FINANCE + "/api/Contract",
    FINANCE_CONTRACTS_CREATE: SERVICE_FINANCE + "/api/Contract",
    FINANCE_CONTRACTS_GET_BY_ID: SERVICE_FINANCE + "/api/Contract/{id}",
    FINANCE_CONTRACTS_UPDATE: SERVICE_FINANCE + "/api/Contract/{id}",
    FINANCE_CONTRACTS_DELETE: SERVICE_FINANCE + "/api/Contract/{id}",
    FINANCE_CONTRACTS_FILTER: SERVICE_FINANCE + "/api/Contract/Filter",

    FINANCE_IKZ_GET_ALL: SERVICE_FINANCE + "/api/IkzItem",
    FINANCE_IKZ_CREATE: SERVICE_FINANCE + "/api/IkzItem",
    FINANCE_IKZ_GET_BY_ID: SERVICE_FINANCE + "/api/IkzItem/{id}",
    FINANCE_IKZ_GET_BY_TYPE: SERVICE_FINANCE + "/api/IkzItem/ByType/{id}",
    FINANCE_IKZ_UPDATE: SERVICE_FINANCE + "/api/IkzItem/{id}",
    FINANCE_IKZ_DELETE: SERVICE_FINANCE + "/api/IkzItem/{id}",
    FINANCE_IKZ_FILTER: SERVICE_FINANCE + "/api/IkzItem/Filter",

    FINANCE_MEDICAL_SERVICES_GET_ALL: SERVICE_FINANCE + "/api/MedicalServiceItem",
    FINANCE_MEDICAL_SERVICES_CREATE: SERVICE_FINANCE + "/api/MedicalServiceItem",
    FINANCE_MEDICAL_SERVICES_GET_BY_ID: SERVICE_FINANCE + "/api/MedicalServiceItem/{id}",
    FINANCE_MEDICAL_SERVICES_GET_BY_TYPE: SERVICE_FINANCE + "/api/MedicalServiceItem/ByType/{id}",
    FINANCE_MEDICAL_SERVICES_UPDATE: SERVICE_FINANCE + "/api/MedicalServiceItem/{id}",
    FINANCE_MEDICAL_SERVICES_DELETE: SERVICE_FINANCE + "/api/MedicalServiceItem/{id}",

    FINANCE_USER_IKZ_ADD: SERVICE_FINANCE + "/api/UserIkz",
    FINANCE_USER_IKZ_REMOVE: SERVICE_FINANCE + "/api/UserIkz/{id}",
    FINANCE_USER_IKZ_GET_MY: SERVICE_FINANCE + "/api/UserIkz/MyItems",
    FINANCE_USER_IKZ_GET_FOR_EMPLOYEE: SERVICE_FINANCE + "/api/UserIkz/ForEmployee/{id}",
    FINANCE_USER_IKZ_FILTER: SERVICE_FINANCE + "/api/UserIkz/Filter",

    FINANCE_INVOICE_GET_ALL: SERVICE_FINANCE + "/api/Invoice",
    FINANCE_INVOICE_CREATE: SERVICE_FINANCE + "/api/Invoice",
    FINANCE_INVOICE_GET_BY_ID: SERVICE_FINANCE + "/api/Invoice/{id}",
    FINANCE_INVOICE_UPDATE: SERVICE_FINANCE + "/api/Invoice/{id}",
    FINANCE_INVOICE_DELETE: SERVICE_FINANCE + "/api/Invoice/{id}",

    FINANCE_UPLOAD_GET_ALL: SERVICE_FINANCE + "/api/Upload",
    FINANCE_UPLOAD_CREATE: SERVICE_FINANCE + "​/api​/Upload",
    FINANCE_UPLOAD_GET_BY_ID: SERVICE_FINANCE + "/api/Upload/{id}",
    FINANCE_UPLOAD_UPDATE: SERVICE_FINANCE + "/api/Upload/{id}",
    FINANCE_UPLOAD_DELETE: SERVICE_FINANCE + "/api/Upload/{id}",
    FINANCE_UPLOAD_UPLOAD: SERVICE_FINANCE + "/api/Upload/Upload",
    FINANCE_UPLOAD_DOWNLOAD: SERVICE_FINANCE + "/api/Upload/Download/{id}",

    FINANCE_ORGANIZATIONALUNIT_GET_ALL: SERVICE_FINANCE + "/api/OrganizationalUnit",
    FINANCE_ORGANIZATIONALUNIT_GET_TOP: SERVICE_FINANCE + "/api/OrganizationalUnit/Top",
    FINANCE_ORGANIZATIONALUNIT_GET_CLUSTERS: SERVICE_FINANCE + "/api/OrganizationalUnit/Clusters",
    FINANCE_ORGANIZATIONALUNIT_GET_REGIONS: SERVICE_FINANCE + "/api/OrganizationalUnit/Regions",
    FINANCE_ORGANIZATIONALUNIT_GET_STRUCTURE: SERVICE_FINANCE + "/api/OrganizationalUnit/Structure",
    FINANCE_ORGANIZATIONALUNIT_GET_STRUCTURE_BY_ID: SERVICE_FINANCE + "/api/OrganizationalUnit/Structure/{id}",
    FINANCE_ORGANIZATIONALUNIT_CREATE: SERVICE_FINANCE + "/api/OrganizationalUnit",
    FINANCE_ORGANIZATIONALUNIT_ADD_UNIT: SERVICE_FINANCE + "/api/OrganizationalUnit/ConnectUnit/{parentId}/{childId}",
    FINANCE_ORGANIZATIONALUNIT_GET_BY_ID: SERVICE_FINANCE + "/api/OrganizationalUnit/{id}",
    FINANCE_ORGANIZATIONALUNIT_UPDATE: SERVICE_FINANCE + "/api/OrganizationalUnit/{id}",
    FINANCE_ORGANIZATIONALUNIT_DELETE: SERVICE_FINANCE + "/api/OrganizationalUnit/{id}",
    FINANCE_ORGANIZATIONALUNIT_DELETE_ID: SERVICE_FINANCE + "/api/OrganizationalUnit/SplitUnit/{parentId}/{childId}",
    FINANCE_ORGANIZATIONALUNIT_CONNECT_LOCAL: SERVICE_FINANCE + "/api/OrganizationalUnit/ConnectLocalUnit/{parentId}/{childId}",
    FINANCE_ORGANIZATIONALUNIT_GET_LOCAL_STRUCTURE_BY_ID: SERVICE_FINANCE + "/api/OrganizationalUnit/LocalStructure/{id}", //new
    FINANCE_ORGANIZATIONALUNIT_GET_OFFICES: SERVICE_FINANCE + "/api/OrganizationalUnit/GetOffices/{unitId}", //NEW
    FINANCE_ORGANIZATIONALUNIT_GET_BY_TYPE: SERVICE_FINANCE + "/api/OrganizationalUnit/ByType/{type}", //NEW

    FINANCE_COMPANY_GET_ALL: SERVICE_FINANCE + "/api/Company",
    FINANCE_COMPANY_CREATE: SERVICE_FINANCE + "/api/Company",
    FINANCE_COMPANY_GET_BY_ID: SERVICE_FINANCE + "/api/Company/{id}",
    FINANCE_COMPANY_UPDATE: SERVICE_FINANCE + "/api/Company/{id}",
    FINANCE_COMPANY_DELETE: SERVICE_FINANCE + "/api/Company/{id}",
    FINANCE_COMPANY_GET_STRUCTURE: SERVICE_FINANCE + "/api/Company/Structure/{id}",
    FINANCE_COMPANY_GET_LOCAL_STRUCTURE: SERVICE_FINANCE + "/api/Company/LocalStructure/{id}",

    //NEW FOR ORGANIZATIONAL UNIT TYPE
    FINANCE_FACILITIES_GET_ALL: SERVICE_FINANCE + "/api/Facility",
    FINANCE_FACILITIES_CREATE: SERVICE_FINANCE + "/api/Facility",
    FINANCE_FACILITIES_GET_BY_ID: SERVICE_FINANCE + "/api/Facility/{id}",
    FINANCE_FACILITIES_GET_BY_INTERNAL_ID: SERVICE_FINANCE + "/api/Facility/{id}",
    FINANCE_FACILITIES_UPDATE: SERVICE_FINANCE + "/api/Facility/{id}",
    FINANCE_FACILITIES_DELETE: SERVICE_FINANCE + "/api/Facility/{id}",

    FINANCE_ORGANIZATIONALUNIT_TYPE_GET_ALL: SERVICE_FINANCE + "/api/OrganizationalUnitType",
    FINANCE_ORGANIZATIONALUNIT_TYPE_CREATE: SERVICE_FINANCE + "/api/OrganizationalUnitType",
    FINANCE_ORGANIZATIONALUNIT_TYPE_GET_BY_ID: SERVICE_FINANCE + "/api/OrganizationalUnitType/{id}",
    FINANCE_ORGANIZATIONALUNIT_TYPE_UPDATE: SERVICE_FINANCE + "/api/OrganizationalUnitType/{id}",
    FINANCE_ORGANIZATIONALUNIT_TYPE_DELETE: SERVICE_FINANCE + "/api/OrganizationalUnitType/{id}",

    FINANCE_ORGANIZATIONALSUBUNIT_GET_ALL: SERVICE_FINANCE + "/api/OrganizationalSubUnit",
    FINANCE_ORGANIZATIONALSUBUNIT_CREATE: SERVICE_FINANCE + "/api/OrganizationalSubUnit",
    FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_ID: SERVICE_FINANCE + "/api/OrganizationalSubUnit/{id}",
    FINANCE_ORGANIZATIONALSUBUNIT_UPDATE: SERVICE_FINANCE + "/api/OrganizationalSubUnit/{id}",
    FINANCE_ORGANIZATIONALSUBUNIT_DELETE: SERVICE_FINANCE + "/api/OrganizationalSubUnit/{id}",
    FINANCE_ORGANIZATIONALSUBUNIT_ADD: SERVICE_FINANCE + "/api/OrganizationalUnit/AddSubUnit/{parentId}/{childId}",

    FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_OFFICE_ID: SERVICE_FINANCE + "/api/OrganizationalSubUnit/GetByOfficeId/{id}",

    FINANCE_ORGANIZATIONALSUBUNIT_GET_TYPES: SERVICE_FINANCE + "/api/OrganizationalSubUnit/GetExistingTypes", //new
    FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_TYPES: SERVICE_FINANCE + "/api/OrganizationalSubUnit/ByTypes", //new

    FINANCE_ORGANIZATIONALSUBUNIT_SET_SCHEDULEDISABLED: SERVICE_FINANCE + "/api/OrganizationalSubUnit/SetScheduleDisabled", //NEW

    FINANCE_BUILDING_GET_ALL: SERVICE_FINANCE + "/api/Building",
    FINANCE_BUILDING_GET: SERVICE_FINANCE + "/api/Building/{id}",

    FINANCE_NFZ_SERVICE_TYPE_GET_ALL: SERVICE_FINANCE + "/api/NfzServiceType",
    FINANCE_NFZ_SERVICE_TYPE_CREATE: SERVICE_FINANCE + "/api/NfzServiceType",
    FINANCE_NFZ_SERVICE_TYPE_GET_BY_ID: SERVICE_FINANCE + "/api/NfzServiceType/{id}",
    FINANCE_NFZ_SERVICE_TYPE_UPDATE: SERVICE_FINANCE + "/api/NfzServiceType/{id}",
    FINANCE_NFZ_SERVICE_TYPE_DELETE: SERVICE_FINANCE + "/api/NfzServiceType/{id}",

    FINANCE_PRICE_LIST_GET_ALL: SERVICE_FINANCE + "/api/PriceList",
    FINANCE_PRICE_LIST_GET: SERVICE_FINANCE + "/api/PriceList/{id}",
    FINANCE_PRICE_LIST_GET_BY_SUBJECT: SERVICE_FINANCE + "/api/PriceList/Subject/{id}",
    FINANCE_PRICE_LIST_GET_BY_SUBJECT_GROUPED: SERVICE_FINANCE + "/api/PriceList/SubjectGrouped/{id}",

    FINANCE_SUBJECT_PRICES_GET_ALL: SERVICE_FINANCE + "/api/SubjectPrices",
    FINANCE_SUBJECT_PRICES_GET: SERVICE_FINANCE + "/api/SubjectPrices/{id}",
    FINANCE_SUBJECT_PRICES_GET_BY_SUBJECT: SERVICE_FINANCE + "/api/PriceList/SubjectPrices/Subject/{id}",
    FINANCE_SUBJECT_PRICES_PRICE_CREATE: SERVICE_FINANCE + "/api/SubjectPrices/Price/{baseId}",
    FINANCE_SUBJECT_PRICES_PRICE_UPDATE: SERVICE_FINANCE + "/api/SubjectPrices/Price/{baseId}/{priceInternalId}",

    FINANCE_GET_EXTERNAL_WORKERS: SERVICE_FINANCE + "/api/ExternalEmployee",
    FINANCE_EXTERNAL_EMPLOYEE_ADD: SERVICE_FINANCE + "/api/ExternalEmployee",
    FINANCE_EXTERNAL_WORKER_GET_BY_ID: SERVICE_FINANCE + "/api/ExternalEmployee/{id}",
    FINANCE_EXTERNAL_WORKER_UPDATE: SERVICE_FINANCE + "/api/ExternalEmployee/{id}",
    FINANCE_EXTERNAL_WORKER_DELETE: SERVICE_FINANCE + "/api/ExternalEmployee/{id}",

    FINANCE_MED_SERVICE_SET_GET_ALL: SERVICE_FINANCE + "/api/MedServiceSet",
    FINANCE_MED_SERVICE_SET_GET: SERVICE_FINANCE + "/api/MedServiceSet/{id}",
    FINANCE_MED_SERVICE_SET_FILTER: SERVICE_FINANCE + "/api/MedServiceSet/Filter",
    FINANCE_MED_SERVICE_SET_HISTORY: SERVICE_FINANCE + "/api/MedServiceSet/History/{setId}",
    FINANCE_MED_SERVICE_SET_REVERT: SERVICE_FINANCE + "/api/MedServiceSet/Revert/{id}/{historyId}",
    FINANCE_MED_SERVICE_GET_BY_BILL_IDS: SERVICE_FINANCE + "/api/MedServiceSet/GetManyByBillingIds",
    FINANCE_MED_SERVICE_SET_COUNT_PROD: SERVICE_FINANCE + "/api/MedServiceSet/CountProd",
    FINANCE_MED_SERVICE_SET_MED_POINTS: SERVICE_FINANCE + "/api/MedServiceSet/MedPoints",
    FINANCE_MED_SERVICE_SET_ERRORS: SERVICE_FINANCE + "/api/MedServiceSet/Errors",

    FINANCE_DATABASE_GET_ALL: SERVICE_FINANCE + "/api/DigitmedDatabase",
    FINANCE_DATABASE_CREATE: SERVICE_FINANCE + "/api/DigitmedDatabase",
    FINANCE_DATABASE_GET_BY_ID: SERVICE_FINANCE + "/api/DigitmedDatabase/{id}",
    FINANCE_DATABASE_UPDATE: SERVICE_FINANCE + "/api/DigitmedDatabase/{id}",
    FINANCE_DATABASE_DELETE: SERVICE_FINANCE + "/api/DigitmedDatabase/{id}",

    //clients
    FINANCE_CLIENT_GET_ALL: SERVICE_FINANCE + "/api/DigitmedClient",
    FINANCE_CLIENT_CREATE: SERVICE_FINANCE + "/api/DigitmedClient",
    FINANCE_CLIENT_GET_BY_ID: SERVICE_FINANCE + "/api/DigitmedClient/{id}",
    FINANCE_CLIENT_UPDATE: SERVICE_FINANCE + "/api/DigitmedClient/{id}",
    FINANCE_CLIENT_DELETE: SERVICE_FINANCE + "/api/DigitmedClient/{id}",

    FINANCE_CLIENTFRAME_GET_ALL: SERVICE_FINANCE + "/api/ClientFrame",
    FINANCE_CLIENTFRAME_CREATE: SERVICE_FINANCE + "/api/ClientFrame",
    FINANCE_CLIENTFRAME_GET_BY_ID: SERVICE_FINANCE + "/api/ClientFrame/{id}",
    FINANCE_CLIENTFRAME_GET_BY_CLIENT: SERVICE_FINANCE + "/api/ClientFrame/GetByClient/{id}",
    FINANCE_CLIENTFRAME_UPDATE: SERVICE_FINANCE + "/api/ClientFrame/{id}",
    FINANCE_CLIENTFRAME_DELETE: SERVICE_FINANCE + "/api/ClientFrame/{id}",
    FINANCE_CLIENTFRAME_STRATEGY_ADD: SERVICE_FINANCE + "/api/ClientFrame/AddStrategy/{frameId}",
    FINANCE_CLIENTFRAME_STRATEGY_UPDATE: SERVICE_FINANCE + "/api/ClientFrame/UpdateStrategy/{frameId}/{strategyId}",
    FINANCE_CLIENTFRAME_STRATEGY_DELETE: SERVICE_FINANCE + "/api/ClientFrame/DeleteStrategy/{frameId}/{strategyId}",

    FINANCE_ORGANIZATIONALSUBUNIT_GET_REPORTED_BY_OFFICE_ID: SERVICE_FINANCE + "/api/OrganizationalSubUnit/GetReportedByOfficeId/{id}",

    // ****************************************************************************************
    // PROGRAMS SERVICE
    // ****************************************************************************************
    PROGRAMS_POPULATION_GET_ALL: SERVICE_PROGRAMS + "/api/PopulationProgram",
    PROGRAMS_POPULATION_GET: SERVICE_PROGRAMS + "/api/PopulationProgram/{id}",
    PROGRAMS_POPULATION_GET_FOR_CONFIG: SERVICE_PROGRAMS + "/api/PopulationProgram/GetForConfig/{internalId}",
    PROGRAMS_POPULATION_GET_BY_FILTER: SERVICE_PROGRAMS + "/api/PopulationProgram/GetByFilter",

    PROGRAMS_POPULATION_CONFIG_GET_ALL: SERVICE_PROGRAMS + "/api/PopulationProgramConfig",
    PROGRAMS_POPULATION_CONFIG_GET: SERVICE_PROGRAMS + "/api/PopulationProgramConfig/{id}",
    PROGRAMS_POPULATION_CONFIG_GET_BY_INTERNAL_ID: SERVICE_PROGRAMS + "/api/PopulationProgramConfig/GetByInternalId/{internalId}",
    PROGRAMS_POPULATION_CONFIG_ADD_EVENT: SERVICE_PROGRAMS + "/api/PopulationProgramConfig/AddEvent/{internalId}",
    PROGRAMS_POPULATION_CONFIG_UPDATE_EVENT: SERVICE_PROGRAMS + "/api/PopulationProgramConfig/UpdateEvent/{internalId}/{eventId}",
    PROGRAMS_POPULATION_CONFIG_DELETE_EVENT: SERVICE_PROGRAMS + "/api/PopulationProgramConfig/DeleteEvent/{internalId}/{eventId}",
    PROGRAMS_POPULATION_CONFIG_GET_EVENTS: SERVICE_PROGRAMS + "/api/PopulationProgramConfig/GetEvents/{internalId}/{type}",

    PROGRAMS_POPULATION_ATTACHMENT_GET_ALL: SERVICE_PROGRAMS + "/api/ProgramAttachment",
    PROGRAMS_POPULATION_ATTACHMENT_GET: SERVICE_PROGRAMS + "/api/ProgramAttachment/{id}",
    PROGRAMS_POPULATION_ATTACHMENT_DOWNLOAD: SERVICE_PROGRAMS + "/api/ProgramAttachment/Download/{id}",
    PROGRAMS_POPULATION_ATTACHMENT_UPLOAD: SERVICE_PROGRAMS + "/api/ProgramAttachment/Upload",
    PROGRAMS_POPULATION_ATTACHMENT_GET_FOR_PROGRAM: SERVICE_PROGRAMS + "/api/ProgramAttachment/GetForProgram/{internalId}",
    PROGRAMS_POPULATION_ATTACHMENT_GET_FOR_PROGRAM_CONFIG: SERVICE_PROGRAMS + "/api/ProgramAttachment/GetForProgramConfig/{internalId}",

    PROGRAMS_POPULATION_EVENT_GET_ALL: SERVICE_PROGRAMS + "/api/ProgramEvent",
    PROGRAMS_POPULATION_EVENT_GET: SERVICE_PROGRAMS + "/api/ProgramEvent/{id}",

    PROGRAMS_POPULATION_EVENT_TYPE_GET_ALL: SERVICE_PROGRAMS + "/api/ProgramEventType",
    PROGRAMS_POPULATION_EVENT_TYPE_GET: SERVICE_PROGRAMS + "/api/ProgramEventType/{id}",

    PROGRAMS_POPULATION_CONFIG_EVENT_GET_ALL: SERVICE_PROGRAMS + "/api/ConfigEvent",
    PROGRAMS_POPULATION_CONFIG_EVENT_GET: SERVICE_PROGRAMS + "/api/ConfigEvent/{id}",
    PROGRAMS_POPULATION_CONFIG_EVENT_GET_BY_INTERNAL: SERVICE_PROGRAMS + "/api/ConfigEvent/ByInternal/{id}",
    PROGRAMS_POPULATION_CONFIG_EVENT_GET_BY_PROGRAM_CONFIG: SERVICE_PROGRAMS + "/api/ConfigEvent/ByProgramConfig/{internalId}",

    PROGRAMS_POPULATION_CONFIG_PROFILE_GET_ALL: SERVICE_PROGRAMS + "/api/ConfigProfile",
    PROGRAMS_POPULATION_CONFIG_PROFILE_GET: SERVICE_PROGRAMS + "/api/ConfigProfile/{id}",
    PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_INTERNAL: SERVICE_PROGRAMS + "/api/ConfigProfile/ByInternal/{id}",
    PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_PROGRAM_CONFIG: SERVICE_PROGRAMS + "/api/ConfigProfile/ByProgramConfig/{internalId}",
    PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_PATIENT_PROGRAM_ID: SERVICE_PROGRAMS + "/api/ConfigProfile/ByPatientProgram/{id}",

    // ****************************************************************************************
    // CRM SERVICE
    // ****************************************************************************************
    CRM_GET_REPORTS: SERVICE_CRM + "/api/CrmReport",
    CRM_GET_REPORT_BY_ID: SERVICE_CRM + "/api/CrmReport/{id}",
    CRM_PUT_REPORT: SERVICE_CRM + "/api/CrmReport/{id}",
    CRM_POST_REPORT: SERVICE_CRM + "/api/CrmReport",
    CRM_DELETE_REPORT: SERVICE_CRM + "/api/CrmReport/{id}",
    CRM_GET_FULL_REPORT_BY_ID: SERVICE_CRM + "/api/CrmReport/full/{id}",
    CRM_REPORT_GET_BY_USER_ID: SERVICE_CRM + "/api/CrmReport/ByUserId/{id}", //NEW
    CRM_REPORT_GET_BY_USER_EMAIL: SERVICE_CRM + "/api/CrmReport/ByUserEmail/{email}", //NEW
    CRM_GET_REPORT_TASKS_PERFORMER_CHANGELOG: SERVICE_CRM + "/api/CrmReport/TaskChangelogs/{id}", //NEW
    CRM_PUT_ANOTHER_DIAGNOSIS: SERVICE_CRM + "/api/CrmReport/AddDiagnosis/{id}", //NEW
    CRM_REPORT_ADD_FILE: SERVICE_CRM + "/api/CrmReport/AddFile/{reportId}", //NEW ADD TO OCELOTE :)

    CRM_GET_TASKS: SERVICE_CRM + "/api/CrmTask",
    CRM_GET_TASK_BY_ID: SERVICE_CRM + "/api/CrmTask/{id}",
    CRM_PUT_TASK: SERVICE_CRM + "/api/CrmTask/{id}",
    CRM_POST_TASK: SERVICE_CRM + "/api/CrmTask",
    CRM_DELETE_TASK: SERVICE_CRM + "/api/CrmTask/{id}",

    CRM_GET_CHECKLISTS: SERVICE_CRM + "/api/CrmChecklist",
    CRM_GET_CHECKLIST_BY_ID: SERVICE_CRM + "/api/CrmChecklist/{id}",
    CRM_PUT_CHECKLIST: SERVICE_CRM + "/api/CrmChecklist/{id}",
    CRM_POST_CHECKLIST: SERVICE_CRM + "/api/CrmChecklist",
    CRM_DELETE_CHECKLIST: SERVICE_CRM + "/api/CrmChecklist/{id}",
    CRM_GET_FULL_CHECKLIST_BY_ID: SERVICE_CRM + "/api/CrmChecklist/full/{id}",

    CRM_CRMMEDIA_UPLOAD: SERVICE_CRM + "/api/CrmMedia/Upload",
    CRM_CRMMEDIA_DOWNLOAD: SERVICE_CRM + "/api/CrmMedia/Download/{id}",
    CRM_CRMMEDIA_GET_ALL: SERVICE_CRM + "/api/CrmMedia",
    CRM_CRMMEDIA_CREATE: SERVICE_CRM + "/api/CrmMedia",
    CRM_CRMMEDIA_GET_BY_ID: SERVICE_CRM + "/api/CrmMedia/{id}", //NEW -> CHANGE
    CRM_CRMMEDIA_UPDATE: SERVICE_CRM + "/api/CrmMedia/{id}",
    CRM_CRMMEDIA_DELETE: SERVICE_CRM + "/api/CrmMedia/{id}",

    CRM_CONVERSATION_GET: SERVICE_CRM + "/api/CrmConversation/{id}",
    CRM_CONVERSATION_REPLY: SERVICE_CRM + "/api/CrmConversation/Reply",

    //Contractors
    CONTRACTORS_GET_ALL: SERVICE_ERC + "/api/TPKContractor/GetAllTPKContractor",
    CONTRACTORS_GET_BY_ID: SERVICE_ERC + "/api/TPKContractor/GetTPKContractorById/{id}",
    CONTRACTORS_CREATE: SERVICE_ERC + "/api/TPKContractor/CreateTPKContractor",
    CONTRACTORS_UPDATE: SERVICE_ERC + "/api/TPKContractor/UpdateTPKContractor/{id}",
    CONTRACTORS_DELETE: SERVICE_ERC + "/api/TPKContractor/DeleteTPKContractor/{id}",
    // more todo

    // P12
    P12_VERIFY_CODE: SERVICE_ERC + "/api/Entry/CheckPassword",

    ERC_WORKERCREDS_GET_BY_EMPLOYE: SERVICE_ERC + "/api/WorkerCreds/GetCreds",

    // TPK
    TPK_RECEIPT_SEND: SERVICE_ERC + "/api/Entry/EntryAction",
    TPK_RECEIPT_GET: SERVICE_ERC + "/api/Entry/GetResult",
    TPK_ARCHIVE_SEARCH: SERVICE_ERC + "/api/TPKEDMResult/GetTPKEDMResultsByPESEL/{id}",
    //TPK_ARCHIVE_SEARCH: SERVICE_ERC + '/api/TPKEDMResult/GetAllTPKEDMResult',

    TPK_REFERRAL_SEND: SERVICE_ERC + "/api/Entry/EntryActionSkierowanie",
    TPK_REFERRAL_GET: SERVICE_ERC + "/api/Entry/GetResultESkierowanie",

    EDM_VISITSUMMARY_SEND: SERVICE_ERC + "/api/Entry/EntryActionEDM",
    EDM_VISITSUMMARY_GET: SERVICE_ERC + "/api/Entry/GetResultEDM",
    EDM_VISITSUMMARY_GET_DIAGNOSIS: SERVICE_ERC + "/api/Entry/GetDiagnosisEDM",

    TPK_EDM_RESULT_SEND: SERVICE_ERC + "/api/TPKEDMResult/CreateTPKEDMResult",

    GENERATE_PDF_FROM_HTML: SERVICE_ERC + "/api/PDFGenerator/GeneratePDF", //NEW
    GOLD_TOOLS_PDF_FROM_HTML: SERVICE_FINANCE + "/api/Tools/PrintFromHtml", //NEW

    P1_DICT_GET_BY_ID: SERVICE_ERC + "/api/Entry/GetP1Dict/{id}",

    //IPOM
    IPOM_SEND: SERVICE_ERC + "/api/Entry/EntryActionIPOM",
    IPOM_GET: SERVICE_ERC + "/api/Entry/GetResultIPOM",

    // TELE
    TELE_RESULT_GET_ALL: SERVICE_TELE + "/api/NightResult/GetAll",
    TELE_RESULT_GET_BY_ID: SERVICE_TELE + "/api/NightResult/GetById/{id}",
    TELE_RESULT_GET_BY_DOMAIN: SERVICE_TELE + "/api/NightResult/GetByDomain/{id}",
    TELE_RESULT_GET_BY_PATIENT_ID: SERVICE_TELE + "/api/NightResult/GetByPatientId/{id}",

    TELE_RESULT_PUT: SERVICE_TELE + "/api/NightResult/Update/{id}",
    TELE_RESULT_DELETE: SERVICE_TELE + "/api/NightResult/Delete/{id}",

    TELE_RESULT_SEND: SERVICE_TELE + "/api/NightResult/Create",

    // ****************************************************************************************
    // SERVICE_EWUS
    // ****************************************************************************************
    EWUS_CHECK_PESEL: SERVICE_EWUS + "/api/NFZEWUSQueue/CheckPesel",
    EWUS_CHECK_RESPONSE: SERVICE_EWUS + "/api/NFZEWUSQueue/CheckResponse?requestId={id}",
    EWUS_GET_HISTORY: SERVICE_EWUS + "/api/NFZEWUSQueue/GetHistory",
    EWUS_GET_HISTORY_BY_PESEL: SERVICE_EWUS + "/api/NFZEWUSQueue/ForPatient/{pesel}", //NEW

    // ****************************************************************************************
    // RIS SERVICE
    // ****************************************************************************************
    JGP2_CALCULATE: SERVICE_FINANCE + '/api/NfzJgp/Calculate',
    JGP2_GET_ALL_CODES: SERVICE_FINANCE + '/api/NfzJgp/AllNfzCodes',
    JGP2_GET_ALL_ITEMS: SERVICE_FINANCE + '/api/NfzJgp/AllListItems',
    JGP2_INFO: SERVICE_FINANCE + '/api/NfzJgp/Info/{code}',






    ZBPOZ_GENERAL: SERVICE_FINANCE + '/api/NfzZbpoz',
    ZBPOZ_FILTER: SERVICE_FINANCE + '/api/NfzZbpoz/ForDomain/{domain}/{year}/{month}',
    ZBPOZ_BY_ID: SERVICE_FINANCE + '/api/NfzZbpoz/{id}',
    ZBPOZ_GENERATE_XML: SERVICE_FINANCE + '/api/NfzZbpoz/GenerateXml/{id}',

    LIOCZ_BY_DOMAIN: SERVICE_FINANCE + '/api/NfzLiocz/ForDomain/{domain}/{year}/{month}',
    LIOCZ_GENERAL: SERVICE_FINANCE + '/api/NfzLiocz',
    LIOCZ_BY_ID: SERVICE_FINANCE + '/api/NfzLiocz/{id}',
    LIOCZ_GENERATE_XML: SERVICE_FINANCE + '/api/NfzLiocz/GenerateXml/{id}',

    NFZ_INVOICE_ADD_CUSTOM: SERVICE_FINANCE + '/api/NfzInvoice/Custom',
    NFZ_INVOICE_ADD_FROM_UMX: SERVICE_FINANCE + '/api/NfzInvoice/FromUmx/{umxId}/{month}',
    NFZ_INVOICE_FILTER: SERVICE_FINANCE + '/api/NfzInvoice/ForDomain/{domain}/{year}/{month}/{type}',
    NFZ_INVOICE_PREVIEW_FROM_TEMPLATE: SERVICE_FINANCE + '/api/NfzInvoice/FromTemplate/{templateId}',
    NFZ_INVOICE_ACCEPT_FROM_TEMPLATE: SERVICE_FINANCE + '/api/NfzInvoice/AcceptFromTemplate',
    NFZ_INVOICE_BY_ID: SERVICE_FINANCE + '/api/NfzInvoice/{id}',
    NFZ_INVOICE_GENERATE_XML: SERVICE_FINANCE + '/api/NfzInvoice/GenerateXml/{id}',
    NFZ_INVOICE_GENERAL: SERVICE_FINANCE + '/api/NfzInvoice/ForDomain/{domain}',

    NFZ_TEMPLATE_GENERAL: SERVICE_FINANCE + '/api/NfzInvoiceTemplate/ForDomain/{domain}',
    NFZ_TEMPLATE_BY_ID: SERVICE_FINANCE + '/api/NfzInvoiceTemplate/{id}',
    NFZ_TEMPLATE_DOWNLOAD: SERVICE_FINANCE + '/api/NfzInvoiceTemplate/Download/{id}',
    NFZ_TEMPLATE_UPLOAD: SERVICE_FINANCE + '/api/NfzInvoiceTemplate/Upload',



    NFZ_GRUPER_GET_ALL: SERVICE_FINANCE + '/api/NfzJgp/All',
    NFZ_GRUPER_UPDATE_LIST: SERVICE_FINANCE + '/api/NfzJgp/UpdateList/{id}',
    NFZ_GRUPER_UPDATE_GROUP: SERVICE_FINANCE + '/api/NfzJgp/UpdateGroup/{id}',



    RIS_SWX_GENERAL: SERVICE_FINANCE + '/api/NfzSwx',
    RIS_SWX_CREATE_FOR_CONTRACT: SERVICE_FINANCE + '/api/NfzSwx/CreateForContract',
    RIS_SWX_GET_BY_ID: SERVICE_FINANCE + '/api/NfzSwx/{id}',
    RIS_SWX_GET_BY_DOMAIN: SERVICE_FINANCE + '/api/NfzSwx/ForDomain/{domain}/{year}/{month}',
    RIS_SWX_NOTE: SERVICE_FINANCE + '/api/NfzSwx/Note',
    //RIS_SWX_GENERATE_XML: 'https://localhost:44387/api/NfzSwx/GenerateXml/{id}/{mode}',
    RIS_SWX_GENERATE_XML: SERVICE_FINANCE + '/api/NfzSwx/GenerateXml/{id}/{mode}',
    RIS_SWX_PREVALIDATE: SERVICE_FINANCE + '/api/NfzSwx/Prevalidate/{id}/{mode}',
    RIS_SWX_RESPONSE_UPLOAD: SERVICE_FINANCE + '/api/NfzSwx/UploadResponse',
    RIS_SWX_MULTI_RESPONSE_UPLOAD: 'https://localhost:44387/api/NfzSwx/UploadMultiResponse',// SERVICE_FINANCE + '/api/NfzSwx/UploadMultiResponse',
    //RIS_SWX_RESPONSE_UPLOAD: 'https://localhost:44387/api/NfzSwx/UploadResponse',

    RIS_APPOINTMENT_GET_ALL: SERVICE_RIS + '/api/RISAppointment',
    RIS_APPOINTMENT_GET_BY_ID: SERVICE_RIS + '/api/RISAppointment/{id}',
    RIS_APPOINTMENT_CREATE: SERVICE_RIS + '/api/RISAppointment',
    RIS_APPOINTMENT_CREATE_WITHOUT_PACKS: SERVICE_RIS + '/api/RISAppointment/CreateWithoutPacks',
    RIS_APPOINTMENT_CREATE_V2: SERVICE_RIS + '/api/RISAppointment/CreateVer2',
    RIS_APPOINTMENT_UPDATE: SERVICE_RIS + '/api/RISAppointment/{id}',
    RIS_APPOINTMENT_DELETE: SERVICE_RIS + '/api/RISAppointment/{id}',
    RIS_APPOINTMENTS_PATIENT: SERVICE_RIS + '/api/RISAppointment/Patient/{id}',
    RIS_APPOINTMENTS_AVAILABLE: SERVICE_RIS + '/api/RISAppointment/Available/{id}',//NEW
    RIS_APPOINTMENTS_AVAILABLE_FILTER: SERVICE_RIS + '/api/RISAppointment/Available',//NEW
    RIS_APPOINTMENTS_FOR_DEVICE: SERVICE_RIS + '/api/RISAppointment/ForDevice',
    RIS_APPOINTMENT_GET_PACKS_STATUS: SERVICE_RIS + '/api/RISAppointment/GetPACKSStatus/{id}',//NEW
    RIS_APPOINTMENTS_DELETE_PACKSAPPOINTMENT: SERVICE_RIS + '/api/RISAppointment/DeletePacksAppointment',//NEW
    RIS_APPOINTMENTS_UPDATE_PACKSAPPOINTMENT: SERVICE_RIS + '/api/RISAppointment/UpdatePacksAppointment', //NEW
    RIS_APPOINTMENTS_GET_BY_SCHEDULEAPPOINTMENT_ID: SERVICE_RIS + '/api/RISAppointment/FromAppointments',//NEW
    RIS_GENERATE_XML: SERVICE_RIS + '/api/RISAppointment/GenerateXML',
    RIS_SEND_TO_R2: SERVICE_RIS + '/api/RISAppointment/r2/{id}',//NEW
    RIS_ADD_STATUS: SERVICE_RIS + '/api/RISAppointment/AddStatus/{id}',
    RIS_APPOINTMENT_UPLOAD_EXTERNALRESULT: SERVICE_RIS + '/api/RISAppointment/UploadExternalResult',//NEW
    RIS_APPOINTMENT_DOWNLOAD_RESULT: SERVICE_RIS + '/api/RISAppointment/DownloadResult/{id}',//NEW
    RIS_APPOINTMENT_FILTER: SERVICE_RIS + '/api/RISAppointment/Filter',//NEW
    RIS_APPOINTMENT_GET_BLOCKED: SERVICE_RIS + '/api/RISAppointment/GetBlockedByDomain/{domain}',
    RIS_APPOINTMENT_BLOCK: SERVICE_RIS + '/api/RISAppointment/BlockExamination/{id}',
    RIS_APPOINTMENT_BLOCK_REMOVE: SERVICE_RIS + '/api/RISAppointment/RemoveBlock/{id}',
    RIS_APPOINTMENT_FILTER_LIGHT: SERVICE_RIS + '/api/RISAppointment/FilterLight',//NEW
    RIS_APPOINTMENTS_GET_LIGHT_BY_PATIENT: SERVICE_RIS + '/api/RISAppointment/GetLightByPatient/{id}',
    RIS_APPOINTMENTS_SET_RESULT_IN_OTHER_SYSTEM: SERVICE_RIS + '/api/RISAppointment/SetResultInOtherSystem/{id}',
    RIS_APPOINTMENTS_SET_REFERRAL_FILE_ID: SERVICE_RIS + '/api/RISAppointment/SetReferralFileId/{id}',
    RIS_APPOINTMENTS_SET_DESCRIPTION_DECISION: SERVICE_RIS + '/api/RISAppointment/SetDescriptionDecision/{id}',
    RIS_APPOINTMENTS_CHANGE_DESCRIPTION_INTENDED: SERVICE_RIS + '/api/RISAppointment/ChangeDescriptionByIntended/{id}',


    RIS_EXAMDESCRIPTIONACCESS_GET_ALL: SERVICE_RIS + '/api/ExamDescriptionAccess',//NEW
    RIS_EXAMDESCRIPTIONACCESS_GET_BY_ID: SERVICE_RIS + '/api/ExamDescriptionAccess/{id}',//NEW
    RIS_EXAMDESCRIPTIONACCESS_CREATE: SERVICE_RIS + '/api/ExamDescriptionAccess',//NEW
    RIS_EXAMDESCRIPTIONACCESS_UPDATE: SERVICE_RIS + '/api/ExamDescriptionAccess/{id}',//NEWs
    RIS_EXAMDESCRIPTIONACCESS_DELETE: SERVICE_RIS + '/api/ExamDescriptionAccess/{id}',//NEW

    RIS_BURN_RESULT: SERVICE_RIS + '/api/Helper/BurnResult/{site}/{number}',//NEW

    RIS_ADD_FILES: SERVICE_RIS + '/api/RISAppointment/SetFiles',

    RIS_EMPLOYEE_STATS: SERVICE_RIS + '/api/RISAppointment/EmployeeStats',

    RIS_APPOINTMENT_CORRECT: SERVICE_RIS + '/api/RISAppointment/Correct',
    RIS_APPOINTMENT_ADD_EDMHISTORY: SERVICE_RIS + '/api/RisAppointment/AddEdmHistory/{id}/{edmNumber}', //NEW

    RIS_APPOINTMENT_EXAMINATOR_COMMENT_UPDATE: SERVICE_RIS + '/api/RISAppointment/ExaminatorComment/{id}',
    RIS_APPOINTMENT_CHANGE_DESCRIPTION_BY_REFERRER: SERVICE_RIS + '/api/RISAppointment/ChangeDescriptionByReferrer/{id}',

    RIS_APPOINTMENT_CANCEL_SIMPLE: SERVICE_RIS + '/api/RISAppointment/CancelSimple/{id}',
    RIS_APPOINTMENT_UPDATE_SIMPLE: SERVICE_RIS + '/api/RISAppointment/UpdateSimple/{id}',

    RIS_GET_STATS: SERVICE_RIS + '/api/Stats/GetStats',
    RIS_GET_ACK_STATS: SERVICE_RIS + '/api/Stats/AckStorageCount',

    // ****************************************************************************************
    // RESULTS SERVICE
    // ****************************************************************************************
    RESULTS_MEDICAL_PROCEDURE_GET_ALL: SERVICE_RESULTS + "/api/MedicalProcedureType",
    RESULTS_MEDICAL_PROCEDURE_GET: SERVICE_RESULTS + "/api/MedicalProcedureType/{id}",

    RESULTS_LAB_EXAMINATION_TYPE_GET_ALL: SERVICE_RESULTS + "/api/LabExaminationType",
    RESULTS_LAB_EXAMINATION_TYPE_GET_BY_ID: SERVICE_RESULTS + "/api/LabExaminationType/{id}",
    RESULTS_LAB_EXAMINATION_TYPE_CREATE: SERVICE_RESULTS + "/api/LabExaminationType",
    RESULTS_LAB_EXAMINATION_TYPE_UPDATE: SERVICE_RESULTS + "/api/LabExaminationType/{id}",
    RESULTS_LAB_EXAMINATION_TYPE_DELETE: SERVICE_RESULTS + "/api/LabExaminationType/{id}",

    RESULTS_IMAGE_EXAMINATION_TYPE_GET_ALL: SERVICE_RESULTS + "/api/ImageExaminationType",
    RESULTS_IMAGE_EXAMINATION_TYPE_GET_BY_ID: SERVICE_RESULTS + "/api/ImageExaminationType/{id}",
    RESULTS_IMAGE_EXAMINATION_TYPE_CREATE: SERVICE_RESULTS + "/api/ImageExaminationType",
    RESULTS_IMAGE_EXAMINATION_TYPE_UPDATE: SERVICE_RESULTS + "/api/ImageExaminationType/{id}",
    RESULTS_IMAGE_EXAMINATION_TYPE_DELETE: SERVICE_RESULTS + "/api/ImageExaminationType/{id}",

    RESULTS_SAMPLE_BASE_GET_ALL: SERVICE_RESULTS + "/api/SampleBase",
    RESULTS_SAMPLE_BASE_GET: SERVICE_RESULTS + "/api/SampleBase/{id}",

    RESULTS_GET_RESULTS_BY_PATIENT: SERVICE_RESULTS + "/api/MedicalResults/Patient/{id}",
    RESULTS_GET_FULL_RESULTS_BY_PATIENT: SERVICE_RESULTS + "/api/MedicalResults/FullForPatient/{id}",
    RESULTS_GET_RESULT_FILE_BY_ID: SERVICE_RESULTS + "/api/MedicalResults/DownloadResult/{id}/{num}",
    RESULTS_GET_RESULTS_BY_PATIENT_PROGRAM: SERVICE_RESULTS + "/api/MedicalResults/GetByProgramIdForPatient/{programId}/{patientId}",
    RESULTS_GET_RESULT_BY_ID: SERVICE_RESULTS + '/api/MedicalResults/{id}',
    RESULTS_ADD_STATUS: SERVICE_RESULTS + '/api/MedicalResults/AddStatus/{id}',
    RESULTS_REMOVE: SERVICE_RESULTS + '/api/MedicalResults/Remove/{id}',
    RESULTS_RESTORE: SERVICE_RESULTS + '/api/MedicalResults/Restore/{id}',

    RESULTS_OTHER_EXAMINATION_TYPE_GET_ALL: SERVICE_RESULTS + "/api/OtherExaminationType",
    RESULTS_OTHER_EXAMINATION_TYPE_GET_BY_ID: SERVICE_RESULTS + "/api/OtherExaminationType/{id}",

    //EXAMINATION TYPES
    RESULTS_DENTAL_EXAMINATION_TYPE_GET_ALL: SERVICE_RESULTS + "/api/DentalExaminationType",
    RESULTS_DENTAL_EXAMINATION_TYPE_GET_BY_ID: SERVICE_RESULTS + "/api/DentalExaminationType/{id}",
    RESULTS_PSYCHE_EXAMINATION_TYPE_GET_ALL: SERVICE_RESULTS + "/api/PsycheExaminationType",
    RESULTS_PSYCHE_EXAMINATION_TYPE_GET_BY_ID: SERVICE_RESULTS + "/api/PsycheExaminationType/{id}",
    RESULTS_PHYSIO_EXAMINATION_TYPE_GET_ALL: SERVICE_RESULTS + "/api/PhysiotherapyExaminationType",
    RESULTS_PHYSIO_EXAMINATION_TYPE_GET_BY_ID: SERVICE_RESULTS + "/api/PhysiotherapyExaminationType/{id}",

    RESULTS_DEEPZOOM_GET_FILE: SERVICE_RESULTS + "/api/DeepZoom/File/{id}/{file}",

    RESULTS_DOWNLOAD_RESULT_BY_CODE: SERVICE_RESULTS + "/api/MedicalResults/DownloadResultByCode/{id}/{num}",

    RESULTS_CYTO_HISTORY_DOMAIN_100_BY_PESEL: SERVICE_RESULTS + "/api/CytoHistoryDomain100/Patient/{pesel}",

    RESULTS_FILTER_RESULTS: SERVICE_RESULTS + "/api/MedicalResults/Filter",
    RESULTS_FILTER_BY_EXAM: SERVICE_RESULTS + "/api/MedicalResults/FilterByExam",
    RESULTS_ADD_EXTERNAL: SERVICE_RESULTS + "/api/MedicalResults",

    RESULTS_UPLOAD_EXTERNAL: SERVICE_RESULTS + "/api/ResultFile/Upload",

    RESULTS_USE_IN_APPOINTMENT: SERVICE_RESULTS + '/api/MedicalResults/UseInAppointment/{id}',

    RESULTS_EDIT_EXAM_TYPE_IDS: SERVICE_RESULTS + '/api/MedicalResults/EditExamTypeIds/{id}',

    RESULTS_P1_REFERRAL_GET_BY_DESTINATION_TYPE: SERVICE_RESULTS + '/api/P1ResultsReferral/GetByDestinationType/{domain}/{type}',
    RESULTS_P1_REFERRAL_GET_BY_DESTINATION_TYPE_SHORT: SERVICE_RESULTS + '/api/P1ResultsReferral/GetByDestinationTypeShort/{domain}/{type}',
    RESULTS_P1_REFERRAL_GET_BY_DESTINATION_CLINIC: SERVICE_RESULTS + '/api/P1ResultsReferral/GetByDestinationClinic/{domain}/{clinic}',
    RESULTS_P1_REFERRAL_USED_IN_AOS: SERVICE_RESULTS + '/api/P1ResultsReferral/UsedInAOS/{id}',
    RESULTS_P1_REFERRAL_GET_BY_ID: SERVICE_RESULTS + '/api/P1ResultsReferral/{id}',
    RESULTS_P1_REFERRAL_GET_BY_KLUCZ: SERVICE_RESULTS + '/api/P1ResultsReferral/ByKlucz/{domain}/{patientId}/{klucz}',
    RESULTS_P1_REFERRAL_UPLOAD_P1_RESULT: SERVICE_RESULTS + '/api/P1ResultsReferral/UploadP1Result',
    RESULTS_P1_REFERRAL_UPDATE: SERVICE_RESULTS + '/api/P1ResultsReferral/Update/{id}',
    RESULTS_P1_REFERRAL_UPDATE_BY_KLUCZ: SERVICE_RESULTS + '/api/P1ResultsReferral/UpdateByKlucz/{domain}/{patientId}/{klucz}',
    RESULTS_P1_REFERRAL_HTML_DOWNLOAD: SERVICE_RESULTS + '/api/P1ResultsReferral/Download/{id}',
    RESULTS_P1_REFERRAL_FILTER: SERVICE_RESULTS + '/api/P1ResultsReferral/Filter',

    RESULTS_P1_REFERRAL_CREATE: SERVICE_RESULTS + '/api/P1ResultsReferral',
    RESULTS_P1_REFERRAL_REMOVE: SERVICE_RESULTS + '/api/P1ResultsReferral/Remove/{id}',
    RESULTS_P1_REFERRAL_RESTORE: SERVICE_RESULTS + '/api/P1ResultsReferral/Restore/{id}',

    RESULTS_P1_RESULT_ZM_GET_BY_KLUCZ: SERVICE_RESULTS + '/api/P1ResultZm/ByKlucz/{domain}/{patientId}/{klucz}',

    RESULTS_IMMUNIZATION_GET: SERVICE_RESULTS + '/api/ResultImmunization/{id}',
    RESULTS_IMMUNIZATION_EXTENDED_GET: SERVICE_RESULTS + '/api/ResultImmunization/ImmunizationExtended/{id}',
    RESULTS_IMMUNIZATION_GET_BY_PATIENT: SERVICE_RESULTS + '/api/ResultImmunization/GetByPatient/{domain}/{patientId}',
    RESULTS_IMMUNIZATION_GET_BY_APPOINTMENT: SERVICE_RESULTS + '/api/ResultImmunization/GetByAppointment/{domain}/{patientId}/{appId}',
    RESULTS_IMMUNIZATION_POST: SERVICE_RESULTS + '/api/ResultImmunization',
    RESULTS_IMMUNIZATION_REMOVE: SERVICE_RESULTS + '/api/ResultImmunization/Remove/{id}',
    RESULTS_IMMUNIZATION_RESTORE: SERVICE_RESULTS + '/api/ResultImmunization/Restore/{id}',
    RESULTS_IMMUNIZATION_QUALIFICATION_GET: SERVICE_RESULTS + '/api/ResultImmunization/Qualification/{id}',
    RESULTS_IMMUNIZATION_QUALIFICATION_EXTENDED_GET: SERVICE_RESULTS + '/api/ResultImmunization/QualificationExtended/{id}',
    RESULTS_IMMUNIZATION_QUALIFICATION_UPDATE: SERVICE_RESULTS + '/api/ResultImmunization/UpdateQualification/{id}',
    RESULTS_IMMUNIZATION_QUALIFICATION_GET_BY_PATIENT: SERVICE_RESULTS + '/api/ResultImmunization/Qualification/GetByPatient/{domain}/{patientId}',
    RESULTS_IMMUNIZATION_QUALIFICATION_GET_BY_APPOINTMENT: SERVICE_RESULTS + '/api/ResultImmunization/Qualification/GetByAppointment/{domain}/{patientId}/{appId}',
    RESULTS_IMMUNIZATION_QUALIFICATION_POST: SERVICE_RESULTS + '/api/ResultImmunization/CreateQualification',
    RESULTS_IMMUNIZATION_QUALIFICATION_REMOVE: SERVICE_RESULTS + '/api/ResultImmunization/QualificationRemove/{id}',
    RESULTS_IMMUNIZATION_QUALIFICATION_RESTORE: SERVICE_RESULTS + '/api/ResultImmunization/QualificationRestore/{id}',
    RESULTS_IMMUNIZATION_JOIN_OBSERVATION: SERVICE_RESULTS + '/api/ResultImmunization/JoinObservationId/{id}/{observationId}',
    RESULTS_IMMUNIZATION_CREATE_WITH_QUALIFICATION: SERVICE_RESULTS + '/api/ResultImmunization/ImmunizationAndQualification',
    RESULTS_IMMUNIZATION_QUALIFICATION_GET_COMPLETED_BY_PATIENT: SERVICE_RESULTS + '/api/ResultImmunization/QualificationCompleted/GetByPatient/{domain}/{patientId}',

    RESULTS_IMMUNIZATION_FILTER: SERVICE_RESULTS + '/api/ResultImmunization/Immunization/Filter',
    RESULTS_IMMUNIZATION_QUALIFICATION_FILTER: SERVICE_RESULTS + '/api/ResultImmunization/Qualification/Filter',


    RESULTS_TEST_RESULT_GET_BY_PATIENT: SERVICE_RESULTS + '/api/SZOKSingleTestResult/GetAllSZOKSingleTestResultForPatient/{domain}/{patientId}',

    // ****************************************************************************************
    // ZUS SERVICE
    // ****************************************************************************************
    ZUS_CHECK_PASSWORD: SERVICE_ZUS + "/api/ZUS/CheckPassword",
    ZUS_ENTRY_ACTION: SERVICE_ZUS + "/api/ZUS/EntryAction",
    ZUS_GET_RESULT: SERVICE_ZUS + "/api/ZUS/GetResult",

    // ****************************************************************************************
    // SOLAB SERVICE
    // ****************************************************************************************
    SOLAB_LAB_ORDER_COMPLETE_CREATE: SERVICE_SOLAB + "/api/SOLAB/CreateLabOrderComplete",
    SOLAB_RESULT_ORDER_COMPLETE: SERVICE_SOLAB + "/api/SOLAB/GetResultOrderComplete?orderId={orderId}",

    // ****************************************************************************************
    // DEEPZOOM
    // ****************************************************************************************
    LAB_DEEPZOOM_GET_BY_ID: SERVICE_LAB + "/api/LabDeepZoomDescription/GetById/{id}", //NEW
    LAB_DEEPZOOM_UPDATE: SERVICE_LAB + "/api/LabDeepZoomDescription/Update/{id}", //NEW
    LAB_DEEPZOOM_CREATE: SERVICE_LAB + "/api/LabDeepZoomDescription/Create", //NEW
    LAB_DEEPZOOM_DELETE: SERVICE_LAB + "/api/LabDeepZoomDescription/Delete/{id}", //NEW
    LAB_DEEPZOOM_SET_LOCK: SERVICE_LAB + "/api/LabDeepZoomDescription/SetLock/{id}/{value}", //NEW
    LAB_SAMPLE_DEEPZOOM_UPDATE_IMAGE: SERVICE_LAB + "/api/LabSample/UpdateDeepZoom/{id}", //NEW
    LAB_SAMPLES_DEEPZOOM_SET_SHAPES: SERVICE_LAB + "/api/LabSample/SetDeepZoomShapes/{id}/{shapesId}", //NEW

    //**
    //EZWM 'https://localhost:44330'
    //
    EZWM_GET: SERVICE_TEST + '/api/EZWM/EZWMGetResult',
    EZWM_SEND: SERVICE_TEST + '/api/EZWM/EZWMAction',
    EZWM_DOWNLOAD_RESULT: SERVICE_TEST + '/api/EZWM/EZWMDownloadResult/{id}',
    EZWM_WORKERCREDS_CREATE: SERVICE_TEST + '/api/WorkerCreds/EZWMCreateCreds',
    EZWM_WORKERCREDS_UPDATE: SERVICE_TEST + '/api/WorkerCreds/EZWMUpdateCreds',
    EZWM_WORKERCREDS_GET: SERVICE_TEST + '/api/WorkerCreds/EZWMGetCreds',
    EZWM_WORKERCREDS_ADD_CONFIGURATION: SERVICE_TEST + '/api/WorkerCreds/EZWMAddWorkerConfiguration',
    EZWM_WORKERCREDS_UPDATE_CONFIGURATION: SERVICE_TEST + '/api/WorkerCreds/EZWMUpdateWorkerConfiguration',
    EZWM_WORKERCREDS_SETACTIVE_CONFIGURATION: SERVICE_TEST + '/api/WorkerCreds/EZWMWorkerConfigurationSetActive',
    EZWM_WORKERCREDS_GET_BY_WORKER: SERVICE_TEST + '/api/WorkerCreds/EZWMGetWorker/{workerId}',
    EZWM_WORKERCREDS_UPDATE_PASSWORD: SERVICE_TEST + '/api/WorkerCreds/EZWMUpdateWorkerPassword',
    EZWM_CHECK_PASSWORD: SERVICE_TEST + '/api/EZWM/CheckPassword',
    EZWM_DOWNLOAD_ORDER_PDF: SERVICE_TEST + '/api/EZWM/EZWMDownloadOrderPdf/{id}',
    EZWM_GET_INITIAL_DATA: SERVICE_TEST + '/api/EZWM/GetInitialData',
    EZWM_WORKERCREDS_ADD_CERT: SERVICE_TEST + '/api/WorkerCreds/EZWMAddCert',




    //****************************************************************************************
    //DILO
    //****************************************************************************************
    DILO_GET: SERVICE_DILO + '/api/DILO/DILOGetResult',
    DILO_SEND: SERVICE_DILO + '/api/DILO/DILOAction',
    DILO_DOWNLOAD_RESULT: SERVICE_DILO + '/api/DILO/DILODownloadResult/{id}',
    DILO_WORKERCREDS_CREATE: SERVICE_DILO + '/api/WorkerCreds/DILOCreateCreds',
    DILO_WORKERCREDS_UPDATE: SERVICE_DILO + '/api/WorkerCreds/DILOUpdateCreds',
    DILO_WORKERCREDS_GET: SERVICE_DILO + '/api/WorkerCreds/DILOGetCreds',
    DILO_WORKERCREDS_ADD_CONFIGURATION: SERVICE_DILO + '/api/WorkerCreds/DILOAddWorkerConfiguration',
    DILO_WORKERCREDS_UPDATE_CONFIGURATION: SERVICE_DILO + '/api/WorkerCreds/DILOUpdateWorkerConfiguration',
    DILO_WORKERCREDS_SETACTIVE_CONFIGURATION: SERVICE_DILO + '/api/WorkerCreds/DILOWorkerConfigurationSetActive',
    DILO_WORKERCREDS_GET_BY_WORKER: SERVICE_DILO + '/api/WorkerCreds/DILOGetWorker/{workerId}',
    DILO_WORKERCREDS_UPDATE_PASSWORD: SERVICE_DILO + '/api/WorkerCreds/DILOUpdateWorkerPassword',

    //****************************************************************************************
    //RECOMMENDATION CONFIG
    //****************************************************************************************
    RECOMMENDATION_CONFIG_GET_WITH_REMOVED: SERVICE_PATIENT_DATA + '/api/RecommendationConfig/GetAll',
    RECOMMENDATION_CONFIG_GET_ALL: SERVICE_PATIENT_DATA + '/api/RecommendationConfig',
    RECOMMENDATION_CONFIG_GET: SERVICE_PATIENT_DATA + '/api/RecommendationConfig/{id}',
    RECOMMENDATION_CONFIG_GET_BY_TYPE: SERVICE_PATIENT_DATA + '/api/RecommendationConfig/ByType/{type}',
    RECOMMENDATION_CONFIG_REMOVE: SERVICE_PATIENT_DATA + '/api/RecommendationConfig/Remove/{id}',
    RECOMMENDATION_CONFIG_RESTORE: SERVICE_PATIENT_DATA + '/api/RecommendationConfig/Restore/{id}',

    RECOMMENDATION_CONFIG_GROUP_GET_WITH_REMOVED: SERVICE_PATIENT_DATA + '/api/RecommendationConfigGroup/GetAll',
    RECOMMENDATION_CONFIG_GROUP_GET_ALL: SERVICE_PATIENT_DATA + '/api/RecommendationConfigGroup',
    RECOMMENDATION_CONFIG_GROUP_GET: SERVICE_PATIENT_DATA + '/api/RecommendationConfigGroup/{id}',
    RECOMMENDATION_CONFIG_GROUP_GET_BY_TYPE: SERVICE_PATIENT_DATA + '/api/RecommendationConfigGroup/ByType/{type}',
    RECOMMENDATION_CONFIG_GROUP_REMOVE: SERVICE_PATIENT_DATA + '/api/RecommendationConfigGroup/Remove/{id}',
    RECOMMENDATION_CONFIG_GROUP_RESTORE: SERVICE_PATIENT_DATA + '/api/RecommendationConfigGroup/Restore/{id}',

    //****************************************************************************************
    //RECOMMENDATION GOAL CONFIG
    //****************************************************************************************

    RECOMMENDATION_GOAL_GET_WITH_REMOVED: SERVICE_PATIENT_DATA + '/api/GoalConfig/GetAll',
    RECOMMENDATION_GOAL_GET_ALL: SERVICE_PATIENT_DATA + '/api/GoalConfig',
    RECOMMENDATION_GOAL_GET: SERVICE_PATIENT_DATA + '/api/GoalConfig/{id}',
    RECOMMENDATION_GOAL_GET_BY_TYPE: SERVICE_PATIENT_DATA + '/api/GoalConfig/ByType/{type}',
    RECOMMENDATION_GOAL_REMOVE: SERVICE_PATIENT_DATA + '/api/GoalConfig/Remove/{id}',
    RECOMMENDATION_GOAL_RESTORE: SERVICE_PATIENT_DATA + '/api/GoalConfig/Restore/{id}',

    RECOMMENDATION_GOAL_GROUP_GET_WITH_REMOVED: SERVICE_PATIENT_DATA + '/api/GoalConfigGroup/GetAll',
    RECOMMENDATION_GOAL_GROUP_GET_ALL: SERVICE_PATIENT_DATA + '/api/GoalConfigGroup',
    RECOMMENDATION_GOAL_GROUP_GET: SERVICE_PATIENT_DATA + '/api/GoalConfigGroup/{id}',
    RECOMMENDATION_GOAL_GROUP_GET_BY_TYPE: SERVICE_PATIENT_DATA + '/api/GoalConfigGroup/ByType/{type}',
    RECOMMENDATION_GOAL_GROUP_REMOVE: SERVICE_PATIENT_DATA + '/api/GoalConfigGroup/Remove/{id}',
    RECOMMENDATION_GOAL_GROUP_RESTORE: SERVICE_PATIENT_DATA + '/api/GoalConfigGroup/Restore/{id}',

    CASH_OPERATION_GET_ALL: SERVICE_FINANCE + '/api/CashOperation',
    CASH_OPERATION_GET: SERVICE_FINANCE + '/api/CashOperation/{id}',

    FISCAL_DOCUMENTS_GET_ALL: SERVICE_FINANCE + '/api/FiscalDocuments',
    FISCAL_DOCUMENTS_GET: SERVICE_FINANCE + '/api/FiscalDocuments/{id}',

    FISCAL_PRINTER_RECEIPT: SERVICE_FINANCE + '/api/FiscalPrinter/Receipt',
    FISCAL_PRINTER_INVOICE: SERVICE_FINANCE + '/api/FiscalPrinter/Invoice',
    FISCAL_PRINTER_REPORT: SERVICE_FINANCE + '/api/FiscalPrinter/Report',
    FISCAL_PRINTER_HEALTHCHECK: SERVICE_FINANCE + '/api/FiscalPrinter/Healthcheck',

    PRODUCTS_CART_GET_ALL: SERVICE_FINANCE + '/api/ProductsCart',
    PRODUCTS_CART_GET: SERVICE_FINANCE + '/api/ProductsCart/{id}',

    TRANSACTION_PAYMENT_GET_ALL: SERVICE_FINANCE + '/api/TransactionPayment',
    TRANSACTION_PAYMENT_GET: SERVICE_FINANCE + '/api/TransactionPayment/{id}',

    //****************************************************************************************
    //SERVICE MISC
    //****************************************************************************************
    MISC_P1EDeklaracja_POST: SERVICE_MISC + '/api/P1EDeklaracja/P1EDeklaracjaAction',
    MISC_P1EDeklaracja_GET: SERVICE_MISC + '/api/P1EDeklaracja/P1EDeklaracjaGetResult/{id}',
    MISC_P1ZM_POST: SERVICE_MISC + '/api/P1ZM/P1ZMAction',
    MISC_P1ZM_GET: SERVICE_MISC + '/api/P1ZM/P1ZMGetResult/{id}',
    MISC_WORKERCREDS_GET: SERVICE_MISC + '/api/WorkerCreds/MISCGetCreds',
    MISC_P1ZLK_POST: SERVICE_MISC + '/api/ZLK/ZLKAction',
    MISC_P1ZLK_GET: SERVICE_MISC + '/api/ZLK/ZLKGetResult/{id}',

    //****************************************************************************************
    //SERVICE APKOLCE
    //****************************************************************************************    
    APKOLCE_WORKERCREDS_CREATE: SERVICE_APKOLCE + '/api/APKolce/AddWorker',
    APKOLCE_WORKERCREDS_UPDATE: SERVICE_APKOLCE + '/api/APKolce/UpdateWorker',
    APKOLCE_WORKERCREDS_GET: SERVICE_APKOLCE + '/api/APKolce/GetWorkerById',
    APKOLCE_WORKERCREDS_ADD_CONFIGURATION: SERVICE_APKOLCE + '/api/APKolce/AddWorkerConfiguration',
    APKOLCE_WORKERCREDS_UPDATE_CONFIGURATION: SERVICE_APKOLCE + '/api/APKolce/UpdateWorkerConfiguration',
    APKOLCE_WORKERCREDS_GET_BY_WORKER: SERVICE_APKOLCE + '/api/APKolce/GetWorker/{workerId}',
    APKOLCE_WORKERCREDS_UPDATE_PASSWORD: SERVICE_APKOLCE + '/api/APKolce/UpdateWorkerPassword',
    APKOLCE_WORKERCREDS_UPDATE_CONF_PASSWORD: SERVICE_APKOLCE + '/api/APKolce/UpdateWorkerConfPassword',
    APKOLCE_ACTION_CHECK_PASSWORD: SERVICE_APKOLCE + '/api/APKolce/APKolceActionCheckPassword',
    APKOLCE_ACTION_GET_RESULT: SERVICE_APKOLCE + '/api/APKolce/APKolceActionResult',



}
