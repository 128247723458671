<template>
  <div>
    <ContentPanel v-if="selectClinicType != null" title="Badanie" icon="plus-box">
      <!-- :type="examinationImage != null ? 'is-success':'is-danger'" -->
      <b-field
        :label="forceSuggested ? 'Wybierz z sugerowanych badań dla wskazanej usługi':'Wybierz badanie'"
        label-position="on-border"
        v-if="examinationImagesAllowed.length > 0"
      >
        <b-autocomplete
          v-model="newExaminationName"
          placeholder="Zacznij pisać, aby wyświetlić sugerowane badanie (min. 2 znaki)"
          :open-on-focus="true"
          :data="filterExaminationImage"
          field="name"
          @select="addExaminationImage"
          :max-height="200"
          clearable
          expanded
          :disabled="readonly"
        >
          <template slot-scope="props">
            <table class="w-100">
              <tr>
                <td style="width:4rem;" class="has-text-weight-semibold pr-2">
                  <span v-if="props.option.codeICD9">{{ props.option.codeICD9 }}</span>
                </td>
                <td style="white-space: pre-wrap" class="has-text-weight-semibold">
                  <p>{{ props.option.name }}</p>
                  <p
                    v-if="props.option.umx && props.option.name != props.option.umx.category"
                    class="is-size-7 has-text-grey"
                  >{{props.option.umx.category}}</p>
                </td>
                <td style="width:3rem;" class="has-text-right">
                  <b-tooltip label="Refundacja" position="is-left">
                    <b-tag v-if="props.option.isRefundedPOZ" type="is-success" rounded>POZ</b-tag>
                    <b-tag v-if="props.option.isRefundedAOS" type="is-primary" rounded>AOS</b-tag>
                  </b-tooltip>
                </td>
              </tr>
            </table>
            <div v-if="props.option.umx && props.option.umx.adds.length > 0">
              <p
                v-for="add in props.option.umx.adds"
                :key="add.index"
                class="is-size-7"
                type="is-secondary"
              >+ {{getExam(add)}}</p>
            </div>
          </template>
        </b-autocomplete>
        <p class="control">
          <b-tooltip
            v-if="forceSuggested"
            type="is-secondary"
            multilined
            position="is-left"
            label="Badania są filtrowane dla wskazanej usługi. Kliknij aby wyświetlić wszystkie badania."
          >
            <b-button
              icon-left="filter-variant"
              type="is-secondary"
              @click="forceSuggested = false"
            ></b-button>
          </b-tooltip>
          <b-tooltip
            v-else-if="suggested.length < 1"
            type="is-secondary"
            multilined
            position="is-left"
            label="Brak sugerowanych badań. Wymagane wybranie manualne."
          >
            <b-button
              disabled
              type="is-secondary"
              icon-left="filter-variant-remove"
              @click="forceSuggested = false"
            ></b-button>
          </b-tooltip>
          <b-tooltip
            v-else
            type="is-dark"
            multilined
            position="is-left"
            label="Wyświetlana jest pełna lista badań. Kliknij aby wyświetlić sugerowane dla wskazanej usługi."
          >
            <b-button
              type="is-danger"
              icon-left="filter-variant-remove"
              @click="forceSuggested = true"
            ></b-button>
          </b-tooltip>
        </p>
      </b-field>
      <b-field
        v-if="examinationImage && examinationImage.examinationType == 2"
        class="card p-3 white-label"
        label-position="on-border"
        label="Projekcje"
      >
        <b-checkbox
          v-for="item in mProjectionTypes"
          :key="item.index"
          :native-value="item.id"
          size="is-small"
          class="mr-3"
          v-model="projections"
        >{{item.name}}</b-checkbox>
      </b-field>
      <b-field
        v-if="bodySides && bodySides.length > 0"
        :type="bodySide == null ? 'is-danger':''"
        label="Strona"
        label-position="on-border"
      >
        <b-select v-model="bodySide">
          <option v-for="(item, index) in bodySides" :key="index">{{ item }}</option>
        </b-select>
      </b-field>
      <div>
        <b-button
          class="is-orange"
          :outlined="examinationImage == null"
          :disabled="
          (examinationImagesAllowed.length > 0 &&
          examinationImage == null ) || readonly
        "
          @click="addProcedure"
          icon-left="arrow-down-bold"
        >
          Dodaj to badanie
          <span
            v-if="examinationImage && examinationImage.umx && examinationImage.umx.adds && examinationImage.umx.adds.length > 0"
          >+ {{examinationImage.umx.adds.length}} skojarzone</span>
        </b-button>
        <b-tooltip
          v-if="examinationImage && examinationImage.umx && examinationImage.umx.adds && examinationImage.umx.adds.length > 0"
          multilined
          label="Badanie dwóch lub trzech okolic anatomicznych powoduje dodanie dwóch lub trzech badań powiązanych ze sobą."
        >
          <b-icon size="is-medium" class="ml-2" icon="help-circle"></b-icon>
        </b-tooltip>
      </div>
    </ContentPanel>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
// import ContentPanel from "@/components/tools/ContentPanel";
import OrdersMixins from "@/mixins/orders/poz_orders";
import RisMixins from "@/mixins/ris";
import { Action } from "../../../store/config_actions";

export default {
  name: "RISWorkshopAddExamination",
  mixins: [CommonHelpers, OrdersMixins, RisMixins],
  components: {
  //  ContentPanel,
  },

  props: {
    selectClinicType: { type: Object, required: true },
    readonly: { type: Boolean, required: false, default: false },
    deviceType: { type: Number, required: false },
    referralType: { type: Object, required: true },
    payerType: { type: Number, required: true },
    examSuggestion: { type: Array, required: false },
    services: { type: Object, required: false }
  },

  data: function () {
    return {
      examinationImage: null,
      bodySide: null,
      newExaminationName: "",
      isSimp: false,
      //isNFZ: false,
      //isPrivate: false,
      isHighCost: false,
      bodySides: [],
      suggested: [],
      forceSuggested: false,
      projections: []
    };
  },

  mounted() {
    if (this.referralType && this.referralType.isSimpRequired) {
      this.isSimp = true;
    } else {
      this.isSimp = false;
    }

    if (this.typesImage.length < 1) {
      this.getImageTypes()
    }

    if (this.examSuggestion && this.examSuggestion.length > 0) {
      this.suggestExamination(this.examSuggestion)
    }
  },

  watch: {



    examSuggestion(val) {
      if (val) {
        this.suggestExamination(val)
      }
    },

    referralType() {
      if (this.referralType && this.referralType.isSimpRequired) {
        this.isSimp = true;
      } else {
        this.isSimp = false;
      }
    },

    isHighCostDisabled() {
      if (this.isHighCostDisabled == false) {
        this.isHighCost = false
      }
    }
  },

  computed: {

    umxServices() {
      return this.$store.state.finance.nfzServices ?? [];
    },

    typesImage() {
      return this.$store.state.results.examImageType ?? [];
    },

    filteredExaminationImageByClinic() {
      if (this.selectClinicType) {
        return this.typesImage.filter((option) => {
          option.toString();
          if (option.clinics) {
            let found = option.clinics.indexOf(
              this.selectClinicType.code.toLowerCase()
            );
            return found >= 0;
          }
          return false;
        });
      }
      return [];
    },

    filteredExaminationImageByDeviceType() {
      if (this.deviceType && this.deviceType > -1) {
        return this.typesImage.filter((option) => {
          option.toString();
          if (option.equipmentUsed) {
            let found = option.equipmentUsed.indexOf(
              this.deviceType
            );
            return found >= 0;
          }
          return false;
        });
      }
      return [];
    },

    examinationImagesAllowed() {
      if (this.deviceType && this.deviceType > -1) {
        return this.filteredExaminationImageByDeviceType;
      } else if (this.selectClinicType) {
        return this.filteredExaminationImageByClinic
      } else {
        return []
      }
    },

    filterExaminationImage() {

      //TODO MC: UMX konfig
      if (this.umxServices && this.services) {
        let umx = this.umxServices.find(x => x.code == this.services.bum)

        if (umx && umx.procedures && umx.procedures.length > 0) {





          let umxexams = []

          umx.procedures.forEach(p => {
            let cdsa = this.examinationImagesAllowed.filter(pp => pp.internalId == p.examId)
            cdsa.forEach(cds => {
              if (cds && !umxexams.includes(cds)) {
                cds.umx = p
                umxexams.push(cds)
              }
            })
          })

          let filt = umxexams.filter((option) => {
            return (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.newExaminationName.toLowerCase()) >= 0 ||
              option.codeICD9
                .toString()
                .toLowerCase()
                .indexOf(this.newExaminationName.toLowerCase()) >= 0
            );
          });

          return filt
        }
      }



      let allowed = this.examinationImagesAllowed.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.newExaminationName.toLowerCase()) >= 0 ||
          option.codeICD9
            .toString()
            .toLowerCase()
            .indexOf(this.newExaminationName.toLowerCase()) >= 0
        );
      });


      if (this.forceSuggested) {
        let suggestions = []

        allowed.forEach(element => {
          if (this.suggested.includes(element.internalId)) {
            suggestions.push(element)
          }
        });



        return suggestions
      }
      else {
        return allowed
      }


    },

    isNFZ() {
      if (this.payerType == 1) {
        return true;// this.referralType
      } else {
        return false;
      }
    },

    isPrivate() {
      if (this.payerType == 0) {
        return true;
      } else {
        return false;
      }
    },

    isSimpDisabled() {
      if (this.isNFZ && (this.referralType && this.referralType.isSimpAllowed && this.referralType.isSimpRequired == false)) {
        return false;
      } else {
        return true;
      }
    },

    isHighCostDisabled() {
      if (this.isNFZ && (this.referralType && this.referralType.isHighCostAllowed)) {
        return false;
      } else {
        return true;
      }
    }
  },

  methods: {
    getExam(add) {
      if (this.examinationImagesAllowed) {
        let exam = this.examinationImagesAllowed.find(r => r.internalId == add)

        if (exam) {
          return exam.codeICD9 + " - " + exam.name
        }
      }
    },
    // do something crazy
    suggestExamination(val) {
      this.suggested = val
      this.forceSuggested = this.suggested.length > 0

      if (this.suggested.length == 1) {
        var examId = this.suggested[0]

        if (examId == -1) {
          // Do nothing. This is a reset!
        } else {
          var exam = this.examinationImagesAllowed.find(x => x.internalId == examId)

          if (exam) {
            if (this.filterExaminationImage.length < 1) {
              this.noAvailable()
            }
            else {
              this.newExaminationName = exam.name
              this.addExaminationImage(exam)
              this.$buefy.snackbar.open(`Sugerowane badanie: ${exam.name}.`)

              // !!!!!!!!!!!!!
              if (this.examinationImage && this.examinationImage.examinationType != 2) {
                if (this.bodySides && this.bodySides.length > 0) {
                  // do nothing
                }
                else {
                  this.addProcedure()
                }
              }
            }
          }
          else {
            this.$buefy.dialog.alert({
              message: `Nie znaleziono badania sugerowanego przez usługę (${examId}). Wymagane ręczne dodanie badania. Proszę zgłosić problem do administratora.`,
              confirmText: "Ok",
              type: "is-info",
              scroll: 'keep',
              hasIcon: true
            })
          }
        }
      }
      else if (this.suggested.length > 1) {
        if (this.filterExaminationImage.length < 1) {
          this.noAvailable()
        }
        else {
          this.$buefy.snackbar.open(`Usługa wskazuje na więcej niż jedno badanie. Wymagany manualny wybór.`)
        }
      }
      else {
        this.$buefy.dialog.alert({
          message: "Wymagane ręczne dodanie badania.",
          confirmText: "Ok",
          type: "is-info",
          scroll: 'keep',
          hasIcon: true
        })
      }
    },

    noAvailable() {
      this.$buefy.dialog.alert({
        message: `Brak badań dostępnych dla tej usługi w wybranej pracowni.`,
        confirmText: "Ok",
        type: "is-warning",
        scroll: 'keep',
        hasIcon: true
      })
    },

    addProcedure() {
      let newProcedure = this.proceduresObject; //remove or change if real refferal have procedures

      if (this.examinationImage) {
        newProcedure.procedureId = this.examinationImage.internalId;
        newProcedure.icd9 = this.examinationImage.codeICD9;
        newProcedure.description = this.examinationImage.name;
        newProcedure.localization = this.examinationImage.bodyArea;
        newProcedure.localizationName = this.mGetBodyAreasName(this.examinationImage.bodyArea)
        newProcedure.localizationCode = this.mGetBodyAreasCode(this.examinationImage.bodyArea)
        newProcedure.localizationDescription = ""
        newProcedure.bodySide = this.bodySide;
        newProcedure.params = this.projections
        newProcedure.sendingToDeviceRequired = this.examinationImage.sendingToDeviceRequired;
        newProcedure.radiologistDescriptionRequired = this.examinationImage.radiologistDescriptionRequired;
        newProcedure.cdBurningRequired = this.examinationImage.cdBurningRequired;
      }

      if (this.isNFZ == true) {
        if (this.isHighCostDisabled == false) {
          newProcedure.isHighCost = this.isHighCost;
        }
        newProcedure.isSimp = this.isSimp;
      }
      newProcedure.isNfz = this.isNFZ;
      newProcedure.isPrivate = this.isPrivate;



      //this.referralProcedures.push(JSON.parse(JSON.stringify(newProcedure)));
      this.$emit("add-procedure", JSON.parse(JSON.stringify(newProcedure)));

      if (this.examinationImage && this.examinationImage.umx) {
        if (this.examinationImage.umx.adds && this.examinationImage.umx.adds.length > 0) {
          this.examinationImage.umx.adds.forEach(add => {
            let exam = this.examinationImagesAllowed.find(e => e.internalId == add)

            if (exam) {
              let abc = {
                procedureId: exam.internalId,
                icd9: exam.codeICD9,
                description: exam.name,
                localization: exam.bodyArea,
                localizationName: this.mGetBodyAreasName(exam.bodyArea),
                localizationCode: this.mGetBodyAreasCode(exam.bodyArea),
                localizationDescription: "",
                bodySide: this.bodySide,
                params: this.projections,
                sendingToDeviceRequired: exam.sendingToDeviceRequired,
                radiologistDescriptionRequired: exam.radiologistDescriptionRequired,
                cdBurningRequired: exam.cdBurningRequired,
              }

              if (this.isNFZ == true) {
                if (this.isHighCostDisabled == false) {
                  abc.isHighCost = this.isHighCost;
                }
                abc.isSimp = this.isSimp;
              }

              abc.isNfz = this.isNFZ;
              abc.isPrivate = this.isPrivate;

              let proc = JSON.parse(JSON.stringify(abc))
              this.$emit("add-procedure", proc);
            }
          })
        }
      }

      //this.isSimp = false;
      //this.isNFZ = false;
      //this.isPrivate = false;
      this.isHighCost = false;
      this.newExaminationName = "";
      this.examinationImage = null;
      this.bodySide = null;
      this.bodySides = [];
      this.projections = []
    },

    addExaminationImage(option) {
      //option.toString()
      this.examinationImage = option;
      this.bodySide = null;
      this.projections = [];

      if (option) {
        if (this.isHighCostDisabled == false && this.isNFZ == true) {
          this.isHighCost = option.isCostIntensive;
        }
        this.bodySides = option.options ?? [];
      }
    },

    getImageTypes() {
      this.$store
        .dispatch(Action.RESULTS_IMAGE_EXAMINATION_TYPE_GET_ALL)
        .then(() => {
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
