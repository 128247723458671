import axios from 'axios'

import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        divisionP1Dict: [],
        mfP1Dict: []
    },

    getters: {},

    mutations: {
        [Mutation.PRESCRIPTION_SET_DIVISION_P1_DICT](currentState, payload) {
            currentState.divisionP1Dict = payload
        },
        [Mutation.PRESCRIPTION_SET_MF_P1_DICT](currentState, payload) {
            currentState.mfP1Dict = payload
        }
    },

    actions: {

        /** Attempt to add paper prescription. Uses POST request and requires paper prescription data object (check config_apis.js) */
        [Action.PATIENT_PAPER_PRESCRIPTION_CREATE](context, customRefferal) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_PAPER_PRESCRIPTION_GET_ALL,
                        data: customRefferal,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt get all paper prescription. Uses GET request without parameter */
        [Action.PATIENT_PAPER_PRESCRIPTION_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PAPER_PRESCRIPTION_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get paper prescription. Uses GET request */
        [Action.PATIENT_PAPER_PRESCRIPTION_GET](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PAPER_PRESCRIPTION_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get paper prescription by patient id and domain. Uses GET request */
        [Action.PATIENT_PAPER_PRESCRIPTION_GET_BY_PATIENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PAPER_PRESCRIPTION_GET_BY_PATIENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get paper prescription by patient id, appointmentId and domain. Uses GET request */
        [Action.PATIENT_PAPER_PRESCRIPTION_GET_BY_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_PAPER_PRESCRIPTION_GET_BY_APPOINTMENT.replace('{domain}', payload.domain).replace('{patientId}', payload.patientId).replace('{appId}', payload.appId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt paper prescription delete. Uses DELETE request */
        [Action.PATIENT_PAPER_PRESCRIPTION_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.PATIENT_PAPER_PRESCRIPTION_GET.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt paper prescription edit. Uses PUT request with id and requires paper prescription data object (check config_apis.js)*/
        [Action.PATIENT_PAPER_PRESCRIPTION_UPDATE](context, customRefferal) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.PATIENT_PAPER_PRESCRIPTION_GET.replace('{id}', customRefferal.id),
                        data: customRefferal,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to set paper prescription prop 'isRemoved' to true. Uses POST request */
        [Action.PATIENT_CUSTOM_REFERRAL_REMOVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_CUSTOM_REFERRAL_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to set paper prescription prop 'isRemoved' to false. Uses POST request */
        [Action.PATIENT_CUSTOM_REFERRAL_RESTORE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_CUSTOM_REFERRAL_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get p1 dict by id. Uses GET request */
        [Action.P1_DICT_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.P1_DICT_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        }
    }
}