<template>
  <div class="pt-1">
    <div v-if="popX && popY && slots" class="card p-2" :style="popPosition(popX, popY)">
      <span v-if="markOne != null">{{ mTime(slots[markOne].time) }} - {{ addFive(slots[tmp].time) }}</span>
      <span v-else>{{ mTime(slots[tmp].time) }}</span>
    </div>
    <div style="overflow: visible; padding-left: 0.05rem">
      <b-loading :is-full-page="false" v-model="officeInProgress"></b-loading>
      <b-field grouped group-multiline class="mb-3" expanded>
        <b-field label-position="on-border" label="Typ terminarza">
          <b-select v-model="filters.searchType">
            <!--  <option :value="2">Poradnia</option>-->
            <option :value="4" v-if="showAllOptions">Gabinet</option>
            <option :value="6" v-if="showAllOptions">Urządzenie</option>
            <option :value="20">Pojazd</option>
            <option :value="0" v-if="showAllOptions">Pracownik (niezalecane)</option>
          </b-select>
        </b-field>
        <b-field
          label-position="on-border"
          expanded
          :label="subjectLabel"
          v-if="filters.searchType == 4"
        >
          <p class="control">
            <b-autocomplete
              :data="subjects"
              v-model="subjectName"
              clearable
              style="min-width: 24rem"
              open-on-focus
              field="name"
              :loading="officeInProgress"
              @select="(option) => targetChanged(option)"
              expanded
            >
              <template slot-scope="props">
                <p class="submedyciny szkoltitle is-6 mb-0">{{ props.option.originalName }}</p>
                <p
                  class="has-text-grey"
                >{{props.option.subUnitName}}, {{ props.option.facilityName }}</p>
              </template>
            </b-autocomplete>
          </p>
        </b-field>
        <b-field
          label-position="on-border"
          :label="subjectLabel"
          class="wide"
          v-else-if="filters.searchType === 6"
        >
          <p class="control">
            <b-autocomplete
              :data="subjects"
              v-model="subjectName"
              clearable
              style="min-width: 24rem"
              open-on-focus
              field="name"
              @select="(option) => targetChanged(option)"
              :key="testKey"
            >
              <template slot-scope="props">
                <div
                  :class="{
                  'has-text-success has-text-weight-bold':
                    filters.device == props.option.id,
                }"
                >
                  <p>{{ props.option.name }}</p>
                </div>
              </template>
            </b-autocomplete>
          </p>
        </b-field>
        <b-field label-position="on-border" :label="subjectLabel" class="wide" v-else>
          <b-autocomplete
            :data="subjects"
            v-model="subjectName"
            clearable
            expanded
            field="name"
            style="width: 20rem;"
            open-on-focus
            @select="(option) => targetChanged(option)"
          >
            <template slot-scope="props">
              <p
                class="subtitle is-6 mb-0"
                :class="{'has-text-grey':props.option.isOtherDomain}"
                style="line-height: 1borekrem;"
              >
                {{ props.option.lastName }}
                {{ props.option.firstName }}
              </p>
              <p
                v-if="props.option.isOtherDomain"
                class="has-text-danger is-size-7"
              >Uwaga! Inna domena/obszar</p>
            </template>
          </b-autocomplete>
        </b-field>
      </b-field>
      <div v-if="devicesInOffice.length > 0">
        <b-tag v-for="item in devicesInOffice" :key="item.id">{{ item.name }}</b-tag>
      </div>
    </div>
    <div class="columns is-variable is-0">
      <div class="column is-narrow">
        <b-field class="mb-0 mt-3">
          <b-switch v-model="calendarRangeMode" size="is-small">Pojedyncze dni/zakres dat</b-switch>
        </b-field>
        <b-field>
          <b-datepicker
            style="margin-left: -0.95rem"
            class="borderless-calendar"
            size="is-small"
            inline
            :multiple="!calendarRangeMode"
            v-model="dates"
            :first-day-of-week="1"
            :range="calendarRangeMode"
          >
            <b-tooltip label="Wyczyść zaznaczenie" type="is-light" position="is-right">
              <b-button
                outlined
                size="is-small"
                icon-left="eraser"
                type="is-danger"
                @click="clearDates"
              ></b-button>
            </b-tooltip>
            <b-tooltip label="Odznacz/zaznacz weekendy" type="is-orange" position="is-right">
              <b-button
                size="is-small"
                icon-left="calendar-weekend"
                outlined
                type="is-orange"
                class="ml-2"
                @click="showWeekends++"
              ></b-button>
            </b-tooltip>
            <b-field class="is-inline-block is-pulled-right">
              <b-tooltip
                v-if="selected === null || dates.length == 0"
                label="Wybierz gabinet i daty"
                position="is-right"
              >
                <b-button
                  icon-left="magnify"
                  :loading="officeInProgress"
                  disabled
                  type="is-primary"
                >Szukaj</b-button>
              </b-tooltip>
              <b-button
                v-else
                :loading="loading"
                type="is-primary"
                :disabled="selected === null || dates.length == 0"
                @click="downloadData"
                icon-left="magnify"
              >Szukaj</b-button>
            </b-field>
          </b-datepicker>
        </b-field>
      </div>
      <div class="column" style="overflow-x: hidden">
        <div
          class="table-container mt-1"
          v-if="scheduleData && scheduleData.days && scheduleData.days.length > 0"
        >
          <table @mouseleave="clearPop">
            <tr>
              <td v-for="item in scheduleData.days" :key="item.date" style="min-width: 13rem">
                <div class="pl-1">
                  <p class="mb-0 pb-1">
                    <b-icon icon="calendar" size="is-small"></b-icon>
                    {{ mDate(item.date) }}
                    <span>{{ mGetDayName(item.date) }}</span>
                  </p>
                  <div style="display: flex; justify-content: space-between">
                    <b-field>
                      <b-checkbox
                        v-model="item.checkboxSelected"
                        @input="fillSlots(scheduleData.days.length, scheduleData.days)"
                        size="is-small"
                      >Uzwględnij</b-checkbox>
                    </b-field>
                    <b-field v-if="isWorktime(item)">
                      <b-tooltip label="Przesuń strefy" position="is-left">
                        <b-button
                          @click="
                            selectedAreas = item;
                            isEditMultiModalActive = true;
                          "
                          size="is-small"
                          class="is-pulled-right mb-1"
                          icon-left="sort-calendar-ascending"
                        ></b-button>
                      </b-tooltip>
                    </b-field>
                  </div>
                </div>
              </td>
            </tr>
            <tr
              v-for="(item, index) in slots"
              :key="index"
              class="hovered"
              :class="{ selected: item.selected, marked: index === markOne }"
            >
              <td
                v-for="(day, index2) in scheduleData.days"
                :key="index2"
                :style="getCellStyle(index, index2)"
                @mousedown="mousedown(index)"
                @mouseenter="mouseover(index, true)"
                @mouseleave="mouseover(index, false)"
              ></td>
            </tr>
            <tr>
              <td
                v-for="item in scheduleData.days"
                :key="item.date"
                style="
                  border-right: 1px solid lightgrey;
                  border-top: 2px solid grey;
                "
              >
                <div class="is-size-7">
                  <table class="table w-100">
                    <tr v-for="work in item.worktimes" :key="work.id">
                      <td>
                        <span
                          class="has-text-weight-bold"
                        >{{ mTime(work.start) }}-{{ mTime(work.stop) }}</span>
                        <div class="is-pulled-right">
                          <a
                            class="has-text-primary pl-3 is-size-7"
                            @click="openEditModal(work)"
                          >Edytuj</a>
                          <a
                            class="has-text-danger pl-3 is-size-7"
                            @click="deleteWorktime(work)"
                          >Usuń</a>
                        </div>
                        <p>{{ getWorkAreaName(work.area) }}</p>
                        <div
                          v-if="work.employees.length > 0"
                          :class="{ 'has-text-success': work.employee > -1 }"
                        >
                          <p v-for="emp in work.employees" :key="emp.index">{{ getWorker(emp) }}</p>
                        </div>
                        <p
                          v-else-if="work.employee != -1"
                          :class="{ 'has-text-success': work.employee > -1 }"
                        >{{ getWorker(work.employee) }}</p>

                        <p>{{ getMedicalDevice(work.device) }}</p>
                        <p v-if="work.info">
                          <i>{{ work.info }}</i>
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      scroll="keep"
      @after-leave="clearSelected"
    >
      <div class="modal-card" style="overflow: visible" v-if="selected">
        <header class="modal-card-head has-background-kin">
          <p class="modal-card-title has-text-light">Nowa strefa w terminarzu</p>
          <button type="button" class="delete" @click="isModalActive = false" />
        </header>

        <section class="modal-card-body pt-2">
          <div>
            <table class="table w-100 is-narrow">
              <tr v-if="filters.searchType === 0">
                <th colspan="2">
                  <p class="subtitle">{{ selected.lastName }} {{ selected.firstName }}</p>
                </th>
              </tr>
              <tr v-if="filters.searchType > 0">
                <th colspan="2" class="pb-2 content">
                  <p
                    class="subtitle mb-1"
                    v-if="selected && selected.originalName"
                  >{{ selected.originalName }}</p>
                  <p class="subtitle mb-0" v-else-if="selected && selected.name">{{ selected.name }}</p>
                  <ul class="mt-0">
                    <li
                      class="has-text-weight-normal mt-0"
                      v-if="selected && selected.subUnitName"
                    >{{ selected.subUnitName }}</li>
                    <li
                      class="has-text-weight-normal mt-0"
                      v-if="selected && selected.facilityName"
                    >{{ selected.facilityName }}</li>
                  </ul>
                </th>
              </tr>
              <tr v-if="filters.searchType == 4">
                <td>
                  <b-icon icon="folder-open-outline" class="mr-2 mb-1" size="is-small"></b-icon>Typ
                </td>
                <td>
                  <p class="has-text-primary" v-if="selected">{{ getItem(selected.type) }}</p>
                </td>
              </tr>
              <tr>
                <td style="width: 5.5rem;">
                  <b-icon icon="clock-outline" class="mr-2 mb-1" size="is-small"></b-icon>Czas
                </td>
                <td>
                  <span v-if="!isWholeDay">
                    <b-tag>{{ mTime(modalStart) }} - {{ mTime(modalStop) }}</b-tag>
                  </span>
                  <span v-else>
                    <b-tag>06:00 - 20:00</b-tag>
                  </span>
                  <span v-if="specialArea" class="is-pulled-right">
                    <b-checkbox v-model="isWholeDay">cały dzień</b-checkbox>
                  </span>
                </td>
              </tr>
              <tr v-show="modalOccupied">
                <td class="has-text-danger">
                  UWAGA! Konflikt z inną strefą! Strefa już istniejąca zostanie
                  nadpisana.
                </td>
              </tr>
              <tr>
                <td>
                  <b-icon icon="calendar" class="mr-2 mb-1" size="is-small"></b-icon>Daty
                </td>
                <td>
                  <b-tag v-for="item in selectedDays" :key="item.index" class="mr-1 mb-1">{{ item }}</b-tag>
                </td>
              </tr>
            </table>

            <div v-if="filters.searchType === 0">
              <b-field style="text-align: right"></b-field>
              <b-field label="Typ strefy" label-position="on-border">
                <b-select
                  v-if="specialArea"
                  expanded
                  v-model="worktimeArea.area"
                  placeholder="Wybierz"
                >
                  <!-- <option v-for="item in docSpecs" :key="item" :value="item">
                    {{ getWorkAreaName(item) }} ({{ item }})
                  </option>-->
                  <option v-if="specialArea && filters.searchType == 0" :value="99">Urlop</option>
                  <option v-if="specialArea && filters.searchType == 0" :value="97">Delegacja</option>
                </b-select>
                <b-select v-else expanded v-model="worktimeArea.area">
                  <option
                    v-for="item in docSpecs"
                    :key="item"
                    :value="item"
                  >{{ getWorkAreaName(item) }} ({{ item }})</option>
                </b-select>
                <b-switch
                  v-model="specialArea"
                  type="is-primary"
                  size="is-small"
                  class="ml-2"
                >Strefy specjalne</b-switch>
              </b-field>
              <b-field
                v-if="
                  specialArea &&
                  (worktimeArea.area == 99 || worktimeArea.area == 97)
                "
                label-position="on-border"
                label="Zastępca"
                class="wide"
              >
                <b-autocomplete
                  :data="employeesFiltered"
                  v-model="additionalNameDeputy"
                  clearable
                  field="name"
                  open-on-focus
                  placeholder="Wybierz zastępcę"
                ></b-autocomplete>
              </b-field>
              <b-field v-if="!specialArea" label="Poradnia" label-position="on-border">
                <b-select expanded v-model="worktimeArea.clinic">
                  <option
                    v-for="item in selected.clinics"
                    :key="item"
                    :value="item"
                  >{{ mGetClinic(item) }}</option>
                </b-select>
              </b-field>
              <b-field v-if="!specialArea" label="Specjalizacja" label-position="on-border">
                <b-select expanded v-model="worktimeArea.spec">
                  <option :value="-1">Brak</option>
                  <option
                    v-for="item in selected.specializations"
                    :key="item"
                    :value="item"
                  >{{ mGetSpecialization(item) }}</option>
                </b-select>
              </b-field>
            </div>
            <!-- OFFICE -->
            <div v-if="filters.searchType === 4">
              <b-field grouped>
                <b-field expanded label="Typ strefy" label-position="on-border">
                  <b-select expanded v-model="worktimeArea.area">
                    <option
                      v-for="item in docSpecs"
                      :key="item"
                      :value="item"
                    >{{ getWorkAreaName(item) }}</option>
                    <option
                      v-if="specialArea && filters.searchType != 0"
                      :value="98"
                    >Strefa serwisowa</option>
                  </b-select>
                </b-field>
                <b-field>
                  <b-switch
                    v-model="specialArea"
                    type="is-primary"
                    size="is-small"
                    class="ml-2 mt-1"
                    style="line-height: .9rem;"
                  >
                    Strefy
                    <br />specjalne
                  </b-switch>
                </b-field>
              </b-field>

              <b-field label-position="on-border" label="Pracownik" class="wide">
                <b-autocomplete
                  :data="employeesFiltered"
                  v-model="additionalName"
                  clearable
                  field="name"
                  open-on-focus
                  @select="(option) => workerToOffice(option)"
                  :style="[ selected && selected.type === 200001 ? { border: '1px solid #f14668' } : {}, ]"
                >
                  <template slot-scope="props">
                    <p
                      class="title is-7 mb-0"
                      :class="{'has-text-grey':props.option.isOtherDomain}"
                      style="line-height: .75rem;"
                    >
                      {{ props.option.lastName }}
                      {{ props.option.firstName }}
                    </p>
                    <p
                      v-if="props.option.isOtherDomain"
                      class="has-text-danger is-size-7"
                    >Uwaga! Inna domena/obszar</p>
                  </template>
                </b-autocomplete>
              </b-field>

              <b-field
                v-if="filters.searchType === 4"
                label-position="on-border"
                :label="
                  showAllRooms
                    ? `Pomieszczenie (${rooms.length})`
                    : `Pomieszczenie w placówce (${rooms.length})`
                "
                class="wide mb-0"
                expanded
              >
                <b-autocomplete
                  expanded
                  :data="rooms"
                  v-model="roomName"
                  clearable
                  field="name"
                  type="is-primary"
                  open-on-focus
                  @select="(option) => roomChanged(option)"
                ></b-autocomplete>
              </b-field>
              <b-field v-if="filters.searchType === 4">
                <b-switch v-model="showAllRooms" size="is-small">Pomieszczenie w innej placówce</b-switch>
              </b-field>
              <b-field
                v-if="filters.searchType === 4"
                label-position="inside"
                label="Urządzenie"
                class="wide"
                :type="{ 'is-danger': configClinicSelected &&configClinicSelected.isMedicalDeviceRequired && includedDevices.length == 0 }"
                expanded
              >
                <b-taginput
                  v-model="includedDevices"
                  :data="subjectsDevicesOffice"
                  autocomplete
                  :allow-new="false"
                  open-on-focus
                  type="is-primary"
                  field="name"
                  :placeholder="devicePlaceholder"
                >
                  <template v-slot="props">
                    <p style="word-wrap: break-word; white-space: pre-wrap">
                      <span>{{ props.option.name }}</span>
                    </p>
                  </template>
                </b-taginput>
              </b-field>
            </div>
            <div v-if="filters.searchType === 6">
              <b-field expanded label="Typ strefy" label-position="inside">
                <b-select expanded v-model="worktimeArea.area">
                  <option :value="-1">{{ selected.name }}</option>
                  <option v-if="specialArea && filters.searchType != 0" :value="98">Strefa serwisowa</option>
                </b-select>
                <b-switch
                  v-model="specialArea"
                  type="is-primary"
                  size="is-small"
                  class="ml-2"
                >Strefy specjalne</b-switch>
              </b-field>
            </div>
            <div v-if="filters.searchType === 20">
              <b-field expanded label="Typ strefy" label-position="inside">
                <b-select expanded v-model="worktimeArea.area">
                  <option
                    v-for="item in vehicleAreas"
                    :key="item.id"
                    :value="item.id"
                  >{{ item.name }}</option>
                </b-select>
              </b-field>
              <b-field label-position="on-border" label="Pracownik" class="wide">
                <b-autocomplete
                  :data="employeesFiltered"
                  v-model="additionalName"
                  clearable
                  field="name"
                  open-on-focus
                  @select="(option) => workerToOffice(option)"
                ></b-autocomplete>
              </b-field>
            </div>
          </div>
          <b-field class="mt-3 mb-0">
            <b-switch
              size="is-small"
              v-model="isVisibleForPatients"
            >Strefa widoczna na portalu pacjenta</b-switch>
          </b-field>
          <b-field>
            <b-switch size="is-small" v-model="cyclicalWorktime">Strefa cykliczna</b-switch>
          </b-field>
          <b-field>
            <b-switch size="is-small" v-model="worktimeArea.isCito">Wizyty CITO</b-switch>
          </b-field>
          <b-field>
            <b-switch size="is-small" disabled v-model="worktimeArea.isEr">Strefa E-Rejestracji</b-switch>
          </b-field>
          <div v-if="cyclicalWorktime">
            <b-field>
              <b-radio v-model="cyclicalType" :native-value="0">Podanie liczby stref</b-radio>
              <b-radio v-model="cyclicalType" :native-value="1" class="ml-3">Podanie daty końcowej</b-radio>
            </b-field>
            <b-field grouped>
              <b-field
                v-if="cyclicalWorktime && cyclicalType == 0"
                label="Liczba stref"
                label-position="on-border"
                style="width: 35%"
              >
                <b-numberinput v-model="numberOfWortimes" min="1" controls-position="compact"></b-numberinput>
              </b-field>

              <b-field
                v-if="cyclicalWorktime && cyclicalType == 1"
                label="Data końcowa"
                label-position="on-border"
              >
                <b-datepicker v-model="worktimePeriodEndDate" :max-date="endDateMax"></b-datepicker>
              </b-field>
              <b-field
                v-if="cyclicalWorktime"
                label="Okres"
                label-position="on-border"
                style="width: 35%"
              >
                <b-numberinput v-model="worktimePeriodNumber" controls-position="compact" min="1"></b-numberinput>
              </b-field>
              <b-field expanded>
                <b-select v-model="worktimePeriodUnit" expanded>
                  <option :value="0">dzień</option>
                  <option :value="1">tydzień</option>
                  <option :value="2">miesiąc</option>
                </b-select>
              </b-field>
            </b-field>
            <b-field v-if="cyclicalWorktime" class="mt-3">
              <b-checkbox v-model="excludeWeekends" type="is-orange">Pomiń weekendy</b-checkbox>
            </b-field>
          </div>
          <b-field label="Dodatkowy opis strefy" label-position="on-border" class="mt-5">
            <b-input type="textarea" maxlength="400" v-model="worktimeArea.info"></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <div class="w-100">
            <b-button
              type="is-danger"
              :loading="loading"
              v-if="modalOccupied"
              :disabled="
                (configClinicSelected &&
                  configClinicSelected.isMedicalDeviceRequired &&
                  (!includedDevices || includedDevices.length == 0)) ||
                (modalAmount === 0 && modalBackupAmount === 0) ||
                (filters.searchType === 4 && selected && selected.type === 200001 && worktimeArea.employee === -1)
              "
              @click="sendWorktimes(true)"
              icon-left="calendar-import"
            >Dodaj do terminarza (Nadpisz)</b-button>
            <!-- <b-button
              type="is-success"
              :loading="loading"
              v-if="modalOccupied"
              @click="sendWorktimes(false)"
              icon-left="calendar-weekend"
              :disabled="modalAmount === 0 && modalBackupAmount === 0"
            >Dodaj do terminarza (Wypełnij)</b-button>-->
            <b-button
              type="is-primary"
              v-else
              :loading="loading"
              @click="sendWorktimes(true)"
              icon-left="calendar-alert"
              :disabled="
                (configClinicSelected &&
                  configClinicSelected.isMedicalDeviceRequired &&
                  (!includedDevices || includedDevices.length == 0)) ||
                (modalAmount === 0 && modalBackupAmount === 0) ||
                (filters.searchType === 4 && selected && selected.type === 200001 && worktimeArea.employee === -1)
              "
            >Dodaj do terminarza</b-button>
            <!-- <b-button
              type="is-danger"
              class="is-pulled-right"
              :loading="loading"
              @click="deleteWorktimes"
              size="is-small"
            >Usuń sloty w podanym zakresie</b-button>-->
          </div>
        </footer>
      </div>
    </b-modal>
    <!------------------------------------------------------------------------------------->
    <b-modal
      :active.sync="isEditModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <div
        class="modal-card"
        style="overflow: visible; width: auto"
        v-if="selected && selectedWorktimeArea"
      >
        <header class="modal-card-head has-background-kin">
          <p class="modal-card-title has-text-primary">Edytuj strefę</p>
          <button type="button" class="delete" @click="isEditModalActive = false" />
        </header>
        <section class="modal-card-body" style="overflow: visible">
          <div>
            <div>
              <p v-if="modalOccupied" class="has-text-danger">
                UWAGA! Konflikt z inną strefą! Strefa już istniejąca zostanie
                nadpisana.
              </p>
              <!-- <p>
                <b-icon
                  icon="clock-outline"
                  class="mr-1"
                  size="is-small"
                ></b-icon
                >Czas
              </p>-->
              <!-- <b-tag
                v-for="item in filters.dates"
                :key="item.index"
                size="is-medium"
                class="mr-1 mb-1"
                >{{ item }}</b-tag
              >-->
              <!-- <b-tag
                v-for="item in selectedDays"
                :key="item.index"
                size="is-medium"
                class="mr-1 mb-1"
                >{{ item }}</b-tag
              >-->
              <b-field label="Data od... do" label-position="inside">
                <b-datetimepicker v-model="selectedDates[0]"></b-datetimepicker>
                <b-datetimepicker v-model="selectedDates[1]"></b-datetimepicker>
              </b-field>
            </div>
            <div v-if="filters.searchType === 0">
              <p class="subtitle mt-4">{{ selected.lastName }} {{ selected.firstName }}</p>
              <b-field style="text-align: right"></b-field>
              <b-field label="Typ strefy" label-position="on-border">
                <b-select
                  v-if="specialArea"
                  expanded
                  v-model="selectedWorktimeArea.area"
                  placeholder="Wybierz"
                >
                  <!-- <option v-for="item in docSpecs" :key="item" :value="item">
                    {{ getWorkAreaName(item) }} ({{ item }})
                  </option>-->
                  <option v-if="specialArea && filters.searchType == 0" :value="99">Urlop</option>
                  <option v-if="specialArea && filters.searchType == 0" :value="97">Delegacja</option>
                </b-select>
                <b-select v-else expanded v-model="selectedWorktimeArea.area">
                  <option
                    v-for="item in docSpecs"
                    :key="item"
                    :value="item"
                  >{{ getWorkAreaName(item) }} ({{ item }})</option>
                </b-select>
                <b-switch
                  v-model="specialArea"
                  type="is-primary"
                  size="is-small"
                  class="ml-2"
                >Strefy specjalne</b-switch>
              </b-field>
              <b-field v-if="!specialArea" label="Poradnia" label-position="on-border">
                <b-select expanded v-model="selectedWorktimeArea.clinic">
                  <option
                    v-for="item in selected.clinics"
                    :key="item"
                    :value="item"
                  >{{ mGetClinic(item) }}</option>
                </b-select>
              </b-field>
              <b-field v-if="!specialArea" label="Specjalizacja" label-position="on-border">
                <b-select expanded v-model="selectedWorktimeArea.spec">
                  <option :value="-1">Brak</option>
                  <option
                    v-for="item in selected.specializations"
                    :key="item"
                    :value="item"
                  >{{ mGetSpecialization(item) }}</option>
                </b-select>
              </b-field>
            </div>
            <!-- OFFICE -->
            <div v-if="filters.searchType === 4">
              <p class="subtitle mb-0" v-if="selected">{{ selected.name }}</p>
              <p class="subtitle has-text-primary" v-if="selected">{{ getItem(selected.type) }}</p>
              <b-field expanded label="Typ strefy" label-position="inside" class="pb-3">
                <b-select expanded v-model="selectedWorktimeArea.area">
                  <option
                    v-for="item in docSpecs"
                    :key="item"
                    :value="item"
                  >{{ getWorkAreaName(item) }}</option>
                  <option v-if="specialArea && filters.searchType != 0" :value="98">Strefa serwisowa</option>
                </b-select>
                <b-switch
                  v-model="specialArea"
                  type="is-primary"
                  size="is-small"
                  class="ml-2"
                >Strefy specjalne</b-switch>
              </b-field>

              <b-field label-position="on-border" label="Pracownik" class="wide">
                <b-autocomplete
                  :data="employeesFiltered"
                  v-model="additionalName"
                  clearable
                  field="name"
                  open-on-focus
                  @select="(option) => workerToOffice(option)"
                ></b-autocomplete>
              </b-field>

              <b-field
                v-if="filters.searchType === 4"
                label-position="on-border"
                :label="
                  showAllRooms
                    ? `Pomieszczenie (${rooms.length})`
                    : `Pomieszczenie w placówce (${rooms.length})`
                "
                class="wide mb-1"
                expanded
              >
                <b-autocomplete
                  expanded
                  :data="rooms"
                  v-model="roomName"
                  clearable
                  field="name"
                  type="is-primary"
                  open-on-focus
                  @select="(option) => roomChanged(option)"
                ></b-autocomplete>
              </b-field>
              <b-field v-if="filters.searchType === 4" class="mb-4">
                <b-switch v-model="showAllRooms" size="is-small">Inne placówki</b-switch>
              </b-field>
              <b-field
                v-if="filters.searchType === 4"
                label-position="on-border"
                label="Urządzenia"
                class="wide"
                expanded
              >
                <b-taginput
                  v-model="includedDevices"
                  :data="subjectsDevicesOffice"
                  autocomplete
                  :allow-new="false"
                  open-on-focus
                  type="is-primary"
                  field="name"
                  :placeholder="devicePlaceholder"
                  clos
                  :style="[
                    configClinicSelected &&
                    configClinicSelected.isMedicalDeviceRequired &&
                    includedDevices.length == 0
                      ? { border: '1px solid #f14668' }
                      : {},
                  ]"
                >
                  <template v-slot="props">
                    <p style="word-wrap: break-word; white-space: pre-wrap">
                      <span>{{ props.option.name }}</span>
                    </p>
                  </template>
                </b-taginput>
              </b-field>
            </div>
            <div v-if="filters.searchType === 6">
              <p class="subtitle mt-4 has-text-weight-medium" v-if="selected">{{ selected.name }}</p>
              <b-field expanded label="Typ strefy" label-position="inside">
                <b-select expanded v-model="worktimeArea.area">
                  <option :value="-1">{{ selected.name }}</option>
                  <option v-if="specialArea && filters.searchType != 0" :value="98">Strefa serwisowa</option>
                </b-select>
                <b-switch
                  v-model="specialArea"
                  type="is-primary"
                  size="is-small"
                  class="ml-2"
                >Strefy specjalne</b-switch>
              </b-field>
            </div>
            <div v-if="filters.searchType === 20" class="mb-3">
              <p class="subtitle" v-if="selected">{{ selected.name }}</p>
              <b-field expanded label="Typ strefy" label-position="inside">
                <b-select expanded v-model="worktimeArea.area">
                  <option
                    v-for="item in vehicleAreas"
                    :key="item.id"
                    :value="item.id"
                  >{{ item.name }}</option>
                </b-select>
              </b-field>
            </div>
            <div class="pt-3">
              <b-field label="Dodatkowy opis strefy" class="mt-3" label-position="inside">
                <b-input type="textarea" maxlength="400" v-model="selectedWorktimeArea.info"></b-input>
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot has-background-kin buttons is-right">
          <b-button @click="modifyWorktime(selectedWorktimeArea)" type="is-primary">Zapisz</b-button>
          <b-button @click="isEditModalActive = false">Anuluj</b-button>
        </footer>
      </div>
    </b-modal>
    <b-modal
      :active.sync="isEditMultiModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="overflow: visible; width: auto">
        <!-- <header class="modal-card-head has-background-kin">
          <b-icon icon="pencil" type="is-primary" class="mr-2"></b-icon>
          <p class="modal-card-title has-text-primary">Edycja stref</p>
          <button
            type="button"
            class="delete"
            @click="isEditMultiModalActive = false"
          />
        </header>-->
        <section class="modal-card-body" style="overflow: visible">
          <b-field label="Przesuń strefy o ... minut" label-position="on-border">
            <b-numberinput
              v-model="minutes"
              controls-position="compact"
              style="width: 15rem"
              expanded
            ></b-numberinput>
            <b-button
              @click="moveDayWorktimes"
              :disabled="minutes == 0"
              class="ml-2 mr-2"
              type="is-primary"
            >Zapisz</b-button>
            <b-button @click="isEditMultiModalActive = false">Anuluj</b-button>
          </b-field>
        </section>
        <!-- <footer class="modal-card-foot has-background-kin buttons is-right">
          <b-button @click="isEditMultiModalActive = false"> Anuluj </b-button>
          <b-button @click="todo" disabled type="is-primary"> Zapisz </b-button>
        </footer>-->
      </div>
    </b-modal>
    <!-- <table>
      <tr>
        <td>
          <pre>{{worktimeArea}}</pre>
        </td>
        <td>
          <pre>{{filters}}</pre>
        </td>
      </tr>
    </table>-->
    <!-- <pre>cyclicalWorktime: {{ cyclicalWorktime }}</pre>
    <pre>isVisibleForPatients: {{ isVisibleForPatients }}</pre>-->

    <b-modal
      v-model="isCyclicalWorktimesActive"
      has-modal-card
      aria-role="dialog"
      aria-modal
      class="p-5"
      trap-focus
      :destroy-on-hide="false"
    >
      <div class="modal-card">
        <header class="modal-card-head has-background-kin p-3">
          <p class="modal-card-title has-text-primelight pl-1 pb-1">
            <b-icon icon="alert-circle" class="mr-3"></b-icon>Wykryto konflikty
            przy zapisie stref cyklicznych
          </p>
          <button type="button" class="delete" @click="isCyclicalWorktimesActive = false" />
        </header>
        <section
          v-if="cyclicalWorktimeSaveResponse"
          class="modal-card-body pt-2 pr-4 pl-4 pb-2"
          style="overflow-y: auto"
        >
          <p class="mt-3 mb-4">Wykryto konflikty dla stref:</p>
          <ul class="ml-4">
            <li
              v-for="(
                conflict, conflict_index
              ) in cyclicalWorktimeSaveResponse.conflicts"
              :key="conflict_index"
            >
              {{ conflict_index + 1 }}. {{ getFormattedDate(conflict.start) }} -
              {{ getFormattedDate(conflict.stop) }}
            </li>
          </ul>
          <hr />
        </section>
        <footer class="modal-card-foot has-background-kin buttons is-right">
          <b-button
            @click="saveCyclicalWorktimesWithConflicts(2)"
            icon-left="calendar-plus"
            type="is-primary"
          >Dodaj z pominięciem konfliktów</b-button>
          <b-button
            @click="saveCyclicalWorktimesWithConflicts(1)"
            icon-left="calendar-plus"
            type="is-danger"
          >Nadpisz strefy</b-button>
          <b-button @click="isEditMultiModalActive = false">Anuluj</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import CommonMixins from '@/mixins/commons'
import ClinicsMixins from '@/mixins/clinics'
import PersonnelMixins from '@/mixins/personnel'
import InvMixins from '@/mixins/inventory'
import AppoMixins from '@/mixins/appointments'
import MobiMixins from '@/mixins/mobi'
import DateHelpers from '@/mixins/date_helpers'
import { Action } from '../../../store/config_actions'
//import { Action } from '../../../store/config_actions'
// import WorkFill from './WorktimeFiller'

export default {
  name: 'WorktimeScheduler',
  mixins: [CommonMixins, DateHelpers, AppoMixins, ClinicsMixins, PersonnelMixins, InvMixins, MobiMixins],
  components: {},

  data: function () {
    return {
      slots: [],
      dates: [],
      selected: null,
      dragging: false,
      markOne: null,
      markTwo: null,
      modalStart: null,
      modalStop: null,
      modalAmount: 0,
      modalBackupAmount: 0,
      isModalActive: false,
      modalStamp: null,
      employee: null,
      loading: false,
      subjectName: '',
      scheduleData: { days: [] },
      tmp: null,
      styles: [],
      clearTrigger: 0,
      popX: null,
      popY: null,
      areaType: 0,
      modalOccupied: false,
      offices: [],
      medicalDevices: [],
      vehicles: [],
      devicesInOffice: [],
      reserveDevices: false,
      allRooms: [],
      roomName: '',
      showAllRooms: false,
      subunits: [],
      includedDevices: [],
      officeInProgress: false,
      additionalName: '',

      filters: {
        "searchType": 4,
        "dates": [
        ],
        "employee": -1,
        "device": -1,
        "clinic": -1,
        "spec": -1,
        "area": -1,
        "office": -1,
        "room": -1,
        "vehicle": -1
      },

      worktimeArea: {
        "start": null,
        "stop": null,
        "subjectId": -1,
        "employee": -1,
        "employees": [],
        "subject": 0,
        "spec": -1,
        "area": -1,
        "info": '',
        "clinic": -1,
        "office": -1,
        "officeId": null,
        "officeRoomId": null,
        "isEr": false,
        "vehicleId": null,
        "created": this.mNow(),
        "createdBy": -1,
        "modified": this.mNow(),
        "modifiedBy": -1
      },

      selectedDays: [],

      specialArea: false,
      calendarRangeMode: false,
      isEditModalActive: false,
      isEditMultiModalActive: false,
      selectedWorktimeArea: null,
      selectedAreas: null,
      selectedDates: [],
      devicePlaceholder: "",
      additionalNameDeputy: "",
      isWholeDay: false,
      showWeekends: 0,
      minutes: 0,
      areaDescription: '',



      //



      cyclicalWorktime: false,
      isEregistration: false,
      cyclicalType: 0,
      numberOfWortimes: 1,
      worktimePeriodNumber: 1,
      worktimePeriodUnit: 0,
      worktimePeriodEndDate: new Date(),
      endDateMax: new Date(moment().add(5, 'year')),

      isVisibleForPatients: false,

      sendedWorktimes: null,

      isCyclicalWorktimesActive: false,
      cyclicalWorktimeSaveResponse: null,

      excludeWeekends: false,
      showAllOptions: true,

      testKey: 0
    }
  },

  props: {
    mobi: { type: Boolean, required: false }
  },

  mounted() {
    if (this.mobi) {
      this.showAllOptions = false
      this.filters.searchType = 20
    }

    this.getData()
    this.downloadMedicalDevices()
    this.downloaVehicles()
    this.fillSlots(0)
    this.getOffroomz()
  },

  watch: {
    'filters.searchType': function () {
      this.filters.subjectId = -1
      this.filters.employee = -1
      this.filters.employees = []
      this.filters.clinicId = -1
      this.filters.clinic = -1
      this.filters.spec = -1
      this.filters.area = -1
      this.filters.office = null
      this.filters.room = null
      this.filters.device = -1
      this.selected = null
      this.subjectName = ''
      this.roomName = ''

      this.testKey++

      this.worktimeArea = {
        "start": null,
        "stop": null,
        "subjectId": -1,
        "employee": -1,
        "employees": [],
        "subject": 0,
        "spec": -1,
        "area": -1,
        "clinic": -1,
        "office": -1,
        "officeId": null,
        "officeRoomId": null,
        "isEr": false,
        "created": this.mNow(),
        "createdBy": -1,
        "modified": this.mNow(),
        "modifiedBy": -1
      }
    },

    isModalActive() {
      this.worktimeArea.info = null
      this.includedDevices = [];
      if(this.selected && this.isModalActive == true && this.isEditModalActive == false && this.filters.searchType ==4){
        if(this.subjectsDevicesOffice != null){
            let found = this.subjectsDevicesOffice.find(d => d.officeId == this.selected.id)
            
            if (found != null && !this.includedDevices.some(incD => incD.id == found.id)) {
              this.includedDevices.push(found)
            }
            
        }
      }
    },

    selected(val) {
      this.scheduleData = { days: [] }
      this.worktimeArea.employee = -1
      this.worktimeArea.info = null
      this.additionalName = ''

      if (val) {
        switch (this.filters.searchType) {
          case 0:
            this.worktimeArea.employee = this.selected.id
            this.worktimeArea.employees = [this.selected.id]
            break
          case 2:
            break
          case 4:
            this.worktimeArea.officeId = this.selected.id
            this.worktimeArea.officeRoomId = null
            break
          case 6:
            this.worktimeArea.device = this.selected.id
            break
          default:
            break
        }

        if (this.dates.length > 0) {
          this.downloadData()
        }
        if (this.configClinicSelected && this.configClinicSelected.isMedicalDeviceRequired) {
          this.devicePlaceholder = "Wyszukaj (pole wymagane)"
        } else {
          this.devicePlaceholder = "Wyszukaj"
        }
      }
    },

    'dates.length': function () {
      this.updateDates()
    },

    dates() {
      if (this.calendarRangeMode) {
        this.updateDates()
      }
    },

    calendarRangeMode() {
      this.clearDates()
    },

    docSpecs(val) {
      if (val && val.length > 0) {
        this.worktimeArea.area = val[0]
      }
    },

    specialArea() {
      if (this.specialArea) {
        if (this.filters.searchType == 0) {
          this.worktimeArea.area = 99
        } else {
          this.worktimeArea.area = 98
        }
      }
    },

    showWeekends() {
      this.scheduleData.days.forEach(element => {
        let weekDay = new Date(element.date).getDay()
        if (weekDay == 0 || weekDay == 6) {
          element.checkboxSelected = !element.checkboxSelected
          this.fillSlots(this.scheduleData.days.length, this.scheduleData.days)
        }
      })
    }
  },

  computed: {
    docSpecs() {
      let areas = []

      if (this.filters.searchType === 0 && this.selected) {
        this.selected.roles.forEach(p => {
          let pos = this.mGetRoleObject(p)
          if (pos) {
            pos.areas.forEach(a => {
              if (!areas.includes(a)) {
                areas.push(a)
              }
            })
          }
        })
      }
      else if (this.filters.searchType === 4 && this.selected) {
        let officeAreas = this.getOfficeAreas(this.selected)

        officeAreas.forEach(element => {
          areas.push(element)
        })
      }
      else if (this.filters.searchType === 6 && this.selected) {
        areas.push(this.selected.id)
      }

      return areas
    },

    facilities() { return this.$store.state.clinic.clinics },


    configOffices() {
      return this.$store.state.config.offices
    },
    configAreas() {
      return this.$store.state.config.areas
    },

    configClinics() {
      return this.$store.state.config.clinics ?? [];
    },

    configClinicSelected() {
      if (this.selected && this.selected.clinicId) {
        let configClinic = this.configClinics.find(obj => obj.id == this.selected.clinicId)

        if (configClinic) {
          return configClinic
        }
      }

      return null
    },

    vehicleAreas() {
      return this.configAreas.filter(option => option.isVehicle === true)
    },

    me() { return this.$store.state.employee.me },

    meParentFacilityObject() {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.internalId === this.me.parentFacility);
        return found;
      } else return null;
    },

    registrationIkz() {
      return this.$store.state.registration.ikz
    },

    clinicId() {
      return this.$store.state.clinic.activeClinic ? this.$store.state.clinic.activeClinic.clinicId : -1
    },

    employees() {
      if (this.$store.state.employee.all !== null) {
        return this.$store.state.employee.all;
      } else return [];
    },

    subjects() {
      let array = []

      switch (this.filters.searchType) {
        case 0: array = this.employees
          break
        case 2: array = this.mClinics
          break
        case 4: array = this.offices
          break
        case 6: array = this.subjectsDevices
          break
        case 20: array = this.vehicles
          break
        default: array = []
          break
      }

      if (array === null) array = []

      return array.filter((option) => {

        if (option.name) {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.subjectName.toLowerCase()) >= 0
        }
        else return []
      })
    },

    subjectsDevices() {
      let medDevices = this.medicalDevices

      medDevices.forEach((medDevice) => {
        medDevice.name = `${medDevice.name} (${medDevice.shortName}), ${this.getFacility(medDevice.facility)}`
        medDevice.facilityName = this.getFacility(medDevice.facility)
      })

      return medDevices
    },

    subjectsDevicesOffice() {
      if (this.selected) {
        return this.subjectsDevices.filter((medDevice) => {
          return medDevice.officeTypes !== null && medDevice.officeTypes.includes(this.selected.type)
        })
      }

      return this.subjectsDevices
    },

    employeesFiltered() {
      let array = this.employees
      return array.filter((option) => {
        if (option.name) {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.additionalName.toLowerCase()) >= 0
        }
        else return []
      })
    },

    rooms() {
      if (this.selected) {
        let array = this.allRooms

        if (this.selected && this.selected.facilityId) {

          if (!this.showAllRooms) {
            array = this.allRooms.filter(r => r.facility && r.facility.id === this.selected.facilityId)
          }

          return array.filter((option) => {

            if (option.name) {
              return option.name
                .toString()
                .toLowerCase()
                .indexOf(this.roomName.toLowerCase()) >= 0
            }
            else return []
          })
        }
        else return array
      }
      else return []
    },

    subjectLabel() {
      switch (this.filters.searchType) {
        case 0: return 'Pracownik'
        case 2: return 'Poradnia'
        case 4: return 'Gabinet'
        case 6: return 'Urządzenie'
        case 20: return 'Pojazd'
        default: return '???'
      }
    },

    popup() {
      return 'podgląd'
    },


  },

  methods: {

    getEmployee(id) {
      if (this.employees) {
        let employee = this.employees.find((e) => e.id === id);
        if (employee) {
          let nameEmployee = employee.name
          return nameEmployee;
        }
      }
      return "";
    },

    getFacility(id) {
      if (this.facilities) {
        var found = this.facilities.find(x => x.id === id)
        return found != null ? found.name : `Brak (${id})`
      }
      else return `Brak subjednostek (${id})`
    },

    getFacilityFromRoom(room) {
      if (this.facilities) {
        var found = this.facilities.find(x => x.internalId === room.facilityId)
        return found != null ? found : null
      }
      else return null
    },

    getFacilityCode(id) {
      if (this.facilities) {
        var found = this.facilities.find(x => x.id === id)
        return found != null ? found.internalId : -1
      }
      else return -1
    },

    getSubunit(id) {
      if (this.subunits) {
        var found = this.subunits.find(x => x.id === id)
        return found != null ? found.name : `Brak (${id})`
      }
      else return `Brak subjednostek (${id})`
    },

    getData() {
      this.officeInProgress = true
      this.$store
        .dispatch(Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_ALL)
        .then((data) => {
          this.subunits = data
          this.officeInProgress = false
          this.downloadOffices()
          this.getOffroomz()
        })
        .catch(error => {
          this.officeInProgress = false
          this.apiProblem(error);
        });
    },

    downloadOffices() {
      this.officeInProgress = true
      this.$store
        .dispatch(Action.OFFICE_GET_ALL)
        .then((data) => {

          let filtered = []
          data.forEach(element => {
            element.facilityName = this.getFacility(element.facilityId)
            element.subUnitName = this.getSubunit(element.subUnitId)
            element.originalName = element.name
            element.name = `${element.name}, ${element.facilityName}, ${element.subUnitName}`
            if (element.hasScheduleDisabled == false) {
              filtered.push(element)
            }
          });

          this.offices = filtered

          this.officeInProgress = false
        })
        .catch(error => {
          this.apiProblem(error);
          this.officeInProgress = false
        });
    },

    getOffroomz() {
      this.$store
        .dispatch(Action.INVENTORY_GET_ALL_ROOMS)
        .then((response) => {

          response.forEach(element => {
            element.facility = this.getFacilityFromRoom(element)
            element.name = `Nr ${element.number ?? '?'}, ${this.mGetName(this.mRoomTypes, element.type)} ${element.name ?? '-'}, ${element.description}`
          });

          this.allRooms = response
        })
        .catch(error => {
          this.apiProblem(error);
        });
    },

    downloadMedicalDevices() {
      this.requestInProgress = true
      this.$store
        .dispatch(Action.MEDICAL_DEVICE_GET_ALL)
        .then((data) => {
          this.medicalDevices = data
          this.requestInProgress = false
        })
        .catch(error => {
          this.apiProblem(error);
          this.requestInProgress = false
        });
    },

    downloaVehicles() {
      this.requestInProgress = true
      this.$store
        .dispatch(Action.MOBI2_GET_VEHICLES)
        .then((data) => {
          data.forEach(x => {
            x.name = `${x.brand} ${x.model} - ${x.registrationNumber}`
          })
          this.vehicles = data
          this.requestInProgress = false
        })
        .catch(error => {
          this.apiProblem(error);
          this.requestInProgress = false
        });
    },

    getItem(id) {
      let category = this.configOffices.find(c => c.id == id)
      if (category) return category.name
      else return `Brak informacji`
    },

    getOfficeAreas(item) {
      let category = this.configOffices.find(c => c.id == item.type)
      if (category && category.areas) return category.areas
      else return []
    },

    workerToOffice(worker) {
      if (worker) {
        this.worktimeArea.employee = worker.id
      }
      else {
        this.worktimeArea.employee = -1
      }
    },

    targetChanged(item) {
      this.devicesInOffice = []
      this.includedDevices = []
      this.worktimeArea.officeRoomId = null
      this.worktimeArea.info = null
      this.worktimeArea.vehicleId = null
      this.worktimeArea.vehicle = -1
      this.filters.vehicle = -1
      this.worktimeArea.device = -1
      this.filters.device = -1

      if (item) {
        this.selected = item

        if (item.areas && item.areas.length > 0) {
          this.worktimeArea.area = item.areas[0]
        }
        else if (this.filters.searchType === 20) {
          this.worktimeArea.vehicle = item.id
          this.filters.vehicle = item.id
        }
        else if (this.filters.searchType === 4) {
          this.worktimeArea.officeId = item.id
          this.filters.office = item.id

          if (this.clinicId > -1) {
            this.devicesInOffice = this.medicalDevices.filter(d => d.location == this.clinicId && d.office === item.id)
          }

          if (this.selected.employee) {
            this.worktimeArea.employee = this.selected.employee
            this.worktimeArea.employees = [this.selected.employee]
            this.additionalName = this.getEmployee(this.selected.employee)
          }
        }
        else if (this.filters.searchType === 6) {
          this.worktimeArea.area = -1
          this.worktimeArea.device = item.id
          this.filters.device = item.id
        }
      }
      else {
        this.subjectName = ''
        this.roomName = ''
        this.worktimeArea.officeId = null
        this.worktimeArea.officeRoomId = null
        this.filters.office = null
        this.filters.room = null
      }

      this.modalAmount = 1
      this.modalBackupAmount = 0
    },

    roomChanged(item) {
      this.includedDevices = []

      if (item) {
        this.filters.room = item.id
        this.worktimeArea.officeRoomId = item.id
      }
      else {
        this.filters.room = null
        this.worktimeArea.officeRoomId = null
      }
    },

    popPosition(popX, popY) {
      return `position: fixed;top: ${popY - 40}px;left: ${popX + 5}px;  z-index: 10;`
    },

    clearDates() {

      while (this.dates.length > 0) {
        this.dates.pop()
      }

      this.clearTrigger++
      this.scheduleData = { days: [] }
    },

    updateDates() {
      this.filters.dates = []

      if (this.calendarRangeMode && this.dates.length == 2) {
        while (!this.filters.dates.includes(this.mDate(this.dates[1]))) {
          if (this.filters.dates.length == 0) {
            this.filters.dates.push(this.mDate(this.dates[0]))
          } else {
            let newDate = moment(this.filters.dates[this.filters.dates.length - 1]).add(1, 'days')
            newDate = new Date(newDate)
            this.filters.dates.push(this.mDate(newDate))
          }
        }
      } else {
        this.dates.forEach(d => {
          this.filters.dates.push(this.mDate(d))
        })
      }
    },

    isIncluded(area, slot) {
      let aStart = moment(area.start)
      let aEnd = moment(area.stop)
      let slotMom = moment(slot)

      let included = slotMom.isSameOrAfter(aStart) && slotMom.isBefore(aEnd)


      return included
    },

    downloadData() {

      if (this.selected) {
        switch (this.filters.searchType) {
          case 0:
            this.filters.subjectId = this.selected.id
            this.filters.employee = this.selected.id
            break
          case 2:
            this.filters.clinicId = this.selected.id
            this.filters.clinic = this.selected.id
            break
          case 4: this.filters.office = this.selected.id
            break
          case 6: this.filters.device = this.selected.id
            break
          default:
            break
        }

        this.loading = true

        let cops = JSON.parse(JSON.stringify(this.filters))

        // if (this.filters.searchType == 4 && this.filters.room === null) {
        //   cops.searchType = 8
        // }
        if (this.filters.searchType == 4) {
          cops.searchType = 8
        }

        this.$store.dispatch(Action.S4_GET_SCHEDULE, cops)
          .then((response) => {
            this.scheduleData = { days: [] }
            this.scheduleData = response.data
            this.loading = false
            let dCount = response.data.days ? response.data.days.length : 0

            if (dCount > 0) {
              response.data.days.forEach(element => {
                let weekDay = new Date(element.date).getDay()
                if (weekDay != 0 && weekDay != 6) {
                  element.checkboxSelected = true
                } else {
                  element.checkboxSelected = false
                }
              })
            }

            this.fillSlots(dCount, response.data.days)
          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
            this.$emit('error')
            this.scheduleData = { days: [] }
          })
      }
    },

    countEmptySlots(worktimes, backups) {
      if (backups) {
        let empty = worktimes.filter(w => w.employee === -1 && w.area === 1)
        return empty.length
      }
      else {
        let empty = worktimes.filter(w => w.employee === -1 && w.area === 0)
        return empty.length
      }
    },

    deleteWorktime(item) {
      this.$buefy.dialog.confirm({
        message: `Czy na pewno chcesz usunąć wybraną strefę terminarza (${this.getDate(item.start)} ${this.getTime(item.start)}-${this.getTime(item.stop)})?`,
        type: 'is-danger',
        confirmText: 'Usuń',
        cancelText: 'Anuluj',
        onConfirm: () => {
          this.loading = true
          this.$store.dispatch(Action.S2_DELETE_WORKTIME, item.id)
            .then(() => {
              this.successSnackbar('Usunięto strefę')
              this.loading = false
              this.downloadData()

              //ADD IKZ - USUNIĘCIE STREFY
              this.addIkzDeleteWorktime()
            })
            .catch((error) => {
              this.loading = false
              this.apiProblem(error)
            })
        }
      })
    },

    deleteWorktimes() {
      let queue = []

      this.filters.dates.forEach(date => {

        let start = date + 'T' + this.modalStart.format('HH:mm:00')
        let stop = date + 'T' + this.modalStop.format('HH:mm:00')

        for (let index = 0; index < 1; index++) {
          queue.push({
            "id": 0,
            "start": start,
            "stop": stop,
            "subjectId": -1,
            "subject": 1,
            "version": "0",
            "employee": -1,
            "device": "",
            "office": -1,
            "officeId": this.worktimeArea.officeId,
            "officeRoomId": this.worktimeArea.officeRoomId,
            "spec": -1,
            "area": 0,
            "clinic": -1,
            "created": this.mNow(),
            "createdBy": this.me.id,
            "modified": this.mNow(),
            "modifiedBy": this.me.id
          })
        }
      })

      this.deleteSend(queue)
    },

    sendWorktimes(force) {
      let amount = this.modalAmount
      // let amountBack = this.modalBackupAmount

      let employees = []
      let facility = -1

      if (this.worktimeArea.employee > -1) {
        employees = [this.worktimeArea.employee]
      }

      if (this.selected.facilityId) {
        facility = this.getFacilityCode(this.selected.facilityId)
      }

      let device = null
      let devices = []

      if (this.includedDevices.length > 0) {
        this.includedDevices.forEach(d => devices.push(d.id))
        device = devices[0]
      } else if (this.filters.searchType == 6) {
        device = this.worktimeArea.device
      }

      let clinic = this.worktimeArea.clinic

      if (this.selected.clinicId != undefined) {
        clinic = this.selected.clinicId
      }

      let queue = []

      // this.filters.dates.forEach(date => {
      this.selectedDays.forEach(date => {

        let start = ""
        let stop = ""

        if (this.isWholeDay && (this.worktimeArea.area == 99 || this.worktimeArea.area == 98)) {
          start = date + 'T06:00:00'
          stop = date + 'T20:00:00'
        } else {
          start = date + 'T' + this.modalStart.format('HH:mm:00')
          stop = date + 'T' + this.modalStop.format('HH:mm:00')
        }

        for (let index = 0; index < amount; index++) {
          queue.push({
            "id": 0,
            "start": moment(start).toDate(),
            "stop": moment(stop).toDate(),
            "subjectId": this.worktimeArea.employee,
            "info": this.worktimeArea.info,
            "subject": 0,
            "version": "0",
            "employee": this.worktimeArea.employee,
            "employees": employees,
            "facility": facility,
            //"device": this.worktimeArea.device,
            "isVisibleForPatients": this.isVisibleForPatients,
            "device": device,
            "devices": devices,
            "officeId": this.worktimeArea.officeId,
            "officeRoomId": this.worktimeArea.officeRoomId,
            "vehicle": this.worktimeArea.vehicle,
            "office": -1,
            "spec": this.worktimeArea.spec,
            "area": this.worktimeArea.area,
            "clinic": clinic,
            "created": this.mNow(),
            "createdBy": this.me.id,
            "modified": this.mNow(),
            "modifiedBy": this.me.id
          })

        }


        // for (let index = 0; index < amountBack; index++) {
        //   queue.push({
        //     "id": 0,
        //     "start": start,
        //     "stop": stop,
        //     "subjectId": this.worktimeArea.employee,
        //     "subject": 1,
        //     "version": "0",
        //     "employee": employee,
        //     "employees": employees,
        //     "device": -1,
        //     "office": this.worktimeArea.office,
        //     "spec": this.worktimeArea.spec,
        //     "area": this.worktimeArea.area,
        //     "clinic": this.worktimeArea.clinic,
        //     "created": this.mNow(),
        //     "createdBy": this.me.id,
        //     "modified": this.mNow(),
        //     "modifiedBy": this.me.id
        //   })
        // }
      })

      if (this.cyclicalWorktime) {

        let arr = JSON.parse(JSON.stringify(queue))

        let unit = 'days'

        if (this.worktimePeriodUnit == 0) {
          unit = 'days'
        } else if (this.worktimePeriodUnit == 1) {
          unit = 'weeks'
        } else if (this.worktimePeriodUnit == 2) {
          unit = 'months'
        }

        if (queue.length > 1) {
          this.dangerSnackbar('Zaznaczono więcej niż jedną strefę')
        }

        if (this.cyclicalType == 0 && queue.length == 1) {
          for (let i = 0; i < this.numberOfWortimes; i++) {
            // add worktime
            queue.forEach(element => {
              let newObj = JSON.parse(JSON.stringify(element))
              newObj.start = moment(arr[arr.length - 1].start).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')
              newObj.stop = moment(arr[arr.length - 1].stop).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')

              let daysToAdd = 0

              if (this.excludeWeekends) {
                let dayOfWeek = moment(newObj.start).day()
                if (dayOfWeek == 6) {
                  daysToAdd = 2
                } else if (dayOfWeek == 7) {
                  daysToAdd = 1
                }

                newObj.start = moment(newObj.start).add(parseFloat(daysToAdd), 'days').format('YYYY-MM-DDTHH:mm:ss.SSS')
                newObj.stop = moment(newObj.stop).add(parseFloat(daysToAdd), 'days').format('YYYY-MM-DDTHH:mm:ss.SSS')
              }

              newObj.start = moment(newObj.start).toDate()
              newObj.stop = moment(newObj.stop).toDate()

              arr.push(newObj)
            })
          }
        } else if (this.cyclicalType == 1 && queue.length == 1) {

          //

          let nextWorktimeStart = moment(arr[arr.length - 1].start).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')
          let worktimeEnd = moment(this.worktimePeriodEndDate).format('YYYY-MM-DDTHH:mm:ss.SSS')

          while (nextWorktimeStart < worktimeEnd) {

            queue.forEach(element => {
              let newObj = JSON.parse(JSON.stringify(element))
              newObj.start = moment(arr[arr.length - 1].start).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')
              newObj.stop = moment(arr[arr.length - 1].stop).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')

              let daysToAdd = 0

              if (this.excludeWeekends) {
                let dayOfWeek = moment(newObj.start).day()
                if (dayOfWeek == 6) {
                  daysToAdd = 2
                } else if (dayOfWeek == 7) {
                  daysToAdd = 1
                }

                newObj.start = moment(newObj.start).add(parseFloat(daysToAdd), 'days').format('YYYY-MM-DDTHH:mm:ss.SSS')
                newObj.stop = moment(newObj.stop).add(parseFloat(daysToAdd), 'days').format('YYYY-MM-DDTHH:mm:ss.SSS')
              }

              newObj.start = moment(newObj.start).toDate()
              newObj.stop = moment(newObj.stop).toDate()

              arr.push(newObj)

              nextWorktimeStart = moment(arr[arr.length - 1].start).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')
            })
          }
        }

        let obj = {
          "forceMode": 0,
          "worktimes": arr
        }

        this.sendedWorktimes = JSON.parse(JSON.stringify(arr))

        this.$store.dispatch(Action.S3_WORKTIMES_CYCLICAL, obj)
          .then((response) => {
            if (response && response.data) {
              if (response.data.success) {
                this.successSnackbar('Pomyślnie dodano')

                //ADD IKZ - UTWORZENIE STREFY
                this.addIkzAddWorktime()
              } else {
                this.cyclicalWorktimeSaveResponse = response.data
                this.openCyclicalWorktimeModal()
              }
            }
            this.loading = false
            this.downloadData()
            this.isModalActive = false
            this.isVisibleForPatients = false
            this.cyclicalWorktime = false
            this.isWholeDay = false
            this.worktimePeriodEndDate = new Date()
            this.worktimePeriodNumber = 1
            this.numberOfWortimes = 1
            this.worktimePeriodUnit = 0
          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
          })


      } else {
        this.multiSend(queue, force)
        this.specialArea = false
      }


    },

    multiSend(arr, force) {
      this.loading = true
      if (force) {
        // cool cool cool
        this.$store.dispatch(Action.S3_OVERWRITE_WORKTIMES, arr)
          .then(() => {
            this.successSnackbar('Pomyślnie dodano')
            this.loading = false
            this.downloadData()
            this.isModalActive = false
            this.isWholeDay = false

            //ADD IKZ - UTWORZENIE STREFY
            this.addIkzAddWorktime()

          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
          })
      }
      else {
        this.$store.dispatch(Action.S3_FILL_WORKTIMES, arr)
          .then(() => {
            this.successSnackbar('Pomyślnie dodano')
            this.loading = false
            this.downloadData()
            this.isModalActive = false
            this.isWholeDay = false

            //ADD IKZ - UTWORZENIE STREFY
            this.addIkzAddWorktime()

          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
          })
      }
    },

    deleteSend(arr) {
      this.loading = true
      this.$store.dispatch(Action.S3_DELETE_WORKTIMES, arr)
        .then(() => {
          this.successSnackbar('Usunięto strefy')
          this.loading = false
          this.downloadData()
          this.isModalActive = false
        })
        .catch((error) => {
          this.loading = false
          this.apiProblem(error)
        })
    },

    chainSend(arr) {
      if (arr.length > 0) {
        this.loading = true
        this.$store.dispatch(Action.S2_SEND_WORKTIME, arr[0])
          .then(() => {
            arr.splice(0, 1)
            this.successSnackbar('Zapisano na serwerze' + arr.length)
            this.loading = false
            this.chainSend(arr)
          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
          })
      }
      else {
        this.isModalActive = false
      }
    },

    clearSelected() {
      this.slots.forEach(s => s.selected = false)
    },

    addFive(time) {
      return moment(time).add(5, 'minutes').format('HH:mm')
    },

    mousedown(item) {

      if (this.dragging) {
        this.markTwo = item
      }
      else {
        this.markOne = item
      }

      if (this.markOne != null && this.markTwo != null) {

        let occu = false

        for (let index = this.markOne; index <= this.markTwo; index++) {
          this.slots[index].selected = true

          if (this.slots[index].occupied) {
            occu = true
          }
        }

        this.modalOccupied = occu

        // this.isModalActive = true
        this.checkSelectedDays()

        this.modalStart = moment(this.slots[this.markOne].time)
        this.modalStop = moment(this.slots[this.markTwo].time).add(5, 'minutes')

        this.markOne = null
        this.markTwo = null
      }

      this.dragging = !this.dragging
    },

    getCellStyle(slotIndex, dayIndex) {
      return this.styles[slotIndex][dayIndex]
      // let ref = 'slot' + (index * 100 + index2)

      // if (this.$refs[ref]) {
      // }

      // let id = index * 100 + index2
      // let sty = this.styles.find(s => s.id == id)

    },

    mouseover(item, hover) {
      // if (this.slots[item].hovered != hover) {
      //   this.slots[item].hovered = hover
      // }

      if (this.markOne != null) {
        let tmpMark = item

        for (let index = this.markOne; index <= tmpMark; index++) {
          this.slots[index].selected = !this.slots[index].selected
        }
      }

      if (hover) {
        this.popX = event.clientX
        this.popY = event.clientY
      }

      this.tmp = item
    },

    clearPop() {
      this.popX = null
      this.popY = null
    },

    getWorker(id) {
      if (this.employees) {
        let emp = this.employees.find(e => e.id === id)

        if (emp) {
          return emp.lastName + ' ' + emp.firstName
        }
        else return 'brak informacji'
      }
    },


    getWorkAreaName(id) {
      let category = this.configAreas.find(c => c.id == id)
      if (category) return category.name
      else return `??? [${id}]`
    },

    fillSlots(days, dayData) {
      this.slots = []
      this.styles = []

      let date = moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
      let end = moment().set({ hour: 19, minute: 59, second: 0, millisecond: 0 })

      while (date.isBefore(end)) {
        let slot = {
          time: date,
          selected: false,
          hovered: false,
          timeString: 'T' + moment(date).format('HH:mm:00')
        }

        let slotsStyles = []

        for (let index = 0; index < days; index++) {

          if (dayData) {
            const item = dayData[index]



            let base = 'min-height: 4px; height: 4px;border-top: 1px solid lightgrey;border-left: 1px solid lightgrey;'
            let width = 20 / days

            base = base + `width:${width}rem;`

            let slotTime = moment(item.date).format('YYYY-MM-DD') + slot.timeString
            let included = item.worktimes.find(s => this.isIncluded(s, slotTime))

            if (included && item.checkboxSelected) {




              let colot = 'background-color: rgba(150, 99, 71, 0.2);'

              switch (included.area) {
                case 0:
                case 4:
                case 100000:
                case 100100:
                case 100200:
                case 100300:
                case 100400:
                case 100500:
                case 100600:
                case 100700:
                  colot = 'background-color: rgba(255, 99, 71, 0.3);'
                  break
                case 1:
                case 5:
                case 100402:
                case 100502:
                  colot = 'background-color: rgba(99, 255, 71, 0.3);'
                  break
                case 2:
                case 6:
                case 100403:
                case 100503:
                  colot = 'background-color: rgba(71, 99, 255, 0.2);'
                  break
                case 3:
                case 7:
                case 100404:
                  colot = 'background-color: rgba(255, 150, 199, 0.3);'
                  break
                case 99: colot = 'background-color: rgba(255, 237, 71, 0.5);'
                  break
              }
              slot.occupied = true
              base = base + colot
            } else if (included && !item.checkboxSelected) {
              let colot = 'background-color: rgba(191, 191, 191, 1);'
              slot.occupied = true
              base = base + colot
            } else if (!item.checkboxSelected) {
              let colot = 'background-color: rgba(191, 191, 191, 1);'
              base = base + colot
            }


            if (included) {
              let begining = moment(included.start)
              let slotBegining = moment(slotTime)

              if (slotBegining.isSame(begining)) {
                base += 'border-top: 1px solid black !important;'
              }
            }

            slotsStyles.push(base)
          } else {
            slotsStyles.push(null)
          }
        }

        this.slots.push(slot)
        this.styles.push(slotsStyles)

        date = moment(date).add(5, 'minutes')
      }
    },

    checkSelectedDays() {
      this.selectedDays = []
      this.scheduleData.days.forEach(day => {
        if (day.checkboxSelected) {
          this.selectedDays.push(moment(day.date).format('YYYY-MM-DD'))
        }
      })
      this.$nextTick(() => {
        this.isModalActive = true
      })
    },

    openEditModal(item) {
      this.selectedWorktimeArea = item

      if (item.area == 98) {
        this.specialArea = true
      }

      this.additionalName = this.getEmployee(item.employee)

      this.selectedDates = []

      this.selectedDates.push(new Date(item.start))
      this.selectedDates.push(new Date(item.stop))

      this.includedDevices = [];

      if (item.devices && item.devices.length > 0) {
        if (this.subjectsDevicesOffice != null && this.subjectsDevicesOffice.length > 0) {
          item.devices.forEach(dev => {
            let found = this.subjectsDevicesOffice.find(d => d.id == dev)
            if (found != null && !this.includedDevices.some(incD => incD.id == found.id)) {
              this.includedDevices.push(found)
            }
          });
        } else {
          this.dangerSnackbar('Brak załadowanych urządzeń')
        }
      }

      //
      this.isEditModalActive = true
    },

    saveEditedWortimekArea() {
      //
    },

    getDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },

    getTime(date) {
      return moment(date).format('HH:mm')
    },

    modifyWorktime(worktime) {
      this.loading = true

      worktime.start = moment(this.selectedDates[0]).format('YYYY-MM-DDTHH:mm:ss')
      worktime.stop = moment(this.selectedDates[1]).format('YYYY-MM-DDTHH:mm:ss')

      this.$store.dispatch(Action.S3_MODIFY_WORKTIME, worktime)
        .then(() => {
          this.successSnackbar('Zapisano zmiany')
          this.loading = false
          this.isEditModalActive = false
          this.downloadData()

          //ADD IKZ - EDYCJA STREFY
          this.addIkzEditWorktime()
        })
        .catch((error) => {
          this.loading = false
          this.apiProblem(error)
        })
    },

    isWorktime(day) {
      let worktime = day.blocks.find(bl => bl.isWorktime)

      if (worktime) {
        return true
      } else return false
    },

    moveDayWorktimes() {

      let worktimes = this.selectedAreas.worktimes

      worktimes.forEach(element => {
        element.start = moment(new Date(element.start)).add(this.minutes, 'minutes').format('YYYY-MM-DDTHH:mm:00')
        element.stop = moment(new Date(element.stop)).add(this.minutes, 'minutes').format('YYYY-MM-DDTHH:mm:00')
      })

      this.chainSend2(worktimes)
    },

    chainSend2(arr) {
      if (arr.length > 0) {
        this.loading = true
        this.$store.dispatch(Action.S3_MODIFY_WORKTIME, arr[0])
          .then(() => {
            arr.splice(0, 1)
            this.successSnackbar('Zapisano na serwerze' + arr.length)
            this.loading = false
            this.chainSend2(arr)
          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
          })
      }
      else {
        this.downloadData()
        this.isEditMultiModalActive = false

      }
    },

    openCyclicalWorktimeModal() {
      this.isCyclicalWorktimesActive = true
    },

    getFormattedDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    },

    saveCyclicalWorktimesWithConflicts(method) {
      let obj = {
        "forceMode": method,
        "worktimes": this.sendedWorktimes
      }

      this.$store.dispatch(Action.S3_WORKTIMES_CYCLICAL, obj)
        .then((response) => {
          if (response && response.data) {
            if (response.data.success) {
              this.successSnackbar('Pomyślnie dodano')
              this.isCyclicalWorktimesActive = false
              this.sendedWorktimes = null
              this.cyclicalWorktimeSaveResponse = null

              //ADD IKZ - UTWORZENIE STREFY
              this.addIkzAddWorktime()

            } else {
              this.cyclicalWorktimeSaveResponse = response.data
              this.openCyclicalWorktimeModal()
            }
          }
          this.loading = false
          this.downloadData()
          this.isModalActive = false
          this.isVisibleForPatients = false
          this.cyclicalWorktime = false
        })
        .catch((error) => {
          this.loading = false
          this.apiProblem(error)
        })
    },

    getMedicalDevice(id) {
      if (id) {
        let medDevice = this.subjectsDevices.find(option => option.id == id)

        if (medDevice) {
          return medDevice.name
        } else return `??? [${id}]`
      } else return 'Brak danych'
    },

    addIkzAddWorktime() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 589)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": 0,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzEditWorktime() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 590)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": 0,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzDeleteWorktime() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 591)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": 0,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hovered:hover {
  background: skyblue;
}
.selected {
  background: green;
}
.marked {
  background: green;
}
.topped {
  border-top: 2px solid red;
}
.vam {
  vertical-align: middle;
}
</style>
