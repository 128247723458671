import { render, staticRenderFns } from "./ScheduleModalAddReferral.vue?vue&type=template&id=edd1469e&scoped=true&"
import script from "./ScheduleModalAddReferral.vue?vue&type=script&lang=js&"
export * from "./ScheduleModalAddReferral.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edd1469e",
  null
  
)

export default component.exports