<template>
  <div
    style="overflow: visible;"
    :class="{ card: !borderless, 'rounded':rounded, 'mb-content': notMarginless }"
  >
    <b-collapse
      style="margin-top: 0px;"
      v-if="colapse"
      class="is-paddingless"
      animation="none"
      aria-id="panelSliderContent"
      :open.sync="colapseOpen"
    >
      <div
        slot="trigger"
        slot-scope="props"
        class="headerTrigger is-marginless header"
        aria-controls="panelSliderContent"
      >
        <div class="columns is-mobile is-variable is-0 m-0" :class="{ botborder: borderless }">
          <div v-if="icon" class="column icon-pad is-narrow">
            <b-icon v-if="success" :icon="icon" :pack="pack" type="is-success"></b-icon>
            <b-icon v-else :icon="icon" :pack="pack" type="is-primary"></b-icon>
          </div>
          <div class="column std-pad2" :class="{'lesser-pad': subtitle}">
            <p
              class="header-text has-text-primary"
              :class="{'has-text-grey-primary': inactive,'has-text-success': success}"
            >{{ title }}</p>
            <p v-if="subtitle" class="header-subtitle">{{subtitle}}</p>
          </div>
          <div class="column is-narrow addon">
            <b-button size="is-small" icon-left="menu-up" v-if="props.open">Ukryj</b-button>
            <b-button v-else icon-left="menu-down" size="is-small">Pokaż</b-button>
          </div>
        </div>
      </div>
      <div
        class="panel-content-borderless"
        :class="{ 'no-pad-basta': paddingless, 'topborder': !borderless }"
      >
        <slot></slot>
      </div>
    </b-collapse>

    <div v-else>
      <div class="header">
        <div class="columns is-mobile is-variable is-0 m-0">
          <div v-if="icon" class="column icon-pad is-narrow">
            <b-icon v-if="success" :icon="icon" :pack="pack" type="is-success"></b-icon>
            <b-icon v-else :icon="icon" :pack="pack" type="is-primary"></b-icon>
          </div>
          <div class="column std-pad" :class="{'lesser-pad': subtitle}">
            <p
              class="header-text has-text-primary"
              :class="{'has-text-grey-primary': inactive, 'has-text-success': success}"
            >{{ title }}</p>
            <p v-if="subtitle" class="header-subtitle">{{subtitle}}</p>
          </div>
          <div v-if="hasAddonSlot" class="column is-narrow addon experiment">
            <slot name="addon"></slot>
          </div>
        </div>
      </div>
      <div class="panel-content" :class="{ 'no-pad-basta': paddingless }" >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentPanel',
  props: {
    icon: {
      type: String,
      required: false
    },
    pack: {
      type: String,
      required: false,
      default: 'mdi'
    },
    subtitle: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    colapse: {
      type: Boolean,
      required: false
    },
    paddingless: {
      type: Boolean,
      required: false
    },
    marginless: {
      type: Boolean,
      required: false
    },
    inactive: {
      type: Boolean,
      required: false
    },
    success: {
      type: Boolean,
      required: false
    },
    shadowTransform: {
      type: Boolean,
      required: false
    },
    borderless: {
      type: Boolean,
      required: false
    },
    openTrigger: {
      type: Number,
      required: false,
      default: 0
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      colapseOpen: false,
    }
  },

  watch: {
    openTrigger() {
      this.colapseOpen = true
    }
  },

  computed: {
    hasAddonSlot() {
      return !!this.$slots.addon
    },
    notMarginless() {
      if (this.marginless) {
        return false
      }
      else {
        return true
      }
    }
  }
}


</script>

<style scoped lang="scss">
.header {
  border: 0px solid #dddddd;
  min-height: 3.1rem !important;
  max-height: 3.1rem !important;
  height: 3.1rem !important;
  padding: 0.22rem 0.3rem;
  border-bottom: 0px solid #c4c4c4;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.headerTrigger {
  border: 0px solid #dddddd;
  min-height: 2rem;
  border-bottom: 0px solid #dddddd;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.header-text {
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  padding-right: 1rem;
}

.panel-content {
  border-top: 1px solid #dbdbdb;
  padding: 1.25rem;
}

.panel-content-borderless {
  padding: 0.5rem 1rem 1rem 1rem;
}

.mb-content {
  margin-bottom: 1rem !important;
}

.header-icon {
  padding: 0 0.3rem 0 0.1rem !important;
  border-bottom-right-radius: 10px;
  margin: 0.2rem 0.4rem 0.1rem 0.3rem;
}

.light {
  background-color: rgba(255, 255, 255, 0.829);
}

.addon {
  padding: 0.35rem 0.35rem 0 0.7rem !important;
}

.addon-menu {
  margin: -1px;
  padding: 4px 6px 1px 4px !important;
  height: calc(2rem + 1px);
}

.no-pad-basta {
  padding: 0 !important;
}

.header-subtitle {
  color: #b3b3b3;
  font-size: 0.8rem;
  text-transform: none;
}

.lesser-pad {
  padding: 0.27rem 0 0 0 !important;
}

.std-pad {
  padding: calc(0.7rem + 1px) 0;
}

.std-pad2 {
  padding: 0.7rem 0;
}

.icon-pad {
  padding: 0.55rem 1rem 0rem 0.6rem !important;
}

.botborder {
  border-bottom: 1px solid #dbdbdb;
}

.topborder {
  border-top: 1px solid #dbdbdb;
}

// .rounded {
//   border-radius: 6px !important;
// }
</style>
