import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        all: null,
        types: null,
        subunits: null,
        subOffices: null,
        companies: null
    },

    mutations: {
        [Mutation.FINANCE_ORGANIZATIONALUNIT_SET_ALL](currentState, organizations) {
            currentState.all = organizations
        },
        [Mutation.FINANCE_ORGANIZATIONALUNIT_TYPE_SET_ALL](currentState, types) {
            currentState.types = types
        },
        [Mutation.FINANCE_ORGANIZATIONALSUBUNIT_SET_ALL](currentState, subunits) {

            let regex = /\d\d\d\d/

            subunits.forEach(s => {

                let match = null

                if (s.departmentalCodes && s.departmentalCodes.specializationCode8th) {
                    match = s.departmentalCodes.specializationCode8th.match(regex)
                }

                if (match && match.length > 0) {
                    s.clinicCode = match[0]
                }
                else {
                    s.clinicCode = '-'
                }
            })

            currentState.subunits = subunits;
        },
        [Mutation.SUBUNIT_SET_OFFICES](currentState, offices) {
            currentState.subOffices = offices;
        },

        [Mutation.FINANCE_COMPANY_SET_ALL](currentState, companies) {
            currentState.companies = companies;
        },

        [Mutation.FINANCE_ORGANIZATIONALSUBUNIT_UPDATE](currentState, subunit) {
            var index = currentState.subunits.findIndex(function (item) {
                return item.id === subunit.id;
            });

            let subunits = currentState.subunits

            subunits = [
                ...subunits.slice(0, index),
                subunit,
                ...subunits.slice(index + 1)
            ]

            this.commit(Mutation.FINANCE_ORGANIZATIONALSUBUNIT_SET_ALL, subunits)
        },
    },

    actions: {

        //CRUD
        [Action.FINANCE_ORGANIZATIONALUNIT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_ORGANIZATIONALUNIT_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_GET_STRUCTURE](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_GET_STRUCTURE,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_GET_STRUCTURE_BY_ID](context, idStructure) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_GET_STRUCTURE_BY_ID.replace('{id}', idStructure),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //FINANCE_ORGANIZATIONALUNIT_GET_LOCAL_STRUCTURE_BY_ID
        [Action.FINANCE_ORGANIZATIONALUNIT_GET_LOCAL_STRUCTURE_BY_ID](context, idStructure) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_GET_LOCAL_STRUCTURE_BY_ID.replace('{id}', idStructure),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


        [Action.FINANCE_ORGANIZATIONALUNIT_GET_TOP](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_GET_TOP,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_CREATE](context, contract) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_CREATE,
                        data: contract,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_ADD_UNIT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_ADD_UNIT.replace('{parentId}/{childId}', payload.parentId + '/' + payload.childId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_DELETE_ID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_DELETE_ID.replace('{parentId}/{childId}', payload.parentId + '/' + payload.childId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_CONNECT_LOCAL](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_CONNECT_LOCAL.replace('{parentId}/{childId}', payload.parentId + '/' + payload.childId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //NEW FOR ORGANIZATIONAL UNIT TYPE
        [Action.FINANCE_ORGANIZATIONALUNIT_TYPE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_TYPE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_ORGANIZATIONALUNIT_TYPE_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_TYPE_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_TYPE_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_TYPE_CREATE](context, contract) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_TYPE_CREATE,
                        data: contract,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_TYPE_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_TYPE_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_TYPE_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_TYPE_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_ORGANIZATIONALSUBUNIT_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALSUBUNIT_CREATE](context, contract) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_CREATE,
                        data: contract,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALSUBUNIT_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.FINANCE_ORGANIZATIONALSUBUNIT_UPDATE,response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALSUBUNIT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.FINANCE_ORGANIZATIONALSUBUNIT_ADD](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_ADD.replace('{parentId}/{childId}', payload.parentId + '/' + payload.childId),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //FINANCE_ORGANIZATIONALSUBUNIT_GET_TYPES
        [Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_TYPES](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_GET_TYPES,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        //FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_TYPES
        [Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_TYPES](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_TYPES,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_GET_REGIONS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_GET_REGIONS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_GET_OFFICES](context, unitId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_GET_OFFICES.replace('{unitId}', unitId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_ORGANIZATIONALUNIT_GET_BY_TYPE](context, type) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_ORGANIZATIONALUNIT_GET_BY_TYPE.replace('{type}', type),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.SUBUNIT_SET_SCHEDULEDISABLED](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_ORGANIZATIONALSUBUNIT_SET_SCHEDULEDISABLED,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_ORGANIZATIONALSUBUNIT_UPDATE,response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        

        [Action.OFFICE_GET_BY_SUBUNIT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.OFFICE_GET_BY_SUBUNIT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.SUBUNIT_SET_OFFICES, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Getting offices by subunit for reporting
         * @param {*} context 
         * @param {String} id - subunit id
         * @returns {Array} List of offices
         */
        [Action.OFFICE_GET_BY_REPORTED_SUBUNIT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.OFFICE_GET_BY_REPORTED_SUBUNIT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        
        /**
         * Getting offices by master office
         * @param {*} context 
         * @param {String} id - office master id
         * @returns List of offices
         */
        [Action.OFFICE_GET_BY_MASTER](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.OFFICE_GET_BY_MASTER.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        
        /**
         * Getting stray offices (Stray office - is not in the structure)
         * @param {*} context 
         * @returns List of offices
         */
        [Action.OFFICE_GET_STRAY_OFFICES](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.OFFICE_GET_STRAY_OFFICES,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //Company
        [Action.FINANCE_COMPANY_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_COMPANY_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.FINANCE_COMPANY_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_COMPANY_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_COMPANY_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_COMPANY_CREATE](context, invoice) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.FINANCE_COMPANY_CREATE,
                        data: invoice,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.FINANCE_COMPANY_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.FINANCE_COMPANY_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_COMPANY_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.FINANCE_COMPANY_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_COMPANY_GET_STRUCTURE](context, companyId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_COMPANY_GET_STRUCTURE.replace('{id}', companyId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.FINANCE_COMPANY_GET_LOCAL_STRUCTURE](context, companyId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.FINANCE_COMPANY_GET_LOCAL_STRUCTURE.replace('{id}', companyId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

    }
}