<template>
  <div
    class="app-base hand"
    :style="`border: 1px solid ${this.colors.dark};border-left: 16px solid ${this.colors.dark};background-color: ${this.colors.light};color: ${this.colors.text};`"
  >
    <div
      class="overlay"
      @click="isModalActive = true"
      :style="overlayStyle"
      @mouseenter="hoverOn"
      @mouseleave="hoverOff"
    >
      <div class="icons">
        <div>
          <b-icon
            :icon="getAppIcon(block.appointment.type)"
            :type="block.appointment.isApkolce ? 'is-warning':'is-light'"
            class="is-size-7"
            size="is-small"
          ></b-icon>
        </div>
        <div style="margin-top: -12px">
          <b-icon
            :icon="mGetServiceIcon(block.appointment.service)"
            type="is-light"
            class="is-size-7"
            size="is-small"
          ></b-icon>
        </div>
      </div>
      <div class="smaller">
        <div>
          <div class="columns is-variable is-0">
            <div class="column">
              <div>
                <span
                  v-if="block.appointment.isGroup && block.appointment.groupPatients"
                >GRUPA ({{block.appointment.groupPatients.length}})</span>
                <span
                  v-else-if="block.appointment.vehicle > 0"
                >POJAZD ({{block.appointment.vehicle}})</span>
                <span
                  v-else-if="patient"
                  class="has-text-weight-semibold"
                  style="letter-spacing: 0em !important;"
                >
                  {{ patient.lastName }}
                  {{ getShort(patient.firstName) }}
                </span>
                <span v-else>
                  <b-skeleton width="95%" height="7px" :animated="true"></b-skeleton>
                </span>
              </div>
              <span v-if="appTypeVisible">{{ getAppName(block.appointment.type) }}</span>
              <span v-if="appTypeVisible && block.appointment.subtype > -1">
                <br />
                {{ getAppointmentSubcategoryCategoryName( block.appointment.type, block.appointment.subtype) }}
              </span>
            </div>
            <div class="column is-narrow" v-if="block.appointment.sms">
              <b-icon icon="message-reply-text" style="margin-top: -2px;" size="is-small"></b-icon>
            </div>
            <div
              class="column is-narrow has-text-right smaller is-family-code"
              style="padding-right: 2px"
            >
              <div>{{ mTime(block.start) }}</div>
              <div>{{ mTime(block.end) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <ScheduleModalAppointmentDetails
        @created="emitCreate"
        @removed="emitRemoved"
        @edited="emitUpdate"
        @split="emitSplit"
        @close="isModalActive = false"
        :block="block"
        :blocks="blocks ? blocks : []"
        :patient="patient"
      ></ScheduleModalAppointmentDetails>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/commons'
import DateHelpers from '@/mixins/date_helpers'
import ColorHelpers from '@/mixins/schedule_colors'
import AppoHelpers from '@/mixins/appointments'
import ScheduleModalAppointmentDetails from './ScheduleModalAppointmentDetails'
import moment from 'moment'
import { Action } from '../../store/config_actions'

export default {
  name: 'ScheduleBlockAppointment',
  mixins: [CommonMixins, DateHelpers, ColorHelpers, AppoHelpers],
  components: { ScheduleModalAppointmentDetails },

  props: {
    date: { type: Object, required: true },
    block: { type: Object, required: true },
    blocks: { type: Array, required: false },
  },

  watch: {
    block() {
      this.getPatient()
      this.configureColors()
    }
  },

  data: function () {
    return {
      colors: {
        dark: 'grey',
        light: 'silver',
        text: 'black',
      },
      isModalActive: false,
      overlayStyle: '',
      patient: null,
      dark: 'grey'
    }
  },

  mounted() {
    this.getPatient()
    this.configureColors()
  },

  computed: {
    appTypeVisible() { return this.$store.state.locals.scheduleApplicationTypeVisible },
    time() {
      return moment(this.block.end).diff(moment(this.block.start), 'minutes')
    },
    apps() { return this.$store.state.config.appointments },
    isHistory() {
      let now = moment().startOf('day')
      return moment(this.block.appointment.start).isBefore(now)
    },
    schedulesStore() {
      return this.$store.state.registration.schedules
    },
  },

  methods: {

    getAppIcon(id) {
      let category = this.apps.find((c) => c.id == id);
      if (category) return category.icon;
      else return `help`;
    },

    getAppName(id) {
      let category = this.apps.find(c => c.id == id)
      if (category) return category.name
      else return `Nieznana strefa`
    },

    emitCreate(payload) {
      this.$emit('create', payload)
    },

    emitUpdate(payload) {
      this.isModalActive = false
      this.$emit('update', payload)
    },

    emitSplit() {
      this.isModalActive = false
      this.$emit('split')
    },

    emitRemoved(payload) {
      this.isModalActive = false
      this.$emit('remove', payload)
    },

    hoverOn() {
      this.overlayStyle = `background-color: ${this.colors.dark} !important;`
    },
    hoverOff() {
      this.overlayStyle = ''
    },

    getPatient() {
      if (this.block.appointment.patientId > 0) {
        if (this.patient == null || this.patient.id != this.block.appointment.patientId) {
          this.$store.dispatch(Action.PATIENT_GET_BY_ID, this.block.appointment.patientId)
            .then((x) => {
              this.patient = x.data
              this.block.appointment.patient = x.data
            })
            .catch((error) => {
              this.apiProblem(error)
            })
        }
      }
    },

    getShort(name) {
      if (name && name.length > 0) {
        return name[0] + '.'
      } else {
        return ''
      }
    },

    configureColors(skipSplit) {
      let colors = { dark: '#68c5d7', light: '#dbeeef', text: '#fff' }

      if (this.apps) {
        let config = this.apps.find(s => s.id === this.block.appointment.type)

        if (config) {
          let subNotFound = true

          if (this.block.appointment.subtype > 0 && config.subcategories && config.subcategories.length > 0) {
            let config2 = config.subcategories.find(s => s.id === this.block.appointment.subtype)

            if (config2) {
              subNotFound = false
              colors.dark = config2.darkColor ?? colors.dark
              colors.light = config2.lightColor ?? colors.light
              colors.text = config2.textColor ?? colors.text
            }
          }

          if (subNotFound) {
            colors.dark = config.darkColor ?? colors.dark
            colors.light = config.lightColor ?? colors.light
            colors.text = config.textColor ?? colors.text
          }
        }
      }

      if (this.block.appointment.isUrgent) { colors.dark = 'red' }

      if (skipSplit) {
        // do nothing
      }
      else {
        if (this.block.appointment.isGroup) { colors.dark = 'hotpink' }
      }

      if (this.block.appointment.state == 1) { colors.dark = 'orange' }
      if (this.block.appointment.state > 4 || this.isHistory) { colors = { dark: '#555', light: '#666', text: '#e6e6e6' } }
      if (this.block.appointment.state == 0 && this.isHistory) { colors.dark = '#2e6b2e' }
      if (this.block.appointment.state == 1 && this.isHistory) { colors.dark = '#cc5200' }
      if (this.block.appointment.state == 8) {
        colors.text = '#ffe6ff'
      }

      if (this.schedulesStore && this.schedulesStore.length > 0) {
        let same = this.schedulesStore.find(x => x.id === this.block.appointment.id)

        if (same) {
          colors.dark = '#00ff99'
        }
      }

      this.colors = colors
    },

    getNoteStyle() {
      return `color: ${this.colors.text};`
    }
  }
}
</script>

<style scoped lang="scss">
.overlay {
  height: 100% !important;
  min-height: 300px;
  padding-left: 0.2rem;
  position: relative;
}

.app-base {
  height: 100% !important;
  border-radius: 3px;
}

.small {
  font-size: 0.7rem;
  line-height: 0.8rem;
}

.smaller {
  font-size: 0.66rem;
  line-height: calc(0.58rem + 0px);
}

.icons {
  position: absolute;
  left: -16px;
  top: 4px;
}

.appnote {
  font-size: 0.5rem;
  line-height: 0.5rem;
}
</style>
