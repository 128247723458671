import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import axios from 'axios'
//import moment from 'moment'

export default {
    namespaced: false,

    state: {
        products: [],
        suppliers: [],
        orders: []
    },

    mutations: {
        [Mutation.ORDERS_SET_ORDERS](state, payload) {
            state.orders = payload
        },
        [Mutation.ORDERS_DELETE_ORDER](state, payload) {
            let productIndex = state.orders.indexOf(payload)
            if (productIndex > -1) { state.orders.splice(productIndex, 1) }
        },

        [Mutation.ORDERS_SET_PRODUCTS](state, payload) {
            state.products = payload
        },
        [Mutation.ORDERS_ADD_PRODUCT](state, payload) {
            state.products.push(payload)
        },
        [Mutation.ORDERS_MODIFY_PRODUCT](state, payload) {
            let productIndex = state.products.findIndex(u => u.id === payload.id)
            if (productIndex > -1) {
                state.products.splice(productIndex, 1)
                state.products.splice(productIndex, 0, payload)
            }
        },
        [Mutation.ORDERS_REMOVE_PRODUCT](state, payload) {
            let productIndex = state.products.indexOf(payload)
            if (productIndex > -1) { state.products.splice(productIndex, 1) }
        },

        [Mutation.ORDERS_SET_SUPPLIERS](state, payload) {
            state.suppliers = payload
        },
        [Mutation.ORDERS_ADD_SUPPLIER](state, payload) {
            state.suppliers.push(payload)
        },
        [Mutation.ORDERS_MODIFY_SUPPLIER](state, payload) {
            let supplier = state.suppliers.find(u => u.id === payload.id)
            if (supplier) { supplier = payload }
        },
        [Mutation.ORDERS_REMOVE_SUPPLIER](state, payload) {
            let supplierIndex = state.suppliers.indexOf(payload)
            if (supplierIndex > -1) { state.suppliers.splice(supplierIndex, 1) }
        },

        [Mutation.ORDERS_RESET](state) {
            state.products = []
            state.suppliers = []
            state.orders = []
        },
    },



    actions: {

        // PRODUCTS ****************************************************************************

        [Action.ORDERS_GET_PRODUCTS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.ORDERS_PRODUCTS_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.ORDERS_SET_PRODUCTS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.ORDERS_ADD_PRODUCT](context, product) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.ORDERS_PRODUCTS_POST_NEW,
                        data: product,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.ORDERS_ADD_PRODUCT, response.data)
                        resolve();
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.ORDERS_MODIFY_PRODUCT](context, product) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.ORDERS_PRODUCTS_MODIFY.replace('{id}', product.id),
                        data: product,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.ORDERS_MODIFY_PRODUCT, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.ORDERS_DELETE_PRODUCT](context, product) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.ORDERS_PRODUCTS_DELETE.replace('{id}', product.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.ORDERS_REMOVE_PRODUCT, product)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // SUPPLIERS ****************************************************************************

        [Action.ORDERS_GET_SUPPLIERS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.ORDERS_SUPPLIERS_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.ORDERS_SET_SUPPLIERS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.ORDERS_ADD_SUPPLIER](context, supplier) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.ORDERS_SUPPLIERS_POST_NEW,
                        data: supplier,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        context.commit(Mutation.ORDERS_ADD_SUPPLIER, response.data)
                        resolve();
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.ORDERS_MODIFY_SUPPLIER](context, supplier) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.ORDERS_SUPPLIERS_MODIFY.replace('{id}', supplier.id),
                        data: supplier,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.ORDERS_MODIFY_SUPPLIER, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.ORDERS_DELETE_SUPPLIER](context, supplier) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.ORDERS_SUPPLIERS_DELETE.replace('{id}', supplier.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.ORDERS_REMOVE_SUPPLIER, supplier)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        // ORDERS ****************************************************************************

        [Action.ORDERS_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.ORDERS_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.ORDERS_SET_ORDERS, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.ORDERS_CREATE_NEW](context, orderBundle) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.ORDERS_POST_NEW,
                        data: orderBundle,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.ORDERS_ADD_SUPPLIER, response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        [Action.ORDERS_GET](context, order) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.ORDERS_GET.replace('{id}', order.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
        [Action.ORDERS_DELETE](context, order) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.ORDERS_DELETE.replace('{id}', order.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.ORDERS_DELETE_ORDER, order)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}