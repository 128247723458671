<template>
  <div>
    <b-table
      paginated
      per-page="20"
      bordered
      narrowed
      hoverable
      :data="translateAppointmentsPatientTickets"
      style="font-size: 0.9rem"
    >
      <b-table-column label="Lp" v-slot="props">{{
        props.row.index
      }}</b-table-column>
      <b-table-column
        v-slot="props"
        field="startDate"
        label="Ważny od"
        cell-class="hand"
        centered
        width="5%"
        >{{ mDate(props.row.startDate) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="expiryDate"
        label="Ważny do"
        cell-class="hand"
        centered
        width="5%"
        >{{ mDate(props.row.expiryDate) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="payerType"
        label="Płatnik"
        cell-class="hand"
        centered
        width="1%"
        >{{ getPayerTypeName(props.row.payerType) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Data wystawienia skierowania"
        cell-class="hand"
        centered
        >
        <span v-if="props.row.referral.apkolce">{{ mDate(props.row.referral.apkolce.issueDate) }}</span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Poradnia kierująca"
        cell-class="hand"
        >
          <p>
            <span v-if="props.row.referral.internalReferringClinicId">
              {{ getSubunitWithFacility(props.row.referral.internalReferringClinicId) }}
            </span>
            <span v-else-if="props.row.referral.externalReferringClinic">
                {{ props.row.referral.externalReferringClinic }}
            </span>
          </p>

          <div v-if="props.row.referral.apkolce"> 
            <!-- <p>Podmiot: {{props.row.referral.apkolce.subject}}</p> -->
            <p>REGON: {{props.row.referral.apkolce.regon}}</p>
            <p>Kod I: {{props.row.referral.apkolce.code1st}},
               Kod VII: {{props.row.referral.apkolce.code7th}},
               Kod VIII: {{props.row.referral.apkolce.code8th}}
            </p>
          </div>
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Lekarz kierujący"
        cell-class="hand"
        width="8%"
        >
            <span v-if="props.row.referral.referredBy !== -1">
              {{ getEmployee(props.row.referral.referredBy) }}
            </span>
            <span v-else>
              {{
                beautyNameDoctor(
                  props.row.referral.externalReferredBy
                )
              }}
            </span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Rozpoznania"
        cell-class="hand"
        width="15%"
        >
         <p v-if="props.row.referral.icd10">
          Główne:
         </p>
          <p v-if="props.row.referral.icd10">
            {{ props.row.referral.icd10.code }} -
            {{ props.row.referral.icd10.name }}

            <span
              v-if="
                props.row.referral.icd10.additional &&
                props.row.referral.icd10.additional.length > 0
              "
            >
               Dodatkowe:
              <span
                v-for="(item, index) in props.row.referral.icd10
                  .additional"
                :key="index"
              >
                {{ item.code }} - {{ item.name }}
              </span>
            </span>
          </p>
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Skierowanie"
        cell-class="hand"
        >
            <p>Rodzaj skierowania: {{ getReferralType(props.row.referral.type) }}, 
               <!-- {{ props.row.referral.isPaperReferral ? "papierowe" : "e-skierowanie" }} -->
            </p>

            <div v-if="!props.row.referral.isPaperReferral">
                <div v-if="props.row.referral.eReferral && props.row.referral.eReferral.id">
                    <p>
                        Root OID:  
                        <span v-if="props.row.referral.eReferral.authorUnitCode">2.16.840.1.113883.3.4424.2.7.{{props.row.referral.eReferral.authorUnitCode}}.4.1
                        </span>
                        <span v-else>
                          Błędny numer, skontaktuj się z administratorem
                        </span>
                    </p>
                    <p>
                        Numer e-skierowania (extension):
                        {{ props.row.referral.eReferral.eSkierowanieNumer }}
                    </p>
                    <p>
                        Klucz e-skierowania:
                        {{ props.row.referral.eReferral.eSkierowanieKlucz }}
                    </p>
                </div>
                <div v-else-if="props.row.referral.eReferralPaper">
                    <p>
                        Root OID: {{ props.row.referral.eReferralPaper.rootOID }}
                    </p>
                    <p>
                        Numer e-skierowania (extension):
                        {{ props.row.referral.eReferralPaper.referralNr }}
                    </p>
                    <p>
                        Klucz e-skierowania:
                        {{ props.row.referral.eReferral.referralId }}
                    </p>
                </div>
            </div>
      </b-table-column>
      <b-table-column v-slot="props" label="Akcje" cell-class="hand" centered>
          <div v-if="props.row.referral" class="mb-3">
            <div
              v-if="
                props.row.referral.isPaperReferral == true &&
                props.row.referral.idReferralFile != null
              "
            >
              <b-tooltip
                label="Podgląd skierowania papierowego"
                position="is-left"
              >
                <b-button
                  size="is-small"
                  type="is-primary"
                  @click="
                    getDownloadedFile(
                      props.row.referral.idReferralFile
                    )
                  "
                  icon-left="magnify"
                ></b-button>
              </b-tooltip>
            </div>
            <div
              v-if="
                props.row.referral.eReferral != null &&
                props.row.referral.eReferral.id != null
              "
            >
              <b-tooltip label="Podgląd e-skierowania" position="is-left">
                <b-button
                  size="is-small"
                  type="is-primary"
                  @click="
                    referralOpen = true;
                    selectedTicket = props.row;
                  "
                  icon-left="magnify"
                ></b-button>
              </b-tooltip>
            </div>
          </div>

        <b-tooltip label="Przypisz bilet do nowej wizyty" position="is-left">
          <b-button
            size="is-small"
            type="is-primary"
            icon-left="arrow-right"
            :disabled="!props.row"
            @click="addTicket(props.row)"
          ></b-button>
        </b-tooltip>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>Brak wizyt</p>
          </div>
        </section>
      </template>
    </b-table>

    <b-modal
      v-if="selectedTicket"
      v-model="referralOpen"
      has-modal-card
      aria-role="dialog"
      aria-modal
      trap-focus
      class="ris-modal-referral"
      :destroy-on-hide="false"
    >
      <div class="modal-card has-background-kin">
        <header class="modal-card-head has-background-kin">
          <p
            class="modal-card-title has-text-primelight"
            style="line-height: 2rem"
          >
            E-skierowanie
          </p>
        </header>
        <section class="modal-card-body">
          <div v-html="selectedTicket.referral.eReferral.eSkierowanie"></div>
        </section>
      </div>
      <footer class="modal-card-foot buttons is-right">
        <div>
          <b-button
            icon-left="close"
            size="is-small"
            @click="referralOpen = false"
            >Zamknij</b-button
          >
        </div>
      </footer>
    </b-modal>
  </div>

</template>

<script>
import CommonHelpers from "@/mixins/commons";
import AppMixins from "@/mixins/appointments";
import { Action } from "@/store/config_actions";
import OrdersMixins from "@/mixins/orders/poz_orders";

import moment from "moment";

export default {
  name: "PatientTicketsClinicSchedule",
  mixins: [CommonHelpers, AppMixins, OrdersMixins],
  components: {},

  props: {
    visits: { type: Array, required: false },
    tickets: { type: Array, required: false },
    patient: { type: Object, required: false },
  },

  data: function () {
    return {
      requestInProgress: false,

      referralOpen: false,
      selectedTicket: null,
    };
  },

  mounted() {},

  watch: {},

  computed: {
    me() {
      return this.$store.state.employee.me;
    },

    apps() {
      return this.$store.state.config.appointments;
    },
    referralsType() {
      return this.$store.state.config.referrals ?? [];
    },

    docs() {
      return this.$store.state.employee.scheduledEmployees;
    },

    employees() {
      return this.$store.state.employee.all ?? [];
    },

    configClinics() {
      return this.$store.state.config.clinics ?? [];
    },

    configAppointments() {
      return this.$store.state.config.appointments ?? [];
    },

    subunitsAll() {
      return this.$store.state.orgUnit.subunits ?? [];
    },

    transletedSubunits() {
      let translated = this.subunitsAll;

      translated.forEach((sub) => {
        if (sub) {
          if (sub.facilityId != null) {
            sub.facilityName = this.getFacility(sub.facilityId);
          } else {
            sub.facilityName = "Nieokreślona";
          }
        }
      });
      return translated;
    },


    facilities() {
      return this.$store.state.clinic.clinics;
    },

    translateAppointmentsPatientTickets() {
      let tickets = this.tickets

      tickets.forEach((tick, index) => {
        tick.index = index + 1
      })

      return tickets
    },

  },

  methods: {
    sortByMoment(a, b) {
      return moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
    },

    sortByMoment2(a, b) {
      return moment(a.dateTimeInfoStart).isBefore(moment(b.dateTimeInfoStart))
        ? -1
        : 1;
    },

    //////////////////////////////////////////////////////////////////////////////////////

getStateName(state) {
      if (state == -1) {
        return "Brak informacji o statusie wizyty";
      } else if (state == 0) {
        return "Wizyta nie została rozpoczęta";
      } else if (state == 1) {
        return "Wizyta rozpoczęta wcześniej, ale nie zakończona";
      } else if (state == 2) {
        return "Wizyta rozpoczęta wcześniej, współdzielona";
      } else if (state == 5) {
        return "Wizyta zakończona";
      } else if (state == 6) {
        return "Wizyta odwołana";
      } else if (state == 7) {
        return "Wizyta przełożona";
      } else if (state == 8) {
        return "Wizyta nie odbyła się. Termin wizyty minął.";
      }

      return "Nieznany status wizyty";
    },

    getAppSubCategory(id, sid) {
      if (sid === null || sid < 0) return "";

      let category = this.configAppointments.find((c) => c.id == id);

      if (category) {
        if (category.subcategories) {
          let subcategory = category.subcategories.find((s) => s.id == sid);
          if (subcategory) {
            return subcategory.name;
          }
        }
      }

      return "Brak informacji";
    },

    getEmployee(id) {
      if (this.employees) {
        let found = this.employees.find((s) => s.id === id);
        if (found) {
          return found.name;
        }
      }
      return "Nie znaleziono";
    },

    getClinic(id) {
      let clinic = this.configClinics.find((option) => option.id === id);
      if (clinic) {
        return clinic.shortName;
      } else {
        return "Brak danych";
      }
    },

    getFacility(id) {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.id === id);
        return found != null ? found.name : `Brak (${id})`;
      } else return `Brak placówki (${id})`;
    },

    getPayerTypeName(payerType) {
      if (payerType == 0) {
        return "Komercja";
      } else if (payerType == 1) {
        return "NFZ";
      } else if (payerType == 2) {
        return "MZ";
      }

      return "Brak informacji";
    },

    getReferralType(id) {
      let referralType = this.referralsType.find(x => x.id == id)
      if(referralType) {
        return referralType.name
      } else {
        return `Brak danych [${id}]`
      }
    },



    getDownloadedFile(fileId) {
      this.requestInProgress = true;
      //this.currentBlob = null;
      this.fileProps = {
        FileNameId: fileId,
        WorkerId: this.me.id,
        PatientId: this.patient.id,
      };
      this.$store
        .dispatch(Action.PATIENT_DOWNLOAD_FILE, this.fileProps)
        .then((response) => {
          //this.fileOptions(option, currentFile);
          let url = URL.createObjectURL(response.data);
          window.open(url, "_blank", "location=yes,scrollbars=yes");
          URL.revokeObjectURL(url);

          this.requestInProgress = false;
        })
        .catch((error) => {
          this.requestInProgress = false;
          this.dangerSnackbar(error.toString());
          //this.apiProblem(error);
        });
    },

    getSubunitWithFacility(id) {
      if (this.transletedSubunits) {
        let subunit = this.transletedSubunits.find((s) => s.id === id);
        if (subunit) {
          return `${subunit.name}, ${subunit.facilityName}`;
        }
      }
      return "Nie znaleziono";
    },

    beautyNameDoctor(referredBy) {
      let arrayDoc = referredBy ? referredBy.split("^") : [];
      if (arrayDoc.length > 3) {
        let referralDoctorLastName = arrayDoc[0] ?? ""
        referralDoctorLastName = referralDoctorLastName.toUpperCase()

        let referralDoctorFirstName = arrayDoc[1] ?? ""
        referralDoctorFirstName = referralDoctorFirstName.toUpperCase()

        let referralDoctorPwz = arrayDoc[2] ?? ""

        let referralDoctorPrefix = arrayDoc[3] ?? ""


        return `${referralDoctorPrefix} ${referralDoctorLastName} ${referralDoctorFirstName} (PWZ:${referralDoctorPwz})`;
      } else {
        return referredBy;
      }
    },

    addTicket(ticketInfo) {
      let ticket = JSON.parse(JSON.stringify(this.ticketObject));

      ticket.ikz = ticketInfo.ikz;
      ticket.procedures = ticketInfo.procedures;
      ticket.patientId = ticketInfo.patientId;
      ticket.releaseDate = this.mNow();
      ticket.startDate = this.mNow();
      ticket.created = this.mNow();
      ticket.expiryDate = moment().add(2, "year");
      ticket.ticketType = ticketInfo.ticketType;
      ticket.ticketOffice = ticketInfo.ticketOffice;
      ticket.minMinutes = 10;
      ticket.isTimeless = false;
      ticket.payerType = ticketInfo.payerType;
      ticket.isPaid = ticketInfo.isPaid;
      ticket.referral = ticketInfo.referral;

      this.$emit("add-ticket", ticket);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
