import { Action } from "../config_actions"
import { Mutation } from '../config_mutations'
import { Apis } from "../config_apis"
import axios from "axios"

export default {
  namespaced: false,

  state: {
    config: [],
    goals:[],
    groupGoals:[],
  },

  mutations: {
    [Mutation.RECOMMENDATION_CONFIG_SET](state, payload) {
      state.config = payload
    },

    [Mutation.RECOMMENDATION_GOAL_SET](state, payload){
      state.goals = payload
    },
    
    [Mutation.RECOMMENDATION_GOAL_GROUP_SET](state, payload){
      state.groupGoals = payload
    }
  },

  actions: {

    // ****************************************************************************************
    // RECOMMENDATIONS CONFIG
    // ****************************************************************************************

    [Action.RECOMMENDATION_CONFIG_GET_WITH_REMOVED](context) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "get",
            url: Apis.RECOMMENDATION_CONFIG_GET_WITH_REMOVED,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              context.commit(Mutation.RECOMMENDATION_CONFIG_SET, response.data)
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GET_ALL](context) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "get",
            url: Apis.RECOMMENDATION_CONFIG_GET_ALL,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              context.commit(Mutation.RECOMMENDATION_CONFIG_SET, response.data)
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GET](context, id) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "get",
            url: Apis.RECOMMENDATION_CONFIG_GET.replace("{id}", id),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GET_BY_TYPE](context, type) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "get",
            url: Apis.RECOMMENDATION_CONFIG_GET_BY_TYPE.replace("{type}", type),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_UPDATE](context, recommendationConfig) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "put",
            url: Apis.RECOMMENDATION_CONFIG_GET.replace(
              "{id}",
              recommendationConfig.id
            ),
            data: recommendationConfig,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_DELETE](context, id) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "delete",
            url: Apis.RECOMMENDATION_CONFIG_GET.replace("{id}", id),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_CREATE](context, recommendationConfig) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "post",
            url: Apis.RECOMMENDATION_CONFIG_GET_ALL,
            data: recommendationConfig,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_REMOVE](context, id) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "post",
            url: Apis.RECOMMENDATION_CONFIG_REMOVE.replace("{id}", id),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_RESTORE](context, id) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "post",
            url: Apis.RECOMMENDATION_CONFIG_RESTORE.replace("{id}", id),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    // ****************************************************************************************
    // RECOMMENDATIONS CONFIG GROUP
    // ****************************************************************************************

    [Action.RECOMMENDATION_CONFIG_GROUP_GET_WITH_REMOVED](context) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "get",
            url: Apis.RECOMMENDATION_CONFIG_GROUP_GET_WITH_REMOVED,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GROUP_GET_ALL](context) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "get",
            url: Apis.RECOMMENDATION_CONFIG_GROUP_GET_ALL,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GROUP_GET](context, id) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "get",
            url: Apis.RECOMMENDATION_CONFIG_GROUP_GET.replace("{id}", id),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GROUP_GET_BY_TYPE](context, type) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "get",
            url: Apis.RECOMMENDATION_CONFIG_GROUP_GET_BY_TYPE.replace("{type}", type),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GROUP_UPDATE](context, recommendationConfig) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "put",
            url: Apis.RECOMMENDATION_CONFIG_GROUP_GET.replace(
              "{id}",
              recommendationConfig.id
            ),
            data: recommendationConfig,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GROUP_DELETE](context, id) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "delete",
            url: Apis.RECOMMENDATION_CONFIG_GROUP_GET.replace("{id}", id),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GROUP_CREATE](context, recommendationConfig) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "post",
            url: Apis.RECOMMENDATION_CONFIG_GROUP_GET_ALL,
            data: recommendationConfig,
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GROUP_REMOVE](context, id) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "post",
            url: Apis.RECOMMENDATION_CONFIG_GROUP_REMOVE.replace("{id}", id),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    [Action.RECOMMENDATION_CONFIG_GROUP_RESTORE](context, id) {
      return new Promise((resolve, reject) => {
        let token = context.rootState.identity.session.token
        if (token != null) {
          axios({
            method: "post",
            url: Apis.RECOMMENDATION_CONFIG_GROUP_RESTORE.replace("{id}", id),
            headers: { Authorization: "Bearer " + token },
          })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        } else reject("401")
      })
    },

    // ****************************************************************************************
    // RECOMMENDATIONS GOAL CONFIG
    // ****************************************************************************************

    [Action.RECOMMENDATION_GOAL_GET_WITH_REMOVED](context) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.RECOMMENDATION_GOAL_GET_WITH_REMOVED,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                context.commit(Mutation.RECOMMENDATION_GOAL_SET, response.data)
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GET_ALL](context) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.RECOMMENDATION_GOAL_GET_ALL,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                context.commit(Mutation.RECOMMENDATION_GOAL_SET, response.data)
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GET](context, id) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.RECOMMENDATION_GOAL_GET.replace("{id}", id),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GET_BY_TYPE](context, type) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.RECOMMENDATION_GOAL_GET_BY_TYPE.replace("{type}", type),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_UPDATE](context, recommendationGOAL) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "put",
              url: Apis.RECOMMENDATION_GOAL_GET.replace(
                "{id}",
                recommendationGOAL.id
              ),
              data: recommendationGOAL,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_DELETE](context, id) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "delete",
              url: Apis.RECOMMENDATION_GOAL_GET.replace("{id}", id),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_CREATE](context, recommendationGOAL) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.RECOMMENDATION_GOAL_GET_ALL,
              data: recommendationGOAL,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_REMOVE](context, id) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.RECOMMENDATION_GOAL_REMOVE.replace("{id}", id),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_RESTORE](context, id) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.RECOMMENDATION_GOAL_RESTORE.replace("{id}", id),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      // ****************************************************************************************
      // RECOMMENDATIONS GOAL GROUP CONFIG
      // ****************************************************************************************

      [Action.RECOMMENDATION_GOAL_GROUP_GET_WITH_REMOVED](context) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.RECOMMENDATION_GOAL_GROUP_GET_WITH_REMOVED,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                context.commit(Mutation.RECOMMENDATION_GOAL_GROUP_SET, response.data)
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GROUP_GET_ALL](context) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.RECOMMENDATION_GOAL_GROUP_GET_ALL,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                context.commit(Mutation.RECOMMENDATION_GOAL_GROUP_SET, response.data)
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GROUP_GET](context, id) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.RECOMMENDATION_GOAL_GROUP_GET.replace("{id}", id),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GROUP_GET_BY_TYPE](context, type) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "get",
              url: Apis.RECOMMENDATION_GOAL_GROUP_GET_BY_TYPE.replace("{type}", type),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GROUP_UPDATE](context, recommendationGOAL_GROUP) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "put",
              url: Apis.RECOMMENDATION_GOAL_GROUP_GET.replace(
                "{id}",
                recommendationGOAL_GROUP.id
              ),
              data: recommendationGOAL_GROUP,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GROUP_DELETE](context, id) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "delete",
              url: Apis.RECOMMENDATION_GOAL_GROUP_GET.replace("{id}", id),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GROUP_CREATE](context, recommendationGOAL_GROUP) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.RECOMMENDATION_GOAL_GROUP_GET_ALL,
              data: recommendationGOAL_GROUP,
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GROUP_REMOVE](context, id) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.RECOMMENDATION_GOAL_GROUP_REMOVE.replace("{id}", id),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },

      [Action.RECOMMENDATION_GOAL_GROUP_RESTORE](context, id) {
        return new Promise((resolve, reject) => {
          let token = context.rootState.identity.session.token
          if (token != null) {
            axios({
              method: "post",
              url: Apis.RECOMMENDATION_GOAL_GROUP_RESTORE.replace("{id}", id),
              headers: { Authorization: "Bearer " + token },
            })
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(error)
              })
          } else reject("401")
        })
      },
  },
}
