<template>
  <div v-if="objectPresent">
    <b-modal
      :active.sync="iscompletedReferralModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="false"
      style="z-index: 9999"
    >
      <div class="modal-card">
        <header class="modal-card-head has-background-kin">
          <p class="modal-card-title has-text-light">Rezygnacja z realizacji e-skierowania</p>
        </header>
        <section class="modal-card-body" style="width: auto; min-height: 400px; color: black;">
          <p class="mt-3 mb-3 is-size-5 has-text-centered has-text-weight-bold">
            <b-icon icon="alert-circle-outline" style="color: red" />Skierowanie zrealizowano na NFZ:
            <b-field
              grouped
              class="is-centered"
              style="display: inline-block; vertical-align: middle"
            >
              <b-radio-button
                disabled
                size="is-small"
                v-model="isNFZ"
                :native-value="false"
              >Nie</b-radio-button>
              <b-radio-button
                disabled
                size="is-small"
                v-model="isNFZ"
                :native-value="true"
              >Tak</b-radio-button>
            </b-field>
          </p>
          <p class="mt-3 mb-3 is-size-5 has-text-centered has-text-weight-bold">
            <b-field label="Komentarz (wymagany przy odmowie min. 5 znaków)" class="is-centered">
              <b-input type="textarea" v-model="comment" maxlength="1000" />
            </b-field>
          </p>

          <div
            class="mt-3 mb-3"
            style="color: red"
            v-if="
              resultP1 &&
              resultP1.errors &&
              (resultP1.eSkierowanieStatus == 4 ||
                resultP1.eSkierowanieStatus == 7)
            "
          >
            <p class="has-text-weight-medium">Błąd:</p>
            <p
              v-for="(item, index) in resultP1.errors"
              :key="index"
            >{{ item.errorMessage }} ({{ item.errorCode }})</p>
          </div>

          <!-- <div v-html="referralView"></div> -->

          <iframe v-if="referralView" :srcdoc="referralView" style="width:100%;height:24rem;"></iframe>
        </section>
        <footer class="modal-card-foot has-background-kin">
          <!-- <b-button :loading="requestInProgress" @click="iscompletedReferralModalActive = false"
            >Anuluj
          </b-button>-->
          <b-button
            :loading="requestInProgress"
            type="is-danger"
            @click="cancelReferral()"
            :disabled="comment && comment.length < 5"
          >Anuluj e-skierowanie</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "../../store/config_actions";
//import { Mutation } from '../../store/config_mutations';

export default {
  name: "AppointmentCancelReferralP1",
  mixins: [CommonHelpers],
  components: {},

  props: {
    ticket: { type: Object, required: false, default: null },
    result: { type: Object, required: false, default: null },
    trigger: { type: Number, required: false },
  },

  data: function () {
    return {
      intervalCancelled: null,
      comment: "",
      timeout: null,

      requestInProgress: false,
      type: 0,

      queryP1: {
        referralId: "",
        referralCode: "",
        referralPesel: "",
      },

      requestP1ver2: {
        eSkierowanieDataRequest: {
          id: "string",
          domain: "string",
          patientId: "",
          eSkierowanieDataAction: 3,
          eSkierowanieStatus: 0,
          eSkierowanieStatusChange: "2021-04-28T22:05:16.417Z",
          workerGuid: "ADM",
          domainId: "string",
          p1PatientData: {
            pesel: "string",
            country: "string",
            zip: "string",
            city: "string",
            street: "string",
            housenumber: "string",
            apartmentnumber: "string",
            simc: "string",
            firstname: "string",
            secondname: "string",
            lastname: "string",
            gender: 0,
            birthdate: "string",
            uprawnieniaDodatkowe: [
              {
                code: "string",
                displayName: "string",
                codeSystem: "string",
              },
            ],
            ewusCode: "string",
            nfZ_code: "string",
            isEwus: true,
          },
          eSkierowanieData: {
            guid: "string",
            data: "2021-04-28T22:05:16.417Z",
            typSkierowania: {
              code: "string",
              description: "string",
            },
            icD10Item: {
              code: "string",
              description: "string",
            },
            iCD9Items: [
              {
                code: "string",
                description: "string",
              },
            ],
            documentItems: [
              {
                description: "string",
                dateTime: "2021-04-28T22:05:16.417Z",
                isLabResult: true,
              },
            ],
            siteInfo: {
              code: "string",
              displayName: "string",
            },
            comments: "string",
            isCito: true,
            numerSkierowania: "string",
            numerZbioru: "string",
            oldNumerSkierowania: "string",
          },
          kodSkierowania: "",
          kluczSkierowania: "string",
          numerSkierowania: "string",
          authorUnitCode: "",
          errors: [
            {
              errorCode: "string",
              errorMessage: "string",
              errorOrigin: "string",
            },
          ],
          version: "string",
        },
      },

      resultP1: {
        id: "",
        workerGuid: "ADM",
        domainId: "string",
        eSkierowanieStatus: 0,
        eSkierowanieStatusChange: "2021-04-28T22:16:45.567Z",
        eSkierowanieKod: "",
        eSkierowanieKlucz: "",
        eSkierowanieNumer: "",
        eSkierowanie: "",
        errors: [
          {
            errorCode: "",
            errorMessage: "",
            errorOrigin: "",
          },
        ],
        // NEW
        isNFZ: false,
        acceptedId: null,
        authorUnitCode: "",
      },

      isNFZ: false,
      referralView: null,
      iscompletedReferralModalActive: false,
    };
  },

  mounted() { },

  beforeDestroy() {
    clearInterval(this.intervalCancelled);
    clearTimeout(this.timeout)
  },

  watch: {
    trigger() {
      if (this.trigger > 0) {
        this.isNFZ = this.getNfzProp()
        this.referralView = this.getReferralView()
        this.iscompletedReferralModalActive = true
        this.comment = ""
      }
    }
  },

  computed: {
    patient() {
      return this.$store.state.registration.patient;
    },

    me() {
      return this.$store.state.employee.me;
    },

    objectPresent() {
      if (this.ticket !== null) {
        return true
      } else if (this.result !== null) {
        return true
      }

      return false
    },
  },

  methods: {
    getReferralCancel() {
      this.$store
        .dispatch(Action.TPK_REFERRAL_GET, this.resultP1)
        .then((responseReferral) => {
          this.resultP1 = responseReferral;
          if (this.resultP1.eSkierowanieStatus == 3) {
            clearInterval(this.intervalCancelled);
            clearTimeout(this.timeout)
            this.successSnackbar("Anulowano realizację e-skierowania");
            this.iscompletedReferralModalActive = false;
            this.requestP1ver2.eSkierowanieDataRequest.kodSkierowania = "";

            if (this.ticket && this.ticket.id && this.ticket.referral) {
              this.ticket.referral.canceledId = responseReferral.id;
              this.ticket.referral.statusSkierowaniaP1 = responseReferral.statusSkierowaniaP1
              this.$store
                .dispatch(Action.TICKET_PUT, this.ticket)
                .then(() => {
                  this.requestInProgress = false;
                  this.successSnackbar("Zmiany zapisane");
                  this.$emit('eReferral-canceled', responseReferral)
                })
                .catch((error) => {
                  this.apiProblem(error);
                  this.requestInProgress = false;
                });
            } else if ((this.ticket && !this.ticket.id) || (this.ticket && this.ticket.id && !this.ticket.referral)) {
              if (this.ticket.referralResultsObject && this.ticket.referralResultsObject.esk) {
                this.ticket.referralResultsObject.esk.cancelledId = responseReferral.id
              }
              this.requestInProgress = false;
              this.successSnackbar("Zmiany zapisane");
              this.$emit('eReferral-canceled', responseReferral)
            } else if (this.result) {
              this.requestInProgress = false;
              this.successSnackbar("Zmiany zapisane");
              this.$emit('eReferral-canceled', responseReferral)
            }
          } else if (
            this.resultP1.eSkierowanieStatus == 4 ||
            this.resultP1.eSkierowanieStatus == 7
          ) {
            this.dangerSnackbar("Błąd zakończenia e-skierowania");
            this.$emit('eReferral-fail', responseReferral)
            this.requestInProgress = false;
            clearInterval(this.intervalCancelled);
            clearTimeout(this.timeout)
          }
        })
        .catch((error) => {
          this.apiProblem(error);
          this.requestInProgress = false;
          clearInterval(this.intervalCancelled);
          clearTimeout(this.timeout)
        });
    },

    errorP1StopDownload() {
      if (
        this.resultP1.eSkierowanieStatus != 3 &&
        this.resultP1.eSkierowanieStatus != 4 &&
        this.resultP1.eSkierowanieStatus != 7
      ) {
        this.dangerSnackbar("Błąd P1");
        this.requestInProgress = false;
      } else {
        //do nothing
      }
    },

    cancelReferral() {
      this.requestP1ver2.eSkierowanieDataRequest.eSkierowanieDataAction = 6
      this.requestP1ver2.eSkierowanieDataRequest.workerGuid = this.me.id.toString()
      this.requestP1ver2.eSkierowanieDataRequest.domainId = this.me.domainId.toString();
      this.requestP1ver2.eSkierowanieDataRequest.domain = this.me.domainId.toString();
      this.requestP1ver2.eSkierowanieDataRequest.comment = this.comment
      this.requestInProgress = true;

      if (this.ticket) {
        this.requestP1ver2.eSkierowanieDataRequest.patientId = this.me.domainId.toString() + "-" + this.ticket.patientId
        if (this.ticket.referralResultsObject && this.ticket.referralResultsObject.esk) {
          this.requestP1ver2.eSkierowanieDataRequest.kodSkierowania = this.ticket.referralResultsObject.esk.eSkierowanieKlucz
          this.requestP1ver2.eSkierowanieDataRequest.kluczSkierowania = this.ticket.referralResultsObject.esk.eSkierowanieKlucz
          this.requestP1ver2.eSkierowanieDataRequest.numerSkierowania = this.ticket.referralResultsObject.esk.number
          this.requestP1ver2.eSkierowanieDataRequest.isNFZ = this.isNFZ
          this.requestP1ver2.eSkierowanieDataRequest.authorUnitCode = this.ticket.referralResultsObject.esk.authorUnitCode ? this.ticket.referralResultsObject.esk.authorUnitCode : ""
        } else if(this.ticket.referral && this.ticket.referral.eReferral) {
          this.requestP1ver2.eSkierowanieDataRequest.kodSkierowania = this.ticket.referral.eReferral.eSkierowanieKlucz
          this.requestP1ver2.eSkierowanieDataRequest.kluczSkierowania = this.ticket.referral.eReferral.eSkierowanieKlucz
          this.requestP1ver2.eSkierowanieDataRequest.numerSkierowania = this.ticket.referral.eReferral.eSkierowanieNumer
          this.requestP1ver2.eSkierowanieDataRequest.isNFZ = this.isNFZ
          this.requestP1ver2.eSkierowanieDataRequest.authorUnitCode = this.ticket.referral.eReferral.authorUnitCode ? this.ticket.referral.eReferral.authorUnitCode : ""
        }
      } else if (this.result) {
        this.requestP1ver2.eSkierowanieDataRequest.patientId = this.patient.domainId + "-" + this.patient.id
        this.requestP1ver2.eSkierowanieDataRequest.kodSkierowania = this.result.eSkierowanieKlucz
        this.requestP1ver2.eSkierowanieDataRequest.kluczSkierowania = this.result.eSkierowanieKlucz
        this.requestP1ver2.eSkierowanieDataRequest.numerSkierowania = this.result.eSkierowanieNumer
        this.requestP1ver2.eSkierowanieDataRequest.isNFZ = this.isNFZ
        this.requestP1ver2.eSkierowanieDataRequest.authorUnitCode = this.result.authorUnitCode ? this.result.authorUnitCode : ""
      }

      this.$store
        .dispatch(Action.TPK_REFERRAL_SEND, this.requestP1ver2)
        .then((response) => {
          this.resultP1.id = response
          this.resultP1.workerGuid = this.me.id.toString();
          this.resultP1.domainId = this.me.domainId.toString();
          this.intervalCancelled = setInterval(() => this.getReferralCancel(), 4000);

          this.timeout = setTimeout(() => {
            clearInterval(this.intervalCancelled);
            this.errorP1StopDownload();
          }, 120000);

        })
        .catch((error) => {
          this.apiProblem(error);
          this.requestInProgress = false;
          clearInterval(this.intervalCancelled)
        });
    },

    getNfzProp() {
      if (this.ticket) {
        if (this.ticket.referralResultsObject) {
          return this.ticket.referralResultsObject.isNFZ
        } else if(this.ticket.referral && this.ticket.referral.eReferral) {
          return this.ticket.referral.eReferral.isNFZ
        }
      } else if (this.result) {
        return this.result.isNFZ
      }

      return false
    },

    getReferralView() {
      if (this.ticket) {
        if (this.ticket.referralResultsObject) {
          return this.ticket.referralResultsObject.fileHtml
        } else if(this.ticket.referral && this.ticket.referral.eReferral) {
          return this.ticket.referral.eReferral.eSkierowanie
        }
      } else if (this.result) {
        return this.result.eSkierowanie
      }

      return null
    }

  },
};
</script>

<style scoped lang="scss">
</style>
