<template>
  <div>
    <b-button @click="size=1" size="is-small" class="mr-1" aria-role="listitem">Mała</b-button>
    <b-button @click="size=2" size="is-small" class="mr-1" aria-role="listitem">Normalna</b-button>
    <b-button @click="size=3" size="is-small" class="mr-1" aria-role="listitem">Duża</b-button>
  </div>
</template>

<script>

export default {
  name: 'FontSizeSelectorButtons',
  data() {
    return {
      size: 2
    }
  },

  watch: {
    size() {
      switch (this.size) {
        case 1:
          document.querySelector('html').style.fontSize = '14px'
          break
        case 3:
          document.querySelector('html').style.fontSize = '20px'
          break
        default:
          document.querySelector('html').style.fontSize = '16px'
          break
      }
    }
  },
}


</script>

<style scoped lang="scss">
</style>
