<template>
  <div>
    <b-button
      v-if="!editMode"
      @click="openModal"
      :loading="isModalLoading"
      :icon-left="addButtonIcon"
      :size="buttonSize"
      :type="buttonType"
      :rounded="isButtonRounded"
      >{{ addButtonLabel }}</b-button
    >
    <b-button
      v-else
      @click="openModal"
      :loading="isModalLoading"
      :icon-left="editButtonIcon"
      :size="buttonSize"
      :type="buttonType"
      :rounded="isButtonRounded"
      >{{ editButtonLabel }}</b-button
    >
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <b-modal
        :active.sync="isModalActive"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        scroll="keep"
      >
        <div class="modal-card" style="overflow: visible">
          <header class="modal-card-head has-background-kin">
            <b-icon
              icon="file-document-edit-outline"
              class="has-text-primary mr-2"
            ></b-icon>
            <p
              class="modal-card-title has-text-primelight"
              style="line-height: 2rem"
            >
              <span v-if="!editMode">Dodaj zadanie</span>
              <span v-else>Edytuj zadanie</span>
            </p>
            <button
              type="button"
              class="delete"
              @click="isModalActive = false"
            />
          </header>
          <section class="modal-card-body" style="overflow: visible">
            <b-field>
              <b-field
                label="Status zadania"
                label-position="on-border"
                class="card p-1 pb-2 worker-task-info-comp-groups"
              >
                <b-radio
                  v-for="(item, item_index) in mWorkerTaskInfoStatuses"
                  :key="item_index"
                  v-model="newTask.status"
                  :native-value="item.id"
                  :type="item_index == 2 ? 'is-success' : 'is-primary'"
                  class="mt-2"
                  :class="{
                    'ml-2': item_index == 0,
                    'mr-1': item_index == 2,
                  }"
                  >{{ item.name }}</b-radio
                >
              </b-field>
              <b-field
                label="Priorytet"
                label-position="on-border"
                class="card p-1 pb-2 ml-3 worker-task-info-comp-groups"
              >
                <b-radio
                  v-for="(item, item_index) in mWorkerTaskInfoPriorityTypes"
                  :key="item_index"
                  v-model="newTask.priority"
                  :native-value="item.id"
                  :type="item_index == 0 ? 'is-primary' : 'is-danger'"
                  :class="{
                    'ml-2 mt-2': item_index == 0,
                    'mt-2 mr-1': item_index == 1,
                  }"
                  >{{ item.name }}</b-radio
                >
              </b-field>
            </b-field>
            <hr class="m-0 p-0 mb-3" />
            <ValidationProvider rules="required" v-slot="{ errors, valid }">
              <b-field
                label="Treść zadania"
                label-position="on-border"
                :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                :message="t(errors[0])"
              >
                <b-input
                  v-model="newTask.content"
                  placeholder="Treść zadania"
                  type="textarea"
                  :rows="2"
                ></b-input>
              </b-field>
            </ValidationProvider>
            <hr class="m-0 p-0 mb-3" />
            <b-field>
              <b-field
                label="Realizacja od"
                label-position="on-border"
                style="width: 50%"
              >
                <b-datetimepicker
                  v-model="newTask.startDate"
                  placeholder="Wybierz"
                ></b-datetimepicker>
              </b-field>
              <b-field
                label="Realizacja do"
                label-position="on-border"
                style="width: 50%"
              >
                <b-datetimepicker
                  v-model="newTask.endDate"
                  placeholder="Wybierz"
                ></b-datetimepicker>
              </b-field>
            </b-field>
            <b-field>
              <b-field
                label="Planowany termin ukończenia"
                label-position="on-border"
                style="width: 50%"
              >
                <b-datetimepicker
                  v-model="newTask.finishDate"
                  placeholder="Wybierz"
                ></b-datetimepicker>
              </b-field>
              <b-field
                label="Deadline"
                label-position="on-border"
                style="width: 50%"
              >
                <b-datetimepicker
                  v-model="newTask.deadlineDate"
                  placeholder="Wybierz"
                ></b-datetimepicker>
              </b-field>
            </b-field>
            <hr class="m-0 p-0 mb-3" />
            <div style="display: flex" class="mb-3">
              <b-checkbox
                v-model="isTaskPatientRelated"
                :native-value="0"
                type="is-primary"
                class="ml-3 has-text-weight-medium"
                style="width: 60%"
                >Zadanie powiązane z pacjentem</b-checkbox
              >
              <div
                v-if="isTaskPatientRelated"
                style="width: 40%"
                class="is-pulled-right"
              >
                <PatientSearch
                  @selected="setPatient"
                  :buttonPharse="'Wyszukaj pacjenta'"
                  :icon="'magnify'"
                  :size="'is-small'"
                  class="is-pulled-right"
                />
              </div>
            </div>
            <div v-if="isTaskPatientRelated && selectedPatient">
              <table class="table fs09 mb-3" style="width: 100%">
                <tr>
                  <th class="has-text-centered">ID</th>
                  <th class="has-text-centered">Domena</th>
                  <th class="has-text-centered">PESEL</th>
                  <th class="has-text-centered">Nr dokumentu</th>
                  <th class="has-text-centered">Imię i nazwisko</th>
                </tr>
                <tr>
                  <td class="has-text-centered">{{ selectedPatient.id }}</td>
                  <td class="has-text-centered">
                    {{ mGetDomainName(selectedPatient.domainId) }}
                  </td>
                  <td class="has-text-centered">{{ selectedPatient.pesel }}</td>
                  <td class="has-text-centered">
                    <span
                      v-if="
                        selectedPatient.identityDocumentNumber != null &&
                        selectedPatient.identityDocumentNumber != ''
                      "
                      >{{ selectedPatient.identityDocumentNumber }}</span
                    >
                    <span v-else>-</span>
                  </td>
                  <td class="has-text-centered">
                    {{ selectedPatient.firstName }}
                    {{ selectedPatient.lastName }}
                  </td>
                </tr>
              </table>
            </div>
            <hr class="m-0 p-0 mb-3" />
            <b-field class="pb-3">
              <b-field>
                <b-autocomplete
                  v-model="searchWorkerName"
                  :data="filteredEmployees"
                  clearable
                  open-on-focus
                  clear-on-select
                  field="name"
                  placeholder="Dodaj pracownika do zadania"
                  :keep-first="true"
                  @select="(option) => addWorker(option)"
                >
                  <template slot-scope="props">
                    <span class="has-text-weight-medium">
                      {{ props.option.name }}
                    </span>
                    ({{ props.option.email }})
                  </template>
                </b-autocomplete>
              </b-field>
            </b-field>
            <div class="mb-3">
              <div
                v-for="(worker, worker_index) in newTask.roles"
                :key="worker_index"
                class="columns"
              >
                <div class="column is-5">
                  <p class="ml-4">
                    {{ worker_index + 1 }}.
                    <span class="has-text-weight-medium">
                      {{ getWorkerName(worker.workerId) }}
                      <b-button
                        @click="removeWorker(worker_index)"
                        class="is-pulled-right"
                        style="margin-top: -5px"
                        type="is-danger"
                        size="is-small"
                        outlined
                        rounded
                        >Usuń</b-button
                      >
                    </span>
                  </p>
                </div>
                <div class="column is-7 py-0">
                  <b-field>
                    <b-select
                      v-model="worker.role"
                      placeholder="Wybierz rolę pracownika"
                      expanded
                    >
                      <option
                        v-for="(role, role_index) in mWorkerTaskInfoRoles"
                        :key="role_index"
                        :value="role.id"
                      >
                        ({{ role.acronym }}) {{ role.description }}
                      </option>
                    </b-select>
                    <b-tooltip label="Usuń wybraną rolę">
                      <b-button
                        @click="worker.role = null"
                        :disabled="!worker.role"
                        icon-left="close"
                      ></b-button>
                    </b-tooltip>
                  </b-field>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot buttons is-right">
            <b-button
              v-if="!editMode"
              @click="addTask"
              :disabled="invalid"
              :loading="saveLoading"
              type="is-success"
              icon-left="check-all"
              >Dodaj zadanie</b-button
            >
            <b-button
              v-else
              @click="editTask"
              :disabled="invalid"
              :loading="saveLoading"
              type="is-success"
              icon-left="check-all"
              >Zapisz zmiany</b-button
            >
            <b-button icon-left="close" @click="isModalActive = false"
              >Anuluj</b-button
            >
          </footer>
        </div>
      </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>

import moment from 'moment'
import { Action } from '@/store/config_actions'

import CommonMixins from '@/mixins/commons'
import DomainsMixins from "@/mixins/domains"
import ProjectsMixins from "@/mixins/management/projects"

import { ValidationProvider, ValidationObserver } from "vee-validate"
import PatientSearch from '@/components/patient/PatientPanelSearch2'

export default {
  name: "WorkerTaskInfo",
  components: { ValidationProvider, ValidationObserver, PatientSearch },
  mixins: [CommonMixins, DomainsMixins, ProjectsMixins],

  props: {
    addButtonLabel: {
      type: String,
      required: false,
      default: "Dodaj zadanie"
    },
    editButtonLabel:
    {
      type: String,
      required: false,
      default: "Edytuj zadanie"
    },
    addButtonIcon: {
      type: String,
      required: false,
      default: "plus"
    },
    editButtonIcon: {
      type: String,
      required: false,
      default: "pencil"
    },
    buttonSize: {
      type: String,
      required: false,
      default: "is-small"
    },
    buttonType: {
      type: String,
      required: false,
      default: "is-primary"
    },
    isButtonRounded: {
      type: Boolean,
      required: false,
      default: false
    },
    workerId: {
      type: Number,
      required: false,
      default() {
        return -1
      }
    },
    startDate: {
      type: Date,
      required: false,
      default() {
        return moment().toDate()
      }
    },
    finishDate: {
      type: Date,
      required: false,
      default() {
        return moment().add(1, 'days').toDate()
      }
    },
    // Task to edit
    task: {
      type: Object,
      required: false,
      default: null
    }
  },

  watch: {},

  mounted() {
    if (this.task) {
      this.editMode = true
      this.newTask = this.task
      this.newTask.deadlineDate = new Date(this.newTask.deadlineDate)
      this.newTask.startDate = new Date(this.newTask.startDate)
      this.newTask.finishDate = new Date(this.newTask.finishDate)
      this.newTask.endDate = new Date(this.newTask.endDate)
      if (this.task.relatedPatientId > 0) {
        this.isTaskPatientRelated = true
      }
    }

    if (this.workerId > 0) {
      let employee = this.employees.find(x => x.id == this.workerId)

      if (employee) {
        this.addWorker(employee)
      }
    }
  },

  computed: {
    me() { return this.$store.state.employee.me },
    employees() { return this.$store.state.employee.all },
    filteredEmployees() {
      if (this.employees) {
        return this.employees.filter((option) => {
          if (option.name) {
            return (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.searchWorkerName.toLowerCase()) >= 0
            )
          } else return false
        })
      } else return []
    }
  },

  data: function () {
    return {
      isModalActive: false,
      editMode: false,
      isTaskPatientRelated: false,
      selectedPatient: null,
      searchWorkerName: "",
      saveLoading: false,
      isModalLoading: false,
      /**Obiekt nowego zadania
       * New task object
       */
      newTask: {
        content: "",
        priority: 0,
        roles: [],
        deadlineDate: this.finishDate,
        startDate: this.startDate,
        finishDate: this.finishDate,
        endDate: this.finishDate,
        relatedPatientId: 0,
        domainId: 0,
        status: 0,
        createdBy: 0,
        created: null,
        updatedBy: 0,
        updated: null
      }
    }
  },

  methods: {
    /**Metoda odpowiadająca za otwarcie modala dodawania/edycji zadania
      * Method for open add/edit task modal
      */
    openModal() {
      if (!this.editMode) {
        this.newTask.domainId = this.me.domainId
        this.isModalActive = true
      } else {
        this.newTask.roles.forEach(element => {
          if (element.role == -1) {
            element.role = null
          }
        })
        if (this.editMode && this.isTaskPatientRelated) {
          this.isModalLoading = true
          this.$store
            .dispatch(Action.PATIENT_GET_BY_ID, this.newTask.relatedPatientId)
            .then((response) => {
              this.selectedPatient = response.data
              this.isModalLoading = false
              this.isModalActive = true
            })
            .catch((error) => {
              this.snackbar(error)
              this.isModalLoading = false
            })
        } else {
          this.isModalActive = true
        }
      }
    },
    /**Metoda odpowiadająca za dodanie pracownika do zadania
      * Method for add worker to task
      */
    addWorker(worker) {
      if (worker) {
        let newWorker = JSON.parse(JSON.stringify(this.mWorkerTaskInfoNewRoleObject))
        newWorker.workerId = worker.id
        this.newTask.roles.push(newWorker)
        this.searchWorkerName = ""
      }
    },
    /**Metoda odpowiadająca za dodanie nowego zadania
       * Method for add new task
       */
    addTask() {
      this.saveLoading = true

      if (this.isTaskPatientRelated && this.selectedPatient) {
        this.newTask.relatedPatientId = this.selectedPatient.id
      }

      this.newTask.roles.forEach(element => {
        if (element.role == null) {
          element.role = -1
        }
      })

      this.newTask.createdBy = this.me.id
      this.newTask.created = this.mNow()
      this.newTask.updatedBy = this.me.id
      this.newTask.updated = this.mNow()

      this.$store
        .dispatch(Action.EMPLOYEE_TASK_INFO_ADD, this.newTask)
        .then(() => {
          this.resetTask()
          this.selectedPatient = null
          this.saveLoading = false
          this.isModalActive = false
        })
        .catch((error) => {
          this.saveLoading = false
          this.snackbar(error)
        })
    },
    /**Metoda odpowiadająca za zapis edytowanego zadania
       * Method for save edited task
       */
    editTask() {
      this.saveLoading = true

      if (this.isTaskPatientRelated && this.selectedPatient) {
        this.newTask.relatedPatientId = this.selectedPatient.id
      }

      this.newTask.roles.forEach(element => {
        if (element.role == null) {
          element.role = -1
        }
      })

      this.newTask.updatedBy = this.me.id
      this.newTask.updated = this.mNow()

      this.$store
        .dispatch(Action.EMPLOYEE_TASK_INFO_PUT, this.newTask)
        .then(() => {
          this.$emit("task-edited")
          this.selectedPatient = null
          this.saveLoading = false
          this.isModalActive = false
        })
        .catch((error) => {
          this.saveLoading = false
          this.snackbar(error)
        })
    },
    /**Metoda odpowiadająca za pobranie nazwiska i imienia pracownika po id
       * Method for getting lastName and firstName of worker by id
       */
    getWorkerName(id) {
      if (this.employees) {
        let found = this.employees.find((e) => e.id == id)
        if (found) {
          return found.lastName + " " + found.firstName
        } else return "-"
      }
    },
    /**Metoda odpowiadająca za reset obiektu nowego zadania
       * Method for reset new task object
       */
    resetTask() {
      this.newTask = {
        content: "",
        priority: 0,
        roles: [],
        deadlineDate: moment().add(1, 'days').toDate(),
        startDate: moment().toDate(),
        finishDate: moment().add(1, 'days').toDate(),
        endDate: moment().add(1, 'days').toDate(),
        relatedPatientId: 0,
        domainId: 0,
        status: 0,
        createdBy: 0,
        created: null,
        updatedBy: 0,
        updated: null
      }
    },
    /**Metoda odpowiadająca za zapis obiektu wybranego pacjenta do zmiennej
       * Method for save selected patient object to variable
       */
    setPatient(patient) {
      this.selectedPatient = patient
    },
    /**Metoda odpowiadająca za usuwanie pracownika z zadania
       * Method for remove worker from task
       */
    removeWorker(index) {
      this.$buefy.dialog.confirm({
        message: "Czy na pewno chcesz usunąć wybranego pracownika z zadania?",
        hasIcon: true,
        icon: "chat-question-outline",
        confirmText: "Tak, usuń",
        cancelText: "Anuluj",
        type: "is-danger",
        onConfirm: () => {
          this.newTask.roles.splice(index, 1)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.worker-task-info-comp-groups .label {
  background-color: white !important;
}
</style>
<style lang="scss" scoped>
.fs09 {
  font-size: 0.9rem;
}
</style>