<template>
  <div>
    <form v-if="patient">
      <ValidationObserver ref="observer" v-slot="{invalid}">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-6-fullhd">
            <p class="subtitle mb-4 has-text-primary">
              <b-icon icon="account" class="mr-2"></b-icon>Dane osobowe
            </p>
            <!-- FIRST NAME -->
            <b-field grouped group-multiline>
              <b-field class="pb-3">
                <ValidationProvider rules="required|surname" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.personal.firstName')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input v-model="patient.firstName"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <!-- LAST NAME -->
              <b-field expanded class="pb-3">
                <ValidationProvider rules="required|surname" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.personal.lastName')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input v-model="patient.lastName"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>

            <b-field grouped group-multiline>
              <!-- SECOND NAME -->
              <b-field class="pb-3">
                <ValidationProvider rules="surname" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.personal.secondName')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input v-model="patient.secondName"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>

              <!-- FAMILY NAME -->
              <b-field expanded class="pb-3">
                <ValidationProvider rules="surname" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.personal.familyName')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input v-model="patient.familyName"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>

            <b-field grouped group-multiline>
              <!-- PESEL -->
              <b-field class="pb-3">
                <ValidationProvider mode="eager" rules="pesel" v-slot="{ errors, valid }">
                  <b-field
                    :label="mothers ? 'PESEL matki' : 'PESEL pacjenta'"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input v-model="patient.pesel"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field>
                <b-checkbox v-model="mothers" type="is-danger">
                  Noworodek
                  <b-tooltip
                    label="UWAGA! Informacja tymczasowo nie jest zapisywana w bazie danych"
                  >
                    <u>(?)</u>
                  </b-tooltip>
                </b-checkbox>
              </b-field>
            </b-field>

            <b-field grouped group-multiline>
              <!-- DATE OF BIRTH -->
              <b-field class="pb-3">
                <b-field :label="t('tools.personal.dateOfBirth')" label-position="on-border">
                  <b-datepicker
                    v-model="dateOfBirth"
                    :placeholder="t('common.choose')"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :focused-date="maxDate"
                    :month-names="monthNames"
                    :day-names="dayNamesShort"
                    trap-focus
                    @input="onBirthdateChanged"
                  ></b-datepicker>
                </b-field>
              </b-field>

              <!-- GENDER -->
              <b-field expanded class="pb-3">
                <b-field :label="t('tools.personal.gender')" label-position="on-border">
                  <b-select v-model="patient.gender" :placeholder="t('common.choose')" expanded>
                    <option value="k">{{ t('tools.personal.genderFemale') }}</option>
                    <option value="m">{{ t('tools.personal.genderMale') }}</option>
                    <!-- <option value="u">{{ t('tools.personal.genderOther') }}</option> -->
                  </b-select>
                </b-field>
              </b-field>
            </b-field>
            <b-field grouped group-multiline>
              <!-- IDENTITY DOCUMENT -->
              <b-field expanded class="pb-3">
                <b-field :label="t('tools.personal.identityDocument')" label-position="on-border">
                  <b-select
                    expanded
                    v-model="patient.identityDocument"
                    :placeholder="t('common.choose')"
                  >
                    <option :value="0">Brak</option>
                    <option :value="1">Dowód osobisty</option>
                    <option :value="2">Paszport</option>
                    <option :value="3">Karta pobytu</option>
                    <option :value="4">Dokument podróży</option>
                    <option :value="5">EKUZ</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- DOCUMENT NUMBER -->
              <b-field expanded class="pb-3" v-if="patient.identityDocument != 5">
                <ValidationProvider rules="alpha_num" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.personal.identityDocumentNumber')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      :disabled="patient.identityDocument === 0"
                      v-model="patient.identityDocumentNumber"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field expanded v-else label="Karta EKUZ" label-position="on-border">
                <b-input v-model="patient.ekuzCard"></b-input>
              </b-field>
            </b-field>
            <b-field label="Dodatkowe informacje" label-position="on-border">
              <b-input v-model="patient.description"></b-input>
            </b-field>

            <p class="subtitle mb-4 has-text-primary">
              <b-icon icon="phone" class="mr-2"></b-icon>Kontakt
            </p>
            <b-field grouped group-multiline>
              <!-- PREFFERED CONTACT -->
              <b-field
                class="pb-3"
                label="Preferowany sposób kontaktu"
                expanded
                label-position="on-border"
              >
                <b-select v-model="patient.preferredContact" expanded>
                  <option :value="-1">Nieokreślony</option>
                  <option :value="0">Telefonicznie</option>
                  <option :value="1">SMS</option>
                  <option :value="2">Email</option>
                  <option :value="3">Portal pacjenta</option>
                  <option :value="4">Listownie</option>
                </b-select>
              </b-field>
              <!-- E-MAIL -->
              <b-field expanded class="pb-3">
                <ValidationProvider mode="eager" rules="email" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.address.email')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input v-model="patient.email"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>

            <b-field grouped group-multiline>
              <!-- TELEPHONE NUMBER -->
              <b-field class="pb-3">
                <ValidationProvider mode="eager" rules="telephone" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.address.telephoneNumber')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input v-model="patient.teleAddressTelephoneNumber"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>

              <!-- PREFFERED CONTACT -->
              <b-field class="pb-3" expanded label="Właściciel numeru" label-position="on-border">
                <b-select expanded @input="checkOtherOwner" v-model="patient.phoneNumberOwner">
                  <option value="pacjent" selected>pacjent</option>
                  <!--<option value="matka">matka</option>
                  <option value="ojciec">ojciec</option>
                  <option value="siostra">siostra</option>
                  <option value="brat">brat</option>
                  <option value="dziadek">dziadek</option>
                  <option value="babcia">babcia</option>
                  <option value="pradziadek">pradziadek</option>
                  <option value="prababcia">prababcia</option>
                  <option value="wnuk">wnuk</option>
                  <option value="wnuczka">wnuczka</option>
                  <option value="prawnuk">prawnuk</option>
                  <option value="prawnuczka">prawnuczka</option> -->                  
                  <option value="krewny">krewny</option>
                  <option value="inna osoba bliska">inna osoba bliska</option>
                  <option :value="''">inny</option>
                  
                </b-select>
              </b-field>

              <b-field
                label="Inny właściciel"
                expanded
                class="pb-3"
                label-position="on-border"
                v-if="otherOwner"
              >
                <b-input v-model="patient.phoneNumberOwner"></b-input>
              </b-field>
            </b-field>
          </div>
          <div class="column is-12-mobile is-6-fullhd">
            <p class="subtitle mb-4 has-text-primary">
              <b-icon icon="home" class="mr-2"></b-icon>Adres zamieszkania
            </p>
            <b-field grouped group-multiline>
              <!-- STREET -->
              <b-field expanded class="pb-3">
                <ValidationProvider
                  rules="alpha_num_space_slash|length:0,100"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    :label="t('tools.address.street')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input v-model="patient.addressStreet" :has-counter="false" maxlength="100"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>

              <!-- HOUSE NUMBER -->
              <b-field class="pb-3">
                <ValidationProvider rules="alpha_num|length:0,5" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.address.houseNumber')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      v-model="patient.addressHouseNumber"
                      :has-counter="false"
                      maxlength="5"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>

              <!-- APARTMENT -->
              <b-field class="pb-3">
                <ValidationProvider rules="alpha_num|length:0,5" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.address.apartmentNumber')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      v-model="patient.addressApartmentNumber"
                      :has-counter="false"
                      maxlength="5"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>

            <b-field grouped group-multiline>
              <!-- ZIP CODE -->
              <b-field class="pb-3">
                <ValidationProvider rules="zip_code" mode="eager" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.address.zipCode')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input v-model="patient.addressZipCode" :has-counter="false" maxlength="6"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>

              <!-- CITY -->
              <b-field expanded class="pb-3">
                <ValidationProvider
                  rules="alpha_space_slash|length:0,100"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    expanded
                    :label="t('tools.address.city')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      expanded
                      v-model="patient.addressCity"
                      :has-counter="false"
                      maxlength="100"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>

            <!-- COUNTRY -->
            <b-field>
              <ValidationProvider rules="alpha|length:0,50" v-slot="{ errors, valid }">
                <b-field
                  :label="t('tools.address.country')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                  :message="t(errors[0])"
                >
                  <b-input v-model="patient.addressCountry" :has-counter="false" maxlength="50"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>

            <p class="subtitle mb-4 has-text-primary">
              <b-icon icon="mail" class="mr-2"></b-icon>Adres korespondencyjny
            </p>
            <b-field grouped group-multiline>
              <!-- STREET -->
              <b-field expanded class="pb-3">
                <ValidationProvider
                  rules="alpha_num_space_slash|length:0,100"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    :label="t('tools.address.street')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      v-model="patient.teleAddressStreet"
                      :has-counter="false"
                      maxlength="100"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>

              <!-- HOUSE NUMBER -->
              <b-field class="pb-3">
                <ValidationProvider rules="alpha_num|length:0,5" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.address.houseNumber')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      v-model="patient.teleAddressHouseNumber"
                      :has-counter="false"
                      maxlength="5"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>

              <!-- APARTMENT -->
              <b-field class="pb-3">
                <ValidationProvider rules="alpha_num|length:0,5" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.address.apartmentNumber')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      v-model="patient.teleAddressApartmentNumber"
                      :has-counter="false"
                      maxlength="5"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>

            <b-field grouped group-multiline>
              <!-- ZIP CODE -->
              <b-field class="pb-3">
                <ValidationProvider rules="zip_code" mode="eager" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('tools.address.zipCode')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      v-model="patient.teleAddressZipCode"
                      :has-counter="false"
                      maxlength="6"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>

              <!-- CITY -->
              <b-field expanded class="pb-3">
                <ValidationProvider
                  rules="alpha_space_slash|length:0,100"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    expanded
                    :label="t('tools.address.city')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      expanded
                      v-model="patient.teleAddressCity"
                      :has-counter="false"
                      maxlength="100"
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>

            <!-- COUNTRY -->
            <b-field>
              <ValidationProvider rules="alpha|length:0,50" v-slot="{ errors, valid }">
                <b-field
                  :label="t('tools.address.country')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-normal': valid }"
                  :message="t(errors[0])"
                >
                  <b-input v-model="patient.teleAddressCountry" :has-counter="false" maxlength="50"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field v-if="readonly === undefined || readonly === false">
              <b-button
                expanded
                type="is-primary"
                class="mr-3"
                :disabled="invalid || idMissing"
                @click="sendPatient"
                :loading="isLoading"
                icon-left="checkbox-marked-circle-outline"
              >Zapisz dane pacjenta</b-button>
              <b-button icon-left="close" @click="resetForm">Resetuj</b-button>
            </b-field>
            <b-notification
              v-if="idMissing"
              :closable="false"
              class="has-text-primary has-text-weight-semibold"
            >
              <b-icon icon="chat-alert" class="mr-4"></b-icon>Numer PESEL lub numer dokumentu tożsamości jest wymagany
            </b-notification>
          </div>
        </div>
      </ValidationObserver>
      <small>Wersja danych: {{patient.version}}</small>
    </form>
  </div>
</template>

<script>

import PatientMixin from '@/mixins/patient/patient_resource'
import DateHalpers from '@/mixins/date_helpers'
import CommonsHalpers from '@/mixins/commons'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Action } from '../../store/config_actions';
import moment from 'moment'

export default {
  name: 'PatientPanelModifier',
  mixins: [CommonsHalpers, PatientMixin, DateHalpers],
  components: { ValidationProvider, ValidationObserver },

  data: function () {
    const today = new Date()
    return {
      patient: null,
      isLoading: false,
      dateOfBirth: new Date(),
      maxDate: new Date(today),
      minDate: new Date(1920, 0, 0),
      otherOwner: false,
      mothers: false,
    }
  },

  props: {
    initial: { type: Object, required: true },
    readonly: { type: Boolean, required: false }
  },

  mounted() {
    this.resetPatient();
  },

  computed: {
    idMissing() {
      if (this.patient) {
        return this.patient.pesel.length === 0 && this.patient.identityDocumentNumber.length === 0
      } else return true
    },

    me() { return this.$store.state.employee.me }
  },

  methods: {
    sendPatient() {

      this.$buefy.dialog.confirm({
        title: `Modyfikacja danych pacjenta`,
        message: `<p class="subtitle mb-1">${this.patient.lastName} ${this.patient.firstName}</p>Czy na pewno zapisać nowe dane pacjenta?`,
        confirmText: 'Tak, zapisz',
        cancelText: 'Anuluj',
        type: 'is-info',
        hasIcon: true,
        scroll: 'keep',
        onConfirm: () => {
          this.patient.dateOfBirth = this.mDateTimeRaw(this.dateOfBirth)

          this.isLoading = true
          this.$store
            .dispatch(Action.PATIENT_MODIFY, this.patient)
            .then((response) => {

              let updated = response.data

              this.mCopy(updated, this.patient)
              this.$emit('updated', updated)

              this.snackbar('Dane zapisane')
              this.isLoading = false
            }).catch(error => {
              this.apiProblem(error);
              this.isLoading = false
            });
        }
      })
    },

    checkOtherOwner(val) {
      this.otherOwner = val === ''
    },

    resetPatient() {
      this.patient = JSON.parse(JSON.stringify(this.initial))
      this.dateOfBirth = moment(this.initial.dateOfBirth).toDate()

      this.$nextTick(() => {
        if (this.$refs.observer) {
          this.$refs.observer.validate()
        }
      })
    },

    onBirthdateChanged(value) {
      this.patient.dateOfBirth = this.mDateTimeRaw(value)
    },

    resetForm() {
      this.resetPatient()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
