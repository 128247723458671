//import Name from '@/components/Name.vue';
const goodEwus = [1,2,8,9,10,11]
const serializableEwus = [1, 2, 3, 4, 8, 9, 10, 11]
export default {
    methods: {
        mCheckEwus(ewusObject){
            if(!ewusObject) return false
            if(!ewusObject.ewusState) return false
            return this.mEwusStatusIsGood(ewusObject.ewusState.ewusStatus)
        },

        mCheckEwusSerializable(ewusObject) {
            if(!ewusObject) return false
            if(!ewusObject.ewusState) return false
            return this.mEwusStatusIsSerializable(ewusObject.ewusState.ewusStatus)
        },

        mEwusStatusIsGood(ewusStatus){
            if(goodEwus.includes(ewusStatus)) return true
            else return false
        },

        mEwusStatusIsSerializable(ewusStatus){
            if(serializableEwus.includes(ewusStatus)) return true
            else return false
        }
    }
};