import axios from 'axios'
import cookies from 'vue-cookies'
//import router from '../../router/router'
import moment from 'moment'

import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Cookies } from '../config_cookies'
import { Apis } from '../config_apis'

const SESSION_TIME_SEC = 900
const TIME_TO_RENEW_TOKEN_MILIS = 60000



export default {
    namespaced: false,

    state: {
        /** Current user session info. */
        session: null,

        /** When session ends */
        sessionEnd: new Date().getTime() + (SESSION_TIME_SEC * 1000),

        /** When cookie ends */
        cookieEnd: new Date().getTime() + (SESSION_TIME_SEC * 1000),

        /** Seconds to session end */
        sessionEndsIn: null,

        /** Seconds to cookie end */
        cookieEndsIn: null,

        /** Roles available for accounts */
        availableRoles: null,

        /** Cache of users */
        users: null,

        loggingOut: false,
        routerLoading: false,
        routerLabel: '',
        authTrigger: 0,

        permissions: {
            admin: false,
            pozOffice: false,
            pharmaOffice: false,
            nurseOffice: false,
            registration: false,
            callCenter: false,
            eLearning: false,
            patientPortal: false,
            treatmentCoordination: false,
            regionManagement: false,
            clusterManagement: false,
            mobi: false,
            orders: false,
            inventory: false,
            eLearningEditor: false,
            eLearningSuperEditor: false,
            collectionPoint: false,
            rehabilitation: false
        }
    },

    getters: {
        isUserAuthorized: state => {
            if (state.session != null) {
                if (state.session.token != null) {
                    return true
                }
            }

            return false
        },

        getMyRoles: state => {
            if (state.session != null) return state.session.roles
            else return []
        },

        isAdmin: state => {

            if (state.session != null) {
                if (state.session.roles != null) {
                    if (state.session.roles.includes("Admin")) {
                        return true
                    }
                }
            }
            else return false
        }
    },

    mutations: {
        /** Sets user info. */
        [Mutation.IDENTITY_OPEN_SESSION](currentState, user) {
            currentState.availableRoles = null
            currentState.session = user
            currentState.sessionEnd = user.ends
            currentState.cookieEnd = user.ends









            // OLD - OBSOLETE - WILL BE REMOVED SOON
            let isAdmin = user.roles.includes('Admin')
            currentState.permissions.admin = isAdmin
            currentState.permissions.pozOffice = isAdmin || user.roles.includes('Doctor')
            currentState.permissions.pharmaOffice = isAdmin || user.roles.includes('Pharmacist')
            currentState.permissions.nurseOffice = isAdmin || user.roles.includes('Nurse')
            currentState.permissions.registration = isAdmin || user.roles.includes('RagistrationWorker') || user.roles.includes('Pharmacist') || user.roles.includes('Doctor') || user.roles.includes('Nurse')
            currentState.permissions.callCenter = isAdmin || user.roles.includes('CallCenterWorker') || user.roles.includes('CallCenterManager')
            currentState.permissions.eLearning = isAdmin || user.roles.includes('Admin')
            currentState.permissions.patientPortal = true // isAdmin || user.roles.includes('Admin')
            currentState.permissions.treatmentCoordination = isAdmin || user.roles.includes('Admin')
            currentState.permissions.regionManagement = isAdmin || user.roles.includes('Admin')
            currentState.permissions.clusterManagement = isAdmin || user.roles.includes('Admin')
            currentState.permissions.mobi = isAdmin || user.roles.includes('MobiManager')
            currentState.permissions.orders = isAdmin || user.roles.includes('OrdersOperator')
            currentState.permissions.inventory = isAdmin || user.roles.includes('Admin')
            currentState.permissions.eLearningEditor = isAdmin || user.roles.includes('ELearningSuperEditor') || user.roles.includes('ELearningEditor')
            currentState.permissions.eLearningSuperEditor = isAdmin || user.roles.includes('ELearningSuperEditor')
            currentState.permissions.collectionPoint = isAdmin || user.roles.includes('Nurse')
            currentState.permissions.rehabilitation = isAdmin


            currentState.authTrigger++
        },

        /** Sets user info. */
        [Mutation.IDENTITY_TIMER_UPDATE](currentState, time) {
            currentState.cookieEnd = currentState.sessionEndsIn = time
            currentState.session.activity--
        },

        [Mutation.IDENTITY_SET_TIMER](currentState, time) {
            currentState.session.activity = time
        },

        /** Sets user info. */
        [Mutation.IDENTITY_RENEW_USER](currentState, user) {
            let newSession = user

            var cookieExpiry = newSession.expires + 's'
            cookies.set(Cookies.SZOK_USER_AUTH, newSession, cookieExpiry);

            newSession.activity = currentState.session.activity
            currentState.session = newSession
            currentState.cookieEnd = newSession.ends
        },

        /** Removes user info. This should lead to automatic logout. */
        [Mutation.IDENTITY_CLOSE_SESSION]() {
            // currentState.loggingOut = true
            // currentState.session = null
            // currentState.users = null
            // currentState.sessionEnd = new Date().getTime() + (SESSION_TIME_SEC * 1000)
            // currentState.cookieEnd = new Date().getTime() + (SESSION_TIME_SEC * 1000)
            // currentState.sessionEndsIn = null
            // currentState.cookieEndsIn = null

            // currentState = {
            //     admin: false,
            //     pozOffice: false,
            //     pharmaOffice: false,
            //     nurseOffice: false,
            //     registration: false,
            //     callCenter: false,
            //     eLearning: false,
            //     patientPortal: false,
            //     treatmentCoordination: false,
            //     regionManagement: false,
            //     clusterManagement: false,
            //     mobi: false,
            //     orders: false,
            //     inventory: false,
            //     eLearningEditor: false,
            //     eLearningSuperEditor: false
            // }

            cookies.remove('SZOK_USER_AUTH');
        },

        /** Update session time. */
        [Mutation.IDENTITY_SESSION_UPDATE_TIME](currentState, now) {
            currentState.sessionEndsIn = Math.round((currentState.sessionEnd - now) / 1000)
            currentState.cookieEndsIn = Math.round((currentState.cookieEnd - now) / 1000)
        },

        /** Removes user info. This should lead to automatic logout. */
        [Mutation.IDENTITY_SESSION_RESET_TIMER](currentState) {
            if (currentState.session) {
                currentState.sessionEnd = new Date().getTime() + (currentState.session.expires * 1000)
                currentState.session.activity = currentState.session.expires
            }
        },

        /** Removes user info. This should lead to automatic logout. */
        [Mutation.IDENTITY_PUSH_NEW_USER](currentState, user) {
            if (currentState.users != null) currentState.users.push(user)
        },

        /** Removes user info. This should lead to automatic logout. */
        [Mutation.IDENTITY_SET_ROLES](currentState, roles) {
            currentState.availableRoles = roles
        },

        /** Removes user info. This should lead to automatic logout. */
        [Mutation.IDENTITY_SET_ALL_USERS](currentState, users) {
            currentState.users = users
        },

        /** Removes user info. This should lead to automatic logout. */
        [Mutation.IDENTITY_DELETE_USER](currentState, indexOfUser) {
            currentState.users.splice(indexOfUser, 1)
        },

        /** Removes user info. This should lead to automatic logout. */
        [Mutation.IDENTITY_ENABLE_USER](currentState, indexOfUser) {
            currentState.users[indexOfUser].isActive = true
        },

        /** Removes user info. This should lead to automatic logout. */
        [Mutation.IDENTITY_DISABLE_USER](currentState, indexOfUser) {
            currentState.users[indexOfUser].isActive = false
        },

        /** Removes user info. This should lead to automatic logout. Email is rewritten for event trigger. */
        [Mutation.IDENTITY_EDIT_USER](currentState, payload) {
            // Property rewrite (insted of splice) for data change trigger without component re-render.
            currentState.users[payload.indexOfUser].username = payload.modifiedUser.username
            currentState.users[payload.indexOfUser].email = payload.modifiedUser.email
            currentState.users[payload.indexOfUser].roles = payload.modifiedUser.roles

            // Splice couses component re-render, resetting filters.
            // currentState.users.splice(payload.indexOfUser, 1, payload.modifiedUser)
        },

        ROUTER_SWITCH(currentState, value) {
            currentState.routerLoading = value
        },
        ROUTER_LABEL(currentState, value) {
            currentState.routerLabel = value
        },
    },

    actions: {

        /** Atttempts user login. */
        [Action.IDENTITY_LOGIN](context, credentials) {
            context.commit(Mutation.IDENTITY_SESSION_RESET_TIMER)
            context.commit(Mutation.WORKERS_CLEAR_MODULE)
            context.commit(Mutation.MOBI_RESET)
            context.commit(Mutation.POZ_RESET)
            context.commit(Mutation.EMPLOYEE_CLEAR)

            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.IDENTITY_LOGIN,
                    data: credentials
                }).then(response => {
                    var added = new Date().getTime() + (response.data.expires * 1000)
                    var until = moment().add(response.data.expires, 'seconds')

                    var cookie = {
                        token: response.data.token,
                        email: response.data.email,
                        name: response.data.name,
                        roles: response.data.roles,
                        expires: response.data.expires,
                        ends: added,
                        until: until,
                        activity: response.data.expires
                    }

                    var cokstring = JSON.stringify(cookie)

                    if (cokstring.length > 3700) {
                        reject('Długość tokena. Prosimy o kontakt z Adminem.')
                        let token = response.data.token
                        axios({
                            url: Apis.IDENTITY_LOGOUT,
                            method: 'get',
                            headers: { Authorization: "Bearer " + token }
                        }).then(() => {
                            context.state.loggingOut = false
                            //window.location.reload()
                            // resolve()
                        }).catch(() => {
                            context.state.loggingOut = false
                            window.location.reload()
                            //reject(error)
                        });
                    }
                    else {
                        if (response.data.employeeAccount) {
                            context.commit(Mutation.EMPLOYEE_SET_ME, response.data.employeeAccount)
                        }
                        else {
                            context.dispatch(Action.EMPLOYEE_GET_ME)
                        }

                        var cookieExpiry = response.data.expires.toString() + 's'
                        cookies.set(Cookies.SZOK_USER_AUTH, cokstring, cookieExpiry);
                        context.commit(Mutation.IDENTITY_OPEN_SESSION, cookie)

                        resolve();
                    }
                }).catch(error => reject(error));
            })
        },

        /** Atttempts user register. */
        [Action.IDENTITY_VERIFY](context, credentials) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.IDENTITY_VERIFY,
                    data: credentials,
                    headers: { Authorization: "Bearer " + context.state.session.token }
                }).then(response => {
                    resolve(response.data);
                }).catch(error => { reject(error) });
            })
        },

        /** Atttempts user register. */
        [Action.IDENTITY_REGISTER](context, credentials) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.IDENTITY_REGISTER,
                    data: credentials,
                    headers: { Authorization: "Bearer " + context.state.session.token }
                }).then(response => {
                    context.commit(Mutation.IDENTITY_PUSH_NEW_USER, response.data)
                    resolve(response.data);
                }).catch(error => { reject(error) });
            })
        },

        /** Atttempts user register. */
        [Action.IDENTITY_REGISTER_WORKER](context, credentials) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.IDENTITY_REGISTER_WORKER,
                    data: credentials,
                    headers: { Authorization: "Bearer " + context.state.session.token }
                }).then(response => {
                    //context.commit(Mutation.IDENTITY_PUSH_NEW_USER, response.data)
                    resolve(response.data);
                }).catch(error => { reject(error) });
            })
        },

        /** Request password reset mail. */
        [Action.IDENTITY_GET_CODES](context, val) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    headers: { Authorization: "Bearer " + context.state.session.token },
                    url: Apis.IDENTITY_GET_CODES.replace('{email}', val)
                }).then(response => {
                    resolve(response.data);
                }).catch(error => { reject(error) });
            })
        },

        /** Request password reset mail. */
        [Action.SET_BLOCK_LOGIN](context, val) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    headers: { Authorization: "Bearer " + context.state.session.token },
                    url: Apis.SET_BLOCK_LOGIN.replace('{val}', val)
                }).then(() => {
                    resolve();
                }).catch(error => { reject(error) });
            })
        },

        /** Request password reset mail. */
        [Action.IDENTITY_REMOVE_EMAIL_BLOCK](context, email) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    headers: { Authorization: "Bearer " + context.state.session.token },
                    url: Apis.IDENTITY_REMOVE_EMAIL_BLOCK.replace('{email}', email)
                }).then(() => {
                    resolve();
                }).catch(error => { reject(error) });
            })
        },

        /** Request password reset mail. */
        [Action.IDENTITY_PASSWORD_RESET_REQUEST](context, email) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: Apis.IDENTITY_PASSWORD_RESET_REQUEST.replace('{email}', email)
                }).then(() => {
                    resolve();
                }).catch(error => { reject(error) });
            })
        },

        /** Request password reset mail. */
        [Action.IDENTITY_LOGOUT_USER](context, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.IDENTITY_LOGOUT_USER,
                    data: payload,
                    headers: { Authorization: "Bearer " + context.state.session.token }
                }).then(() => {
                    resolve();
                }).catch(error => { reject(error) });
            })
        },

        // /** Request password reset mail. */
        // [Action.IDENTITY_LOGOUT_USER](context, email) {
        //     return new Promise((resolve, reject) => {
        //         axios({
        //             method: 'get',
        //             url: Apis.IDENTITY_LOGOUT_USER.replace('{email}', email),
        //             headers: { Authorization: "Bearer " + context.state.session.token }
        //         }).then(() => {
        //             resolve();
        //         }).catch(error => { reject(error) });
        //     })
        // },

        /** Atttempts password change with reset token. */
        [Action.IDENTITY_PASSWORD_RESET](context, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    data: payload,
                    url: Apis.IDENTITY_PASSWORD_RESET
                }).then(() => {
                    resolve();
                }).catch(error => { reject(error) });
            })
        },

        /** Atttempts user register. */
        [Action.IDENTITY_CHANGE_PASSWORD](context, credentials) {
            return new Promise((resolve, reject) => {

                axios({
                    method: 'post',
                    url: Apis.IDENTITY_CHANGE_PASSWORD,
                    data: credentials,
                    headers: { Authorization: "Bearer " + context.state.session.token }
                }).then(() => {
                    resolve();
                }).catch(error => { reject(error) });
            })
        },

        /** Atttempts to get roles. */
        [Action.IDENTITY_GET_ROLES](context) {
            return new Promise((resolve, reject) => {
                axios({
                    url: Apis.IDENTITY_ROLES,
                    method: 'get',
                    headers: { Authorization: "Bearer " + context.state.session.token }
                }).then(response => {
                    context.commit(Mutation.IDENTITY_SET_ROLES, response.data)
                    resolve(response);
                }).catch(error => reject(error));
            })
        },

        /** Atttempts to get roles. */
        [Action.IDENTITY_GET_USERS](context) {
            return new Promise((resolve, reject) => {
                axios({
                    url: Apis.IDENTITY_ADMIN_USERS,
                    method: 'get',
                    headers: { Authorization: "Bearer " + context.state.session.token }
                }).then(response => {
                    context.commit(Mutation.IDENTITY_SET_ALL_USERS, response.data)
                    resolve(response);
                }).catch(error => reject(error));
            })
        },

        /** Atttempts to get roles. */
        [Action.IDENTITY_GET_DRIVERS](context) {
            return new Promise((resolve, reject) => {
                axios({
                    url: Apis.IDENTITY_DRIVERS,
                    method: 'get',
                    headers: { Authorization: "Bearer " + context.state.session.token }
                }).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            })
        },

        /** Atttempts to get roles. */
        [Action.IDENTITY_LOGOUT_SOFT](context) {
            context.state.loggingOut = true
            cookies.remove('SZOK_USER_AUTH');

            return new Promise((resolve) => {
                context.commit(Mutation.TIME_STOP)
                context.commit(Mutation.IDENTITY_CLOSE_SESSION)
                window.location.reload()
                resolve()
            })
        },

        /** Atttempts to get roles. */
        [Action.IDENTITY_LOGOUT](context) {
            context.state.loggingOut = true
            cookies.remove('SZOK_USER_AUTH');

            return new Promise((resolve) => {
                context.commit(Mutation.TIME_STOP)
                context.commit(Mutation.IDENTITY_CLOSE_SESSION)

                if (context.state.session) {
                    let token = context.state.session.token
                    axios({
                        url: Apis.IDENTITY_LOGOUT,
                        method: 'get',
                        headers: { Authorization: "Bearer " + token }
                    }).then(() => {
                        context.state.loggingOut = false
                        window.location.reload()
                        // resolve()
                    }).catch(() => {
                        context.state.loggingOut = false
                        window.location.reload()
                        //reject(error)
                    });
                }
                else {
                    window.location.reload()
                }


                // context.commit(Mutation.WORKERS_CLEAR_MODULE)
                // context.commit(Mutation.MOBI_RESET)
                // context.commit(Mutation.POZ_RESET)
                // context.commit(Mutation.EMPLOYEE_CLEAR)

                // router.push('login')

                resolve()
            })
        },

        /** Atttempts token renawal. */
        [Action.IDENTITY_SESSION_RENEW_TOKEN]({ state, commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    url: Apis.IDENTITY_TOKEN_RENEW,
                    method: 'get',
                    headers: { Authorization: "Bearer " + state.session.token }
                }).then(response => {
                    var added = new Date().getTime() + (response.data.expires * 1000)
                    var until = moment().add(response.data.expires, 'seconds')

                    var cookie = {
                        token: response.data.token,
                        email: response.data.email,
                        name: response.data.name,
                        roles: response.data.roles,
                        expires: response.data.expires,
                        ends: added,
                        until: until,
                        activity: response.data.expires
                    }

                    commit(Mutation.IDENTITY_RENEW_USER, cookie)
                    resolve();
                }).catch(error => reject(error));
            })
        },

        /** User logout. This actually applies mutation. */
        [Action.IDENTITY_SESSION_UPDATE]({ commit, state, dispatch }, now) {
            commit(Mutation.IDENTITY_SESSION_UPDATE_TIME, now)
            var cookieTimeLeft = state.cookieEnd - now

            if (cookieTimeLeft < TIME_TO_RENEW_TOKEN_MILIS) {
                dispatch(Action.IDENTITY_SESSION_RENEW_TOKEN).then(() => { }).catch(() => { })
            }

            let cookieUser = cookies.get(Cookies.SZOK_USER_AUTH);

            if (cookieUser === null || cookieUser === undefined) {
                dispatch(Action.IDENTITY_LOGOUT, 'STORE_NO_COOKIE')
            }

            if (state.sessionEndsIn <= 0 || cookieTimeLeft <= 0) {
                dispatch(Action.IDENTITY_LOGOUT, 'STORE_COOKIE_EXPIRE')
            }
        },

        /** User logout. This actually applies mutation. */
        [Action.IDENTITY_DELETE_USER]({ commit, state }, user) {
            return new Promise((resolve, reject) => {
                if (user != null) {
                    let indexOfUser = state.users.findIndex(u => u.email === user.email)

                    if (indexOfUser > -1) {
                        let credentials = { email: user.email }
                        axios({
                            method: 'DELETE',
                            url: Apis.IDENTITY_DELETE_USER,
                            data: credentials,
                            headers: { Authorization: "Bearer " + state.session.token }
                        }).then(() => {
                            commit(Mutation.IDENTITY_DELETE_USER, indexOfUser)
                            resolve();
                        }).catch(error => { reject(error) });
                    }
                    else {
                        reject()
                    }
                }
                else {
                    reject()
                }
            })
        },

        /** User logout. This actually applies mutation. */
        [Action.IDENTITY_ACTIVATE_USER]({ state }, email) {
            return new Promise((resolve, reject) => {
                let credentials = { email: email }
                axios({
                    method: 'post',
                    url: Apis.IDENTITY_ACTIVATE_USER,
                    data: credentials,
                    headers: { Authorization: "Bearer " + state.session.token }
                }).then(() => {
                    resolve();
                }).catch(error => { reject(error) });
            })
        },

        /** User logout. This actually applies mutation. */
        [Action.IDENTITY_DEACTIVATE_USER]({ state }, email) {
            return new Promise((resolve, reject) => {
                let credentials = { email: email }
                axios({
                    method: 'post',
                    url: Apis.IDENTITY_DEACTIVATE_USER,
                    data: credentials,
                    headers: { Authorization: "Bearer " + state.session.token }
                }).then(() => {
                    resolve();
                }).catch(error => { reject(error) });
            })
        },

        /** User logout. This actually applies mutation. */
        [Action.IDENTITY_MODIFY_USER]({ commit, state }, credentials) {
            return new Promise((resolve, reject) => {
                if (credentials != null) {
                    let indexOfUser = state.users.findIndex(u => u.email === credentials.email)

                    if (indexOfUser > -1) {
                        axios({
                            method: 'PUT',
                            url: Apis.IDENTITY_MODIFY_USER,
                            data: credentials,
                            headers: { Authorization: "Bearer " + state.session.token }
                        }).then((response) => {
                            let modifiedUser = response.data
                            commit(Mutation.IDENTITY_EDIT_USER, { indexOfUser, modifiedUser })
                            resolve();
                        }).catch(error => { reject(error) });
                    }
                    else {
                        reject()
                    }
                }
                else {
                    reject()
                }
            })
        },

        /** User logout. This actually applies mutation. */
        [Action.IDENTITY_MODIFY_EMAIL]({ state }, credentials) {
            return new Promise((resolve, reject) => {
                if (credentials != null) {
                    axios({
                        method: 'PUT',
                        url: Apis.IDENTITY_MODIFY_EMAIL,
                        data: credentials,
                        headers: { Authorization: "Bearer " + state.session.token }
                    }).then((response) => {
                        resolve(response)
                    }).catch(error => { reject(error) });
                }
                else {
                    reject()
                }
            })
        },

        /** User logout. This actually applies mutation. */
        [Action.QUICKTOKEN_VALIDATE](context, credentials) {
            return new Promise((resolve, reject) => {
                if (credentials != null) {
                    axios({
                        method: 'post',
                        url: Apis.QUICKTOKEN_VALIDATE,
                        data: credentials,
                    }).then((response) => {
                        resolve(response.data)
                    }).catch(error => { reject(error) });
                }
                else {
                    reject()
                }
            })
        },
        /** User logout. This actually applies mutation. */
        [Action.QUICKTOKEN_REQUEST](context, credentials) {
            return new Promise((resolve, reject) => {
                if (credentials != null) {
                    axios({
                        method: 'post',
                        url: Apis.QUICKTOKEN_REQUEST,
                        data: credentials
                    }).then((response) => {
                        resolve(response.data)
                    }).catch(error => { reject(error) });
                }
                else {
                    reject()
                }
            })
        },

        [Action.IDENTITY_TELEPHONE_VERIFICATION_TOKEN](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.IDENTITY_TELEPHONE_VERIFICATION_TOKEN,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });

                } else reject('401')
            })
        },

        [Action.IDENTITY_TELEPHONE_TOKEN_VALIDATION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.IDENTITY_TELEPHONE_TOKEN_VALIDATION,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });

                } else reject('401')
            })
        }
    }


}