export default {

  data: () => {
    return {
      mSpecializations: [
        {
          id: 0,
          name: 'Brak informacji'
        },
        {
          id: 1,
          name: 'Rodzinny'
        },
        {
          id: 2,
          name: 'Kardiolog'
        },
        {
          id: 3,
          name: 'Położna'
        },
        {
          id: 4,
          name: 'Pielęgniarka'
        },
        {
          id: 5,
          name: 'Pulmonolog'
        },
        {
          id: 6,
          name: 'Rezydent'
        },
        {
          id: 7,
          name: 'Ginekolog'
        },
        {
          id: 8,
          name: 'Internista'
        },
        {
          id: 9,
          name: 'Neurolog'
        },
        {
          id: 10,
          name: 'Psycholog'
        }
      ]
    }
  },

  methods: {
    mGetSpec(id) {
      let spec = this.mSpecializations.find(s => s.id === id)
      if (spec) return spec.name
      else return `Brak [${id}]`
    }
  }
}
